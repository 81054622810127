import React, { useCallback } from "react";
import { projectsConstants } from "../../../../_constants/projects.constants";
import { CardSensor } from "./CardSensor/CardSensor";

export const TerminalDeviceGenericAnalogSensorView = (props) => {

  const {
    terminalDevice,
    onAnalogSensorClick
  } = props

  const getAnalogSensorComponents = useCallback(() => {
    if (terminalDevice?.sensors instanceof Array) {
      let cardSensors = terminalDevice.sensors
        .sort( (a, b) => a?.sensorIndex - b?.sensorIndex)
        .filter(
          (sensor) =>
            sensor?.active &&
            sensor?.sensorId?.physicalCommunicationType ===
              projectsConstants.global.sensors.phys.analog
        )
        .flatMap(sensor => sensor.measures.map(measure => ({...sensor, measure})))
        .filter(wrapSensorMeasure => !!(wrapSensorMeasure.measure))
        .map((wrapSensorMeasure, index) => {
          return (
            <CardSensor
              key={index}
              physicalCommunicationType={
                projectsConstants.global.sensors.phys.analog
              }
              id={wrapSensorMeasure.id}
              sensorIndex={wrapSensorMeasure.sensorIndex}
              sensorValue={wrapSensorMeasure.measure.value}
              sensorUnit={wrapSensorMeasure.measure.unit}
              loading={wrapSensorMeasure?.isRequesting}
              dateTime={
                wrapSensorMeasure.lastValueAt &&
                new Date(wrapSensorMeasure.lastValueAt)
              }
              onClick={onAnalogSensorClick}
              allowSwitchToAverage={!isNaN(wrapSensorMeasure.measure.dailyAverage)}
              dialyAverageSensorValue={wrapSensorMeasure.measure.dailyAverage}
              dailyMinSensorValue={wrapSensorMeasure.measure.dailyMin}
              dailyMaxSensorValue={wrapSensorMeasure.measure.dailyMax}
            />
          );
        });

      return cardSensors
    }
    return <>No hay sensores analógicos configurados.</>;
  }, [onAnalogSensorClick, terminalDevice.sensors]);

  return <>
   <div className="sensorsCardFather">{getAnalogSensorComponents(terminalDevice)}</div>
  </>;
};
