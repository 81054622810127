import React from "react";
import { projectsConstants } from "../../../_constants/projects.constants";
import { MqttMessageProcessor } from "./MqttMessageProcessor";
import PropTypes from 'prop-types';

export const SetoutputvMQTT = (props) => {
  return (
    <MqttMessageProcessor
      topic={projectsConstants.master_outputs.actions.setoutputv}
      processMsg={props.processMsg}
      processErrorMsg={props.processErrorMsg}
    ></MqttMessageProcessor>
  );
};

SetoutputvMQTT.propTypes = {
  processMsg: PropTypes.func.isRequired,
  processErrorMsg: PropTypes.func.isRequired
}
