import React from "react";
import PropTypes from 'prop-types';
import { Spinner } from "../../../../../Airframe/Spinner/Spinner";

export const CalibrationStepWaitingToStabilize = (props) => {
  return (
    <div>
      <div>
        ¡Dispositivo notificado! Esperando a que reporte el final del calibrado.
        Este proceso puede tardar varios minutos.
      </div>
      <div className="spinnerCalibrate">
        <Spinner size={"50px"} />
      </div>
      <div>Último valor: {props.lastValue}pH</div>
      {props.lastValueAt && (
        <div>
          Recibido a las: {props.lastValueAt}.
          {}
        </div>
      )}
    </div>
  );
};

CalibrationStepWaitingToStabilize.propTypes = {
    lastValue: PropTypes.number.isRequired,
    lastValueAt: PropTypes.string
}
