import { terminalsConstants } from "../_constants/terminals.constants";
import produce from "immer";

let terminalDevice = {
  loading: false,
  loaded: false,
  error: false,
  data: {} // -> contendra {{terminalId: {terminalDevices: [{}]}} , ...,{}}
};

export const terminalDevices = (state = terminalDevice, action) => {
  switch (action.type) {
    case terminalsConstants.devices.selected:
      return produce(state, draft => {
        var terminal = action.terminal;
        draft.data[terminal.id] = {
          mustCallApi: true,
          fakeLoad: false,
          reload: 0,
          devices: {}
        };
        for (var index in terminal.terminalDevices) {
          var terminalDeviceId = terminal.terminalDevices[index];
          draft.data[terminal.id].devices[terminalDeviceId] = {
            mustLoad: true,
            fakeLoad: false
          };
        }
      });
      break;
    case terminalsConstants.devices.get.request_all:
    case terminalsConstants.devices.get.request:
      return produce(state, draft => {
        draft.loaded = false;
        draft.loading = true;
        draft.error = false;
      });
    case terminalsConstants.devices.get.response:
      return produce(state, draft => {
        draft.loaded = true;
        draft.loading = false;
        draft.error = false;
        var terminal = action.terminal;
        var {
          terminalDeviceId,
          terminalDevice
        } = action.terminalIdAndTerminalDevice;
        draft.data[terminal.id].devices[terminalDeviceId] = terminalDevice;
        draft.data[terminal.id].devices[terminalDeviceId].mustLoad = false;
        draft.data[terminal.id].devices[terminalDeviceId].fakeLoad = false;
        draft.data[terminal.id].devices[terminalDeviceId].meterData = {};
      });
    case terminalsConstants.devices.get.response_all:
      return produce(state, draft => {
        draft.loaded = true;
        draft.loading = false;
        draft.error = false;

        var terminal = action.terminal;

        draft.data[terminal.id].devices.reload++;
        draft.data[terminal.id].devices.mustCallApi = false;
        for (var index in action.terminalDevices.terminalDevices) {
          var recvTerminalDevice =
            action.terminalDevices.terminalDevices[index];

          draft.data[terminal.id].devices[
            recvTerminalDevice.id
          ] = recvTerminalDevice;
          var terminalDevice =
            draft.data[terminal.id].devices[recvTerminalDevice.id];
          terminalDevice.mustLoad = false;
          terminalDevice.fakeLoad = false;
          terminalDevice.meterData = {};
        }
      });
    case terminalsConstants.devices.get.error:
      alert("ERROR AL RECUPERAR EL TerminalDevice");
      return produce(state, draft => {
        draft.loaded = false;
        draft.loading = false;
        draft.error = true;
      });
    case terminalsConstants.devices.closed:
      return produce(state, draft => {
        var terminal = action.terminal;
        draft.data[terminal.id].devices[action.terminalDeviceId] = {};
      });
    case terminalsConstants.devices.output.action:
      return produce(state, draft => {
        var terminalDeviceId = action.terminalDeviceId;
        var found = false;

        const terminalIds = Object.keys(state.data);
        for (const terminalId of terminalIds) {
          const terminalDevicesKeys = Object.keys(
            state.data[terminalId].devices
          );

          for (var tdId of terminalDevicesKeys) {
            var terminalDevice = state.data[terminalId].devices[tdId];
            if (terminalDevice.id == terminalDeviceId) {
              draft.data[terminalId].devices[terminalDeviceId].intensity =
                action.intensity;
              draft.data[terminalId].devices[
                terminalDeviceId
              ].mustLoad = !terminalDevice.mustLoad;
              draft.data[terminalId].devices[terminalDeviceId].fakeLoad = true;
              draft.data[terminalId].devices[terminalDeviceId].outputs[
                action.targetOutput
              ].expectedState = action.state;

              found = true;
              break;
            }
          }
          if (found) {
            break;
          }
        }
      });
    case terminalsConstants.devices.output.notification:
      return produce(state, draft => {
        var terminalDeviceId = action.terminalDeviceId;
        var found = false;

        const terminalIds = Object.keys(state.data);
        for (const terminalId of terminalIds) {
          const terminalDevicesKeys = Object.keys(
            state.data[terminalId].devices
          );

          for (var tdId of terminalDevicesKeys) {
            var terminalDevice = state.data[terminalId].devices[tdId];
            if (terminalDevice.id == terminalDeviceId) {
              draft.data[terminalId].devices[terminalDeviceId].intensity =
                action.intensity;
              draft.data[terminalId].devices[terminalDeviceId].lastStatus =
                action.datetime;
              draft.data[terminalId].devices[
                terminalDeviceId
              ].mustLoad = !terminalDevice.mustLoad;
              draft.data[terminalId].devices[terminalDeviceId].fakeLoad = true;
              draft.data[terminalId].devices[terminalDeviceId].outputs[
                action.targetOutput
              ].currentState = action.state;
              draft.data[terminalId].devices[terminalDeviceId].outputs[
                action.targetOutput
              ].intensity = action.intensity;
              found = true;
              break;
            }
          }
          if (found) {
            break;
          }
        }
      });
    case terminalsConstants.devices.meterData.updated:
      return produce(state, draft => {
        var terminalDeviceId = action.terminalDeviceId;
        var found = false;

        const terminalIds = Object.keys(state.data);
        for (const terminalId of terminalIds) {
          const terminalDevicesKeys = Object.keys(
            state.data[terminalId].devices
          );

          for (var tdId of terminalDevicesKeys) {
            var terminalDevice = state.data[terminalId].devices[tdId];
            if (terminalDevice.id == terminalDeviceId) {
              draft.data[terminalId].devices[terminalDeviceId].meterData =
                action.terminalDeviceMeterData;
              found = true;
              break;
            }
          }
          if (found) {
            break;
          }
        }
      });
    case terminalsConstants.devices.meterData.reveiced:
      return produce(state, draft => {
        var terminal = action.terminal;
        if (
          draft.data[terminal.id].devices[action.terminalDeviceId] !== undefined
        ) {
          draft.data[terminal.id].devices[action.terminalDeviceId].meterData =
            action.terminalDeviceMeterData;
        }
      });
    default:
      return state;
  }
};
