import React, { useCallback, useState, useRef } from "react";
import { useUploader } from "../../../context/upload-context";
import PropTypes from "prop-types";
import { Progress } from "reactstrap";
import exifr from "exifr";
import "./FIleUploader.sass";
export const uploadTargetEntities = {
  sector: "sector",
  terminal: "terminal",
  trigger: "trigger"
};

export const FileUploader = (props) => {
  const { uploadSectorImage, uploadTerminalImage, uploadTriggersExcel } = useUploader();
  const { targetEntity, uploadEntityIds, onUpdate } = props;
  const inputFileRef = useRef(null);
  const MaxSize = 8;
  const [state, setState] = useState({
    file: undefined,
    message: "",
    uploading: false,
    existsImage: false,
    orientation: 0
  });
  const [progress, setProgress] = useState(0);
  const errorMessage = <div className="Error">Error subiendo el documento.</div>;
  const errorTypeSize = (
    <div className="Error">
      El archivo es demasiado grande, debe de ser menor a 8Mb
    </div>
  );
  const successMessage = (
    <div className="SuccessUploadPic">Se ha subido el archivo.</div>
  );
  const awaitMessage = <div className="SuccessUploadPic">Subiendo archivo.</div>;

  const isImageFile = useCallback(() => {
    return targetEntity === uploadTargetEntities.sector || targetEntity === uploadTargetEntities.terminal
  }, [targetEntity])

  const onUploadProgress = useCallback(
    (progressEvent) => {
      let progress = parseInt(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      // Hay un delay bastante importante entre cuando se recibel el 100 y
      // se ejecuta la respuesta a uploadSectorImage.
      // Quitamos ese 2 % para no dar sensación de finalizado cuando no es correcto.
      progress = progress === 100 ? progress - 2 : progress;
      setProgress(progress);
    },
    []
  );

  const setSuccessMessage = useCallback((response) => {
    setState({
      ...state,
      message: successMessage,
      uploading: false,
      existsImage: isImageFile(),
    });
    setProgress(100);
    onUpdate(state.file,state.orientation, response);
  }, [state, onUpdate, successMessage, isImageFile]);

  const setAwaitMessage = useCallback(() => {
    setState({
      ...state,
      message: awaitMessage,
      progress: 0,
      uploading: false,
      existsImage: false,
      file: undefined,
    });
  }, [state, awaitMessage]);

  const setErrorMessage = useCallback(() => {
    setState({
      ...state,
      message: errorMessage,
      uploading: false,
      existsImage: false,
      file: undefined,
    });
    setProgress(0);
  }, [state, errorMessage]);

  const onFileSelected = useCallback(
    async (event) => {
      if (
        event.target.files instanceof FileList &&
        event.target.files.length > 0
      ) {
        const selectedFile = event.target.files[0];
        //Check Size:
        const mb = selectedFile.size / 1048576;
        if (mb < MaxSize) {
          //Check Type:
          //const typePhoto = selectedFile.type;
          //  if (typePhoto === "image/jpeg" || typePhoto === "image/heif"  ) {
          let path = URL.createObjectURL(selectedFile);

          let orientation = 0;
          
         
          try{
            orientation = await exifr.orientation(path);
            orientation = orientation || 0;
         
          }catch(e) {
            console.log(e);
          }
          setState({
            uploading: false,
            file: selectedFile,
            existsImage: isImageFile(),
            message: "",
            orientation
          });
        } else {
          setState({
            message: errorTypeSize,
            progress: 0,
            uploading: false,
            existsImage: false,
            file: undefined,
          });
        }
        setProgress(0);

        /* } else {
          setState({
            message: errorType,
            progress: 0,
            uploading: false,
            existsImage: false,
            file: undefined,
          }); }*/
      }
    },
    [errorTypeSize, isImageFile]
  );

  const upload = useCallback(
    (e) => {
      if (
        targetEntity === uploadTargetEntities.sector ||
        targetEntity === uploadTargetEntities.terminal ||
        targetEntity === uploadTargetEntities.trigger
      ) {
        setState({ ...state, uploading: true, message: "" });
      }

      switch (targetEntity) {
        case uploadTargetEntities.sector:
          uploadSectorImage(...uploadEntityIds, state.file, onUploadProgress, state.orientation)
            .then((sectorImageDto) => {
              setSuccessMessage();
            })
            .catch(() => {
              setErrorMessage();
            });
          break;
        case uploadTargetEntities.terminal:
          uploadTerminalImage(...uploadEntityIds, state.file, onUploadProgress, state.orientation)
            .then(() => {
              setSuccessMessage();
            })
            .catch(() => {
              setErrorMessage();
            });
          break;
          case uploadTargetEntities.trigger:
            uploadTriggersExcel(...uploadEntityIds, state.file, onUploadProgress)
            .then((response) => {
              setSuccessMessage(response);
            })
            .catch(() => {
              setErrorMessage();
            });
            break;
        default:
          setState({ ...state, uploading: false });
          console.log("Entidad no soportada");
          break;
      }
    },
    [state, targetEntity, uploadEntityIds, setSuccessMessage, setErrorMessage, onUploadProgress, uploadSectorImage, uploadTerminalImage, uploadTriggersExcel]
  );

  const onUploadClick = useCallback(() => {
    if (!state.file) {
      // No se ha seleccionado un archivo.
      inputFileRef.current.click();
      setAwaitMessage();
    } else {
      // Ya hay un archivo seleccionado
      upload();
    }
  }, [state, upload, setAwaitMessage]);

  const selectOtherImage = useCallback(() => {
    if (!state.uploading) {
      inputFileRef.current.click();
    }
  }, [state, inputFileRef]);

  const contentMarcoFoto = (
    <div className="PhotoContent">
      <input
        type="file"
        id="upload2"
        name="myImage"
        className="FixUpload"
        ref={inputFileRef}
        //accept="image/x-png,image/gif,image/jpeg,image/heif"
        //accept="image/jpeg"
      />
    </div>
  );

  return (
    <>
      {/*Previsualización foto: */}
      {isImageFile() && state.file && (
        <>
          <div className="ImagePreview">
            <img
              src={URL.createObjectURL(state.file)}
              alt="picUpload"
              className="picUpload"
              width="20%"
              onClick={selectOtherImage}
            />
            {state.existsImage ? (
              <div className="PicName">{state.file?.name}</div>
            ) : (
              <></>
            )}
          </div>
        </>
      )}

      {!state.uploading && (
        <>
          <div className="NumeroElementosDiv" onChange={onFileSelected}>
            {state.existsImage ? <></> : <div className="subtitleapp2">Elegir documento</div>}

            {!state.existsImage ? (
              <div className="MarcoFoto">{contentMarcoFoto}</div>
            ) : (
              <div className="Hidden-MarcoFoto">{contentMarcoFoto}</div>
            )}
          </div>
        </>
      )}

      {state.file && (
        <div className="ProgressView">
          <Progress
            className="BarraProgress FullWidth"
            color="success"
            value={progress}
          />
        </div>
      )}
      {state.message}
      {state.uploading && <div className="buttongeneral disabled">Subir</div>}
      {!state.uploading && (
        <div className="buttongeneral" onClick={(e) => onUploadClick()}>
          Subir
        </div>
      )}
    </>
  );
};

FileUploader.propTypes = {
  targetEntity: PropTypes.oneOf(Object.values(uploadTargetEntities)).isRequired,
  uploadEntityIds: PropTypes.array,
  onUpdate: PropTypes.func.isRequired,
  showImagePreview: PropTypes.bool
};
