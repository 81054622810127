import React from "react";

export const Documentation = (props) => {
  return (
    <div className="documentation">
      <div className="helptitle">Documentación para el usuario:</div>
      <div className="helptitle">Elige terminales:</div>

      <div className="subhelptitle">
        Haz click en la llave para seleccionar uno o varios terminales.
      </div>
      <div className="helptitle">Para quien será y cuanto tiempo?</div>
      <div className="subhelptitle">
        Introduce un nombre y unos días, (ya sea por días o por fechas) y debajo
        aparecerán los botones para compartir tu llave.
        En caso de no elegir un día, por defecto es 1.
      </div>
      <div className="helptitle">Comprobación de llaves. (Ver / Eliminar)</div>
      <div className="subhelptitle">
        El icono de pergamino es el histórico para comprobar que llaves has
        enviado.
        <div>
          Al dar en el icono de compartir puedes seleccionar de nuevo la llave.
        </div>
      </div>
      <div></div>
      <div className="helptitle">
        <div>Seleccionar llave:</div>
       
      </div>

      <div className="subhelptitle">
        Haciendo click en el mensaje y en la mano, te copia la llave para que la
        envies por el método que tú prefieras!.
      </div>
      <div className="subrayar">
          Pulsa el botón de correo o el de whatssap para enviar la llave.
        </div>
    </div>
  );
};
