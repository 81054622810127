import React, { useEffect, useState, useCallback } from "react";
import "./Subprograms.sass";
import { InfraProgramsUI } from "../InfraPrograms/InfraProgramsUI";
import { SubprogramsUI } from "./SubprogramsUI";
import { OutputService } from "../../../../../../../_services/outputcontroller.service";
import { TerminalDeviceSubprogram } from "../../../../../../../classes/TerminalDevice/Program/Subprogram/TerminalDeviceSubprogram";

function uniq(a) {
  return Array.from(new Set(a));
}
export const ProgramationOutputs = (props) => {
 
  const {
    program,
    outputs,
    actionSaveSubroutine,
    enabled,
    subroutine,
    Programsreorganizeds,
    typeofCard,
    returnToUIViewCreateSubprogram,
    onSubprogramModified,
    ShowButtonSavePrograms
  } = props;

  const getDefaultTerminalDeviceSubprogram = useCallback(() => {
    const tds = new TerminalDeviceSubprogram();
    tds.activeTime = "00:00";
    tds.outputTasks = [];
    tds.infraprograms = new Map();
    return tds;
  }, []);

  const [viewInfraprograms, setviewInfraprograms] = useState(false);
  const [subprogramShadow, setSubprogramShadow] = useState(subroutine || getDefaultTerminalDeviceSubprogram());
  const [restartPrePoswhenreturn, setrestartPrePoswhenreturn] = useState(false);
  const errorStateMachineDefaultObject = {
    selectedOutputs: false,
    selectedTime: false,
  };
  const [errorStateMachine, setErrorStateMachine] = useState(
    errorStateMachineDefaultObject
  );


  const [infraprograms, setinfraprograms] = useState(subroutine?.infraprograms || new Map());

  const [existsHierarchyLevels, setExistsHierarchyLevels] = useState(undefined);


  const typesConfigureOutputs={
    subprograms: "subprograms",
    infraprograms: "infraprograms"
  }
  
  const [customOutputsActivations, setcustomOutputsActivations] = useState(
    new Map()
  );

  const changeTypeOutputView = useCallback(
    (outputSelect, mode, activationParams) => {
      //guarda Lista de salidas cambiadas temporalmente desde ajustes:
      //customOutputsActivations
      const data = {
        mode,
        activationParams,
      };

      customOutputsActivations.set(outputSelect.output, data);
      setcustomOutputsActivations(new Map(customOutputsActivations));
    },
    [customOutputsActivations]
  );



  useEffect(() => {
    //Obtiene la fecha de finalización del ultimo subprograma y su duración
    //  para establecer la duración del infraprograma:
    getAllLevelssOutputs();
  }, []);

  useEffect(() => {
    setSubprogramShadow(subroutine || getDefaultTerminalDeviceSubprogram());
    setinfraprograms(subroutine?.infraprograms || new Map());
  }, [subroutine]);

  const getAllLevelssOutputs = useCallback(() => {
    OutputService.getOutputTypes(0, 50).then(
      (Activations) => {
        const activationTypes = Activations.sort((first, second) => {
          if (first?.hierarchyLevel < second?.hierarchyLevel) {
            return -1;
          }
          if (first?.hierarchyLevel > second?.hierarchyLevel) {
            return 1;
          }
          return 0;
        });
        //paso 1 guardar todos los tipos que se encuentreen en salidas sin repetir.
        //Muestra todas las salidas del dispositivo:
        const hierarchyLevels = [];
        outputs.forEach((output) => {
          if (output?.outputType != null) {
            hierarchyLevels.push(output?.outputType.hierarchyLevel);
          }
        });
        const sortedHierarchyLevels = uniq(hierarchyLevels).sort();
        //para ocultar boton avanzado si es 0

        setExistsHierarchyLevels(sortedHierarchyLevels.length > 0);
       
        const newInfraprogramsMap = new Map();
        sortedHierarchyLevels.forEach((hierarchyLevel) => {
          newInfraprogramsMap.set(hierarchyLevel, []);
        });
        for (let [level, infrapgrogramsInLevel] of infraprograms.entries()) {
          newInfraprogramsMap.set(level, infrapgrogramsInLevel);
        }
        setinfraprograms(newInfraprogramsMap);
      },
      (error) => {
        return "error obteniendo los distintos niveles";
      }
    );
  }, [infraprograms]);

  const onInfraprogramDelete = useCallback(
    (outputType, infraprogram) => {
      const outputTypeId = outputType;
      if (infraprograms.has(outputTypeId)) {
        const outputTypeInfraprograms = infraprograms.get(outputTypeId);
        const indexOf = outputTypeInfraprograms.indexOf(infraprogram);
        if (indexOf >= 0) {
          const outpuTypeInfraprogramsRemoved = outputTypeInfraprograms.splice(
            indexOf,
            1
          );
          outputTypeInfraprograms.forEach((infraprogram, index) => {
            infraprogram.index = index;
          });
          infraprograms.set(outputTypeId, outputTypeInfraprograms);

          const newInfraprograms = new Map(infraprograms);
          setinfraprograms(newInfraprograms);
        }
      }
    },
    [infraprograms, onSubprogramModified]
  );
  //Save y edit deben de mostrar icono en programsview de que el programa esta en edición:
  const onInfraprogramSave = useCallback(
    (numberhiearchyactual, infraprogram) => {
      //const outputTypeId = numberhiearchyactual;
      if (!infraprograms.has(numberhiearchyactual)) {
        infraprogram.index = 0;
        infraprograms.set(numberhiearchyactual, [infraprogram]);
      } else {
        const outputTypeInfraprograms = infraprograms.get(numberhiearchyactual);
        infraprogram.index = outputTypeInfraprograms.length;
        outputTypeInfraprograms.push(infraprogram);
      }
      const newInfraprograms = new Map(infraprograms);
      setinfraprograms(newInfraprograms);
      ShowButtonSavePrograms();
    },
    [infraprograms, onSubprogramModified]
  );

  const onInfraprogramUpdate = useCallback(
    (outputType, infraprogram) => {
      const outputTypeId = outputType?.id;
      if (!infraprograms.has(outputTypeId)) {
        infraprograms.set(outputTypeId, [infraprogram]);
      } else {
        const outputTypeInfraprograms = infraprograms.get(outputTypeId);
        const indexOf = outputTypeInfraprograms.indexOf(infraprogram);
        if (indexOf >= 0) {
          outputTypeInfraprograms[indexOf] = infraprogram;
          infraprograms.set(outputTypeId, outputTypeInfraprograms);

          const newInfraprograms = new Map(infraprograms);
          setinfraprograms(newInfraprograms);
        }
      }
    },
    [infraprograms, onSubprogramModified]
  );

  const onInfraprogramsHierarhcyDone = useCallback(() => {
    subprogramShadow.infraprograms = infraprograms;
    onSubprogramModified(subprogramShadow);
    returnToUIViewCreateSubprogram();
  }, [
    infraprograms,
    subprogramShadow,
    onSubprogramModified,
    returnToUIViewCreateSubprogram,
  ]);
  /**
   * Abre la UI para insertar los datos de un InfraPrograma.
   */
  const viewUICreateInfraPrograms = useCallback(() => {
    setviewInfraprograms(true);
    //cambiar
    //ButtonAdvancedClick();
    // actionSaveSubroutine(false);
  }, []);

  const returnToUISubprogramsfromInfraprograms = useCallback(() => {
    setviewInfraprograms(false);
    setrestartPrePoswhenreturn(true);
  }, []);

  const onSubroutineSaveClick = useCallback((...params) => {
    actionSaveSubroutine(...params);
  }, [actionSaveSubroutine]);

  const onAdvancedInfraprogramsClick = useCallback((terminalDeviceSubprogram,customOutputsActivations) => {
    let index = 0;
    if(program?.subprograms && program.subprograms instanceof Array){
      index = program.subprograms.length;
    }
    
    terminalDeviceSubprogram.index = index;
    //Salidas modificadas de subprogramas en infraprogramas:
   // terminalDeviceSubprogram.customActivationTypes=customOutputsActivations;
    setSubprogramShadow(terminalDeviceSubprogram);
  }, [program]);



  return (
    <>
      {/* UI ADD INFRAPROGRAMS: */}
      {!viewInfraprograms ? (
        <>
          <SubprogramsUI
            viewUICreateInfraPrograms={viewUICreateInfraPrograms}
            program={program}
            errorStateMachine={errorStateMachine}
            setErrorStateMachine={setErrorStateMachine}
            subroutine={subprogramShadow}
            outputs={outputs}
            actionSaveSubroutine={onSubroutineSaveClick}
            onAdvancedInfraprogramsClick={onAdvancedInfraprogramsClick}
            clockEnabled={enabled}
            Cancel={returnToUIViewCreateSubprogram}
            restartPrePoswhenreturn={restartPrePoswhenreturn}
            showAdvancedButton={existsHierarchyLevels}
            modeC={typesConfigureOutputs.subprograms}
            changeTypeOutputView={changeTypeOutputView}
            customOutputsActivations={customOutputsActivations}
            ShowButtonSavePrograms={ShowButtonSavePrograms}
          />
        </>
      ) : (
        <>
          <InfraProgramsUI
            infraprograms={infraprograms}
            programSelect={program}
            outputs={outputs}
            subroutine={subprogramShadow}
            Programsreorganizeds={Programsreorganizeds}
            Cancel={returnToUISubprogramsfromInfraprograms}
            typeofCard={typeofCard.infraprogram}
            createInfraProgram={onInfraprogramSave}
            onInfraprogramDelete={onInfraprogramDelete}
            onInfraprogramUpdate={onInfraprogramUpdate}
            onInfraprogramsHierarhcyDone={onInfraprogramsHierarhcyDone}
            modeC={typesConfigureOutputs.infraprograms}
            customOutputsActivations={customOutputsActivations}
            changeTypeOutputView={changeTypeOutputView}
            ShowButtonSavePrograms={ShowButtonSavePrograms}
          />
        </>
      )}
    </>
  );
};
