import React, { useState, useEffect, useCallback } from "react";
import Masonry from "react-masonry-css";
import { projectsConstants } from "../../../../_constants/projects.constants";
import { getDateTimeFormatted } from "../../../../_helpers/DateFormatHelper";
import { useTriggers } from "../../../../_services/triggers.service";
import { Spinner } from "../../Airframe/Spinner/Spinner";
import { OutputsSubprogram } from "../ProgramsView/OutputsSubprogram";

export const DatetimeTriggersSummaryView = (props) => {
  const { terminalDevice, onTriggerClick } = props;

  const states = {
    IDLE: "IDLE",
    LOADING_TRIGGERS: "LOADING_TRIGGERS",
    LOADED_TRIGGERS: "LOADED_TRIGGERS",
    ERROR_LOADING_TRIGGERS: "ERROR_LOADING_TRIGGERS",
  };

  const initState = {
    state: states.IDLE,
    triggers: [],
    triggersForToday: [],
  };

  const [state, setState] = useState(initState);
  const triggersService = useTriggers();

  const mustRunToday = useCallback((days) => {
    const now = new Date();
    let todayOffset = now.getDay();
    const today = 1 << todayOffset;

    return (today & days) > 0;
  }, []);

  const getOutputsComponent = useCallback((outputs) => {
    const components = [];
    for (let i = 0; 1 << i <= outputs; i++) {
      if (outputs & (1 << i)) {
        components.push(
          <OutputsSubprogram key={`outputsSubprogram-${i}`} number={i + 1} />
        );
      }
    }
    return components;
  }, []);

  const getPrevActivationEndDateTime = useCallback((activations, targetId, defaultEndDateTime) => {
    if(targetId === 0){
      return defaultEndDateTime
    }

    const subprogram = activations.find(element => element.id === targetId)
    return subprogram?.endDateTime ?? defaultEndDateTime
  }, [])

  const getActivationsComponent = useCallback(
    (activations, triggerStartDatetime) => {
      const sortedActivations = activations
        .sort((first, second) => first?.id - second?.id)
        
        sortedActivations.forEach(activation => {
        const startDateTime = new Date(getPrevActivationEndDateTime(sortedActivations, activation.prevId, triggerStartDatetime).getTime() 
          + (activation.offset ?? 0))
        const endDateTime = new Date(startDateTime.getTime() + (activation.unitsLimit ?? 0))

        activation.startDateTime = startDateTime
        activation.endDateTime = endDateTime

      })


      return sortedActivations
      .map((activation, index) => {
        return (
          <div key={index} style={{width: "100%"}}>
            <div className="ProgramsCard-SubProgram">
              <div className="ProgramsCard-Program">
                <div className="ProgramsCard-SubProgram-Title">
                  <div className="ProgramsCard-Programs-Name">
                    S{activation?.id}
                  </div>
                  <div className="ProgramsCard-Programs-Outputs">
                    {getOutputsComponent(activation?.outputs)}
                  </div>
                </div>

                <div className="ProgramsCard-Programs-Hour">
                  De {getDateTimeFormatted(activation.startDateTime)} a{" "}
                  {getDateTimeFormatted(activation.endDateTime)}{" "}
                </div>
              </div>
            </div>
            <hr className="Separator"></hr>
          </div>
        );
      });
    },
    [getOutputsComponent]
  );

  const getTriggersComponent = useCallback(() => {
    if (
      state.state === states.LOADED_TRIGGERS &&
      state.triggersForToday instanceof Array
    ) {
      return state.triggersForToday
      .map((trigger, index) => (
        <div key={index}>
          <div className="ProgramsCard" onClick={(e) => onTriggerClick(trigger)}>
            <div className="ProgramsCard-Title">
              <div>
                Prog. {trigger.id} - {trigger?.description}
              </div>
              <div>
                {getDateTimeFormatted(new Date(trigger?.startTimestamp)) || ""}
              </div>
            </div>

            <div className="ProgramsCard-SubPrograms">
              {getActivationsComponent(
                trigger?.activations,
                new Date(trigger?.startTimestamp)
              )}
            </div>
            {/*    <SubprogramInfoCard/> */}
          </div>
        </div>
      ));
    }
    return <></>
  }, [getActivationsComponent, onTriggerClick, state.state, state.triggersForToday, states.LOADED_TRIGGERS]);

  //#region  useEffec

  useEffect(() => {
    if (state?.state === states.IDLE) {
      setState((prev) => {
        return { ...prev, state: states.LOADING_TRIGGERS };
      });
      triggersService.getAll(terminalDevice?.terminal, terminalDevice?.id).then(
        (triggers) => {
          triggers = triggers.filter(
            (trigger) =>
              !trigger?.deletedByUser &&
              trigger?.type === projectsConstants.global.triggers.types.program
          );

          const triggersForToday = triggers
          .filter(trigger => trigger.active)
          .filter((trigger) =>
            mustRunToday(trigger.days)
          );

          setState((prev) => {
            return {
              ...prev,
              state: states.LOADED_TRIGGERS,
              triggers,
              triggersForToday,
            };
          });
        },
        (error) => {
          setState((prev) => {
            return { ...prev, state: states.ERROR_LOADING_TRIGGERS };
          });
        }
      );
    }
  }, [
    mustRunToday,
    state,
    states.ERROR_LOADING_TRIGGERS,
    states.IDLE,
    states.LOADED_TRIGGERS,
    states.LOADING_TRIGGERS,
    terminalDevice?.id,
    terminalDevice?.terminal,
    triggersService,
  ]);

  //#endregion

  return (
    <>
      <div className="ProgramsCardFathertitle ">
        {state.state === states.LOADED_TRIGGERS && state.triggersForToday?.length > 0 && 
        <div>Programas para hoy:</div>
        }
        {state.state === states.LOADING_TRIGGERS && (
          <Spinner size="25px"></Spinner>
        )}
        {state.state === states.ERROR_LOADING_TRIGGERS && (
          <div>Error cargando los programas</div>
        )}
        {/* clase padre para controlar a la izquierda si solo hay un programa */}
        <div className="FatherMansory">
          <Masonry
            breakpointCols={2}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {state.state === states.LOADED_TRIGGERS && getTriggersComponent()}
          </Masonry>
        </div>
      </div>
    </>
  );
};
