import React, { useCallback, useState } from "react"
import { FileUploader, uploadTargetEntities } from "../../File/FileUploader"
import { useApp } from "../../../../context/app-context"
import { CheckComponent } from "../../Airframe/CheckComponent/CheckComponents"
import { Badge, Button } from "reactstrap"

const states = {
    IDLE: "IDLE",
    UPLOAD_DONE: "UPLOAD_DONE"
}

export const DateTimetriggerUploader = (props) => {

    const { onTriggerClick } = props
    const {selectedTerminal, terminalDevices} = useApp()
    const [state, setState] = useState({
        state: states.IDLE,
        triggers: undefined
    })

    const onFileUpload = useCallback((file, orientation, response) => {
        setState(prev => ({...prev, state: states.UPLOAD_DONE, triggers: response}))
    }, [])

    const getTerminalDeviceDescription = useCallback(terminalDeviceId => {
        if(terminalDevices instanceof Array){
            const terminalDevice = terminalDevices
                .find(terminalDevice => terminalDevice?.id === terminalDeviceId)

            if(terminalDevice?.description){
                return terminalDevice.description
            }
        }
        
        return ""
    }, [terminalDevices])

    const getDoneComponent = useCallback(() => {
        const triggerMessages = state
            .triggers
            .map(trigger => 
                <Button color="primary" outline onClick={e => onTriggerClick(trigger)}>
                    <div>{getTerminalDeviceDescription(trigger.terminalDevice)}: {trigger.description} ({trigger.id})</div>
                </Button>
            )

        return <CheckComponent message={
            <div>
                <h5>Creados los siguientes triggers (inactivos por seguridad): </h5>
                {triggerMessages}
            </div>}>
            </CheckComponent>
    }, [state.triggers, getTerminalDeviceDescription, onTriggerClick])

    return <div className="PanelPopUp">
    <div>
      <div className="titleapp2">Subir excel con programación para el terminal</div>
      {state.state === states.UPLOAD_DONE ? getDoneComponent() : <></>}
      <FileUploader
        targetEntity={uploadTargetEntities.trigger}
        uploadEntityIds={[selectedTerminal?.id]}
        onUpdate={onFileUpload}
      />
    </div>
  </div>
}