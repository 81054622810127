import React, { useCallback } from "react";
import { useOutputsAPI } from "../../../../context/outputs-context";
import { projectsConstants } from "../../../../_constants/projects.constants";
import { TerminalDeviceOutputsView } from "../OutputsView/TerminalDeviceOutputsView";
import { useTerminalDevice } from "../../../../context/terminal-device-context";
import { useApp } from "../../../../context/app-context";

const {
  output_deactivated: OUTPUT_DEACTIVATED,
  output_transition: OUTPUT_TRANSITION,
} = projectsConstants.global.states;

export const OutputTerminalDevices = (props) => {
  const {
    updateTerminalDevice
  } = useApp()

  const { terminalDevice } = useTerminalDevice()
  const { actoutput, endoutput, advanced_actoutput, isValidForAdvancedActoutput } = useOutputsAPI();

  const actionClickOnDisabled = (output) => {
    //console.log("Click on disabled " + output.output);
  };

  //Envia Datos de cada salida en su dirección correspondiente:
  const ActiveOutput = useCallback(
    (terminalDeviceId, output, outputIndex) => {
      if(terminalDevice?.outputs instanceof Array){
        const outputs = terminalDevice.outputs
        if (outputs[outputIndex].currentState !== OUTPUT_TRANSITION) {
          if (outputs[outputIndex].currentState === OUTPUT_DEACTIVATED) {
  
            if(isValidForAdvancedActoutput(terminalDevice))
            {
              advanced_actoutput(terminalDeviceId, output)
            }else{
              actoutput(
                terminalDeviceId,
                output,
                outputs[outputIndex].getActivationType(),
                outputs[outputIndex].activationParams
              );
            }
          } else {
            endoutput(terminalDeviceId, output);
          }
          outputs[outputIndex].prevState = outputs[outputIndex].currentState;
          outputs[outputIndex].currentState = OUTPUT_TRANSITION;
  
          updateTerminalDevice(terminalDevice);
        }
      }
      
    },
    [actoutput, advanced_actoutput, endoutput, isValidForAdvancedActoutput, terminalDevice, updateTerminalDevice]
  );

  return terminalDevice.outputs !== undefined ? (
    <div className="FilaOutputs">
      <TerminalDeviceOutputsView
        mode={"principalview"}
        onClickOnDisabled={actionClickOnDisabled}
        onClick={ActiveOutput}
        outputs={terminalDevice.outputs}
        mustUseLongPress={true}
      />
    </div>
  ) : (
    <div>Sin salidas.</div>
  );
  //return <>{data}</>;
};
