import React, { useMemo } from "react";
import { PaginatedOptions } from "../../../PaginatedOptions/PaginatedOptions";

export const TerminalDeviceSensorReportInterval = (props) => {
  const { sensor, onReportOptionClick } = props;
  const reportOptions = useMemo(() => [2, 5, 15, 30, 60], []);

  return (
    <PaginatedOptions
      label={"Reporte cada"}
      unit={"min"}
      values={reportOptions}
      valueActive={sensor?.reportIntervalInSeconds / 60}
      onOptionClick={onReportOptionClick}
    ></PaginatedOptions>
  );
};
