import React, { useCallback, useState, useEffect } from "react";
import Slider from "rc-slider";
import PropTypes from "prop-types";
import CustomInput from "../../../../Airframe/CustomInput";
import classes from "./SliderWithMultiplier.sass";

export const SliderWithMultiplier = (props) => {
  const {
    initValue,
    onChange,
    multipliers,
    name,
    label,
    onMultiplierSelected,
    multiplierSelectedId
  } = props;
  const [multiplier, setmultiplier] = useState(
    getDefaultMultiplier(multipliers)
  );
  const [realValue, setrealValue] = useState(initValue);
  const [multiplierValue, setmultiplierValue] = useState(initValue);
  const [sliderValue, setsliderValue] = useState(initValue);



  function getDefaultMultiplier(multipliers) {
    let multiplier = undefined;
    let baseMultiplier = undefined
    if (multipliers && multipliers instanceof Array) {

      multipliers.forEach((m) => {
        if (m?.baseUnit == true) {
          baseMultiplier = m;
        }
        if (multiplierSelectedId === m.id) {
          multiplier = m;
        }
      });
    }
    return multiplier || baseMultiplier;
  }

  const getSliderStep = useCallback(() => {
    return multiplier?.step || 1;
  }, [multiplier]);

  const getSliderMaxValue = useCallback(() => {
    return multiplier?.maxValue || 0;
  }, [multiplier]);

  const getSliderMinValue = useCallback(() => {
    return multiplier?.minValue || 0;
  }, [multiplier]);

  const onSliderChange = useCallback(
    (value, callCallback) => {
      const realValue = multiplier?.baseUnit
        ? value
        : multiplier.multiplier * value;
      setmultiplierValue(value);
      setsliderValue(value);
      setrealValue(realValue);
      if (callCallback === undefined || callCallback === true) {
        onChange(realValue);
      }
    },
    [multiplier, onChange]
  );

  const getLimitedValue = useCallback((multiplier, value) => {
    return value > multiplier.maxValue
      ? multiplier.maxValue
      : value < multiplier.minValue
        ? multiplier.minValue
        : value;
  });

  const actionOnMultiplierSelected = useCallback(
    (multiplierId) => {
      let tempMult = multiplier;
      if (multipliers && multipliers instanceof Array) {
        multipliers.forEach((m) => {
          if (m?.id === multiplierId) {
            tempMult = m;

            // Debemos transformar el valor actual a su correspondiente en el nuevo multiplier.
            let newMultValue = 0;
            if (tempMult.multiplier > multiplier.multiplier) {
              newMultValue = Math.round(realValue / tempMult.multiplier);
            } else {
              newMultValue = tempMult.multiplier * realValue;
            }
            newMultValue = getLimitedValue(tempMult, newMultValue);
            setmultiplierValue(newMultValue);
            setsliderValue(newMultValue);

            let rValue = tempMult.multiplier * newMultValue;
            setrealValue(rValue);
            onChange(rValue);
          }
        });
      }
      setmultiplier(tempMult);
      onMultiplierSelected(tempMult);
    },
    [multiplier, realValue, onMultiplierSelected]
  );

  useEffect(() => {

    if (initValue < getSliderMinValue()) {
      let tmpInitValue = getSliderMinValue();
      onSliderChange(tmpInitValue);
    } else {
      onSliderChange(initValue, false);
    }
  }, [initValue]);

  useEffect(() => {
    setmultiplier(getDefaultMultiplier(multipliers));
  }, [ multiplierSelectedId])

  return (
    <div className="sliderMultiplierContainer">
      <div className="Title_Descripction">{`${label} ${multiplierValue} ${multiplier.label}`}</div>
      {/*<div>
        MultiplierValue {multiplierValue} {multiplier.label};<br></br>
        RealValue {realValue};
      </div>*/}
      <div className="sliderMultiplier">
        <Slider
          onChange={(value) => {
            onSliderChange(value);
          }}
          value={sliderValue}
          min={getSliderMinValue()}
          max={getSliderMaxValue()}
          step={getSliderStep()}
          enabled={true}
        />
      </div>
      <div className={"sliderMultiplierOptions"}>
        {multipliers.map((mult, index) => (
          <CustomInput
            className={"sliderMultiplierOptionsInput"}
            key={index}
            type="radio"
            id={`radioInlineCustom-${name}-${index + 1}`}
            name={`radioInlineCustom-${name}`}
            label={mult.label}
            inline
            defaultChecked={mult.id === multiplier.id}
            onClick={() => {
              actionOnMultiplierSelected(mult.id);
            }}
          />


        ))}
      </div>
    </div>
  );
};

SliderWithMultiplier.propTypes = {
  initValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onMultiplierSelected: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  multiplierSelectedId: PropTypes.number.isRequired,
  multipliers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      maxValue: PropTypes.number.isRequired,
      minValue: PropTypes.number,
      multiplier: PropTypes.number.isRequired,
      baseUnit: PropTypes.bool.isRequired,
      step: PropTypes.number,
    })
  ),
};
