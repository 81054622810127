import React from "react";
import { projectsConstants } from "../../../../_constants/projects.constants";
import { MqttMessageProcessor } from "../../MQTT/MqttMessageProcessor";

export const SpecConfigMQTT = (props) => {
  return (
    <MqttMessageProcessor
      topic={projectsConstants.postigel.actions.specconfig}
      project={projectsConstants.postigel.id}
      processMsg={props.processMsg}
      processErrorMsg={props.processErrorMsg}
    ></MqttMessageProcessor>
  );
};
