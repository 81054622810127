import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { DropdownC } from "../../../Airframe/Dropdown/Dropdown";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import { sensorService } from "../../../../../_services/sensors.service";
import { DangerComponent } from "../../../../DangerComponent/DangerComponent";
import { Spinner } from "../../../Airframe/Spinner/Spinner";
import { Input } from "reactstrap";
import { options as TwoOptionsBoxOptions } from "./TwoOptionsBox";
import "./Cuds.sass";
import { usePublisher } from "../../../../../context/publish-context";
import uuid from "uuid/v4";

import { MESSAGE_TYPE_ACTION } from "../../../../../_constants/messageType.constants";
import { TerminalDeviceSensorStats } from "../../TerminalDevices/Components/TerminalDeviceSensorStats";
import { ReactComponent as QuestionSvg } from "./../../../../../images/ImagesTerminalsDevice/question.svg";
import { PopUpC } from "../../../Airframe/PopUp/PopUp";
import { CudsConfigurationHelp } from "./CudsConfigurationHelp";
import { TerminalDeviceCalibrationManualOutputActivation } from "../../../Calibration/TerminalDeviceCalibrationManualOutputActivation";
import { useApp } from "../../../../../context/app-context";
import { TerminalDeviceSensorCommonConfiguration } from "./TerminalDeviceSensorCommonConfiguration";
import { useTerminalDevice } from "../../../../../context/terminal-device-context";

export function castToPositiveInteger(field) {
  if (("" + field).length > 0 && !isNaN(field)) {
    field = parseInt(field);
    field = field < 0 ? 0 : field;
    return field;
  }
  return "";
}

export function castToPositiveFloat(field) {
  if (("" + field).length > 0 && !isNaN(field)) {
    field = parseFloat(field);
    field = field < 0 ? 0 : field;
    return field;
  }
  return "";
}

export const CudsConfiguration = (props) => {
  const {
    onCudChangeNotification,
    mustSave,
    cudChangeNotificationType,
    onErrorSaving,
    onSuccessSaving,
  } = props;

  const { terminalDevice, updateTerminalDeviceSensor: parentUpdateTerminalDeviceSensor} = useTerminalDevice()

  const CALIBRATION_MULTIPLIER = 50;
  const CALIBRATION_NUM_OPTIONS = 4;

  const sensortypesLoadedStates = {
    idle: "IDLE",
    loading: "LOADING",
    loaded: "LOADED",
    error: "ERROR",
    error_not_found: "ERROR_NOT_FOUND",
  };

  const errorCodes = {
    error_form_raw_value: 1,
    error_form_unit_value: 2,
    error_saving: 4,
  };

  const apiStates = {
    idle: "IDLE",
    create: "CREATE",
    update: "UPDATE",
    waiting: "WAITING",
    created: "CREATED",
    updated: "UPDATED",
    error: "ERROR",
  };

  const initState = {
    selectedSensor: undefined,
    httpAction: undefined,
    sensorType: undefined,
    sensorTypesLoadState: sensortypesLoadedStates.idle,
    lastMustSave: mustSave,
    apiState: apiStates.idle,
    errorCode: 0,
    calibrationAutomaticOption: 0,
    twoOptionsBoxSelected: TwoOptionsBoxOptions.one,

    selectedOutput:
      terminalDevice?.outputs instanceof Array && terminalDevice.outputs[0],
    showHelp: false,
  };

  const httpActions = {
    create: "CREATE",
    update: "UPDATE",
  };

  const [state, setState] = useState(initState);
  const { selectedTerminal: terminal } = useApp();
  const { publish } = usePublisher();
  const { maxCuds } = terminalDevice.device;

  const loadCudSensor = useCallback(
    (sensorIndex, source) => {
      if (source instanceof Array) {
        let tmpSelectedSensor = source.filter(
          (s) =>
            s?.sensorIndex === sensorIndex &&
            s?.sensorId.physicalCommunicationType ===
              projectsConstants.global.sensors.phys.cuds
        )[0];

        let measurement = (state.sensorType?.measurements &&
          state.sensorType?.measurements[0]) || {
          measurementUnit: "l",
          sensorUnit: "Ud",
        };

        if (tmpSelectedSensor) {
          tmpSelectedSensor.measurement = {
            ...tmpSelectedSensor.measurement,
            measurementUnit: measurement.measurementUnit,
            sensorUnit: measurement.sensorUnit,
          };
          setState({
            ...state,
            httpAction: httpActions.update,
            selectedSensor: _.cloneDeep(tmpSelectedSensor),
          });
        } else {
          measurement = {
            ...measurement,
            calibrationRawValue: 100,
            calibrationUnitValue: 1.0,
          };
          setState({
            ...state,
            httpAction: httpActions.create,
            selectedSensor: {
              sensorIndex,
              active: false,
              description: undefined,
              sensorId: {
                id: state.sensorType?.id,
              },
              measurement,
            },
          });
        }
      }
    },
    [httpActions.create, httpActions.update, state]
  );

  //#region  API POST/PUT
  const getDto = useCallback((sensor) => {
    return {
      sensorIndex: sensor.sensorIndex,
      active: sensor.active,
      description: sensor.description,
      reportIntervalInSeconds: sensor.reportIntervalInSeconds || 300,
      measurement: {
        measurementUnit: sensor.measurement.measurementUnit,
        sensorUnit: sensor.measurement.sensorUnit,
        calibrationRawValue: parseInt(sensor.measurement.calibrationRawValue),
        calibrationUnitValue: parseFloat(
          sensor.measurement.calibrationUnitValue
        ),
      },
    };
  }, []);

  const createTerminalDeviceSensor = useCallback(() => {
    setState({ ...state, apiState: apiStates.waiting });
    sensorService
      .createTerminalDeviceSensor(
        terminalDevice.terminal,
        terminalDevice.id,
        state.sensorType.id,
        state.selectedSensor.sensorIndex,
        getDto(state.selectedSensor)
      )
      .then(
        (terminalDeviceSensorCreated) => {
          setState({
            ...state,
            apiState: apiStates.created,
            selectedSensor: terminalDeviceSensorCreated,
          });
        },
        (error) => {
          setState({
            ...state,
            apiState: apiStates.error,
            errorCode: errorCodes.error_saving,
          });
        }
      );
  }, [
    apiStates.waiting,
    terminalDevice.terminal,
    terminalDevice.id,
    state,
    getDto,
    apiStates.created,
    apiStates.error,
    errorCodes.error_saving,
  ]);

  const updateTerminalDeviceSensor = useCallback(() => {
    setState({ ...state, apiState: apiStates.waiting });
    sensorService
      .updateTerminalDeviceSensor(
        terminalDevice.terminal,
        terminalDevice.id,
        state.sensorType.id,
        state.selectedSensor.sensorIndex,
        getDto(state.selectedSensor)
      )
      .then(
        (terminalDeviceSensorUpdated) => {
          setState({
            ...state,
            apiState: apiStates.updated,
            selectedSensor: terminalDeviceSensorUpdated,
          });
        },
        (error) => {
          setState({
            ...state,
            apiState: apiStates.error,
            errorCode: errorCodes.error_saving,
          });
        }
      );
  }, [
    apiStates.waiting,
    terminalDevice.terminal,
    terminalDevice.id,
    state,
    getDto,
    apiStates.updated,
    apiStates.error,
    errorCodes.error_saving,
  ]);
  //#endregion

  const validateForm = useCallback(() => {
    if (state.selectedSensor && state.selectedSensor?.measurement) {
      const sensor = state.selectedSensor;
      let errorCode = 0;
      let apiState = apiStates.idle

      if(sensor.active){
        const parsedInt = castToPositiveInteger(
          sensor.measurement.calibrationRawValue
        );
        const parsedFloat = castToPositiveFloat(
          sensor.measurement.calibrationUnitValue
        );
  
        
        if (!(parsedInt > 0)) {
          errorCode |= errorCodes.error_form_raw_value;
        }
  
        if (!(parsedFloat > 0)) {
          errorCode |= errorCodes.error_form_unit_value;
        }
  
        apiState = errorCode === 0 ? apiStates.idle : apiStates.error;
      }

      return { ...state, apiState, errorCode };
      
    }
    return { ...state, apiState: apiStates.error };
  }, [
    state,
    apiStates.error,
    apiStates.idle,
    errorCodes.error_form_raw_value,
    errorCodes.error_form_unit_value,
  ]);

  const onSaveAction = useCallback(() => {
    let newState = validateForm();
    if (newState.apiState !== apiStates.error) {
      switch (state.httpAction) {
        case httpActions.create:
          newState = { ...newState, apiState: apiStates.create };
          break;
        case httpActions.update:
          newState = { ...newState, apiState: apiStates.update };
          break;
        default:
          console.log("httpAction no soportado");
          break;
      }
    }
    setState(newState);
  }, [
    validateForm,
    apiStates.error,
    apiStates.create,
    apiStates.update,
    state.httpAction,
    httpActions.create,
    httpActions.update,
  ]);

  const onCudSelected = useCallback(
    (sensorIndex) => {
      loadCudSensor(sensorIndex, terminalDevice.sensors);
    },
    [loadCudSensor, terminalDevice.sensors]
  );

  const getDropDownCuds = useCallback(() => {
    let selected = undefined;
    const options = _.times(maxCuds, (i) => {
      const option = {
        id: i + 1,
        value: i + 1,
        label: `Caudalímetro ${i + 1}`,
      };

      if (i === state.selectedSensor?.sensorIndex) {
        selected = option;
      }
      return option;
    });

    return (
      <DropdownC
        options={options}
        onChange={(selected) => onCudSelected(selected?.id - 1)}
        placeholder={"Seleccione un caudalímetro..."}
        value={selected}
      />
    );
  }, [maxCuds, onCudSelected, state.selectedSensor?.sensorIndex]);

  //#region on change
  const onInfoClick = useCallback(() => {
    setState((prev) => ({ ...prev, showHelp: true }));
  }, []);

  const onInfoClose = useCallback(() => {
    setState((prev) => ({ ...prev, showHelp: false }));
  }, []);

  const onAnyFieldChange = useCallback(() => {
    onCudChangeNotification(cudChangeNotificationType);
  }, [onCudChangeNotification, cudChangeNotificationType]);

  const onActivedChange = useCallback(() => {
    if (state.selectedSensor) {
      state.selectedSensor.active = !state.selectedSensor.active;
      setState({ ...state });
      onAnyFieldChange();
    }
  }, [state, onAnyFieldChange]);

  const onChangeReportInterval = useCallback(
    (intervalInMinutes) => {
      if (state.selectedSensor) {
        state.selectedSensor.reportIntervalInSeconds = intervalInMinutes * 60;
        setState({ ...state });
        onAnyFieldChange();
      }
    },
    [onAnyFieldChange, state]
  );

  const onDescriptionChange = useCallback(
    (description) => {
      if (state.selectedSensor) {
        state.selectedSensor.description = description;
        setState({ ...state });
        onAnyFieldChange();
      }
    },
    [state, onAnyFieldChange]
  );

  const onCalibrationChange = useCallback(
    (rawValue, unitValue) => {
      if (state.selectedSensor?.measurement) {
        state.selectedSensor.measurement.calibrationUnitValue = castToPositiveFloat(
          unitValue
        );
        state.selectedSensor.measurement.calibrationRawValue = castToPositiveInteger(
          rawValue
        );
        setState({ ...state });
        onAnyFieldChange();
      }
    },
    [onAnyFieldChange, state]
  );
  //#endregion

  const isInvalidCalibrationRawValue = useCallback(
    (state) => {
      return (state.errorCode & errorCodes.error_form_raw_value) > 0;
    },
    [errorCodes.error_form_raw_value]
  );

  const isInvalidCalibrationUnitValue = useCallback(
    (state) => {
      return (state.errorCode & errorCodes.error_form_unit_value) > 0;
    },
    [errorCodes.error_form_unit_value]
  );

  //#region  AUTOMATIC

  const calculateRawValueFromAutomaticOption = useCallback((option) => {
    if (option === 0) {
      return CALIBRATION_MULTIPLIER;
    }
    return Math.pow(2, option) * CALIBRATION_MULTIPLIER;
  }, []);

  const getIndexOfRawValueForAutomaticOptions = useCallback(() => {
    if (state.selectedSensor?.measurement) {
      const rawValue = state.selectedSensor?.measurement.calibrationRawValue;
      const autoValues = _.times(CALIBRATION_NUM_OPTIONS, (i) =>
        calculateRawValueFromAutomaticOption(i)
      );
      return autoValues.indexOf(rawValue);
    }
    return -1;
  }, [calculateRawValueFromAutomaticOption, state.selectedSensor?.measurement]);

  const getTwoOptionsBoxSelectedOption = useCallback(() => {
    if (getIndexOfRawValueForAutomaticOptions() >= 0) {
      return TwoOptionsBoxOptions.one;
    } else {
      calculateRawValueFromAutomaticOption(0);
      return TwoOptionsBoxOptions.two;
    }
  }, [
    calculateRawValueFromAutomaticOption,
    getIndexOfRawValueForAutomaticOptions,
  ]);
  //#endregion

  //#region useEffects
  useEffect(() => {
    if (
      terminalDevice?.sensors &&
      terminalDevice.sensors instanceof Array &&
      terminalDevice.sensors.length > 0 &&
      state.sensorTypesLoadState === sensortypesLoadedStates.loaded
    ) {
      if (state.selectedSensor?.sensorIndex !== undefined) {
        loadCudSensor(state.selectedSensor.sensorIndex, terminalDevice.sensors);
      } else {
        loadCudSensor(0, terminalDevice.sensors);
      }
    }
  }, [terminalDevice.sensors, state.sensorTypesLoadState]);

  useEffect(() => {
    if (state.sensorTypesLoadState === sensortypesLoadedStates.idle) {
      // CARGAMOS LOS SENSORES.
      setState((state) => {
        return {
          ...state,
          sensorTypesLoadState: sensortypesLoadedStates.loading,
        };
      });
      sensorService.getSensorsTypes().then(
        (sTypes) => {
          sTypes = sTypes instanceof Array ? sTypes : [];
          const sType = sTypes.filter(
            (sType) =>
              sType?.physicalCommunicationType ===
                projectsConstants.global.sensors.phys.cuds &&
              sType?.name === projectsConstants.global.sensors.name.cuds
          )[0];
          setState((state) => {
            return {
              ...state,
              sensorType: sType,
              sensorTypesLoadState: sType
                ? sensortypesLoadedStates.loaded
                : sensortypesLoadedStates.error_not_found,
            };
          });
        },
        (error) => {
          setState((state) => {
            return {
              ...state,
              sensorType: undefined,
              sensorTypesLoadState: sensortypesLoadedStates.error,
            };
          });
        }
      );
    }
  }, []);

  useEffect(() => {
    if (state.lastMustSave !== mustSave && mustSave > 0) {
      state.lastMustSave = mustSave;
      onSaveAction();
    }
  }, [mustSave, onSaveAction, state.lastMustSave]);

  useEffect(() => {
    switch (state.apiState) {
      case apiStates.create:
        createTerminalDeviceSensor();
        break;
      case apiStates.update:
        updateTerminalDeviceSensor();
        break;
      case apiStates.created:
        parentUpdateTerminalDeviceSensor(state.selectedSensor);
        onSuccessSaving(cudChangeNotificationType);
        publish(projectsConstants.master_outputs.actions.common.sensorsconfig, {
          id: uuid(),
          type: MESSAGE_TYPE_ACTION,
          data: {
            target_id: terminalDevice?.id,
          },
        });
        break;
      case apiStates.updated:
        parentUpdateTerminalDeviceSensor(state.selectedSensor);
        onSuccessSaving(cudChangeNotificationType);
        publish(projectsConstants.master_outputs.actions.common.sensorsconfig, {
          id: uuid(),
          type: MESSAGE_TYPE_ACTION,
          data: {
            target_id: terminalDevice?.id,
          },
        });
        break;
      case apiStates.error:
        if (state.errorCode === errorCodes.error_saving) {
          onErrorSaving(cudChangeNotificationType);
        }
        break;
      default:
        break;
    }
  }, [state.apiState]);

  useEffect(() => {
    if (state.selectedSensor?.measurement) {
      state.calibrationAutomaticOption = getIndexOfRawValueForAutomaticOptions(
        state.selectedSensor.measurement.calibrationRawValue
      );
      setState({ ...state });
    }
  }, [state.selectedSensor?.measurement?.calibrationRawValue]);

  useEffect(() => {
    if (state.selectedSensor) {
      state.twoOptionsBoxSelected = getTwoOptionsBoxSelectedOption();
      setState({ ...state });
    }
  }, [state.selectedSensor]);
  //#endregion

  if (
    state.sensorTypesLoadState === sensortypesLoadedStates.error ||
    state.sensorTypesLoadState === sensortypesLoadedStates.error_not_found
  ) {
    return (
      <DangerComponent
        message={"Error recuperando los tipos de sensores."}
      ></DangerComponent>
    );
  } else if (
    state.sensorTypesLoadState === sensortypesLoadedStates.loading ||
    state.apiState === apiStates.waiting
  ) {
    return <Spinner></Spinner>;
  }

  return (
    <>
      <PopUpC
        activate={state.showHelp}
        deactivatepopup={onInfoClose}
        content={<CudsConfigurationHelp />}
      />
      <div className="Configuration">
        <div className="Titlewithinfo">
          <div className="Title_DescripctionConf ">Caudalímetro:</div>
          <div className="icoOutputconf">
            <div className="icoOutputconf2">
              <QuestionSvg
                className="ButtonProgram Info"
                onClick={(e) => onInfoClick()}
              />
            </div>
          </div>
        </div>
        {getDropDownCuds()}

        <div className="Title_Descripction">Descripción:</div>
        <Input
          type="text"
          name="cud_descr"
          id="cud_descr"
          placeholder={"Descripción..."}
          className="InputForm descriptionInput"
          onChange={(e) => onDescriptionChange(e.target.value)}
          value={state.selectedSensor?.description || ""}
          disabled={false}
        />

        <TerminalDeviceSensorCommonConfiguration
          sensor={state.selectedSensor}
          onChangeActiveState={onActivedChange}
          onReportIntervalSelected={onChangeReportInterval}
        />

        {state.selectedSensor?.active && (
          <>
            <div className="TabOutputConf"></div>
            <div className="titleModesActivationConf">Datos calibrado</div>
            {(isInvalidCalibrationRawValue(state) ||
              isInvalidCalibrationUnitValue(state)) && (
              <DangerComponent
                message={"Los datos del calibrado no son correctos"}
              />
            )}
            <TerminalDeviceCalibrationManualOutputActivation
              terminal={terminal}
              terminalDevice={terminalDevice}
              sensor={state.selectedSensor}
              calibrationRawValue={
                state.selectedSensor?.measurement?.calibrationRawValue
              }
              calibrationUnitValue={
                state.selectedSensor?.measurement.calibrationUnitValue
              }
              onCalibrationChange={onCalibrationChange}
              unitsTitle={"Litros"}
            />

            <TerminalDeviceSensorStats
              sensor={state.selectedSensor}
            ></TerminalDeviceSensorStats>
          </>
        )}
      </div>
    </>
  );
};
