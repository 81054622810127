import React, { useCallback, useEffect, useState, useRef } from "react";
import { Infoprograms } from "./../components/Info/Infoprograms";
import { Spinner } from "../../../../Airframe/Spinner/Spinner";
import { PopUpC } from "../../../../Airframe/PopUp/PopUp";
import PropTypes from "prop-types";
import { TerminalDeviceSubprogram } from "../../../../../../classes/TerminalDevice/Program/Subprogram/TerminalDeviceSubprogram";
import { TerminalDeviceProgram } from "../../../../../../classes/TerminalDevice/Program/TerminalDeviceProgram";

import { checkValidProgramDatetime } from "../components/CheckVarlidProgramDatetime";
import { ProgramUI } from "./ProgramUI";
import { ProgramationOutputs } from "./Subprograms/ProgramationOutputs";

export const Program = (props) => {
  const defaultInitHour = "12";
  const defaultInitMinute = "00";

  const {
    actionOnSaveProgram,
    updateProgram,
    program,
    outputs,
    loading,
    menuSubrutineActivef,
    ListPrograms,
    goToProgram,
  } = props;

  const typeofCard = {
    subprogram: "subprogram",
    infraprogram: "infraprogram",
  };

  useEffect(() => {
    //Forzar reinicio de menus en programas para mostrar programas y no subprogramas o infraprogramas.

    returnToUIViewCreateSubprogram();
  }, [goToProgram]);

  const getEmptyProgramObject = useCallback(() => {
    return TerminalDeviceProgram.parseFromObjectDto({
      name: undefined,
      hour: defaultInitHour,
      minute: defaultInitMinute,
      days: undefined,
      subprograms: undefined,
      new: true,
      modified: true,
      removed: false,
      index: 0,
    });
  }, []);

  const errorStateMachineInitValue = {
    daysSelected: false,
    subprogramsSize: false,
    datetime: false,
  };

  const [viewUIInputInfraProgram, setviewUIInputInfraProgram] = useState(false);
  const [errorStateMachine, setErrorStateMachine] = useState(
    errorStateMachineInitValue
  );
  const [alldata, setalldata] = useState(program || getEmptyProgramObject());
  const [SubroutinesList, setSubroutinesList] = useState(
    alldata?.subprograms || []
  );
  const [showButtonSave, setshowButtonSave] = useState(false);
  const errorDatetime = useRef(null);
  const errorDaysSelected = useRef(null);
  const errorSubprogramsSize = useRef(null);
  const [menuSubrutineActive, setmenuSubrutineActive] = useState(
    menuSubrutineActivef
  );

  const [subroutinedataEdit, setsubroutinedataEdit] = useState(undefined);
  const [isMenuAdvanced, setisMenuAdvanced] = useState(false);

  const [messagenotedit, setmessagenotedit] = useState(false);
  const [reorderedError, setReorderedError] = useState(false);
  const [focusOnSubprogramIndex, setFocusOnSubprogramIndex] = useState(-1);

  const [PermisedEditionProgram, setPermisedEditionProgram] = useState(true);
  const RefreshSubprogramsAndShowButtonSave = useCallback(() => {
    setshowButtonSave(true);
  }, []);

  const [showpopupInfosubprograms, setshowpopupInfosubprograms] = useState(
    false
  );
  const [showpopupInfoprograms, setshowpopupInfoprograms] = useState(false);

  //on click button advanced:
  const ButtonAdvancedClick = useCallback(
    (selectedOutputs, hours, minutes, closeView) => {
      actionSaveSubroutine(selectedOutputs, hours, minutes, closeView);
    },
    [updateProgram, subroutinedataEdit, alldata]
  );
  //#region  SUBROUTINES ACTIONS
  //Guardando subrutina y guardando ficha en programas:

  const actionSaveSubroutine = useCallback(
    (terminalDeviceSubprogram, closeView,customOutputsActivations) => {
      setshowButtonSave(true);
      setmessagenotedit(false);
      let localErrorStateMachine = errorStateMachine;
      const list = [...SubroutinesList];
      let index = SubroutinesList?.length || 0;
      let modified = true;
      let subprogram = undefined;
      if (!isNaN(subroutinedataEdit?.index)) {
        //index = subroutinedataEdit?.index-1;
        index = subroutinedataEdit?.index;
        if (SubroutinesList.length > index) {
          if (
            subroutinedataEdit.outputs !== terminalDeviceSubprogram.outputs ||
            subroutinedataEdit.activeTime !==
              terminalDeviceSubprogram.activeTime ||
            subroutinedataEdit.initDelay !==
              terminalDeviceSubprogram.initDelay ||
            subroutinedataEdit.endDelay !== terminalDeviceSubprogram.endDelay
          ) {
            const prevActiveTime = subroutinedataEdit.activeTime;
            list[index] = TerminalDeviceSubprogram.parseFromObjectDto({
              ...list[index],
              outputs: terminalDeviceSubprogram.outputs,
              activeTime: terminalDeviceSubprogram.activeTime,
              initDelay: terminalDeviceSubprogram.initDelay,
              endDelay: terminalDeviceSubprogram.endDelay,
              modified: true,
              notified: false,
            });
            subprogram = list[index];
            if (
              !checkValidProgramDatetime(alldata.hour, alldata.minute, list)
            ) {
              list[index].activeTime = prevActiveTime;
              localErrorStateMachine = {
                ...localErrorStateMachine,
                datetime: true,
              };
            } else {
              localErrorStateMachine = {
                ...localErrorStateMachine,
                datetime: false,
              };
            }
          } else {
            console.log("Error modificado");
            modified = false;
          }
        }
      } else {
        terminalDeviceSubprogram.name = `S${index + 1}`;
        terminalDeviceSubprogram.index = index;
        terminalDeviceSubprogram.new = true;
        subprogram = terminalDeviceSubprogram;
        list.push(terminalDeviceSubprogram);
        if (!checkValidProgramDatetime(alldata.hour, alldata.minute, list)) {
          // console.log("Error 24 horas");
          list.pop();
          localErrorStateMachine = {
            ...localErrorStateMachine,
            datetime: true,
          };
        } else {
          setshowButtonSave(true);
          // console.log("no error 24 horas");
          localErrorStateMachine = {
            ...localErrorStateMachine,
            datetime: false,
          };
        }
      }
      if (modified) {
        // Creamos la array con las outputTasks que se crearían.
        let i = 0;
        let offset = 1;
        const outputTasks = [];
        while (subprogram.outputs >= offset) {
          if (subprogram.outputs & offset) {
            outputTasks.push({
              output: i + 1,
              notified: false,
              deleted_by_user: false,
            });
          }
          offset = 1 << ++i;
        }
        subprogram.outputTasks = outputTasks;

//Añadimos customActivationTypes a subrutine
//subprogram.customActivationTypes=customOutputsActivations;
//---

        // Para Infraprogramas
        console.log(subprogram);
        setsubroutinedataEdit(subprogram);
        setSubroutinesList(list);
        if (closeView === false) {
          //boton avanzado no debe mostrar icono programa en edición:
          alldata.modified = false;
        } else {
          alldata.modified = true;
        }

        alldata.subprograms = list;
        updateProgram(alldata);
      }

      if (closeView !== undefined && !localErrorStateMachine.datetime) {
        setmenuSubrutineActive(closeView ? false : true);
      } else {
        setmenuSubrutineActive(false);
      }
      localErrorStateMachine = {
        ...localErrorStateMachine,
        subprogramsSize: false,
      };

      setErrorStateMachine(localErrorStateMachine);
      setFocusOnSubprogramIndex(index);
    },
    [
      updateProgram,
      subroutinedataEdit,
      alldata,
      errorStateMachine,
      SubroutinesList,
    ]
  );

  //Check if time exceded
  useEffect(() => {
    //Fix si existen subprogramas comprueba tiempo si nos no.
    if (alldata?.subprograms.length > 0 && !errorStateMachine.datetime) {
      check24hError();
    }

    //}, [alldata]);
    //}, [program?.id,alldata]);
  }, [alldata?.subprograms]);

  const check24hError = useCallback(() => {
    const isValid24h = checkValidProgramDatetime(
      alldata?.hour,
      alldata?.minute,
      alldata?.subprograms
    );

    setErrorStateMachine((errorStateMachine) => {
      return { ...errorStateMachine, datetime: !isValid24h };
    });
  }, []);

  useEffect(() => {
    if (errorStateMachine.datetime && errorDatetime.current) {
      errorDatetime.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    } else if (errorStateMachine.daysSelected && errorDaysSelected.current) {
      errorDaysSelected.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    } else if (
      errorStateMachine.subprogramsSize &&
      errorSubprogramsSize.current
    ) {
      errorSubprogramsSize.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  }, [errorStateMachine]);

  //#region CRUD PROGRAMA: EDIT / CANCEL / DELETE && INFO
  const onSubprogramModified = useCallback(
    (subprogram) => {
      const subprogramIndexOf = program.subprograms.indexOf(subprogram);
      program.modified = true;
      const newSubprogram = TerminalDeviceSubprogram.parseFromObjectDto({
        ...subprogram,
      });
      if (subprogramIndexOf >= 0) {
        program.subprograms[subprogramIndexOf] = newSubprogram;
      } else {
        program.subprograms.push(newSubprogram);
      }
      setsubroutinedataEdit(newSubprogram);
      updateProgram(program);
    },
    [program, updateProgram]
  );

  const actionOnSave = useCallback(() => {
    let filteredSubRoutines = SubroutinesList.filter(
      (subprogram) => !subprogram.deleted_by_user
    );
    if (filteredSubRoutines.length > 0) {
      const program = TerminalDeviceProgram.parseFromObjectDto({
        ...alldata,
        subprograms: filteredSubRoutines,
      });
      actionOnSaveProgram(program);
    } else {
      setErrorStateMachine({ ...errorStateMachine, subprogramsSize: true });
    }
  }, [alldata, SubroutinesList, errorStateMachine, actionOnSaveProgram]);

  //---Se elimina una subrutina.
  const actionOnDeleteSubroutine = useCallback(
    (subroutine) => {
      if (subroutine !== undefined) {
        const list = [...SubroutinesList];

        if (subroutine?.notified) {
          /*const newSubroutine = { ...subroutine, deleted_by_user: true };
          list[newSubroutine.index] = newSubroutine;
          setSubroutinesList(list);

          program.subprograms = list;
          updateProgram(program); */
        } else {
          let deleted = list.splice(subroutine.index, 1);

          if (deleted.length > 0) {
            let offset = subroutine.index;
            for (let i = offset; i < list?.length; i++) {
              let subprogram = list[i];
              subprogram.index = i;
              subprogram.name = `S${i + 1}`;
            }
            setSubroutinesList(list);
            alldata.subprograms = list;
            updateProgram(alldata);
            RefreshSubprogramsAndShowButtonSave();
          }
          check24hError();
        }
      }
    },
    [updateProgram, alldata, SubroutinesList]
  );
  //POPUP INFO
  //OPEN
  const OpenInfo = useCallback((type) => {
    if (type === "Subprogramas") {
      setshowpopupInfosubprograms(true);
    } else {
      setshowpopupInfoprograms(true);
    }
  }, []);

  //CLOSE
  const closeInfo = useCallback(() => {
    setshowpopupInfosubprograms(false);
    setshowpopupInfoprograms(false);
  }, []);

  //---Se edita una subrutina
  const actionOnEdit = useCallback((subroutine) => {
    const [hour, minutes] = subroutine.activeTime.split(":");
    //Abrimos la vista de subrutinas:
    setmenuSubrutineActive(true);
    subroutine.hour = hour;
    subroutine.minute = minutes;
    console.log(subroutine);
    setsubroutinedataEdit(subroutine);
  }, []);

  /**
   * Abre la UI para insertar los datos de una subrutina.
   */
  const ShowUIAddSubroutines = useCallback(() => {
    setmessagenotedit(false);
    //Choose day
    if (alldata.days === undefined || alldata.days === 0) {
      setErrorStateMachine({ ...errorStateMachine, daysSelected: true });
    } else {
      setErrorStateMachine({ ...errorStateMachine, daysSelected: false });
      setsubroutinedataEdit(undefined);
      setmenuSubrutineActive(true);
    }
  }, [
    alldata,
    errorStateMachine,
    menuSubrutineActive,
    subroutinedataEdit,
    actionOnEdit,
  ]);

  //#endregion
  //#region  INFRAPROGRAMS VIEWS FUNCTIONS
  //Regresa a la vista principal de programas
  const returnToUIViewCreateSubprogram = useCallback(() => {
    setviewUIInputInfraProgram(false);
    setmenuSubrutineActive(false);
    setisMenuAdvanced(false);
    check24hError();
  }, []);
  //#region  SUBROUTINE VIEWS FUNCTIONS

  const Regresando = useCallback(() => {
    setmenuSubrutineActive(false);
    setviewUIInputInfraProgram(false);
    setisMenuAdvanced(false);
  }, [menuSubrutineActive, viewUIInputInfraProgram, isMenuAdvanced]);

  const isSubprogramNotified = useCallback((subprogram) => {
    let allNotified = false;
    if (subprogram?.outputTasks && subprogram.outputTasks instanceof Array) {
      let foundNotNotified = false;
      for (let i = 0; i < subprogram.outputTasks.length; i++) {
        const outputTask = subprogram.outputTasks[i];
        if (!outputTask?.notified) {
          foundNotNotified = true;
          break;
        }
      }

      if (!foundNotNotified) {
        allNotified = true;
      }
    }
    return allNotified;
  }, []);

  /**
   * Metodo utilizado para reorganizar la lista de subprogramas.
   * Este método se le pasa al Drag&Drop.
   */
  const Programsreorganizeds = useCallback(
    (subprogramsOrder) => {
      if (subprogramsOrder instanceof Array) {
        if (
          subprogramsOrder.length === SubroutinesList.length &&
          SubroutinesList.length > 1
        ) {
          let foundNotified = undefined;
          let foundNotNofied = undefined;
          let error = false;
          for (let i = 0; i < subprogramsOrder.length; i++) {
            const newIndex = i;
            const subp = SubroutinesList[subprogramsOrder[i]];
            if (isSubprogramNotified(subp)) {
              foundNotified = newIndex;
            } else {
              foundNotNofied = newIndex;
            }

            if (
              foundNotified !== undefined &&
              foundNotNofied !== undefined &&
              foundNotified > foundNotNofied
            ) {
              error = true;
            }
          }
          if (!error) {
            const newSortedList = subprogramsOrder.map(
              (oldProgramIndex, newIndex) => {
                let subP = SubroutinesList[oldProgramIndex];
                subP.index = newIndex;
                subP.name = `S${newIndex + 1}`;
                return subP;
              }
            );
            RefreshSubprogramsAndShowButtonSave();
            setReorderedError(false);
            setSubroutinesList(newSortedList);
          } else {
            setReorderedError(true);
            setSubroutinesList([...SubroutinesList]);
          }
        } else {
          console.log("ESTADO INDESEADO");
        }
      }
    },
    [SubroutinesList, isSubprogramNotified]
  );

  //#endregion

  //Reiniciando:
  useEffect(() => {
    let dataprogram = program || getEmptyProgramObject();
    setalldata(dataprogram);
    setSubroutinesList(dataprogram.subprograms || []);
    //setmenuSubrutineActive(false);
    setPermisedEditionProgram(true);

    if (dataprogram.subprograms !== undefined) {
      let allNotified = true;
      dataprogram.subprograms.forEach((subprogram) => {
        for (let i = 0; i < subprogram?.outputTasks?.length; i++) {
          const notified = subprogram?.outputTasks[i]?.notified;
          if (notified !== true) {
            allNotified = false;
            break;
          }
        }
        if (allNotified === true) {
          //Desactivando edición
          setPermisedEditionProgram(false);
        }
      });
    }

    // Todo programa guardado no puede ser modificado (hora y dias).
    if (!dataprogram.new && dataprogram?.id !== undefined) {
      setPermisedEditionProgram(false);
    }
    //al cambiar de programa vuelve al menu principal de programas:
    setmenuSubrutineActive(false);
    setviewUIInputInfraProgram(false);
  }, [program, getEmptyProgramObject]);

  if (loading) {
    return (
      <div className="MessageServerOut">
        <div className="TitleCard">Conectando con el servidor...</div>
        <div className={"Loadspinner2"}>
          <Spinner size={"50px"} />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="BoxDialogFather" hidden={true}></div>

      {showpopupInfoprograms ? (
        <PopUpC
          activate={showpopupInfoprograms}
          deactivatepopup={closeInfo}
          content={<Infoprograms />}
        />
      ) : (
        <></>
      )}

      {menuSubrutineActive === false && viewUIInputInfraProgram === false && (
        <ProgramUI
          {...props}
          SubroutinesList={SubroutinesList}
          errorStateMachine={errorStateMachine}
          alldata={alldata}
          reorderedError={reorderedError}
          focusOnSubprogramIndex={focusOnSubprogramIndex}
          PermisedEditionProgram={PermisedEditionProgram}
          messagenotedit={messagenotedit}
          ListPrograms={ListPrograms}
          program={alldata}
          updateProgram={updateProgram}
          ShowUIAddSubroutines={ShowUIAddSubroutines}
          actionOnSave={actionOnSave}
          actionOnDeleteSubroutine={actionOnDeleteSubroutine}
          actionOnEdit={actionOnEdit}
          InfoC={OpenInfo}
          Programsreorganizeds={Programsreorganizeds}
          typeofCard={typeofCard.subprogram}
          showButtonSave={showButtonSave}
        />
      )}

      {/* UI ADD SUBPROGRAMS: */}
      {menuSubrutineActive && (
        <>
          <ProgramationOutputs
            viewUIInputInfraProgram={viewUIInputInfraProgram}
            program={alldata}
            outputs={outputs}
            subroutine={subroutinedataEdit}
            enabled={true}
            isMenuAdvanced={isMenuAdvanced}
            actionSaveSubroutine={actionSaveSubroutine}
            Cancel={Regresando}
            typeofCard={typeofCard}
            ListPrograms={ListPrograms}
            Programsreorganizeds={Programsreorganizeds}
            returnToUIViewCreateSubprogram={returnToUIViewCreateSubprogram}
            onSubprogramModified={onSubprogramModified}
            ButtonAdvancedClick={ButtonAdvancedClick}
            ShowButtonSavePrograms={RefreshSubprogramsAndShowButtonSave}
          />
        </>
      )}
    </>
  );
};
Program.propTypes = {
  actionOnCancelProgram: PropTypes.func.isRequired,
  actionOnSaveProgram: PropTypes.func.isRequired,
  program: PropTypes.object,
  outputs: PropTypes.array.isRequired,
};
