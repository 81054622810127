import { useSubscription } from "mqtt-react-hooks";
import React, { useEffect } from "react";
import { useUser } from "../../../context/user-context";
import { projectsConstants } from "../../../_constants/projects.constants";
import { MqttMessageProcessor } from "./MqttMessageProcessor";

export const GetTerminalsOnlineMQTT = (props) => {
  const { user } = useUser();
  const { message: loraOnlineTerminalMessages } = useSubscription(
    `${process.env.REACT_APP_TECO_MQTT_CLOUD_ID}/${user.username}/${projectsConstants.lora_outputs.id}/notification/${projectsConstants.lora_outputs.actions.getonlineterminals}`
  );

  useEffect(() => {
    if (props.processLoraMsg && loraOnlineTerminalMessages) {
      const msg = {
        topic: loraOnlineTerminalMessages.topic,
        message: JSON.parse(loraOnlineTerminalMessages.message),
      }
      props.processLoraMsg(
        msg.message.id,
        msg.message
      );
    }
  }, [loraOnlineTerminalMessages]);

  return (
    <MqttMessageProcessor
      topic={projectsConstants.master_outputs.actions.getonlineterminals}
      processMsg={props.processMsg}
    ></MqttMessageProcessor>
  );
};
