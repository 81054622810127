import React, { useState, useEffect, useMemo, useCallback } from "react";
import { sensorService } from "../../../../../../_services/sensors.service";
import { InputGroupwithid } from "../../../../Airframe/InputGroup/InputGroupwithid";
import { Spinner } from "../../../../Airframe/Spinner/Spinner";

export const TerminalDeviceSensorCalibrationInfo = (props) => {
  const { terminalDevice, terminalDeviceSensor, inputUnit } = props;

  const states = useMemo(() => {
    return {
      IDLE: "IDLE",
      LOAD_LAST_CALIBRATION: "LOAD_LAST_CALIBRATION",
      LOADING_LAST_CALIBRATION: "LOADING_LAST_CALIBRATION",
      LOADED_LAST_CALIBRATION: "LOADED_LAST_CALIBRATION",

      ERROR_LOAD_LAST_CALIBRATION: "ERROR_LOAD_LAST_CALIBRATION",
    };
  }, []);
  const initState = useMemo(() => { return {
    state: states.LOAD_LAST_CALIBRATION,
    calibration: undefined,
  }}, [states.LOAD_LAST_CALIBRATION]);
  const [state, setState] = useState(initState)

  const prefix = "terminal-device-sensor-calibration-info-row";

  const loadLastCalibration = useCallback(() => {
    if (
      terminalDevice?.terminal !== undefined &&
      terminalDevice?.id !== undefined &&
      terminalDeviceSensor?.sensorId?.id !== undefined &&
      terminalDeviceSensor?.sensorIndex !== undefined
    ) {
      setState((prev) => {
        return { ...prev, state: states.LOADING_LAST_CALIBRATION };
      });

      sensorService
        .getLastTerminalDeviceSensorCalibration(
          terminalDevice.terminal,
          terminalDevice.id,
          terminalDeviceSensor.sensorId.id,
          terminalDeviceSensor.sensorIndex
        )
        .then(
          (calibrationDto) => {
            setState((prev) => {
              return {
                ...prev,
                state: states.LOADED_LAST_CALIBRATION,
                calibration: calibrationDto?.tsm_sensor_calibration,
              };
            });
          },
          (error) => {
            setState((prev) => {
              return {
                ...prev,
                state: states.ERROR_LOAD_LAST_CALIBRATION,
                calibration: undefined,
              };
            });
          }
        );
    }
  }, [
    states.ERROR_LOAD_LAST_CALIBRATION,
    states.LOADED_LAST_CALIBRATION,
    states.LOADING_LAST_CALIBRATION,
    terminalDevice?.id,
    terminalDevice?.terminal,
    terminalDeviceSensor?.sensorId?.id,
    terminalDeviceSensor?.sensorIndex,
  ]);

  useEffect(() => {
    setState(initState)
  }, [initState, terminalDeviceSensor])

  useEffect(() => {
    switch (state.state) {
      case states.LOAD_LAST_CALIBRATION:
        loadLastCalibration();
        break;
      case states.IDLE:
      case states.LOADING_LAST_CALIBRATION:
      case states.LOADED_LAST_CALIBRATION:
      default:
        break;
    }
  }, [
    loadLastCalibration,
    state,
    states.IDLE,
    states.LOADED_LAST_CALIBRATION,
    states.LOADING_LAST_CALIBRATION,
    states.LOAD_LAST_CALIBRATION,
  ]);

  const getLabelInputComponent = useCallback(
    (label, value, index, unit) => {
      return (
        <div key={index} className="RowUISelectTypes">
          <div className="DropdownF_SelectType dropdownPanelTypeBig">
            <InputGroupwithid
              type="number"
              placeholder={`Patrón...`}
              value={label}
              symbol={unit}
              disabled={true}
            ></InputGroupwithid>
          </div>
          <div className="DropdownF_SelectType dropdownPanelTypeBig">
            <InputGroupwithid
              foco={false}
              type="number"
              placeholder={""}
              value={value}
              tabIndex={-1}
              id={`${prefix}-${index}`}
              symbol={inputUnit || "mV"}
              disabled={true}
            ></InputGroupwithid>
          </div>
        </div>
      );
    },
    []
  );

  const getTemperatureLabelInputComponent = useCallback(value => {
    return (
      <div className="RowUISelectTypes">
        <div className="DropdownF_SelectType dropdownPanelTypeBig">
          <InputGroupwithid
            type="text"
            value={"Temperatura"}
            symbol={"ºC"}
            disabled={true}
          ></InputGroupwithid>
        </div>
        <div className="DropdownF_SelectType dropdownPanelTypeBig">
          <InputGroupwithid
            type="number"
            value={value}
            tabIndex={-1}
            id={`ph-cal-temp-input-info`}
            symbol="ºC"
            disabled={true}
          ></InputGroupwithid>
        </div>
      </div>
    );
  }, [])

  const getPointValuesCalibrationComponent = useCallback(() => {
    if(state.calibration.values instanceof Array 
      && state.calibration.points instanceof Array
      && terminalDeviceSensor?.sensorId?.measurements[0]){
      const unit = terminalDeviceSensor.sensorId.measurements[0].measurementUnit;
      return state.calibration.points.map((point, index) =>  getLabelInputComponent(point, state.calibration.values[index], index, unit))
    }
    return <>ERROR</>
  },[getLabelInputComponent, state.calibration?.points, state.calibration?.values, terminalDeviceSensor?.sensorId?.measurements])

  const getTemperatureCalibrationComponent = useCallback(() => {
    const tempValue = state.calibration?.temperature || "";
    return getTemperatureLabelInputComponent(tempValue)
  }, [getTemperatureLabelInputComponent, state.calibration?.temperature])

  return (
    <div className="mainContainer">
      {state.state === states.LOADING_LAST_CALIBRATION && <Spinner></Spinner>}
      {state.state !== states.LOADING_LAST_CALIBRATION && state.calibration && !state.calibration.values && !state.calibration.points && (
        <div>
          <div>
            Último calibrado (
            {state.calibration.receivedAt &&
              new Date(
                Date.parse(state.calibration.receivedAt)
              ).toLocaleString()}
            ):
          </div>
          <div>
            % Respecto Ácido: {state.calibration.probe_acid_ideal || "ERROR"} |
            % Respecto Base ideal:{" "}
            {state.calibration.probe_base_ideal || "ERROR"}
          </div>
        </div>
      )}
      {state.state !== states.LOADING_LAST_CALIBRATION && state.calibration && state.calibration.values && state.calibration.points ? (
        <div>
          <div>
            Último calibrado (
            {state.calibration.receivedAt &&
              new Date(
                Date.parse(state.calibration.receivedAt)
              ).toLocaleString()}
            ):
          </div>
          <div>
            {getPointValuesCalibrationComponent()}
            {getTemperatureCalibrationComponent()}
          </div>
        </div>
      ) : <div>Sin calibrado previo.</div>}
    </div>
  );
};
