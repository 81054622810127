import { TerminalDeviceSubprogram } from "./Subprogram/TerminalDeviceSubprogram";

export class TerminalDeviceProgram {
    id;
	terminalDeviceId;
	name;
	triggerType;
	startHour;
	days;
	//private List<TerminalDeviceSubprogramDTO> 
    subprograms;
	active;
	subprogramsDelay;

	deleted_by_user;
	deleted_by_user_at;

    // USO front-end, no forma parte del DTO.
    index;
    hour;
    minute;
    new;
    modified;
    removed;

    static parseFromPlainDto(dtoText){
        const plainObject = JSON.parse(dtoText);
        return TerminalDeviceProgram.parseOutputDtoFromObject(plainObject);
    }

    static parseFromObjectDto(dtoObject){
        if(dtoObject){
            const terminalDeviceProgram = Object.setPrototypeOf(dtoObject, TerminalDeviceProgram.prototype);

            if(dtoObject.subprograms instanceof Array){
				terminalDeviceProgram.subprograms = dtoObject.subprograms.map(subprogram => TerminalDeviceSubprogram.parseFromObjectDto(subprogram));
			}else{
				terminalDeviceProgram.subprograms = [];
			}

            return terminalDeviceProgram;
        }
        return dtoObject
    }

    getStartDate(){
        let startDate = new Date();
        startDate.setHours(this.hour || 12);
        startDate.setMinutes(this?.minute || 0);
        startDate.setSeconds(0);

        return startDate;
    }

    mustRunToday() {
        const now = new Date();
        let todayOffset = now.getDay();
        const today = 1 << todayOffset;

        return (today & this.days) > 0;
    }

    getName() {
        return this.name || (this.index !== undefined && `Programa ${this.index + 1}`) || undefined;
    }
}