export const getToken = () => {
  let user = JSON.parse(localStorage.getItem("user"));

  if (user && user.token) {
    return user.token;
  } else return null;
};

export const setToken = (token) => {
  let user = JSON.parse(localStorage.getItem("user"));

  if (user && user.token) {
    user.token = token;
    localStorage.setItem("user", JSON.stringify(user));
  }
};

export const deleteToken = () => {
  setToken(null);
};

export const authHeader = () => {
  // return authorization header with jwt token
  let token = getToken();

  if (token) {
    return { Authorization: token };
  } else {
    return {};
  }
};
