import React, { useCallback, useMemo, useEffect, useState } from "react";
import axios from "axios";
import { Authservice } from "./Authservice";
import { projectsConstants } from "../_constants/projects.constants";
import { usePublisher } from "../context/publish-context";
import { useUser } from "../context/user-context";
import { useSubscription } from "mqtt-react-hooks";
import { useTerminalDevice } from "../context/terminal-device-context";
import { useApp } from "../context/app-context";

const AtomzierContext = React.createContext();

export const atomizerValidatonErrorCodes = {
  notSelectedOutputs: "NOT_SELECTED_OUTPUTS",
  notSelectedDays: "NOT_SELECTED_DAYS",
  invalidDosificationTimeSeconds: "INVALID_DOSIFICATION_TIME_SECONDS",
  invalidReportTime: "INVALID_REPORT_TIME",
  invalidPauseTime: "INVALID_PAUSE_TIME",
  invalidStartTimestamp: "INVALID_START_TIMESTAMP",
  invalidEndTimestamp: "INVALID_END_TIMESTAMP",
};

export const AtomizerProvider = (props) => {
  const { updateTerminalDevice } = useApp();
  const { user } = useUser();
  const { terminalDevice } = useTerminalDevice();
  const { message: syncDeviceConfigMessage } = useSubscription(
    `${process.env.REACT_APP_TECO_MQTT_CLOUD_ID}/${user.username}/+/notification/${projectsConstants.lora_outputs.actions.syncdeviceconfig}`
  );
  const { message: dynamicPayloadMessage } = useSubscription(
    `${process.env.REACT_APP_TECO_MQTT_CLOUD_ID}/${user.username}/+/notification/${projectsConstants.lora_outputs.actions.dynamic_payload}`
  );
  const { publish } = usePublisher();

  //const notInitizalizedMessage = "NOT_INIT_MESSAGE";
  const [lastMessages, setLastMessages] = useState({
    syncDeviceConfigMessage: undefined,
    dynamicPayloadMessage: undefined,
  });

  const get = useCallback((terminalId, terminalDeviceId) => {
    return axios
      .get(
        `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/atomizer/`,
        Authservice.getRequestOptions("GET")
      )
      .then(Authservice.handleResponse)
      .then((atomizerRcv) => {
        return atomizerRcv;
      });
  }, []);

  useEffect(() => {
    if (lastMessages.syncDeviceConfigMessage !== syncDeviceConfigMessage) {
      setLastMessages({ ...lastMessages, syncDeviceConfigMessage });

      const syncDeviceConfigMessageObject =
        syncDeviceConfigMessage === undefined
          ? undefined
          : {
              topic: syncDeviceConfigMessage.topic,
              message: JSON.parse(syncDeviceConfigMessage.message),
            };

      const targetId = syncDeviceConfigMessageObject?.message?.data?.target_id;
      if (targetId === terminalDevice.id) {
        get(terminalDevice.terminal, terminalDevice.id).then(
          (atomizer) => {
            terminalDevice.atomizer = atomizer;
            updateTerminalDevice(terminalDevice);
          },
          (error) => console.log(error)
        );
      }
    }
  }, [
    get,
    lastMessages,
    syncDeviceConfigMessage,
    terminalDevice,
    updateTerminalDevice,
  ]);

  useEffect(() => {
    if (lastMessages.dynamicPayloadMessage !== dynamicPayloadMessage) {
      setLastMessages({ ...lastMessages, dynamicPayloadMessage });

      const dynamicPayloadMessageObject =
        dynamicPayloadMessage === undefined
          ? undefined
          : {
              topic: dynamicPayloadMessage.topic,
              message: JSON.parse(dynamicPayloadMessage.message),
            };

      const targetId = dynamicPayloadMessageObject?.message?.data?.target_id;
      if (targetId === terminalDevice?.id) {
        const {
          sensorValues,
          outputValues,
        } = dynamicPayloadMessageObject?.message?.data;
        const terminalDeviceSensors = terminalDevice.sensors;
        const terminalDeviceOutputs = terminalDevice.outputs;
        let shouldUpdate = false;

        if (
          sensorValues instanceof Array &&
          terminalDeviceSensors instanceof Array
        ) {
          sensorValues.forEach((sensorValue) => {
            const terminalDeviceSensor = terminalDeviceSensors.find(
              (terminalDeviceSensor) =>
                terminalDeviceSensor.sensorIndex === sensorValue?.index &&
                terminalDeviceSensor?.sensorId?.physicalCommunicationType ===
                  sensorValue?.physicalCommunicationType
            );

            if (terminalDeviceSensor !== undefined) {
              terminalDeviceSensor.lastValue = sensorValue?.value;
              terminalDeviceSensor.lastValueAt = sensorValue?.receivedAt;

              shouldUpdate = true;
            }
          });
        }

        if (
          outputValues instanceof Array &&
          terminalDeviceOutputs instanceof Array
        ) {
          outputValues.forEach((outputValue) => {
            const terminalDeviceOutput = terminalDeviceOutputs.find(
              (terminalDeviceOutput) =>
                terminalDeviceOutput.output === outputValue?.index + 1
            );

            if (terminalDeviceOutput) {
              terminalDeviceOutput.currentState =
                outputValue?.value > 0
                  ? projectsConstants.global.states.output_active
                  : projectsConstants.global.states.output_deactivated;
              terminalDeviceOutput.lastCurrentStateUpdate =
                outputValue?.receivedAt;
              shouldUpdate = true;
            }
          });
        }

        if (shouldUpdate) {
          updateTerminalDevice(terminalDevice);
        }
      }
    }
  }, [dynamicPayloadMessage, lastMessages, terminalDevice, updateTerminalDevice]);

  const validateAtomizer = useCallback((atomizer) => {
    let errorCodes = [];
    if (atomizer.dosificationOutputs <= 0) {
      errorCodes.push(atomizerValidatonErrorCodes.notSelectedOutputs);
    }

    if (
      atomizer.dosificationPremise ===
      projectsConstants.lora_outputs.dosificationPremises.timeDiscrimination
    ) {
      if (atomizer.days <= 0) {
        errorCodes.push(atomizerValidatonErrorCodes.notSelectedDays);
      }

      if (atomizer.startTimestamp === undefined) {
        errorCodes.push(atomizerValidatonErrorCodes.invalidStartTimestamp);
      }

      if (atomizer.endTimestamp === undefined) {
        errorCodes.push(atomizerValidatonErrorCodes.invalidEndTimestamp);
      }
    }

    if (atomizer.dosificationTimeSeconds <= 0) {
      errorCodes.push(
        atomizerValidatonErrorCodes.invalidDosificationTimeSeconds
      );
    }

    if (atomizer.reportIntervalSeconds < 120) {
      errorCodes.push(atomizerValidatonErrorCodes.invalidReportTime);
    }

    if (atomizer.pauseSeconds <= 0) {
      errorCodes.push(atomizerValidatonErrorCodes.invalidPauseTime);
    }

    return errorCodes;
  }, []);

  const save = useCallback((terminalId, terminalDeviceId, atomizer) => {
    return axios
      .post(
        `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/atomizer/`,
        atomizer,
        Authservice.getRequestOptions("POST")
      )
      .then(Authservice.handleResponse)
      .then((atomizerRcv) => {
        return atomizerRcv;
      });
  }, []);

  const update = useCallback((terminalId, terminalDeviceId, atomizer) => {
    return axios
      .put(
        `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/atomizer/`,
        atomizer,
        Authservice.getRequestOptions("PUT")
      )
      .then(Authservice.handleResponse)
      .then((atomizerRcv) => {
        return atomizerRcv;
      });
  }, []);

  const publishLoraOutputs = useCallback(
    (topic, message) => {
      publish(topic, message, projectsConstants.lora_outputs.id);
    },
    [publish]
  );

  const publishSyncDeviceConfig = useCallback(
    (terminalDeviceId) => {
      publishLoraOutputs(
        projectsConstants.lora_outputs.actions.syncdeviceconfig,
        {
          type: 0,
          data: {
            target_id: terminalDeviceId,
          },
        }
      );
    },
    [publishLoraOutputs]
  );

  const value = useMemo(() => {
    return {
      get,
      save,
      update,
      validateAtomizer,
      publishSyncDeviceConfig,
    };
  }, [get, save, update, validateAtomizer, publishSyncDeviceConfig]);

  return <AtomzierContext.Provider value={value} {...props} />;
};

export const useAtomizer = () => {
  const context = React.useContext(AtomzierContext);
  if (!context) {
    throw new Error(
      "useAtomizers debe estar dentro del proveedor AtomizerContext"
    );
  }
  return context;
};
