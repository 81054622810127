import { projectsConstants } from "../../../../../_constants/projects.constants";
import { isTerminalDeviceMMSValidFor, isTerminalDeviceSIOValidFor } from "../../VersionChecker";

const isTerminalDeviceMMSValidForSetworkmodeV2 = terminalDevice => isTerminalDeviceMMSValidFor(
  terminalDevice,
  projectsConstants.master_outputs.versions.MMS_HW1_MIN_SETWORKMODE_V2,
  projectsConstants.master_outputs.versions.MMS_HW3_MIN_SETWORKMODE_V2
)

const isTerminalDeviceSIOValidForSetworkmodeV2 = terminalDevice => isTerminalDeviceSIOValidFor(
  terminalDevice,
  projectsConstants.master_outputs.versions.SIO_HW1_MIN_SETWORKMODE_V2,
  projectsConstants.master_outputs.versions.SIO_HW3_MIN_SETWORKMODE_V2
)

export const isTerminalDeviceValidForSetworkmodeV2 = (terminalDevice) => {
  return (
    isTerminalDeviceMMSValidForSetworkmodeV2(terminalDevice) ||
    isTerminalDeviceSIOValidForSetworkmodeV2(terminalDevice) ||
    (terminalDevice?.device?.id === projectsConstants.global.devices.PKT7 &&
      parseInt(terminalDevice?.mainCurrentVersion) >=
        projectsConstants.master_outputs.versions.PKT7_MIN_SETWORKMODE_v2)
  );
};
