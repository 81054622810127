import React, { useCallback, useState, useEffect, useMemo } from "react";
import { Input } from "reactstrap";
import { useApp } from "../../../../../context/app-context";
import { useTerminalDevice } from "../../../../../context/terminal-device-context";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import { getDateTimeFormatted } from "../../../../../_helpers/DateFormatHelper";
import {
  atomizerValidatonErrorCodes,
  useAtomizer,
} from "../../../../../_services/atomizer.service";
import { DangerComponent } from "../../../../DangerComponent/DangerComponent";
import { DropdownC } from "../../../Airframe/Dropdown/Dropdown";
import { Spinner } from "../../../Airframe/Spinner/Spinner";
import { PaginatedOptions } from "../../../PaginatedOptions/PaginatedOptions";
import { Clock } from "../../Fichas/Programs/components/Clock/Clock";
import { Days } from "../../Fichas/Programs/components/Days/Days";
import { TerminalDeviceOutputsView } from "../../OutputsView/TerminalDeviceOutputsView";
import { TerminalDeviceFooterInfo } from "../../TerminalDevices/Components/TerminalDeviceFooterInfo";

function getInitAtomizer(atomizer) {
  atomizer =
    atomizer === undefined
      ? { created: true }
      : { ...atomizer, created: false };

  if (
    atomizer.startTimestamp === undefined ||
    atomizer.endTimestamp === undefined
  ) {
    const startDate = new Date();
    startDate.setHours(12);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);

    const endDate = new Date();
    endDate.setTime(startDate.getTime());

    atomizer.startTimestamp = startDate.toISOString();
    atomizer.endTimestamp = endDate.toISOString();
  }

  if (
    atomizer.dosificationOutputs === undefined ||
    isNaN(atomizer.dosificationOutputs)
  ) {
    atomizer.dosificationOutputs = 0;
  }

  if (atomizer.dosificationPremise === undefined) {
    atomizer.dosificationPremise =
      projectsConstants.lora_outputs.dosificationPremises.none;
  }

  if (atomizer.days === undefined) {
    atomizer.days = 0;
  }

  return atomizer;
}

const apiStates = {
  idle: "IDLE",
  saving: "SAVING",
  done: "DONE",
  error: "ERROR",
};

export const AtomizerDosificationConfiguration = (props) => {
  const { terminalDevice } = useTerminalDevice();
  const {
    save: saveAtomizer,
    update: updateAtomizer,
    validateAtomizer,
    publishSyncDeviceConfig,
  } = useAtomizer();
  const { updateTerminalDevice } = useApp();

  const [atomizer, setAtomizer] = useState(
    getInitAtomizer(terminalDevice?.atomizer)
  );
  const [apiState, setApiState] = useState(apiStates.idle);
  const [validationResult, setValidationResult] = useState([]);

  const reportOptions = useMemo(() => [2, 5, 15, 30, 60], []);

  useEffect(() => {
    setAtomizer(getInitAtomizer(terminalDevice?.atomizer));
  }, [terminalDevice?.atomizer]);

  // CLOCKS
  const onStartHourMinuteChanges = useCallback(
    (second, minute, hour) => {
      const startDate = new Date(atomizer.startTimestamp);
      const endDate = new Date(atomizer.endTimestamp);
      const durationMiliseconds = endDate.getTime() - startDate.getTime();

      const date = new Date();
      date.setHours(parseInt(hour || 0));
      date.setMinutes(parseInt(minute || 0));
      date.setSeconds(parseInt(second || 0));
      date.setMilliseconds(0);

      endDate.setTime(date.getTime() + durationMiliseconds);

      setAtomizer((currentAtomizer) => ({
        ...currentAtomizer,
        startTimestamp: date.toISOString(),
        endTimestamp: endDate.toISOString(),
      }));
    },
    [atomizer.endTimestamp, atomizer.startTimestamp]
  );

  const getStartClockComponent = useCallback(() => {
    let hour = "12";
    let minute = "00";
    if (atomizer.startTimestamp) {
      const hourMinuteSplited = getDateTimeFormatted(
        new Date(atomizer.startTimestamp)
      ).split(":");
      hour = hourMinuteSplited[0];
      minute = hourMinuteSplited[1];
    }

    return (
      <Clock
        SetHour={onStartHourMinuteChanges}
        minMinutes={0}
        initHour={`${hour}`.padStart(2, "0")}
        initMinute={`${minute}`.padStart(2, "0")}
        title={<div className="HourStartIn">Hora de inicio: </div>}
        maxHours={23}
        maxMinutes={59}
        maxMinutesWhenMaxHoursReached={59}
        onMaxHourSetMinutesToMax={true}
        applyMaxMinutsOnlyWhenMaxHoursReached={true}
        enabled={true}
      />
    );
  }, [onStartHourMinuteChanges, atomizer.startTimestamp]);

  const onEndHourMinuteChanges = useCallback(
    (second, minute, hour) => {
      let durationMiliseconds =
        parseInt(hour || 0) * 60 * 60 * 1000 +
        parseInt(minute || 0) * 60 * 1000 +
        parseInt(second || 0) * 1000;
      const endDate = new Date();
      const startDate = new Date(atomizer.startTimestamp);
      endDate.setTime(startDate.getTime() + durationMiliseconds);

      setAtomizer({ ...atomizer, endTimestamp: endDate.toISOString() });
    },
    [atomizer]
  );

  const parseSecondsToHourMinutesSeconds = useCallback((segundos) => {
    const horas = Math.floor(segundos / 3600);
    const minutos = Math.floor((segundos % 3600) / 60);
    const segundosRestantes = segundos % 60;

    return [horas, minutos, segundosRestantes];
  }, []);

  const getEndClockComponent = useCallback(() => {
    const startDate = new Date(atomizer.startTimestamp);
    const endDate = new Date(atomizer.endTimestamp);
    const durationMiliseconds = endDate.getTime() - startDate.getTime();
    const [hour, minute] = parseSecondsToHourMinutesSeconds(
      durationMiliseconds / 1000
    );

    return (
      <Clock
        SetHour={onEndHourMinuteChanges}
        minMinutes={0}
        initHour={`${hour}`.padStart(2, "0")}
        initMinute={`${minute}`.padStart(2, "0")}
        title={<div className="HourStartIn">Duración: </div>}
        maxHours={23}
        maxMinutes={59}
        maxMinutesWhenMaxHoursReached={59}
        onMaxHourSetMinutesToMax={true}
        applyMaxMinutsOnlyWhenMaxHoursReached={true}
        enabled={true}
        plugin={
          <div style={{ display: "flex", flexDirection: "column" }}>
            Finalizará a las:
            <div style={{ fontSize: "1.5em" }}>
              {getDateTimeFormatted(endDate)}
            </div>
          </div>
        }
      />
    );
  }, [
    atomizer.endTimestamp,
    atomizer.startTimestamp,
    onEndHourMinuteChanges,
    parseSecondsToHourMinutesSeconds,
  ]);

  const getFilteredOutputs = useCallback(() => {
    let offset = 1,
      i = 0;
    const map = new Map();
    while (offset <= atomizer.dosificationOutputs) {
      if ((offset & atomizer.dosificationOutputs) > 0) {
        map.set(i + 1, true);
      }
      ++i;
      offset = 1 << i;
    }
    return map;
  }, [atomizer.dosificationOutputs]);

  const onSelectOutput = useCallback((terminalDeviceId, output) => {
    setAtomizer((currentAtomizer) => {
      let dosificationOutputs = currentAtomizer.dosificationOutputs || 0;
      if ((dosificationOutputs & (1 << (output - 1))) > 0) {
        // Desactivamos
        dosificationOutputs &= ~(1 << (output - 1));
      } else {
        // Activamos
        dosificationOutputs |= 1 << (output - 1);
      }
      return { ...currentAtomizer, dosificationOutputs };
    });
  }, []);

  const onDosificationPremiseChange = useCallback((dosificationOption) => {
    setAtomizer((currentAtomizer) => ({
      ...currentAtomizer,
      dosificationPremise: dosificationOption.id,
    }));
  }, []);

  const getDosificationPremisesComponent = useCallback(() => {
    const options = [
      {
        id: projectsConstants.lora_outputs.dosificationPremises.none,
        value: projectsConstants.lora_outputs.dosificationPremises.none,
        label: "Sin discriminación",
      },
      {
        id:
          projectsConstants.lora_outputs.dosificationPremises
            .timeDiscrimination,
        value:
          projectsConstants.lora_outputs.dosificationPremises
            .timeDiscrimination,
        label: "Con discriminación horaria",
      },
    ];

    const selectedOption = options.filter(
      (option) => option.id === atomizer.dosificationPremise
    );

    return (
      <div style={{ width: "100%" }}>
        <div className="Titlewithinfo">
          <div className="Title_DescripctionConf ">Dosifcación:</div>
        </div>
        <DropdownC
          options={options}
          onChange={(selected) => onDosificationPremiseChange(selected)}
          placeholder={"Seleccione premisa de dosificación..."}
          value={selectedOption}
        />
      </div>
    );
  }, [atomizer.dosificationPremise, onDosificationPremiseChange]);

  const onReportOptionClick = useCallback((optionInMinutes) => {
    setAtomizer((currentAtomizer) => ({
      ...currentAtomizer,
      reportIntervalSeconds: optionInMinutes * 60,
    }));
  }, []);

  const onDaysChange = useCallback((days) => {
    setAtomizer((currentAtomizer) => ({ ...currentAtomizer, days }));
  }, []);

  const onDosificationTimeSecondsChange = useCallback(
    (dosificationTimeSeconds) => {
      if (!isNaN(dosificationTimeSeconds)) {
        setAtomizer((currentAtomizer) => ({
          ...currentAtomizer,
          dosificationTimeSeconds: parseInt(dosificationTimeSeconds),
        }));
      }
    },
    []
  );

  const onPauseSecondsChange = useCallback((pauseSeconds) => {
    if (!isNaN(pauseSeconds)) {
      setAtomizer((currentAtomizer) => ({
        ...currentAtomizer,
        pauseSeconds: parseInt(pauseSeconds),
      }));
    }
  }, []);

  const isInvalidOutputs = useCallback(
    () =>
      validationResult.includes(atomizerValidatonErrorCodes.notSelectedOutputs),
    [validationResult]
  );
  const isInvalidDays = useCallback(
    () =>
      validationResult.includes(atomizerValidatonErrorCodes.notSelectedDays),
    [validationResult]
  );

  const onSaveClick = useCallback(() => {
    const validationResult = validateAtomizer(atomizer);
    if (validationResult.length === 0) {
      const serviceFunction = atomizer.created ? saveAtomizer : updateAtomizer;
      setApiState(apiStates.saving);
      serviceFunction(
        terminalDevice.terminal,
        terminalDevice.id,
        atomizer
      ).then(
        (atomizer) => {
          setApiState(apiStates.done);
          setAtomizer({ ...atomizer });

          terminalDevice.atomizer = atomizer;
          updateTerminalDevice(terminalDevice);

          if (
            atomizer.syncState ===
            projectsConstants.lora_outputs.syncStates.notSync
          ) {
            publishSyncDeviceConfig(terminalDevice.id);
          }
        },
        (error) => setApiState(apiStates.error)
      );
    }

    setValidationResult(validationResult);
  }, [
    atomizer,
    saveAtomizer,
    terminalDevice,
    updateAtomizer,
    updateTerminalDevice,
    validateAtomizer,
    publishSyncDeviceConfig,
  ]);

  return (
    <>
      {apiState === apiStates.saving && <Spinner />}
      {apiState === apiStates.error && (
        <DangerComponent message={"Error guardando los cambios"} />
      )}
      {apiState !== apiStates.saving && (
        <>
          {getDosificationPremisesComponent()}
          {atomizer.dosificationPremise ===
            projectsConstants.lora_outputs.dosificationPremises
              .timeDiscrimination && (
            <>
              {getStartClockComponent()}
              {getEndClockComponent()}
              <Days
                SetDays={onDaysChange}
                initDays={atomizer.days}
                enabled={true}
              />
              <div hidden={!isInvalidDays()} className="Error">
                Debe seleccionar almenos un dia.
              </div>
            </>
          )}

          <div className="OutputsdViewSubroutines">
            <div className="TitleCard">
              Selecciona una o varias salidas para la dosificación.
            </div>

            <TerminalDeviceOutputsView
              onClick={onSelectOutput}
              outputs={terminalDevice?.outputs || []}
              forcedMapStates={getFilteredOutputs()}
              forcedSelectedColor={"rgb(33,150,243)"}
            />
            <div hidden={!isInvalidOutputs()} className="Error">
              Debe seleccionar las salidas que se deben activar.
            </div>
          </div>

          <div className="Title_Descripction">Tiempo de activación (seg):</div>
          <Input
            type="number"
            min="1"
            placeholder={"Tiempo de activación..."}
            className="InputForm descriptionInput"
            onChange={(e) => onDosificationTimeSecondsChange(e.target.value)}
            value={atomizer.dosificationTimeSeconds || 0}
            invalid={validationResult.includes(
              atomizerValidatonErrorCodes.invalidDosificationTimeSeconds
            )}
          />

          <div className="Title_Descripction">
            Descanso entre activaciones (seg):
          </div>
          <Input
            type="number"
            min="1"
            placeholder={"Descanso entre activaciones..."}
            className="InputForm descriptionInput"
            onChange={(e) => onPauseSecondsChange(e.target.value)}
            value={atomizer.pauseSeconds || 0}
            invalid={validationResult.includes(
              atomizerValidatonErrorCodes.invalidPauseTime
            )}
          />

          <PaginatedOptions
            label={"Reporte cada"}
            unit={"min"}
            values={reportOptions}
            valueActive={(atomizer.reportIntervalSeconds || 120) / 60}
            onOptionClick={onReportOptionClick}
          ></PaginatedOptions>

          <div className="ButtonOffAll" onClick={(e) => onSaveClick()}>
            Guardar
          </div>
          <TerminalDeviceFooterInfo terminalDevice={terminalDevice} />
        </>
      )}
    </>
  );
};
