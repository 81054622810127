import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { InputGroup } from "../../../../Airframe/InputGroup/InputGroup";

export const FirstLastPulses = (props) => {
  const {
    startPulsesLael,
    stopPulsesLabel,
    startPulsesValue,
    stopPulsesValue,
    onStartPulsesChange,
    onStopPulsesChange,
    foco
  } = props;
  const [errorOnStartPulses, seterrorOnStartPulses] = useState(false);
  const [errorOnStopPulses, seterrorOnStopPulses] = useState(false);
  const [startPulses, setStartPulses] = useState(parseInt(startPulsesValue));
  const [stopPulses, setStopPulses] = useState(parseInt(stopPulsesValue));


  const onStartChange = useCallback(
    (value) => {
      let number = parseFloat(value);
      let parsedValue = 0;
      if (isNaN(number) || number < 0) {
        seterrorOnStartPulses(true);
      } else {
        seterrorOnStartPulses(false);
        parsedValue = parseInt(value * 1000);
      }
      onStartPulsesChange(parsedValue);
      setStartPulses(isNaN(number) ? undefined : parsedValue);
    },
    [onStartPulsesChange]
  );

  const onStopChange = useCallback(
    (value) => {
      let number = parseFloat(value);
      let parsedValue = 0;
      if (isNaN(number) || number < 0) {
        seterrorOnStopPulses(true);
      } else {
        seterrorOnStopPulses(false);
        parsedValue = parseInt(value * 1000);
      }
      onStopPulsesChange(isNaN(number) ? undefined : parsedValue);
      setStopPulses(parsedValue);
    },
    [onStopPulsesChange]
  );

  useEffect(() => {
    const stopValue = stopPulsesValue / 1000;
    if (isNaN(stopValue) || stopValue < 0) {
      seterrorOnStopPulses(true);
    } else {
      seterrorOnStopPulses(false);
      setStopPulses(stopPulsesValue);
    }
  }, [stopPulsesValue]);

  useEffect(() => {
    const startValue = startPulsesValue / 1000;
    if (isNaN(startValue) || startValue < 0) {
      seterrorOnStartPulses(true);
    } else {
      seterrorOnStartPulses(false);
      setStartPulses(startPulsesValue);
    }
  }, [startPulsesValue]);


  return (
    <div className="firstLastPulses">

      <div className="firstLastPulsesColumn">
        <div>{startPulsesLael}</div>

        <InputGroup
          foco={foco}
          type="number"
          placeholder={"Tiempo de arranque..."}
          id="stopPulsesMore"
          symbol="seg"
          value={!isNaN(startPulses) ? startPulses / 1000 : ""}
          className={`${errorOnStopPulses && "is-invalid"}`}
          onChange={onStartChange}
          min={0}
        />

        {errorOnStartPulses && (
          <div className="invalid-feedback">Campo requerido. </div>
        )}
      </div>

      <div className="firstLastPulsesColumn">

        <div>{stopPulsesLabel}</div>

        <InputGroup
          foco={false}
          type="number"
          placeholder={"Tiempo de parada..."}
          id="stopPulsesLess"
          symbol="seg"
          value={!isNaN(stopPulses) ? stopPulses / 1000 : ""}
          className={`${errorOnStopPulses && "is-invalid"}`}
          onChange={onStopChange}
        />

        {errorOnStopPulses && (
          <div className="invalid-feedback">Campo requerido. </div>
        )}
      </div>

    </div>


  );
};

FirstLastPulses.propTypes = {
  startPulsesLael: PropTypes.string.isRequired,
  stopPulsesLabel: PropTypes.string.isRequired,
  startPulsesValue: PropTypes.number,
  stopPulsesValue: PropTypes.number,
  onStartPulsesChange: PropTypes.func.isRequired,
  onStopPulsesChange: PropTypes.func.isRequired
};
