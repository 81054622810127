import React, { useCallback} from "react";
import { ReactComponent as Editsvg } from "./../../../../images/editar.svg";
import { getDateTimeFormatted } from "../../../../_helpers/DateFormatHelper";

export const TriggerSubprogramCardDragAndDrop = (props) => {
  const { subprogram, innerRef, onEdit, onDelete } = props;

  const getOutputs = useCallback(() => {
    let pos = 0,
      i = 0;
    let outputs = [];
    let outputsSelected = subprogram?.outputs || 0;
    if (outputsSelected === 0) {
      return <div>Tiempo de descanso</div>;
    }
    while (pos < outputsSelected) {
      pos = 1 << i;
      if ((outputsSelected & pos) > 0) {
        outputs.push(
          <OutputComponent key={i} outputId={i + 1}></OutputComponent>
        );
      }
      i++;
    }
    return outputs;
  }, [subprogram]);

  /*ref={innerRef}*/
  return (
    <div
      className={`CardsSubroutine Notified`}
    >
      <div className="twocolums">
        <div className="NameCardSubRoutine">
          <div>
            <div onClick={() => onEdit(subprogram)}>
              <Editsvg className="ICoCard" />
            </div>
          </div>
          <div className="titlecardsensor">{subprogram?.id}</div>
          <div
            className="fa fa-fw fa-close text-danger buttonDeleteCard "
            onClick={() => onDelete(subprogram)}
          />
        </div>

        <div className="flex center">
          <div className="titlehourpr">
            De
            <div className="text-center HourCard">
              {getDateTimeFormatted(subprogram?.startDateTime)}
            </div>
            a
            <div className="text-center HourCard">
              {getDateTimeFormatted(subprogram?.endDateTime)}
            </div>
          </div>
        </div>
      </div>
      <div className="OutputsCardSubroutine">
        <div className="Salidas">{getOutputs()}</div>
      </div>

      {/* <OptionsPrePo subprogram={subprogram} /> */}
    </div>
  );
};

const OutputComponent = (props) => {
  const { outputId } = props;

  return (
    <>
      <div className="outputpilldragodrop">
        <div className="badge-primary2 outputvi">{outputId}</div>
      </div>
    </>
  );
};
