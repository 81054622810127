import React, { useEffect, useCallback, useState } from "react";
import { PopUpC } from "../../../../../Airframe/PopUp/PopUp";
import { SliderC } from "../../../../../Airframe/Slider/Slider";
import { ReactComponent as ExclamationSvg } from "./exclamation.svg";
import "./Clock.sass";
import { Helps } from "../Helps/Helps";

export const Clock = (props) => {
  const {
    SetHour,
    initHour,
    initMinute,
    initSecond,
    enabled,
    WarningMessage,
    title,
    maxHours,
    maxMinutes,
    maxSecond,
    maxMinutesWhenMaxHoursReached,
    onMaxHourSetMinutesToMax,
    applyMaxMinutsOnlyWhenMaxHoursReached,
    minMinutes,
    plugin
  } = props;

  const initValue = "00";
  //const [hora, sethora] = useState(initHour || initValue);
  //const [minutos, setminutos] = useState(initMinute || initValue);
  const [hora, sethora] = useState(initHour || initValue);
  const [minutos, setminutos] = useState(initMinute || initValue);
  const [segundos, setSegundos] = useState(initSecond || initValue);
  const [openshowinfo, setopenshowinfo] = useState(false);

  const SliderHour = useCallback(
    (value) => {
      let hour = value;
      let minutes = minutos;
      if (
        onMaxHourSetMinutesToMax &&
        maxHours === parseInt(hour) &&
        maxMinutesWhenMaxHoursReached < parseInt(minutes)
      ) {
        minutes = maxMinutesWhenMaxHoursReached;
      }

      if (hour.toString().length === 1) {
        hour = "0" + hour.toString();
      }
      if (minutes.toString().length === 1) {
        minutes = "0" + minutes.toString();
      }
      sethora(hour);
      setminutos(minutes);
      SetHour(segundos, minutes, hour);
    },
    [
      minutos,
      segundos,
      SetHour,
      onMaxHourSetMinutesToMax,
      maxHours,
      maxMinutesWhenMaxHoursReached,
    ]
  );

  const SliderMinutes = useCallback(
    (value) => {
      let minutes = value;
      if (
        onMaxHourSetMinutesToMax &&
        maxHours === parseInt(hora) &&
        maxMinutesWhenMaxHoursReached < parseInt(minutes)
      ) {
        minutes = maxMinutesWhenMaxHoursReached;
      }
      if (minutes.toString().length === 1) {
        minutes = "0" + minutes.toString();
      }
      setminutos(minutes);
      SetHour(segundos, minutes, hora);
    },
    [
      hora,
      segundos,
      SetHour,
      maxHours,
      onMaxHourSetMinutesToMax,
      maxMinutesWhenMaxHoursReached,
    ]
  );

  const SliderSeconds = useCallback(
    (value) => {
      let seconds = value;
      if (seconds.toString().length === 1) {
        seconds = "0" + seconds.toString();
      }
      setSegundos(seconds)
      SetHour(seconds, minutos, hora);
    },
    [hora, minutos, SetHour]
  );

  const closePopUP = useCallback(() => {
    setopenshowinfo(false);
  }, []);
  const viewInfoPrograms = useCallback(() => {
    setopenshowinfo(true);
  }, []);

  useEffect(() => {
    sethora(initHour);
    setminutos(initMinute);
    setSegundos(initSecond);
  }, [initHour, initMinute, initSecond]);

  const getMinutesMax = useCallback(() => {
    if (applyMaxMinutsOnlyWhenMaxHoursReached && maxHours === hora) {
      return maxMinutesWhenMaxHoursReached;
    }
    return maxMinutes;
  }, [applyMaxMinutsOnlyWhenMaxHoursReached, maxHours, hora, maxMinutes, maxMinutesWhenMaxHoursReached]);

  return (
    <>
      <div className="DataPickerV2">
        <div className="HourDisplayFather">
          {title}
          <div className="HoursDisplay">
            <div className="hourpicker">
              <div className="fixheightHour">{hora}</div>
            </div>
            <div className="Title_Descripction separatorClock">:</div>
            <div className="hourpicker">
              <div className="fixheightHour">{minutos}</div>
            </div>
            {maxSecond > 0 &&
            <><div className="Title_Descripction separatorClock">:</div>
            <div className="hourpicker">
              <div className="fixheightHour">{segundos}</div>
            </div>
            </>
            }
          </div>

          <div className="HourStartIn">{plugin}</div>

          <PopUpC
            activate={openshowinfo}
            deactivatepopup={closePopUP}
            content={<Helps />}
          />
        </div>

        <div className="HoursDisplay2 column">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "flex-end",
            }}
          >
            <div className="TitleClock">Horas:</div>

            <SliderC
              onChange={SliderHour}
              value={parseInt(hora)}
              max={maxHours}
              enabled={enabled}
              WarningMessage={WarningMessage}
              min={minMinutes}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "flex-end",
            }}
          >
            <div className="TitleClock">Minutos: </div>
            <SliderC
              onChange={SliderMinutes}
              value={parseInt(minutos)}
              max={getMinutesMax()}
              enabled={enabled}
              WarningMessage={WarningMessage}
              min={0}
            />
          </div>
          {maxSecond > 0 &&
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "flex-end",
            }}
          >
            <div className="TitleClock">Segundos: </div>
            <SliderC
              onChange={SliderSeconds}
              value={parseInt(segundos)}
              max={maxSecond}
              enabled={enabled}
              WarningMessage={WarningMessage}
              min={0}
            />
          </div>
          }
        </div>
      </div>
    </>
  );
};
