import { ProgramElement } from "../ProgramElement";


export class TerminalDeviceInfraprogram extends ProgramElement{
    
    static parseFromObjectDto(dtoObject: any) : TerminalDeviceInfraprogram | any{
        if(dtoObject){
            return Object.setPrototypeOf(dtoObject, TerminalDeviceInfraprogram.prototype);
        }
        return dtoObject;
    }
}