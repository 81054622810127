import React, { useCallback, useState } from "react";
import { TerminalsChild } from "./TerminalsChild";
import "./Page2.sass";
import "reactjs-popup/dist/index.css";

export const SelectTerminalsPage = (props) => {
  const {
    terminals,
    datestoToken,
    listselectedterminals,
    Createtoken,
    tokenCreating,
    tokenError,

  } = props;
  const [dateEnd, setdateEnd] = useState(undefined);
  const [dateStart, setdateStart] = useState(undefined);
  const [name, setname] = useState(undefined);
  const [boolErrorSelectTerminals, setboolErrorSelectTerminals] = useState(0);
  const [list, setlist] = useState([]);

  const checkSelectUserTerminalsprepare = useCallback(
    (listofTerminals) => {
      if (listofTerminals.length === 0) {
        setboolErrorSelectTerminals(1);
      } else {
        if (datestoToken === undefined || datestoToken === null) {
          setname("Llave tecatel");
        } else {
          const name = datestoToken.name;
          setname(datestoToken.name);
        }

        setboolErrorSelectTerminals(0);
        listselectedterminals(listofTerminals);
      }
    },
    [datestoToken, name, boolErrorSelectTerminals]
  );
/*
  const getDateTimeFormatted = (date) => {
    const month =
      date.getMonth() < 10 ? `0${date.getMonth()}` : `${date.getMonth()}`;
    const day =
      date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    return `${day}/${month}/${date.getFullYear()}`;
    //return `date`;
  };

  const PrepareCreateToken = useCallback((startDate, endDate, name, list) => {
    Createtoken(startDate, endDate, name, list);
  });
*/
  const AddTerminalToList = useCallback(
    (id) => {
      for (let i = 0; i < list.length; i++) {
        if (id == list[i]?.id) {
          list.splice(i, 1);
        }
      }

      let foundTerminal = undefined;
      for (let i = 0; i < terminals.length; i++) {
        const terminal = terminals[i];
        if (terminal?.id === id) {
          foundTerminal = terminal;
        }
      }
      if (foundTerminal) {
        list.push(foundTerminal);
        setlist(list);
      }
    },
    [list]
  );

  const DeleteTerminalToList = useCallback((id) => {
    for (let i = 0; i < list.length; i++) {
      if (id == list[i]?.id) {
        list.splice(i, 1);
      }
    }
  }, []);
  /*
  useEffect(() => {
    if (datestoToken !== undefined) {
      setname(datestoToken.name);
      setdateStart(getDateTimeFormatted(startDate));
      setdateEnd(getDateTimeFormatted(endDate));

      // Comprobamos si solo hay un terminal.
      if (terminals?.length === 1 && terminals[0] !== undefined) {
        const oneTerminalList = [terminals[0]];
        // checkSelectUserTerminals(oneTerminalList);
      }
    }
  }, [dateStart, dateEnd, name]);
*/
  return tokenCreating ? (
    <div className="Token">
      <div className="Title_Descripction center title2">
        Preparando llave...
      </div>
    </div>
  ) : (
    <>
      <div className="Token">
        <div className="Title_Descripction center title2">
          Elije los terminales a los que quieres dar acceso:
        </div>

        {tokenError && (
          <div className="Error">
            Error preparando la llave, vuelva a intentarlo en unos minutos.
          </div>
        )}

        <div className="FormPage2">
          <div className="fechasPaso2">
            <div className="DatesPage2">
              Del {dateStart} hasta el {dateEnd}
            </div>
          </div>
        </div>

        <hr className="Separator2"></hr>

        <div className="TerminalChildF">
          <div className="TerminalChildFC idsize">ID:</div>
          <div className="TerminalChildFC descriptionsize">Descripción</div>
          <div className="TerminalChildFC numbersize">Número de serie:</div>
        </div>
        <hr className="Separator2"></hr>
        {terminals === undefined ? (
          <>Cargando...</>
        ) : (
          <div className="ListaPaso2">
            {Object.keys(
              terminals.sort((first, second) => {
                if (first.id < second.id) {
                  return -1;
                }
                if (first.id > second.id) {
                  return 1;
                }
                return 0;
              })
            ).map((item, i) => (
              <TerminalsChild
                id={terminals[item].id}
                description={terminals[item].description}
                sn={terminals[item].sn}
                AddTerminalToList={AddTerminalToList}
                DeleteTerminalToList={DeleteTerminalToList}
                key={terminals[item].id}
                SelectOnlyTerminal={checkSelectUserTerminalsprepare}
                list={list}
              />
            ))}
          </div>
        )}

     {/*  <button
          className="ButtonToken"
          //Seleccionando terminales
          onClick={(e) => checkSelectUserTerminalsprepare(list)}
        >
          Aceptar
        </button> */} 

        {boolErrorSelectTerminals === 1 ? (
          <div className="Error">No has seleccionado ningún terminal</div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
