import React, { useCallback, useEffect } from "react";
import Photo from "./Dialog2.png";
import "./BoxDialog.sass";
import "./BoxDialognew.sass";

const shadowFathers = document.getElementsByClassName('BoxDialogFather');

export const BoxDialog = (props) => {
  const { content, show, onClose } = props;
  const classwidth = {
    class1: "widthbox1",
    class2: "widthbox2",
    class3: "widthbox3",
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (show) {
        onClose();
      }
    },
    [onClose, show]
  );

  const getClassWitdh = useCallback((event) => {
    let sizewidth = content.length;
    if (sizewidth > 40) {
      return classwidth.class1;
    }else {
      return classwidth.class2;
    }
  }, [content, classwidth]);

  const getClassCenter = useCallback((event) => {
    let sizewidth = content.length;
    if (sizewidth > 40) {
      return classwidth.class1;
    }else{
      return classwidth.class2;
    }
  }, [content, classwidth]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("ontouchend", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("ontouchend", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if(shadowFathers && shadowFathers instanceof HTMLCollection) {
      let shadow = null;
      for(let i = 0; i  < shadowFathers.length; i++){
        if(shadow = shadowFathers.item(i)){
          shadow.hidden = !show;
        }
      }
    }
  }, [show]);

  return (
    <>
      {show ? (
        <>
       
        <div className="alldisplayclickOnClose">
          <div className={`BoxdialogModal ${getClassCenter()}`}>
            <img
              src={Photo}
              alt="boxDialog"
              className={`BoxDialog ${getClassWitdh()}`}
            />
            <div className="BoxDialogFatherContent"> {content}</div>
          </div>
        </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
