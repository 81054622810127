import React from "react";

export const CudsConfigurationHelp = (props) => {
  return (
    <div className="documentation">
      <div className="helptitle">Caudalímetros</div>
      <div className="grouphelp">
        El dispositivo dispone de varios entradas destinadas al control de caudalímetros.
      </div>

      <div className="grouphelp">
        <div className="subhelptitle">Calibración</div>
        <div>Para un registro correcto de los datos es necesario realizar la calibración del caudalímetro.</div>
      </div>
      <br />
      <div>
        Una vez activado se verán los datos en la vista principal y en la sección de estadísticas.
      </div>
    </div>
  )
};
