import React from "react";

export const Infoprograms = (props) => {
  return (
    <div className="documentation">
      <div className="titleform">Funcionamiento de programas.</div>

      <div className="TextInfo divfrase">
        Introduce los datos del programa: Nombre, Hora en la que empezara el
        programa y los días de la semana que quieras que se repita.
      </div>

      <div className="TextInfo divfrase">
        Una vez le des a guardar, el programa se creará sin manejar ninguna
        salida
      </div>

      <div className="TextInfo divfrase">
        Para ello dale a subprograma y te llevará a un nuevo menú donde podrás
        elegir las salidas y tiempo de repetición del programa.
      </div>
    </div>
  );
};
