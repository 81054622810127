
import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "../Spinner/Spinner";
import PropTypes from 'prop-types'

import "./Accordion.sass";

export const AccordionWithContent = (props) => {

    const {showContent, title, children} = props;

    const initState = {
        dsiplayed: false,
        inSync: false
    }

    const [state, setState] = useState(initState);

    const onClick = useCallback(() => {
        setState(state => {
            return {...state, dsiplayed: !state.dsiplayed};
        })
    }, []);

    useEffect(() => {
        if(showContent){
            setState(state => {
                return {...state, dsiplayed: true}
            })
        }
    }, [showContent])

    return (
        
        <div className="ContentTAccordionSplit">
          <div className="PadreAccordion">
            <div
              id="togglerOutputs"
              className="togglerOutputs"
              onClick={(e) => onClick()}
            >
              <div className={`iconacco fa fa-fw ${state.dsiplayed ? "fa-chevron-down" : "fa-chevron-right"}`} />
              <div className="titleaccordion">{title}</div>
    
              <div className="spinneraccordion">
                {state.inSync && <Spinner size={"25px"} />}
              </div>
            </div>
          </div>

          {state.dsiplayed && <div className="AccordionSplitContent">{children}</div>}
        </div>
      );
}

AccordionWithContent.propTypes = {
    showContent: PropTypes.bool,
    title: PropTypes.element.isRequired
}