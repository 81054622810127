import React from "react";
import { Badge } from "reactstrap";
import { useTerminalDevice } from "../../../../../context/terminal-device-context";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import { getFullDateTimeFormatted } from "../../../../../_helpers/DateFormatHelper";
import { Spinner } from "../../../Airframe/Spinner/Spinner";

function getClockSyncStateComponent(clockSyncState) {
  return (
    <Badge
      id="badge_atomizerSyncState"
      className="mt-2"
      color={
        clockSyncState === projectsConstants.lora_outputs.clockSyncStates.done
          ? "success"
          : "danger"
      }
    >
      Fecha/Hora: {clockSyncState}
    </Badge>
  );
}

function getSyncStateComponent(syncState) {
  return (
    <Badge
      id="badge_atomizerSyncState"
      className="mt-2"
      color={
        syncState === projectsConstants.lora_outputs.syncStates.sync
          ? "success"
          : "danger"
      }
    >
      Configuración: {syncState}
    </Badge>
  );
}

function getLoadingComponent(clockSyncState, syncState) {
  if (
    syncState !== projectsConstants.lora_outputs.syncStates.sync ||
    clockSyncState !== projectsConstants.lora_outputs.clockSyncStates.done
  ) {
    return <Spinner size={"0.8em"} />;
  }

  return <></>;
}

function getLoraRssiComponent(loraRssi) {
  const color =
  loraRssi > -110 ? "success" :
  loraRssi > -120 ? "warning" :
  "danger";

  return (
    <Badge
      id="badge_atomizerSyncState"
      className="mt-2"
      color={color}
    >
      RSSI: {loraRssi}
    </Badge>
  );
}

function getLoraSnrComponent(loraSnr) {
  const color =
  loraSnr > -5 ? "success" :
  loraSnr > -10 ? "warning" :
  "danger";

  return (
    <Badge
      id="badge_atomizerSyncState"
      className="mt-2"
      color={color}
    >
      SNR: {loraSnr}
    </Badge>
  );
}

export const AtomizerSyncStatus = (props) => {
  const { terminalDevice } = useTerminalDevice();

  return (
    <>
      Estado de sincronización de la configuracion de dosificación:{" "}
      <div style={{ display: "flex" }}>
        {getClockSyncStateComponent(terminalDevice?.atomizer?.clockSyncState)}
        {getSyncStateComponent(terminalDevice?.atomizer?.syncState)}
        {getLoadingComponent(
          terminalDevice?.atomizer?.clockSyncState,
          terminalDevice?.atomizer?.syncState
        )}
        {getLoraRssiComponent(terminalDevice?.loraRssi)}
        {getLoraSnrComponent(terminalDevice?.loraSnr)}
      </div>
      <p style={{ fontSize: "0.5em" }}>
        ({terminalDevice?.atomizer?.syncStateLastAt ?  getFullDateTimeFormatted(new Date(terminalDevice.atomizer.syncStateLastAt)) : ""})
      </p>
    </>
  );
};
