import React, { useCallback, useEffect, useState, useRef } from "react";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import { ModeContinuo } from "../../Fichas/OutputConfiguration/Modes/ModeContinuo";
import { ModePulses } from "../../Fichas/OutputConfiguration/Modes/ModePulses";
import { ModePulsesperMinute } from "../../Fichas/OutputConfiguration/Modes/ModePulsesperMinute";
import _ from "lodash";
import { DropdownNew } from "./DropdownNew/DropdownNew";
import "./PanelTypesSubprograms.sass";
export const SelectActivationTypes = (props) => {
  const {
    activationParams,
    onActivationParamsChange,
    outputs,
    saveModificationOutputInMap,
    changeOutput,
    outputTypes,
    mode,
    changeMode,
    outputSelect,
    ShowButtonSavePrograms
  } = props;

 

  const saveInFile = useCallback(() => {
    console.log("guardando cambio en customactivationType")
    saveModificationOutputInMap(mode);
    ShowButtonSavePrograms();
  }, [mode, saveModificationOutputInMap]);

  const onChangePwm = useCallback(
    (pwm) => {
      let tmpParams = { ...activationParams, pwm };
      onActivationParamsChange(tmpParams);
    },
    [activationParams, onActivationParamsChange]
  );

  const onChangeFirstPulse = useCallback(
    (first_pulse) => {
      let tmpParams = { ...activationParams, first_pulse };
      onActivationParamsChange(tmpParams);
    },
    [activationParams, onActivationParamsChange]
  );

  const onChangeLastPulse = useCallback(
    (last_pulse) => {
      let tmpParams = { ...activationParams, last_pulse };
      onActivationParamsChange(tmpParams);
    },
    [activationParams, onActivationParamsChange]
  );
  const onChildernActivationParamsChange = useCallback(
    (params) => {
      onActivationParamsChange(params);
    },
    [onActivationParamsChange]
  );

  const getFirstPulse = useCallback(() => {
    return activationParams?.first_pulse || 0;
  }, [activationParams]);

  const getLastPulse = useCallback(() => {
    return activationParams?.last_pulse || 0;
  }, [activationParams]);

  const getPwm = useCallback(() => {
    return activationParams?.pwm || 0;
  }, [activationParams]);

  //----

  const onChangeActivationMode = useCallback(
    (position, option) => {
      changeMode(position, option);
    },
    [changeMode]
  );

  const onChangeOutput = useCallback(
    (position, option) => {
      changeOutput(position, option);
    },
    [changeOutput]
  );

  const getDropdownOutputs = useCallback(() => {
    let selectedOption = undefined;
    const outputsOptions = _.cloneDeep(outputs).map((output) => {
      
      output.value = output.output + "." + output.description;

      if (output.output === outputSelect?.output) {
        selectedOption = output;
      }

      return output;
    });
    return (
      <DropdownNew
        initial={selectedOption}
        options={outputsOptions}
        ChangeOption={onChangeOutput}
      />
    );
  }, [outputs, onChangeOutput, outputSelect]);

  const getDropdownActivationTypes = useCallback(() => {
    let selectedOption = undefined;
    const activationTypesOptions = _.cloneDeep(outputTypes).map(
      (activationType, i) => {
        activationType.value = activationType.description;
        if (activationType.sid === mode) {
          selectedOption = activationType;
        }
        return activationType;
      }
    );

    return (
      <DropdownNew
        initial={selectedOption}
        options={activationTypesOptions}
        ChangeOption={onChangeActivationMode}
      />
    );
  }, [onChangeActivationMode, mode, outputTypes]);

  return (
    <div className="PanelFatherTypes">
      <div className="titleMenuEditActivation">Modos de activación:</div>
      {/* Cambia el tipo de activación de cada salida y su tiempo de activación */}
      {outputTypes && (
        <>
          <div className="RowUISelectTypes">
            <div className="DropdownF_SelectType">
              <div>Salidas:</div>
              {getDropdownOutputs()}
            </div>
            <div className="DropdownF_SelectType">
              <div>Activacion:</div>
              {getDropdownActivationTypes()}
            </div>
          </div>
        </>
      )}

      <div className="configAndButton">
        <div className="ConfigTypesOutputs">
          {mode === projectsConstants.global.activations.continuous && (
            <>
              <div className="ElementConfigType">
                <ModeContinuo pwm={getPwm()} setPwm={onChangePwm} />
              </div>
            </>
          )}

          {mode === projectsConstants.global.activations.pulses_per_minute && (
            <div className="ElementConfigType">
              <ModePulsesperMinute
                activationParams={activationParams}
                onActivationParamsChange={onChildernActivationParamsChange}
              />
            </div>
          )}

          {(mode === projectsConstants.global.activations.pulses ||
            mode ===
              projectsConstants.global.activations.pulses_with_polarity) && (
            <div className="widthUiMode">
              <div className="ElementConfigType">
                <ModePulses
                  selectedOutputFocus={outputSelect}
                  setStart={onChangeFirstPulse}
                  setStop={onChangeLastPulse}
                  start={getFirstPulse()}
                  stop={getLastPulse()}
                />
              </div>
            </div>
          )}
        </div>

        <div className="ButtonsPrograms">
          <>
            {/*     <div className="ButtonType Secondary" onClick={(e) => back()}>
            Atras
          </div>*/}
          </>

          <>
            <div className="ButtonType" onClick={(e) => saveInFile()}>
              Guardar
            </div>
          </>
        </div>
      </div>
    </div>
  );
};
