import React, { useCallback, useState, useEffect, useMemo } from "react";
import { Spinner } from "../../../../Airframe/Spinner/Spinner";
import { TerminalDeviceSensorCalibrationInfo } from "../../../Fichas/SensorsConfiguration/calibration/TerminalDeviceSensorCalibrationInfo";
import { TerminalDeviceSensorTriggerConfigurator } from "../../../Fichas/Triggers/TerminalDeviceSensorTriggerConfigurator";
import { TerminalDeviceSensorStats } from "../../../TerminalDevices/Components/TerminalDeviceSensorStats";
import "./configure.sass";
import _ from "lodash";

export const TsmConfigure = (props) => {
  const {
    back,
    terminalDevices,
    selectedSensor: sSensor,
    terminalDevice,
  } = props;

  const typesCondition = {
    greater: "GT",
    less: "LT",
  };



  const [selectedSensor, setselectedSensor] = useState(sSensor);
  const [triggersSensorChoose, settriggersSensorChoose] = useState(undefined);

  const loadTriggerSensor = useCallback(
    (trigger) => {
      settriggersSensorChoose(trigger);
    },
    []
  );

  useEffect(() => {
    //elegimos por defecto el primer trigger si existe
    if (selectedSensor.triggers.length > 0) {
      loadTriggerSensor(selectedSensor.triggers[0]);
    }else{
      settriggersSensorChoose(undefined);
    }
    
  }, [loadTriggerSensor, selectedSensor, selectedSensor.triggers]);

  const onSensorTriggerChanged = useCallback(() => {
    if (terminalDevice?.sensors instanceof Array) {
      terminalDevice.sensors = _.cloneDeep(terminalDevice.sensors);
      if (selectedSensor) {
        let selSensor = terminalDevice.sensors.filter(
          (sensor) => sensor?.sensorIndex === selectedSensor.sensorIndex
        )[0];
        setselectedSensor(selSensor);
      }
    }
  }, [selectedSensor, terminalDevice])

  return (
    <div className="tsmBody">
      <div className="head_TsmConfigure">
        <div onClick={(e) => back()}>
          <div className="fa fa-fw fa-home backico" />
        </div>

        <div className="configuretsm_Title">Configuración:</div>
      </div>
      <TerminalDeviceSensorStats sensor={selectedSensor} trigger={triggersSensorChoose}></TerminalDeviceSensorStats>
      

      {triggersSensorChoose && triggersSensorChoose.notified === false && !triggersSensorChoose.deletedByUser && 
        <div className="head_TsmConfigure">
          <div>Sincronizando con el dispositivo...</div>
          <Spinner></Spinner>
        </div>
      }

      <TerminalDeviceSensorCalibrationInfo terminalDevice={terminalDevice} terminalDeviceSensor={selectedSensor}></TerminalDeviceSensorCalibrationInfo>
      <TerminalDeviceSensorTriggerConfigurator
                terminalDevice={terminalDevice}
                selectedSensor={selectedSensor}
                terminalDevices={terminalDevices}
                enableBackButton={true}
                back={back}
                autofocus={false}
                onSensorsChanged={onSensorTriggerChanged}
              ></TerminalDeviceSensorTriggerConfigurator>
    </div>
  );
};
