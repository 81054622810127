import React, { useCallback, useEffect, useState } from "react";
import "./Sectors.sass";
import { useNavigate } from 'react-router-dom';
import { SquareView } from "../Terminals/SquareView/SquareView";
import { Authservice } from "../../../_services/Authservice";
import { zoneService } from "../../../_services/zone.service";
import { useApp } from "../../../context/app-context";
import { uploadTargetEntities } from "../File/FileUploader";

export const Sector = (props) => {
  const { sector, zoneId } = props;
  const { setSelectedSector } = useApp();

  const history = useNavigate();
  const [srcImg, setSrcImg] = useState(undefined);
  const [internalName, setInternalName] = useState(sector?.name);

  const states = {
    IDLE: "IDLE",
    LOADING_IMAGE: "LOADING_IMAGE",
    LOADED_IMAGE: "LOADED_IMAGE",
  };
  const [state, setState] = useState({
    state: states.LOADING_IMAGE,
    imageLoaded: false,
  });

  const selectSector = useCallback(() => {
    history(`/zones/${zoneId}/sector/${sector?.id}/`);
    setSelectedSector(sector);
  }, [history, sector, setSelectedSector, zoneId]);

  useEffect(() => {
    if (sector?.hasImage && state.state === states.LOADING_IMAGE) {
      Authservice.loadImage(
        `${process.env.REACT_APP_API_PATH}/zones/${zoneId}/sectors/${sector?.id}/images/`
      )
        .then((img) => {
          setState({ ...state, imageLoaded: true, state: states.LOADED_IMAGE });
          setSrcImg(img);
        })
        .catch((err) => {
          setState({
            ...state,
            imageLoaded: false,
            state: states.LOADED_IMAGE,
          });
        });
    } else if (!sector?.hasImage && state.state === states.LOADING_IMAGE) {
      setState({ ...state, imageLoaded: false, state: states.LOADED_IMAGE });
    }

    if (state.state === states.LOADED_IMAGE) {
      setState({ ...state, state: states.IDLE });
    }
  }, [
    sector?.hasImage,
    sector?.id,
    state,
    states.IDLE,
    states.LOADED_IMAGE,
    states.LOADING_IMAGE,
    zoneId,
  ]);

  useEffect(() => {
    setInternalName(sector?.name);
  }, [sector]);

  const onNameChange = useCallback(
    (name) => {
      const { hasImage, ...newSector } = { ...sector, name };
      zoneService
        .updateZoneSector(zoneId, sector?.id, newSector)
        .then((sectorDto) => {
          //console.log(sectorDto);
        })
        .catch((error) => {
          //console.log(error);
        });
      setInternalName(name);
    },
    [sector, zoneId]
  );

  return (
    <div className={"ContentImageFather"}>
      <SquareView
        name={internalName}
        icon={false}
        targetEntity={uploadTargetEntities.sector}
        uploadEntityIds={[zoneId, sector?.id]}
        loadingImage={state.state === states.LOADING_IMAGE}
        srcImage={state.imageLoaded ? srcImg : undefined}
        onNameChange={onNameChange}
        onSelected={selectSector}
        data={sector}
      />
    </div>
  );
};
