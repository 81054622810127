import React, { useCallback } from "react"
import { Input } from "reactstrap"

export const TerminalDeviceSimpleCalibration = props => {

    const {
        rawTitle,
        unitsTitle,
        calibrationRawValue,
        calibrationUnitValue,
        onCalibrationRawValueChange,
        onCalibrationUnitValueChange
    } = props

    const isInvalidCalibrationRawValue = useCallback(() => {
        return calibrationRawValue === undefined || calibrationRawValue <= 0;
    }, [calibrationRawValue])

    const isInvalidCalibrationUnitValue = useCallback(() => {
        return isNaN(calibrationUnitValue) || parseFloat(calibrationUnitValue) < 0
    }, [calibrationUnitValue])

    return <>
    <div className="Title_Descripction">{rawTitle}:</div>
    <Input
      type="number"
      name="cud_rawValues"
      id="cud_rawValues"
      placeholder={rawTitle}
      className="InputForm descriptionInput"
      onChange={(e) => onCalibrationRawValueChange(e.target.value)}
      value={calibrationRawValue || ""}
      disabled={false}
      min={1}
      invalid={isInvalidCalibrationRawValue()}
    />

    <div className="Title_Descripction">{unitsTitle}:</div>
    <Input
      type="number"
      name="cud_unitsValue"
      id="cud_unitsValue"
      placeholder={`${unitsTitle}...`}
      className="InputForm descriptionInput"
      onChange={(e) => onCalibrationUnitValueChange(e.target.value)}
      value={!isNaN(calibrationUnitValue) ? calibrationUnitValue : ""}
      invalid={isInvalidCalibrationUnitValue()}
    />
  </>
}