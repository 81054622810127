import React, { useState, useEffect } from "react";
import { BarChart, XAxis, YAxis, Tooltip, ResponsiveContainer, Bar, CartesianGrid, Legend } from 'recharts';
export const RecipientRechart = (props) => {
    const { data, right ,maxvalue} = props


    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart className="barpostigel"  data={data}>

                {right ? <YAxis
                    type="number" unit="L" orientation="right" domain={[0, maxvalue]} /> : <YAxis
                    type="number" unit="L"  domain={[0,maxvalue]}/>}

                <Bar dataKey="liquido"
                    fill="#4180FF"
                    background={{ fill: '#eee' }}
                //radius={[0, 10, 10, 0]}
                />

            </BarChart>
        </ResponsiveContainer>


    );
};
