export class OutputType {
    id;
	description;
	abbreviation;
	hierarchyLevel;
    order;
    createdAt;
    modifiedAt;

    constructor(id, description, abbreviation, hierarchyLevel, order){
        this.id = id;
        this.description = description;
        this.abbreviation = abbreviation;
        this.hierarchyLevel = hierarchyLevel;
        this.order = order;
    }

    static parseFromPlainDto(dtoText){
        const plainObject = JSON.parse(dtoText);
        return OutputType.parseOutputDtoFromObject(plainObject);
    }

    static parseFromObjectDto(dtoObject){
        if(dtoObject){
            return Object.setPrototypeOf(dtoObject, OutputType.prototype);
        }
        return dtoObject;
    }
}