import { createTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Slider, { SliderThumb } from "@mui/material/Slider";

const theme = createTheme({
  palette: {
    type: "light",

    primary: {
      main: "#52433C",
      dark: "#2A160B",
      light: "#787473",
    },
    secondary: {
      main: "#5893df",
      dark: "#14779e",
      light: "#5fc4eb",
    },
    background: {
      default: "#FFFBFA",
      blue: "#4DCDE6",
    },
  },
  typography: {
    fontSize: 14,
    color: "default",
    variantMapping: {
      h1: "h2",
      h2: "h3",
      h3: "h4",
      h4: "h5",
      h5: "h6",
      h6: "subtitle1",
      subtitle1: "subtitle2",
      subtitle2: "body1",
      body1: "body2",
      body2: {
        fontSize: 10,
        color: "#5893df",
      },
      caption: "caption",
      overline: "overline",
    },
  },
});



export const StyledDayButton = styled(ToggleButton)({
  "&.MuiToggleButton-root": {
    width: 32,
    height: 32,
    
    padding: 6,
    variant: "h6",
    backgroundColor: "#C9F0F8",
    "&:hover": {
        width: 32,
        height: 32,
      backgroundColor: "#C9F0F8",
    },
  },

  "&.Mui-disabled": {
    width: 32,
    height: 32,
    backgroundColor: "#C9F0F8",
  },

  "&.Mui-selected": {
    width: 32,
    height: 32,
    backgroundColor: "#4DCDE6",
    "&.Mui-disabled": {
        width: 32,
        height: 32,
      backgroundColor: "#4DCDE6",
    },
  },

  "&.Mui-selected:hover": {
    width: 32,
    height: 32,
    backgroundColor: "#4DCDE6",
  },
});

export const StyledOutputButton = styled(ToggleButton)({
    "&.MuiToggleButton-root": {
      width: 28,
      height: 28,
      color: "#2A160B",
      backgroundColor: "#FFFFFF",
      fontSize: 12,
      "&:hover": {
        width: 28,
        height: 28,
        color: "#2A160B",
        backgroundColor: "#FFFFFF",
        fontSize: 12,
      },
    },
  
    "&.Mui-disabled": {
      width: 28,
      height: 28,
      color: "#2A160B",
      backgroundColor: "#FFFFFF",
      fontSize: 12,
    },
  
    "&.Mui-selected": {
      width: 28,
      height: 28,
      color: "#FFFFFF",
      backgroundColor: "#2A160B",
      fontSize: 12,
       "&.Mui-disabled": {
        width: 28,
        height: 28,
        color: "#FFFFFF",
        backgroundColor: "#2A160B",
        fontSize: 12,
        },
    },
  
    "&.Mui-selected:hover": {
      width: 28,
      height: 28,
      color: "#FFFFFF",
      backgroundColor: "#2A160B",
      fontSize: 12,
     },
  });

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
   
    "& .MuiToggleButtonGroup-grouped": {
      margin: theme.spacing(.5),
      "&.Mui-disabled": {
      },
      "&:not(:first-of-type)": {
        borderRadius: theme.shape.borderRadius,
      },
      "&:first-of-type": {
        borderRadius: theme.shape.borderRadius,
      },
      "&.Mui-selected": {
      //  margin: theme.spacing(.5),
      },
    },
  })
);

export const IOSSlider = styled(Slider)(({ theme }) => ({
  height: 1,
  //padding: "0px 0",
  "& .MuiSlider-thumb": {
   
    backgroundColor: theme.palette.secondary,
    "&:focus, &:hover, &.Mui-active": {
      borderColor: "#4DCDE6",
    },
  },
  "& .MuiSlider-valueLabel": {
    fontWeight: "normal",
    top: 1,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "& .Mui-active": {},
  },

  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-track-disabled": {
    borderColor: "#1CABE2",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
  "& .MuiSlider-markLabel": {
    color: "#3F3F3F",
    marginTop: 0,
    fontSize: 12,
    textAlign: "center",
  },
  "& .Mui-active": {
    backgroundColor: "primary",
    color: "#5FC4EB",
  },

  "& .MuiSlider-disabled": {
    backgroundColor: "primary",
    fontSize: 12,
    color: "#1CABE2",
  },
  "& .MuiSlider-dragging": {},
}));

export const boxShadow =
  "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)";

export default theme;
