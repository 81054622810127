import React, { useCallback, useMemo, useState } from "react";
import { Button, Input, InputGroup } from "reactstrap";

export const ReceiversAssociationView = (props) => {
  const { receivers, onReceiverDelete, onReceiverChange } = props;

  const getReceiverInput = useCallback((receiver, index) =>
   <ReceiverView key={index} receiver={receiver} index={index} onReceiverChange={onReceiverChange} onReceiverDelete={onReceiverDelete} />
  , [onReceiverChange, onReceiverDelete]);

  const getReceivers = useCallback(() => {
      if(receivers instanceof Array){
          return receivers.map((receiver, index) => getReceiverInput(receiver, index))
      }
      return <></>
  }, [getReceiverInput, receivers])

  return <>{getReceivers()}</>
};

const ReceiverView = props => {
  const {receiver, index, onReceiverChange, onReceiverDelete} = props

  const states = useMemo(() => ({
    mail: "mail",
    phone: "phone"
  }),[])
  const initState = useMemo(() => ({
    state: states.mail
  }), [states.mail])

  const [state, setState] = useState(initState)

  const onEmailChange = useCallback(value => {
    if(receiver){
      receiver.email = value
      onReceiverChange(receiver)
    }
  }, [onReceiverChange, receiver])

  const onPhoneChange = useCallback(value => {
    if(receiver){
      receiver.phone = value
      onReceiverChange(receiver)
    }
  }, [onReceiverChange, receiver])

  const onChangeToPhone = useCallback(() => {
    setState(current => ({...current, state: states.phone}))
  }, [states.phone])
  const onChangeToEmail = useCallback(() => {
    setState(current => ({...current, state: states.mail}))
  }, [states.mail])

  return (
    <InputGroup key={index}>
      {state.state === states.mail &&
        <Input placeholder="Email destinatario..." 
        autoFocus={receiver?.email === undefined} 
        value={receiver?.email || ""}
        onChange={event => onEmailChange(event.target.value)}
        />
      }
      {state.state === states.phone &&
        <Input placeholder="Telefono destinatario..." 
        autoFocus={receiver?.phone === undefined} 
        value={receiver?.phone || "+34"}
        onChange={event => onPhoneChange(event.target.value)}
        />
      }

        {state.state === states.mail &&
          <Button color="primary" outline onClick={() => onChangeToPhone()}>
            <i className="fa fa-phone mr-2"></i>
          </Button>
        }
        {state.state === states.phone &&
          <Button color="primary" outline onClick={() => onChangeToEmail()}>
            <i className="fa fa-at mr-2"></i>
          </Button>
        }
        <Button color="danger" outline onClick={() => onReceiverDelete(receiver)}>
          <i className="fa fa-close mr-2"></i>
        </Button>
    </InputGroup>
  );
}
