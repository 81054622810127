import { Sensor } from "../../Sensor/Sensor";

export class TerminalDeviceSensor {
    terminalDevice;
	sensorId;
	sensorIndex;
	active;
    triggers;
	hasTempSensor;
	statusAt;
	status;
	error;
	lastValue;
	lastValueAt;

    static parseFromPlainDto(dtoText){
        const plainObject = JSON.parse(dtoText);
        return TerminalDeviceSensor.parseOutputDtoFromObject(plainObject);
    }

    static parseFromObjectDto(dtoObject){
        if(dtoObject){
            const terminalDeviceSensor = Object.setPrototypeOf(dtoObject, TerminalDeviceSensor.prototype);

            if(dtoObject.sensorId instanceof Object){
                terminalDeviceSensor.sensorId = Sensor.parseFromObjectDto(dtoObject.sensorId);
            }

            return terminalDeviceSensor;
        }
        return dtoObject;
    }
}