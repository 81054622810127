import React, { useCallback, useState, useEffect } from "react";
import Image1 from "./images/basic/programas1.png";
import Image2 from "./images/basic/programas2.png";
import Image3 from "./images/basic/programas3.png";
export const Helps = (props) => {
  return (
    <>
      <div className="documentation">
        <div className="helptitle">
          Información sobre programas y subprogramas.
        </div>
        <div className="subhelptitle">
          Crea programas para automatizar por tiempo las activaciones de las
          salidas.
        </div>
        <div className="subhelptitle">
          Puedes elegir un tiempo de inicio seleccionando una hora y uno de
          repetición dándole al botón de programar salidas.
        </div>
        <div className="ImageCenterhelp">
          <img
            src={Image3}
            alt="Programshelpimage3"
            className="ImageHelpPrograms"
          />
        </div>

        <div className="subhelptitle">
          Se creará una tarjeta con las salidas y el tiempo elegido.
        </div>

        <div className="subhelptitle">
          La hora de inicio es el marcado en el reloj y el tiempo de las
          tarjetas indican el tiempo que estarán activadas las salidas desde la
          fecha de inicio.
        </div>
        <div className="subhelptitle">
          Estas se crean en el menu que aparece al darle programar salidas.
        </div>
        <div className="ImageCenterhelp">
          <img
            src={Image1}
            alt="Programshelpimage1"
            className="ImageHelpPrograms"
          />
        </div>

        <div className="subhelptitle">
          La tarjeta en verde significa que esta guardada en el servidor. No se
          puede modificar.{" "}
        </div>
        <div className="subhelptitle">
          {" "}
          La tarjeta naranja no ha sido guardada todavía y puedes editarla o
          borrarla. En caso de querer borrar una verde, borra el programa y crea
          otro nuevo.
        </div>
        <div className="ImageCenterhelp">
          <img
            src={Image2}
            alt="Programshelpimage2"
            className="ImageHelpPrograms"
          />
        </div>

        <div className="subhelptitle">
          Primero elige una fecha de partida y luego añade tantas tarjetas como
          quieras.
        </div>
        <div className="subhelptitle">
          Para editar el orden de las tarjetas intercambia su posición,
          arrastrando en mvl o moviendolas con el ratón en pc.
        </div>
      </div>
    </>
  );
};
