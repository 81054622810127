import React, { useCallback, useEffect, useRef, useState } from "react"
import { projectsConstants } from "../../../../_constants/projects.constants"
import "./OutputCircle.sass"

export const OutputCircle = (props) => {

    const {
      output,
      title,
      disabled,
      colorLed,
      shadowColor,
      onClick,
      onClickOnDisabled,
      mustUseLongPress,
    } = props

    // No podemos utilizar tan solo el estado puesto que la instancia de onTimeout cuando se llama dentro de onTouchStart tendrá siempre el isPressed_state a true
    const [isPressed_state, setIsPressed_state] = useState(false)
    const isPressed = useRef(false);
    const timeoutIdContainer = useRef(0);

    const LONG_PRESS_MILISECONDS = 1000;

    const onClickDone = useCallback(event => {
      disabled ? onClickOnDisabled(output.output) : onClick(output.output)
    }, [disabled, onClick, onClickOnDisabled, output])

    const onTimeout = () => {
      if(isPressed.current){
        isPressed.current = false;
        setIsPressed_state(false);
        onClickDone()
      }
    }

    const onTouchStart = event => {
      if(output.currentState !== projectsConstants.global.states.output_transition){
        const startAt = Date.now()
        isPressed.current = true;
        setIsPressed_state(isPressed.current)
        timeoutIdContainer.current = setTimeout(onTimeout, LONG_PRESS_MILISECONDS, startAt)
      }
    }

    const onTouchEnd = useCallback(event => {
        isPressed.current = false
        setIsPressed_state(isPressed.current)
    }, [])

    return <div
    title={`Salida: ${title}`}
    className={`NumberOutput ${disabled && "notpointer"}`}
  >
    {mustUseLongPress && 
    <div 
      className={`numberCircle ${isPressed_state && "pressed"}`} 
      style={{borderColor: shadowColor, backgroundColor: disabled ? "#999999" : colorLed, }}
      onTouchStart={e => onTouchStart()}
      onTouchEnd={e => onTouchEnd()}
      onMouseDown={e => onTouchStart()}
      onMouseUp={e => onTouchEnd()}
      >
        {output.output}
    </div>
    }
    {!mustUseLongPress && 
    <div onClick={e => {onClickDone(e)}} className={`numberCircle`} style={{borderColor: shadowColor, background: disabled ? "#999999" : colorLed, }}>{output.output}</div>
    }
  </div>
}