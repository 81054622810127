import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import IcoPending from "./pendingIcon.png";
import Timer from "../../../../../Timer/Timer";
import { swing } from "react-animations";
import Radium, { StyleRoot } from "radium";

import DeleteIco from "./icoIos.png";
import "./ProgramBarItem.sass";
import { PopUpC } from "../../../../../Airframe/PopUp/PopUp";

import { useNavigate } from 'react-router-dom';
import { useApp } from "../../../../../../../context/app-context";
const styles = {
  AnimFirst: {
    //velocidad, si aumentas el tiempo va más lento, por eso los timeout una manera de controlar loop fijando velocidad.
    animation: "x 2s",
    animationName: Radium.keyframes(swing, "Animacion1"),
    animationIterationCount: Number.MAX_VALUE,
  },
};
export const ProgramBarItem = (props) => {
  //#region CONSTANTS
  const PROGRAM = "PROG";
  const intervalExectutionMiliseconds = 200;
  const longPressMinMiliseconds = 2000;
  //#endregion

  //#region STATES
  const {
    id,
    onSelected,
    selected,
    program,
    innerRef,
    onDelete,
    showAnimationdeleteAlls,
    showAnimationsDelete,
    terminalDevice,
    urlstate,
  } = props;
  const history = useNavigate();
  const { selectedZone, selectedSector, selectedTerminal } = useApp();
  const [initialTime, setinitialTime] = useState(new Date());
  const [
    ActivateTimerTocount1Second,
    setActivateTimerTocount1Second,
  ] = useState(false);
  const [longPressedIterations, setLongPressedIterations] = useState(0);
  const [isInDeletingMode, setIsInDeletingMode] = useState(false);
  const [confirmDeletePopUpActive, setConfirmDeletePopUpActive] = useState(
    false
  );
  const [outerClick, setOuterClick] = useState(0);
  //#endregion
  
  const ActivatingTimer = useCallback(() => {
    if (!isInDeletingMode) {
      const iterationsInMiliseconds =
        (longPressedIterations + 1) * intervalExectutionMiliseconds;
      let currentPogress =
        (iterationsInMiliseconds * 100) / longPressMinMiliseconds;
      currentPogress = Math.ceil(currentPogress / 5) * 5;

      setLongPressedIterations(longPressedIterations + 1);
      if (currentPogress >= 105) {
        //Desactivar temporizador
        showAnimationdeleteAlls(true);
        setActivateTimerTocount1Second(false);
        setLongPressedIterations(0);
      }
    }
  }, [
    longPressedIterations,
    showAnimationdeleteAlls,
    intervalExectutionMiliseconds,
    longPressMinMiliseconds,
    isInDeletingMode,
  ]);

  const CheckIfWantDelete = useCallback(() => {
    setinitialTime(new Date());
    if (!isInDeletingMode) {
      setActivateTimerTocount1Second(true);
    }
  }, [isInDeletingMode]);

  const SelectProgram = useCallback(() => {
    let zoneId = selectedZone?.id || urlstate.zoneId;
    let sectorId = selectedSector?.id || urlstate.sectorId;
    let terminalId = terminalDevice?.terminal || urlstate.terminalId;
    let terminalDeviceId = terminalDevice?.id || urlstate.terminalDeviceId;
    let programid = program?.id || "";

    history({
      pathname: `/zones/${zoneId}/sector/${sectorId}/terminals/${terminalId}/terminalDevices/${terminalDeviceId}/programs/${programid}`,
      dataprops: { ...props },
      programid,
      sectorId,
      terminalDeviceId,
      terminalId,
      zoneId,
    });
  }, [
    history,
    selectedZone,
    selectedSector,
    terminalDevice,
    program,
    urlstate,
  ]);

  const CheckActivationRangeTime = useCallback(() => {
    if (!isInDeletingMode) {
      const FinalTime = new Date();
      const time = FinalTime.getTime() - initialTime.getTime();
      // 5segundos para activar:
      if (time >= longPressMinMiliseconds) {
        //ShowAnimationdelete();
        showAnimationdeleteAlls(true);
      } else {
        if (onSelected) {
          onSelected(program);
        }
        SelectProgram();
      }
      setActivateTimerTocount1Second(false);
      setLongPressedIterations(0);
    } else {
      if (onSelected) {
        onSelected(program);
      }
      SelectProgram();
      setConfirmDeletePopUpActive(true);
    }
  }, [
    initialTime,
    onSelected,
    program,
    showAnimationdeleteAlls,
    onDelete,
    isInDeletingMode,
    SelectProgram,
  ]);

  const onPopUpClose = useCallback(() => {
    setConfirmDeletePopUpActive(false);
  }, []);

  const onPopUpConfirmDelete = useCallback(() => {
    setConfirmDeletePopUpActive(false);
    onDelete(program);
  }, [program, onDelete, SelectProgram]);

  //#region  useEffects
  //Si se activa un elemento un callback del padre pone a true las animaciones y deben animarse todos.
  useEffect(() => {
    setIsInDeletingMode(showAnimationsDelete);
  }, [props, showAnimationsDelete]);

  const handleClickOutside = useCallback((event) => {
    if (
      event.target.className === "nameprogramc" ||
      event.target.className === "ProgramsC" ||
      event.target.className === "ColorBarProgram" ||
      event.target.className === "IcoPending" ||
      event.target.className === "IcoDeleteProgram" ||
      event.target.className === "IcoDelProgram" ||
      event.target.className === "MessageConfirmPopUp" ||
      event.target.className === "close"
    ) {
      return;
    }
    setOuterClick((outerClick) => outerClick + 1);
  }, []);

  useEffect(() => {
    if (outerClick) {
      if (isInDeletingMode && selected && !confirmDeletePopUpActive) {
        showAnimationdeleteAlls(false);
      }
      setOuterClick(0);
    }
  }, [
    outerClick,
    isInDeletingMode,
    confirmDeletePopUpActive,
    selected,
    showAnimationdeleteAlls,
  ]);

  useEffect(() => {
    if (selected) {
      SelectProgram();
    }
  }, [selected]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("ontouchend", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("ontouchend", handleClickOutside);
    };
  }, [handleClickOutside]);
  //#endregion

  return (
    <>
      <PopUpC
        activate={confirmDeletePopUpActive}
        deactivatepopup={onPopUpClose}
        content={
          <div className="MessageConfirmPopUp">
            <div>Confirma para borrar {program?.getName() || ""}: </div>
            <div
              className="ButtonOffAll"
              onClick={(e) => onPopUpConfirmDelete()}
            >
              Confirmar
            </div>
          </div>
        }
      ></PopUpC>
      <StyleRoot
        style={isInDeletingMode ? styles.AnimFirst : undefined}
        onTouchEnd={CheckActivationRangeTime}
        onMouseUp={CheckActivationRangeTime}
        onTouchStart={CheckIfWantDelete}
        onMouseDown={CheckIfWantDelete}
        className={`ProgramsC ${selected ? "selected" : ""}`}
      >
        <div ref={innerRef} className="nameprogramc">
          <div className="nameprogramc">{PROGRAM}</div>
          <div className="nameprogramc">{id}</div>
        </div>
        <div className={`ColorBarProgram ${selected ? "selected" : ""}`}></div>

        {program.modified && (
          <>
            <div className="IcoPending">
              <img
                src={IcoPending}
                alt="Programs"
                className="PicPlugTD Small confOutputs"
              />
            </div>
          </>
        )}
        {isInDeletingMode && (
          <>
            <div className="IcoDeleteProgram">
              <img
                src={DeleteIco}
                alt="ButtonDeleteProgram"
                className="IcoDelProgram"
              />
            </div>
          </>
        )}
      </StyleRoot>
      {ActivateTimerTocount1Second ? (
        <Timer
          isActive={true}
          callback={ActivatingTimer}
          callbackFirstExecutionDelayMiliseconds={0}
          callbackExecutionEveryMiliseconds={intervalExectutionMiliseconds}
          timerIntervalMiliseconds={intervalExectutionMiliseconds}
        />
      ) : (
        <></>
      )}
    </>
  );
};
ProgramBarItem.propTypes = {
  id: PropTypes.number.isRequired,
  onSelected: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};
