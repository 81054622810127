import { projectsConstants } from "./projects.constants";

export const terminalsConstants = {
  get: {
    request: "TERMINALS_GET_REQUEST",
    response: "TERMINALS_GET_RESPONSE",
    error: "TERMINALS_GET_ERROR"
  },
  type: {
    lora_outputs: "LORA_OUTPUTS",
    master_outputs: "MASTER_OUTPUTS"
  },
  devices: {
    selected: "TERMINAL_DEVICE_SELECTED",
    get: {
      request: "TERMINAL_DEVICE_GET_REQUEST",
      response: "TERMINAL_DEVICE_GET_RESPONSE",
      error: "TERMINAL_DEVICE_GET_ERROR",
      request_all: "TERMINAL_DEVICE_GET_REQUEST_ALL",
      response_all: "TERMINAL_DEVICE_GET_RESPONSE_ALL"
    },
    closed: "TERMINAL_DEVICE_ACTION_CARD_CLOSED",
    output: {
      action: "TERMINAL_DEVICE_OUTPUT_ACTION",
      notification: "TERMINAL_DEVICE_OUTPUT_NOTIFICATION"
    },
    meterData: {
      reveiced: "TERMINAL_DEVICE_METER_DATA_RECEIVED",
      updated: "TERMINAL_DEVICE_METER_DATA_UPDATED"
    }
  },
  status: {
    online: {
      transition: undefined,
      active: "Active",
      deactived: "Deactived"
    }
  }
};

export const getProjectId = terminalTye => {
  switch (terminalTye) {
    case terminalsConstants.type.lora_outputs:
      return projectsConstants.lora_outputs.id;
    case terminalsConstants.type.master_outputs:
      return projectsConstants.master_outputs.id;
    default:
      return "unknown";
  }
};
