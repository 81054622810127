import React, { useState, useCallback, useEffect } from "react";
import { Sectorsfather } from "./../../components/Sectors/Sectorsfather";
import { TecoSidebar } from "./../../TecoSidebar/TecoSidebar";
import { TecoNavbar } from "./../../TecoNavbar/TecoNavbar";
import TecoLayout from "./../../Layout/TecoLayout";
import { useNavigate, useParams } from 'react-router-dom';
import { useApp } from "../../../context/app-context";
import { zoneService } from "../../../_services/zone.service";

export const PageSectors = (props) => {
  const { selectedZone, setSelectedZone } = useApp();
  let params = useParams();
  const [zoneId] = useState(params?.zoneId || 0);
  const history = useNavigate();

  useEffect(() => {
    if (selectedZone?.id !== zoneId) {
      zoneService
        .getZone(zoneId)
        .then((zone) => {
          setSelectedZone(zone);
        })
        .catch((error) => {
          console.log("ERROR CARGANDO SECTOR");
        });
    }
  }, [selectedZone?.id, setSelectedZone, zoneId]);

  const Return = useCallback(() => {
    history(`/zones`);
  }, [history]);

  return (
    <>
      <TecoLayout
        routedNavBar={() => <TecoNavbar NavMenu={"Sectors"} zoneId={zoneId} />}
        routedSideBar={() => <TecoSidebar />}
      >
        <div className="ContainerZones">
          <div className="titleApp">{selectedZone?.name}</div>
          <Sectorsfather zoneId={zoneId} changeTitle={zoneId} />
        </div>
      </TecoLayout>
    </>
  );
};
