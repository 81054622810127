export class SensorMeasurement {
    id;
	sensor;
	measurementUnit;
	sensorUnit;
	equation;
    calibrationRawValue;
    calibrationUnitValue;

    static parseFromPlainDto(dtoText){
        const plainObject = JSON.parse(dtoText);
        return SensorMeasurement.parseOutputDtoFromObject(plainObject);
    }

    static parseFromObjectDto(dtoObject){
        if(dtoObject){
            return Object.setPrototypeOf(dtoObject, SensorMeasurement.prototype);
        }
        return dtoObject;
    }
}