import React from "react";

import { ReactComponent as CircleSvg } from "./circlenotfill.svg";
export const OutputconfigLegend = (props) => {
  return (
    <div
      className="legend">
      <div className="legendChild">
        <div>
          <CircleSvg className="legendcircle Color1C" />
        </div>
        <div> Modo Continuo</div>
      </div>

      <div className="legendChild">
        <div>
          <CircleSvg className="legendcircle Color2C" />
        </div>
        <div> Pulsos sin polaridad</div>
      </div>

      <div className="legendChild">
        <div>
          <CircleSvg className="legendcircle Color3C" />
          {/**.SvgIcoR2 */}
        </div>
        <div> Pulsos con polaridad</div>
      </div>
      <div className="legendChild">
        <div>
          <CircleSvg className="legendcircle Color4C" />
        </div>
        <div> Pulsos por minuto</div>
      </div>
    </div>
  );
};
