import React from "react";
import PropTypes from "prop-types";

export const CalibrationStepPointDone = (props) => {
    return (
        <>
          <div>
            ¡Calibrado realizado con éxito!
          </div>
          <div className="ButtonTSMf">
            <div className="buttonTSMinColumn">
              <div className="ButtonTSM withStep">
                <div
                  className="ButtonOffAll"
                  onClick={(e) => {
                    props.onNext();
                  }}
                >
                  Siguiente
                </div>
              </div>
            </div>
          </div>
        </>
      );
}

CalibrationStepPointDone.propTypes = {
  onNext: PropTypes.func.isRequired
};