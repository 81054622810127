import React from "react";

import "./Badge.sass";

export const Badge = (props) => {
  const { value, extraClassName } = props;

  return (
    <div className={`custom-badge rounded-pill ml-auto ${extraClassName}`}>
      {value}
    </div>
  );
};
