import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./DragDrop.sass";
import { TitleDragDrop } from "./TitleDragDrop";

// fake data generator
const getItems = (count) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k}-${Math.floor(Math.random() * 101)}`,
    content: `item ${k}`,
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  display: "flex",
  padding: grid,
  overflow: "auto",
});

export class DragDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.content,
      numberCardsNotified: undefined,
      desactivate: false,
      ClassDragDrop: "DragDropProvided Main",
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props?.content && this.props?.content !== prevProps?.content) {
      if (this.props.numberMaxNotified > 0 && this.props.numberElements != 0) {
        //Desactivate
        this.setState({
          desactivate: true,
          ClassDragDrop: "DragDropProvided Main deactivateDrag",
        });
      } else {
        //Activate
        this.setState({
          desactivate: false,
          ClassDragDrop: "DragDropProvided Main",
        });
      }
      this.setState({
        items: this.props.content,
        numberCardsNotified: this.props.numberMaxNotified,
      });
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });
    if (items instanceof Array) {
      this.props.itemstoReorganized(items.map((item) => item?.key));
    }
  }

  getPrePostContent(droppableIdExtension, source) {
    return (
      <Droppable
        droppableId={`droppable-${droppableIdExtension}`}
        direction="horizontal"
        className="flex end"
        isDropDisabled={true}
        isCombineEnabled={false}
      >
        {(provided, snapshot) => (
          <div ref={provided.innerRef} className={"DragDropProvided prepost"}>
            {source.map((item, index) => {
              return (
                <Draggable
                  key={index}
                  draggableId={`Pre-${index}`}
                  index={index}
                  isDragDisabled={true}
                  disableInteractiveElementBlocking={true}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div>{item.content}</div>
                    </div>
                  )}
                </Draggable>
              );
            })}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }

  getPreContent() {
    return this.getPrePostContent("pre", this.props.preContent);
  }

  getPosContent() {
    return this.getPrePostContent("pos", this.props.posContent);
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        {/*    <TitleDragDrop desactivate={this.state.desactivate} />*/}
        <div style={{ display: "flex", width: "100%", height: "auto" }}>
          {this.props?.preContent && this.getPreContent()}

          <Droppable
            droppableId="droppable"
            direction="horizontal"
            className="flex end"
          >
            {(provided, snapshot) => (
              <div ref={provided.innerRef} className={this.state.ClassDragDrop}>
                {this.state.items.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={
                      this.state.desactivate || item.isDragDisabled
                    }
                  >
                    {(provided, snapshot) => (
                      <div>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={
                            snapshot.isDragging
                              ? "DragDropSelect"
                              : "DragDropNotSelect"
                          }
                        >
                          {item.content}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {this.props?.posContent && this.getPosContent()}
        </div>
      </DragDropContext>
    );
  }
}
