import React from "react";
import { projectsConstants } from "../../../../../../_constants/projects.constants";

export const CalibrationPointsSelection = (props) => {
  const { onPointsSelected } = props;

  return (
    <div className="contentPopupCalibrate">
      <div>Calibrar pH</div>
      <div>
        El calibrado del punto medio implica volver a calibrar los otros dos puntos.
      </div>

      <div className="ButtonTSMf">
        <div className="buttonTSMinColumn">
          <div className="ButtonTSM withStep">
            <div
              className="ButtonOffAll"
              onClick={(e) => onPointsSelected(projectsConstants.master_outputs.tsm.points.two)}
            >
              Punto medio (7pH)
            </div>
          </div>
          <div className="ButtonTSM withStep">
            <div className="ButtonOffAll Secondary" onClick={(e) => onPointsSelected(projectsConstants.master_outputs.tsm.points.one)}>
              Punto bajo (4pH)
            </div>
          </div>
          <div className="ButtonTSM withStep">
            <div className="ButtonOffAll Secondary" onClick={(e) => onPointsSelected(projectsConstants.master_outputs.tsm.points.three)}>
              Punto alto (10pH)
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
