import React, { useEffect, useState, useCallback, useMemo } from "react";
import { zoneService } from "./../../../_services/zone.service";
import { Sector } from "./Sector";
import { Spinner } from "./../../components/Airframe/Spinner/Spinner";
import "./Sectors.sass";
import { DangerComponent } from "../../DangerComponent/DangerComponent";
import "../Zones/Zones.sass"

export const Sectorsfather = (props) => {
  const { zoneId } = props;

  const states = {
    IDLE: "IDLE",
    LOADING: "LOADING",
    LOADED: "LOADED",
    ERROR: "ERROR",
  };
  const initState = useMemo(() => {
    return {
      state: states.IDLE,
      sectors: [],
    };
  }, [states.IDLE]);
  const [state, setState] = useState(initState);

  const LoadZones = useCallback(() => {
    zoneService.getZoneSectors(zoneId).then(
      (tmpSectors) => {
        setState((prev) => {
          return { ...prev, state: states.LOADED, sectors: tmpSectors?.zoneSectors || [] };
        });
      },
      (error) => {
        setState((prev) => {
          return { ...prev, state: states.ERROR, sectors: [] };
        });
      }
    );
  }, [states.ERROR, states.LOADED, zoneId]);

  useEffect(() => {
    if (state.state === states.IDLE) {
      setState((prev) => {
        return { ...prev, state: states.LOADING };
      });
      LoadZones();
    }
  }, [LoadZones, state.state, states.IDLE, states.LOADING]);

  const getSectorsComponent = useCallback(() => {
    if (state.sectors?.length > 0) {
      return state.sectors.map((sector, index) => (
        <Sector key={sector?.id || index} zoneId={zoneId} sector={sector} />
      ));
    } else {
      return <DangerComponent message={"No existen Sectores en esta zona."}></DangerComponent>;
    }
  }, [state.sectors, zoneId]);

  return (
    <>
      {(state.state === states.LOADING || state.state === states.IDLE) && (
        <div className="LoadSpinner heightspinner">
          <Spinner size="50px"></Spinner>
        </div>
      )}
      {state.state === states.LOADED && (
        <div className="ZonesDiv">{getSectorsComponent()}</div>
      )}
      {state.state === states.ERROR && (
        <DangerComponent message="Error cargando los sectores."></DangerComponent>
      )}
    </>
  );
};
