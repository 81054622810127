import { TerminalDeviceCalibration } from "../Calibration/TerminalDeviceCalibration";

export class Output {
    terminalDevice; //-
	output; //-
    intensity;//-
    currentState; //"DEACTIVATED"
    expectedState; // "DEACTIVATED"
    lastCurrentStateUpdate;
    lastExpectedStateUpdate;
    readyToWork; // true
    activationType // 1
    activationTypeExpected; // 1
    activationParams; // ""
    description;// ""
    currentAction; // 0
    outputCalibration;
    digitalSensor;

    static parseOutputDto(outputDtoText){
        const plainObject = JSON.parse(outputDtoText);
        return Output.parseOutputDtoFromObject(plainObject);
    }

    static parseOutputDtoFromObject(outputDtoPlainObject){
        const output = Object.setPrototypeOf(outputDtoPlainObject, Output.prototype);
        if(output.outputCalibration){
            output.outputCalibration = TerminalDeviceCalibration.parseFromObjectDto(output.outputCalibration);
        }
        return output;
    }


    getActivationType() {
        return this.activationType;
    }

    getActivationTypeExpected() {
        return this.activationTypeExpected;
    }
}