import React from "react";
import { Link } from "react-router-dom";

import { Sidebar } from "../Sidebar/Sidebar";
import { SidebarTrigger } from "../SidebarTrigger/SidebarTrigger";

import { TecoSidebarTop } from "./TecoSidebarTop";
import { TecoSidebarMiddleNav } from "./TecoSidebarMiddleNav";
import { LogoThemed } from "../login/components/LogoThemed";
import { TecoSidebarBottom } from "./TecoSidebarBottom";

export const TecoSidebar = () => (
  <Sidebar>
    {/* START SIDEBAR-OVERLAY: Close (x) */}
    <Sidebar.Close>
      <SidebarTrigger tag={"a"}>
        <i className="fa fa-times-circle fa-fw"></i>
      </SidebarTrigger>
    </Sidebar.Close>
    {/* START SIDEBAR-OVERLAY: Close (x) */}

    {/* START SIDEBAR: Only for Desktop */}
    <Sidebar.HideSlim>
      <Sidebar.Section>
        <Link to="/" className="sidebar__brand">
          <LogoThemed checkBackground customStyle={{maxWidth: "8rem"}} />
        </Link>
      </Sidebar.Section>
    </Sidebar.HideSlim>
    {/* END SIDEBAR: Only for Desktop */}

    {/* START SIDEBAR: Only for Mobile
          //Fix position datauser, outside MobileFluid (not scroll)
           */}
    <TecoSidebarTop />
    <Sidebar.MobileFluid>
      <Sidebar.Section fluid cover>
        {/* SIDEBAR: Menu */}
        <TecoSidebarMiddleNav />
      </Sidebar.Section>
      <TecoSidebarBottom />
    </Sidebar.MobileFluid>
    {/* END SIDEBAR: Only for Mobile */}
  </Sidebar>
);
