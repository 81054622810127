import React, { useEffect, useCallback, useState, useRef } from "react";
import { Input } from "reactstrap";
import { DataPicker } from "../../components/Airframe/Datapicker/Datapicker";

import { ReactComponent as Keypng } from "./Step1_EnterDateAndListTerminals/components/images/giveKey.svg";
import { ReactComponent as Infosvg } from "./Step1_EnterDateAndListTerminals/components/images/exclamation.svg";
import Historical from "./Step1_EnterDateAndListTerminals/components/images/Historico.png";
import Popup from "reactjs-popup";
import "./PageTokens.sass";
import "./Step1_EnterDateAndListTerminals/Token.sass";
import "./Step1_EnterDateAndListTerminals/Page3.sass";
import "reactjs-popup/dist/index.css";
import { SelectTerminalsPage } from "./Step2_SelectTerminalsToShareTokens/SelectTerminals";
import { Documentation } from "./Step1_EnterDateAndListTerminals/components/Documentation";
import { TecoSidebar } from "../../TecoSidebar/TecoSidebar";
import { TecoNavbar } from "../../TecoNavbar/TecoNavbar";
import { PopUpC } from "../../components/Airframe/PopUp/PopUp";
import { terminalService } from "../../../_services/terminal.service";
import { useUser } from "../../../context/user-context";
import TecoLayout from "../../Layout/TecoLayout";
import { ButtonsShareF } from "./Step1_EnterDateAndListTerminals/components/ButtonsShareF";
import { TokenManager } from "./TokenManager/Historic";
import { tokenService } from "../../../_services/token.service";

export const PageTokens = (props) => {
  const { user } = useUser();
  const [OpenSelectKeysPopUp, setOpenSelectKeysPopUp] = useState(false);
  const [terminals, setterminals] = useState(undefined);
  const [terminalsLoadError, setterminalsLoadError] = useState(false);
  const [daysform, setdaysform] = useState(1);
  const [messageErrorDays, setmessageErrorDays] = useState(0);
  const [messageErrorName, setmessageErrorName] = useState(0);
  const [startDate, setstartDate] = useState(new Date());
  const [classNavPillDay, setclassNavPillDay] = useState("nav-link");
  const [classNavPillData, setclassNavPillData] = useState("nav-link active2");
  const [modeDataorDay, setmodeDataorDay] = useState(1);
  const [modeDataorDayChanged, setmodeDataorDayChanged] = useState(true);
  const [
    selectTerminalsToShowButtonsBool,
    setselectTerminalsToShowButtonsBool,
  ] = useState(false);
  const [endDate, setendDate] = useState(() => {
    let d = new Date();
    d.setDate(startDate.getDate() + 1);
    return d;
  });
  const [alldataform, setalldataform] = useState({
    name: undefined,
    from: new Date(),
    until: new Date() + parseInt(1),
  });
  const [tokenCreating, settokenCreating] = useState(false);
  const [tokenError, settokenError] = useState(false);
  const [checkDatesInRangeState, setcheckDatesInRangeState] = useState(0);
  const [datapickerclass, setdatapickerclass] = useState(
    "datapickeractive form-control"
  );
  const [activatePopUp, setactivatePopUp] = useState(false);
  const [activateInfo, setactivateInfo] = useState(false);
  const [activateHistorical, setactivateHistorical] = useState(false);
  const [token, settoken] = useState(undefined);
  const [terminalsselected, setterminalsselected] = useState(undefined);
  const [listofterminalsave, setlistofterminalsave] = useState([undefined]);

  const [selectdayclassActive, setselectdayclassActive] = useState(
    "bg-white tokenInput DaysKey"
  );
  const [
    errorTerminalsnotSelectedWarningUI,
    seterrorTerminalsnotSelectedWarningUI,
  ] = useState(false);
  const [namecard, setnamecard] = useState(["Invitado"]);
  const [name, setname] = useState("Invitado");
  const InputDay = useRef(null);
  const InputName = useRef(null);
  const closeDocumentation = useCallback(() => {
    setactivateInfo(false);
  }, [activateInfo]);

  const Createtoken = useCallback(
    (startDate, endDate, name, list) => {
      let namenoEmpty = name;
      if (!namenoEmpty) {
        namenoEmpty = user.username;
      }

      setselectTerminalsToShowButtonsBool(false);
      let DataOut = endDate;
      if (typeof DataOut !== "string") {
        DataOut = DataOut.toISOString();
      }
      let DataToPost = {
        validSince: startDate.toISOString(),
        validUntil: DataOut,
        roles: ["ROLE_TGATE"],
        description: namenoEmpty,
        terminals: list.map((terminal) => terminal.id),
      };

      tokenService.createToken(DataToPost).then(
        function (result) {
          setselectTerminalsToShowButtonsBool(true);
          const token = result.token;

          settoken(token);
          TerminalsSelected(list, result.token);
          settokenCreating(false);
          settokenError(false);
        },
        (error) => {
          settokenCreating(false);
          settokenError(true);
        }
      );
    },
    [token, selectTerminalsToShowButtonsBool]
  );

  const closeHistorical = useCallback(() => {
    setactivateHistorical(false);
  }, []);

  const showHistorical = useCallback(() => {
    setselectTerminalsToShowButtonsBool(false);
    setactivateHistorical(true);
    setselectTerminalsToShowButtonsBool(true);
  }, [selectTerminalsToShowButtonsBool]);

  const showInfo = useCallback(() => {
    setactivateInfo(true);
  }, [activateInfo]);

  const ButtonShare = useCallback(
    (tokenStored) => {
      let token =tokenStored.token;
      let name4 = tokenStored.description;
      let terminals = tokenStored.terminals;
      
      let formwithname = alldataform;
      setnamecard(name4);

      formwithname.name = name4;

      setalldataform(formwithname);
      setactivateHistorical(false);
      setselectTerminalsToShowButtonsBool(true);
      settoken(token);
      setterminalsselected(terminals);
    },
    [
      namecard,
      alldataform,
      token,
      terminalsselected,
      activateHistorical,
      selectTerminalsToShowButtonsBool,
    ]
  );

  //Contiene ids y token:
  const TerminalsSelected = useCallback(
    (list, token) => {
      setactivatePopUp(false);
      setactivateHistorical(false);
      setselectTerminalsToShowButtonsBool(true);
      settoken(token);
      setterminalsselected(list);
    },
    [token, terminalsselected, activateHistorical]
  );

  const setOption = useCallback((option) => {
    if (option === "Day") {
      setclassNavPillDay("nav-link");
      setclassNavPillData("nav-link active2");
      setmodeDataorDay(1);
    } else {
      setclassNavPillDay("nav-link active2");
      setclassNavPillData("nav-link");

      setmodeDataorDay(0);
    }
    setmodeDataorDayChanged(true);
  }, []);
  const deactivatePopUp = useCallback(() => {
    setactivatePopUp(false);
  }, [activatePopUp]);

  const ShowShareButtonsDown = useCallback(
    (id, Nterminales) => {
      let errord = 0;
      const dataform = {
        name: alldataform.name,
        from: startDate,
        until: endDate,
      };
      if (!dataform.name) {
        setmessageErrorName(1);
        errord = 1;
      } else {
        setmessageErrorName(0);
      }

      if (errord == 0) {
        const validDates = CheckDates(dataform.from, dataform.until);
        if (validDates) {
          setalldataform(dataform);
          setactivatePopUp(true);

          if (Nterminales === 1) {
            // Tan solo hay un Terminal disponible. Lo asociamos automaticamente y pasamos al paso 3.
          } else {
            // setactivatePopUp(true);
          }
        }
      }
    },
    [startDate, endDate, messageErrorName, modeDataorDay, alldataform]
  );
  const Saveselectterminasls = useCallback(
    (terminalschoose) => {
      setlistofterminalsave(terminalschoose);

      setOpenSelectKeysPopUp(false);
      console.table(terminalschoose);
      if (terminalschoose.length > 0) {
        seterrorTerminalsnotSelectedWarningUI(false);
      } else {
        seterrorTerminalsnotSelectedWarningUI(true);
      }
      console.table(terminalschoose[0].description);
      settokenCreating(true);
      InputName.current.focus();
    },
    [listofterminalsave, errorTerminalsnotSelectedWarningUI, tokenCreating]
  );

  useEffect(() => {
    if (user !== null && name === "Invitado") {
      setname(user.username);
      setnamecard(user.username);
    }

    //Recovery Terminals:

    terminalService.getAll().then(
      (tmpTerminals) => {
        setterminals(tmpTerminals);
        setterminalsLoadError(false);
      },
      (error) => {
        setterminalsLoadError(true);
      }
    );
  }, [name, namecard]);

  //Get dates
  const getDataPicker = useCallback(
    (id, day) => {
      let start = startDate;
      let end = endDate;
      if (id === "start") {
        setstartDate(day);
        alldataform.from = day;
        start = day;
      } else if (id === "end") {
        setendDate(day);
        alldataform.until = day;
        end = day;
      }
      let Difference_In_Time = end - start;
      let inDays = Math.round(Difference_In_Time / (1000 * 3600 * 24));

      setdaysform(inDays);
      const validDates = inDays > 0;
      setcheckDatesInRangeState(validDates ? 0 : 1);

      //ShowShareButtonsDown(id, terminals?.length || 0);
    },
    [startDate, endDate, terminals, daysform]
  );

  const CheckDataToCreateToken = useCallback(
    (selectedTerminals, name, datein, dateout) => {
      let listofTerminals = selectedTerminals;

      if (
        (listofTerminals.length < 0 || listofTerminals[0] === undefined) &&
        terminals.length === 1
      ) {
        listofTerminals = [terminals[0]];
      }
      if (listofTerminals.length > 0 && listofTerminals[0] !== undefined) {
        seterrorTerminalsnotSelectedWarningUI(0);

        if (checkDatesInRangeState !== 1) {
          settokenCreating(true);
          //console.log("Creando token");
          Createtoken(datein, dateout, name, listofTerminals);
          settoken(token);
        }
      } else {
        seterrorTerminalsnotSelectedWarningUI(1);
      }

      //CreateToken(startDate, endDate, alldataform.name, listofTerminals);
    },
    [token, errorTerminalsnotSelectedWarningUI, namecard, terminals]
  );
  //Get name
  const RecopileDatesForShow = useCallback(
    (data) => {
      if (data.id === "NameKey") {
        setname(data.value);
        setnamecard(data.value);
        alldataform.name = data.value;
      } else if (data.id == "DaysKey") {
        const days = UsersetDay(data.value);
        if (days[0] !== "error") {
          alldataform.from = days[0];
          alldataform.until = days[1];

          setstartDate(days[0]);
          setendDate(days[1]);
        } else {
          const days = parseInt(data.value);
          if (days <= 0) {
            let d = new Date();
            d.setDate(startDate.getDate() + (days === 0 ? -1 : days));
            setendDate(d);
          }
        }
      }
      setalldataform(alldataform);
      //ShowShareButtonsDown(data.id, terminals.length);
    },
    [name, alldataform, startDate, namecard]
  );
  //Get Day
  const UsersetDay = useCallback((daysString) => {
    const days = parseInt(daysString);

    setdaysform(days);
    if (days <= 0) {
      setmessageErrorDays(1);
      return ["error", "error"];
    } else if (isNaN(days)) {
      setmessageErrorDays(0);
      setdatapickerclass("datapickeractive form-control");
      return ["error", "error"];
    } else {
      setmessageErrorDays(0);
      //setdatapickerclass("datapickerdeactive");
      const actualday = new Date();
      var finalday = new Date();
      finalday.setDate(actualday.getDate() + parseInt(days));
      return [actualday, finalday];
    }
  });
  //Show Saves
  const ShowSaveKeys = useCallback(() => {
    setOpenSelectKeysPopUp(false);
  });

  //-------------Functions Secondarys
  const CheckDates = useCallback((validSince, validUntil) => {
    let value = +validUntil > +validSince;
    return value === true;
  });

  const CheckOpenSelectTerminals = useCallback(() => {
    //Comprobando que el usuario a seleccionado algún terminal:
    if (alldataform !== undefined) {
      if (alldataform.name === undefined) {
        const data = alldataform;
        data.name = "Llave tecatel";
        setalldataform(data);
      }
      if (setterminalsLoadError === false) {
        if (terminals.length === 0) {
        } else {
          if (alldataform !== undefined) setOpenSelectKeysPopUp(true);
        }
      }
    }

    setOpenSelectKeysPopUp(true);
  }, [alldataform]);

  useEffect(() => {
    if (modeDataorDay === 1 && modeDataorDayChanged) {
      InputDay.current.focus();
    }
  }, [modeDataorDay, modeDataorDayChanged]);

  useEffect(() => {
    InputName.current.focus();
  }, []);

  return (
    <>
      <TecoLayout
        routedNavBar={() => <TecoNavbar NavMenu={"Envío llave"} />}
        routedSideBar={() => <TecoSidebar />}
        key="menu"
      >
        <div className="Token">
          {/*<div className="Title_Descripction">Gestion De Tokens:</div>*/}

          <div className="IconsTitleToken">
            <div className="IconsTitleToken2">
              <Infosvg className="IcoInfo" onClick={(e) => showInfo()} />
            </div>
            <div className="IconsTitleToken1">
              <div className="circlesvg">
                <Keypng
                  alt="keyslist"
                  className="SvgIcoR4"
                  onClick={(e) => CheckOpenSelectTerminals()}
                />
              </div>
            </div>

            {errorTerminalsnotSelectedWarningUI ? (
              <div className="Error keysError">Selecciona un terminal</div>
            ) : (
              <></>
            )}
            {/* Seleccionar terminales */}
            <Popup
              open={OpenSelectKeysPopUp}
              closeOnDocumentClick
              onClose={ShowSaveKeys}
            >
              <div>
                {/*     <a className="close" onClick={ShowSaveKeys}>*/}
                <a className="close" onClick={ShowSaveKeys}>
                  &times;
                </a>
                <>
                  <SelectTerminalsPage
                    datestoToken={alldataform}
                    endDate={endDate}
                    startDate={startDate}
                    terminals={terminals}
                    deactivatepopup={deactivatePopUp}
                    TerminalsSelected={TerminalsSelected}
                    ShowSaveKeys={ShowSaveKeys}
                    alldataform={alldataform}
                    listselectedterminals={Saveselectterminasls}
                    Createtoken={Createtoken}
                    tokenError={tokenError}
                    listofterminalsave={listofterminalsave}
                  />
                </>
              </div>
            </Popup>
          </div>

          <hr className="Separator"></hr>
          <div className="PadreToken">
            <div className="Inputsa50 Token">
              <div className="InputnameProgram2">
                <div className="titleform"> Nombre:</div>
                <div>
                  <Input
                    type="input"
                    innerRef={InputName}
                    name="NameKey"
                    id="NameKey"
                    placeholder=""
                    className="bg-white tokenInput NameKey"
                    onChange={(e) => RecopileDatesForShow(e.target)}
                  />
                </div>
              </div>
              {messageErrorName ? (
                <div className="Error">Nombre no puede estar vacio.</div>
              ) : (
                <></>
              )}
            </div>
            <div className="Column2">
              <div className="ButtonsKey">
                <div
                  className={classNavPillData}
                  onClick={(e) => setOption("Day")}
                >
                  Por días
                </div>
                <div
                  className={classNavPillDay}
                  onClick={(e) => setOption("Data")}
                >
                  Por fechas
                </div>
              </div>
            </div>
            <div className="Inputsa50">
              {modeDataorDay ? (
                <>
                  <div
                    className="InputnameProgram2"
                    onClick={(e) =>
                      setselectdayclassActive("tokenInput DaysKey")
                    }
                  >
                    <div className="titleform">Días:</div>
                    <div className="inputdays">
                      <Input
                        innerRef={InputDay}
                        type="number"
                        name="DaysKey"
                        id="DaysKey"
                        placeholder="Días válido"
                        className={selectdayclassActive}
                        onChange={(e) => RecopileDatesForShow(e.target)}
                        defaultValue={daysform}
                      />
                    </div>
                  </div>
                  {messageErrorDays ? (
                    <div className="Error">
                      Error, número de días no puede ser negativo.
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <div className="widthDatapicker">
                    <div
                      className="InputFlex"
                      onClick={(e) =>
                        setdatapickerclass("datapickeractive form-control")
                      }
                    >
                      <div className="titleform">De:</div>

                      <DataPicker
                        className={datapickerclass}
                        getDataPicker={getDataPicker}
                        id="start"
                        defaultValue={startDate}
                      />

                      <div
                        className="InputFlex"
                        onClick={(e) =>
                          setdatapickerclass("datapickeractive form-control")
                        }
                      >
                        <div className="titleform">Hasta:</div>

                        <DataPicker
                          className={datapickerclass}
                          getDataPicker={getDataPicker}
                          id="end"
                          defaultValue={endDate}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {checkDatesInRangeState === 1 ? (
                <div className="Error">Error, Fechas incorrectas!.</div>
              ) : (
                <></>
              )}
            </div>
          </div>

          {/* Botón Crear token */}
          <div className="ButtonAndHistoric">
            <div className="ButtonAndHistoric1">
              <button
                className="ButtonToken"
                onClick={() =>
                  CheckDataToCreateToken(
                    listofterminalsave,
                    name,
                    startDate,
                    endDate
                  )
                }
              >
                {" "}
                Crear llave
              </button>
            </div>
            {/* <Historical className="SvgIcoR3" onClick={(e) => showHistorical()} />*/}
            <div
              className="ButtonAndHistoric2"
              onClick={() => showHistorical()}
            >
              <img className="keyslist" src={Historical} alt="keyslist" />
            </div>
          </div>

          {/* Historico */}
          <div>
            <hr className="Separator" />
          </div>
        </div>

        <PopUpC
          activate={activateHistorical}
          deactivatepopup={closeHistorical}
          content={
            <TokenManager
              ButtonShare={ButtonShare}
              tokenName={`Llave: ${alldataform?.name}`}
            />
          }
        />

        {/* Documentación */}

        <PopUpC
          activate={activateInfo}
          deactivatepopup={closeDocumentation}
          content={<Documentation />}
        />

        {/* Tarjetas token con botones */}
        {selectTerminalsToShowButtonsBool === true ? (
          <ButtonsShareF
            tokens={token}
            terminals={terminalsselected}
            tokenName={`Llave: ${alldataform?.name}`}
            namecard={namecard}
          />
        ) : (
          <></>
        )}
      </TecoLayout>
    </>
  );
};
