import React, { useEffect } from "react";
import { useUser } from "../../context/user-context";
import SidebarMenu from "./../SidebarMenu/index";
import { ReactComponent as KeyICo } from "./keyico.svg";
const TOKEN_ACCESS = "ROLE_PRIVILEGE_TOKEN_MANAGE";
export const TecoSidebarMiddleNav = () => {
  const { jwtPayloadDecoded } = useUser();

  useEffect(() => {

  }, [jwtPayloadDecoded]);

  const isTokenAuth = (jwtPayloadDecoded) => {
    let found = false;
    let authorites = jwtPayloadDecoded?.authorities;
    if (authorites) {
      authorites.forEach((auth) => {
        found |= auth?.authority === TOKEN_ACCESS;
      });
    }
    return found;
  };

  return (
    <SidebarMenu>


      <SidebarMenu.Item
        icon={<i className="fa fa-fw fa-home"></i>}
        title="Dashboards"
      >
        <SidebarMenu.Item title="IoT" to="/dashboards/iot" exact />
      </SidebarMenu.Item>



      {isTokenAuth(jwtPayloadDecoded) ? (
        <SidebarMenu.Item
          icon={<div style={{marginRight: "0.5rem"}}><KeyICo className="IcoTecosidebarsvg" /></div>}
          title="Envío llave"
          to="/Tokens"
        ></SidebarMenu.Item>
      ) : <></>}
    </SidebarMenu>
  );
};
