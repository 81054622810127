import React, { useEffect, useState, useCallback } from "react";
import { DragDropInfraPrograms } from "./DragDropInfraPrograms";
import { ReactComponent as DangerIcoOffOutputs } from "./danger.svg";
import "./InfraProgramUI.sass";
import { getDateTimeFormatted } from "../../../../../../../_helpers/DateFormatHelper";
import { TerminalDeviceOutputsView } from "../../../../OutputsView/TerminalDeviceOutputsView";
import { Clock } from "../../components/Clock/Clock";
import { TerminalDeviceInfraprogram } from "../../../../../../../classes/TerminalDevice/Program/Infraprogram/TerminalDeviceInfraprogram";
import { TypesController } from "../Subprograms/Types/TypesController";
import _ from "lodash";
import { projectsConstants } from "../../../../../../../_constants/dist/projects.constants.dev";

function filterData(arr, criteria) {
  return arr.filter(function (obj) {
    return Object.keys(criteria).every(function (c) {
      return obj[c] == criteria[c];
    });
  });
}

export const InfraProgramsUI = (props) => {
  const colorUnselected = "rgb(147,18,21)";
  const colorSelected = "rgb(33,150,243)";
  const zeroHour = "00",
    zeroMinute = "00";

   

  const {
    programSelect,
    outputs,
    Cancel,
    subroutine,
    Programsreorganizeds,
    typeofCard,
    createInfraProgram,
    infraprograms,
    onInfraprogramDelete,
    onInfraprogramUpdate,
    onInfraprogramsHierarhcyDone,
    modeC,
    customOutputsActivations,
    changeTypeOutputView,
    ShowButtonSavePrograms
  } = props;

  const [outputStates, setoutputStates] = useState(new Map());
  const [hour, sethour] = useState(zeroHour);
  const [minute, setminute] = useState(zeroMinute);
  const [infraprogramsState, setinfraprogramsState] = useState(infraprograms);
  const [hierarchyActualIndex, sethierarchyActualIndex] = useState(0);
  const [hierarchyTotals, sethierarchyTotals] = useState(undefined);
  const navigationButtons = {
    back: "back",
    next: "next",
  };

  const [editSubprogram, seteditSubprogram] = useState(false);
  const [starthourinfraprogram, setstarthourinfraprogram] = useState(undefined);
  const [endhourinfraprogram, setendhourinfraprogram] = useState(undefined);
  const [maxhour, setmaxhour] = useState(24);
  const [maxminutes, setmaxminutes] = useState(59);
  const [timePercentFulfilled, setTimerPercentFulfilled] = useState(0);

  const [editposition, seteditposition] = useState(undefined);
  const [actualizedDropdownTools, setactualizedDropdownTools] = useState(0);

  const continuousMode = 0;
  const pulsesMode = 1;
  const activationsPulses = 2;
  const pulsesPerMinute = 3;

  const [showButtonSaveOnCustomActivation, setshowButtonSaveOnCustomActivation] = useState(false);

  const [
    showButtonfinalizedWhenSave,
    setshowButtonfinalizedWhenSave,
  ] = useState(false);


  const [
    outputModifiedViewInPopUpTools,
    setoutputModifiedViewInPopUpTools,
  ] = useState(undefined);

  const getSelectedOutputsFromBits = useCallback((outputsBits) => {
    const newOutputStates = new Map();
    let offset = 1;
    let i = 0;
    while (offset <= outputsBits) {
      if ((outputsBits & offset) > 0) {
        newOutputStates.set(i + 1, true);
      }
      offset = 1 << ++i;
    }

    return newOutputStates;
  }, []);

  const calculateStartDateTime = useCallback(() => {
    let initDate = new Date(0);
    if (programSelect) {
      initDate = programSelect.getStartDate();
      if (
        programSelect?.subprograms &&
        programSelect?.subprograms instanceof Array
      ) {
        programSelect.subprograms.forEach((subprogram) => {
          if (subprogram?.activeTime) {
            const [hour, minute] = subprogram.activeTime.split(":");
            initDate = initDate.addHours(hour);
            initDate = initDate.addMinutes(minute);
          }
        });
      }
    }

    return initDate;
  }, [programSelect]);

  const getKeyFromIndex = useCallback(() => {
    let i = 0;
    for (let [nextHierarchyLevel, infprgs] of infraprograms.entries()) {
      if (i === hierarchyActualIndex) {
        return nextHierarchyLevel;
      }
      i++;
    }
    return 0;
  }, [hierarchyActualIndex, infraprograms]);

  const getInfraprogramsForSelectedHierarchy = useCallback(() => {
    if (infraprograms) {
      let i = 0;
      for (let [nextHierarchyLevel, infprgs] of infraprograms.entries()) {
        if (i === hierarchyActualIndex) {
          return infprgs;
        }
        i++;
      }
    }
    return 0;
  }, [infraprograms, hierarchyActualIndex]);



  const getInfraprogramsAllTest = useCallback(() => {
    if (infraprograms) {
   
      let i = 0;
      for (let [nextHierarchyLevel, infprgs] of infraprograms.entries()) {
        return infprgs;

        i++;
      }
    }
    return 0;
  }, [infraprograms]);

  const calculateDateTimes = useCallback(() => {
    let [hour, minute] = subroutine.activeTime.split(":");

    const startTimeSubprogram = calculateStartDateTime();
    const finalinfraprogramDate = new Date(startTimeSubprogram);
    finalinfraprogramDate.addHours(hour);
    finalinfraprogramDate.addMinutes(minute);

    const [preHour, preMinutes] = (subroutine?.initDelay || "00:00").split(":");
    const [posHour, posMinutes] = (subroutine?.endDelay || "00:00").split(":");

    startTimeSubprogram.addHours(preHour);
    startTimeSubprogram.addMinutes(preMinutes);
    finalinfraprogramDate.subHours(posHour);
    finalinfraprogramDate.subMinutes(posMinutes);

    setstarthourinfraprogram(startTimeSubprogram);
    setendhourinfraprogram(finalinfraprogramDate);

    const prePosHour = parseInt(preHour) + parseInt(posHour);
    const prePosMinutes = parseInt(preMinutes) + parseInt(posMinutes);
    const initTime =
      parseInt(hour - prePosHour) * 60 + (parseInt(minute) - prePosMinutes);

    let lastTime = 0;
    const infraprograms = getInfraprogramsForSelectedHierarchy();
    if (infraprograms) {
      infraprograms.forEach((infraprogram) => {
        if (infraprogram?.activeTime) {
          const [hourI, minuteI] = infraprogram.activeTime.split(":");
          const h = parseInt(hourI);
          const m = parseInt(minuteI);
          lastTime += h * 60 + m;
        }
      });
    }

    if (initTime > 0) {
      const diffTime = initTime - lastTime;
      hour = Math.floor(diffTime / 60);
      minute = diffTime % 60;
      setmaxhour(hour);
      setmaxminutes(minute);
      setTimerPercentFulfilled(lastTime / initTime);
    }

    if (infraprograms.length === 0) {
      setTimerPercentFulfilled(0);
    }

    return [startTimeSubprogram, finalinfraprogramDate];
  }, [
    subroutine,
    calculateStartDateTime,
    getInfraprogramsForSelectedHierarchy,
  ]);
  const isAllTimeFulfilled = useCallback(() => {
    return timePercentFulfilled * 100 >= 100;
  }, [timePercentFulfilled]);

  useEffect(() => {
    //create infraprogram
    calculateDateTimes();
  }, [subroutine, infraprograms]);

  const resetInfraprogramsDataStates = useCallback(() => {
    sethour(zeroMinute);
    setminute(zeroHour);
    setoutputStates(new Map());
  }, [zeroMinute, zeroHour]);

  useEffect(() => {
    resetInfraprogramsDataStates();
    //button change infraprogram

    calculateDateTimes();
  }, [hierarchyActualIndex]);

  const errorStateMachineDefaultObject = {
    selectedOutputs: false,
    selectedTime: false,
  };
  const [errorStateMachine, setErrorStateMachine] = useState(
    errorStateMachineDefaultObject
  );

  const addinfraprogramobject = useCallback(
    (activetime, index, hour, minute, outputs) => {
      return new TerminalDeviceInfraprogram(
        activetime,
        index,
        hour,
        minute,
        outputs
      );
    },
    []
  );

  const SaveInfraProgram = useCallback(() => {
    setshowButtonSaveOnCustomActivation(false);
    //  console.log("AQUI DEBEMOS DIFERENCIAR ENTRE SI ES UN ELEMENTO CREADO O ACTUALIZADO");
    const h = hour + ":" + minute;

    if (!editSubprogram) {
      let selectedOutputs = getSelectedOutputs(outputStates);

      const newprogram = addinfraprogramobject(
        h,
        0,
        hour,
        minute,
        selectedOutputs
      );

      createInfraProgram(getKeyFromIndex(), newprogram);
    } else {
      const list = getInfraprogramsForSelectedHierarchy();
      const elementEdit = list[editposition];
      seteditSubprogram(false);
      //revisar
      elementEdit.activeTime = h;
      elementEdit.hour = hour;
      elementEdit.minute = minute;
      elementEdit.modified = true;

      elementEdit.outputs = getSelectedOutputs(outputStates);

      onInfraprogramUpdate(getKeyFromIndex(), elementEdit);
    }
    resetInfraprogramsDataStates();

    setshowButtonfinalizedWhenSave(true);
  }, [
    createInfraProgram,
    outputStates,
    editSubprogram,
    editposition,
    hour,
    minute,
    onInfraprogramUpdate,
    getInfraprogramsForSelectedHierarchy,
    addinfraprogramobject,
    resetInfraprogramsDataStates,
    timePercentFulfilled,
    getKeyFromIndex,
  ]);

  const deleteInfraprogram = useCallback(
    (item) => {
      //borrando
      onInfraprogramDelete(getKeyFromIndex(), item);
    },
    [onInfraprogramDelete, getKeyFromIndex]
  );

  const editInfraprogram = useCallback(
    (item) => {
      sethour(item.hour);
      setminute(item.minute);
      setshowButtonfinalizedWhenSave(false);
      seteditposition(item.index);
      setoutputStates(getSelectedOutputsFromBits(item?.outputs));
      seteditSubprogram(true);
    },
    [getSelectedOutputsFromBits]
  );

  const calculateInfraprogramStartDateTime = useCallback(() => {
    let initDate = new Date(0);
    let startDateTime = starthourinfraprogram;
    if (!starthourinfraprogram) {
      [startDateTime] = calculateDateTimes();
    }

    if (subroutine && startDateTime) {
      initDate = new Date(startDateTime.getTime());
      const infraprograms = getInfraprogramsForSelectedHierarchy();
      let infraprogramsfix = infraprograms;
      if (infraprograms === 0) {
        infraprogramsfix = [];
      }
      infraprogramsfix.forEach((infraprogram) => {
        if (infraprogram?.activeTime) {
          const [hour, minute] = infraprogram.activeTime.split(":");
          initDate = initDate.addHours(hour);
          initDate = initDate.addMinutes(minute);
        }
      });
    }
    return initDate;
  }, [
    subroutine,
    starthourinfraprogram,
    getInfraprogramsForSelectedHierarchy,
    calculateDateTimes,
  ]);

  const calculateInfraprogramMaxEndDateTime = useCallback(() => {
    let endDateTime = endhourinfraprogram,
      start;
    if (!endDateTime) {
      [start, endDateTime] = calculateDateTimes();
    }
    return endDateTime;
  }, [endhourinfraprogram, calculateDateTimes]);

  //#endregion
  const getSelectedOutputs = useCallback((outputsMap) => {
    if (outputsMap !== undefined) {
      let outputsBits = 0;
      for (var [outputId, state] of outputsMap) {
        if (state) {
          outputsBits |= 1 << (outputId - 1);
        }
      }
      return outputsBits;
    } else return 0;
  }, []);

  const SelectOutput = useCallback(
    (terminalDevice, output, outputIndex) => {
      const prev = outputStates.has(output) ? outputStates.get(output) : false;
      let newState = new Map(outputStates);
      const selectedOutput = prev === false ? true : false;
      newState.set(output, selectedOutput);
      setoutputStates(newState);

      setErrorStateMachine({ ...errorStateMachine, selectedOutputs: false });
    },
    [outputStates, errorStateMachine]
  );

  const SetHour = useCallback((seconds, minutes, hour) => {
    sethour(hour);
    setminute(minutes);
    setshowButtonfinalizedWhenSave(false);
    if (hour !== zeroHour && minutes !== zeroMinute) {
      setErrorStateMachine({ ...errorStateMachine, selectedTime: false });
    }
  }, []);

  const ChangeOfMenuHierarchy = useCallback(
    (modeNavigation) => {
      let newHierarchyIndex = -1;
      if (
        modeNavigation === navigationButtons.next &&
        hierarchyActualIndex !== infraprograms.size - 1
      ) {
        newHierarchyIndex = hierarchyActualIndex + 1;
      } else if (
        modeNavigation === navigationButtons.back &&
        hierarchyActualIndex !== 0
      ) {
        newHierarchyIndex = hierarchyActualIndex - 1;
      }

      if (newHierarchyIndex >= 0) {
        sethierarchyActualIndex(newHierarchyIndex);
      }

      setshowButtonfinalizedWhenSave(false);
      setactualizedDropdownTools(actualizedDropdownTools+1);
    },
    [hierarchyActualIndex, infraprograms,actualizedDropdownTools]
  );

  //--------
  const isButtonSaveVisible = useCallback(() => {
    return hour !== zeroHour || minute !== zeroMinute;
  
  }, [outputStates, hour, minute, zeroHour, zeroMinute]);

  const getInfraprogramsDateString = useCallback(() => {
    const startDateTime = calculateInfraprogramStartDateTime();
    const endDateTime = calculateInfraprogramMaxEndDateTime();
    return `Solo se puede activar, como máximo lo que dura el subprograma de ${getDateTimeFormatted(
      startDateTime
    )} hasta ${getDateTimeFormatted(endDateTime)}`;
  }, [
    calculateInfraprogramStartDateTime,
    calculateInfraprogramMaxEndDateTime,
    getDateTimeFormatted,
  ]);

  //save outputs modified:


/*
  useEffect(() => {
    //getNumberofTypes(outputs);

    let listHiearchys = [];
    outputs.forEach((outputTmp) => {
      if (outputTmp?.outputType) {
        listHiearchys.push(outputTmp?.outputType.description);
      }
    });
    const listHiearchysNotrepeat = getNumberofTypes(listHiearchys);

    sethierarchyTotals(Object.keys(listHiearchysNotrepeat).length);
  }, [outputs]);
*/
  const getNumberofTypes = useCallback((data) => {
    let counts = {};
    for (let i = 0; i < data.length; i++) {
      counts[data[i]] = 1 + (counts[data[i]] || 0);
    }
    return counts;
  }, []);

  const showInfoOutputModified = useCallback(
    (outputAndParams) => {
   
      setoutputModifiedViewInPopUpTools(outputAndParams);
    },
    [outputModifiedViewInPopUpTools]
  );

  /*
  const getFilteredOutputs = useCallback(
    (outputsTmp) => {

      let outputsTmpFiltered = [];
      outputsTmp.forEach((outputsTmp) => {
        if (outputsTmp.outputType) {
          outputsTmpFiltered.push(outputsTmp);
        }
      });
      console.log(outputsTmpFiltered);
      return outputsTmpFiltered;
    },
    [outputs]
  );*/
  function sortOutputs(first, second) {
    if (first?.output < second?.output) {
      return -1;
    }
    if (first?.output > second?.output) {
      return 1;
    }
    return 0;
  }

  const getFilteredOutputs = useCallback(
    (outputsTmp) => {
      // if (customOutputsActivations.size > 0) {

      const numberhiearchyactual = getKeyFromIndex();
      const cloneOutputs = _.cloneDeep(outputsTmp)
        .filter(
          (output) =>
            output?.outputType?.hierarchyLevel === numberhiearchyactual
        )
        .map((output) => {
          if (customOutputsActivations.has(output.output)) {
            let value = customOutputsActivations.get(output.output);
            let newactivationType = undefined;
            switch (value.mode) {
              case continuousMode:
                newactivationType =
                  projectsConstants.global.activations.continuous;
                break;
              case pulsesMode:
                newactivationType = projectsConstants.global.activations.pulses;
                break;
              case activationsPulses:
                newactivationType =
                  projectsConstants.global.activations.pulses_with_polarity;
                break;
              case pulsesPerMinute:
                newactivationType =
                  projectsConstants.global.activations.pulses_per_minute;
                break;

              default:
                break;
            }
            output.activationType = newactivationType;
            output.activationParams = value.activationParams;
          }
          return output;
        });

      return cloneOutputs.sort(sortOutputs);

      // }
      // return outputs;
    },
    [outputs, customOutputsActivations, getKeyFromIndex]
  );
  const showButtonsSaveforSaveDataPost = useCallback(() => {
    console.log("Activando boton de guardar en infraprogramas");
    ShowButtonSavePrograms();
    setshowButtonSaveOnCustomActivation(true);
  }, []);


  return (
    <>
      <div className="TitlePageInfraProgram">
        Programar categorias del subprograma {subroutine?.getName()}. Nivel{" "}
        {hierarchyActualIndex + 1}
      </div>

      <DragDropInfraPrograms
        itemstoReorganized={Programsreorganizeds}
        program={programSelect}
        typeofCard={typeofCard}
        deleteInfraprogram={deleteInfraprogram}
        editInfraprogram={editInfraprogram}
        //hiearchyActual={hierarchyActualIndex}
        valuebar={timePercentFulfilled * 100}
        InfraProgramsFiltered={getInfraprogramsForSelectedHierarchy()}
        //InfraProgramsFiltered={getInfraprogramsAllTest()}
        subroutine={subroutine}
        infraprograms={infraprograms}
        minutes={maxminutes}
        hours={maxhour}
      />

      {/* <div>Añade una nueva programación:</div>*/}
      <hr className="Separator3 buttonss" />
      <div className="ViewInterfaceSubroutinesPart1">
        <div className="OutputsdViewSubroutines">
          <div className="TitleCard">
            Dale a siguiente para cambiar de categoría
          </div>

          <TypesController
            outputs={getFilteredOutputs(outputs)}
            changeTypeOutputView={changeTypeOutputView}
            modeC={modeC}
            outputModifiedViewInPopUpTools={outputModifiedViewInPopUpTools}
            actualizedDropdownTools={actualizedDropdownTools}
            ShowButtonSavePrograms={showButtonsSaveforSaveDataPost}
          />
          <div>
            {/* Filtrar por id pasando la id de showcategory por props */}
            {/*se pasan todos los outputs y filtra el hijo , cambiar por lista.  */}
            <TerminalDeviceOutputsView
              mode="infraprogram"
              onClick={SelectOutput}
              outputs={outputs}
              forcedMapStates={outputStates}
              forcedSelectedColor={colorSelected}
              forcedUnselectedColor={colorUnselected}
              numberhiearchyactual={getKeyFromIndex()}
              onClickOnDisabled={() => {}}
              outputsModified={customOutputsActivations}
              showInfoOutputModified={showInfoOutputModified}
            />
          </div>
        </div>

        <div className="SelectTimeProgramS">
          <div className="messageclockInfo">
            <div>
              <div>{getInfraprogramsDateString()}</div>
            </div>
          </div>
          <Clock
            SetHour={SetHour}
            initHour={hour}
            initMinute={minute}
            minMinutes={0}
            title={
              <div className="TitleClock">
                Seleccione el tiempo de activación.
              </div>
            }
            maxHours={maxhour}
            maxMinutes={59}
            maxMinutesWhenMaxHoursReached={maxminutes}
            onMaxHourSetMinutesToMax={true}
            applyMaxMinutsOnlyWhenMaxHoursReached={true}
            enabled={!isAllTimeFulfilled()}
            //enabled={true}
          />
          {errorStateMachine.selectedTime && (
            <div className="MessageServerOut">
              <div className="Error">
                Debe seleccionar el tiempo de activación de la salida
              </div>
              <div>
                <DangerIcoOffOutputs className="IcoError" />
              </div>
            </div>
          )}
        </div>
      </div>
      <hr className="Separator3 buttonss" />

      <div className="ButtonsInfraPrograms">
        <div className="NavigationCategories">
          {hierarchyActualIndex + 1 !== 1 ? (
            <>
              <div
                className="ButtonC "
                onClick={(e) => ChangeOfMenuHierarchy(navigationButtons.back)}
              >
                Atras
              </div>
            </>
          ) : (
            <>
              <div
                className="ButtonC returnfromadvanced"
                onClick={(e) => Cancel()}
              >
                Volver
              </div>
            </>
          )}

          {((isButtonSaveVisible() && !showButtonfinalizedWhenSave) || showButtonSaveOnCustomActivation )&&  (
            <div className="fButtonSaveInfraprograms">
              <div
                className="ButtonC2 smallb Secondary2inf helpsecondary"
                onClick={(e) => SaveInfraProgram()}
              >
                Guardar
              </div>
            </div>
          )}

          {showButtonfinalizedWhenSave &&
            hierarchyActualIndex + 1 !== infraprogramsState.size && (
              <div
                className="ButtonC"
                onClick={(e) => onInfraprogramsHierarhcyDone()}
              >
                Finalizar
              </div>
            )}

          <div className="ButtonNextlevel">
            <div className="ButtonCf">
              {hierarchyActualIndex + 1 !== infraprogramsState.size ? (
                <>
                  <div
                    className="ButtonC withNumber"
                    onClick={(e) =>
                      ChangeOfMenuHierarchy(navigationButtons.next)
                    }
                  >
                    Siguiente
                  </div>

                  <div className="ControlNCategory">
                    {hierarchyActualIndex + 1}/{infraprogramsState.size}
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="ButtonC withNumber"
                    onClick={(e) => onInfraprogramsHierarhcyDone()}
                  >
                    Finalizar
                  </div>
                  <div className="ControlNCategory">
                    {hierarchyActualIndex + 1}/{infraprogramsState.size}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
