import React, { useEffect, useState } from "react";
import copy from "./images/keycopy.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ButtonsShare } from "./ButtonsShare";
import { zoomIn, fadeInLeft, zoomOutRight, fadeIn } from "react-animations";
import Radium, { StyleRoot } from "radium";
const styles = {
  AnimSecond: {
    animation: "x 0.8s",
    animationName: Radium.keyframes(fadeIn, "Animacion1"),
  },
  AnimSend: {
    animation: "x 0.8s",
    animationName: Radium.keyframes(zoomOutRight, "Animacion1"),
  },
  AnimStart: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeInLeft, "Animacion1"),
  },
};
export const Copy = (props) => {
  const { message, description, tokenName, namecard } = props;
  const [save, setsave] = useState(undefined);
  const [handStartEffect, sethandStartEffect] = useState(undefined);
  const [messagecopied2, setmessagecopied2] = useState("Error");
  const [boolControlAnimationCopied, setboolControlAnimationCopied] = useState(
    0
  );
  const [tokenname, settokenname] = useState(tokenName);
  useEffect(() => {
    setmessagecopied2(message);
    setsave(description);
  }, [props, messagecopied2, save]);

  return (
    <div className="messageCopiedF">
      <div className="messageCopied">
        <div className="datacopy">
          {boolControlAnimationCopied === 0 ? (
            <div className={"notHideme Title_Descripction copy"}>
              Haz click en la mano, para copiar:
            </div>
          ) : boolControlAnimationCopied === 1 ? (
            <div className="hideMe Title_Descripction copy">{"Copiado."}</div>
          ) : (
            <>Error cargando pagina</>
          )}
        </div>

        <div className="IconosFila">
          <ButtonsShare messagesToShare={message} subject={tokenName} />
          <CopyToClipboard
            text={messagecopied2}
            onCopy={() => {
              //Parte animación al copiar:
              sethandStartEffect(styles.AnimSend);
              setboolControlAnimationCopied(1);
              setTimeout(() => {
                setboolControlAnimationCopied(0);
              }, 800);

              setTimeout(() => {
                setboolControlAnimationCopied(0);
                sethandStartEffect(styles.AnimSecond);
              }, 800);
            }}
          >
            <div className="MensajeB">
              <StyleRoot style={handStartEffect} className="copyIcon">
                <img src={copy} alt="Logo" className="LogoCopiedToken" />
              </StyleRoot>
            </div>
          </CopyToClipboard>
        </div>
        <div className="KeyShow">
         
            <div className="KeyShowC">Terminal: {description }</div>
            <div className="KeyShowC"> Llave tecatel: {namecard}</div>
         
        </div>
      </div>
    </div>
  );
};
