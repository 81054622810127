import { useMqttState } from 'mqtt-react-hooks';
import React, { useCallback, useMemo, useEffect } from 'react'
import { projectsConstants } from '../_constants/projects.constants';
import { useUser } from './user-context';

const PublisherContext = React.createContext();

export const PublisherProvider = (props) => {
    const {client, connectionStatus: status }  = useMqttState()
    const {user} = useUser()
    const publish = useCallback(
        (topic, message, project) => {
            if(client !== undefined && status === "Connected" && message){
                const projectId = project || projectsConstants.master_outputs.id;
                if (message) {
                    client.publish(
                      `${process.env.REACT_APP_TECO_MQTT_CLOUD_ID}/${user.username}/${projectId}/action/${topic}`,
                      "" + JSON.stringify(message)
                    );
                }
                //client.publish({topic, message: JSON.stringify(message), id:""}, project)
                return true;
            }
            return false;
        },
        [client, status, user.username],
    )

    const value = useMemo(() => {
        return ({
            publish
        })
    }, [publish]);

    return  <PublisherContext.Provider value={value} {...props} />;
}

export const usePublisher = () => {
    const context = React.useContext(PublisherContext);
    if(!context) {
        console.log("usePublisher debe estar dentro del proveedore PublisherContext")
    }
    return context;
}

export default PublisherContext
