import { projectsConstants } from "../../../../../_constants/projects.constants";
import { terminalDevices } from "../../../../../_reducers/terminalDevices.reducer";
import { isTerminalDeviceMMSValidFor, isTerminalDeviceSIOValidFor } from "../../VersionChecker";

const isTerminalDeviceMMSValidForReportSensorValues = terminalDevice => isTerminalDeviceMMSValidFor(
  terminalDevice,
  projectsConstants.master_outputs.versions.MMS_HW1_MIN_REPORTSENSORVALUES,
  projectsConstants.master_outputs.versions.MMS_HW3_MIN_REPORTSENSORVALUES
)


const isTerminalDeviceSIOValidForReportSensorValues = terminalDevice => isTerminalDeviceSIOValidFor(
  terminalDevice,
  projectsConstants.master_outputs.versions.SIO_HW1_MIN_REPORTSENSORVALUES,
  projectsConstants.master_outputs.versions.SIO_HW3_MIN_REPORTSENSORVALUES
)

const isTerminalDeviceLoraValidForReportSensorValues = (terminalDevice) => {
  return terminalDevice?.device?.id === projectsConstants.global.devices.TL01_TL03
    || terminalDevice?.device?.id === projectsConstants.global.devices.LORA
    || terminalDevice?.device?.id === projectsConstants.global.devices.LORA_V2
    || terminalDevice?.device?.id === projectsConstants.global.devices.LSC_V1
}

export const isTerminalDeviceValidForReportSensorValues = (terminalDevice) => {
  return (
    isTerminalDeviceMMSValidForReportSensorValues(terminalDevice) ||
    isTerminalDeviceSIOValidForReportSensorValues(terminalDevice) ||
    isTerminalDeviceLoraValidForReportSensorValues(terminalDevice)
  );
};
