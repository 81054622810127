import React, { useCallback } from "react";
import { SubprogramInfoListElement } from "./SubprogramInfoListElement";

export const ProgramInfoCard = (props) => {
  const { program, subprograms, goToProgram } = props;
  
  const selectProgram = useCallback(() => {
    goToProgram(program);
  }, [goToProgram, program]);

  const getCardViews = useCallback(() => {
    const components = [];
    if (program) {
      let initDate = program.getStartDate();
      if (subprograms && subprograms instanceof Array) {
        subprograms.forEach((subprogram, index) => {
          components.push(
            <SubprogramInfoListElement
              key={`programCardview2-${index}`}
              subprogram={subprogram}
              startDatetime={new Date(initDate.getTime())}
            />
          );

          if (subprogram?.activeTime) {
            const [hour, minute] = subprogram.activeTime.split(":");
            initDate.addHours(hour);
            initDate.addMinutes(minute);
          }
        });
      }
    }

    return components;
  }, [program, subprograms]);

  return (
    <>
      <div className="ProgramsCard" onClick={(e) => selectProgram(e)}>
        <div className="ProgramsCard-Title">
          <div>{program.getName()}</div>
          <div>{program.startHour}</div>
        </div>

        <div className="ProgramsCard-SubPrograms">{getCardViews()}</div>
        {/*    <SubprogramInfoCard/> */}
      </div>
    </>
  );
};
