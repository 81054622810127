import React, { useEffect, useState, useCallback } from "react";
import { Clock } from "../../components/Clock/Clock";
import { ReactComponent as DangerIcoOffOutputs } from "./danger.svg";
import { getDateTimeFormatted } from "../../../../../../../_helpers/DateFormatHelper";
import { TerminalDeviceOutputsView } from "../../../../OutputsView/TerminalDeviceOutputsView";
import { Headtimepreypos } from "../../../../../Airframe/CheckCircle/Headtimepreypos";
import { TerminalDeviceSubprogram } from "../../../../../../../classes/TerminalDevice/Program/Subprogram/TerminalDeviceSubprogram";
import { ReactComponent as ExclamationSvg } from "./exclamation.svg";
import { PopUpC } from "../../../../../Airframe/PopUp/PopUp";
import { HelpsAdvanced } from "../../components/Helps/HelpsAdvanced";
import _ from "lodash";

import { TypesController } from "./Types/TypesController";
import { projectsConstants } from "../../../../../../../_constants/dist/projects.constants.dev";
export const SubprogramsUI = (props) => {
  const colorUnselected = "rgb(147,18,21)";
  const colorSelected = "rgb(33,150,243)";
  const zeroHour = "00",
    zeroMinute = "00";
  const {
    program,
    nameNewsubroutine,
    clockEnabled,
    Cancel,
    subroutine,
    outputs,
    actionSaveSubroutine,
    onAdvancedInfraprogramsClick,
    viewUICreateInfraPrograms,
    restartPrePoswhenreturn,
    showAdvancedButton,
    modeC,
    customOutputsActivations,
    changeTypeOutputView,
    ShowButtonSavePrograms,
  } = props;

  const [errorChangeRangeTime, setErrorChangeRangeTime] = useState(undefined);

  const [hour, sethour] = useState(zeroHour);
  const [hourpre, sethourpre] = useState(zeroHour);
  const [hourpos, sethourpos] = useState(zeroHour);
  const [minutepre, setminutepre] = useState(zeroHour);
  const [minutepos, setminutepos] = useState(zeroHour);
  const [minute, setminute] = useState(zeroMinute);
  const [showRangeFinalizedHour, setshowRangeFinalizedHour] = useState(false);
  const [outputStates, setoutputStates] = useState(new Map());
  const [showButtonSave, setshowButtonSave] = useState(false);
  const [isHourMinuteValid, setIsHourMinuteValid] = useState(false);
  const [classButtonFixVolver, setclassButtonFixVolver] =
    useState("dinamycClassOff");
  const [openshowinfo, setopenshowinfo] = useState(false);
  const errorStateMachineDefaultObject = {
    selectedOutputs: false,
    selectedTime: false,
  };
  const [errorStateMachine, setErrorStateMachine] = useState(
    errorStateMachineDefaultObject
  );

  const typesClock = {
    pre: "pre",
    pos: "pos",
    default: "default",
  };

  const [infoOutputModSelected, setinfoOutputModSelected] = useState(false);

  const [outputModifiedViewInPopUpTools, setoutputModifiedViewInPopUpTools] =
    useState(undefined);

  const [outputsModifiedTypeController, setoutputsModifiedTypeController] =
    useState(outputs);

  const continuousMode = 0;
  const pulsesMode = 1;
  const activationsPulses = 2;
  const pulsesPerMinute = 3;
  /*
  useEffect(() => {
    console.log(customOutputsActivations);
  }, [customOutputsActivations]);
*/
  useEffect(() => {
    //coloca hora en preactivación y posactivación.
    if (!restartPrePoswhenreturn) {
      if (typeof subroutine?.initDelay === "string") {
        const [hourpre, minutespre] = subroutine.initDelay.split(":");
        setPreAndPosHour(hourpre, minutespre, typesClock.pre);
      }
      if (typeof subroutine?.endDelay === "string") {
        const [hourpos, minutespos] = subroutine.endDelay.split(":");
        setPreAndPosHour(hourpos, minutespos, typesClock.pos);
      }
    }
  }, [subroutine?.endDelay, subroutine?.initDelay, restartPrePoswhenreturn]);

  const [typeClock, settypeClock] = useState(typesClock.default);

  const activeClockPre = useCallback(() => {
    settypeClock(typesClock.pre);
  }, []);
  const activeClockPos = useCallback(() => {
    settypeClock(typesClock.pos);
  }, []);
  const activeClockDefault = useCallback(() => {
    settypeClock(typesClock.default);
  }, []);

  const showButtonsSaveforSaveDataPost = useCallback(() => {
    ShowButtonSavePrograms();
    setshowButtonSave(true);
  }, []);

  const calculateStartDateTime = useCallback(() => {
    let initDate = new Date(0);
    if (program) {
      initDate = program.getStartDate();
      if (program?.subprograms && program?.subprograms instanceof Array) {
        for (let i = 0; i < program.subprograms.length; i++) {
          const subprogramFromList = program.subprograms[i];

          if (subprogramFromList == subroutine) {
            // Esto indica que se está editando un programa
            // y por tanto el resto de programas se deben de ingorar para calcular la hora de inicio.
            break;
          }
          if (subprogramFromList?.activeTime) {
            const [hour, minute] = subprogramFromList.activeTime.split(":");
            initDate = initDate.addHours(hour);
            initDate = initDate.addMinutes(minute);
          }
        }
      }
    }

    return initDate;
  }, [program, subroutine]);

  const getSelectedOutputsFromBits = useCallback((outputsBits) => {
    const newOutputStates = new Map();
    let offset = 1;
    let i = 0;
    while (offset <= outputsBits) {
      if ((outputsBits & offset) > 0) {
        newOutputStates.set(i + 1, true);
      }
      offset = 1 << ++i;
    }

    return newOutputStates;
  }, []);

  const calculateEndDateTime = useCallback(
    (startDatetime) => {
      if (startDatetime && startDatetime instanceof Date) {
        startDatetime.addHours(hour);
        startDatetime.addMinutes(minute);
      }
      return startDatetime;
    },
    [hour, minute]
  );

  const SelectOutput = useCallback(
    (terminalDevice, output, outputIndex) => {
      const prev = outputStates.has(output) ? outputStates.get(output) : false;
      let newState = new Map(outputStates);
      const selectedOutput = prev === false ? true : false;
      newState.set(output, selectedOutput);
      setoutputStates(newState);

      //
      setErrorStateMachine({ ...errorStateMachine, selectedOutputs: false });
    },
    [outputStates, errorStateMachine]
  );

  const SetHour = useCallback(
    (minutes, hour) => {
      sethour(hour);
      setminute(minutes);
      if (hour === zeroHour && minutes === zeroMinute) {
        setclassButtonFixVolver("dinamycClassOff");
        setIsHourMinuteValid(false);
        setshowButtonSave(false);
      } else {
        setIsHourMinuteValid(true);
        setclassButtonFixVolver("dinamycClassON");
        setshowButtonSave(true);
        setshowRangeFinalizedHour(true);
      }
      if (hour !== zeroHour && minutes !== zeroMinute) {
        setErrorStateMachine({ ...errorStateMachine, selectedTime: false });
      }
    },
    [errorStateMachine]
  );

  const HourChange = useCallback(
    (seconds, minutes, hour) => {
    
      switch (typeClock) {
        case typesClock.pre:
          sethourpre(hour);
          setminutepre(minutes);
          break;
        case typesClock.pos:
          sethourpos(hour);
          setminutepos(minutes);
          break;
        case typesClock.default:
        default:
          SetHour(minutes, hour);
          break;
      }
    },
    [typeClock, SetHour, typesClock]
  );

  //#endregion

  const getSelectedOutputs = useCallback((outputsMap) => {
    let outputsBits = 0;
    for (var [outputId, state] of outputsMap) {
      if (state) {
        outputsBits |= 1 << (outputId - 1);
      }
    }
    return outputsBits;
  }, []);

  const getStartHourMinute = useCallback(() => {
    const startDateTime = calculateStartDateTime();
    if (startDateTime) {
      return getDateTimeFormatted(startDateTime);
    }
    return "ERROR";
  }, [calculateStartDateTime]);

  const getEndHourMinute = useCallback(() => {
    const startDateTime = calculateStartDateTime();
    if (startDateTime) {
      const endDateTime = calculateEndDateTime(startDateTime);
      if (endDateTime) {
        return getDateTimeFormatted(endDateTime);
      }
    }
  }, [calculateEndDateTime, calculateStartDateTime]);

  const getPreAndPostDelays = useCallback(() => {
    var hourandminutesPre = new Date();
    hourandminutesPre.setHours(hourpre);
    hourandminutesPre.setMinutes(minutepre);

    var hourandminutesPos = new Date();
    hourandminutesPos.setHours(hourpos);
    hourandminutesPos.setMinutes(minutepos);

    return [
      getDateTimeFormatted(hourandminutesPre),
      getDateTimeFormatted(hourandminutesPos),
    ];
  }, [hourpre, minutepre, hourpos, minutepos]);

  const getTerminalDeviceSubprogram = useCallback(
    (selectedOutputs, hour, minute, initDelay, endDelay) => {
      if (subroutine instanceof TerminalDeviceSubprogram) {
        subroutine.outputs = selectedOutputs;
        subroutine.activeTime = `${hour}:${minute}`;
        subroutine.initDelay = initDelay;
        subroutine.endDelay = endDelay;
        subroutine.customOutputsActivations = customOutputsActivations;
      } else {
        const tds = new TerminalDeviceSubprogram();
        tds.outputs = selectedOutputs;
        tds.activeTime = `${hour}:${minute}`;
        tds.initDelay = initDelay;
        tds.endDelay = endDelay;
        tds.outputTasks = [];
        tds.infraprograms = new Map();

        return tds;
      }
      return subroutine;
    },
    [subroutine, customOutputsActivations]
  );

  const SaveSubroutine = useCallback(
    (closeView, notSave) => {
      if (CheckRangeTimePrePostsmallerThanActivation()) {
        const [initDelay, endDelay] = getPreAndPostDelays();
        let selectedOutputs = getSelectedOutputs(outputStates);
        //---
        setErrorStateMachine({ ...errorStateMachine, selectedOutputs: false });

        /*   if (hour === zeroMinute && minute === zeroMinute) {
        setErrorStateMachine({ ...errorStateMachine, selectedTime: true });
        return;
      }*/
        setErrorStateMachine({ ...errorStateMachine, selectedTime: false });
        const tds = getTerminalDeviceSubprogram(
          selectedOutputs,
          hour,
          minute,
          initDelay,
          endDelay
        );
        if (!notSave) {
          //--Guardar en subrutina los cambios de tipos de activación-->

          actionSaveSubroutine(tds, closeView, customOutputsActivations);
        }
        return tds;
      }
      return undefined;
    },
    [
      outputStates,
      hour,
      minute,
      actionSaveSubroutine,
      getSelectedOutputs,
      errorStateMachine,
      getPreAndPostDelays,
      getTerminalDeviceSubprogram,
      customOutputsActivations,
      subroutine,
    ]
  );

  useEffect(() => {
    SetHour(subroutine?.minute || zeroMinute, subroutine?.hour || zeroHour);
    setoutputStates(getSelectedOutputsFromBits(subroutine?.outputs));
  }, [subroutine, getSelectedOutputsFromBits]);

  const CheckRangeTimePrePostsmallerThanActivation = useCallback(() => {
    const initTime = parseInt(hour) * 60 + parseInt(minute);
    const initTimepre = parseInt(hourpre) * 60 + parseInt(minutepre);
    const initTimepos = parseInt(hourpos) * 60 + parseInt(minutepos);


    const timepreypost = initTimepre + initTimepos;

    if (initTime > timepreypost) {
      setErrorChangeRangeTime(false);
      return true;
    } else {
      if (initTimepre === 0 && initTimepos === 0) {
        setErrorChangeRangeTime(false);
        return true;
      } else {
        setErrorChangeRangeTime(true);
        return false;
     
      }
    }
  }, [HourChange]);

  //Info open/close
  const closePopUP = useCallback(() => {
    setopenshowinfo(false);
  }, []);
  const viewInfoAdvanced = useCallback(() => {
    setopenshowinfo(true);
  }, []);
  //----
  const onAdvancedClick = useCallback(() => {
    // console.log(subroutine.infraprograms);

    if (CheckRangeTimePrePostsmallerThanActivation()) {
      const tds = SaveSubroutine(false, true);
      onAdvancedInfraprogramsClick(tds, customOutputsActivations);
      viewUICreateInfraPrograms();
    }
  }, [
    SaveSubroutine,
    viewUICreateInfraPrograms,
    onAdvancedInfraprogramsClick,
    customOutputsActivations,
  ]);

  const setPreAndPosHour = useCallback(
    (hour, minutes, type) => {
      if (type === typesClock.pos) {
        sethourpos(hour);
        setminutepos(minutes);
      }

      if (type === typesClock.pre) {
        sethourpre(hour);
        setminutepre(minutes);
      }
    },
    [subroutine?.endDelay, subroutine?.initDelay]
  );

  const getClockHour = useCallback(() => {
    switch (typeClock) {
      case typesClock.pre:
        return hourpre;
      case typesClock.pos:
        return hourpos;
      case typesClock.default:
      default:
        return hour;
    }
  }, [
    typeClock,
    typesClock,
    hour,
    hourpre,
    hourpos,
    subroutine,
    setPreAndPosHour,
  ]);

  const getClockMinutes = useCallback(() => {
    switch (typeClock) {
      case typesClock.pre:
        return minutepre;
      case typesClock.pos:
        return minutepos;
      case typesClock.default:
      default:
        return minute;
    }
  }, [typeClock, typesClock, minute, minutepre, minutepos]);

  function sortOutputs(first, second) {
    if (first?.output < second?.output) {
      return -1;
    }
    if (first?.output > second?.output) {
      return 1;
    }
    return 0;
  }

  const getFilteredOutputs = useCallback(
    (outputsTmp) => {
      // if (customOutputsActivations.size > 0) {

      const cloneOutputs = _.cloneDeep(outputsTmp)
        .map((output) => {
          if (customOutputsActivations.has(output.output)) {
            let value = customOutputsActivations.get(output.output);
            let newactivationType = undefined;
            switch (value.mode) {
              case continuousMode:
                newactivationType =
                  projectsConstants.global.activations.continuous;
                break;
              case pulsesMode:
                newactivationType = projectsConstants.global.activations.pulses;
                break;
              case activationsPulses:
                newactivationType =
                  projectsConstants.global.activations.pulses_with_polarity;
                break;
              case pulsesPerMinute:
                newactivationType =
                  projectsConstants.global.activations.pulses_per_minute;
                break;

              default:
                break;
            }
            output.activationType = newactivationType;
            output.activationParams = value.activationParams;
          }
          return output;
        });

      return cloneOutputs.sort(sortOutputs);

      // }
      // return outputs;
    },
    [customOutputsActivations]
  );

  const showInfoOutputModified = useCallback((outputAndParams) => {

    setoutputModifiedViewInPopUpTools({ ...outputAndParams });
  }, []);

  return (
    <>
      <div className="TitleandProandPreRiego">
        <div className="TitleProgram">
          Programar salidas de la rutina {nameNewsubroutine}:
        </div>
      </div>

      <hr className="Separator3" />

      <div className="ViewInterfaceSubroutinesPart1">
        <div className="OutputsdViewSubroutines">
          <div className="TitleCard">
            Selecciona una o varias salidas para la subrutina.
          </div>
          {/*<TypesController
            outputs={getFilteredOutputs(outputs)}
            changeTypeOutputView={changeTypeOutputView}
            modeC={modeC}
            outputModifiedViewInPopUpTools={outputModifiedViewInPopUpTools}
            ShowButtonSavePrograms={showButtonsSaveforSaveDataPost}
          />*/}

          <div>
            <TerminalDeviceOutputsView
              mode={"subprogram"}
              onClick={SelectOutput}
              outputs={getFilteredOutputs(outputs)}
              forcedMapStates={outputStates}
              forcedSelectedColor={colorSelected}
              forcedUnselectedColor={colorUnselected}
              onClickOnDisabled={() => {}}
              outputsModified={customOutputsActivations}
              showInfoOutputModified={showInfoOutputModified}
            />
          </div>
          <div className="TitleCard">
            *Si no seleccionas ninguna salida, se considera tiempo de descanso.
          </div>
        </div>

        <div className="SelectTimeProgramS modRiego">
          <Headtimepreypos
            activeClockPre={activeClockPre}
            activeClockPos={activeClockPos}
            activeClockDefault={activeClockDefault}
          />
          <div className="bodyClock">
            <>
              <div className="messageclockInfo">
                {typeClock === typesClock.default && (
                  <>
                    {showRangeFinalizedHour ? (
                      <>
                        {`Las salidas se activarán desde las ` +
                          getStartHourMinute() +
                          ` hasta las ` +
                          getEndHourMinute() +
                          `.`}
                      </>
                    ) : (
                      <>
                        {`La activación de las salidas comenzará a las: ` +
                          getStartHourMinute()}
                      </>
                    )}
                  </>
                )}
              </div>
              <Clock
                SetHour={HourChange}
                initHour={getClockHour()}
                initMinute={getClockMinutes()}
                //initHour={hour}
                //initMinute={minute}
                enabled={clockEnabled}
                minMinutes={0}
                title={
                  <div className="TitleClock">
                    Seleccione el tiempo de activación.
                  </div>
                }
                maxHours={24}
                maxMinutes={59}
                //debe de ser 0, si la hora es igual a 24, los minutos no se pueden elegir.
                maxMinutesWhenMaxHoursReached={0}
                onMaxHourSetMinutesToMax={true}
                applyMaxMinutsOnlyWhenMaxHoursReached={true}
              />
              {errorStateMachine.selectedTime && (
                <div className="MessageServerOut">
                  <div className="Error">
                    Debe seleccionar el tiempo de activación de la salida
                  </div>
                  <div>
                    <DangerIcoOffOutputs className="IcoError" />
                  </div>
                </div>
              )}

              {errorChangeRangeTime && (
                <div className="MessageServerOut">
                  <div className="Error">
                    El tiempo del pre y post no pueden ser mayores que el tiempo
                    de activación
                  </div>
                  <div>
                    <DangerIcoOffOutputs className="IcoError" />
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
      <hr className="Separator3" />

      <div className={`ButtonsSubPrograms ` + classButtonFixVolver}>
        <div className="Button 50%" onClick={(e) => Cancel()}>
          Atras
        </div>

        {isHourMinuteValid && showAdvancedButton ? (
          <>
            <div className="ButtonandInfoAdvanced">
              <div
                className="ButtonInfraPrograms Secondary2"
                onClick={(e) => onAdvancedClick()}
              >
                Avanzado
              </div>
              <div>
                <ExclamationSvg
                  className="ButtonProgram InfoAdvanced"
                  onClick={(e) => viewInfoAdvanced()}
                />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {showButtonSave && (
          <>
            <div className="Button 50%" onClick={(e) => SaveSubroutine()}>
              Guardar
            </div>
          </>
        )}
      </div>

      <PopUpC
        activate={openshowinfo}
        deactivatepopup={closePopUP}
        content={<HelpsAdvanced />}
      />
    </>
  );
};
