import React, { useState, useEffect, useCallback } from "react";

import { ReactComponent as IcoGraphics } from '../../images/barchart.svg';
import changeBotteIco from "../../images/changebottle.png";
import { ReactComponent as IcoAlarm } from "../../images/campana.svg";
import { ReactComponent as Configure } from "./../../../../../images/ImagesTerminalsDevice/configure.svg"
import "./help.sass";
import { HelpCardDropdown } from "./HelpCardDropdown";
export const HelpPostigel = (props) => {

    const [contentShareToShow, setcontentShareToShow] = useState(false);


    const [help, sethelp] = useState(false);
    const [alarm, setalarm] = useState(false);
    const [deposit, setdeposit] = useState(false);
    const [stadistics, setstadistics] = useState(false);



    //Muetra la info del seleccionado:
    const viewInfo = useCallback((data, id) => {
        setcontentShareToShow(data);

        console.log(id);

        if (id === "alarm") { 
            setalarm(true);
            setdeposit(false);
            setstadistics(false);
            sethelp(false);
        }
        if (id === "deposit") {
            setdeposit(true);
            setalarm(false);
            setstadistics(false);
            sethelp(false);
         }
        if (id === "stadistics") {
            setstadistics(true);
            setdeposit(false);
            setalarm(false);
            sethelp(false);
         }
        if (id === "help") { 
            sethelp(true);
            setdeposit(false);
            setstadistics(false);
            setalarm(false);
        }

        //setType({ ...type, typetmp: true });
    }, [contentShareToShow, alarm, help, deposit, stadistics]);

    //Cierra la vista 
    const closeInfo = useCallback((id) => {
      
        if (id === "alarm") { 
            setalarm(false);
            setdeposit(false);
            setstadistics(false);
            sethelp(false);
        }
        if (id === "deposit") {
            setdeposit(false);
            setalarm(false);
            setstadistics(false);
            sethelp(false);
         }
        if (id === "stadistics") {
            setstadistics(false);
            setdeposit(false);
            setalarm(false);
            sethelp(false);
         }
        if (id === "help") { 
            sethelp(false);
            setdeposit(false);
            setstadistics(false);
            setalarm(false);
        }


        //  setType({ ...type, typetmp: false });
    }, [contentShareToShow, alarm, help, deposit, stadistics]);


    return (
        <div className="documentation">
            <div className="helptitle titlehelpPostigel">Información:</div>


            <div className="cardsHelpAccordion">


                <HelpCardDropdown
                    id={"help"}
                    title={"Configuración y autocalibrado:"}
                    ico={<Configure className="IcoPostigel" />}
                    closeInfo={closeInfo}
                    viewInfo={viewInfo}
                    content={<>
                        <div className="subhelptitleFather">
                            <div className="subhelptitle">
                                Tiempo de la bomba: Configura los segundos que saldrán de líquido en cada dosis.
            </div>

                            <div className="subhelptitle">
                                Tiempo del led: Los segundos que se visualizará la luz.
            </div>

                            <div className="subhelptitle">
                                Tiempo mínimo entre desinfecciones: Los segundos que hay que esperar para servirse.
            </div></div></>}

                    type={help} />



                <HelpCardDropdown
                    id={"alarm"}
                    title={"Alarma:"}
                    ico={<IcoAlarm className="IcoPostigel" />}
                    closeInfo={closeInfo}
                    viewInfo={viewInfo}
                    content={<>
                        <div className="subhelptitleFather">
                            <div className="subhelptitle">
                                Haz click en el nivel de liquido y cambiara al menu de alarma.
            </div>
                            <div className="subhelptitle">
                                Indica el porcentaje de liquido que quieras.
            </div>
                            <div className="subhelptitle">
                                Cuando llege a este, postigel te avisará para que puedas reponerlo.
            </div>
                        </div></>}
                    type={alarm}
                />


                <HelpCardDropdown title={"Cambiar deposito:"}
                    id={"deposit"}
                    ico={<img src={changeBotteIco}
                        alt="check"
                        className="icoResetPostigel" />}
                    closeInfo={closeInfo}
                    viewInfo={viewInfo}
                    content={<>
                        <div className="subhelptitleFather">
                            <div className="subhelptitle">
                                Elige entre 1L y 5L.
                          </div>
                        </div></>}

                    type={deposit} />


                <HelpCardDropdown
                    id={"stadistics"}
                    title={"Estadisticas:"}
                    ico={<IcoGraphics className="IcoPostigel" />}
                    closeInfo={closeInfo}
                    viewInfo={viewInfo}
                    content={<>
                        <div className="subhelptitleFather">
                            <div className="subhelptitle">
                                Haz click en el icono de estadisticas, o en la tarjeta de la batería para ver la información de los sensores o los de energía.
                            </div>
                            <div className="subhelptitle">
                                Visualiza la actividad a lo largo de la semana, eligiendo el día que quierás.
                           </div>
                        </div></>}
                    type={stadistics}
                />
                {/* <div className="ImageCenterhelp"></div>*/}




            </div>



            <div className="positionToShowContentShare">
                {contentShareToShow}
            </div>

        </div>
    );
};
