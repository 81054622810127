import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./DragDrop/DragDrop.sass";


const getItems = (count) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k}`,
    content: `item ${k}`,
  }));

export const DragDropWithHooks = (props) => {
  const { onReordered, items } = props;

  const dragDropStyles = {
    MAIN: "DragDropProvided Main",
    DISABLED: "DragDropProvided Main deactivateDrag",
  };

  const states = {
    IDLE: "IDLE",
    DISABLED: "DISABLED",
  };

  const initState = {
    state: states.IDLE,
    items: items,
    dragDropStyle: dragDropStyles.MAIN,
  };
  const [state, setState] = useState(initState);

  // a little function to help us with reordering the result
  const reorder = useCallback((list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }, []);

  const onDragEnd = useCallback(
    (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const items = reorder(
        state.items,
        result.source.index,
        result.destination.index
      );

      setState((prev) => ({ ...prev, items }));
      if (items instanceof Array) {
        onReordered(items.map((item) => item?.id));
      }
    },
    [onReordered, reorder, state.items]
  );

  useEffect(() => {
      setState(prev => ({...prev, items}))
  }, [items]);

  function Element({ element, index }) {
    return (
      <Draggable draggableId={`${element.id}`} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {element.content}
          </div>
        )}
      </Draggable>
    );
  }

  const ElementsList = React.memo(function ElementsList({ elements }) {
    return elements.map((element, index) => (
      <Element element={element} index={index} key={element.id} />
    ));
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {/*    <TitleDragDrop desactivate={this.state.desactivate} />*/}
      <div style={{ display: "flex", width: "100%", height: "auto" }}>
        <Droppable
          droppableId="droppable2"
          direction="horizontal"
          className="flex end"
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={state.dragDropStyle}
            >
              <ElementsList elements={state.items} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

DragDropWithHooks.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        content: PropTypes.element.isRequired
    })),
    onReordered: PropTypes.func.isRequired
  };