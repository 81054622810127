import React, {
  createRef,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";

import { ProgramBarItem } from "./ProgramBarItem";
import "./ProgramsView.sass";
import "./SliderControlled.sass";
export const ProgramsView = (props) => {
  const {
    SetProgramClick,
    ListPrograms,
    program,
    focusOnIndex,
    onProgramDelete,
    terminalDevice,
    urlstate,
    goToProgram
  } = props;
  const [selectedProgramId, setselectedProgramId] = useState(
    program ? program.index + 1 : 0
  );
  const ProgramsCardsRefs = useRef([]);
  const [showAnimationsDelete, setshowAnimationsDelete] = useState(false);
  
  useEffect(() => {
    if (focusOnIndex <= ProgramsCardsRefs.current.length) {
      if (ProgramsCardsRefs.current[focusOnIndex]?.current) {
        ProgramsCardsRefs.current[focusOnIndex].current.scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      }
    }
  }, [focusOnIndex]);

  useEffect(() => {
   setselectedProgramId(program ? program.index + 1 : 0);
  }, [program]);

  const showAnimationdeleteAlls = useCallback((value) => {
    setshowAnimationsDelete(value);
  }, [showAnimationsDelete]);

  const getPrograms = useCallback(() => {
    let refs = ProgramsCardsRefs.current;
    let subpList = ListPrograms.map((program, index) => {
      const itemRef = createRef();
      if (index < refs.length) {
        refs[index] = itemRef;
      } else {
        refs.push(itemRef);
      }

      const assocProgram = program;
      let programId = assocProgram.index + 1;
      if (programId === "undefined" || isNaN(programId)) {
        programId = 1;
      }
      const item = (
        <ProgramBarItem
          key={index}
          id={programId}
          onSelected={SetProgramClick}
          selected={programId === selectedProgramId}
          program={assocProgram}
          innerRef={itemRef}
          onDelete={onProgramDelete}
          showAnimationdeleteAlls={showAnimationdeleteAlls}
          showAnimationsDelete={showAnimationsDelete}
          terminalDevice={terminalDevice}
          urlstate={urlstate}
          goToProgram={goToProgram}
        />
      );
      return item;
    });
    return subpList;
  //}, [SetProgramClick, selectedProgramId, ListPrograms, showAnimationsDelete, showAnimationdeleteAlls, onProgramDelete]);
}, [SetProgramClick, selectedProgramId, ListPrograms, showAnimationsDelete, onProgramDelete,program]);
  return (
    <>
       <div className="FixScroll">{getPrograms()}</div>
    </>
  );
};
