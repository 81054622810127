import React from "react";
import { Link } from "react-router-dom";
import { Navbar } from "../Navbar/navbar";
import { Nav } from "../Nav/nav";
import { SidebarTrigger } from "../SidebarTrigger/SidebarTrigger";
import "./StyleMenu.sass";
import { NavItem, NavLink } from "reactstrap";

export const TecoNavbar = (props) => {
  const {sectorId, zoneId,terminalId,NavMenu} = props;

  const linkMain = (
    <>
      <Link to="/">
        <span className="navbar-text">
          <i className="fa fa-home"></i>
        </span>
        <span className="navbar-text px-2">
          <i className="fa fa-angle-right"></i>
        </span>
      </Link>
    </>
  );
  const linkZones = (
    <Link to={`/zones`}>
      <span className="navbar-text px-2">
        <i className="fa">Zonas</i>
      </span>
    </Link>
  );
  const linkSectors = (
    <Link to={zoneId ? `/zones/${zoneId}/` : `/zones`}>
      <span className="navbar-text px-2">
        <i className="fa fa-angle-right"></i>
      </span>
      <span className="navbar-text px-2">
        <i className="fa">Sectores</i>
      </span>
    </Link>
  );
  const menuHome = <>{linkMain}</>;
  const menuZones = (
    <>
      {menuHome}
      <div className="flexMenuChild">
        <span className="navbar-text px-2">
          <i className="fa">Zonas</i>
        </span>
      </div>
    </>
  );
  const menuSectors = (
    <>
      {" "}
      {menuHome}
      {linkZones}
      <div className="flexMenuChild">
        <span className="navbar-text px-2">
          <i className="fa fa-angle-right"></i>
        </span>
        <span className="navbar-text px-2">
          <i className="fa">Sectores</i>
        </span>
      </div>
    </>
  );

  const menuTerminals = (
    <>
      {menuHome}
      {linkZones}
      {linkSectors}
      <div className="flexMenuChild">
        <span className="navbar-text px-2">
          <i className="fa fa-angle-right"></i>
        </span>
        <span className="navbar-text px-2">
          <i className="fa">Terminales</i>
        </span>
      </div>
    </>
  );

  const menuTerminalDevices = (
    <>
      {menuHome}
      {linkZones}
      {linkSectors}
      <Link to={zoneId && sectorId? `/zones/${zoneId}/sector/${sectorId}/` : `/zones`}>
        <span className="navbar-text px-2">
          <i className="fa fa-angle-right"></i>
        </span>
        <span className="navbar-text px-2">
          <i className="fa">Terminales</i>
        </span>
      </Link>
      <div className="flexMenuChild">
        <span className="navbar-text px-2">
          <i className="fa fa-angle-right"></i>
        </span>
        <span className="navbar-text px-2">
          <i className="fa">Dispositivos</i>
        </span>
      </div>
    </>
  );

  const menuPrograms = (
    <>
      {menuHome}
      {linkZones}
      {linkSectors}
      <Link to={zoneId && sectorId? `/zones/${zoneId}/sector/${sectorId}/` : `/zones`}>
        <span className="navbar-text px-2">
          <i className="fa fa-angle-right"></i>
        </span>
        <span className="navbar-text px-2">
          <i className="fa">Terminales</i>
        </span>
      </Link>
      <Link to={`/zones/${zoneId}/sector/${sectorId}/terminals/${terminalId}/`}>
      <div className="flexMenuChild">
        <span className="navbar-text px-2">
          <i className="fa fa-angle-right"></i>
        </span>
        <span className="navbar-text px-2">
          <i className="fa">Dispositivos</i>
        </span>
      </div>
      </Link>
      <div className="flexMenuChild">
        <span className="navbar-text px-2">
          <i className="fa fa-angle-right"></i>
        </span>
        <span className="navbar-text px-2">
          <i className="fa">Programas</i>
        </span>
      </div>
    </>
  );

  const menuPostigel = (
    <>
      {menuHome}
      {linkZones}
      {linkSectors}
      <Link to={zoneId && sectorId? `/zones/${zoneId}/sector/${sectorId}/` : `/zones`}>
        <span className="navbar-text px-2">
          <i className="fa fa-angle-right"></i>
        </span>
        <span className="navbar-text px-2">
          <i className="fa">Terminales</i>
        </span>
      </Link>
      
      <div className="flexMenuChild">
        <span className="navbar-text px-2">
          <i className="fa fa-angle-right"></i>
        </span>
        <span className="navbar-text px-2">
          <i className="fa">Postigel</i>
        </span>
      </div>
    </>
  );


  
  let title = "";
  let menupc=NavMenu;
  if (NavMenu === "Page1") {
    title = "Zonas";
    menupc = menuZones; // Menu + MenuHome
  } else if (NavMenu === "Sectors") {
    title = "Sectores";
    menupc = menuSectors; // Menu + MenuHome + Sectors
  } else if (NavMenu === "Terminals") {
    title = "Terminales";
    menupc = menuTerminals; // Menu + MenuHome + Sectors
  } else if (NavMenu === "TerminalDevices") {
    title = "Dispositivos";
    menupc = menuTerminalDevices; // Menu + MenuHome + Sectors
  }else if(NavMenu === "Envío llave"){
    title = "Envío llave";
  }else if(NavMenu === "PagePrograms"){
    title = "Programas";
    menupc = menuPrograms; 
  }else if(NavMenu === "Postigel"){
    title = "Postigel";
    menupc = menuPostigel; 
  }
  return (
    <Navbar light expand="xs" fluid>
      <Nav navbar>
        {/*------------------------------PC-------------------*/}
        <div className="PicTitle2">
          <div className="MenuChild">
            <SidebarTrigger />
            <NavItem className="flexMenu">{menupc}</NavItem>
          </div>
          <div className="Title">{title}</div>
        </div>

        {/*------------------------------MVL-------------------*/}
        <div className="PicTitle">
          <div className="MenuChild">
            <div>
              <SidebarTrigger />
            </div>

            <div className="Title">{title}</div>
          </div>
        </div>
      </Nav>
    </Navbar>
  );
};

export const NavbarUser = (props) => (
  <NavItem {...props}>
    <NavLink tag={Link} to="/logout">
      <i className="fa fa-power-off"></i>
    </NavLink>
  </NavItem>
);
