import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { LogoThemed } from "./LogoThemed";

const HeaderAuth = (props) => (
  <>
    <div className="LogicPic">
      <div>
        <Link to="/" className="d-inline-block">
          {props.icon ? (
            <i
              className={`fa fa-${props.icon} fa-3x ${props.iconClassName}`}
            ></i>
          ) : (
            <LogoThemed className="PicLogin" />
          )}
        </Link>
      </div>
      <div className="loginTitle">{props.title}</div>
    </div>
    <div>
      <p className="loginsubTitle">{props.text}</p>
    </div>
  </>
);
HeaderAuth.propTypes = {
  icon: PropTypes.node,
  iconClassName: PropTypes.node,
  title: PropTypes.node,
  text: PropTypes.node,
};
HeaderAuth.defaultProps = {
  title: "Waiting for Data...",
  text:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure voluptas aperiam odit, reiciendis dicta nihil.",
  iconClassName: "text-theme",
};

export { HeaderAuth };
