import React from "react";
import { Link } from "react-router-dom";

import Sidebar from "./../Sidebar/index";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";
import { useUser } from "../../context/user-context";
import { useMqttState } from "mqtt-react-hooks";

export const TecoSidebarTop = () => {
  const { user } = useUser();
  const { connectionStatus: status } = useMqttState()

  return (
    <React.Fragment>
      {/* START: Sidebar Default */}
      <Sidebar.HideSlim>
        <Sidebar.Section className="pt-0">
          <UncontrolledButtonDropdown caret={"true"}>
            <DropdownToggle color="link" caret>{user?.username ||"Anónimo"}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>{user?.username ||"Anónimo"}</DropdownItem>
              <DropdownItem divider />
              {/*<DropdownItem tag={Link} to="/apps/profile-details">
                  Mi perfil
                </DropdownItem>
                <DropdownItem tag={Link} to="/apps/settings-edit">
                  Ajustes
                </DropdownItem>
                <DropdownItem divider />*/}
              <DropdownItem tag={Link} to="/logout">
                <i className="fa fa-fw fa-sign-out mr-2"></i>
                Cerrar sesión
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
          <div className="small sidebar__link--muted">
            <Badge
              id="badge_mqttConnectionTooltip"
              className="mt-2"
              color={status === "Connected" ? "success" : "danger"}
            >
              MQTT
            </Badge>
            <UncontrolledTooltip
              placement="bottom"
              target="badge_mqttConnectionTooltip"
            >
              Indica la conexión con el servidor MQTT. Este servidor es el que
              permite la comunicación bidireccional con los dispositivos.
            </UncontrolledTooltip>
          </div>
        </Sidebar.Section>
      </Sidebar.HideSlim>
      {/* END: Sidebar Default */}

      {/* START: Sidebar Slim */}

      {/* END: Sidebar Slim */}
    </React.Fragment>
  );
};
