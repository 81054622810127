import React, { useEffect, useCallback, useState } from "react";
import { OutputService } from "../../../../../_services/outputcontroller.service";
import { ModeContinuo } from "./Modes/ModeContinuo";
import { ModePulses } from "./Modes/ModePulses";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import { DropdownC } from "../../../Airframe/Dropdown/Dropdown";
import { ModePulsesperMinute } from "./Modes/ModePulsesperMinute";

const continuousMode = 0;
const pulsesMode = 1;
const activationsPulses = 2;
const pulsesPerMinute = 3;

export const OutputConfig = (props) => {
  const [mode, setmode] = useState(continuousMode);
  const [activationmode, setactivationmode] = useState([]);
  const [activationModesLoaded, setactivationModesLoaded] = useState(false);
  const {
    output,
    outputActivation,
    onChangeOutputActivation,
    disabled,
    activationParams,
    onActivationParamsChange,
    excludedActivationModes
  } = props;
  const [actParams, setactParams] = useState(activationParams || {});

  useEffect(() => {
    if (!activationModesLoaded) {
      GetAllActivations();
    } else {
      ChangeOption({ value: outputActivation });
    }
    return () => { };
  }, [activationmode, output]);

  useEffect(() => {


    setactParams(activationParams);
  }, [activationParams]);

  function GetAllActivations() {
    OutputService.getActivations().then(
      (tmpActivations) => {

        if(tmpActivations instanceof Array && excludedActivationModes instanceof Array){
          tmpActivations = tmpActivations.filter(act => !excludedActivationModes.includes(act.id));
        }

        setactivationModesLoaded(true);
        setactivationmode(tmpActivations);
        if (outputActivation !== undefined) {
          ChangeOption({ value: outputActivation });
        }
      },
      (error) => {
        console.table("Error al recuperar los modos de activación");
      }
    );
  }
  const ChangeOption = useCallback(
    (select) => {
      let selectedSid = select.value;
      switch (selectedSid || select) {
        case projectsConstants.global.activations.continuous:
          setmode(continuousMode); //<ModeContinuo />)
          break;
        case projectsConstants.global.activations.pulses:
          setmode(activationsPulses);
          break;
        case projectsConstants.global.activations.pulses_per_minute:
          setmode(pulsesPerMinute);
          break;
        case projectsConstants.global.activations.pulses_with_polarity:
          setmode(pulsesMode); // <ModePulses />;
          break;
        default:
          break;
      }
      onChangeOutputActivation(selectedSid);
    },
    [onChangeOutputActivation]
  );

  const getPwm = useCallback(() => {
    return actParams?.pwm || 0;
  }, [actParams]);

  const getFirstPulse = useCallback(() => {
    return actParams?.first_pulse || 0;
  }, [actParams]);

  const getLastPulse = useCallback(() => {
    return actParams?.last_pulse || 0;
  }, [actParams]);

  const onChangePwm = useCallback(
    (pwm) => {

      let tmpParams = { ...actParams, pwm };
      onActivationParamsChange(tmpParams);
    },
    [actParams, onActivationParamsChange]
  );

  const onChangeFirstPulse = useCallback(
    (first_pulse) => {

      let tmpParams = { ...actParams, first_pulse };
      setactParams(tmpParams);
      onActivationParamsChange(tmpParams);
    },
    [actParams, onActivationParamsChange]
  );

  const onChangeLastPulse = useCallback(
    (last_pulse) => {

      let tmpParams = { ...actParams, last_pulse };

      setactParams(tmpParams);
      onActivationParamsChange(tmpParams);
    },
    [actParams]
  );

  const getSelectActivationTypes = useCallback(() => {
    let optionActivationType = null;
    const optionsActivationModes = Object.keys(activationmode).map(
      (item, i) => {
        const option = {
          value: activationmode[i].sid,
          label: activationmode[i].description,
        };

        if (activationmode[i].sid === outputActivation) {
          optionActivationType = option;
        }

        return option;
      }
    );
    return (
      <>
        <DropdownC
          options={activationModesLoaded && optionsActivationModes}
          onChange={ChangeOption}
          placeholder={"Seleccione un tipo de activación..."}
          value={optionActivationType}
          disabled={disabled}
          changestyles={true}
        />
      </>
    );
  }, [
    activationmode,
    activationModesLoaded,
    disabled,
    ChangeOption,
    outputActivation,
  ]);

  return (
    <>
      {/* <hr class="Separator"></hr>  */}
      <div className="TabOutputConf"></div>
     {/*  <div className="Title_Descripction center"> Modo de trabajo: tiempo</div>*/}

      <div className="titleModesActivationConf">Modos de activación por tiempo:</div>

      {getSelectActivationTypes()}
      {(() => {
        switch (mode) {
          case continuousMode:
            return <ModeContinuo pwm={getPwm} setPwm={onChangePwm} />;
          case activationsPulses:
            return (
              <ModePulses
                setStart={onChangeFirstPulse}
                setStop={onChangeLastPulse}
                start={getFirstPulse()}
                stop={getLastPulse()}
                activationType={activationsPulses}
              />
            );
          case pulsesPerMinute:
            return (
              <ModePulsesperMinute
                activationParams={actParams}
                onActivationParamsChange={onActivationParamsChange}

              />
            );
          case pulsesMode:
            return (
              <ModePulses
                setStart={onChangeFirstPulse}
                setStop={onChangeLastPulse}
                start={getFirstPulse()}
                stop={getLastPulse()}
                activationType={pulsesMode}
              />
            );
          default:
            return <>Sin opciones especificas.</>;
        }
      })()}
    </>
  );
};
