import React from 'react';
import PropTypes from 'prop-types';
import { APP_VERSION } from '../../App';

const FooterText = (props) => (
	<React.Fragment>
		(C) { props.year } Todos los derechos reservados.
		Diseñado e implementado por
		<a
		href="http://www.tecatel.com"
		target="_blank"
		rel="noopener noreferrer"
		className="sidebar__link"
		>
		{" "}www.tecatel.com
		</a>. Version: {APP_VERSION}
	</React.Fragment>
)
FooterText.propTypes = {
    year: PropTypes.node,
	name: PropTypes.node,
	desc: PropTypes.node,
};
FooterText.defaultProps = {
    year: "2021",
    name: "TecoCloud",
};

export { FooterText };
