import { authHeader } from "../_helpers";
//import { routesConstants } from "../_constants/routes.constants";
//import { projectsConstants } from "../_constants/projects.constants";
import axios from "axios";

export const tokenService = {
  getTokens,
  getToken,
  createToken,
  deleteToken,
  updateToken
};

function getTokens(page,size) {
  const authToken = authHeader();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization
    }
  };

  return axios
        .get(`${process.env.REACT_APP_API_PATH}/tokens?page=${page}&size=${size}` , requestOptions)
        .then(handleResponse)
        .then(tokens => {
       
          return tokens});
}

function getToken(tokenId) {
  const authToken = authHeader();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization
    }
  };

  return axios
        .get(`${process.env.REACT_APP_API_PATH}/tokens/${tokenId}`, requestOptions)
        .then(handleResponse)
        .then(tokens => {
       
          return tokens});
}

/**
 * Objeto token debe tener:
 * {
    "validSince": "2020-11-30T11:29:08.852Z",
    "validUntil": "2020-11-30T11:29:08.852Z",
    "roles": [
      "string"
    ],
    "description": "string",
    "terminals": [
      0
    ]
  } 
  @param {*} token 
 */
function createToken(token) {
  const authToken = authHeader();
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken.Authorization,
  };
  const requestOptions = {
    method: "POST",
    headers,
  };

  return axios
    .post(`${process.env.REACT_APP_API_PATH}/tokens`,
      token,
      requestOptions
    )
    .then(handleResponse)
    .then((completeToken) => {
      return completeToken;
    });
}

function deleteToken(tokenId){
  const authToken = authHeader();
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken.Authorization,
  };
  const requestOptions = {
    method: "DELETE",
    headers,
  };

  return axios
    .delete(
      `${process.env.REACT_APP_API_PATH}/tokens/${tokenId}`,
      requestOptions
    )
    .then(handleResponse)
    .then((deletedToken) => {
      return deletedToken;
    });
}

/**
 * Objeto token debe tener:
 * {
  "description": "string"
  }
 * @param {*} token 
 */
function updateToken(token) {
  const authToken = authHeader();
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken.Authorization,
  };
  const requestOptions = {
    method: "PUT",
    headers,
  };

  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/tokens`,
      token,
      requestOptions
    )
    .then(handleResponse)
    .then((updatedToken) => {
      return updatedToken;
    });
}

function handleResponse(response) {
  var p = new Promise((resolve, reject) => {
    const data = response.data;
    if (response.status >= 400) {
      /*if (response.status === 401) {
        history(routesConstants.sign.OUT);
      }*/

      const error = response.statusText;
      reject(error);
    }

    resolve(data);
  });
  return p.then(data => {
    return data;
  });
}
