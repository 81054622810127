import React, { useEffect, useRef } from "react";
import "./InputGroup.sass";

export const InputGroup = (props) => {
  const {
    id,
    placeholder,
    value,
    symbol,
    type,
    className,
    onChange,
    foco,
    min,
  } = props;
  const startInputRef = useRef(null);
  useEffect(() => {
    if (foco) {
      startInputRef.current.focus();
    }
  }, [foco]);


  return (
    <>
<div className="InputGroupTecoDynamicWidth">
      <div className="InputGroupTeco">
        <input
          ref={startInputRef}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          value={value}
          id={id}
          type={type}
          className={"form-control " + className}
          min={min}
        />

       
      </div>
      <div className="tecogroup-prepend">
          <span className="tecogroup-text">{symbol}</span>
        </div>
      </div>
    </>
  );
};
