import React from "react";
import Outputs6image from "./picsOutputs/6salidas2.png";
import Outputs12image from "./picsOutputs/12salidas.png";
export function Outputconfhelp(props) {
  const { outputs } = props;
  const numbertotaloutputs = outputs.length;

  return (
    <div className="documentation">
      <div className="helptitle">Configuración de las salidas:</div>
      <div className="grouphelp">
        Elige una salida para poder cambiar su descripción y/o su tipo de
        activación.
      </div>
      <div className="helptitle">Tipos de activación:</div>

      <div className="grouphelp">
        <div className="subhelptitle">• Activación continua:</div>
        <div> Establece un porcentaje para el pwm. </div>
      </div>

      <div className="grouphelp">
        <div className="subhelptitle">• Activación por pulsos sin polaridad:</div>
        <div>
          Establece el tiempo de arranque y parada en segundos
          <div>Se activa durante el tiempo establecido en start.</div>
          <div>Se desactiva durante el tiempo establecido en stop.</div>
        </div>
      </div>
      <div className="grouphelp">
        <div className="subhelptitle">• Activación por pulsos con polaridad:</div>
        <div className="imageandtextcontent">
          <div>
            Establece el tiempo de arranque y parada en segundos
            <div>Cada flecha indica la relación de conexión</div>
            <div>
              Se activa con polaridad directa durante el tiempo establecido en
              start.
            </div>
            <div>
              Se activa con polaridad inversa durante el tiempo establecido en
              stop.
            </div>
            <div>Esta salida se muestra de color gris y sin puntero para indicar que ya esta siendo utilizada.</div>
          </div>
          <div className="imagecontenthelp">
            {numbertotaloutputs === 6 && (
              <img
                src={Outputs6image}
                alt="OutputsImageHelp"
                className="sizepicHelpOutputs"
              />
            )}

            {numbertotaloutputs === 12 && (
              <img
                src={Outputs12image}
                alt="OutputsImageHelp"
                className="sizepicHelpOutputs"
              />
            )}
          </div>
        </div>
        <div></div>
      </div>

      <div className="grouphelp">
        <div className="subhelptitle">• Pulsos por minuto:</div>
        <div>
          <div>
            Elige el tiempo de duración de los pulsos del tiempo de arranque y
            del tiempo de parada.
          </div>
          <div>Pueden ser horas, minutos o segundos.</div>
        </div>
        <div className="grouphelp">
          Por ejemplo 7 segundos para el arranque y 30 minutos para la parada.
        </div>
      </div>
    </div>
  );
}
