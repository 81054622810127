import React, { useCallback, useEffect, useState } from "react";
import "./Terminals.sass";
import { useNavigate } from 'react-router-dom';
import { useApp } from "../../../context/app-context";
import { terminalService } from "../../../_services/terminal.service";
import { TerminalWithSensorsCard } from "./TerminalWithSensorsCard";

export const Terminal = (props) => {
  const { setselectedTerminal } = useApp();
  const history = useNavigate();
  const { terminal, zoneId, sectorId, terminalDevices } = props;

  const ViewTerminalsDevice = useCallback((terminalDevices) => {
    setselectedTerminal(terminal);

    const typeTerminal = terminal.terminalType;
    history( `/zones/${zoneId}/sector/${sectorId}/terminals/${terminal.id}`, {
      state: { typeTerminal, terminalDevices },
    });
  }, [setselectedTerminal, terminal, history, zoneId, sectorId]);

  const onDescriptionChange = useCallback(
    (description) => {
      const { hasImage, ...newTerminal } = { ...terminal, description };
      terminalService
        .updateTerminal(terminal?.id, newTerminal)
        .then((sectorDto) => {
          //console.log(sectorDto);
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    [terminal]
  );

  return (
    <div className="ContentImageFather">
      <TerminalWithSensorsCard 
        terminal={terminal}
        terminalDevices={terminalDevices}

        onDescriptionChange={onDescriptionChange}
        onSelect={ViewTerminalsDevice}
        />

      {/*
      <SquareView
        name={internalName}
        statusMqtt={terminal?.statusMqtt}
        icon={true}
        targetEntity={uploadTargetEntities.terminal}
        uploadEntityIds={[terminal?.id]}
        loadingImage={state.state === states.LOADING_IMAGE}
        srcImage={state.imageLoaded ? srcImg : undefined}
        onNameChange={onDescriptionChange}
        classfather={"descriptionTerminals"}
        data={terminal}
        NumberTerminals={terminalDevices}
        onSelected={ViewTerminalsDevice}
      />*/
      }
    </div>
  );
};
