import { authHeader } from "../_helpers";
import axios from "axios";
import { Authservice } from "./Authservice";
import { TerminalDevice } from "../classes/TerminalDevice/TerminalDevice";

export const terminalService = {
  getAll,
  getTerminal,
  getTerminalDevices,
  getTerminalDevice,
  getTerminalsofSectorInZoneAll,
  updateTerminal,
  updateTerminalDevice
};

const authToken = authHeader();
const requestOptions = {
  method: "PUT",
  headers: {
    "Content-Type": "application/json",
    Authorization: authToken.Authorization,
  },
};

function getAll() {
  const requestOptions = Authservice.getRequestOptions("GET");
  return axios
        .get(`${process.env.REACT_APP_API_PATH}/terminals/`, requestOptions)
        .then(Authservice.handleResponse)
        .then(tmpTerminals => { return tmpTerminals});
}

function getTerminal(terminalId) {
  const requestOptions = Authservice.getRequestOptions("GET");

  return axios
        .get(`${process.env.REACT_APP_API_PATH}/terminals/${terminalId}`, requestOptions)
        .then(Authservice.handleResponse)
        .then(terminal => { return terminal});
}

function getTerminalDevices(terminalId) {
  const authToken = authHeader();
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken.Authorization
  };
  const requestOptions = {
    method: "GET",
    headers
  };

  return axios
        .get(`${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/`, requestOptions)
        .then(handleResponse)
        .then(tmpTerminalDevices => {
          return tmpTerminalDevices.sort(sortTerminalDevices)
          .map(terminalDevice => parseTerminalDevice(terminalDevice))
        });
}

function getTerminalDevice(terminalId, terminalDeviceId) {
  const authToken = authHeader();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization
    }
  };

  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}`,
      requestOptions
    )
    .then(handleResponse)
    .then(tmpTerminalDevice => {
      return parseTerminalDevice(tmpTerminalDevice);
    });
}

function getTerminalsofSectorInZoneAll(zoneId,sectorId) {
  const authToken = authHeader();
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken.Authorization,
  };
  const requestOptions = {
    method: "GET",
    headers,
  };
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/zones/${zoneId}/sectors/${sectorId}/terminals/`, requestOptions)
    .then(handleResponse)
    .then((tmpTerminals) => {return tmpTerminals;});
}
/*function getTerminalDeviceLastMeterData(terminalDeviceId) {
  const authToken = authHeader();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization
    }
  };

  return axios
    .get(
      `${projectsConstants.lora_outputs.api_path}/terminal_devices/${terminalDeviceId}/last_meterdata`,
      requestOptions
    )
    .then(handleResponse)
    .then(terminalDeviceMeterData => {
      return terminalDeviceMeterData;
    });
}*/

function updateTerminalDevice(terminalId, terminalDeviceid, terminalDto){
  return axios
        .put(
          `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceid}`,
          terminalDto,
          requestOptions
        )
        .then(Authservice.handleResponse)
        .then(tmpTerminalDevice => {
          return parseTerminalDevice(tmpTerminalDevice);
        });
}

function updateTerminal(terminalId, terminalDto){
  return axios
        .put(
          `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}`,
          terminalDto,
          Authservice.getRequestOptions("PUT")
        )
        .then(Authservice.handleResponse)
        .then((terminal) => {
          return terminal;
        });
}

function handleResponse(response) {
  var p = new Promise((resolve, reject) => {
    const data = response.data;
    if (response.status >= 400) {
  /*    if (response.status === 401) {
        history(routesConstants.sign.OUT);
      }*/

      const error = response.statusText;
      reject(error);
    }

    resolve(data);
  });
  return p.then(data => {
    return data;
  });
}


function parseTerminalDevice(terminalDevice) {
  return TerminalDevice.parseFromObjectDto(terminalDevice);
}

function sortTerminalDevices(first,second) {
  if (first?.id < second?.id) {
    return -1;
  }
  if (first?.id > second?.id) {
    return 1;
  }
  return 0;
}