import React, {useEffect} from "react";

import "react-notifications-component/dist/theme.css";
import sassNotification from "./Notification.sass";
import { store } from "react-notifications-component";
export const Notification = (props) => {

  useEffect(() => {
    store.addNotification({
      title: props.title,
      message: props.message,
      type: props.type,
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        //Debug
       // duration: 500000,
        duration: props.time ? props.time :1500,
        onScreen: true,
        showIcon: true,
      },
    });
  }, [props.message, props.time, props.title, props.type])

  return <div className="ionCount"></div>;
};
