import React, { useCallback, useState } from "react";
import { ButtonGroup, Button } from "reactstrap";
import { DayChoose } from "./DayChoose";

// Definir los periodos disponibles
export const periods = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  YEAR: "year",
  TOTAL: "total"
};

export const PeriodSelector = (props) => {
  const { onPeriodChange } = props;

  // Estado único para manejar tanto el período como el día de inicio (para semana/mes/año)
  const [state, setState] = useState({
    period: periods.DAY, // Default period: "day"
    day: new Date(), // Default day: current day
  });

  // Función auxiliar para obtener el primer día de la semana (lunes)
  const getFirstDayOfWeek = (date) => {
    const d = new Date(date);
    const day = d.getDay(); // 0 (domingo) a 6 (sábado)
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // Ajuste para que lunes sea el primer día de la semana
    return new Date(d.setDate(diff));
  };

  // Función auxiliar para obtener el primer día del mes
  const getFirstDayOfMonth = (date) => {
    const d = new Date(date);
    return new Date(d.getFullYear(), d.getMonth(), 1);
  };

  // Función auxiliar para obtener el primer día del año
  const getFirstDayOfYear = (date) => {
    const d = new Date(date);
    return new Date(d.getFullYear(), 0, 1);
  };

  // Función para manejar el cambio de período
  const handlePeriodChange = (newPeriod) => {
    let newDay = new Date();

    if (newPeriod === periods.WEEK) {
      newDay = getFirstDayOfWeek(newDay);
    } else if (newPeriod === periods.MONTH) {
      newDay = getFirstDayOfMonth(newDay);
    } else if (newPeriod === periods.YEAR) {
      newDay = getFirstDayOfYear(newDay);
    }

    const newState = { period: newPeriod, day: newDay };
    setState(newState);
    onPeriodChange(newState); // Llamada al callback
  };

  const handleDaySelection = useCallback(
    (direction) => {
      const newDay = new Date(state.day);

      if (state.period === periods.DAY) {
        newDay.setDate(newDay.getDate() + direction);
      } else if (state.period === periods.WEEK) {
        newDay.setDate(newDay.getDate() + direction * 7);
      } else if (state.period === periods.MONTH) {
        newDay.setMonth(newDay.getMonth() + direction);
      } else if (state.period === periods.YEAR) {
        newDay.setFullYear(newDay.getFullYear() + direction);
      }

      const newState = { ...state, day: newDay };
      setState(newState);
      onPeriodChange(newState);
    },
    [onPeriodChange, state]
  );

  const handlePrevDaySelection = useCallback(() => {
    handleDaySelection(-1); // Llama con -1 para retroceder
  }, [handleDaySelection]);

  const handleNextDaySelection = useCallback(() => {
    handleDaySelection(1); // Llama con 1 para avanzar
  }, [handleDaySelection]);

  const handleOnDayChange = useCallback((day) => {
    let newDay = day;

    if (state.period === periods.WEEK) {
      newDay = getFirstDayOfWeek(day);
    } else if (state.period === periods.MONTH) {
      newDay = getFirstDayOfMonth(day);
    } else if (state.period === periods.YEAR) {
      newDay = getFirstDayOfYear(day);
    }

    const newState = { ...state, day: newDay };
    setState(newState);
    onPeriodChange(newState);
  }, [onPeriodChange, state]);

  return (
    <div className="period-selector">
      <ButtonGroup>
        <Button
          active={state.period === periods.DAY}
          color="primary"
          outline
          onClick={() => handlePeriodChange(periods.DAY)}
        >
          D
        </Button>
        <Button
          active={state.period === periods.WEEK}
          color="primary"
          outline
          onClick={() => handlePeriodChange(periods.WEEK)}
        >
          S
        </Button>
        <Button
          active={state.period === periods.MONTH}
          color="primary"
          outline
          onClick={() => handlePeriodChange(periods.MONTH)}
        >
          M
        </Button>
        <Button
          active={state.period === periods.YEAR}
          color="primary"
          outline
          onClick={() => handlePeriodChange(periods.YEAR)}
        >
          A
        </Button>
        <Button
          active={state.period === periods.TOTAL}
          color="primary"
          outline
          onClick={() => handlePeriodChange(periods.TOTAL)}
        >
          Total
        </Button>
      </ButtonGroup>

      {state.period !== periods.TOTAL && (
        <DayChoose
          day={state.day}
          onPrevClick={handlePrevDaySelection}
          onNextClick={handleNextDaySelection}
          onDayChange={handleOnDayChange}
        />
      )}
    </div>
  );
};
