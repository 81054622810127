import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ThemeConsumer } from '../../Theme';
import tecoWhiteLogoPath from './../../../images/logos/gea_logo.png'

const logos = {
    'white': tecoWhiteLogoPath,
    'primary': tecoWhiteLogoPath,//require('./../../../images/logos/logo-teco-primary.png'),
    //'success': require('./../../images/logos/logo-success.svg'),
    //'warning': require('./../../images/logos/logo-warning.svg'),
    //'danger': require('./../../images/logos/logo-white.svg'),
    //'info': require('./../../images/logos/logo-info.svg'),
    //'indigo': require('./../../images/logos/logo-indigo.svg'),
    //'purple': require('./../../images/logos/logo-purple.svg'),
    //'pink': require('./../../images/logos/logo-pink.svg'),
    //'yellow': require('./../../images/logos/logo-yellow.svg')
}

const getLogoUrl = (style, color) => {
    return logos[color];
}

// Check for background
const getLogoUrlBackground = (style, color) => {
    if (style === 'color') {
        return logos['white'];
    } else {
        return getLogoUrl(style, color);
    }
}

const LogoThemed = ({ checkBackground, className, style, color, customStyle, ...otherProps }) => {

    return (<ThemeConsumer>
    {
        ({ style, color }) => (
            <img
                src={
                    checkBackground ?
                        getLogoUrlBackground(style, color) :
                        getLogoUrl(style, color)
                }
                className={ classNames('d-block', className) }
                alt="Teco logo"
                style={customStyle}
                { ...otherProps }
            />
        )
    }
    </ThemeConsumer>);
}
LogoThemed.propTypes = {
    checkBackground: PropTypes.bool,
    className: PropTypes.string,
};

export { LogoThemed };
