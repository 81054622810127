import React, { useEffect, useCallback, useState } from "react";
import PopUpSass from "./PopUp.sass";
import Popup from "reactjs-popup";
export const PopUpC = (props) => {
  const {
    content,
    activate,
    deactivatepopup,
    popupPersonalizedClass,
  } = props;
  const [popupOpen, setpopupOpen] = useState(activate);
  const ShowSaveKeys = useCallback(
    (e) => {
      if (popupOpen) {
        deactivatepopup();
      }
    },
    [deactivatepopup, popupOpen]
  );

  useEffect(() => {
    setpopupOpen(activate);
  }, [activate]);

  return (
    <>
      <Popup
        open={popupOpen}
        closeOnDocumentClick={true}
        onClose={ShowSaveKeys}
        //className={popupPersonalizedClass}
        position={"top right"}
        nested={true}
      >
        <div style={{maxHeight: "80vh", overflow: "scroll"}} id="closePopUpDiv" onClick={() => {}}>
          <a
            id={"closePopUp"}
            className="close"
            onClick={(e) => ShowSaveKeys()}
          >
            &times;
          </a>
          <div>{content}</div>
        </div>
      </Popup>
    </>
  );
};
