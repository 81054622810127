import React from "react"
import Bell from "./../../../../../images/bell.png";
import BellActive from "./../../../../../images/bell_active.png";
import StopActive from "./../../../../../images/stop.png";
import Stop from "./../../../../../images/stop_trans.png";
import AddUser from "./../../../../../images/users/add-user.png";


export const TerminalDeviceSensorTriggerAdditionalActions = props => {

    const {trigger, onStopClick, onBellClick, onAddUserClick} = props
    
    return <div className="RowUISelectTypes titleSecondaryTSM">
    <div style={{ minWidth: "80%" }}>Acciones adicionales:</div>
    <div
      style={{ maxWidth: "20%", textAlign: "end", cursor: "pointer" }}
      onClick={(event) => onStopClick()}
    >
      <img
        src={
          trigger?.mustEnterInMaintenanceWhenExecuted ? StopActive : Stop
        }
        alt={"Botón para parar el sistema cuando se cumpla la condición."}
        className="IcoDelProgram"
      />
    </div>
    <div
      style={{ maxWidth: "20%", textAlign: "end", cursor: "pointer" }}
      onClick={(event) => onBellClick()}
    >
      <img
        src={trigger?.mustBeReportedWhenExecuted ? BellActive : Bell}
        alt={"Campana notificación cuando se cumpla la condición."}
        className="IcoDelProgram"
      />
    </div>
    <div
      style={{
        maxWidth: "20%",
        textAlign: "end",
        cursor: "pointer",
        opacity: `${trigger?.mustBeReportedWhenExecuted ? 1 : 0.4}`,
      }}
      onClick={(event) => onAddUserClick()}
    >
      <img
        src={AddUser}
        alt={"Botón para añadir los receptores de las alarmas."}
        className="IcoDelProgram"
      />
    </div>
  </div>
}