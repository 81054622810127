import React, { useCallback, useEffect } from "react";
import { projectsConstants } from "../../../../../../_constants/projects.constants";
import { DropdownC } from "../../../../Airframe/Dropdown/Dropdown";

export const TerminalDeviceSensorsDropDown = (props) => {
  const {
    sensors,
    maxAnalogInputs,
    disabled,
    onSelected,
    selectedSensor,
    physicalCommunicationType = projectsConstants.global.sensors.phys.analog,
    filterOnlyActiveAndConfigured = false,
    useSensorTypeDescpritionIfEmtpyCustomDescription = true,
    isClearable = false
  } = props;

  const getTerminalDeviceSensorsDropDown = useCallback(() => {

    let sensorsConfigured = [];
    let selectedOption = undefined;
    const maxSensors = filterOnlyActiveAndConfigured ? sensors?.length : maxAnalogInputs
    
    for (let i = 0; i < maxSensors; i++) {
      let sensorConfigured = false;

      let sensorOption = undefined;

      for (let y = 0; y < sensors.length; y++) {
        const currentSensor = sensors[y];
        if (
          currentSensor.sensorId.physicalCommunicationType === physicalCommunicationType
             && currentSensor.sensorIndex === i
        ) {
          sensorConfigured = true;
          const label = currentSensor.description || (useSensorTypeDescpritionIfEmtpyCustomDescription ? currentSensor.sensorId.description : "")
          sensorOption = {
            id: i + 1,
            value: `Sensor ${i + 1}: ${currentSensor.sensorId.name}`,
            label: `Sensor ${i + 1}: ${label}`,
            sensor: currentSensor
          };
          if (i === selectedSensor?.sensorIndex) {
       
            selectedOption = sensorOption;
          }
          break;
        }
      }
      const id = i + 1;
      if (sensorConfigured) {
        sensorsConfigured.push(sensorOption);
      } else if(!filterOnlyActiveAndConfigured) {
        const option = {
          id,
          value: `Sensor ${id}: NO CONFIGURADO`,
          label: `Sensor ${id}: NO CONFIGURADO`,
        };
        if (i === selectedSensor?.sensorIndex) {
          selectedOption = option;
        }
        sensorsConfigured.push(option);
      }
    }
   
    return (
      <>      
      <DropdownC
        options={sensorsConfigured}
        onChange={(selected) => onSelected(selected?.id - 1, selected?.sensor)}
        placeholder={selectedOption || "Seleccione un sensor..."}
        disabled={disabled}
        value={selectedOption || null}
        isClearable={isClearable}
      />
      </>
    );
  }, [isClearable, disabled, maxAnalogInputs, sensors, physicalCommunicationType, selectedSensor?.sensorIndex, onSelected, filterOnlyActiveAndConfigured, useSensorTypeDescpritionIfEmtpyCustomDescription]);

  useEffect(() => {
  }, [props]);

  return <>{getTerminalDeviceSensorsDropDown()}</>;
};
