import React from "react";
import Pressure from "./../../../../../images/ImagesTerminalsDevice/pressure_2.png";
import "./NotCardSensor.sass";
export const NotCardSensor = (props) => {
  const { message } = props;

  return (
    <>
     {/*  <div className="Title_Descripction SensorShow flex">
        <div className="CardPartA"></div>
        <div className="CardPartB">{message}</div>
      </div>*/}
    </>
  );
};
