import React, { useState, useEffect, useCallback } from "react";
import { getDateTimeFormatted } from "../../../../_helpers/DateFormatHelper";
import { DangerComponent } from "../../../DangerComponent/DangerComponent";
import { Headtimepreypos } from "../../Airframe/CheckCircle/Headtimepreypos";
import { Clock } from "../Fichas/Programs/components/Clock/Clock";
import { isTerminalDeviceValidForDatetimeTriggersWithSeconds } from "../Fichas/Triggers/TerminalDeviceTriggerChecker";
import { TerminalDeviceOutputsView } from "../OutputsView/TerminalDeviceOutputsView";

export const TriggerSubprogramForm = (props) => {
  const { terminalDevice, subprogram, onBack, onSave, defaultStartDateTime } = props;

  const states = {
    IDLE: "IDLE",
    HELP: "HELP",

    ERROR_INVALID_ACTIVATION_TIME: "ERROR_INVALID_ACTIVATION_TIME",
  };
  const initState = {
    state: states.IDLE,
    activation_data: {},
  };
  const [state, setState] = useState(initState);

  //#region  methods

  const loadDataFromActivation = useCallback((subprogram) => {
    setState((prev) => ({
      ...prev,
      activation_data: subprogram,
    }));
  }, []);

  const getStartDateTime = useCallback(() => {
    return state.activation_data?.startDateTime || defaultStartDateTime
  }, [defaultStartDateTime, state.activation_data?.startDateTime])

  const getEndDateTime = useCallback(() => {
      return state.activation_data?.endDateTime || defaultStartDateTime
  }, [defaultStartDateTime, state.activation_data?.endDateTime])
  //#endregion


  //#region on methods
  const onSelectOutput = useCallback((terminalDeviceId, output) => {
    setState((prev) => {
      let outputs = prev.activation_data?.outputs || 0;
      if ((outputs & (1 << (output - 1))) > 0) {
        // Desactivamos
        outputs &= ~(1 << (output - 1));
      } else {
        // Activamos
        outputs |= 1 << (output - 1);
      }
      return { ...prev, activation_data: { ...prev.activation_data, outputs } };
    });
  }, []);

  const onHourMinuteSecondsChanges = useCallback(
    (seconds, minute, hour) => {
      let unitsLimit = hour * 60 * 60 * 1000 + minute * 60 * 1000 + seconds * 1000;

      const newState = {
        ...state,
        activation_data: {
          ...state.activation_data,
          startDateTime: getStartDateTime(),
          endDateTime: new Date(getStartDateTime().getTime() + unitsLimit),
          unitsLimit,
        },
      };
      setState(newState);
    },
    [getStartDateTime, state]
  );
  //#endregion

  //#region components
  const getFilteredOutputs = useCallback(() => {
    let offset = 1,
      i = 0;
    const map = new Map();
    while (offset <= state.activation_data?.outputs) {
      if ((offset & state.activation_data?.outputs) > 0) {
        map.set(i + 1, true);
      }
      ++i;
      offset = 1 << i;
    }
    return map;
  }, [state.activation_data?.outputs]);

  const toHoursMinutesSeconds = useCallback(unitsLimit => {
    const segundos = Math.floor(unitsLimit / 1000);
    const horas = Math.floor(segundos / 3600);
    const minutos = Math.floor((segundos % 3600) / 60);
    const segundosRestantes = segundos % 60;

    return [horas, minutos, segundosRestantes];
  }, [])

  const getClockComponent = useCallback(() => {
    const [hour, minute, second] = toHoursMinutesSeconds(state?.activation_data?.unitsLimit || 0)

    return (
      <Clock
        SetHour={onHourMinuteSecondsChanges}
        minMinutes={0}
        initHour={`${hour}`.padStart(2, '0')}
        initMinute={`${minute}`.padStart(2, '0')}
        initSecond={`${second}`.padStart(2, '0')}
        title={
          <div className="HourStartIn">Seleccione el tiempo de activacion:</div>
        }
        maxHours={23}
        maxMinutes={59}
        maxSecond={isTerminalDeviceValidForDatetimeTriggersWithSeconds(terminalDevice) ? 59 : 0}
        maxMinutesWhenMaxHoursReached={59}
        onMaxHourSetMinutesToMax={true}
        applyMaxMinutsOnlyWhenMaxHoursReached={true}
        enabled={true}
      />
    );
  }, [terminalDevice, onHourMinuteSecondsChanges, state?.activation_data?.unitsLimit, toHoursMinutesSeconds]);

  const canBeSaved = useCallback(() => {
    let validLimit = state.activation_data?.unitsLimit > 0;
    return validLimit;
  }, [state.activation_data?.unitsLimit]);

  const getStartHourMinute = useCallback(() => {
    return getDateTimeFormatted(getStartDateTime());
  }, [getStartDateTime]);

  const getEndHourMinute = useCallback(() => {
    return getDateTimeFormatted(getEndDateTime());
  }, [getEndDateTime]);
  //#endregion

  useEffect(() => {
    loadDataFromActivation(subprogram);
  }, [loadDataFromActivation, subprogram]);

  return (
    <>
      <div className="TitleandProandPreRiego">
        <div className="TitleProgram">Programar salidas:</div>
      </div>

      <hr className="Separator3" />

      <div className="ViewInterfaceSubroutinesPart1">
        <div className="OutputsdViewSubroutines">
          <div className="TitleCard">
            Selecciona una o varias salidas para la subrutina.
          </div>

          <TerminalDeviceOutputsView
            onClick={onSelectOutput}
            outputs={terminalDevice?.outputs || []}
            forcedMapStates={getFilteredOutputs()}
            forcedSelectedColor={"rgb(33,150,243)"}
          />
          <div className="TitleCard">
            *Si no seleccionas ninguna salida, se considera tiempo de descanso.
          </div>
        </div>

        <div className="SelectTimeProgramS modRiego">
          <Headtimepreypos
            activeClockPre={() => {}}
            activeClockPos={() => {}}
            activeClockDefault={() => {}}
          />
          <div className="bodyClock">
            <>
              <div className="messageclockInfo">
                <>
                  {`Las salidas se activarán desde las ` +
                    getStartHourMinute() +
                    ` hasta las ` +
                    getEndHourMinute() +
                    `.`}
                </>
              </div>
              {getClockComponent()}
              {state.state === states.ERROR_INVALID_ACTIVATION_TIME && (
                <DangerComponent
                  message={
                    <h4>
                      Debe seleccionar el tiempo de activación de la salida
                    </h4>
                  }
                />
              )}
            </>
          </div>
        </div>
      </div>
      <hr className="Separator3" />

      <div className="Buttons">
        <div className="ButtonOffAll Secondary" onClick={(e) => onBack()}>
          Atras
        </div>
        {canBeSaved() ? (
          <div
            className="ButtonOffAll"
            onClick={(e) => onSave(state.activation_data)}
          >
            Aceptar
          </div>
        ) : (
          <div className="ButtonOffAll" disabled>
            Aceptar
          </div>
        )}
      </div>
    </>
  );
};
