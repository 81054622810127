import React, {useCallback} from "react"
import { TerminalDeviceOutputCard } from "./TerminalDeviceOutputCard";

export const TerminalDeviceOutputsCardsView = props => {
    const { terminalDevice } = props;

    const getOutputCards = useCallback(() => {
        if (terminalDevice?.outputs instanceof Array) {
          return terminalDevice.outputs
            .filter(
              (output) => output?.reportIntervalInSeconds > 0 && output?.measurement
            )
            .map((output, index) => <TerminalDeviceOutputCard key={index} terminalDevice={terminalDevice} output={output}></TerminalDeviceOutputCard>);
        }
        return <></>
      }, [terminalDevice]);

    return <div className="sensorsCardFather">{getOutputCards()}</div>
}