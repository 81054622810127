import React from "react"
import { projectsConstants } from "../../../_constants/projects.constants"
import { MqttMessageProcessor } from "./MqttMessageProcessor"

export const LoraEndoutputMQTT = (props) => {
    return <MqttMessageProcessor
    topic={projectsConstants.lora_outputs.actions.endoutput}
    processMsg={props.processMsg}
    processErrorMsg={props.processErrorMsg}
    project={projectsConstants.lora_outputs.id}
  ></MqttMessageProcessor>
}