import React, { useEffect, useState, useCallback } from "react";
import ProhibitedPng from "./images/stop.png";
import StartPng from "./images/start.png";
import StartTransPng from "./images/start_trans.png";
import StopTransPng from "./images/stop_trans.png";
import { PopUpC } from "../../../../Airframe/PopUp/PopUp";
import PropTypes from "prop-types";
import "./MaintenanceButton.sass";
import { TerminalDevice } from "../../../../../../classes/TerminalDevice/TerminalDevice";
import { Spinner } from "../../../../Airframe/Spinner/Spinner";

export const MaintenanceButton = (props) => {
  const {
    terminalDevice,
    stopButtonEnabled,
    ActionStopClick,
    ActionStartClick,
    InMaintenanceTransition,
    fromStartToStop,
  } = props;

  const classButtonstitleMaintenancefather="TitleAndButton2";
  const classButtonstitleMaintenance="ButtonMaintenance";
  const classButtonstitleMaintenance2="ButtonMaintenance2";
  const [showPopupConfirmation, setshowPopupConfirmation] = useState(false);
  const [classtitlemaintenance, setclasstitlemaintenance] = useState(classButtonstitleMaintenance);
  const [classtitlemaintenance2, setclasstitlemaintenance2] = useState(classButtonstitleMaintenancefather);

  const closePopUP = useCallback(() => {
    setshowPopupConfirmation(false);
  }, []);

  const ShowMessageConfirmMaintenance = useCallback(() => {
    setshowPopupConfirmation(true);
  }, []);

  const Confirm = useCallback(() => {
    ActionStopClick();
    closePopUP();
  }, [ActionStopClick, closePopUP]);

  useEffect(() => {
    if (terminalDevice.isInMaintenanceSync()) {
      setclasstitlemaintenance(classButtonstitleMaintenance);
    } else {
      setclasstitlemaintenance(classButtonstitleMaintenance2);
      setclasstitlemaintenance2(classButtonstitleMaintenancefather);
    }
  }, [terminalDevice.inMaintenance, terminalDevice.inMaintenanceExpected, classtitlemaintenance,classtitlemaintenance2]);

  return (
    <>
      <div className={classtitlemaintenance2}>
        <div className={classtitlemaintenance}>
          {!terminalDevice.isInMaintenanceSync() && <Spinner size="25px" />}
          {stopButtonEnabled ? (
            !terminalDevice.isMaintenanceExpected() ? (
              <>
                <div>
                  <img
                    src={ProhibitedPng}
                    alt="Stop"
                    className="PicPlugTD"
                    onClick={(e) => ShowMessageConfirmMaintenance()}
                  />
                </div>
              </>
            ) : (
              <>
                <div>
                  <img
                    src={StartPng}
                    alt="Start"
                    className="PicPlugTD"
                    onClick={(e) => ActionStartClick()}
                  />
                </div>
              </>
            )
          ) : InMaintenanceTransition === fromStartToStop ? (
            <>
              <div>
                <img
                  src={StartTransPng}
                  alt="Esperando respuesta"
                  className="PicPlugTD"
                />
              </div>
            </>
          ) : (
            <>
              <div>
                <img
                  src={StopTransPng}
                  alt="Esperando respuesta"
                  className="PicPlugTD"
                />
              </div>
            </>
          )}
          <PopUpC
            activate={showPopupConfirmation}
            deactivatepopup={closePopUP}
            content={
              <div className="contentpopup">
                <div className="textapp">
                  Estas seguro de que quieres poner esta tarjeta en
                  mantenimiento?
                </div>{" "}
                <div className="ButtonConfirmPopup" onClick={(e) => Confirm()}>
                  Confirmar
                </div>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

MaintenanceButton.propTypes = {
  terminalDevice: PropTypes.instanceOf(TerminalDevice),
};

MaintenanceButton.defaultProps = {
  terminalDevice: new TerminalDevice(),
};
