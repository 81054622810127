var path = require("path");

var root = path.join(__dirname);

var config = {
  rootDir: root,
  // Targets ========================================================
  serveDir: path.join(root, ".serve"),
  distDir: path.join(root, "dist"),
  clientManifestFile: "manifest.webpack.json",
  clientStatsFile: "stats.webpack.json",

  // Source Directory ===============================================
  srcDir: path.join(root, "app"),
  srcServerDir: path.join(root, "server"),

  // HTML Layout ====================================================
  srcHtmlLayout: path.join(root, "app", "index.html"),

  // Site Config ====================================================
  siteTitle: "TecoCloud",
  siteDescription: "Panel de control de los dispositivos Teco",
  siteCannonicalUrl: "http://dev.teco.tecatel.com:4100",
  siteKeywords: "tecatel teco iot tecoCloud",
  scssIncludes: []
};

module.exports = config;
