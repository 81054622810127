import React, { useCallback, useEffect, useState } from "react";
import { OptionsPrePos } from "./OptionsPrePos";
import { CardActivate } from "./TypeCards/CardActivate";
import { CardDeactivate } from "./TypeCards/CardDeactivate";
export const CardElementDrapDrop = (props) => {
  const {
    index,
    startDateTime,
    subprogram,
    actionOnEdit,
    actionOnDelete,
    actionOnRestore,
    innerRef,
    NumberCardsNotified,
    showMessageNotEdit,
    typeofCard,
  } = props;

  const [changetypecard, setchangetypecard] = useState(0);


  useEffect(() => {

    if (typeofCard === "subprogram") {
      setchangetypecard(1);
    } else {
      setchangetypecard(0);
    }
  }, [typeofCard]);

  const getOutputTasksNotified = useCallback(() => {
    let toRet = [undefined, undefined, undefined];
    if (subprogram?.outputTasks && subprogram.outputTasks instanceof Array) {
      let notified = 0;
      let pending = 0;
      const total = subprogram.outputTasks.length;
      subprogram.outputTasks.forEach((outputTask) => {
        if (outputTask?.notified) {
          notified++;
        } else {
          pending++;
        }
      });

      toRet = [notified, pending, total];
    }
    return toRet;
  }, [subprogram]);
  useEffect(() => {
    const [notified] = getOutputTasksNotified();
    NumberCardsNotified(notified);
  }, [subprogram, NumberCardsNotified, getOutputTasksNotified]);

  const getOutputs = useCallback(() => {
    let pos = 0,
      i = 0;
    let outputs = [];
    let outputsSelected = subprogram?.outputs || 0;
    if (outputsSelected === 0) {
      return <div>Tiempo de descanso</div>;
    }
    while (pos < outputsSelected) {
      pos = 1 << i;
      if ((outputsSelected & pos) > 0) {
        outputs.push(
          <OutputComponent key={i} outputId={i + 1}></OutputComponent>
        );
      }
      i++;
    }
    return outputs;
  }, [subprogram]);

  //Cambiar por useeffect
  const isSubprogramNotified = useCallback(() => {
    const [notified, pending, total] = getOutputTasksNotified();

    return total > 0 && notified === total;
  }, [subprogram, getOutputTasksNotified]);

  return (
    <>
      {/* Modification con two components for onclick only deactivated */}
      {!isSubprogramNotified() ? (
        <div
          key={index}
          className={`Title_Descripction CardsSubroutine ${
            isSubprogramNotified() ? "Notified" : "NotNotified"
          } ${subprogram?.deleted_by_user ? "Deleted" : ""} cardS-body`}
          ref={innerRef}
        >
          {changetypecard ? (
            <>
             
              <CardActivate
                typeofCard={typeofCard}
                startDateTime={startDateTime}
                subprogram={subprogram}
                isSubprogramNotified={isSubprogramNotified}
                actionOnDelete={actionOnDelete}
                actionOnRestore={actionOnRestore}
                showMessageNotEdit={showMessageNotEdit}
                actionOnEdit={actionOnEdit}
              />
            </>
          ) : (
            <>
         
              <CardActivate
                typeofCard={typeofCard}
                startDateTime={startDateTime}
                subprogram={subprogram}
                isSubprogramNotified={isSubprogramNotified}
                actionOnDelete={actionOnDelete}
                actionOnRestore={actionOnRestore}
                showMessageNotEdit={showMessageNotEdit}
                actionOnEdit={actionOnEdit}
              />
            </>
          )}
          <div className="OutputsCardSubroutine">
            <div className="Salidas">{getOutputs()}</div>
          </div>

<OptionsPrePos subprogram={subprogram}/>


        </div>
      ) : (
        <div
          key={index}
          className={`Title_Descripction CardsSubroutine ${
            isSubprogramNotified() ? "Notified" : "NotNotified"
          } ${subprogram?.deleted_by_user ? "Deleted" : ""} cardS-body`}
          ref={innerRef}
          onClick={() => showMessageNotEdit()}
        >
          {changetypecard ? (
            <>
              
              <CardDeactivate
                startDateTime={startDateTime}
                subprogram={subprogram}
                isSubprogramNotified={isSubprogramNotified}
                actionOnDelete={actionOnDelete}
                actionOnRestore={actionOnRestore}
                showMessageNotEdit={showMessageNotEdit}
                actionOnEdit={actionOnEdit}
              />
            </>
          ) : (
            <>
              <CardDeactivate
                startDateTime={startDateTime}
                subprogram={subprogram}
                isSubprogramNotified={isSubprogramNotified}
                actionOnDelete={actionOnDelete}
                actionOnRestore={actionOnRestore}
                showMessageNotEdit={showMessageNotEdit}
                actionOnEdit={actionOnEdit}
              />
            </>
          )}

          <div className="OutputsCardSubroutine">
            <div className="Salidas">{getOutputs()}</div>
          </div>
          <OptionsPrePos/>

        </div>
      )}
    </>
  );
};

const OutputComponent = (props) => {
  const { outputId } = props;

  return (
    <>
      <div className="outputpilldragodrop">
        <div className="badge-primary2 outputvi">{outputId}</div>
      </div>
    </>
  );
};
