import React, { useEffect, useCallback, useState } from "react";
import { SliderC } from "../../../../Airframe/Slider/Slider";
import { SliderWithMultiplier } from "./SliderWithMultiplier";

export const ModePulsesperMinute = (props) => {
  const { activationParams, onActivationParamsChange, mod } = props;
  const [actParams, setactParams] = useState(activationParams);
  const [personalizedOrNot, setpersonalizedOrNot] = useState(getCalculatedMode(actParams));
  const [pwm, setPwm] = useState(getPwm(actParams));



  useEffect(() => {
    setmultiplierOnSelected(getActParamsMultiplierOn(activationParams));
    setmultiplierOffSelected(getActParamsMultiplierOff(activationParams));
    setpersonalizedOrNot(getCalculatedMode(activationParams));
    setPwm(getPwm(activationParams));
    setactParams(activationParams);

  }, [activationParams]);




  /**
   * Obtiene de activationPArams si se utiliza la opción de datos calculados o personalizados.
   * @param {*} activationParams 
   */
  function getCalculatedMode(activationParams) {
    return (activationParams?.custom || false);
  }

  function getPwm(activationParams) {
    return activationParams?.pwm || 1;
  }


  const defaultMultiplier = {
    id: 0,
    label: "Segundos",
    maxValue: 10 * 90,
    minValue: 0,
    multiplier: 1,
    baseUnit: true,
    step: 5
  };
  const multipliers = [
    defaultMultiplier,
    {
      id: 1,
      label: "Minutos",
      maxValue: 360,
      minValue: 0,
      multiplier: 60,
      baseUnit: false,
      step: 1
    },
    {
      id: 2,
      label: "Horas",
      maxValue: 24,
      minValue: 0,
      multiplier: 3600,
      baseUnit: false,
      step: 1
    }
  ];

  function getMultiplierFromParams(mult) {
    let foundMultiplier = undefined;
    if (mult != undefined) {
      multipliers.forEach(m => {
        if (foundMultiplier === undefined && mult?.id === m.id) {
          foundMultiplier = m;
        }
      });
    }
    return foundMultiplier;
  }

  function getActParamsMultiplierOn(activationParams) {
    return getMultiplierFromParams(activationParams?.multiplier_on) || defaultMultiplier;
  }

  function getActParamsMultiplierOff(activationParams) {
    return getMultiplierFromParams(activationParams?.multiplier_off) || defaultMultiplier;
  }

  const [multiplierOnSelected, setmultiplierOnSelected] = useState(getActParamsMultiplierOn(actParams));
  const [multiplierOffSelected, setmultiplierOffSelected] = useState(getActParamsMultiplierOff(actParams));

  /**
   * Notifica los valores cuando varia el slider para lo opción de datos calculados.
   */
  const onCalculatedSliderChangeByMinute = useCallback((pwm) => {
    let [valueModStart, valueModStop] = calculateFirstAndLast(pwm);
    let tmp = { ...actParams, pwm, first_pulse: valueModStart, last_pulse: valueModStop, custom: false };

    onActivationParamsChange(tmp);
    setPwm(pwm);
  }, [actParams]);

  const calculateFirstAndLast = useCallback((pwm) => {
    let valueMod = pwm * 3;
    valueMod = 60000 / valueMod / 2;
    return [valueMod, valueMod];
  }, []);

  /**
   * Cuando cambia el valor del slider para pulsos ON.
   * Se encarga de asignar first_pulse para el modo personalizado.
   */
  const onStartPulsesChange = useCallback((first_pulse) => {
    //setStart(value);
    let tmp = { ...actParams, first_pulse: first_pulse * 1000, multiplier_on: multiplierOnSelected, custom: true };



    setactParams(tmp);
    onActivationParamsChange(tmp);
  }, [actParams, multiplierOnSelected]);

  /**
   * Cuando cambia el valor del slider para pulsos OFF.
   * Se encarga de asignar last_pulse para el modo personalizado.
   */
  const onStopPulsesChange = useCallback((last_pulse) => {
    // setStop(value);
    let tmp = { ...actParams, last_pulse: last_pulse * 1000, multiplier_off: multiplierOffSelected, custom: true };

    setactParams(tmp);
    onActivationParamsChange(tmp);
  }, [actParams, multiplierOffSelected]);

  /**
   * Establece los valores cuando se selecciona la opcíon de cálculo auotmático.
   */
  //Por minuto
  const onActionCalculatedModeSelected = useCallback(() => {

    let [valueModStart, valueModStop] = calculateFirstAndLast(pwm);
    let { multiplier_on, multiplier_off, ...tmp } = { ...activationParams, custom: false, pwm, first_pulse: valueModStart, last_pulse: valueModStop };

    setpersonalizedOrNot(false);
    setPwm(getPwm(activationParams));

    onActivationParamsChange(tmp);
  }, [activationParams, pwm, personalizedOrNot]);

  /**
   * Establece los valores cuando se selecciona la opcíon de campos personalizados.
   */
  //Personalizado
  const onActionCustomModeSelected = useCallback(() => {

    let tmp = { ...activationParams, custom: true, multiplier_on: multiplierOnSelected, multiplier_off: multiplierOffSelected, first_pulse: 0, last_pulse: 0 };


    setpersonalizedOrNot(true);
    setmultiplierOnSelected(getActParamsMultiplierOn(activationParams));
    setmultiplierOffSelected(getActParamsMultiplierOff(activationParams));
   
    onActivationParamsChange(tmp);
  }, [activationParams, personalizedOrNot, multiplierOnSelected]);


  /**
   * Controla el cambio de multiplicador ON sleccionado para poder almacenarlo.
   */
  const onMultiplierOnChange = useCallback((multiplier) => {
  
    let tmp = { ...actParams, multiplier_on: multiplier };


    onActivationParamsChange(tmp);
    setmultiplierOnSelected(multiplier);
  }, [actParams]);

  /**
   * Controla el cambio de multiplicador OFF sleccionado para poder almacenarlo.
   */
  const onMultiplierOffChange = useCallback((multiplier) => {

    let tmp = { ...actParams, multiplier_off: multiplier };

    onActivationParamsChange(tmp);
    setmultiplierOffSelected(multiplier);
  }, [actParams]);

  /**
   * Obtiene el valor real en base a un multiplicador.
   */
  const getRealValue = useCallback((realValue, multiplier) => {
 
    let multValue = 0;
    if (realValue) {
      multValue = parseInt((realValue / 1000) / multiplier.multiplier);
    }
    return multValue;
  })


  return (
    <>
      <div className="pill-container">
        <div
          className={`pill secondary ${!personalizedOrNot && "selected"}`}
          active={/*personalizedOrNot ? !personalizedOrNot.toString() :*/ "true"}
          onClick={() => onActionCalculatedModeSelected()}
        >
          Por minuto
        </div>
        <div
          className={`pill secondary ${personalizedOrNot && "selected"}`}
          active={/*!personalizedOrNot ? (personalizedOrNot).toString() : */"true"}
          onClick={() => onActionCustomModeSelected()}
        >
          Personalizado
        </div>
      </div>
<div className="cardModepulsesPerMinute">
      {!personalizedOrNot && (
        <>
          <div className="Title_Descripction marginpulses">Pulsos por minuto: {pwm * 3}</div>
          <SliderC onChange={onCalculatedSliderChangeByMinute} value={pwm} max={100} min={0} enabled={true} />
        </>
      )}
      {personalizedOrNot && (
        <>
          <SliderWithMultiplier
            initValue={getRealValue(actParams?.first_pulse, multiplierOnSelected)}
            name={"One"}
            label={"Tiempo activa: "}
            onChange={(value) => {
              onStartPulsesChange(value);
            }}
            onMultiplierSelected={onMultiplierOnChange}
            multipliers={multipliers}
            multiplierSelectedId={multiplierOnSelected?.id || 0}
          ></SliderWithMultiplier>
          <SliderWithMultiplier
            initValue={getRealValue(actParams?.last_pulse, multiplierOffSelected)}
            name={"Two"}
            label={"Tiempo parada: "}
            onChange={(value) => {
              onStopPulsesChange(value);
            }}
            onMultiplierSelected={onMultiplierOffChange}
            multipliers={multipliers}
            multiplierSelectedId={multiplierOffSelected?.id || 0}
          ></SliderWithMultiplier>
        </>
      )}
      </div>
    </>
  );
};
