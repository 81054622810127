import React from "react";
import {
  TwoOptionsBox,
  options as boxOptions,
} from "../TerminalDevices/Fichas/SensorsConfiguration/TwoOptionsBox";
import { TerminalDeviceSimpleCalibration } from "./TerminalDeviceSimpleCalibration";
import { TerminalDeviceOutputTimeOrCudCalibration } from "./TerminalDeviceOutputTimeOrCudCalibration";

export const TerminalDeviceCalibrationTwoOptions = (props) => {
  const {
    calibrationRawValue,
    calibrationUnitValue,
    calibrationOptions,
    onCalibrationRawValueChange,
    onCalibrationUnitValueChange,
    terminalDevice,
    unit,
    cud,
    output,

    rawTitle,
    unitsTitle,
  } = props;

  const manualOption = (
    <TerminalDeviceSimpleCalibration
      rawTitle={rawTitle}
      unitsTitle={unitsTitle}
      calibrationRawValue={calibrationRawValue}
      calibrationUnitValue={calibrationUnitValue}
      onCalibrationRawValueChange={onCalibrationRawValueChange}
      onCalibrationUnitValueChange={onCalibrationUnitValueChange}
    />
  );

  const automaticOption = (
    <TerminalDeviceOutputTimeOrCudCalibration
      calibrationRawValue={calibrationRawValue}
      calibrationUnitValue={calibrationUnitValue}
      calibrationOptions={calibrationOptions}
      onCalibrationRawValueChange={onCalibrationRawValueChange}
      onCalibrationUnitValueChange={onCalibrationUnitValueChange}
      terminalDevice={terminalDevice}
      unit={unit}
      cud={cud}
      output={output}
      rawTitle={rawTitle}
      unitsTitle={unitsTitle}
    />
  );

  return (
    <TwoOptionsBox
      optionOneTitle={<>Automático</>}
      optionOneContent={automaticOption}
      optionTwoTitle={<>Manual</>}
      optionTwoContent={manualOption}
      optionSelected={boxOptions.one}
    ></TwoOptionsBox>
  );
};
