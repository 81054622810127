import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const Timer = (props) => {
  const {
    isActive,
    callback,
    callbackParams,
    callbackFirstExecutionDelayMiliseconds,
    callbackExecutionEveryMiliseconds,
    timerIntervalMiliseconds,
  } = props;

  const [milisecondsCount, setMilisecondsCount] = useState(0);

  const [delayCallbackDone, setDelayCallbackDone] = useState(false);

  useEffect(() => {
    if (isActive) {
      if (
        !delayCallbackDone &&
        callbackFirstExecutionDelayMiliseconds > 0 &&
        milisecondsCount >= callbackFirstExecutionDelayMiliseconds
      ) {
       // console.log(1111);
        // Debemos ejecutar el callback.
        setDelayCallbackDone(true);
        callback(callbackParams);
        setMilisecondsCount(0); // Reiniciamos el contador.
      } else if (
        !delayCallbackDone &&
        !callbackFirstExecutionDelayMiliseconds
      ) {
        setDelayCallbackDone(true);
        callback(callbackParams);
        setMilisecondsCount(0); // Reiniciamos el contador.
      } else if (
        delayCallbackDone &&
        milisecondsCount >= callbackExecutionEveryMiliseconds
      ) {
      //  console.log(33333);
        //Se ejecuta al acabar el tiempo
        callback(callbackParams);
        setMilisecondsCount(0);
      }

      const interval = setInterval(() => {
        setMilisecondsCount(milisecondsCount + timerIntervalMiliseconds);
      }, timerIntervalMiliseconds);
      return function () {
   
        clearInterval(interval);
      };
    }
  }, [milisecondsCount, isActive]);

  return <></>;
};

export default Timer;

Timer.propTypes = {
  isActive: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  callbackParams: PropTypes.any,
  callbackFirstExecutionDelayMiliseconds: PropTypes.number.isRequired,
  callbackExecutionEveryMiliseconds: PropTypes.number.isRequired,
  timerIntervalMiliseconds: PropTypes.number.isRequired,
};
