import React from "react";
import { Spinner } from "../../../Airframe/Spinner/Spinner";

export const TerminalDeviceSensorNotifiedState = (props) => {
  const { sensor } = props;

  if (sensor?.notified === false) {
    return (
      <div className="head_TsmConfigure">
        <div>Sincronizando con el dispositivo...</div>
        <Spinner></Spinner>
      </div>
    );
  }

  return <></>;
};
