import React, { useState,useEffect, useRef, useCallback } from "react";
import "./InputGroup.sass";

export const InputGroupwithid = (props) => {
  const {
    id,
    placeholder,
    value,
    symbol,
    type,
    className,
    onChange,
    foco,
    min,
    max,
    personalized,
    changeCondition,
    disabled
  } = props;
  const startInputRef = useRef(null);
  const [classPersonalized, setclassPersonalized] = useState("input-group InputGroupTeco");
  useEffect(() => {
    if (foco) {
      startInputRef.current.focus();
    }
  }, [foco]);

  useEffect(() => {

    if(personalized==="tsm"){
      setclassPersonalized("input-group InputGroupTeco inputTSM");
    }else if(personalized==="calibrate"){

      setclassPersonalized("input-group InputGroupTeco inputCalibrate");

    }
    else{
      setclassPersonalized("input-group InputGroupTeco");
    }
  }, [personalized]);

  const internalOnChange = useCallback((value, id) => {
    if(onChange){
      onChange(value, id);
    }
  }, [onChange])

  return (
    <>
      <div
        className={classPersonalized}
      >
       
        <input
          ref={startInputRef}
          onChange={(e) => internalOnChange(e.target.value, id)}
          placeholder={placeholder}
          value={value}
          id={id}
          type={type}
          className={"form-control " + className}
          min={min}
          max={max}
          disabled={disabled || false}
        />
     

        {personalized === "tsm" && (
          <div
            className="input-group-prepend tecogroup-prependPostigel inputTSM"
            onClick={(e) => changeCondition()}
          >
            <span className="tecogroup-text">{symbol}</span>
          </div>
        )}

        {personalized === "calibrate" && (
          <div
            className="input-group-prepend tecogroup-prependPostigel calibrateInputGroup"
           
          >
            <span className="tecogroup-text">{symbol}</span>
          </div>
        )}

        {personalized !== "tsm" && personalized !== "calibrate" && (
          <div className="input-group-prepend tecogroup-prependPostigel ">
            <span className="tecogroup-text">{symbol}</span>
          </div>
        )}
      </div>
    </>
  );
};
