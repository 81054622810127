import React, { useCallback } from "react";
import "./OptionsPrePos.sass";
export const OptionsPrePos = (props) => {
  const { subprogram } = props;

  const validDelay = useCallback(delay => {
    const splitedDate = delay.split(":")
      return splitedDate.length >= 2 && parseInt(splitedDate[0]) > 0 && parseInt(splitedDate[1]) > 0
  }, [])


  const shouldShow = useCallback((delay) => {
    return typeof delay === "string" && validDelay(delay)
  }, [validDelay])


  return (
    <div className="pillcardsP">
      { shouldShow(subprogram?.initDelay) && <div className="pillCardP">pre: {subprogram?.initDelay}</div>}
      { shouldShow(subprogram?.endDelay) && <div className="pillCardP">pos: {subprogram?.endDelay}</div>}
    </div>
  );
};
