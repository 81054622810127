import React, { useCallback, useMemo } from "react";
import Toggle from "react-toggle";
import { DropdownC } from "../../../../Airframe/Dropdown/Dropdown";
import PropTypes from "prop-types";
import { PaginatedOptions } from "../../../../PaginatedOptions/PaginatedOptions";
import { Spinner } from "../../../../Airframe/Spinner/Spinner";
import { projectsConstants } from "../../../../../../_constants/projects.constants";
import { SensorGenericMeasurementConfiguration } from "./SensorGenericMeasurementConfiguration";

export const SensorConfiguration = (props) => {
  const {
    sensor,
    sensorTypes,
    onChangeActivation,
    onChangeSensorType,
    onChangeReportInterval,
    sensorTypeSelectorDisabled,
    sensorTypeSelectorDisabledInfoComponent,
    onTerminalDeviceSensorMeasurementChange,
  } = props;

  const reportOptions = useMemo(() => [2, 5, 15, 30, 60], []);

  const onActivedChange = useCallback(() => {
    onChangeActivation(!sensor?.active);
  }, [onChangeActivation, sensor]);

  const onTypeChange = useCallback(
    (selected) => {
      onChangeSensorType(selected.value);
    },
    [onChangeSensorType]
  );

  const onReportOptionClick = useCallback(
    (optionInMinutes) => {
      onChangeReportInterval(optionInMinutes * 60);
    },
    [onChangeReportInterval]
  );

  const isAGenericVoltageOrMilliAmpsSensor = useCallback(
    (terminalDeviceSensor) => {
      return (
        terminalDeviceSensor?.sensorId?.name ===
          projectsConstants.global.sensors.name.generic_voltage ||
        terminalDeviceSensor?.sensorId?.name ===
          projectsConstants.global.sensors.name.generic_milli_amps
      );
    },
    []
  );

  const getSensorTypesComponent = useCallback(() => {
    let selectedSensor = null;
    const sensors = sensorTypes.map((sensorType) => {
      const optionSensor = {
        value: sensorType.id,
        label: (
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            {sensor?.notified === false && <Spinner size={"10px"}></Spinner>}
            {sensorType.description}
          </div>
        ),
      };
      if (sensor?.sensorId?.id === sensorType.id) {
        selectedSensor = optionSensor;
      }
      return optionSensor;
    });

    return (
      <div className="divdropdown">
        <DropdownC
          options={sensors}
          value={selectedSensor}
          onChange={onTypeChange}
          placeholder={"Seleccione el tipo de sensor..."}
          disabled={!sensor || sensorTypeSelectorDisabled}
          changestyles={true}
        />
        {sensorTypeSelectorDisabled && sensorTypeSelectorDisabledInfoComponent}
        <div className="toggleVoltage">
          <div className="childCardDevices2">
            <div className="Title_Descripction">Estado:</div>
          </div>
          <div className="TogglewithInfo">
            <div className="DetailsToggle">OFF</div>
            <Toggle
              id="cheese-status"
              checked={sensor?.active || false}
              icons={false}
              onChange={onActivedChange}
              className="toggle"
              disabled={!sensor}
            />
            <div className="DetailsToggle">ON</div>
          </div>
        </div>
        {sensor?.active && (
          <>
            {isAGenericVoltageOrMilliAmpsSensor(sensor) && (
              <SensorGenericMeasurementConfiguration
                terminalDeviceSensor={sensor}
                onTerminalDeviceSensorMeasurementChange={onTerminalDeviceSensorMeasurementChange}
              />
            )}
            <PaginatedOptions
              label={"Reporte cada"}
              unit={"min"}
              values={reportOptions}
              valueActive={sensor?.reportIntervalInSeconds / 60}
              onOptionClick={onReportOptionClick}
            ></PaginatedOptions>
          </>
        )}
      </div>
    );
  }, [sensorTypes, onTypeChange, sensor, sensorTypeSelectorDisabled, sensorTypeSelectorDisabledInfoComponent, onActivedChange, isAGenericVoltageOrMilliAmpsSensor, onTerminalDeviceSensorMeasurementChange, reportOptions, onReportOptionClick]);

  return (
    <>{sensorTypes?.length > 0 ? <>{getSensorTypesComponent()}</> : <></>}</>
  );
};

const sensorShape = {
  active: PropTypes.bool.isRequired,
  sensorId: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  reportIntervalInSeconds: PropTypes.number,
};
SensorConfiguration.propTypes = {
  sensor: PropTypes.shape(sensorShape),
  sensorTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
  onChangeActivation: PropTypes.func.isRequired,
  onChangeSensorType: PropTypes.func.isRequired,
};
