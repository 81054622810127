import React, { useState, useEffect } from "react";

import "./Cardpostigel.sass";
import "./../../../components/TerminalDevices/TerminalDevices/Components/LiquidBarchart.sass"
import { ReactComponent as IcoLiquid } from '../images/liquidIco.svg';
import { RecipientRechart } from "./RecipientRechart";
export const LiquidBarchart = (props) => {
    const { liquid, dataContainer } = props
    const [sizeCapacity, setsizeCapacity] = useState(1);
    const mydata = [
        {
            name: 'Líquido',
            //desde donde comienza, valor actual y valor fin-->
            liquido: [0, liquid / 100, sizeCapacity],
        },
    ];
    const [selectSize, setselectSize] = useState(undefined);



    const sizesCentilitres = {
        small: 1000,
        big: 5000,
    }



    useEffect(() => {

        if (dataContainer.capacity === sizesCentilitres.big) {

            setselectSize(sizesCentilitres.big);
            setsizeCapacity(sizesCentilitres.big / 1000);
        } else if (dataContainer.capacity === sizesCentilitres.small) {

            setselectSize(sizesCentilitres.small);
            setsizeCapacity(sizesCentilitres.small / 1000);
        }
    }, []);

    return (
        <div className="cardSavePostigel">
            <div className="rechartLiquido">
                <div className="titlecardpostiwithico">
                    <div className="cardPostigelIco">
                        <IcoLiquid className="IcoPostigel2" />
                    </div>
                    <div className="titlecardSavePostigel">Nivel de Líquido postigel</div>
                </div>
                <RecipientRechart data={mydata} />
            </div>
        </div>
    );
};
