import React, { useEffect, useCallback, useState } from "react";
export const HelpCardDropdown = (props) => {
    const { title, ico, content, viewInfo, closeInfo, type, id } = props;
    const [accordionOpen, setaccordionOpen] = useState(false);


    useEffect(() => {
   
        setaccordionOpen(type);
    }, [type]);

    const opentoRead = useCallback(() => {
        setaccordionOpen(!accordionOpen);


        if (accordionOpen) {
            closeInfo(id);
        } else {
            viewInfo(content, id);
        }

    }, [accordionOpen, type]);


    return (
        <>
            <div className="subhelptitle subhelptitlewithimageCard" onClick={(e) => opentoRead()}>
                <div
                    className={`iconDropdownHelp fa fa-fw ${accordionOpen ? "fa-chevron-down" : "fa-chevron-right"
                        }`} />


                <div className="fixPositionHelp">
                    <div> {ico} </div> <div className="titlehelpDescriptionPostigel">{title}</div>
                </div>

            </div>


        </>
    );
};
