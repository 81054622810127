import React, { useCallback, useMemo } from "react";
import axios from "axios";
import { Authservice } from "../_services/Authservice";

const UploaderContext = React.createContext();

export const UploaderProvider = (props) => {
  const upload = useCallback((url, file, onUploadProgress) => {
    const formData = new FormData();
    formData.append("file", file);

    const options = Authservice.getRequestOptions("POST");
    options.headers = {
      ...options.headers,
      "content-type": "multipart/form-data",
    };
    options.onUploadProgress = onUploadProgress;
    return axios
      .post(url, formData, options)
      .then(Authservice.handleResponse)
      .then((response) => {
        return response;
      });
  }, []);

  const uploadSectorImage = useCallback(
    (zoneId, sectorId, file, onUploadProgress, orientation) => {
      return upload(
        `${
          process.env.REACT_APP_API_PATH
        }/zones/${zoneId}/sectors/${sectorId}/images/?orientation=${
          orientation || 0
        }`,
        file,
        onUploadProgress
      );
    },
    [upload]
  );

  const uploadTerminalImage = useCallback(
    (terminalId, file, onUploadProgress, orientation) => {
      return upload(
        `${
          process.env.REACT_APP_API_PATH
        }/terminals/${terminalId}/images/?orientation=${orientation || 0}`,
        file,
        onUploadProgress
      );
    },
    [upload]
  );

  const uploadTriggersExcel = useCallback((terminalId, file, onUploadProgress) => {
    return upload(
      `${
        process.env.REACT_APP_API_PATH
      }/terminals/${terminalId}/devices/0/triggers/excel`,
      file,
      onUploadProgress
    );
  }, [upload])

  const value = useMemo(() => {
    return {
      uploadSectorImage,
      uploadTerminalImage,
      uploadTriggersExcel
    };
  }, [uploadSectorImage, uploadTerminalImage, uploadTriggersExcel]);

  return <UploaderContext.Provider value={value} {...props} />;
};

export const useUploader = () => {
  const context = React.useContext(UploaderContext);
  if (!context) {
    throw new Error(
      "useUploader debe estar dentro del proveedore UploaderContext"
    );
  }
  return context;
};

export default UploaderContext;
