import React, { useCallback } from "react";
import { DropdownC } from "../../../Airframe/Dropdown/Dropdown";
import PropTypes from "prop-types"
import { Output } from "../../../../../classes/TerminalDevice/Output/Output";
import { Spinner } from "../../../Airframe/Spinner/Spinner";
import { Badge } from "reactstrap";
import { projectsConstants } from "../../../../../_constants/projects.constants";

import "./OutputConfigurationStyles.sass"

export const TerminalDeviceOutputsDropDown = (props) => {

    const {outputs, disabled, selectedOutput, onChange} = props;

    const internalCallback = useCallback(event => {
      const outputId = event.value;
  
      onChange(outputId, outputs.filter(o => o.output === outputId)[0]);
    }, [onChange, outputs])

    const getOutputColor = useCallback(output => {
      switch(output.currentState){
        case projectsConstants.global.states.output_activated:
        case projectsConstants.global.states.output_active:
          return "text-success"
        case projectsConstants.global.states.output_deactivated:
        case projectsConstants.global.states.output_transition:
        default:
          return "text-dark"
      }
    }, [])

    const getOutputIcon = useCallback(output => {
      switch(output.currentState){
        case projectsConstants.global.states.output_activated:
        case projectsConstants.global.states.output_active:
          return "SmallOutputActive fa fa-play"
        case projectsConstants.global.states.output_deactivated:
          return "SmallOutputStopped fa fa-stop"
        case projectsConstants.global.states.output_transition:
        default:
          return "SmallOutputPaused fa fa-pause"
      }
    }, [])

    const getOutputsDropdown = useCallback(() => {
        let selectedOption = null;
        const options = Object.keys(outputs).map((item, i) => {
          const output = outputs[i];
          const option = {
            value: output.output,
            label: (
              <div style={{display: "flex", flexDirection: "row", width: "100%"}}>
                <div>{output.output + "." + (output.description || "")}</div>
                {<div className="SmallOutputInfo"><i className={`${getOutputColor(output)} ${getOutputIcon(output)}`}></i></div>}
                {output instanceof Output &&
                  output.getActivationType() !==
                    output.getActivationTypeExpected() && (
                    <Spinner size={"10px"}></Spinner>
                  )}
              </div>
            ),
          };
          if (output.output === selectedOutput?.output) {
            selectedOption = option;
          }
          return option;
        });
        return (
          <>
            <DropdownC
              options={options}
              value={selectedOption}
              onChange={internalCallback}
              placeholder={"Seleccione una salida..."}
              disabled={disabled}
            />
          </>
        );
      }, [outputs, internalCallback, disabled, getOutputColor, getOutputIcon, selectedOutput?.output]);

      return <>{getOutputsDropdown()}</>
}

TerminalDeviceOutputsDropDown.propTypes = {
    outputs: PropTypes.arrayOf(PropTypes.instanceOf(Output)),
    disabled: PropTypes.bool.isRequired,
    selectedOutput: PropTypes.instanceOf(Output),
    onChange: PropTypes.func.isRequired
}