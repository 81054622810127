import React, { useCallback, useEffect, useState } from "react";
import { useTerminalDevice } from "../../../../../context/terminal-device-context";
import { useTriggers } from "../../../../../_services/triggers.service";
import { Input } from "reactstrap";
import Toggle from "react-toggle";

const minValue = 15;

function getDefaultInitConfig(terminalDevice) {
  return {
    triggerWeeklyActivationGracePeriod:
      terminalDevice.triggerWeeklyActivationGracePeriod || minValue,
    triggerWeeklyChecks: terminalDevice.triggerWeeklyChecks,
  };
}

export const TerminalDeviceTriggersConfig = (props) => {
  const { terminalDevice } = useTerminalDevice();
  const { updateConfig } = useTriggers();

  const [state, setState] = useState(getDefaultInitConfig(terminalDevice));

  useEffect(() => {
    setState(getDefaultInitConfig(terminalDevice));
  }, [terminalDevice]);

  const isValidGracePeriod = useCallback((gracePeriod) => {
    return Number.isInteger(gracePeriod) && gracePeriod >= minValue;
  }, []);

  const setGracePeriod = useCallback((triggerWeeklyActivationGracePeriod) => {
    const gracePeriod = parseInt(triggerWeeklyActivationGracePeriod, 10);
    if (Number.isInteger(gracePeriod)) {
      setState((current) => ({
        ...current,
        triggerWeeklyActivationGracePeriod: gracePeriod,
      }));
    }
  }, []);

  const updateChanges = useCallback(
    (state) => {
      const dto = {
        triggerWeeklyActivationGracePeriod:
          state.triggerWeeklyActivationGracePeriod,
        triggerWeeklyChecks: state.triggerWeeklyChecks || true,
      };
      updateConfig(terminalDevice.terminal, terminalDevice.id, dto);
    },
    [terminalDevice.id, terminalDevice.terminal, updateConfig]
  );

  const handleBlur = useCallback(() => {
    if (
      terminalDevice &&
      isValidGracePeriod(state.triggerWeeklyActivationGracePeriod)
    ) {
      updateChanges(state);
    }
  }, [terminalDevice, isValidGracePeriod, state, updateChanges]);

  const onActivedChange = useCallback(() => {
    const nextState = {
      ...state,
      triggerWeeklyChecks: !state.triggerWeeklyChecks,
    };
    setState(nextState);

    if (isValidGracePeriod(nextState.triggerWeeklyActivationGracePeriod)) {
      updateChanges(nextState);
    }
  }, [isValidGracePeriod, state, updateChanges]);

  return (
    <>
      <div className="toggleVoltage">
        <div className="childCardDevices2">
          <div className="Title_Descripction">
            Control de ejecución de programas:
          </div>
        </div>
        <div className="TogglewithInfo">
          <div className="DetailsToggle">OFF</div>
          <Toggle
            id="cheese-status"
            checked={state.triggerWeeklyChecks || false}
            icons={false}
            onChange={onActivedChange}
            className="toggle"
          />
          <div className="DetailsToggle">ON</div>
        </div>
      </div>

      {state.triggerWeeklyChecks && (
        <>
          <div className="Title_Descripction">
            Márgen de tiempo (segundos) para saltar alarma de un programa:
          </div>
          <Input
            type="number"
            placeholder="Segundos"
            name="trigger-config-grace-period"
            id="trigger-config-grace-period"
            className="InputForm descriptionInput"
            onChange={(e) => setGracePeriod(e.target.value)}
            onBlur={handleBlur}
            value={state.triggerWeeklyActivationGracePeriod || minValue}
            invalid={
              !isValidGracePeriod(state.triggerWeeklyActivationGracePeriod)
            }
          />
        </>
      )}
    </>
  );
};
