import React, { useState, useEffect, useCallback } from "react";
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Label } from 'recharts';
import { SliderC } from "../../../../components/Airframe/Slider/Slider";
import "./cardConfigPostigel.sass";


import {
  BrowserView,
  MobileView,
} from "react-device-detect";




export const CardConfpostigelEspecial = (props) => {
  const { title, id, min, max, value, icon, saveConfig,data } = props;

  const data02 = [
    { value: 10 },

  ];

  const maxSlider = 100;
  const WarningMessage = "Error";

  ;
  const [valueSlider, setvalueSlider] = useState(value);





  const ChangeSlider = useCallback((value) => {
    setvalueSlider(value);
    const timeInSeconds = parseFloat(((value / maxSlider) * max).toFixed(1));
    saveConfig(id, timeInSeconds);
  }, [saveConfig, id, maxSlider, max]);



//Revisar el if
  useEffect(() => {
    console.log(value);
    //if(value!==0){
      const sliderValue = value * maxSlider / max;
      setvalueSlider(sliderValue);
    //}
  }, [value])



  const getfilterSeconds = useCallback(() => {
  
    const number = ((valueSlider / maxSlider) * max).toFixed(1);
    let check0final = number % 1;
    if (check0final === 0) {
      return parseInt(number).toFixed(0) + "s";
    }
    else {
      return number + "s";
    }
  }, [valueSlider, maxSlider, max,props]);


  return (
    <>
      <div className="cardPostigelConfespecial">

        <div className="titleform titleEspecialpostigel">
          {/*  <div className="icoConfPostigel">
          </div>*/}
          <div className="headCard">
            <div className="textcardpostigel"> {title}:</div>
            <div>{icon}</div>
          </div>
        </div>

        <MobileView className="rechartConfig">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={30} height={28}>

              {/* Circle Background */}

              <Pie data={data02}
                startAngle={360}
                endAngle={0}

                //Position:
                cx={"50%"}
                cy={"50%"}
                //Size:
                innerRadius={18}
                outerRadius={28}
                //Color:
                fill="#dee2e6"
                label={false}
                isAnimationActive={false}
              //Direction:

              //Name in middle:
              />

              {/* Circle Active value */}

              <Pie data={data02}
                startAngle={valueSlider * 3.6}
                endAngle={0}

                isAnimationActive={false}
                //Position:
                cx={"50%"}
                cy={"50%"}
                //Size:
                innerRadius={18}
                outerRadius={28}
                //Color:
                fill="#4180FF"
                label={false}
              //Direction:

              //Name in middle:
              >
                {/* Si los segundos acaban en 0 no muestre decimales: */}
                <Label
                  value={getfilterSeconds()}
                  position="centerBottom" className='label-top' fontSize='27px'
                /></Pie>

            </PieChart>
          </ResponsiveContainer>
        </MobileView>
        <BrowserView className="rechartConfig">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={60} height={60}>

              {/* Circle Background */}

              <Pie data={data02}
                startAngle={450}
                endAngle={90}

                //Position:
                cx={"50%"}
                cy={"50%"}
                //Size:
                innerRadius={35}
                outerRadius={50}
                //Color:
                fill="#dee2e6"
                label={false}
                isAnimationActive={false}
              //Direction:

              //Name in middle:
              />

              {/* Circle Active value */}

              <Pie data={data02}
                //startAngle={450}
                //endAngle={90}
                //startAngle={-valueSlider*3.6} 
                //endAngle={0}

                startAngle={(-valueSlider * 3.6) + 90}
                endAngle={90}


                isAnimationActive={false}
                //Position:
                cx={"50%"}
                cy={"50%"}
                //Size:
                innerRadius={35}
                outerRadius={50}
                //Color:
                fill="#4180FF"
                label={false}
              //Direction:

              //Name in middle:
              >
                {/* Si los segundos acaban en 0 no muestre decimales: */}
                <Label
                  //  value={((valueSlider / maxSlider) * max).toFixed(1) ? ((valueSlider / maxSlider) * max).toFixed(0) + "s" : ((valueSlider / maxSlider) * max).toFixed(0) + "s"}
                  value={getfilterSeconds()}
                  position="centerBottom" className='label-top' fontSize='27px'
                /></Pie>

            </PieChart>
          </ResponsiveContainer>
        </BrowserView>





        <SliderC
          size="s"
          onChange={ChangeSlider}
          value={valueSlider}
          max={maxSlider}
          enabled={true}
          WarningMessage={WarningMessage}
          min={0}
        />
        <div className="valuesSliderPostigel">
          <div className="textcardpostigel2">

            {0 + "s"}
            {/*  {getfilterSeconds()}*/}
          </div>

          <div className="textcardpostigel2">
            {max}s
</div>
        </div>


      </div>
    </>
  );
};
