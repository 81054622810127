import React, { useCallback, useState, useEffect } from "react";
import { Spinner } from "../../../../Airframe/Spinner/Spinner";
import "./Calibrate.sass";
import { CalibrateContent2 } from "./CalibrateContent2";
import { CalibrationPointsSelection } from "./CalibrationPointsSelection";
import { CalibrateProcessChecker } from "./CalibrateContent3";
import { usePublisher } from "../../../../../../context/publish-context";
import { projectsConstants } from "../../../../../../_constants/projects.constants";
import uuid from "uuid/v4";
import { MESSAGE_TYPE_ACTION } from "../../../../../../_constants/messageType.constants";
import { TsmCalibrateSensorMQTT } from "../../../../MQTT/tsm/TsmCalibrateSensorMQTT";
import { TsmSensorsListener } from "../TsmSensorsListener";
import { CalibrationWithPoints } from "./CalibrationWithPoints";

export const Calibrate = (props) => {
  const { terminalDevice, sensor, closePopup } = props;

  const [optionChoose, setoptionChoose] = useState(undefined);
  

  const CALIBRATION_STATES = {
    IDLE: "IDLE",
    NOTIFIYING: "NOTIFIYING",
    NOTIFIED_WAITING_TO_STABILIZE: "NOTIFIED_WAITING_TO_STABLISH",
    CALIBRATION_DONE: "CALIBRATION_DONE",
    CALIBRATION_ERROR: "CALIBRATION_ERROR",
    CALIBRATION_NOTIFYING_ERROR: "CALIBRATION_NOTIFYING_ERROR",
  };
  const calibrationInitState = {
    state: CALIBRATION_STATES.IDLE,
    lastStateAt: undefined,
    notified: false,
  };

  const [calibrationState, setCalibrationState] = useState(
    calibrationInitState
  );

  const processSensorState = useCallback(
    (sensor) => {
      if (calibrationState.notified) {
        let state = "UNKNOWN";
        switch (sensor?.status) {
          case projectsConstants.global.sensors.states.idle:
          case projectsConstants.global.sensors.states.measuring:
            if (
              sensor?.error === projectsConstants.global.sensors.errors.none
            ) {
              state = CALIBRATION_STATES.CALIBRATION_DONE;
            } else {
              state = CALIBRATION_STATES.CALIBRATION_ERROR;
            }
            break;
          case projectsConstants.global.sensors.states.measuring_not_stable:
          case projectsConstants.global.sensors.states.calibrating:
            state = CALIBRATION_STATES.NOTIFIED_WAITING_TO_STABILIZE;
            break;
          default:
            break;
        }

        setCalibrationState((prevState) => {
          return {
            ...prevState,
            lastStateAt: new Date(),
            state,
          };
        });
      }
    },
    [sensor, CALIBRATION_STATES, calibrationState]
  );

  useEffect(() => {
    processSensorState(sensor);
  }, [sensor?.status, sensor?.lastValueAt]);

  
  /*const typesCalibrationDescription = {
    high: "Descripción Calibrado alto",
    medium: "Descripción Calibrado medio",
    low: "Descripción Calibrado bajo",
  };*/

  const onMediumPointClick = useCallback((event) => {
    setoptionChoose(projectsConstants.master_outputs.tsm.points.two);
  }, []);

  const onLowPointClick = useCallback((event) => {
    setoptionChoose(projectsConstants.master_outputs.tsm.points.one);
  }, []);

  //Change by closePopup In Debug:
  const onHighPointClick = useCallback((event) => {
    setoptionChoose(projectsConstants.master_outputs.tsm.points.three);
  }, []);

  /*const onBufferValueConfirmClick = useCallback(
    (bufferValue) => {
      setstep(3);
      publishCalibrationMQTT(optionChoose, bufferValue);
    },
    [publishCalibrationMQTT, optionChoose]
  );*/

  

  
  const onUpdateStateReceived = useCallback(
    (sensorUpdated) => {
      if (sensorUpdated === sensor) {
        processSensorState(sensor);
      }
    },
    [sensor, processSensorState]
  );

  const onPointsSelected = useCallback(pointsSelected => {
    setoptionChoose(pointsSelected);
  }, []);

  const getContent = useCallback(() => {
    let points = undefined;
    if(optionChoose === projectsConstants.master_outputs.tsm.points.one){
      points = [4];
    }
    if(optionChoose === projectsConstants.master_outputs.tsm.points.two){
      points = [7];
    }
    if(optionChoose === projectsConstants.master_outputs.tsm.points.three){
      points = [10];
    }

    if(!points){
      return (
        <CalibrationPointsSelection onPointsSelected={onPointsSelected} />
      );
    }else{
      return <CalibrationWithPoints points={points} terminalDevice={terminalDevice} sensor={sensor}></CalibrationWithPoints>;
    }
  }, [optionChoose, onPointsSelected, terminalDevice, sensor]);

  return (
    <>
      
      <TsmSensorsListener
        terminalDevice={terminalDevice}
        onUpdateStateReceived={onUpdateStateReceived}
      />
      {getContent()}
      {/*{step === 1 && (
        <CalibrateContent1
          title={"Calibrar PH"}
          body={contentstep1}
          actionMedium={onMediumPointClick}
          actionLow={onLowPointClick}
          actionHigh={onHighPointClick}
          step={step}
        />
      )}
      {step === 2 && (
        <>
          <CalibrateContent2
            optionChoose={optionChoose}
            step={step}
            action={onBufferValueConfirmClick}
            contentstep={contentstep2}
          />
        </>
      )}
      {step === 3 && (
        <CalibrateProcessChecker
          optionChoose={optionChoose}
          step={step}
          action={closePopup}
          contentstep={getCalibrationStateContent()}
        />
      )}
      */}
    </>
  );
};
