export function getDateTimeFormatted(date){
  const hours = getTwoDigit(date?.getHours())
  const minutes = getTwoDigit(date?.getMinutes())
  const seconds = getTwoDigit(date?.getSeconds())
  return `${hours}:${minutes}:${seconds}`;
};

export function getTwoDigit(number) {
return number < 10 ? `0${number}` : `${number}`;
}

export function getDayOfYearFormatted(date){
const year = getTwoDigit(date?.getFullYear());
const month = getTwoDigit(date?.getMonth() + 1);
const day = getTwoDigit(date?.getDate());
return `${year}-${month}-${day}`;
}

export function getFullDateTimeFormatted(date){
return `${date?.toLocaleDateString()} ${date?.toLocaleTimeString()}`
}

export function getHoursMinuteTimeFormatted(date){
const hours = getTwoDigit(date?.getHours())
const minutes = getTwoDigit(date?.getMinutes())
return `${hours}:${minutes}`;
}

export function getDayAndMonthDateFormatted(date) {
const month = getTwoDigit(date?.getMonth() + 1);
const day = getTwoDigit(date?.getDate());
return `${day}-${month}`;
}