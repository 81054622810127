import React, { useEffect, useState, useMemo } from "react";
import { zoneService } from "./../../../_services/zone.service";
import { TecoSidebar } from "./../../TecoSidebar/TecoSidebar";
import { TecoNavbar } from "./../../TecoNavbar/TecoNavbar";
import TecoLayout from "./../../Layout/TecoLayout";
import { MapFile } from "../../dashboards/IoTMapZone";
import { Spinner } from "./../../components/Airframe/Spinner/Spinner";
import { DangerComponent } from "../../DangerComponent/DangerComponent";

export const Page1 = (props) => {
  const states = {
    IDLE: "IDLE",
    LOADING: "LOADING",
    LOADED: "LOADED",
    ERROR: "ERROR",
  };
  const initState = useMemo(() => {
    return {
      state: states.IDLE,
      zones: [],
    };
  }, [states.IDLE]);
  const [state, setState] = useState(initState);

  useEffect(() => {
    if (state.state === states.IDLE) {
      setState((prev) => {
        return { ...prev, state: states.LOADING };
      });
      zoneService.getAllZones().then(
        (tmpZones) => {
          setState((prev) => {
            return { ...prev, state: states.LOADED, zones: tmpZones || [] };
          });
        },
        (error) => {
          setState((prev) => {
            return { ...prev, state: states.ERROR, zones: [] };
          });
        }
      );
    }
  }, [state.state, states.ERROR, states.IDLE, states.LOADED, states.LOADING]);

  return (
      <TecoLayout
        routedNavBar={() => <TecoNavbar NavMenu={"Page1"} />}
        routedSideBar={() => <TecoSidebar />}
        key="menu"
      >
        <div className="ContentMap">
          {(state.state === states.LOADING || state.state === states.IDLE) && (
            <div className="LoadSpinner">
              <Spinner size="50px"></Spinner>
            </div>
          )}
          {state.state === states.LOADED && (
            <MapFile zones={state.zones} />
          )}
          {state.state === states.ERROR && (
            <DangerComponent message="Error cargando las zonas."></DangerComponent>
          )}
        </div>
      </TecoLayout>

  );
};
