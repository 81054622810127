import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { DropdownC } from "./../../../Airframe/Dropdown/Dropdown";
import { projectsConstants } from "./../../../../../_constants/projects.constants";
import { usePublisher } from "./../../../../../context/publish-context";
import { MESSAGE_TYPE_ACTION } from "./../../../../../_constants/messageType.constants";
import { SetTriggersMQTT } from "./../../../MQTT/triggers/SetTriggersMQTT";
import { DeleteTriggersMQTT } from "./../../../MQTT/triggers/DeleteTriggersMQTT";
import "./triggers.sass";
import { Spinner } from "../../../Airframe/Spinner/Spinner";
import { Notification } from "../../../Airframe/Notification/components/Notification";
import { states as TerminalDeviceSensorTriggerCardStates } from "./TerminalDeviceSensorTriggerCard";
import { PopUpC } from "../../../Airframe/PopUp/PopUp";
import { ReactComponent as QuestionSvg } from "./../../../../../images/ImagesTerminalsDevice/question.svg";
import { TerminalDeviceSensorTriggerConfiguratorHelp } from "./TerminalDeviceSensorTriggerConfiguratorHelp";
import { useTriggers } from "../../../../../_services/triggers.service";
import { TerminalDeviceSensorTriggerCardsView } from "./TerminalDeviceSensorTriggerCardsView";
import { TerminalDeviceSensorTriggerForm } from "./TerminalDeviceSensorTriggerForm";
import { isTerminalDeviceValidForReportTriggers } from "./TerminalDeviceTriggerChecker";

export const TriggerConfigurationTypes = {
  SENSOR: "SENSOR",
  SENSOR_ALARM: "SENSOR_ALARM",
  BUTTON: "BUTTON",
};

export const TriggerConfigurationOperators = {
  greater: "GT",
  less: "LT",
  simple_smaller_than: "SIMPLE_SMALLER_THAN",
  simple_greater_than: "SIMPLE_GREATER_THAN",
  falling_flank: "FALLING_FLANK",
};

export const TerminalDeviceSensorTriggerConfigurator = (props) => {
  const {
    terminalDevice,
    selectedSensor,
    terminalDevices,
    autofocus,
    onSensorsChanged,
    trigger,
    triggerConfigurationType,
    allowOutputFromAnyDeviceOnTerminal,
    title,
    greaterOperator,
    smallerOperator,
    allowAdditionalActions,
  } = props;

  const states = {
    IDLE: "IDLE",
    CREATING: "CREATING",
    CREATED: "CREATED",
    UPDATING: "UPDATING",
    UPDATED: "UPDATED",
    DELETING: "DELETING",
    DELETED: "DELETED",
    DELETE_ASK_CONFIRMATION: "DELETE_ASK_CONFIRMATION",

    ERROR_CREATE: "ERROR_CREATE",
    ERROR_UPDATE: "ERROR_UPDATE",
    ERROR_DELETE: "ERROR_DELETE",
    NOT_FOUND: "NOT_FOUND",
    INCOMPLETE_DATA: "INCOMPLETE_DATA",
  };

  const initState = useMemo(
    () => ({
      state: states.IDLE,
      notifications: [],
      showHelp: false,
    }),
    [states.IDLE]
  );

  const getTitle = useCallback(() => {
    return title || "consigna";
  }, [title]);

  const getGreaterOperator = useCallback(() => {
    return greaterOperator || TriggerConfigurationOperators.greater;
  }, [greaterOperator]);

  const getSmallerOperator = useCallback(() => {
    return smallerOperator || TriggerConfigurationOperators.less;
  }, [smallerOperator]);

  const initTriggerSensor = useMemo(() => {
    return {
      operator:
        triggerConfigurationType === TriggerConfigurationTypes.BUTTON
          ? TriggerConfigurationOperators.falling_flank
          : getGreaterOperator(),
      activations: [],
      type: TriggerConfigurationTypes.SENSOR,
      active: true,
      sensor: {
        terminalDevice: selectedSensor?.terminalDevice,
        sensorId: selectedSensor?.sensorId?.id,
        sensorIndex: selectedSensor?.sensorIndex,
      },
      mustBeReportedWhenExecuted: false,
      mustEnterInMaintenanceWhenExecuted: false,
    };
  }, [
    getGreaterOperator,
    selectedSensor?.sensorId?.id,
    selectedSensor?.sensorIndex,
    selectedSensor?.terminalDevice,
    triggerConfigurationType,
  ]);

  const triggerToDeleteRef = useRef(undefined);
  const [triggersSensorChoose, settriggersSensorChoose] = useState(
    undefined
  );
  const [terminalDeviceChoose, setterminalDeviceChoose] = useState(undefined);
  const [
    triggerTerminalDeviceOutputs,
    setTriggerTerminalDeviceOutputs,
  ] = useState(0);
  const [state, setState] = useState(initState);
  const { publish } = usePublisher();
  const triggerService = useTriggers();

  const loadTriggerSensor = useCallback(
    (trigger) => {
      setState((prev) => {
        return { ...prev, state: states.IDLE };
      });
      trigger.cardState = TerminalDeviceSensorTriggerCardStates.EDIT;
      settriggersSensorChoose(trigger);
      if (!trigger?.deletedByUser && terminalDevices instanceof Array) {
        if (trigger?.activations?.length > 0) {
          const terminalDeviceOutputs =
            trigger.activations[0]?.terminalDeviceOutputs;
          const terminalDeviceChoosed = terminalDevices.filter(
            (td) => td.id === terminalDeviceOutputs
          )[0];
          if (terminalDeviceChoosed) {
            setterminalDeviceChoose(terminalDeviceChoosed);
            const activation = trigger.activations.filter(
              (triggerActivation) =>
                triggerActivation?.terminalDeviceOutputs ===
                terminalDeviceChoosed.id
            )[0];
            setTriggerTerminalDeviceOutputs(activation?.outputs);
          }
        } else {
          setterminalDeviceChoose(terminalDevice);
        }
      }
    },
    [states.IDLE, terminalDevices, terminalDevice]
  );

  useEffect(() => {
    // Función de limpieza que se ejecuta al desmontar el componente
    return () => {
      if(triggersSensorChoose){
        triggersSensorChoose.cardState = undefined
      }
    };
  }, []); //

  useEffect(() => {
    if (trigger) {
      loadTriggerSensor(trigger);
    }  else if(triggersSensorChoose === undefined){
      settriggersSensorChoose(undefined);
      setterminalDeviceChoose(undefined);
      setTriggerTerminalDeviceOutputs(0);
    }
  }, [
    loadTriggerSensor,
    selectedSensor,
    selectedSensor.triggers,
    terminalDevice,
    trigger,
    triggersSensorChoose
  ]);

  useEffect(() => {
    if (
      state.state === states.DELETED ||
      state.state === states.UPDATED ||
      state.state === states.CREATED
    ) {
      document
        .getElementById(`tds-${terminalDevice?.id}-triggers`)
        .scrollIntoView({ block: "center", inline: "nearest" });

      settriggersSensorChoose(undefined);
      setterminalDeviceChoose(undefined);
      setTriggerTerminalDeviceOutputs(0);
    }
  }, [
    state.state,
    states.CREATED,
    states.DELETED,
    states.UPDATED,
    terminalDevice?.id,
  ]);
  //#region ON METHODS

  const onInfoClick = useCallback(() => {
    setState((prev) => ({ ...prev, showHelp: true }));
  }, []);

  const onInfoClose = useCallback(() => {
    setState((prev) => ({ ...prev, showHelp: false }));
  }, []);

  const onSelectOutput = useCallback((terminalDeviceId, output) => {
    setTriggerTerminalDeviceOutputs((prevOutputs) => {
      let outputs = prevOutputs;
      if ((prevOutputs & (1 << (output - 1))) > 0) {
        // Desactivamos
        outputs &= ~(1 << (output - 1));
      } else {
        // Activamos
        outputs |= 1 << (output - 1);
      }
      return outputs;
    });
  }, []);

  const onSelectTerminalDevice = useCallback(
    (event) => {
      const terminalDeviceChoosed = event.value;

      setState((state) => {
        return { ...state, state: states.IDLE };
      });

      setTriggerTerminalDeviceOutputs(0);
      setterminalDeviceChoose(terminalDeviceChoosed);
    },
    [states.IDLE]
  );

  const onChangeInput = useCallback(
    (value) => {
      setState((state) => {
        return { ...state, state: states.IDLE };
      });
      settriggersSensorChoose((trigger) => {
        return { ...trigger, setpoint: parseFloat(value) };
      });
    },
    [states.IDLE]
  );

  const onChangeCondition = useCallback(
    (event) => {
      settriggersSensorChoose((trigger) => {
        const operator =
          trigger?.operator === getGreaterOperator()
            ? getSmallerOperator()
            : getGreaterOperator();
        return { ...trigger, operator };
      });
    },
    [getGreaterOperator, getSmallerOperator]
  );

  const onProportionalBandChange = useCallback(
    (event) => {
      event.persist();
      setState((state) => {
        return { ...state, state: states.IDLE };
      });
      settriggersSensorChoose((trigger) => {
        return { ...trigger, proportionalBand: parseFloat(event.target.value) };
      });
    },
    [states.IDLE]
  );

  const onReadPeriodChange = useCallback(
    (event) => {
      event.persist();
      setState((state) => {
        return { ...state, state: states.IDLE };
      });
      settriggersSensorChoose((trigger) => {
        return { ...trigger, readPeriod: parseInt(event.target.value) };
      });
    },
    [states.IDLE]
  );

  const onDosificationPeriodChange = useCallback(
    (event) => {
      event.persist();
      setState((state) => {
        return { ...state, state: states.IDLE };
      });
      settriggersSensorChoose((trigger) => {
        return { ...trigger, dosificationPeriod: parseInt(event.target.value) };
      });
    },
    [states.IDLE]
  );

  const onActivationTimeChange = useCallback(
    (event) => {
      event.persist();
      setState((state) => {
        return { ...state, state: states.IDLE };
      });
      settriggersSensorChoose((trigger) => {
        return {
          ...trigger,
          activations: [{ unitsLimit: parseInt(event.target.value) * 1000 }],
        };
      });
    },
    [states.IDLE]
  );

  const onTriggerUpdated = useCallback(
    (trigger, callSuper) => {
      if (!selectedSensor?.triggers instanceof Array) {
        selectedSensor.triggers = [trigger];
      } else {
        // Comprobamos si esta presente
        if (
          selectedSensor.triggers.filter(
            (sourceTrigger) => sourceTrigger?.id === trigger?.id
          ).length > 0
        ) {
          selectedSensor.triggers = selectedSensor.triggers.map(
            (sourceTrigger) =>
              sourceTrigger?.id === trigger?.id ? trigger : sourceTrigger
          );
        } else {
          selectedSensor.triggers.push(trigger);
        }
      }
      if (callSuper === undefined || callSuper) {
        onSensorsChanged();
      }
    },
    [selectedSensor, onSensorsChanged]
  );

  const onTriggerDeleted = useCallback(
    (trigger) => {
      if (!(selectedSensor?.triggers instanceof Array)) {
        selectedSensor.triggers = [];
      } else {
        let indexFound = -1;
        selectedSensor.triggers
          .filter((sourceTrigger) => sourceTrigger?.id === trigger?.id)
          .forEach((sourceTrigger, index) => {
            indexFound = index;
            sourceTrigger.deletedByUser = true;
          });

        if (indexFound >= 0) {
          onSensorsChanged();
        }
      }
    },
    [onSensorsChanged, selectedSensor]
  );

  const onTerminalDeviceSensorTriggerCardEdit = useCallback(
    (triggerToEdit) => {
      if (selectedSensor?.triggers?.length > 0) {
        selectedSensor.triggers.forEach((trigger) => {
          if (trigger?.id === triggerToEdit?.id) {
            if (
              trigger.cardState === TerminalDeviceSensorTriggerCardStates.EDIT
            ) {
              trigger.cardState = TerminalDeviceSensorTriggerCardStates.IDLE;
              settriggersSensorChoose(undefined);
            } else {
              trigger.cardState = TerminalDeviceSensorTriggerCardStates.EDIT;
              loadTriggerSensor(trigger);
            }
          } else {
            trigger.cardState = TerminalDeviceSensorTriggerCardStates.IDLE;
          }
        });
      }
    },
    [loadTriggerSensor, selectedSensor?.triggers]
  );

  const onTerminalDeviceSensorTriggerAdd = useCallback(() => {
    if (selectedSensor?.triggers?.length > 0) {
      selectedSensor.triggers.forEach(
        (trigger) =>
          (trigger.cardState = TerminalDeviceSensorTriggerCardStates.IDLE)
      );
    }
    settriggersSensorChoose(initTriggerSensor);
    setterminalDeviceChoose(terminalDevice);
    setTriggerTerminalDeviceOutputs(0);
  }, [initTriggerSensor, selectedSensor.triggers, terminalDevice]);

  const onTriggerDelete = useCallback(
    (trigger) => {
      if (trigger) {
        triggerToDeleteRef.current = trigger;
        setState((prev) => {
          return { ...prev, state: states.DELETE_ASK_CONFIRMATION };
        });
      }
    },
    [states.DELETE_ASK_CONFIRMATION]
  );

  const onCancelDelete = useCallback(() => {
    setState((prev) => {
      return { ...prev, state: states.IDLE };
    });
  }, [states.IDLE]);

  const onBellClick = useCallback(() => {
    settriggersSensorChoose((current) => ({
      ...current,
      mustBeReportedWhenExecuted: !current?.mustBeReportedWhenExecuted,
    }));
  }, []);

  const onStopClick = useCallback(() => {
    settriggersSensorChoose((current) => ({
      ...current,
      mustEnterInMaintenanceWhenExecuted: !current?.mustEnterInMaintenanceWhenExecuted,
    }));
  }, []);

  const onAddUserClick = useCallback(() => {
    if (triggersSensorChoose?.mustBeReportedWhenExecuted) {
      if (!(triggersSensorChoose.receivers instanceof Array)) {
        triggersSensorChoose.receivers = [];
      }

      const voidToAdd = { id: undefined, email: undefined, phone: undefined };

      if (
        triggersSensorChoose.receivers.filter(
          (source) => source.email === undefined && source.phone === undefined
        ).length === 0
      ) {
        triggersSensorChoose.receivers.push(voidToAdd);
        settriggersSensorChoose({
          ...triggersSensorChoose,
          receivers: [...triggersSensorChoose.receivers],
        });
      }
    }
  }, [triggersSensorChoose]);

  const onReceiverDelete = useCallback(
    (receiver) => {
      if (triggersSensorChoose.receivers instanceof Array) {
        const index = triggersSensorChoose.receivers.indexOf(receiver);
        if (index > -1) {
          triggersSensorChoose.receivers.splice(index, 1); // 2nd parameter means remove one item only
          settriggersSensorChoose({
            ...triggersSensorChoose,
            receivers: [...triggersSensorChoose.receivers],
          });
        }
      }
    },
    [triggersSensorChoose]
  );
  const onReceiverChange = useCallback((receiver) => {
    settriggersSensorChoose((current) => ({
      ...current,
      receivers: [...current.receivers],
    }));
  }, []);
  //#endregion

  //#region GET METHODS
  const getDropDownTerminalDevices = useCallback(() => {
    let options = [];
    let selectedOption;
    const disabled = allowOutputFromAnyDeviceOnTerminal === false;

    if (
      (allowOutputFromAnyDeviceOnTerminal === undefined ||
        allowOutputFromAnyDeviceOnTerminal) &&
      terminalDevices instanceof Array
    ) {
      options = terminalDevices
        .filter(
          (td) =>
            td.device?.id === projectsConstants.global.devices.MMS ||
            td.device?.id === projectsConstants.global.devices.MMS_X5 ||
            td.device?.id === projectsConstants.global.devices.SIO
        )
        .map((td, i) => {
          const option = {
            value: td,
            label: td.description || td.id + "-" + td.device.id,
          };

          if (td?.id === terminalDeviceChoose?.id) {
            selectedOption = option;
          }

          return option;
        });
    } else {
      selectedOption = {
        value: terminalDevice,
        label:
          terminalDevice.description ||
          terminalDevice.id + "-" + terminalDevice.device.id,
      };
      options = [selectedOption];
    }

    return (
      <DropdownC
        options={options}
        onChange={onSelectTerminalDevice}
        placeholder={"Dispositivo..."}
        value={selectedOption}
        disabled={disabled}
        changestyles={true}
      />
    );
  }, [
    allowOutputFromAnyDeviceOnTerminal,
    terminalDevices,
    onSelectTerminalDevice,
    terminalDeviceChoose?.id,
    terminalDevice,
  ]);

  const getFilteredOutputs = useCallback(() => {
    let offset = 1,
      i = 0;
    const map = new Map();
    while (offset <= triggerTerminalDeviceOutputs) {
      if ((offset & triggerTerminalDeviceOutputs) > 0) {
        map.set(i + 1, true);
      }
      ++i;
      offset = 1 << i;
    }
    return map;
  }, [triggerTerminalDeviceOutputs]);

  //#endregion

  //#region  MQTT
  const publishDeleteTriggers = useCallback(
    (target_id) => {
      publish(
        projectsConstants.master_outputs.actions.common.deletetriggers,
        {
          type: MESSAGE_TYPE_ACTION,
          data: {
            target_id,
          },
        },
        projectsConstants.master_outputs.id
      );
    },
    [publish]
  );

  const publishSetupTriggers = useCallback(
    (target_id) => {
      publish(
        projectsConstants.master_outputs.actions.common.settriggers,
        {
          type: MESSAGE_TYPE_ACTION,
          data: {
            target_id,
          },
        },
        projectsConstants.master_outputs.id
      );
    },
    [publish]
  );

  const processSetTriggerIds = useCallback(
    (triggerIds, isDeleted) => {
      if (terminalDevice?.sensors instanceof Array) {
        let updated = false;
        terminalDevice.sensors
          .filter(
            (sensor) =>
              sensor?.triggers instanceof Array && sensor.triggers.length > 0
          )
          .flatMap((sensor) => {
            sensor.triggers.forEach(
              (t) => (t.sensorIndex = sensor.sensorIndex)
            );
            return sensor.triggers;
          })
          .filter(
            (trigger) =>
              triggerIds.filter(
                (receivedTriggerId) => receivedTriggerId === trigger?.id
              ).length > 0
          )
          .forEach((trigger) => {
            trigger.notified = true;
            trigger.deletedByUser = isDeleted;
            updated = true;

            onTriggerUpdated(trigger, false);

            setState((state) => {
              return {
                ...state,
                notifications: [
                  ...state.notifications,
                  `Se ha ${
                    isDeleted ? "eliminado" : "definido"
                  } la ${getTitle()} para el sensor analógico ${
                    trigger.sensorIndex + 1
                  }. Revise el estado de las salidas anteriores asociadas.`,
                ],
              };
            });
          });

        if (updated) {
          onSensorsChanged();
        }
      }
    },
    [getTitle, onSensorsChanged, onTriggerUpdated, terminalDevice.sensors]
  );

  const onSetTriggersMqttMessage = useCallback(
    (topic, message) => {
      //const terminalId = message?.data?.terminal_id;
      const success = message?.data?.success;
      const triggerIds = message?.data?.trigger_ids;
      const triggerIdsActive = message?.data?.trigger_ids_active;
      if (success && terminalDevice?.sensors instanceof Array) {
        if (triggerIds instanceof Array) {
          processSetTriggerIds(triggerIds, false);
        }
        if (triggerIdsActive instanceof Array) {
          processSetTriggerIds(triggerIdsActive, false);
        }
      }
    },
    [processSetTriggerIds, terminalDevice?.sensors]
  );

  const processDeleteTriggerIds = useCallback(
    (triggerIds, isActive) => {
      processSetTriggerIds(triggerIds, true);
    },
    [processSetTriggerIds]
  );

  const onDeleteTriggersMqttMessage = useCallback(
    (topic, message) => {
      const success = message?.data?.success;
      const triggerIds = message?.data?.trigger_ids;
      const triggerIdsActive = message?.data?.trigger_ids_active;
      const triggerIdsNotFound = message?.data?.trigger_ids_not_found;
      if (success && terminalDevice.sensors instanceof Array) {
        if (triggerIds instanceof Array) {
          processDeleteTriggerIds(triggerIds);
        }
        if (triggerIdsActive instanceof Array) {
          processDeleteTriggerIds(triggerIdsActive);
        }
        if (triggerIdsNotFound instanceof Array) {
          processDeleteTriggerIds(triggerIdsNotFound);
        }
      }
    },
    [terminalDevice.sensors, processDeleteTriggerIds]
  );

  const voidMqttErrorMethod = useCallback((topic, message) => {}, []);
  //#endregion

  //#region  HTTP

  const getDto = useCallback(() => {
    const readPeriod =
      triggerConfigurationType === TriggerConfigurationTypes.SENSOR_ALARM
        ? 5
        : triggersSensorChoose.readPeriod;

    let activations = [];

    if(!triggersSensorChoose?.mustEnterInMaintenanceWhenExecuted){

      const unitsLimit = triggersSensorChoose?.activations ? triggersSensorChoose?.activations[0]?.unitsLimit : 0;
      if(
        ((triggersSensorChoose?.operator === TriggerConfigurationOperators.simple_greater_than 
          || triggersSensorChoose?.operator === TriggerConfigurationOperators.simple_smaller_than
          || triggersSensorChoose?.operator === TriggerConfigurationOperators.falling_flank) && !isNaN(unitsLimit) && unitsLimit > 0)
        || (triggersSensorChoose?.operator === TriggerConfigurationOperators.greater
          || triggersSensorChoose?.operator === TriggerConfigurationOperators.less)){
            activations.push({
              id: 1,
              terminalDeviceOutputs: terminalDeviceChoose?.id,
              outputs: triggerTerminalDeviceOutputs,
              pwm: 100,
              unitControl: "TIME",
              source: "SENSOR",
              sourceId: 0,
              loop: false,
              unitsLimit
            })
          }
    }

    return {
      ...triggersSensorChoose,
      readPeriod,
      sensor: {
        terminalDevice: selectedSensor?.terminalDevice,
        sensorId: selectedSensor?.sensorId?.id,
        sensorIndex: selectedSensor?.sensorIndex,
      },
      activations,
    };
  }, [
    selectedSensor?.sensorId?.id,
    selectedSensor?.sensorIndex,
    selectedSensor?.terminalDevice,
    terminalDeviceChoose?.id,
    triggerConfigurationType,
    triggerTerminalDeviceOutputs,
    triggersSensorChoose,
  ]);

  const isValidAlarmDosificationData = useCallback(() => {
    if (triggersSensorChoose?.activations instanceof Array
        && triggersSensorChoose?.activations[0]?.unitsLimit > 0) {

      return terminalDeviceChoose?.id > 0 && triggerTerminalDeviceOutputs > 0;
    }

    return true;
  }, [terminalDeviceChoose?.id, triggerTerminalDeviceOutputs, triggersSensorChoose?.activations]);

  const isValidAlarmReportData = useCallback(() => {
    if (triggersSensorChoose?.mustBeReportedWhenExecuted) {
      return triggersSensorChoose?.receivers?.length > 0;
    }

    return true;
  }, [triggersSensorChoose]);

  const validateTriggerData = useCallback(() => {
    if (terminalDevice) {
      switch (triggerConfigurationType) {
        case undefined:
        case TriggerConfigurationTypes.SENSOR:
          return (
            terminalDeviceChoose?.id > 0 &&
            triggerTerminalDeviceOutputs > 0 &&
            !isNaN(triggersSensorChoose?.setpoint) &&
            !isNaN(triggersSensorChoose?.proportionalBand) &&
            !isNaN(triggersSensorChoose?.readPeriod) &&
            triggersSensorChoose?.readPeriod >= 1 &&
            !isNaN(triggersSensorChoose?.dosificationPeriod) &&
            triggersSensorChoose?.dosificationPeriod >= 1
          );

        case TriggerConfigurationTypes.BUTTON:
          return (

            isValidAlarmReportData() &&
            isValidAlarmDosificationData() &&
            (triggersSensorChoose?.mustEnterInMaintenanceWhenExecuted ||
              triggersSensorChoose?.activations[0]?.unitsLimit > 999 ||
              triggersSensorChoose?.mustBeReportedWhenExecuted)
          );

        case TriggerConfigurationTypes.SENSOR_ALARM:
          return (
            !isNaN(triggersSensorChoose?.setpoint) &&
            !isNaN(triggersSensorChoose?.proportionalBand) &&
            !isNaN(triggersSensorChoose?.dosificationPeriod) &&
            triggersSensorChoose?.dosificationPeriod >= 1 &&
            isValidAlarmReportData() &&
            isValidAlarmDosificationData() &&
            (triggersSensorChoose?.mustEnterInMaintenanceWhenExecuted ||
              triggersSensorChoose?.activations[0]?.unitsLimit > 0 ||
              triggersSensorChoose?.mustBeReportedWhenExecuted)
          );
        default:
          break;
      }
    }

    return false;
  }, [
    isValidAlarmDosificationData,
    isValidAlarmReportData,
    terminalDevice,
    terminalDeviceChoose?.id,
    triggerConfigurationType,
    triggerTerminalDeviceOutputs,
    triggersSensorChoose?.activations,
    triggersSensorChoose?.dosificationPeriod,
    triggersSensorChoose?.mustBeReportedWhenExecuted,
    triggersSensorChoose?.mustEnterInMaintenanceWhenExecuted,
    triggersSensorChoose?.proportionalBand,
    triggersSensorChoose?.readPeriod,
    triggersSensorChoose?.receivers?.length,
    triggersSensorChoose?.setpoint,
  ]);

  const saveTrigger = useCallback(
    (event) => {
      if (validateTriggerData()) {
        if (triggersSensorChoose?.id > 0) {
          setState((state) => {
            return { ...state, state: states.UPDATING };
          });
          // ACTUALIZAMOS
          //sensorService
          triggerService
            .update(
              //terminalId, terminalDeviceId, triggerId, trigger
              terminalDevice.terminal,
              terminalDevice.id,
              triggersSensorChoose.id,
              getDto()
            )
            .then(
              (trigger) => {
                setState((state) => {
                  return { ...state, state: states.UPDATED };
                });
                onTriggerUpdated(trigger);
                publishSetupTriggers(terminalDevice.id);
              },
              (err) => {
                setState((state) => {
                  return { ...state, state: states.ERROR_UPDATE };
                });
              }
            );
        } else {
          // CREAMOS
          setState((state) => {
            return { ...state, state: states.CREATING };
          });
          //sensorService
          triggerService
            .save(
              // (terminalId, terminalDeviceId, trigger)
              terminalDevice.terminal,
              terminalDevice.id,
              getDto()
            )
            .then(
              (trigger) => {
                setState((state) => {
                  return { ...state, state: states.CREATED };
                });
                onTriggerUpdated(trigger);
                publishSetupTriggers(terminalDevice.id);
              },
              (err) => {
                setState((state) => {
                  return { ...state, state: states.ERROR_CREATE };
                });
              }
            );
        }
      } else {
        setState((state) => {
          return { ...state, state: states.INCOMPLETE_DATA };
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      terminalDevice,
      terminalDeviceChoose?.id,
      triggersSensorChoose,
      selectedSensor?.sensorId?.id,
      selectedSensor?.sensorIndex,
      states.UPDATING,
      states.UPDATED,
      states.ERROR_UPDATE,
      states.CREATING,
      states.CREATED,
      states.ERROR_CREATE,
      states.INCOMPLETE_DATA,
      onTriggerUpdated,
      publishSetupTriggers,
      onTriggerUpdated,
      getDto,
      validateTriggerData,
    ]
  );

  const deleteTrigger = useCallback(
    (trigger) => {
      if (trigger?.id > 0) {
        setState((state) => {
          return { ...state, state: states.DELETING };
        });
        //sensorService
        triggerService
          .deleteTrigger(
            //(terminalId, terminalDeviceId, triggerId)
            terminalDevice.terminal,
            terminalDevice.id,
            trigger.id
          )
          .then(
            (trigger) => {
              onTriggerDeleted(trigger);
              publishDeleteTriggers(terminalDevice.id);
              setState((state) => {
                return { ...state, state: states.DELETED };
              });
            },

            (error) => {
              setState((state) => {
                return { ...state, state: states.ERROR_DELETE };
              });
            }
          );
      } else {
        setState((state) => {
          return { ...state, state: states.NOT_FOUND };
        });
      }
    },
    [
      triggerService,
      terminalDevice.terminal,
      terminalDevice.id,
      states.DELETING,
      states.DELETED,
      states.ERROR_DELETE,
      states.NOT_FOUND,
      onTriggerDeleted,
      publishDeleteTriggers,
    ]
  );
  //#endregion

  //#region CONFIRMATION DELETE
  const onConfirmDelete = useCallback(() => {
    deleteTrigger(triggerToDeleteRef.current);
  }, [deleteTrigger]);
  //#endregion

  //#region COMPONENTS
  const getNotificationsComponents = useCallback(() => {
    let component = <></>;
    if (state.notifications) {
      const notifications = state.notifications.map((message, index) => {
        return (
          <Notification
            key={index}
            title={""}
            message={message}
            type={"info"}
            time={10000}
          ></Notification>
        );
      });
      component = <div>{notifications}</div>;
      state.notifications = [];
    }
    return component;
  }, [state]);
  //#endregion

  return (
    <div
      className={`mainContainer ${
        triggerConfigurationType === TriggerConfigurationTypes.SENSOR_ALARM
          ? "alarms"
          : ""
      }`}
    >
      <SetTriggersMQTT
        processMsg={onSetTriggersMqttMessage}
        processErrorMsg={voidMqttErrorMethod}
      />
      <DeleteTriggersMQTT
        processMsg={onDeleteTriggersMqttMessage}
        processErrorMsg={voidMqttErrorMethod}
      />

      <PopUpC
        activate={state.showHelp}
        deactivatepopup={onInfoClose}
        content={<TerminalDeviceSensorTriggerConfiguratorHelp />}
      />

      {state.state === states.DELETE_ASK_CONFIRMATION && (
        <PopUpC
          activate={true}
          deactivatepopup={onCancelDelete}
          content={
            <div className="contentpopup">
              <div className="textapp">
                Estas seguro de eliminar la {getTitle()}?
              </div>{" "}
              <div
                className="ButtonConfirmPopup"
                onClick={(e) => onConfirmDelete()}
              >
                Confirmar
              </div>
            </div>
          }
        />
      )}

      {getNotificationsComponents()}

      <div id={`tds-${terminalDevice?.id}-triggers`}>
        <TerminalDeviceSensorTriggerCardsView
          triggers={selectedSensor.triggers}
          terminalDevices={terminalDevices}
          title={getTitle()}
          greaterOperator={getGreaterOperator()}
          smallerOperator={getSmallerOperator()}
          onEdit={onTerminalDeviceSensorTriggerCardEdit}
          onDelete={onTriggerDelete}
          triggerConfigurationType={triggerConfigurationType}
        />
      </div>

      <div className="ButtonTSM">
        <div style={{ display: "flex" }}>
          <div
            className="ButtonOffAll Secondary"
            onClick={(e) => onTerminalDeviceSensorTriggerAdd()}
          >
            Nueva {getTitle()}
          </div>
          <div className="icoOutputconf">
            <div className="icoOutputconf2">
              <QuestionSvg
                className="ButtonProgram Info"
                onClick={(e) => onInfoClick()}
              />
            </div>
          </div>
        </div>
      </div>

      {triggersSensorChoose && (
        <>
          {triggersSensorChoose.notified === false &&
            !triggersSensorChoose.deletedByUser && (
              <div className="head_TsmConfigure">
                <div>Sincronizando con el dispositivo...</div>
                <Spinner></Spinner>
              </div>
            )}

          <TerminalDeviceSensorTriggerForm
            trigger={triggersSensorChoose}
            terminalDeviceSensor={selectedSensor}
            title={getTitle()}
            focusOnSetpoint={autofocus}
            greaterOperator={getGreaterOperator()}
            smallerOperator={getSmallerOperator()}
            triggerConfigurationType={triggerConfigurationType}
            allowAdditionalActions={
              allowAdditionalActions &&
              isTerminalDeviceValidForReportTriggers(terminalDevice)
            }
            onSetpointChange={onChangeInput}
            onOperatorChange={onChangeCondition}
            onProportionalBandChange={onProportionalBandChange}
            onReadPeriodChange={onReadPeriodChange}
            onDosificationPeriodChange={onDosificationPeriodChange}
            onActivationTimeChange={onActivationTimeChange}
            onSelectOutput={onSelectOutput}
            onBellClick={onBellClick}
            onStopClick={onStopClick}
            onAddUserClick={onAddUserClick}
            onReceiverDelete={onReceiverDelete}
            onReceiverChange={onReceiverChange}
            dropDownTerminalDevices={getDropDownTerminalDevices()}
            triggerTerminalDeviceOutputs={getFilteredOutputs()}
            terminalDeviceOutputs={terminalDeviceChoose}
          />

          {state.state === states.ERROR_DELETE && (
            <div className="Errortsm">Error Borrando</div>
          )}
          {state.state === states.ERROR_CREATE && (
            <div className="Errortsm">Error Agregando</div>
          )}
          {state.state === states.ERROR_UPDATE && (
            <div className="Errortsm">Error Modificando</div>
          )}
          {state.state === states.NOT_FOUND && (
            <div className="Errortsm">No tienes ninguna</div>
          )}
          {state.state === states.INCOMPLETE_DATA && (
            <div className="Errortsm">Faltan datos</div>
          )}

          {state.state === states.CREATED && (
            <div className="messageTsm">{getTitle()} creada</div>
          )}
          {state.state === states.UPDATED && (
            <div className="messageTsm">{getTitle()} modificada</div>
          )}

          {triggersSensorChoose?.id > 0 &&
            !triggersSensorChoose?.deletedByUser && (
              <div className="Buttons">
                {state.state !== states.DELETING &&
                state.state !== states.UPDATING &&
                state.state !== states.CREATING ? (
                  <div className="ButtonOffAll" onClick={(e) => saveTrigger()}>
                    <>Definir {getTitle()}</>
                  </div>
                ) : (
                  <Spinner></Spinner>
                )}
                <div
                  className="ButtonOffAll Danger"
                  onClick={(e) => onTriggerDelete(triggersSensorChoose)}
                >
                  <>Eliminar {getTitle()}</>
                </div>
              </div>
            )}
          {!triggersSensorChoose?.id && (
            <div className="ButtonTSM">
              <div className="ButtonOffAll" onClick={(e) => saveTrigger()}>
                <>Definir {getTitle()}</>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
