import { authHeader } from "../_helpers";
import axios from "axios";
import { Output } from "../classes/TerminalDevice/Output/Output";
import { Authservice } from "./Authservice";

export const PostigelService = {
  postSendDataConfPostigel,
  getAll,
  getLast
};
function getLast(terminalId, terminalDeviceId) {
  const authToken = authHeader();

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken.Authorization
  };
  const requestOptions = {
    method: "GET",
    headers
  };

  return axios
    .get(`${process.env.REACT_APP_API_PATH}/postigel/terminals/${terminalId}/devices/${terminalDeviceId}/registers/last/`, requestOptions)
    .then(handleResponse)
    .then(tmpdataPostigel => {
      return tmpdataPostigel
    });

};

function getAll(terminalId, terminalDeviceId) {
  const authToken = authHeader();

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken.Authorization
  };
  const requestOptions = {
    method: "GET",
    headers
  };

  return axios
    .get(`${process.env.REACT_APP_API_PATH}/postigel/terminals/${terminalId}/devices/${terminalDeviceId}/registers/`, requestOptions)
    .then(handleResponse)
    .then(tmpdataPostigel => {
      return tmpdataPostigel
    });

};

function postSendDataConfPostigel(
  terminalId,
  terminalDeviceId,
  data
) {
  console.log(data);
  const authToken = authHeader();

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization,
    },
  };

  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/outputs/`,
      data,
      requestOptions
    )
    .then(handleResponse)
    .then((outputs) => {
      console.log(outputs);
      //return parseOutputs(outputs);
    });
}

function handleResponse(response) {
  var p = new Promise((resolve, reject) => {
    const data = response.data;
    if (response.status >= 400) {
      /*if (response.status === 401) {
          history(routesConstants.sign.OUT);
        }*/

      const error = response.statusText;
      reject(error);
    }

    resolve(data);
  });
  return p.then((data) => {
    return data;
  });
}

function parseOutputs(outputs) {
  if (outputs && outputs instanceof Array) {
    return outputs.map((output) => Output.parseOutputDtoFromObject(output));
  }
  return outputs;
}
