import React from "react"
import { projectsConstants } from "../../../_constants/projects.constants"
import { MqttMessageProcessor } from "./MqttMessageProcessor"

export const AdvancedActoutputMQTT = (props) => {
    return <MqttMessageProcessor
    topic={projectsConstants.master_outputs.actions.advanced_actoutput}
    processMsg={props.processMsg}
    processErrorMsg={props.processErrorMsg}
  ></MqttMessageProcessor>
}