import React, { useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import {
  GoogleMap,
  Marker,
  LoadScript
} from "@react-google-maps/api";
import { useApp } from "../../context/app-context";
import { Spinner } from "reactstrap";

export const MapFile = (props) => {
  const { setSelectedZone } = useApp();
  const { zones } = props;
  let history = useNavigate();

  const changeOpen = useCallback((zone) => {
    setSelectedZone(zone);
    history({
      pathname: `/zones/${zone.id}/`,
      state: {
        [props]: props,
      },
    });
  }, [history, props, setSelectedZone]);

  return (
      <LoadScript
        id="script-loader"
        googleMapsApiKey="AIzaSyA-6kqGbe3PizBki0WwF6WteRbxFgOKH6o"
        loadingElement={<Spinner size="50px"></Spinner>}
      >
          <GoogleMap
            zoom={7}
            center={{ lat: 38.932749, lng: -0.186515 }}
            id="IoT-map"
            mapContainerStyle={{
              height: "90vh",
              width: "100%",
              //margin: "auto",
            }}
            //onLoad={map => handleOnLoad(map,setMenu,tmpZones)}
          >
            {zones instanceof Array &&
              zones.map((zone, index) => (
                <div key={index}>
                  <Marker
                    //icon={}
                    key={`google-marker-${zone.id}`}
                    //label={<div className="makerTitle">hola</div>}
                    label={{
                      className: "makerTitle",
                      text: zone.name,
                      color: "#FFFFFF",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                    position={{
                      lat: zone.location_latitude,
                      lng: zone.location_longitude,
                    }}
                    onClick={(e) => changeOpen(zone)}
                  />
                </div>
              ))}
          </GoogleMap>
      </LoadScript>
  );
};
