import React, { useState, useEffect } from "react";
import { RemainingBar } from "../../../components/Airframe/RemainingBar/RemainingBar";
import { BarChart, XAxis, YAxis, Tooltip, ResponsiveContainer, Bar, CartesianGrid, Legend, AreaChart, Area } from 'recharts';
import "./Cardpostigel.sass";
import "./../../../components/TerminalDevices/TerminalDevices/Components/LiquidBarchart.sass"
import "./MultipleStatistics.sass";

export const MultipleStatistics = (props) => {
    //mapDataGroupByDate
    const { stadisticsActives, mapDataGroupByDate, dayselect } = props;
    const [dataDetectionsAndDesinfections, setdataDetectionsAndDesinfections] = useState([]);

    useEffect(() => {

        if (mapDataGroupByDate !== undefined && dayselect !== undefined) {
            if (mapDataGroupByDate.has(dayselect)) {
                setdataDetectionsAndDesinfections(mapDataGroupByDate.get(dayselect).data);
            } else {
                setdataDetectionsAndDesinfections([]);
            }
        }
    }, [props]);

    const data = [{
        "name": "6:00",
        "Líquido": 100,
        "Desinfecciones": 0,
        "Detecciones": 0,
        "temperatura": 25,
    },
    {
        "name": "08:00",

        "Líquido": 90,
        "Desinfecciones": 10,
        "Detecciones": 10,
        "temperatura": 25,

    },
    {
        "name": "10:00",

        "Líquido": 80,
        "Desinfecciones": 30,
        "Detecciones": 27,
        "temperatura": 25,

    },
    {
        "name": "12:00",

        "Líquido": 60,
        "Desinfecciones": 50,
        "Detecciones": 45,
        "temperatura": 25,

    },
    {
        "name": "14:00",

        "Líquido": 40,
        "Desinfecciones": 70,
        "Detecciones": 60,
        "temperatura": 20,

    },
    {
        "name": "16:00",

        "Líquido": 35,
        "Desinfecciones": 85,
        "Detecciones": 80,
        "temperatura": 25,

    },
    {
        "name": "18:00",

        "Líquido": 30,
        "Desinfecciones": 100,
        "Detecciones": 90,
        "temperatura": 25,

    }];
    return (
        <> {stadisticsActives.Liquido.active &&
            <ResponsiveContainer className="statisticsfather" width="100%" height="100%">
                <AreaChart width={730} height={250} data={data}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <defs>
                        {/* //Definimos los colores que queremos usar: si no usamos ninguno en ios se vera negro: */}
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="100%" stopColor="#75A3FF" stopOpacity={0.1} />

                        </linearGradient>

                    </defs>
                    <XAxis dataKey="name" />
                    <YAxis unit="%" orientation="right" />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Area type="monotone" dataKey="Líquido" stroke="#75A3FF" fillOpacity={1} fill="url(#colorPv)" />
                </AreaChart>

            </ResponsiveContainer>
        }


            {(stadisticsActives.Desinfecciones.active || stadisticsActives.Detecciones.active) &&
                <ResponsiveContainer className="statisticsfather" width="100%" height="100%">
                    <AreaChart width={730} height={250} data={dataDetectionsAndDesinfections}
                        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>

                        <defs>

                            <linearGradient id="orange" x1="0" y1="0" x2="0" y2="1">

                                <stop offset="100%" stopColor="#FF6600" stopOpacity={0.1} />
                            </linearGradient>
                            <linearGradient id="green" x1="0" y1="0" x2="0" y2="1">

                                <stop offset="100%" stopColor="#02A20A" stopOpacity={0.1} />
                            </linearGradient>
                        </defs>

                        <XAxis dataKey="name" />
                        <YAxis unit="" orientation="right" />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        {stadisticsActives.Desinfecciones.active && <Area type="monotone" dataKey="Desinfecciones" stroke="#02A20A" fillOpacity={1} fill="url(#green)" />}
                        {stadisticsActives.Detecciones.active && <Area type="monotone" dataKey="Detecciones" stroke="#FF6600" fillOpacity={1} fill="url(#orange)" />}
                    </AreaChart>

                </ResponsiveContainer>
            }

        </>
    );





};
