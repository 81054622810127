export const checkValidProgramDatetime = function (
  initHour,
  initMinute,
  subprograms
) {
  if (
    initHour != undefined &&
    initMinute != undefined &&
    subprograms instanceof Array
  ) {
    const initDate = new Date(2021, 1, 1, initHour, initMinute);
    let date = new Date(initDate.getTime());
    subprograms.forEach(subprogram => {
        if (subprogram?.activeTime) {
            const [hour, minute] = subprogram.activeTime.split(":");
            date = date.addHours(hour);
            date = date.addMinutes(minute);
          }
    })
    return lessThan24hBetween(initDate, date);
  }
  return true;
};

const sameDay = function(d1, d2){
    return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();
}

const lessThan24hBetween = function(d1, d2){
    const d1Time = d1.getTime();
    const d2Time = d2.getTime();

    const diff = Math.abs(d1Time - d2Time);
    const hoursDiff = diff / 1000 / 60 / 60;
    return hoursDiff <= 24;
}

Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

Date.prototype.addMinutes = function (m) {
  this.setTime(this.getTime() + m * 60 * 1000);
  return this;
};

Date.prototype.subHours = function (h) {
  return this.addHours(h * -1);
};

Date.prototype.subMinutes = function (m) {
  return this.addMinutes(m * -1);
};
