import React from "react";

export const TerminalDeviceFooterInfo = (props) => {
  const { terminalDevice } = props;

  return (
    <div className="serienumber fSerienumber">
      {terminalDevice?.id}-{terminalDevice?.nserie}-v
      {terminalDevice?.mainCurrentVersion}-hv{terminalDevice?.hardwareVersion}
    </div>
  );
};
