import React, { useEffect } from "react";
import "./TypeCards.sass";
import { getDateTimeFormatted } from "../../../../../../../../_helpers/DateFormatHelper";
export const CardDeactivate = (props) => {
  const { startDateTime, subprogram } = props;
  return (
    <>
      <div className="tworowsdeactivated">
        <div className="Deactivate50">
          <div className="titlecardsensor">{subprogram?.name}</div>
        </div>

        <div className="Deactivate50">
          <div className="titlehourpr">
            De
            <div className="text-center HourCard">
              {getDateTimeFormatted(startDateTime)} a
            </div>
            <div className="text-center HourCard">
              {getDateTimeFormatted(subprogram.getEndDateTime(startDateTime))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
