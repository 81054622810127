import React, { useCallback, useEffect, useState } from "react";
import LogoTecatel from "./LogoTecatel.png";
import { terminalsConstants } from "../../../../_constants/terminals.constants";
import classes from "./SquareView.sass";
import Timer from "../../Timer/Timer";
import { Progress } from "reactstrap";
import { PopUpC } from "../../Airframe/PopUp/PopUp";
import { FormUpload } from "./FormUpload";
import { Spinner } from "../../Airframe/Spinner/Spinner";
import { Info } from "./Components/Info";
import { PlugOn } from "../../Icon/PlugOn";
import { PlugTransition } from "../../Icon/PlugTransition";
import { PlugOff } from "../../Icon/PlugOff";

export const SquareView = (props) => {
  const intervalExectutionMiliseconds = 20;
  const longPressMinMiliseconds = 2000;
  const { transition, active, deactived } = terminalsConstants.status.online;
  const {
    name,
    statusMqtt,
    icon,
    srcImage,
    uploadEntityIds,
    onNameChange,
    targetEntity,
    classfather,
    loadingImage,
    onSelected,
    data,
    terminalDevices,
    EnterRouting,
  } = props;

  const [initialTime, setinitialTime] = useState(new Date());
  const [progressBarNumber, setprogressBarNumber] = useState(0);
  const [longPressedIterations, setLongPressedIterations] = useState(0);
  const [showBoxDialog, setshowBoxDialog] = useState(false);
  const [classImageRotiation, setclassImageRotiation] = useState(
    data.imageOrientation
  );

  const [coordenatestoprops, setcoordenatestoprops] = useState(undefined);
  const [
    classnameHeightPersonalized,
    setclassnameHeightPersonalized,
  ] = useState("Barrita");
  //Activar y desactivar popup automatico.
  const [activatePopup, setactivatePopup] = useState(false);
  //Visualizar la barra de progreso:
  const [showProgressBar, setshowProgressBar] = useState(false);
  const [showPopUpInfo, setshowPopUpInfo] = useState(false);
  const [
    ActivateTimerTocount1Second,
    setActivateTimerTocount1Second,
  ] = useState(false);
  const [internalSrcImage, setInternalSrcImg] = useState(srcImage);
  const [internalName, setInternalName] = useState(name);

  const ChangeName = useCallback(() => {
    setactivatePopup(true);
  }, []);

  const ChangeNameTotime = useCallback(() => {
    setshowProgressBar(true);
    const iterationsInMiliseconds =
      (longPressedIterations + 1) * intervalExectutionMiliseconds;
    let currentPogress =
      (iterationsInMiliseconds * 100) / longPressMinMiliseconds;
    currentPogress = Math.ceil(currentPogress / 5) * 5;
    setprogressBarNumber(currentPogress);
    setLongPressedIterations(longPressedIterations + 1);

    if (currentPogress >= 105) {
      //Desactivar temporizador
      ChangeName();
      setActivateTimerTocount1Second(false);
      setLongPressedIterations(0);
    }
  }, [progressBarNumber, ActivateTimerTocount1Second, longPressedIterations]);

  useEffect(() => {
    if (classfather == "descriptionTerminals") {
      setclassnameHeightPersonalized("BarritaTerminals");
    }
    setInternalName(name);
  }, [name]);

  useEffect(() => {
    setInternalSrcImg(srcImage);
  }, [srcImage]);

  useEffect(() => {
    setclassImageRotiation(data?.imageOrientation || 0);
  }, [data?.imageOrientation]);

  const PressInName = useCallback(() => {
    setinitialTime(new Date());
    setActivateTimerTocount1Second(true);
  }, []);

  const InfoData = useCallback(() => {
    // const coords="Longitud: " + data?.location_longitude + " / " + "Latitud:" + data?.location_latitude;
    const coords = data?.location_longitude + " / " + data?.location_latitude;
    if (data?.location_longitude !== undefined) {
      setcoordenatestoprops(coords);
    }

    // setshowPopUpInfo(true);
    setshowBoxDialog(!showBoxDialog);
  }, []);

  const CheckTimetoChangeName = useCallback(() => {
    const FinalTime = new Date();
    const time = FinalTime.getTime() - initialTime.getTime();

    // 5segundos para activar:
    if (time >= longPressMinMiliseconds) {
      ChangeName();
      setActivateTimerTocount1Second(false);
    } else {
      if (onSelected) {
        onSelected();
      }
    }
    setprogressBarNumber(0);
    setLongPressedIterations(0);
  }, [initialTime, ChangeName, onSelected]);

  const ClosePopUp = useCallback(() => {
    setactivatePopup(false);
    setshowPopUpInfo(false);
    setprogressBarNumber(0);
    setLongPressedIterations(0);
    setshowProgressBar(undefined);

    if (name !== internalName) {
      // Debemos actualizar
      onNameChange(internalName);
    }
  }, [activatePopup, progressBarNumber, showProgressBar, name, internalName]);

  const onElementUpdate = useCallback((name, file, orientation) => {
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setInternalSrcImg(reader.result);
      };
      reader.readAsDataURL(file);
      //rota imagen en local
      setclassImageRotiation(0);
    }
    setInternalName(name);
  }, []);

  return (
    <>
      {/*PopUp info contenido*/}
      <PopUpC
        activate={showPopUpInfo}
        deactivatepopup={ClosePopUp}
        content={<Info data={data} />}
      />
      {/*PopUp añadir foto*/}
      <PopUpC
        activate={activatePopup}
        deactivatepopup={ClosePopUp}
        content={
          <FormUpload
            nameProps={internalName}
            targetEntity={targetEntity}
            uploadEntityIds={uploadEntityIds}
            onUpdate={onElementUpdate}
          />
        }
      />

      <div className="ImageAndSector">
        <div
          onTouchEnd={CheckTimetoChangeName}
          onTouchStart={PressInName}
          onMouseDown={PressInName}
          onMouseUp={CheckTimetoChangeName}
        >
          <div className="ContentCardTecatel">
            {loadingImage ? (
              <div className="SpinnerCard">
                <div className={"LoadspinnerImage"}>
                  <Spinner size={"50px"} />
                </div>
              </div>
            ) : (
              <div className="ContentCardTecatelLoad">
                <img
                  src={internalSrcImage || LogoTecatel}
                  alt="Logo"
                  className={"Image Content" + classImageRotiation}
                />
              </div>
            )}
            {showProgressBar && (
              <Progress
                className={classnameHeightPersonalized}
                color="success"
                value={progressBarNumber}
              />
            )}

            {icon && (
              <>
                <div className="NotificationTerminal">
                  {(() => {
                    switch (statusMqtt) {
                      case transition:
                        return <PlugTransition />;
                      case active:
                        return <PlugOn />;
                      case deactived:
                      default:
                        return <PlugOff />;
                    }
                  })()}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* */}

      <>
        <div className="contenttitlecard">
          <div className="TitleTerminalName">{internalName}</div>
        </div>
      </>

      {ActivateTimerTocount1Second ? (
        <Timer
          isActive={true}
          callback={ChangeNameTotime}
          callbackFirstExecutionDelayMiliseconds={0}
          callbackExecutionEveryMiliseconds={intervalExectutionMiliseconds}
          timerIntervalMiliseconds={intervalExectutionMiliseconds}
        />
      ) : (
        <></>
      )}
    </>
  );
};
