import axios from "axios";
import { authHeader } from "../_helpers";

export const Authservice = {
  handleResponse: handleResponse,
  getRequestOptions,
  loadImage
};

function handleResponse(response) {
  var p = new Promise((resolve, reject) => {
    const data = response.data;
    if (response.status >= 400) {
      /*if (response.status === 401) {
          history(routesConstants.sign.OUT);
        }*/

      const error = response.statusText;
      reject(error);
    }

    resolve(data);
  });
  return p.then((data) => {
    return data;
  });
}

function handleRawResponse(response) {
  var p = new Promise((resolve, reject) => {
    if (response.status >= 400) {
      /*if (response.status === 401) {
          history(routesConstants.sign.OUT);
        }*/

      const error = response.statusText;
      reject(error);
    }

    resolve(response);
  });
  return p.then((response) => {
    return response;
  });
}

function getRequestOptions(method) {
  const authToken = authHeader();
  return {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization,
    },
  };
}

function loadImage(url) {
  return axios
      .get(
        url,
        getRequestOptions("GET")
      )
      .then(handleRawResponse)
      .then((response) => {
        return `data:${response.headers['content-type']};base64,${response.data}`;
      });
}
