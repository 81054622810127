import { authHeader } from "../_helpers";
//import { routesConstants } from "../_constants/routes.constants";
//import { projectsConstants } from "../_constants/projects.constants";
import axios from "axios";
import { Authservice } from "./Authservice";

export const zoneService = {
  getAllZones,
  getZone,
  getZoneSectors,
  getZoneSector,
  updateZoneSector,
};

function getAllZones() {
  const authToken = authHeader();
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken.Authorization
  };
  const requestOptions = {
    method: "GET",
    headers
  };

  return axios
        .get(`${process.env.REACT_APP_API_PATH}/zones/`, requestOptions)
        .then(handleResponse)
        .then(tmpZones => {

          return tmpZones});
}

function getZone(zoneId){
  const authToken = authHeader();
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken.Authorization
  };
  const requestOptions = {
    method: "GET",
    headers
  };

  return axios
        .get(`${process.env.REACT_APP_API_PATH}/zones/${zoneId}`, requestOptions)
        .then(handleResponse)
        .then(zone => {

          return zone});
}

function getZoneSectors(zoneId) {
  const authToken = authHeader();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization
    }
  };

  return axios
        .get(`${process.env.REACT_APP_API_PATH}/zones/${zoneId}/sectors/`, requestOptions)
        .then(handleResponse)
        .then(zoneSectors => {
          return{
            zoneId,
            zoneSectors
          };
        });
}

function getZoneSector(zoneId, sectorId) {
  const authToken = authHeader();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization
    }
  };

  return axios
    .get(
        `${process.env.REACT_APP_API_PATH}/zones/${zoneId}/sectors/${sectorId}`,
        requestOptions
    )
    .then(handleResponse)
    .then(tmpZoneSector => {
      return{
        sectorId: sectorId,
        zoneSector: tmpZoneSector
      };
    });
}

function updateZoneSector(zoneId, sectorId, sectorDto){
  return axios
        .put(
          `${process.env.REACT_APP_API_PATH}/zones/${zoneId}/sectors/${sectorId}`,
          sectorDto,
          Authservice.getRequestOptions("PUT")
        )
        .then(Authservice.handleResponse)
        .then((sector) => {
          return sector;
        });
}

function handleResponse(response) {
  var p = new Promise((resolve, reject) => {
    const data = response.data;
    if (response.status >= 400) {
      /*if (response.status === 401) {
        history(routesConstants.sign.OUT);
      }*/

      const error = response.statusText;
      reject(error);
    }

    resolve(data);
  });
  return p.then(data => {
    return data;
  });
}
