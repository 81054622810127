import React, { useEffect } from "react";
import { ReactComponent as DangerIcoOffOutputs } from "./../../images/ImagesTerminalsDevice/danger.svg";

export const DangerComponent = (props) => {
  const { message } = props;
  useEffect(() => {}, [props]);
  return (
    <div className="childCardDevices">
      <div style={{ display: "flex", alignItems: "center" }} className="Title_Descripction">
        <div style={{ width: "15%" }}>
          <DangerIcoOffOutputs className="PicPlugTD" fill="red" stroke="red" />{" "}
        </div>
        <div style={{ width: "85%", paddingLeft: "1rem" }}>{message}</div>
      </div>
    </div>
  );
};
