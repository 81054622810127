export class OutputTaskTerminalDeviceSubprogram {
    output;
	id;
	notified;
	notified_at;
	deleted_by_user;
	deleted_by_user_at;

    static parseFromPlainDto(dtoText){
        const plainObject = JSON.parse(dtoText);
        return OutputTaskTerminalDeviceSubprogram.parseOutputDtoFromObject(plainObject);
    }

    static parseFromObjectDto(dtoObject){
        if(dtoObject){
            return Object.setPrototypeOf(dtoObject, OutputTaskTerminalDeviceSubprogram.prototype);
        }
        return dtoObject
    }
}