import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { TsmUpdateStatesMQTT } from "../../../MQTT/tsm/TsmUpdateStatesMQTT";
import { TsmGetStatesMQTT } from "../../../MQTT/tsm/TsmGetStatesMQTT";

export const TsmSensorsListener = (props) => {
  const { terminalDevice, onUpdateStateReceived } = props;

  const onTsmUpdateStateMqttMsg = useCallback(
    (topic, message) => {
      const terminalId = message?.data?.terminal_id;
      if (terminalId === terminalDevice?.terminal) {
        const deviceStates = message?.data?.devices_state;
        if (deviceStates instanceof Array) {
          deviceStates
            .filter((ds) => ds?.target_id === terminalDevice?.id)
            .filter((ds) => ds.sensors instanceof Array)
            .flatMap((ds) => ds.sensors)
            .forEach((tsmSensorState) => {
              const selectedSensor = terminalDevice.sensors.filter(
                (sensor) => sensor?.sensorIndex === (tsmSensorState?.sensor_id - 1)
              )[0];
              if (selectedSensor) {
                selectedSensor.lastValueAt = tsmSensorState.received_at;
                selectedSensor.lastValue = tsmSensorState.value;
                selectedSensor.status = tsmSensorState.status_code;
                selectedSensor.error = tsmSensorState.error_code;

                if (onUpdateStateReceived) {
                  onUpdateStateReceived(selectedSensor);
                }
              }
            });
        }
      }
    },
    [terminalDevice, onUpdateStateReceived]
  );

  const onTsmUpdateStateMqttError = useCallback((topic, message) => {
    console.log("Recibido error en tsm_updatestates");
    console.log(message);
  }, []);

  return (
    <>
      <TsmUpdateStatesMQTT
        processMsg={onTsmUpdateStateMqttMsg}
        processErrorMsg={onTsmUpdateStateMqttError}
      />
      <TsmGetStatesMQTT
        processMsg={onTsmUpdateStateMqttMsg}
        processErrorMsg={onTsmUpdateStateMqttError}
      />
    </>
  );
};

TsmSensorsListener.propTypes = {
  terminalDevice: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sensors: PropTypes.array,
  }),
  onUpdateStateReceived: PropTypes.func,
};
