import React, { useEffect, useState, useCallback, useRef } from "react";
import { terminalService } from "./../../../../../_services/terminal.service";

import { devicesConstants } from "./../../../../../_constants/devices.constants";
import { Copy } from "./Copy";
import { projectsConstants } from "./../../../../../_constants/projects.constants";
import { isValidForAdvancedActoutput } from "../../../../components/TerminalDevices/Fichas/OutputConfiguration/OutputsChecker";

export const ButtonsShareF = (props) => {
  const { tokens, terminals, tokenName, namecard } = props;
  const terminalDevices = useRef([]);
  const [terminalsLoaded, setterminalsLoaded] = useState(false);
  const [terminalIndexRequested, setterminalIndexRequested] = useState(0);
  const [terminalIndex, setterminalIndex] = useState(0);
  const [error, seterror] = useState(false);
  const [refresh, setrefresh] = useState(false);

  const addTerminalDevice = useCallback(
    (termDev) => {
      terminalDevices.current.push(termDev);

      let newIndex = terminalIndex + 1;
      setterminalIndex(newIndex);
      setterminalsLoaded(newIndex === terminals.length);
      seterror(false);
      setrefresh(!refresh);
    },
    [terminalIndex, terminals, refresh]
  );

  useEffect(() => {
    if (!terminalsLoaded) {
      if (
        !error &&
        terminals &&
        terminalIndex < terminals.length &&
        terminalIndexRequested === terminalIndex
      ) {
        let terminal = terminals[terminalIndex];

        if (!isNaN(terminal) || terminal?.id) {
          setterminalIndexRequested(terminalIndexRequested + 1);
          terminalService
            .getTerminalDevices(isNaN(terminal) ? terminal.id : terminal)
            .then(
              (termDev) => {
                addTerminalDevice(termDev);
              },
              (error) => {
                seterror(true);
                setterminalIndex(0);
                setterminalIndexRequested(0);
                setterminalsLoaded(false);
              }
            );
        }
      }
    }
  }, [props, terminalsLoaded, terminalIndex, error, refresh]); //
  return (
    <>
      {/* <div className="Title_Descripction center bold">Llave creada, se puede enviar:</div> */}
      <div className="Token">
        {/** */}
        {error && (
          <div className="Error">
            Error cargando los datos de los terminales.
          </div>
        )}
        {terminals === undefined || !terminalsLoaded ? (
          <></>
        ) : (
          Object.keys(
            terminals.sort((first, second) => {
              if (first.id < second.id) {
                return -1;
              }
              if (first.id > second.id) {
                return 1;
              }
              return 0;
            })
          ).map((item, i) => {
            // Buscamos en los TerminalDevice el primer MMS.
            let terminalDeviceId = 1;
            const currentTerminal = terminals[item];
            let found = false;
            let mqttCommand = projectsConstants.master_outputs.actions.actoutput;
            for (let z = 0; z < terminalDevices.current.length; z++) {
              const tds = terminalDevices.current[z];
              if (tds) {
                for (let y = 0; y < tds.length; y++) {
                  const td = tds[y];
                  if (td?.terminal === currentTerminal.id) {
                    if (td?.device?.id === devicesConstants.ids.MMS || tds.length === 1) {
                      terminalDeviceId = td?.id;
                      found = true;

                      if(isValidForAdvancedActoutput(td)){
                          mqttCommand = projectsConstants.master_outputs.actions.advanced_actoutput;

                      }

                      break;
                    }
                  } else {
                    break;
                  }
                }
              }
              if (found) {
                break;
              }
            }
            const tokenSplited = tokens.split(".");
            let tokenAsUrlParams = "";
            if (tokenSplited.length >= 3) {
              tokenAsUrlParams = `h=${tokenSplited[0]}&p=${tokenSplited[1]}&s=${tokenSplited[2]}`;
            }
            const message = `${process.env.REACT_APP_TECO_GATE_BASE_PATH}?t=${
              currentTerminal.id
            }&mdl=${encodeURIComponent((currentTerminal?.terminalType || projectsConstants.master_outputs.id).toLowerCase())}&td=${terminalDeviceId || 1}&o=1&${tokenAsUrlParams}&txt=${
              currentTerminal.description
                ? encodeURIComponent(currentTerminal.description)
                : ""
            }&cmd=${mqttCommand}`;
            return (
              <div className="CardToken" key={currentTerminal.id}>
              
                <Copy
                tokenName={tokenName}
                  message={message}
                  description={currentTerminal.description}
                  sn={currentTerminal.sn}
                  namecard={namecard}

                />
              
              </div>
            );
          })
        )}
        <></>
      </div>
    </>
  );
};
