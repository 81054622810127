import React, { useCallback, useState } from "react";
import { InputGroupwithid } from "../../../../Airframe/InputGroup/InputGroupwithid";
import { ReactComponent as IcoBottle } from "./bottle.svg";
import "./CalibrateContent2.sass";
export const CalibrateContent2 = (props) => {
  const { optionChoose, step, action, contentstep } = props;
  const valueMax = 14;
  const [valueInput, setvalueInput] = useState(0);
  const [errorMaxvalue, seterrorMaxvalue] = useState(false);

  const changeInput = useCallback(
    (value) => {
      if (value <= valueMax) {
        setvalueInput(value);
        seterrorMaxvalue(false);
      } else {
        seterrorMaxvalue(true);
      }
    },
    [valueInput, errorMaxvalue]
  );

  return (
    <div className="contentPopupCalibrate">
      <div>{optionChoose}</div>

      <div className="bodyCalibrateContent contentCalibratewithInput">
        <div className="bodyCalibrateContentcStep2">{contentstep} </div>

        <div className="widthpersonalizedCalibrate">
          <InputGroupwithid
            type="number"
            placeholder={valueInput}
            value={valueInput}
            className="bg-white"
            personalized={"calibrate"}
            tabIndex={-1}
            id={"inputgroup"}
            symbol={"pH"}
            onChange={changeInput}
            min={0}
          />
        </div>

        {errorMaxvalue && (
          <div className="widthErrorCalibrate">
            {" "}
            Error, el valor introducido debe de ser menor a {valueMax}
          </div>
        )}
      </div>

      <div className="ButtonTSMf">
        <div className="ButtonTSM withStep">
          <div className="ButtonOffAll Secondary" onClick={(e) => action(valueInput)}>
            {"Iniciar calibrado"}
          </div>
        </div>
        <div>{step}/3</div>
      </div>
    </div>
  );
};
