import React, { useState, useEffect } from "react";
import { BarChart, XAxis, YAxis, Tooltip, ResponsiveContainer, Bar, CartesianGrid, Legend } from 'recharts';
import { ReactComponent as ICoBateryFull } from './bateryFull.svg';
import { ReactComponent as ICoBatery75 } from './batery75.svg';
import { ReactComponent as ICoBatery25 } from './batery25.svg';
import { ReactComponent as ICoBateryLoad } from './bateryLoad.svg';
export const RegulatorIcobatery = (props) => {
const {value,loadBatery}=props;

    return (
        <>
        {value > 75 && !loadBatery &&  <ICoBateryFull className="IcoPostigel" />}
        {value < 75 && !loadBatery &&<ICoBatery75 className="IcoPostigel" />}
        {value < 25 && !loadBatery &&<ICoBatery25 className="IcoPostigel" />}
        {value === 0 && !loadBatery &&<ICoBatery25 className="IcoPostigel" />}
        {loadBatery && <ICoBateryLoad className="IcoPostigel" />}
              </>
    );
};
