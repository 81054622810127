import React from "react";

export const DigitalSensorsConfigurationHelp = (props) => {
  return (
    <div className="documentation">
      <div className="helptitle">Pulsos</div>
      <div className="grouphelp">
        El dispositivo dispone de varias entradas de pulsos que se pueden utilizar como
        desencadenantes de acciones o bien como contador.
      </div>

      <div className="grouphelp">
        <div className="subhelptitle">Sensores conectados a la entrada de pulsos</div>
        <div>En el conector del dispositivo se pueden conectar sensores digitales (activación flanco de bajada).</div>
      </div>
    </div>
  );
};
