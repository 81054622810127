import React, { useCallback } from "react";
import Toggle from "react-toggle";
import PropTypes from "prop-types";
import { Spinner } from "../Spinner/Spinner";

export const ToggleWithSync = (props) => {
  const {
    title,
    disabled,
    onChange,
    checked,
    unselectedValue,
    selectedValue,
    syncing,
  } = props;

  const onChangeInternal = useCallback((checked) => {
    onChange(checked);
  }, [onChange]);

  return (
    <div className="toggleVoltage">
      <div className="childCardDevices2">
        <div className="Title_Descripction">{title}</div>
      </div>
      <div className="TogglewithInfo">
        <div className="DetailsToggle">{unselectedValue || ""}</div>
        <Toggle
          id="cheese-status"
          checked={checked}
          icons={false}
          disabled={disabled}
          onChange={(e) => onChangeInternal(e.target.checked)}
          className="toggle"
        />
        <div className="DetailsToggle">{selectedValue || ""}</div>
        {syncing && <Spinner size={"25px"}></Spinner>}
      </div>
    </div>
  );
};

ToggleWithSync.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  unselectedValue: PropTypes.string,
  selectedValue: PropTypes.string,
  syncing: PropTypes.bool,
};
