import React, { useEffect, useCallback, useState } from "react";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import "./PanelTypesSubprograms.sass";
import "./Sensors.sass"
import { CardStyle2 } from "./CardStyle2";
import { ReactComponent as IcoSensorPH } from './images/sensorPH.svg';
import { ReactComponent as IcoSensorEC} from './images/electricity.svg';
import { ReactComponent as IcoSensorDO} from './images/sensor.svg';
import { ReactComponent as IcoRedox} from './images/redox.svg';
export const CardSensorTSM = (props) => {
  const { id, sensorIndex, sensorUnit, sensor, button, configureConsign, setpoint } = props;
  const [unitInfo, setUnitInfo] = useState(undefined);

  const getUnitInfo = useCallback((sensorUnit) => {
    for (let unit in projectsConstants.global.sensors.units) {
      if (unit === sensorUnit) {
        return projectsConstants.global.sensors.units[unit];
      }
    }
  }, []);

  useEffect(() => {
    const unitInfo = getUnitInfo(sensorUnit);
    setUnitInfo(unitInfo);
  }, [sensorUnit]);

  const getBadgeName = useCallback(() => {
    return unitInfo?.badge || "sensor";
  }, [unitInfo]);

  const getBadgeClassName = useCallback(() => {
    return unitInfo?.className || "badge-primary";
  }, [unitInfo]);

  const getIcon = useCallback(() => {
    switch (unitInfo?.icon) {
      case "PH":
        return <IcoSensorPH className="SensorIconTsm" />;
      case "ORP":
        return <IcoRedox className="SensorIconTsm" />;
      case "EC":
        return <IcoSensorEC className="SensorIconTsm" />;
      case "DO":
        return <IcoSensorDO className="SensorIconTsm" />;
      default:
        return <></>;
    }
  }, [unitInfo]);

  return (
    <>


      {/* 
      <CardStyle1
        id={id}
        sensorIndex={sensorIndex}
        sensorValue={sensorValue}
        sensorUnit={sensorUnit}
        getBadgeClassName={getBadgeClassName}
        getBadgeName={getBadgeName}
        getIcon={getIcon} />
*/}

      <CardStyle2
        id={id}
        sensorIndex={sensorIndex}
        sensorValue={sensor?.lastValue}
        sensorUnit={sensorUnit}
        sensorValueDateTime={sensor?.lastValueAt}
        sensorStatus={sensor?.status}
        sensorError={sensor?.error}
        setpoint={setpoint}
        showButton={sensor?.status !== projectsConstants.global.sensors.states.calibrating}
        getBadgeClassName={getBadgeClassName}
        getBadgeName={getBadgeName}
        getIcon={getIcon}
        button={button} 
        configureConsign={configureConsign}/>


    </>
  );
};
