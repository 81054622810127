import React, { useCallback, useState, useEffect } from "react";
import { OutputService } from "../../../../../../_services/outputcontroller.service";
import { DropdownC } from "../../../../Airframe/Dropdown/Dropdown";

export function DropdownOutputsTypes(props) {
  const { waitingMqttResponse, waitingApiResponse, showButtonSave, selectedOutputTypeId } = props;
  const [showErrorgetOutputsService, setshowErrorgetOutputsService] = useState(false);
  const [showlistOrSpinner, setshowlistOrSpinner] = useState(false);
  const [options, setoptions] = useState([]);
  const [seletoption, setselectoption] = useState([
    { value: -1, label: '' }
  ]);

  useEffect(() => {
    getOuputsType();
  }, []);

  useEffect(() => {
    if (options) {
      let selectedOption = undefined;
      for (let i = 0; i < options.length; i++) {
        const option = options[i];
        if (option?.id === selectedOutputTypeId) {
          selectedOption = option;
          break;
        }
      }
      if (selectedOption) {
        setselectoption(selectedOption);
      } else if (options.length > 0) {
        setselectoption(options[0]);
      }
    }
  }, [options, selectedOutputTypeId])


  const getNameTypewithAbreviation = useCallback((description, abreviation) => {
    const maxlengthLetters = 15;

    const trimmedString = description.length > maxlengthLetters ?
      description.substring(0, maxlengthLetters - 3) + "..." :
      description;
    return abreviation + " - " + trimmedString;
  }, []);
  const getOuputsType = useCallback(() => {
    setshowlistOrSpinner(true);
    OutputService.getOutputTypes(0, 50).then(
      (activationstypes) => {
        if (activationstypes !== undefined) {
          let selectedOptionIndex = 0;
          let activationstypestmp = [{ id: -1, value: -1, label: 'Principal' }, ...activationstypes.map((element, index) => {
            if (element.id === selectedOutputTypeId) {
              selectedOptionIndex = index + 1;
            }


            return { id: element.id, value: element.id, label: getNameTypewithAbreviation(element.description, element.abbreviation) }
          })];
          setoptions(activationstypestmp);
          setselectoption(activationstypestmp[selectedOptionIndex]);
        }
        setshowlistOrSpinner(false);
        setshowErrorgetOutputsService(false);

      },
      (error) => {
        setshowErrorgetOutputsService(true);
      }
    );
  }, [OutputService.getOutputTypes, selectedOutputTypeId]);


  const DropdownChange = useCallback(
    (select) => {
      setselectoption(select);
      showButtonSave(select.id);
    },
    [showButtonSave]
  );

  return (
    <>
      <DropdownC
        options={options}
        value={seletoption}
        onChange={DropdownChange}
        placeholder={"Selecciona un tipo..."}
        disabled={waitingApiResponse || waitingMqttResponse} />
    </>
  );
}
