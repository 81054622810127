import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Progress } from "reactstrap";
import classes1 from "./RemainingBar.sass";

const RemainingBar = (props) => {
  const { children, slim, className, colorrestbar, ...otherProps } = props;
  const progressClass = classNames(className, {
    [classes1["slim"]]: slim,
  });
  const [colorrestbars] = useState(
    colorrestbar !== undefined ? "colorrestbar" :  " OnlyBarColor"
  );

  return (
    <Progress className={progressClass +  colorrestbars } {...otherProps}>
      {!slim && children}
    </Progress>
  );
};

RemainingBar.propTypes = {
  slim: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export { RemainingBar };
