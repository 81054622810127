import React, { useCallback } from "react";
import { projectsConstants } from "../../../../../_constants/projects.constants";

import { ReactComponent as Configure } from "./configure.svg";
export const CardStyle2 = (props) => {
  const {
    sensorIndex,
    getBadgeClassName,
    getBadgeName,
    getIcon,
    sensorValue,
    sensorStatus,
    sensorError,
    setpoint,
    button,
    configureConsign,
    sensorValueDateTime,
    showButton,
  } = props;

  const getSensorStatus = useCallback(() => {
    switch (sensorStatus) {
      case projectsConstants.global.sensors.states.calibrating:
        return <>¡Calibrando!</>;
      case projectsConstants.global.sensors.states.idle:
      case projectsConstants.global.sensors.states.measuring:
      default:
        return <>Midiendo</>;
    }
  }, [sensorStatus]);

  const getSensorError = useCallback(() => {
    switch (sensorError) {
      case projectsConstants.global.sensors.errors.calibratig:
        return <>¡Error en el calibrado!</>;
      case projectsConstants.global.sensors.errors.none:
      default:
        return <br></br>;
    }
  }, [sensorError]);

  const getSetPointElement = useCallback((setpoint) => {
    return <span style={{ color: "red" }}>Cons: {setpoint}</span>;
  }, []);

  const getContainerExtraClasses = useCallback(() => {
    let diff = Math.abs(setpoint - sensorValue);
    return setpoint && diff > 0.15 ? "blinkingBackground" : ""
  }, [setpoint, sensorValue])

  return (
    <>
      <div>
        <div
          className={`badge ml-auto badge2 badge-pill ${getBadgeClassName()}`}
        >
          {`${getBadgeName()}-${sensorIndex + 1}`}
        </div>
        <div className={`cardSensorTsm ${getContainerExtraClasses()}`}>
          <div className="cardSensorTsmc">
            <div className="cardSensorTsmc1">
              <div className="valuecardSavePostigel2">
                {isNaN(sensorValue) ? "X.XX" : sensorValue?.toFixed(3)}
                {/*sensorUnit*/}
              </div>
              <div>
                {sensorValueDateTime
                  ? new Date(Date.parse(sensorValueDateTime)).toLocaleString()
                  : ""}
              </div>
            </div>
            <div>
              {setpoint && getSetPointElement(setpoint)}
              <div> {getIcon()} </div>
            </div>
          </div>
          <div>
            <div>{getSensorStatus()}</div>
            <div>{getSensorError()}</div>
          </div>

          <div className="ButtonTSMf">
            {configureConsign && (
              <div>
                <Configure
                  className="SvgIcoRTsm"
                  onClick={(e) => configureConsign(sensorIndex)}
                />
              </div>
            )}

            {showButton && button}
          </div>
        </div>
      </div>
    </>
  );
};
