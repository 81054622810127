import React, { useCallback, useState } from "react";
import TerminalsChildSass from "./TerminalsChild.sass";
export const TerminalsChild = (props) => {
  const {
    id,
    description,
    sn,
    AddTerminalToList,
    DeleteTerminalToList,
  } = props;
  const [selected, setselected] = useState("TerminalsF");
  const [memoryselected, setmemoryselected] = useState(0);
  const SelectTerminal_Token = useCallback(
    (id, description, sn) => {
   
    
      if (memoryselected === 0) {
        setselected("TerminalsF_selected");
        setmemoryselected(1);
        AddTerminalToList(id);
        //Beta
        props.SelectOnlyTerminal(props.list);
      } else {
        setselected("TerminalsF");
        setmemoryselected(0);
        DeleteTerminalToList(id);
      }
    },
    [selected, memoryselected]
  );

  return (
    <div
      className={selected}
      onClick={(e) => SelectTerminal_Token(id, description, sn)}
    >
      <div className="TerminalChildFC idsize">{id}</div>
      <div className="TerminalChildFC descriptionsize">{description}</div>
      <div className="TerminalChildFC numbersize">{sn}</div>
    </div>
  );
};
