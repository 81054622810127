import React, {
  createRef,
  useCallback,
  useRef,
  useEffect,
  useState,
} from "react";

import { CardElementDrapDrop } from "./CardElementDrapDrop";
import { DragDrop } from "./DragDrop/DragDrop";
import { CardPrePos } from "./TypeCards/CardPrePos";

export const DrapDropView = (props) => {
  const {
    alldata,
    listElements,
    actionOnDeletep,
    actionOnRestore,
    itemstoReorganized,
    focusOnIndex,
    actionOnEdit,
    Info,
    reorderedError,
    showMessageNotEdit,
    typeofCard,
    subroutineinfo,
    typeDragDrop,
  } = props;
  const cardsRefs = useRef([]);
  const [numberMaxNotified, setnumberMaxNotified] = useState([undefined]);
  const [cards, setcards] = useState([undefined]);

  const NumberCardsNotified = useCallback((numbers) => {
    setnumberMaxNotified(numbers);
  }, []);

  const getItemCard = useCallback(
    (index, date, subprogram, itemRef) => {
      const item = {
        id: "Item " + index,
        key: subprogram?.index,
        content: (
          <CardElementDrapDrop
            typeofCard={typeofCard}
            key={index}
            index={index}
            startDateTime={date}
            subprogram={subprogram}
            actionOnDelete={actionOnDeletep}
            actionOnRestore={actionOnRestore}
            innerRef={itemRef}
            actionOnEdit={actionOnEdit}
            NumberCardsNotified={NumberCardsNotified}
            showMessageNotEdit={showMessageNotEdit}
          />
        ),
      };

      return item;
    },
    [
      typeofCard,
      actionOnEdit,
      actionOnDeletep,
      actionOnRestore,
      NumberCardsNotified,
      showMessageNotEdit,
    ]
  );

  useEffect(() => {
    if (focusOnIndex >= 0 && focusOnIndex <= cardsRefs.current.length) {
      if (cardsRefs.current[focusOnIndex]?.current) {
        cardsRefs.current[focusOnIndex].current.scrollIntoView(false);
      }
    }
  }, [focusOnIndex]);

  useEffect(() => {
    getCardsForDragDrop();
  }, [numberMaxNotified, props]);

  const getPreCard = useCallback((hourMinute) => {
    return {
      id: "PreItem",
      key: "PreItem",
      isDragDisabled: true,
      content: <CardPrePos type={0} hourMinute={hourMinute}></CardPrePos>,
    };
  }, []);

  const getPosCard = useCallback((hourMinute) => {
    return {
      id: "PosItem",
      key: "PosItem",
      isDragDisabled: true,
      content: <CardPrePos type={1} hourMinute={hourMinute}></CardPrePos>,
    };
  }, []);

  const getCardsForDragDrop = useCallback(() => {
    if (listElements && alldata) {
      let initDate = alldata.getStartDate();
      let refs = cardsRefs.current;

      let preContent = undefined,
        posContent = undefined;
      const pre = subroutineinfo?.initDelay;
      const pos = subroutineinfo?.endDelay;
      if (typeDragDrop === "Infraprogram") {
        if (pre && pre !== "00:00") {
          preContent = [getPreCard(pre)];
        }
        if (pos && pos !== "00:00") {
          posContent = [getPosCard(pos)];
        }
      }

      let subpList = listElements.map((elementlist, index) => {
        const itemRef = createRef();
        if (index < refs.length) {
          refs[index] = itemRef;
        } else {
          refs.push(itemRef);
        }

        const item = getItemCard(
          index,
          new Date(initDate.getTime()),
          elementlist,
          itemRef
        );
        if (elementlist?.activeTime) {
          const [hour, minute] = elementlist.activeTime.split(":");
          initDate.addHours(hour);
          initDate.addMinutes(minute);
        }
        return item;
      });

      setcards(
        <>
          <DragDrop
            reorderedError={reorderedError}
            content={subpList}
            itemstoReorganized={itemstoReorganized}
            Info={Info}
            numberMaxNotified={numberMaxNotified}
            numberElements={listElements?.length || 0}
            typeofCard={typeofCard}
            preContent={preContent}
            posContent={posContent}
          />
        </>
      );
    }
  }, [
    listElements,
    alldata,
    subroutineinfo?.initDelay,
    subroutineinfo?.endDelay,
    typeDragDrop,
    reorderedError,
    itemstoReorganized,
    Info,
    numberMaxNotified,
    typeofCard,
    getPreCard,
    getPosCard,
    getItemCard,
  ]);

  return (
    <>
      {" "}
      {listElements?.length > 0 ? <div className="cards">{cards}</div> : <></>}
    </>
  );
};
