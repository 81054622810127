import React, { useCallback, useEffect, useState } from "react";
import { TerminalDeviceSensorsDropDown } from "../SensorsConfiguration/sensorsConf/TerminalDeviceSensorsDropDown";
import { Input } from "reactstrap";
import { projectsConstants } from "../../../../../_constants/projects.constants";

export const  TerminalDeviceOutputDigitalSensorAssociate = props => {

    const {terminalDevice, output, onTerminalDeviceSensorSelected, onTheoreticalFlowChange} = props

    const [selectedSensor, setSelectedSensor] = useState(output.digitalSensor)

    useEffect(() => {
        setSelectedSensor(output.digitalSensor)
    }, [output])

    const onTerminalDeviceSensorSelectedCallback  = useCallback((terminalDeviceSensorIndex, terminalDeviceSensor) => {
        setSelectedSensor(terminalDeviceSensor)
        onTerminalDeviceSensorSelected(terminalDeviceSensor)
    }, [onTerminalDeviceSensorSelected])

    return <>
        <TerminalDeviceSensorsDropDown 
            sensors={terminalDevice?.sensors}
            physicalCommunicationType={projectsConstants.global.sensors.phys.digital}
            onSelected={onTerminalDeviceSensorSelectedCallback}
            selectedSensor={selectedSensor}
            filterOnlyActiveAndConfigured={true}
            useSensorTypeDescpritionIfEmtpyCustomDescription={false}
            isClearable={true}
        />
        {output?.output > 0 && output?.digitalSensor &&
        <>
            <div className="Title_Descripction">Caudal teórico (m3/h):</div>
            <Input
            type="number"
            name="theoretical_flow"
            id="theoretical_flow"
            className="InputForm descriptionInput"
            onChange={(e) => onTheoreticalFlowChange(e.target.value)}
            value={output?.theoreticalFlow || 0}
            />
        </>
        }
    </>
}