import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { SetoutputvMQTT } from "../../../MQTT/SetoutputvMQTT";
import { ToggleVoltaje } from "./ToggleVoltaje";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import { useOutputsAPI } from "../../../../../context/outputs-context";
import { ReactComponent as DangerIcoOffOutputs } from "./../OutputConfiguration/danger.svg";
import { TerminalDevice } from "../../../../../classes/TerminalDevice/TerminalDevice";

export const VoltageSelector = (props) => {
  const { V12, V24 } = projectsConstants.global.voltages;
  const ACK = projectsConstants.global.codes.ACK;
  const MMS = projectsConstants.global.devices.MMS;
  const PKT7 = projectsConstants.global.devices.PKT7;
  const {
    output_active: OUTPUT_ACTIVE,
    output_transition: OUTPUT_TRANSITION,
  } = projectsConstants.global.states;

  const v12toogleValue = false;
  const v24toogleValue = true;
  const { terminalDevice, eventSync } = props;

  let i = 0;
  const states = {
    IDLE: i,
    WAITING_TERMINAL_DEVICE_RESPONSE: ++i,
    RECEIVED_TERMINAL_DEVICE_RESPONSE: ++i,

    ERROR_HAS_OUTPUT_ACTIVE: ++i,
    ERROR_ON_TERMINAL_DEVICE_RESPONSE: ++i, // ERROR EN LA RESPUESTA DEL TERMINAL DEVICE.
    ERROR_ON_RESPONSE: ++i, // ERROR DEL SERVIDOR.
  };
  const [stateMachine, setStateMachine] = useState({
    state: states.IDLE,
  });

  const { setoutputv } = useOutputsAPI();
  const [notshow, setnotshow] = useState(true);
  const [outputVIsSync, setOutputVIsSync] = useState(true);

  const mustBeHidden = useCallback(() => {
    return (terminalDevice.device.name === MMS && parseInt(terminalDevice.hardwareVersion ?? '0') < 3) || terminalDevice.device.name === PKT7
  },[terminalDevice.device.name, terminalDevice.hardwareVersion, MMS, PKT7])

  useEffect(() => {
    if (stateMachine.state === states.RECEIVED_TERMINAL_DEVICE_RESPONSE) {
      setStateMachine({ state: states.IDLE });
    }

    setnotshow(!mustBeHidden());

    let isSync =
      terminalDevice &&
      terminalDevice.outputsVoltage &&
      terminalDevice.outputsVoltageExpected &&
      terminalDevice.outputsVoltage === terminalDevice.outputsVoltageExpected
        ? true
        : false;
    setOutputVIsSync(isSync);
    eventSync(isSync);
  }, [stateMachine]);

  //#endregion

  //#region MENSAJES SETOUTPUTV MQTT
  const processSetoutputMsg = useCallback(
    (messageId, message) => {
      let data = message?.data;
      let terminalDeviceVoltage = data.setoutputv?.voltage;
      let success = data.success === ACK;
      let target_id = data.setoutputv?.target_id;
      if (terminalDeviceVoltage && target_id === terminalDevice.id) {
        if (success) {
          if (terminalDeviceVoltage !== V24) {
            terminalDeviceVoltage = V12;
          }
          terminalDevice.outputsVoltage = terminalDeviceVoltage;
          setStateMachine({ state: states.RECEIVED_TERMINAL_DEVICE_RESPONSE });
        } else {
          setStateMachine({ state: states.ERROR_ON_TERMINAL_DEVICE_RESPONSE });
        }
      }
    },
    [terminalDevice, ACK, V12, V24, states.ERROR_ON_TERMINAL_DEVICE_RESPONSE, states.RECEIVED_TERMINAL_DEVICE_RESPONSE]
  );

  const processSetoutputErrorMsg = useCallback(
    (messageId, message) => {
      const data = message?.data;
      let target_id = data?.target_id;
      //let terminalDeviceVoltage = data?.voltage;
      if (target_id === terminalDevice.id) {
        setStateMachine({ state: states.ERROR_ON_RESPONSE });
      }
    },
    [terminalDevice, states.ERROR_ON_RESPONSE]
  );

  //#endregion

  const onVoltageSelected = useCallback(() => {
    let detector = 0;
    terminalDevice.outputs.forEach((element) => {
      if (
        element.currentState === OUTPUT_ACTIVE ||
        element.currentState === OUTPUT_TRANSITION
      ) {
        //Error , alguna salida esta activada.
        //No puedes cambiar el voltaje sin apagar antes todas las salidas.
        detector = 1;
        setStateMachine({ state: states.ERROR_HAS_OUTPUT_ACTIVE });
      }
    });

    if (detector === 0) {
      let targetVoltage = terminalDevice.outputsVoltageExpected === V12 ? V24 : V12;
      setStateMachine({ state: states.WAITING_TERMINAL_DEVICE_RESPONSE });
      setoutputv(terminalDevice.id, targetVoltage);
      terminalDevice.outputsVoltageExpected = targetVoltage;
    }
  }, [terminalDevice, OUTPUT_ACTIVE, OUTPUT_TRANSITION, V12, V24, setoutputv, states.ERROR_HAS_OUTPUT_ACTIVE, states.WAITING_TERMINAL_DEVICE_RESPONSE]);

  const getToogleState = useCallback(() => {
    return (
      stateMachine.state === states.WAITING_TERMINAL_DEVICE_RESPONSE || terminalDevice.device.name === PKT7
    );
  }, [stateMachine, PKT7, states.WAITING_TERMINAL_DEVICE_RESPONSE, terminalDevice.device.name]);

  const inErrorState = useCallback(() => {
    return (
      stateMachine.state === states.ERROR_HAS_OUTPUT_ACTIVE ||
      stateMachine.state === states.ERROR_ON_RESPONSE ||
      stateMachine.state === states.ERROR_ON_TERMINAL_DEVICE_RESPONSE
    );
  }, [stateMachine, states.ERROR_HAS_OUTPUT_ACTIVE, states.ERROR_ON_RESPONSE, states.ERROR_ON_TERMINAL_DEVICE_RESPONSE]);

  const getErrorMessage = useCallback(() => {
    let msg = "";
    switch (stateMachine.state) {
      case states.ERROR_HAS_OUTPUT_ACTIVE:
        msg =
          "Hay salidas activas, debe apagarlas para poder modificar el voltaje.";
        break;
      case states.ERROR_ON_RESPONSE:
        msg = "No se ha podido contactar con el dispositivio. En cuanto tenga conexión se le actualizarán los datos si es necesario.";
        break;
      case states.ERROR_ON_TERMINAL_DEVICE_RESPONSE:
        msg = "El dispositivo no ha podido efectuar el cambio de voltaje.";
        break;
      default:
        break;
    }
    return msg;
  }, [stateMachine, states.ERROR_HAS_OUTPUT_ACTIVE, states.ERROR_ON_RESPONSE, states.ERROR_ON_TERMINAL_DEVICE_RESPONSE]);
  //#region useEffect

  return (
    <>
      {notshow && <div className="TabOutputConf"></div>}
      <div className="cardDescription">
        {inErrorState() && (
          <div className="childCardDevices">
            <div className="MessageError">
              <div>
                <DangerIcoOffOutputs
                  className="PicPlugTD"
                  fill="red"
                  stroke="red"
                />
              </div>
              <div className="textErrorM"> {getErrorMessage()}</div>
            </div>
          </div>
        )}
        <SetoutputvMQTT
          processMsg={processSetoutputMsg}
          processErrorMsg={processSetoutputErrorMsg}
        ></SetoutputvMQTT>
        {notshow && (
          <>
            <ToggleVoltaje
              checkedToogle={
                terminalDevice.outputsVoltageExpected === V12
                  ? v12toogleValue
                  : v24toogleValue
              }
              stateToggle={getToogleState()}
              ActionToggle={onVoltageSelected}
              voltage={terminalDevice.outputsVoltageExpected}
              syncing={!outputVIsSync}
            />
          </>
        )}
      </div>
    </>
  );
};

VoltageSelector.propTypes = {
  terminalDevice: PropTypes.instanceOf(TerminalDevice),
  eventSync: PropTypes.func,
};
