import React, { useCallback, useState, useEffect } from "react";
import _ from "lodash";

import "./Days.sass";

export const Days = (props) => {
  const days = ["L", "M", "X", "J", "V", "S", "D"];
  const { SetDays, initDays, enabled, WarningMessage } = props;
  const [selectedDaysIndex, setselectedDaysIndex] = useState(
    getDaysFromBits(initDays)
  );


  useEffect(() => {
    setselectedDaysIndex(getDaysFromBits(initDays));
  }, [initDays, props]);
  function getDaysFromBits(days) {
    let offset = 1;
    let parsed = [];
    let i = 0;
    while (offset <= days) {
      if ((days & offset) > 0) {
        parsed.push((i + 6) % 7);
      }
      offset = 1 << ++i;
    }
    return parsed;
  }

  /************** SELECCION DE DIAS ********************/
  const isSelected = useCallback(
    (dayIndex) => {
      return selectedDaysIndex.indexOf(dayIndex) >= 0;
    },
    [selectedDaysIndex]
  );

  const getDays = useCallback((daysIndex) => {
    let days = 0;
    daysIndex.forEach((index) => {
      const newIndex = (index + 1) % 7;
      days |= 1 << newIndex;
    });
    return days;
  }, []);

  const select = useCallback(
    (dayIndex) => {
      let tmp = _.cloneDeep(selectedDaysIndex);
      tmp.push(dayIndex);
      setselectedDaysIndex(tmp);
      SetDays(getDays(tmp));
    },
    [selectedDaysIndex, SetDays]
  );
  const SelectAll = useCallback(
    (dayIndex) => {
      SetDays(getDays([0, 1, 2, 3, 4, 5, 6]));
    },
    [selectedDaysIndex, SetDays]
  );
  const unselect = useCallback(
    (dayIndex) => {
      const indexOf = selectedDaysIndex.indexOf(dayIndex);
      if (indexOf >= 0) {
        let tmp = _.cloneDeep(selectedDaysIndex);
        tmp.splice(indexOf, 1);
        setselectedDaysIndex(tmp);

        SetDays(getDays(tmp));
      }
    },
    [selectedDaysIndex, SetDays]
  );

  const onDaySelected = useCallback(
    (dayIndex) => {
      if (isSelected(dayIndex)) {
        unselect(dayIndex);
      } else {
        select(dayIndex);
      }
    },
    [isSelected, select, unselect]
  );

  return (
    <>
      <div className="DayweeksTitleF">
        <div className="DayweeksTitle">Días semana:</div>
        {enabled && (
        <div className="pildora buttonselect" onClick={(e) => SelectAll()}>
              Seleccionar todos
            </div>)}
      </div>

      {enabled ? (
        <>
          <div className="positionButtonSelectAllDays">
      
            <div className="ContentSelectDays">
              {days.map((d, index) => {
                return (
                  <Day
                    key={index}
                    id={index}
                    day={d}
                    selected={isSelected(index)}
                    onSelected={onDaySelected}
                  />
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <>
        <div className="ContentSelectDays">
          {days.map((d, index) => {
            return (
              <DayDeactivated
                key={index}
                id={index}
                day={d}
                selected={isSelected(index)}
                onSelected={onDaySelected}
                WarningMessage={WarningMessage}
              />
            );
          })}
          </div>
        </>
      )}
    </>
  );
};

const DayDeactivated = (props) => {
  const { selected, onSelected, id, day, WarningMessage } = props;
  return (
    <div
      className={`DayP ${selected ? "activeUnclick" : "deactive"}`}
      value={day}
      onClick={WarningMessage}
    >
      {`${day} `}
    </div>
  );
};

const Day = (props) => {
  const { selected, onSelected, id, day } = props;
  return (
    <div
      className={`DayP ${selected ? "active" : "deactive"}`}
      value={day}
      onClick={(e) => onSelected(id)}
    >
      {`${day} `}
    </div>
  );
};
