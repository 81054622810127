import React, { useCallback, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const DayChoose = (props) => {
  const { onPrevClick, onNextClick, day, onDayChange } = props;

  const isNextDayEnabled = useCallback((date) => {
    const now = new Date(); // Obtiene la fecha actual
    const currentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const inputDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return inputDate < currentDate;
  }, []);

  // Componente personalizado para el disparador del DatePicker
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="dayInDayBatery" onClick={onClick} ref={ref}>
      {value}
    </div>
  ));

  return (
    <>
      <div className="selectorDatef">
        <div className="selectorday tsmDay">
          <div className="selectorsDaysBatery">
            <div className={`fa fa-fw fa-chevron-left icoSelectDayBatery`} onClick={onPrevClick}></div>

            {/* Utiliza DatePicker con customInput */}
            <DatePicker
              locale="es"
              selected={day}
              onChange={(selectedDate) => onDayChange(selectedDate)}
              customInput={<CustomInput />}
              maxDate={new Date()} // Fecha máxima es la actual
              dateFormat="dd/MM/yyyy"
            />

            <div className={`fa ${!isNextDayEnabled(day) ? "fa-disabled" : ""} fa-fw fa-chevron-right icoSelectDayBatery`} 
              onClick={() => {
                if (isNextDayEnabled(day)) {
                  onNextClick();
                }
              }}>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
