import React from "react";
import Bell from "./../../../../../images/bell.png";
import BellActive from "./../../../../../images/bell_active.png";
import StopActive from "./../../../../../images/stop.png";
import Stop from "./../../../../../images/stop_trans.png";

export const TerminalDeviceSensorTriggerConfiguratorHelp = (props) => {
  return (
    <div className="documentation">
      <div className="helptitle">Consignas</div>
      <div className="grouphelp">
        El dispositivo es capaz de realizar ciertas acciones en base a distintas
        consignas
      </div>

      <div className="helptitle">Consignas para sensores</div>
      <div className="grouphelp">
        <div>
          Se pueden activar salidas en base al valor del sensor. El tiempo de
          activación de las salidas se irá ajustando en base a lo cerca que se
          esté de la consigna.
        </div>

        <div className="subhelptitle">• Valor</div>
        <div>
          {" "}
          Cuando el valor del sensor es {">"} o {"<"} del valor de la consigna
          se activarán las salidas seleccionadas.{" "}
        </div>

        <div className="subhelptitle">• Banda proporcional</div>
        <div>
          {" "}
          La banda proporcional indica la diferencia máxima entre la consigna y
          el valor del sensor para que las salidas se activen 100% del tiempo de
          lectura.
        </div>

        <div className="subhelptitle">• Periodo lectura</div>
        <div>
          {" "}
          Indica cada cuandos segundos el dispositivo debe comprobar el valor
          del sensor.
        </div>

        <div className="subhelptitle">• Periodo inyección</div>
        <div>
          {" "}
          Indica el tiempo máximo que debe estar activa una salida cuando
          valorAbsoluto(ValorSensor - Consigna) {">"} BandaProporcional. El
          periodo de inyección normalmente será mayor al periodo de lectura.
        </div>

        <div className="subhelptitle">• Salidas</div>
        <div>
          {" "}
          Salidas a activar cuando se cumplan las condiciones definidas. El
          icono{" "}
          <div style={{ maxWidth: "1rem", textAlign: "center" }}>
            <img
              src={BellActive}
              alt="ButtonDeleteProgram"
              className="IcoDelProgram"
            />
          </div>
          indica que se notificará cuando se cumpla la consigna.
          Además el icono{" "}
          <div style={{ maxWidth: "1rem", textAlign: "center" }}>
            <img
              src={StopActive}
              alt="ButtonDeleteProgram"
              className="IcoDelProgram"
            />
          </div>
          indica que el sistema entrará en modo mantenimiento cuando se cumpla la consigna.
        </div>
      </div>
      <br />

      <div className="helptitle">Consignas para pulsos</div>
      <div className="grouphelp">
        <div>Se pueden activar salidas en base a un pulsos.</div>

        <div className="subhelptitle">• Tiempo activación</div>
        <div> Indica el tiempo que se deben activar las salidas.</div>

        <div className="subhelptitle">• Salidas</div>
        <div>
          {" "}
          Salidas a activar cuando se cumplan las condiciones definidas. Ver el
          apartado anterior.
        </div>
      </div>
      <br />
    </div>
  );
};
