import React from "react";
import Toggle from "react-toggle";

export const TerminalDeviceSensorActiveState = (props) => {

    const { sensor, onChange } = props

  return (
    <div className="toggleVoltage">
      <div className="childCardDevices2">
        <div className="Title_Descripction">Estado:</div>
      </div>

      <div className="TogglewithInfo">
        <div className="DetailsToggle">OFF</div>
        <Toggle
          id="cheese-status"
          checked={sensor?.active || false}
          icons={false}
          onChange={onChange}
          className="toggle"
          disabled={sensor === undefined}
        />
        <div className="DetailsToggle">ON</div>
      </div>
    </div>
  );
};
