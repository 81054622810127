import React, { useMemo, useState, useCallback, useEffect } from "react";
import _ from "lodash";
import { terminalService } from "../_services/terminal.service";
import { TerminalDevice } from "../classes/TerminalDevice/TerminalDevice";

const AppContext = React.createContext();
export const LOADING_STATUS = {
  idle: "idle",
  loading: "loading",
  loaded: "loaded",
  error: "error",
};

export const AppProvider = (props) => {
  const [selectedZone, setSelectedZone] = useState(undefined);
  const [selectedSector, setSelectedSector] = useState(undefined);
  const [selectedTerminal, setselectedTerminal] = useState(undefined);
  const [terminalDevices, setTerminalDevices] = useState(undefined);
  const [loadingState, setLoadingState] = useState(LOADING_STATUS.idle);
  const [terminals, setTerminals] = useState(undefined);

  const updateTerminalDevice = useCallback(
    (terminalDevice) => {
      if (terminalDevices instanceof Array) {
        let tmpTerminalDevices = _.cloneDeep(terminalDevices);

        const index = tmpTerminalDevices.findIndex(
          (td) => td?.id === terminalDevice.id
        );

        if (index >= 0) {
          tmpTerminalDevices[index] = TerminalDevice.parseFromObjectDto({...terminalDevice})
          setTerminalDevices(tmpTerminalDevices);
        }
      }
    },
    [terminalDevices]
  );

  const sortTerminalDeviceOutputs = useCallback((terminalDevice) => {
    if (terminalDevice.outputs && terminalDevice.outputs instanceof Array) {
      terminalDevice.outputs = terminalDevice.outputs.sort((first, second) => {
        if (first?.output < second?.output) {
          return -1;
        }
        if (first?.output > second?.output) {
          return 1;
        }
        return 0;
      });
    }
  }, []);

  const reloadTerminalDevice = useCallback(
    (terminalDeviceId) => {
      if (selectedTerminal?.id > 0) {
        terminalService
          .getTerminalDevice(selectedTerminal.id, terminalDeviceId)
          .then(
            (terminalDevice) => {
              if (terminalDevices instanceof Array) {
                const index = terminalDevices.findIndex(
                  (td) => td?.id === terminalDevice.id
                );

                if (index >= 0) {
                  sortTerminalDeviceOutputs(terminalDevice);
                  terminalDevices[index] = terminalDevice;
                }

                setTerminalDevices([...terminalDevices]);
              }
            },
            (err) => {
              console.log(err);
            }
          );
      }
    },
    [selectedTerminal, sortTerminalDeviceOutputs, terminalDevices]
  );

  const loadTerminalDevices = useCallback(() => {
    if (selectedTerminal?.id > 0) {
      setLoadingState(LOADING_STATUS.loading);
      terminalService.getTerminalDevices(selectedTerminal.id).then(
        (tmpTerminalDevices) => {
          setTerminalDevices(tmpTerminalDevices || []);
          setLoadingState(LOADING_STATUS.loaded);
        },
        (error) => {
          setLoadingState(LOADING_STATUS.error);
          setTerminalDevices([]);
        }
      );
    }
  }, [selectedTerminal]);

  useEffect(() => {
    loadTerminalDevices()
  }, [loadTerminalDevices])

  const value = useMemo(() => {
    return {
      selectedTerminal,
      setselectedTerminal,
      selectedZone,
      setSelectedZone,
      selectedSector,
      setSelectedSector,
      terminals,
      setTerminals,
      terminalDevices,
      updateTerminalDevice,
      reloadTerminalDevice,
      loadingState,
    };
  }, [
    selectedTerminal,
    selectedZone,
    selectedSector,
    terminals,
    terminalDevices,
    updateTerminalDevice,
    reloadTerminalDevice,
    loadingState,
  ]);

  return <AppContext.Provider value={value} {...props} />;
};

export const useApp = () => {
  const context = React.useContext(AppContext);
  if (!context) {
    throw new Error("useApp debe estar dentro del proveedore AppContext");
  }
  return context;
};

export default AppContext;
