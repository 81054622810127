import React, { useState, useEffect, useCallback } from "react";
import { RemainingBar } from "../../../components/Airframe/RemainingBar/RemainingBar";
import { BarChart, XAxis, YAxis, Tooltip, ResponsiveContainer, Bar, CartesianGrid, Legend, AreaChart, Area } from 'recharts';
import "./Cardpostigel.sass";
import "./../../../components/TerminalDevices/TerminalDevices/Components/LiquidBarchart.sass"
import { ReactComponent as IcoLiquid } from '../images/liquidIco.svg';
import { DayChoose } from "./DayChoose";
import { PostigelService } from "../../../../_services/postigel.service";
export const BatteryStatistics = (props) => {
    const { title, value, id, icon ,terminalId, terminal} = props;
    const [mapDataGroupByDate, setmapDataGroupByDate] = useState(undefined);
    const actualdaytmp=new Date(Date.now());
    const actualday=`${actualdaytmp.getFullYear()}-${actualdaytmp.getMonth() + 1}-${actualdaytmp.getDate()}`;
    const [dayselect, setdayselect] = useState(actualday);

      //ordenar por fecha

      function compareValues(key, order = 'asc') {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }
    const data = [
        {
            "name": "6:00",

            "bateria": 100,

        },
        {
            "name": "8:00",

            "bateria": 90,

        },
        {
            "name": "10:00",

            "bateria": 85,

        },
        {
            "name": "12:00",

            "bateria": 80,

        },
        {
            "name": "14:00",

            "bateria": 40,

        },
        {
            "name": "16:00",

            "bateria": 25,

        },
        {
            "name": "18:00",

            "bateria": 10,

        },

    ]




    const data2 = [
        {
            name: '8:00',
 
            Horas: 20,
           
        },
        {
            name: '10:00',
   
            Horas: 40,
            
        },
        {
            name: '12:00',

            Horas: 30,
       
        },
        {
            name: '14:00',
        
            Horas: 50,
     
        },
        {
            name: '16:00',
    
            Horas: 60,
           
        },
        {
            name: '18:00',
         
            Horas: 20,
         
        },

    ];
    const changeDay = useCallback((selectDay) => {

    }, []);

    useEffect(() => {

        // const terminalIdTmp=6;
        // const terminalTmp=23;
 
         const terminalIdTmp=terminalId;
         const terminalTmp=terminal.id;
 
         PostigelService.getAll(terminalIdTmp, terminalTmp)
             .then((postigels) => {
                 if (postigels.length > 0) {
 
                     //Antes de hacer el push ordena por fecha:
                     //const postigelsSorted = postigels.slice().sort((a, b) => Date.parse(b.receivedAt) - Date.parse(a.receivedAt));
                     const postigelSorted2 = postigels.sort(compareValues('receivedAt'));
 
                     const map = new Map();
                     postigelSorted2.forEach(report => {
                         let date = new Date(Date.parse(report.receivedAt));
 
 
                         const data = {
                             name: date.getHours() + ":" + date.getMinutes(),
                             Desinfecciones: report.dosificationCounter,
                             Detecciones: report.presenceCounter,
                         };
                         //ActualDay
                         const key = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                         //console.log(key);
                         if (!map.has(key)) {
                             map.set(key, { data: [] });
                         }
                         map.get(key).data.push(data);
                     });
                     setmapDataGroupByDate(map);
 
 
                     const keys = Array.from(mapDataGroupByDate.keys())
                     if (keys.length > 0) {
                         const tmpActualday = keys[keys.length - 1];
                         setdayselect(tmpActualday);
                     }
 
                     //
                     /*
                     "dosificationCounter": 0, <----------------- Desinfecciones
                     "presenceCounter": 0, <------------------------  Detecciones
                     "batteryLevel": 0, <-------- Siempre será 0, no hay sensores aún 
                     "receivedAt": "2021-04-08T11:40:58.946Z",
                     "uuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
                      *Falta añadir liquido..
                     */
                 } else {
                     console.log("No hay ninguno");
                 }
             })
             .catch(error => {
                 console.log("Error obteniendo datos de postigel");
             });
     }, []);

    return (
        <>
            <div className="titlebatery">
                <div className="titleMultipleStatistics">
                    Batería:
        </div>
                <DayChoose  mapDataGroupByDate={mapDataGroupByDate} changeDay={changeDay}/>
            </div>

            <div>Nivel de Batería:</div>

            <ResponsiveContainer className="baterypopupfather" width="100%" height="100%">
                <AreaChart width={730} height={250} data={data}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <defs>

                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#F1D394" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#F1D394" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" />
                    <YAxis unit="%" orientation="right" />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />

                    <Area type="monotone" dataKey="bateria" stroke="#F1D394" fillOpacity={1} fill="url(#colorPv)" />
                </AreaChart>

            </ResponsiveContainer>




            <div>Actividad</div>
            <ResponsiveContainer className="baterypopupfather" width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={data2}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis unit="%" orientation="right" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Horas" fill="#0080FD" background={{ fill: '#E1F1FA' }} />
               
                </BarChart>
            </ResponsiveContainer>

        </>
    );
};
