export class TerminalDeviceCalibration {
  terminalDevice;
  inputId;
  inputType;
  pulses;
  valueInUnit;
  unit;

  static parseFromPlainDto(dtoText) {
    const plainObject = JSON.parse(dtoText);
    return TerminalDeviceCalibration.parseFromObjectDto(plainObject);
  }

  static parseFromObjectDto(dtoObject) {
    return Object.setPrototypeOf(
      dtoObject,
      TerminalDeviceCalibration.prototype
    );
  }
}
