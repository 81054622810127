import React, { useCallback, useEffect, useState, useRef } from "react";
import { RemainingBarPrograms } from "../../../../../Airframe/RemainingBar/RemainingBarPrograms";
import { DrapDropView } from "../../components/DragDrop/DrapDropView";

export const DragDropInfraPrograms = (props) => {
  const {
    program,
    itemstoReorganized,
    InfraProgramsFiltered,
    typeofCard,
    deleteInfraprogram,
    editInfraprogram,
    showInfo,
    valuebar,
    subroutine,
    infraprograms,
    hours,
    minutes
  } = props;



  return (
    <div className="InfraProgramsView">
      {/* <div>Programas de tipo : {categoryActual?.description}</div>*/}
     
      <RemainingBarPrograms valuebar={valuebar} hours={hours} minutes={minutes}/>
      <DrapDropView
        typeofCard={typeofCard}
        reorderedError={false}
        listElements={InfraProgramsFiltered}
        //subprograms={(TerminalDeviceProgram.parseFromObjectDto({ program: 1 }), TerminalDeviceProgram.parseFromObjectDto({ program: 2 }))}
        alldata={program}
        actionOnDeletep={deleteInfraprogram}
        actionOnEdit={editInfraprogram}
        itemstoReorganized={itemstoReorganized}
        focusOnIndex={0}
        Info={showInfo}
        showMessageNotEdit={"Error infraprogramas"}
        subroutineinfo={subroutine}
        infraprogramsTotals={infraprograms}
        typeDragDrop={"Infraprogram"}
      />
    </div>
  );
};
