import React, { useState, useCallback, useMemo } from "react";
import "./Principal_CardView.sass";
import { ReactComponent as ExclamationSvg } from "./exclamation.svg";
import Popup from "reactjs-popup";
import { ProgramsCard } from "../../ProgramsView/ProgramsCard";
import { TerminalDeviceSensorsView } from "../../SensorsView/TerminalDeviceSensorsView";
import { DatetimeTriggersSummaryView } from "../../DatetimeTriggers/DatetimeTriggersSummaryView";
import { TerminalDeviceOutputsCardsView } from "../../OutputsView/TerminalDeviceOutputsCardsView";
import { isTerminalDeviceValidForDatetimeTriggers } from "../../Fichas/Triggers/TerminalDeviceTriggerChecker";
import { OutputTerminalDevices } from "../FatherOutputsMessagesMQTT";
import { projectsConstants } from "../../../../../_constants/projects.constants";

export const PrincipalCardView = (props) => {
  const {
    OffOuputAlls,
    OnOuputAlls,
    DangerIcoOffOutputs,
    terminalDevice,
    goToProgram
  } = props;
  const [showInfo, setshowInfo] = useState(false);
  
  const allOutputsActionDisabled = useMemo(() => {
    if(terminalDevice?.outputs instanceof Array){
      return terminalDevice
        .outputs
        .some(output => output?.currentState === projectsConstants.global.states.output_transition);
    }

    return true;
  }, [terminalDevice])

  const CloseInfo = useCallback(() => {
    setshowInfo(false);
  }, []);

  const getAllOutputsActionsComponent = useCallback(() => {
    return <div className="Buttons">
    <div className="ButtonOffAll" onClick={(e) => !allOutputsActionDisabled && OnOuputAlls()} disabled={allOutputsActionDisabled}>
      Encender todas
    </div>
    <div className="ButtonOffAll Secondary" onClick={(e) => !allOutputsActionDisabled && OffOuputAlls()} disabled={allOutputsActionDisabled}>
      Apagar todas
    </div>
  </div>
  }, [allOutputsActionDisabled, OffOuputAlls, OnOuputAlls])

  return (
    <>
      {showInfo ? (
        <Popup open={showInfo} closeOnDocumentClick onClose={CloseInfo}>
          <div>
            <a className="close" onClick={e => CloseInfo()}>
              &times;
            </a>
            <div className="subhelptitle">
           
                Haz click en los botones para apagar todas las salidas o
                encenderlas a la vez. Es útil para realizar pruebas!
     
            </div>
          </div>
        </Popup>
      ) : (
        <></>
      )}
      <div className="ContentCard">
        <div className="NotificationTerminalDevices">
          {terminalDevice.isInMaintenance() && (
            <div className="MessageinMaintenance">
              <DangerIcoOffOutputs
                className="MessageinMaintenanceChild"
                fill="red"
                stroke="red"
              />
            </div>
          )}
          <TerminalDeviceSensorsView terminalDevice={terminalDevice}/>
          <hr className="Barra" />
          <TerminalDeviceOutputsCardsView terminalDevice={terminalDevice} />
        {isTerminalDeviceValidForDatetimeTriggers(terminalDevice) 
          ? <DatetimeTriggersSummaryView terminalDevice={terminalDevice} onTriggerClick={goToProgram}/> 
          : <ProgramsCard terminalDevice={terminalDevice} goToProgram={goToProgram}/>
        }
        
          <hr className="Barra" />

          <div className="subtitleapp">Salidas</div>

          <OutputTerminalDevices />
        </div>
        
        {/** El boton de apagar todas va fuera del div. */}
        <div className="Buttonsf">
          <div className="Buttons">
           {getAllOutputsActionsComponent()}
          </div>
          <div>
            <ExclamationSvg
              className="ButtonProgram Info"
              onClick={(e) => setshowInfo(true)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
