import React, { useEffect, useCallback, useState } from "react";
import { SliderC } from "./../../../../Airframe/Slider/Slider";

export const ModeContinuo = (props) => {
  const { pwm: initValue, setPwm: setParentPwm } = props;
  const [pwm, setPwm] = useState(initValue);

  useEffect(() => {
    setPwm(initValue);
  }, [initValue]);

  const ValueSlider = useCallback((value) => {
    setPwm(value);
    setParentPwm(value);
  }, [pwm,setParentPwm]);

  return (
    <>
      <div className="Title_Descripction marginpulses">Activación continua: {pwm}%</div>
      <SliderC onChange={ValueSlider} value={pwm} enabled={true} min={0}/>
    </>
  );
};
