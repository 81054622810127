import React, { useEffect, useState, useCallback } from "react";
import "./ProgramsCard.sass";
import PropTypes from "prop-types"
import { TerminalDevice } from "../../../../classes/TerminalDevice/TerminalDevice";
import { usePrograms } from "../../../../_services/programs.service";
import { Spinner } from "../../Airframe/Spinner/Spinner";
import { ProgramInfoCard } from "./ProgramInfoCard";
import Masonry from 'react-masonry-css'
import "./Mansory.css";

export const ProgramsCard = (props) => {

  const { terminalDevice, goToProgram } = props;
  const [terminalDeviceId, setTerminalDeviceId] = useState(terminalDevice?.id);
  const programsService = usePrograms();

  const states = {
    FETCH_DATA: "FETCH_DATA",
    FETCHING_DATA: "FETCHING_DATA",
    FETCH_DATA_DONE: "FETCH_DATA_DONE",
    NO_PROGRAMS_FOR_TODAY: "NO_PROGRAMS_FOR_TODAY",
    IDLE: "IDLE"
  }
  const errorStates = {
    NONE: "NONE",
    FETCH_ERROR: "FETCH_ERROR"
  }
  const getInitStateMachine = useCallback(() => { return { state: states.FETCH_DATA } }, []);
  const [stateMachine, setStateMachine] = useState(getInitStateMachine())
  const [errorStateMachine, setErrorStateMachine] = useState({ error: errorStates.NONE });

  //#region  Fetch

  const getPrograms = useCallback(() => {
    //if(terminalDevice.programs === undefined){
    programsService.getAll(terminalDevice.terminal, terminalDevice.id).then(
      (fetchPrograms) => {
        terminalDevice.programs = fetchPrograms;
        setStateMachine(stateMachine => { return { ...stateMachine, state: states.FETCH_DATA_DONE } });
      },
      (error) => {
        setErrorStateMachine(errorStateMachine => { return { ...errorStateMachine, error: errorStates.FETCH_ERROR } });
      }
    );
    //}
  }, [programsService, errorStates, terminalDevice]);

  //#endregion

  //#region useEffects
  useEffect(() => {
    switch (stateMachine.state) {
      case states.FETCH_DATA:
        getPrograms();
        setStateMachine({ state: states.FETCHING_DATA });
        break;
      case states.FETCHING_DATA:
        break;
      case states.FETCH_DATA_DONE:
        break;
      case states.NO_PROGRAMS_FOR_TODAY:
        break;
      case states.IDLE:
      default:
        break
    }
  }, [stateMachine]);

  useEffect(() => {
    if (terminalDevice.id !== terminalDeviceId) {
      setTerminalDeviceId(terminalDevice.id);
      setStateMachine(getInitStateMachine());
    }
  }, [terminalDevice.id])
  //#endregion

  const getProgramsCards = useCallback(() => {
    if (terminalDevice && terminalDevice.programs) {
      const components = [];
      let programsForToday = false;
      terminalDevice.programs.forEach((program, index) => {
        if (program.mustRunToday() && program?.subprograms && program.subprograms instanceof Array) {
          components.push(
            <ProgramInfoCard key={`programInfoCard-${index}`} program={program} subprograms={program.subprograms} goToProgram={goToProgram} />
          );
          programsForToday = true;
        }
      })
      if (!programsForToday) {
        setStateMachine(stateMachine => { return { ...stateMachine, state: states.NO_PROGRAMS_FOR_TODAY } });
      }
      return components;
    }
    return <></>;
  }, [terminalDevice.programs, states]);

  return (
    <>
      {stateMachine.state === states.NO_PROGRAMS_FOR_TODAY ? <></> :
        <div className="ProgramsCardFathertitle ">
          <div>
            Programas para hoy:
        </div>
          {stateMachine.state === states.FETCHING_DATA && <Spinner size="25px"></Spinner>}
          {errorStateMachine.state === errorStates.FETCH_ERROR && <div>Error cargando los programas</div>}
       {/* clase padre para controlar a la izquierda si solo hay un programa */}
       <div className="FatherMansory">
          <Masonry
            breakpointCols={2}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
              {stateMachine.state === states.FETCH_DATA_DONE && getProgramsCards()}
          </Masonry>
        </div>
        </div>
      }
    </>
  );
};

ProgramsCard.propTypes = {
  terminalDevice: PropTypes.instanceOf(TerminalDevice)
}
