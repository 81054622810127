import React, { useState, useEffect, useCallback } from "react";
import { RemainingBar } from "../../../components/Airframe/RemainingBar/RemainingBar";
import "./Cardpostigel.sass";
import { ReactComponent as IcoDanger } from "../images/danger.svg";
import { ReactComponent as IcoAlarm } from "../images/campana.svg";
import { ReactComponent as IcoHand } from "../images/hand.svg";
import { Input } from "reactstrap";
import { InputGroupwithid } from "../../../components/Airframe/InputGroup/InputGroupwithid";
export const Cardpostigel = (props) => {
  const { title, value, id, icon, symbol, showgraphicBatery } = props

  //vars conf alarm:
  const [viewcardConfAlarm, setviewcardConfAlarm] = useState(false);
  const [minValuetoAlarm, setminValuetoAlarm] = useState(20);
  const [showErrorsetAlarm, setshowErrorsetAlarm] = useState(false);


  const idWithAlarm = "fluid";
  const idWithStadisticsBatery = "batery";

  //Configura alarma haciendo click en la tarjeta de info gel:
  const ClickInCard = useCallback(() => {
    if (id === idWithAlarm) {
      setviewcardConfAlarm(true);
    }

    if (id === idWithStadisticsBatery) {
      showgraphicBatery();
    }

  }, [viewcardConfAlarm]);


  const setAlarm = useCallback((newvalue) => {
    setminValuetoAlarm(newvalue);
    if (newvalue > 100 || newvalue < 0) {
      setshowErrorsetAlarm(true);
    };
  }, [minValuetoAlarm]);

  const confAlarmClose = useCallback(() => {
    setviewcardConfAlarm(false);
  }, [viewcardConfAlarm]);

  const saveAlarm = useCallback(() => {
    setviewcardConfAlarm(false);
  }, [viewcardConfAlarm]);




  return (
    <>
      <div className={(id === "fluid" || id === "detection") ? "cardPostigelf" : "cardPostigel"}>




        {!viewcardConfAlarm ? <div className="cardPostigelChildren" onClick={(e) => ClickInCard()} >

          <div className="dataCardpostigel">

            <div className="cardPostigelIco">
              {icon}
            </div>

            <div className="descriptioncardpostigel">
              <div className="descriptioncardpostigelc">
                <div> {title}</div>
                <div className="valueCardPostigel"> {value} {symbol}</div>
              </div>

              {(id === "fluid" || id === "batery") && <>

                <div className="barprogressCardpostigel"><RemainingBar
                  //className="BarraProgress FullWidth"
                  className="BarritaTerminals"
                  color="successPostigel"
                  value={value}
                  colorrestbar={"colorpostigelnormal"} /></div>
                <div className="IndicatorClickPostigel">  <IcoHand className="cardPostigelClickIco" /></div>
              </>}
            </div>


          </div>

          {(id === "fluid" && value === 0) && <div className="noticePostigel"> <IcoDanger className="noticeIco" /> <div>Cambiar gel</div> </div>}
        </div>
          : <div className="fatherAlarm">
            <div className="Alarmacard">
              <div className="iconAlarmPostigel">
                <IcoAlarm className="cardPostigelAlarmIco" />
              </div>
              <div className="textAlarmcardPosti">
                Configurando alarma:
              </div>
              <div className="closeAlarmPostigel" onClick={(e) => confAlarmClose()}>
                <div className="fa fa-fw fa-close icoDeletePostigel" />
              </div>

            </div>

            <div className="InputAlarmcardPosti" >
              {showErrorsetAlarm && <div className="Error">
                Error, debe ser entre 0 y 100%
              </div>}

              <div className="InputAlarmcardPostiC">
                <InputGroupwithid
                  foco={true}
                  type="number"
                  placeholder={title}
                  value={minValuetoAlarm}
                  className="bg-white inputpostigel"
                  tabIndex={-1}
                  id={"confAlarmPostigel"}
                  symbol="%"
                  onChange={setAlarm}
                  min={0}
                  max={100} />
              </div>
              <div className="postigelcenterButton">
                <div className="DivButtonsPostigelAlarm">
                  <div
                    className="ButtonC2 smallb Secondary2inf helpsecondary"
                    onClick={(e) => saveAlarm()}
                  >
                    Aceptar  </div>
                </div>
              </div>
            </div>
          </div>}
      </div>
    </>
  );
};
