import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";

export const options = {
  one: "ONE",
  two: "TWO",
};

export const TwoOptionsBox = (props) => {
  const types = {
    right: "right",
    left: "left",
    principal: "select",
  };

  const {
    optionOneContent,
    optionTwoContent,
    optionOneTitle,
    optionTwoTitle,
    optionSelected
  } = props;

  const initState = {
    selectedOption: optionSelected || options.one,
  };
  const [state, setState] = useState(initState);
  

  const onOptionOneClick = useCallback(() => {
    setState((state) => {
      return { ...state, selectedOption: options.one };
    });
  }, []);

  const onOptiontwoClick = useCallback(() => {
    setState((state) => {
      return { ...state, selectedOption: options.two };
    });
  }, []);

  return (
    <>
      <div className="OptionsIrrigationf">
        <div
          className={`IrriElement left ${
            state.selectedOption === options.one ? types.principal : types.left
          }`}
          onClick={(e) => onOptionOneClick()}
        >
          {optionOneTitle}
        </div>
        <div
          className={`IrriElement right ${
            state.selectedOption === options.two ? types.principal : types.right
          }`}
          onClick={(e) => onOptiontwoClick()}
        >
          {optionTwoTitle}
        </div>
      </div>

      <div className="twoOptions">
        {state.selectedOption === options.one
          ? optionOneContent
          : optionTwoContent}
      </div>
    </>
  );
};

TwoOptionsBox.propTypes = {
  optionOneContent: PropTypes.element.isRequired,
  optionTwoContent: PropTypes.element.isRequired,
  optionOneTitle: PropTypes.element.isRequired,
  optionTwoTitle: PropTypes.element.isRequired,
  optionSelected: PropTypes.string
};
