import React, { useCallback } from "react";
import { DeleteTriggersMQTT } from "../MQTT/triggers/DeleteTriggersMQTT";
import { SetTriggersMQTT } from "../MQTT/triggers/SetTriggersMQTT";
import _ from "lodash";

export const TerminalDeviceTriggerStatesHandler = (props) => {
  const { triggers, onStateReceived } = props;

  const processSetTriggerIds = useCallback(
    (triggerIds, isDeleted) => {
      if (triggers instanceof Array && triggerIds instanceof Array) {
       
        let updated = false;
        triggerIds.forEach(triggerId => {
            triggers
            .filter(trigger => trigger?.id === triggerId)
            .forEach(trigger => {
                trigger.notified = true;
                trigger.deletedByUser = isDeleted;
                updated = true;
            })
        })
        if(updated){
            onStateReceived(_.cloneDeep(triggers));
        }
      }
    },
    [onStateReceived, triggers]
  );

  const onSetTriggersMqttMessage = useCallback((topic, message) => {
    //const terminalId = message?.data?.terminal_id;
    const success = message?.data?.success;
    const triggerIds = message?.data?.trigger_ids;
    const triggerIdsActive = message?.data?.trigger_ids_active;
    if (success) {
      if (triggerIds instanceof Array) {
        processSetTriggerIds(triggerIds, false);
      }
      if (triggerIdsActive instanceof Array) {
        processSetTriggerIds(triggerIdsActive, false);
      }
    }
  }, [processSetTriggerIds]);

  const onDeleteTriggersMqttMessage = useCallback((topic, message) => {
    const success = message?.data?.success;
    const triggerIds = message?.data?.trigger_ids;
    const triggerIdsActive = message?.data?.trigger_ids_active;
    if (success) {
      if (triggerIds instanceof Array) {
        processSetTriggerIds(triggerIds, true);
      }
      if (triggerIdsActive instanceof Array) {
        processSetTriggerIds(triggerIdsActive, true);
      }
    }
  }, [processSetTriggerIds]);

  const voidMethod = useCallback((topic, message) => {}, []);

  return (
    <>
      <SetTriggersMQTT
        processMsg={onSetTriggersMqttMessage}
        processErrorMsg={voidMethod}
      />
      <DeleteTriggersMQTT
        processMsg={onDeleteTriggersMqttMessage}
        processErrorMsg={voidMethod}
      />
    </>
  );
};
