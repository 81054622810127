import React from "react";
import { TerminalDeviceSensorStats } from "../TerminalDevices/Components/TerminalDeviceSensorStats";

export const TerminalDeviceSensorsStats = (props) => {
  const { terminalDevice } = props;

  return (
    <div className="ContentCard">
      <div className="NotificationTerminalDevices">
        <hr className="Barra" />

        <div className="subtitleapp">Datos sensores:</div>

        {terminalDevice?.sensors instanceof Array &&
          terminalDevice.sensors
            .filter((terminalDeviceSensor) => terminalDeviceSensor?.active)
            .map((terminalDeviceSensor) => (
              <div style={{width: "100%"}} key={`${terminalDeviceSensor?.sensorId?.id}-${terminalDeviceSensor?.sensorIndex}`}>
                <h5 style={{ textAlign: "center" }}>{`Sensor ${
                  terminalDeviceSensor?.sensorIndex + 1
                } ${terminalDeviceSensor?.description || ""}`}</h5>
                <TerminalDeviceSensorStats
                  sensor={terminalDeviceSensor}
                ></TerminalDeviceSensorStats>
              </div>
            ))}
        {(!terminalDevice?.sensors ||
          terminalDevice.sensors.filter(
            (terminalDeviceSensor) => terminalDeviceSensor?.active
          ).length === 0) && (
          <div className="subtitleapp">No dispone de sensores activos</div>
        )}


        <hr className="Barra" />

        <div className="subtitleapp">Datos salidas:</div>

        {terminalDevice?.outputs instanceof Array &&
          terminalDevice.outputs
            .filter((output) => output?.reportIntervalInSeconds > 0 || output?.digitalSensor?.terminalDevice > 0)
            .map((output) => (
              <div style={{width: "100%"}} key={output?.output}>
                <h5 style={{ textAlign: "center" }}>{`Salida ${
                  output?.output
                } ${output?.description || ""}`}</h5>
                <TerminalDeviceSensorStats
                  output={output}
                ></TerminalDeviceSensorStats>
              </div>
            ))}
        {(!terminalDevice?.outputs ||
          terminalDevice.outputs.filter(
            (output) => output?.reportIntervalInSeconds > 0
          ).length === 0) && (
          <div className="subtitleapp">No dispone de salidas calibradas</div>
        )}
      </div>
    </div>
  );
};
