import { SensorMeasurement } from "./SensorMeasurement";

export class Sensor {
    id;
	name;
	description;
	physicalCommunicationType;
	measurements;
    analogInputType;

    static parseFromPlainDto(dtoText){
        const plainObject = JSON.parse(dtoText);
        return Sensor.parseOutputDtoFromObject(plainObject);
    }

    static parseFromObjectDto(dtoObject){
        if(dtoObject){
            const sensor = Object.setPrototypeOf(dtoObject, Sensor.prototype);

            if(dtoObject.measurements instanceof Object){
                sensor.measurements = dtoObject.measurements.map(measurement => SensorMeasurement.parseFromObjectDto(measurement));
            }

            return sensor;
        }
        return dtoObject;
    }
}