import React, { useState, useCallback, useRef, useEffect } from "react";
import { Input } from "reactstrap";
import FormUploadSass from "./FormUpload.sass";
import "./Images.sass";
import { FileUploader, uploadTargetEntities } from "../../File/FileUploader";

export const FormUpload = (props) => {
  const { nameProps, targetEntity, uploadEntityIds, onUpdate } = props;
  const [name, setname] = useState(nameProps);
  const focusName = useRef(null);

  useEffect(() => {
    focusName.current.focus();
  }, []);

  const onImageUpdate = useCallback(
    (file, orientation) => {
      onUpdate(name, file, orientation);
    },
    [name, onUpdate]
  );

  const onNameUpdate = useCallback(
    (name) => {
      setname(name);
      onUpdate(name, undefined);
    },
    [onUpdate]
  );

  return (
    <div className="PanelPopUp">
      <div>
        <div className="titleapp2">Cambiar datos:</div>

        {/* Content */}

        <div className="formInputs">
          <div className="subtitleapp2"> Nombre:</div>
          <Input
            type="text"
            name="nameUpload"
            id="nameUpload"
            placeholder={nameProps}
            className="InputForm subtitleapp2"
            onChange={(e) => onNameUpdate(e.target.value)}
            value={name}
            ref={focusName}
          />
        </div>

        <div className="titleapp2">Sube una foto:</div>
        <FileUploader
          targetEntity={targetEntity}
          uploadEntityIds={uploadEntityIds}
          onUpdate={onImageUpdate}
        />

        {/* -------- */}
      </div>
    </div>
  );
};
