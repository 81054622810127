import React, { useState, useEffect, useCallback } from "react";
import { OptionsPrePos } from "../../../../components/TerminalDevices/Fichas/Programs/components/DragDrop/OptionsPrePos";
import { RecipientRechart } from "../RecipientRechart";

import "./resetPostigel.sass";
export const ResetPostigel = (props) => {

    const { dataContainer, liquid, closePopUp } = props;
    const [changeclassBig, setchangeclassBig] = useState(false);
    const [changeclassSmall, setchangeclassSmall] = useState(false);
    const [errorChoose, seterrorChoose] = useState(false);
    const [errorNotSizeInLoad, seterrorNotSizeInLoad] = useState(false);

    const [liquidActualSmall, setliquidActualSmall] = useState(liquid);
    const initialValueLiquidBig = 1;
    const [liquidActualBig, setliquidActualBig] = useState(initialValueLiquidBig);

    const sizesCentilitres = {
        small: 1000,
        big: 5000,
    }

    useEffect(() => {

        if (dataContainer.capacity === sizesCentilitres.big) {
            setliquidActualBig((liquid / 100) * (sizesCentilitres.big / 1000));
        } else if (dataContainer.capacity === sizesCentilitres.small) {
            setliquidActualSmall((liquid / 100) * (sizesCentilitres.small / 1000));
        }

        if (dataContainer === undefined) {
            seterrorNotSizeInLoad(true);
        } else {

            if (dataContainer.capacity === sizesCentilitres.big) {
                setchangeclassBig(true);
            } else if (dataContainer.capacity === sizesCentilitres.small) {
                setchangeclassSmall(true);
            }
        }
    }, []);

    const options = {
        small: "1",
        big: "5",
    }

    const smallData = [
        {
            name: 'liquido',
            //desde donde comienza, valor actual y valor fin-->
            liquido: [0, liquidActualSmall - 0.1, liquidActualSmall],
        },
    ];



    const bigData = [
        {
            name: 'liquido',
            //desde donde comienza, valor actual y valor fin-->
            liquido: [0, liquidActualBig],
        },
    ];

    const reset = useCallback(() => {
        console.log("Colocando Liquido");
        if (changeclassSmall === false && changeclassBig === false) {
            seterrorChoose(true);
        } else {
            seterrorChoose(false);
            seterrorNotSizeInLoad(false);
            closePopUp();
            //reiniciando
        }
    }, [changeclassSmall, changeclassBig]);

    const choose = useCallback((size) => {

        if (size === options.big) {

            setliquidActualSmall(0);
            setliquidActualBig(sizesCentilitres.big);
            setchangeclassBig(true);
            setchangeclassSmall(false);

        } else if (size === options.small) {
            setliquidActualSmall(sizesCentilitres.small);
            setliquidActualBig(initialValueLiquidBig);
            setchangeclassSmall(true);
            setchangeclassBig(false);
        } else {
            console.log("Error en tamaños reset postigel");
        }
    }, [liquidActualSmall, liquidActualBig]);


    return (
        <>
            <div className="titleResetPostigel">Elige una medida:</div>
            <div className="chooseSizeReset">

                <div className={!changeclassBig ? "cardReset" : "cardResetChoose"} onClick={(e) => choose(options.big)}>

                    <div className="RecipientResetPostigel">
                        <RecipientRechart data={bigData} right={true} maxvalue={5} />
                    </div>
                    <div className="textSizeResetPostigel">
                        Grande - 5L
                    </div>

                </div>
                <div className={!changeclassSmall ? "cardReset" : "cardResetChoose"} onClick={(e) => choose(options.small)}>

                    <div className="RecipientResetPostigelsmall">
                        <RecipientRechart data={smallData} right={true} maxvalue={1} />
                    </div>
                    <div className="textSizeResetPostigel">
                        Pequeño - 1L
                    </div>
                </div>
            </div>

            {!errorNotSizeInLoad && errorChoose && <div className="Error margin2postigel">Por favor, elige un tamaño.</div>}

            {!errorNotSizeInLoad && <div className="postigelcenterButton">
                <div className="DivButtonsPostigelAlarm">
                    <div
                        className="ButtonC2 smallb Secondary2inf helpsecondary"
                        onClick={(e) => reset()}
                    >
                        Colocar  </div>
                </div>
            </div>}


            {errorNotSizeInLoad && <div className="postigelcenterButton">
                <div className="DivButtonsPostigelAlarm">
                    <div
                        className="ButtonC2 smallb Secondary2inf helpsecondary"
                        onClick={(e) => reset()}
                    >
                        Nuevo  </div>
                </div>
            </div>}
        </>
    );
};
