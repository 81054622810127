import React, { useState, useCallback } from "react";
import { InputGroupwithid } from "../../../../components/Airframe/InputGroup/InputGroupwithid";

export const InputCalibration = (props) => {
    const { name, onChange, onChangeParams } = props;
    const [value, setvalue] = useState(0);

    const changeInput1 = useCallback((actualvalue) => {
        if (isNaN(actualvalue) || actualvalue < 0) {
            actualvalue = 0;
        }
        actualvalue = Math.round(actualvalue * 100) / 100
        setvalue(actualvalue);
        onChange(actualvalue, ...onChangeParams);
    }, [onChange]);
    return (
        <div className="inputCalibrate">
            <div className="subhelptitle titlecalibrateinput">
                {name}
            </div>
            <div className="InputCalibratePosti">

                <InputGroupwithid
                    foco={true}
                    type="number"
                    placeholder={"cl"}
                    value={value}
                    className="bg-white inputpostigel"
                    tabIndex={-1}
                    id={"confAlarmPostigel"}
                    symbol="Cl"
                    onChange={changeInput1}
                    min={0}
                    max={100} />
            </div>

        </div>

    );
};
