import React from "react";
import { FirstLastPulses } from "./FirstLastPulses";

export const ModePulses = (props) => {
  return <FirstLastPulses 
  startPulsesLael={"Arranque"}
  stopPulsesLabel={"Parada"}
  startPulsesValue={props.start}
  stopPulsesValue={props.stop}
  onStartPulsesChange={props.setStart}
  onStopPulsesChange={props.setStop}
  foco={props.selectedOutputFocus}
  />
};
