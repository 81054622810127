import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { projectsConstants } from "../../../../_constants/projects.constants";
import { OutputsView } from "./OutputsView";
import { TerminalDevice } from "../../../../classes/TerminalDevice/TerminalDevice";

export const TerminalDeviceOutputsView = (props) => {
  const colorUnselected = "rgb(147, 18, 21)";
  const colorSelected = "rgb(27,185,52)";
  const colorTransition = "#6C757D";

  const {
    outputs,
    onClickOnDisabled,
    onClick,
    forcedMapStates,
    forcedSelectedColor,
    forcedUnselectedColor,
    numberhiearchyactual,
    outputsModified,
    showInfoOutputModified,
    mustUseLongPress
  } = props;

  const {
    output_active: OUTPUT_ACTIVE,
    output_deactivated: OUTPUT_DEACTIVATED,
    output_transition: OUTPUT_TRANSITION,
  } = projectsConstants.global.states;

  const showOutputModifiedInToolsFromView = useCallback(
    (output) => {
      if (outputsModified.has(output)) {
        showInfoOutputModified({ output, ...outputsModified.get(output) });
      }
    },
    [outputsModified, showInfoOutputModified]
  );

  //#region RENDER SALIDAS
  /************ PREPARACION COMPONENTE PARA LA SALIDAS */

  const getColorSelected = useCallback(() => {
    return forcedSelectedColor || colorSelected;
  }, [forcedSelectedColor]);
  const getColorUnselected = useCallback(() => {
    return forcedUnselectedColor || colorUnselected;
  }, [forcedUnselectedColor]);
  const getOutputState = useCallback(
    (output) => {
      if (forcedMapStates && forcedMapStates instanceof Map) {
        if (
          forcedMapStates.has(output.output) &&
          forcedMapStates.get(output.output)
        ) {
          return OUTPUT_ACTIVE;
        } else {
          return OUTPUT_DEACTIVATED;
        }
      } else {
        return output.currentState;
      }
    },
    [OUTPUT_ACTIVE, OUTPUT_DEACTIVATED, forcedMapStates]
  );

  const getColorLed = useCallback(
    (output) => {
      const outputState = getOutputState(output);
      return outputState === OUTPUT_ACTIVE
        ? getColorSelected()
        : outputState === OUTPUT_TRANSITION
        ? colorTransition
        : getColorUnselected();
    },
    [
      OUTPUT_ACTIVE,
      OUTPUT_TRANSITION,
      getColorSelected,
      getColorUnselected,
      getOutputState,
    ]
  );

  const onOutputViewClick = useCallback(
    (output) => {
      outputs.forEach((outputObject, index) => {
        if (outputObject.output === output) {
          onClick(outputObject.terminalDevice, output, index);
        }
      });
    },
    [outputs, onClick]
  );

  const onOutputViewClickOnDisabled = useCallback(
    (output) => {
      if (onClickOnDisabled) {
        outputs.forEach((outputObject, index) => {
          if (outputObject.output === output) {
            onClickOnDisabled(outputObject);
          }
        });
      }
    },
    [outputs, onClickOnDisabled]
  );

  const getOutput = useCallback(
    (output, index, disabled, half) => {
      let OutputModifiedToChild = false;
      if (outputsModified instanceof Map) {
        OutputModifiedToChild = outputsModified.has(output.output);
      }

      return (
        <>
          {/* Si salida esta modificada cambia el click de general a solo una parte para poder activar el icono de i de su interior */}
          <>
            <div
              className={`${index < half ? "Output2" : "Output"} ${
                disabled && "notpointer"
              } ${OutputModifiedToChild && "outputModified"}`}
              key={`div-output-${output.terminalDevice}-${output.output}`}
            >
              <OutputsView
                key={output.terminalDevice + "-" + output.output}
                output={output}
                disabled={disabled}
                colorLed={getColorLed(output)}
                showpill={true}
                outputModified={OutputModifiedToChild}
                onClickOnDisabled={onOutputViewClickOnDisabled}
                onClick={onOutputViewClick}
                showInfoOutputModified={showOutputModifiedInToolsFromView}
                className={`outputClick ${index >= half && "reverse"}`}
                mustUseLongPress={mustUseLongPress}
              />
            </div>
          </>
        </>
      );
    },
    [
      outputsModified,
      getColorLed,
      onOutputViewClickOnDisabled,
      onOutputViewClick,
      showOutputModifiedInToolsFromView,
      mustUseLongPress
    ]
  );

  const prepareOutputs = useCallback(() => {
    let polarity = 0;
    const half = Math.round(outputs.length / 2);

    const components = outputs
      .sort((first, second) => {
        if (first?.output < second?.output) {
          return -1;
        }
        if (first?.output > second?.output) {
          return 1;
        }
        return 0;
      })
      .filter((output) => {
        if (numberhiearchyactual !== undefined) {
          return output?.outputType?.hierarchyLevel === numberhiearchyactual;
        } else {
          return true;
        }
      })
      .map((output, i) => {
        let disabled = false;

        if (i >= half) {
          let pair = i - half;
          if ((polarity & (1 << pair)) > 0) {
            disabled = true;
          }
        } else {
          polarity |=
            (output.getActivationType() ===
            projectsConstants.global.activations.pulses_with_polarity
              ? 1
              : 0) << i;
        }
        return getOutput(output, i, disabled, half);
      });

    let groupComponents = [];
    const rest = components.length % 2;
    for (let i = 0; i < half; i++) {
      const first = components[i];
      const second = components[i + half];
      groupComponents.push(
        <div key={i} className="filaSalidas">
          {first}
          {second}
        </div>
      );
    }

    if (rest > 0) {
      const leftElement = components[components.length];
      groupComponents.push(
        <div key={groupComponents.length} className="filaSalidas2">
          {leftElement}
        </div>
      );
    }
    return <div className="outputsT">{groupComponents}</div>;
  }, [outputs, numberhiearchyactual, getOutput]);

  //#endregion
  return <>{prepareOutputs()}</>;
};

TerminalDeviceOutputsView.propTypes = {
  onClickOnDisabled: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  terminalDevice: PropTypes.instanceOf(TerminalDevice),
  /*outputs: PropTypes.arrayOf(
    PropTypes.shape({
      output: PropTypes.number.isRequired,
      currentState: PropTypes.string.isRequired,
      terminalDevice: PropTypes.number.isRequired,
    })
  ),*/
  onMqttResponseActoutput: PropTypes.func,
  onMqttResponseEndoutput: PropTypes.func,
  forcedMapStates: PropTypes.object,
  forcedSelectedColor: PropTypes.string,
  forcedUnselectedColor: PropTypes.string,
};
