import { projectsConstants } from "../../../../../_constants/projects.constants";
import {
  isTerminalDeviceMMSValidFor,
  isTerminalDeviceSIOValidFor,
} from "../../VersionChecker";

// TRIGGERS
const isTerminalDeviceMMS_ValidForTriggers = (terminalDevice) =>
  isTerminalDeviceMMSValidFor(
    terminalDevice,
    projectsConstants.master_outputs.versions.MMS_HW1_MIN_TRIGGERS,
    projectsConstants.master_outputs.versions.MMS_HW3_MIN_TRIGGERS
  );

const isTerminalDeviceSIO_ValidForTriggers = (terminalDevice) =>
  isTerminalDeviceSIOValidFor(
    terminalDevice,
    projectsConstants.master_outputs.versions.SIO_HW1_MIN_TRIGGERS,
    projectsConstants.master_outputs.versions.SIO_HW3_MIN_TRIGGERS
  );

export const isTerminalDeviceValidForTriggers = (terminalDevice) => {
  return (
    isTerminalDeviceMMS_ValidForTriggers(terminalDevice) ||
    isTerminalDeviceSIO_ValidForTriggers(terminalDevice)
  );
};

// REPORT TRIGGERS
const isTerminalDeviceMMSValidForReportTriggers = (terminalDevice) =>
  isTerminalDeviceMMSValidFor(
    terminalDevice,
    projectsConstants.master_outputs.versions.MMS_HW1_MIN_REPORT_TRIGGERS,
    projectsConstants.master_outputs.versions.MMS_HW3_MIN_REPORT_TRIGGERS
  );

const isTerminalDeviceSIOValidForReportTriggers = (terminalDevice) =>
  isTerminalDeviceSIOValidFor(
    terminalDevice,
    projectsConstants.master_outputs.versions.SIO_HW1_MIN_REPORT_TRIGGERS,
    projectsConstants.master_outputs.versions.SIO_HW3_MIN_REPORT_TRIGGERS
  );

export const isTerminalDeviceValidForReportTriggers = (terminalDevice) => {
  return (
    isTerminalDeviceMMSValidForReportTriggers(terminalDevice) ||
    isTerminalDeviceSIOValidForReportTriggers(terminalDevice)
  );
};

// DIGITAL SENSOR TRIGGERS
const isTerminalDeviceMMSValidForDigitalSensorTriggers = (terminalDevice) =>
  isTerminalDeviceMMSValidFor(
    terminalDevice,
    projectsConstants.master_outputs.versions.MMS_HW1_MIN_DIGITAL_SENSORS_TRIGGERS,
    projectsConstants.master_outputs.versions.MMS_HW3_MIN_DIGITAL_SENSORS_TRIGGERS
  );

const isTerminalDeviceSIOValidForDigitalSensorTriggers = (terminalDevice) =>
  isTerminalDeviceSIOValidFor(
    terminalDevice,
    projectsConstants.master_outputs.versions.SIO_HW1_MIN_DIGITAL_SENSORS_TRIGGERS,
    projectsConstants.master_outputs.versions.SIO_HW3_MIN_DIGITAL_SENSORS_TRIGGERS
  );

export const isTerminalDeviceValidForDigitalSensorsTriggers = (
  terminalDevice
) => {
  return (
    isTerminalDeviceMMSValidForDigitalSensorTriggers(terminalDevice) ||
    isTerminalDeviceSIOValidForDigitalSensorTriggers(terminalDevice)
  );
};

// DATETIME TRIGGERS

const isTerminalDeviceMMSValidForDateTimeTriggers = (terminalDevice) =>
  isTerminalDeviceMMSValidFor(
    terminalDevice,
    projectsConstants.master_outputs.versions.MMS_HW1_MIN_DATETIME_TRIGGERS,
    projectsConstants.master_outputs.versions.MMS_HW3_MIN_DATETIME_TRIGGERS
  );

const isTerminalDeviceSIOValidForDateTimeTriggers = (terminalDevice) =>
  isTerminalDeviceSIOValidFor(
    terminalDevice,
    projectsConstants.master_outputs.versions.SIO_HW1_MIN_DATETIME_TRIGGERS,
    projectsConstants.master_outputs.versions.SIO_HW3_MIN_DATETIME_TRIGGERS
  );

export const isTerminalDeviceValidForDatetimeTriggers = (terminalDevice) => {
  return (
    isTerminalDeviceMMSValidForDateTimeTriggers(terminalDevice) ||
    isTerminalDeviceSIOValidForDateTimeTriggers(terminalDevice)
  );
};


// DATETIME TRIGGERS WITH SECONDS
const isTerminalDeviceMMSValidForDateTimeTriggersWithSeconds = (terminalDevice) =>
  isTerminalDeviceMMSValidFor(
    terminalDevice,
    projectsConstants.master_outputs.versions.MMS_HW1_MIN_DATETIME_TRIGGERS_WITH_SECONDS,
    projectsConstants.master_outputs.versions.MMS_HW3_MIN_DATETIME_TRIGGERS_WITH_SECONDS
  );

const isTerminalDeviceSIOValidForDateTimeTriggersWithSeconds = (terminalDevice) =>
  isTerminalDeviceSIOValidFor(
    terminalDevice,
    projectsConstants.master_outputs.versions.SIO_HW1_MIN_DATETIME_TRIGGERS_WITH_SECONDS,
    projectsConstants.master_outputs.versions.SIO_HW3_MIN_DATETIME_TRIGGERS_WITH_SECONDS
  );

export const isTerminalDeviceValidForDatetimeTriggersWithSeconds = (terminalDevice) => {
  return (
    isTerminalDeviceMMSValidForDateTimeTriggersWithSeconds(terminalDevice) ||
    isTerminalDeviceSIOValidForDateTimeTriggersWithSeconds(terminalDevice)
  );
};

// DATE TIME TRIGGERS WITH LOOP
const isTerminalDeviceMMSValidForDateTimeTriggersWithLoop = (terminalDevice) =>
  isTerminalDeviceMMSValidFor(
    terminalDevice,
    projectsConstants.master_outputs.versions.MMS_HW1_MIN_DATETIME_TRIGGERS_WITH_LOOP,
    projectsConstants.master_outputs.versions.MMS_HW3_MIN_DATETIME_TRIGGERS_WITH_LOOP
  );

const isTerminalDeviceSIOValidForDateTimeTriggersWithLoop = (terminalDevice) =>
  isTerminalDeviceSIOValidFor(
    terminalDevice,
    projectsConstants.master_outputs.versions.SIO_HW1_MIN_DATETIME_TRIGGERS_WITH_LOOP,
    projectsConstants.master_outputs.versions.SIO_HW3_MIN_DATETIME_TRIGGERS_WITH_LOOP
  );

export const isTerminalDeviceValidForDatetimeTriggersWithLoop = (terminalDevice) => {
  return (
    isTerminalDeviceMMSValidForDateTimeTriggersWithLoop(terminalDevice) ||
    isTerminalDeviceSIOValidForDateTimeTriggersWithLoop(terminalDevice)
  );
};

// DATE TIME TRIGGERS WITH OFFSET IN ACTIVATIONS
const isTerminalDeviceMMSValidForDateTimeTriggersActivationsWithOffset = (terminalDevice) =>
  isTerminalDeviceMMSValidFor(
    terminalDevice,
    projectsConstants.master_outputs.versions.MMS_HW1_MIN_DATETIME_TRIGGERS_ACTIVATIONS_WITH_OFFSET,
    projectsConstants.master_outputs.versions.MMS_HW3_MIN_DATETIME_TRIGGERS_ACTIVATIONS_WITH_OFFSET,
    projectsConstants.master_outputs.versions.MMS_HW5_MIN_DATETIME_TRIGGERS_ACTIVATIONS_WITH_OFFSET
  );

const isTerminalDeviceSIOValidForDateTimeTriggersActivationsWithOffset = (terminalDevice) =>
  isTerminalDeviceSIOValidFor(
    terminalDevice,
    projectsConstants.master_outputs.versions.SIO_HW1_MIN_DATETIME_TRIGGERS_ACTIVATIONS_WITH_OFFSET,
    projectsConstants.master_outputs.versions.SIO_HW3_MIN_DATETIME_TRIGGERS_ACTIVATIONS_WITH_OFFSET
  );

export const isTerminalDeviceValidForDatetimeTriggersActivationsWithOffset = (terminalDevice) => {
  return (
    isTerminalDeviceMMSValidForDateTimeTriggersActivationsWithOffset(terminalDevice) ||
    isTerminalDeviceSIOValidForDateTimeTriggersActivationsWithOffset(terminalDevice)
  );
};