import React, { useCallback, useEffect, useState, useRef } from "react";
import "./DropdownNew.sass";
import useOutsideClick from "./useOutsideClick";
export const DropdownNew = (props) => {
  const { initial, options, ChangeOption } = props;

  const [isOpen, setisOpen] = useState(false);
  const [optionSelect, setoptionSelect] = useState(initial);
  const ref = useRef();


  //Otorga la habilidad de poder cerrar el desplegable
  useOutsideClick(ref, () => {
    if (isOpen) setisOpen(false);
  });

  useEffect(() => {
    setoptionSelect(initial);
  }, [initial]);

  const selectOption = useCallback((position, option) => {
    setoptionSelect(option);
    ChangeOption(position, option);
  }, [ChangeOption]);

  const OpenDropdown = useCallback(
    (select) => {
      setisOpen(!isOpen);
    },
    [isOpen]
  );

  const getOptionsDropdwon = useCallback(() => {
    const optionsDropdown = options.map((option, i) => {
      return (
        <div
          key={i}
          className="icoDropddownMy_Option"
          onClick={(e) => selectOption(i, option)}
        >
          {option.value}
        </div>
      );
    });

    return (
      <div ref={ref} className="icoDropddownMy_Options">
        {optionsDropdown}
      </div>
    );
  }, []);

  return (
    <div
      className={`dropdownMy ${isOpen ? "dropdownMyOpen" : "dropdownMyClose"}`}
      onClick={(e) => OpenDropdown()}
    >
      <div className="icoDropddownMy_placeholder">
        <div>{optionSelect?.value}</div>
        <div className={`icoDropddownMy fa fa-fw  fa-chevron-down`} />
      </div>

      {isOpen && <>{getOptionsDropdwon()}</>}
    </div>
  );
};
