import React from "react";
import Toggle from "react-toggle";
import PropTypes from "prop-types";
import { Spinner } from "reactstrap";

export const ToggleVoltaje = (props) => {
  const { checkedToogle, stateToggle, ActionToggle, voltage, syncing } = props;
  return (
      <div className="toggleVoltage">
        <div className="childCardDevices2">
          <div className="Title_Descripction">Voltaje:</div>
          <div>{voltage}</div>
        </div>
        <div className="TogglewithInfo">
          <div className="DetailsToggle">12V</div>
          <Toggle
            id="cheese-status"
            checked={checkedToogle}
            icons={false}
            disabled={stateToggle}
            onChange={ActionToggle}
            className="toggle"
          />
          <div className="DetailsToggle">24V</div>
          {syncing && <Spinner size={"25px"}></Spinner>}
        </div>
      </div>
  );
};
ToggleVoltaje.propTypes = {
  checkedToogle: PropTypes.bool.isRequired,
  stateToggle: PropTypes.bool.isRequired,
  ActionToggle: PropTypes.func.isRequired,
  voltage: PropTypes.string.isRequired,
  syncing: PropTypes.bool
};
