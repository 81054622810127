import React, { useCallback, useState, useEffect } from "react";
import { ProgramsView } from "../components/Programs/ProgramsView";
//images
import { ReactComponent as DelProgram } from "./../images/delete.svg";
import { ReactComponent as AddProgram } from "./../images/add.svg";

import _ from "lodash";
import { Program } from "./Program";
import { projectsConstants } from "../../../../../../_constants/projects.constants";
import { usePrograms } from "../../../../../../_services/programs.service";
import { usePublisher } from "../../../../../../context/publish-context";
import { MESSAGE_TYPE_ACTION } from "../../../../../../_constants/messageType.constants";

import { ReactComponent as DangerIcoOffOutputs } from "./danger.svg";

import uuid from "uuid/v4";

import { TerminalDeviceProgram } from "../../../../../../classes/TerminalDevice/Program/TerminalDeviceProgram";
import { TerminalDeviceSubprogram } from "../../../../../../classes/TerminalDevice/Program/Subprogram/TerminalDeviceSubprogram";


export const ProgramFather = (props) => {
  //#region Constantes
  const maxTasks = 32; // Limitación dada por la memoria de los dispositivos.
  const [FocusOnProgramIndex, setFocusOnProgramIndex] = useState(0);

  const { publish } = usePublisher();
  const programService = usePrograms();
  const {
    terminalDevice,
    programs,
    CancelScheduledTasks,
    outputs: Outputs,
    back,
    goToProgram,
    urlstate
  } = props;
  const { terminal: terminalId } = terminalDevice;
  const [isApiConsuming, setIsApiConsuming] = useState(false);

  /**
   * Obtiene la estructura base para un objeto de ListPrograms.
   */
  const getNewProgramObject = useCallback((programIndex) => {

    return TerminalDeviceProgram.parseFromObjectDto({
      name: undefined,
      hour: "12",
      minute: "00",
      days: undefined,
      subprograms: undefined,

      index: programIndex,
      new: true,
      modified: false,
      removed: false,
    });
  }, []);

  function getRandomPrograms() {
    return _.times(10, () => {
      return getNewProgramObject(undefined);
    }).map((item, index) => {
      const hour = Math.round(Math.random() * (23 - 0) + 0);
      const minute = Math.round(Math.random() * (59 - 0) + 0);

      item.index = index;
      item.days = Math.round(Math.random() * (127 - 0) + 0);
      item.hour = hour < 10 ? `0${hour}` : hour.toString();
      item.minute = minute < 10 ? `0${minute}` : minute.toString();
      return item;
    });
  }
  const [ListPrograms, setListPrograms] = useState(
    programs && programs instanceof Array && programs.length > 0
      ? programs
      : [getNewProgramObject(0)]
  );
  const [selectedProgram, setselectedProgram] = useState(undefined);
  const [outputTasksToCreateCount, setOutputTasksToCreateCount] = useState(0);
  const [outputTasksMaxSizeReached, setOutputTasksMaxSizeReached] = useState(false );
  //#endregion

  /**
   * Selecciona el primer programa de la lista si antes no se había seleccionado uno.
   */

  const setProgramIfPrevEmpty = useCallback(
    (listPrograms) => {
      if (selectedProgram === undefined && listPrograms.length > 0) {
        console.log(listPrograms[0]);
        setselectedProgram(listPrograms[0]);
      }
    },
    [selectedProgram]
  );

  const getNumberOfExistentTasks = useCallback((programs) => {
    let tasksCount = 0;
    if (programs instanceof Array) {
      programs.forEach((program) => {
        if (program?.subprograms instanceof Array) {
          program.subprograms.forEach((subprogram) => {
            if (subprogram?.outputTasks instanceof Array) {
              tasksCount += subprogram.outputTasks.length;
            }
          });
        }
      });
    }
    setOutputTasksToCreateCount(tasksCount);
    return tasksCount;
  }, []);

  useEffect(() => {
    // Debemos machacar los programas que no sean nuevos
    if (programs !== undefined && programs instanceof Array) {
      const newListPrograms = programs.filter(program => !program?.new);
      ListPrograms.forEach((program, index) => {
        if (program?.new) {
          program.index = newListPrograms.length + index;
          newListPrograms.push(program);
        }
      });
      setListPrograms(newListPrograms);

      if(selectedProgram){
        setselectedProgram(newListPrograms.filter(program => program.id === selectedProgram.id)[0] ?? selectedProgram)
      }

      getNumberOfExistentTasks(newListPrograms);
    }
  }, [programs]);
/*
  useEffect(() => {
    //Debe de ser false al darle al boton de avanzado
    console.log(selectedProgram?.modified);
    setselectedProgram
  }, [selectedProgram]);
*/
  useEffect(() => {
    if (programs !== undefined && programs instanceof Array) {
      let programTargetId = selectedProgram?.id;
      if(goToProgram !== undefined){
        programTargetId = goToProgram;
      }
      let foundProgram = false;
      programs.forEach(program => {
        if (programTargetId === program?.id) {
          setselectedProgram(program);
          foundProgram = true;
        }
      });
      if(!foundProgram){
        setProgramIfPrevEmpty(programs);
      }
    }
  }, [goToProgram]);
  //#endregion

  /**
   * Obtiene el indice del nuevo programa.
   */
  const getNextProgramIndex = useCallback(() => {
    return ListPrograms.length;
  }, [ListPrograms]);

  /**
   * Elimina el programa de la lista de programas. Reasigna los index y selecciona un nuevo programa si puede ser.
   */
  const removeProgramFromList = useCallback(() => {
    let list = [...ListPrograms];
    if (!isNaN(selectedProgram?.index)) {
      let deleted = list.splice(selectedProgram.index, 1);
      getNumberOfExistentTasks(list);

      if (deleted.length > 0) {
        let offset = selectedProgram.index;
        for (let i = offset; i < list?.length; i++) {
          let program = list[i];
          program.index = i;
        }
        setListPrograms(list);
        let prevIndex = offset - 1;
        if (prevIndex >= 0 && ListPrograms.length > prevIndex) {
          console.log("1");
          setselectedProgram(list[prevIndex]);
        } else if (list.length > 0 && prevIndex < 0) {
          // No quedan programas en
          console.log("1");
          setselectedProgram(list[0]);
        } else {
          const newProgram = getNewProgramObject(0);
          setListPrograms([newProgram]);
          console.log("1");
          setselectedProgram(newProgram);
        }
      }
    }
  }, [
    selectedProgram,
    ListPrograms,
    getNumberOfExistentTasks,
    getNewProgramObject,
  ]);


  /**
   * Actualiza un programa de la lista.
   */
  const updateProgramList = useCallback(
    (program) => {
      const newReference = TerminalDeviceProgram.parseFromObjectDto({
        ...program,
      });
      setselectedProgram(newReference);

      let index = -1;
      for (let i = 0; i < ListPrograms.length; i++) {
        const listProgram = ListPrograms[i];
        if (listProgram && program && listProgram?.index === program?.index) {
          index = i;
          break;
        }
      }
      if (index >= 0) {
        ListPrograms[index] = newReference;
        getNumberOfExistentTasks(ListPrograms);
      }
    },
    [ListPrograms, getNumberOfExistentTasks]
  );

  /**
   * Añade un nuevo programa al estado.
   */
  const createProgram = useCallback(() => {
    const index = getNextProgramIndex();
    const newProgram = getNewProgramObject(index);
    const newListPrograms = [...ListPrograms, newProgram];
    setselectedProgram(newProgram);
    setFocusOnProgramIndex(index);
    setListPrograms(newListPrograms);
  }, [selectedProgram, ListPrograms]);

  //#region  Create Update Delete

  const pusblishSetTasks = useCallback(
    (target_id) => {
      publish(projectsConstants.master_outputs.actions.settasksbyid, {
        type: MESSAGE_TYPE_ACTION,
        id: uuid(),
        data: {
          target_id,
        },
      });
    },
    [publish]
  );

  const publishDeletetasks = useCallback(
    (target_id) => {
      publish(projectsConstants.master_outputs.actions.deletetasks, {
        type: MESSAGE_TYPE_ACTION,
        id: uuid(),
        data: {
          target_id,
        },
      });
    },
    [publish]
  );

  //#region POST PUT DELETE
  const parseInfraprograms = useCallback(infaprogramsMap => {
    if(infaprogramsMap instanceof Map){
      let infaprograms = []
      infaprogramsMap.forEach(infraprogramsSet => {
        infaprograms.push(...infraprogramsSet)
      })
      return infaprograms
    }
    return []
  }, [])

  const parseSubprograms = useCallback((subprograms) => {
    if(subprograms instanceof Array){
      return subprograms.map(subprogram => {

        subprogram.infraprograms = parseInfraprograms(subprogram.infraprograms)
        let initSplited = subprogram.initDelay.split(":") || ["0", "0"]
        let endSplited = subprogram.endDelay.split(":") ["0", "0"]
        return {...subprogram, 
          infraprograms: parseInfraprograms(subprogram.infraprograms),
          initDelay:  (+initSplited[0]) * 60 * 60 + (+initSplited[1]) * 60,
          endDelay: (+endSplited[0]) * 60 * 60 + (+endSplited[1]) * 60,
        }
      })
    }
    return subprograms
  } , [parseInfraprograms]);

  const postProgram = useCallback(
    (program) => {
      const programObjectForService = {
        name: program.name || "",
        triggerType: projectsConstants.global.programs.trigger_types.time,
        startHour: `${program.hour}:${program.minute}`,
        days: program.days,
        active: true,
        subprogramsDelay: 0,
        subprograms: parseSubprograms(program.subprograms),
      };
      setIsApiConsuming(true);
      programService
        .save(terminalId, terminalDevice.id, programObjectForService)
        .then(
          (recvProgram) => {
            program.new = false;
            program.modified = false;
            program.id = recvProgram.id;
            program.errorSaving = false;
            updateProgramList(program);
            setIsApiConsuming(false);

            pusblishSetTasks(terminalDevice.id);
          },
          (error) => {
            setIsApiConsuming(false);
            program.errorSaving = true;
            updateProgramList(program);
          }
        );
    },
    [updateProgramList, pusblishSetTasks, terminalDevice, parseSubprograms]
  );

  const putProgram = useCallback(
    (program) => {
      if (program.id) {
        const programObjectForService = {
          name: program.name || "",
          triggerType: projectsConstants.global.programs.trigger_types.time,
          startHour: `${program.hour}:${program.minute}`,
          days: program.days,
          active: true,
          subprogramsDelay: 0,
          subprograms: parseSubprograms(program.subprograms),
        };
        setIsApiConsuming(true);
        programService
          .update(
            terminalId,
            terminalDevice.id,
            program.id,
            programObjectForService
          )
          .then(
            (recvProgram) => {
              program.new = false;
              program.modified = false;
              program.errorSaving = false;
              updateProgramList(program);
              setIsApiConsuming(false);

              publishDeletetasks(terminalDevice.id);
              pusblishSetTasks(terminalDevice.id);
            },
            (error) => {
              setIsApiConsuming(false);
              program.errorSaving = true;
              program.modified = false;
              updateProgramList(program);
            }
          );
      } else {
        console.log("NO SE PUEDE ACTUALIZAR PORQUE NO TIENE ID");
      }
    },
    [updateProgramList, pusblishSetTasks, publishDeletetasks, terminalDevice, parseSubprograms]
  );

  const deleteProgram = useCallback(
    (program) => {
      setIsApiConsuming(true);
      programService
        .deleteProgram(terminalId, terminalDevice.id, program.id)
        .then(
          (recvProgram) => {
            removeProgramFromList();
            setIsApiConsuming(false);
            publishDeletetasks(terminalDevice.id);
          },
          (error) => {
            setIsApiConsuming(false);
            program.errorDeleting = true;
            program.removed = false;
            updateProgramList(program);
          }
        );
    },
    [
      removeProgramFromList,
      updateProgramList,
      publishDeletetasks,
      terminalDevice,
    ]
  );

  //#endregion

  /**
   * Elimina un programa de la lista si este no está almacenado en la nube.
   * En caso contrario notifica a la API y espera respuesta.
   */

  //Confirmación para borrar programa:
  const removeProgramConfirmed = useCallback((program) => {
    const targetProgram = program || selectedProgram;
    if (targetProgram?.new) {
      // NO es necesario contactar con la API.
      removeProgramFromList();
    } else if (!isNaN(targetProgram?.id)) {
      // Hace falta contactar con la API
      targetProgram.removed = true;
      updateProgramList(targetProgram);
      deleteProgram(targetProgram);
    }
    //setshowpopupConfirmDeleteProgram(false);
  }, [
    selectedProgram,
    removeProgramFromList,
    updateProgramList,
    deleteProgram
  ]);

  const removeProgram = useCallback((program) => {
    if (program) {
      console.log("1");
      setselectedProgram(program);
    }
    removeProgramConfirmed(program);
  }, [removeProgramConfirmed]);

  

  const [menuSubrutineActive, setmenuSubrutineActive] = useState(false);
  const SetProgramClick = useCallback(
    (program) => {
      console.log(program);
      if (!isApiConsuming) {
        console.log("1");
        setselectedProgram(program);
      }
    },
    [isApiConsuming]
  );

  const actionOnCancelProgram = useCallback(() => {
    CancelScheduledTasks();
  }, [CancelScheduledTasks]);

  const actionOnSaveProgram = useCallback(
    (programData) => {
      if(!programData instanceof TerminalDeviceProgram){
        programData = TerminalDeviceProgram.parseFromObjectDto(programData);
      }
      // Comprobamos si se ha superado el limite de tareas:
      if (getNumberOfExistentTasks(ListPrograms) > maxTasks) {
        setOutputTasksMaxSizeReached(true);
        return;
      }
      if (programData?.new) {
        console.log("creando infraprograma");
        // LO CREAMOS
        postProgram(programData);
      } else {
        console.log("Actualizando programa");
        // ACTUALIZAMOS
        putProgram(programData);
      }
    },
    [postProgram, putProgram, ListPrograms]
  );

  const isAnySubroutineSaved = useCallback(() => {
    if (ListPrograms && ListPrograms instanceof Array) {
      let filtered = ListPrograms.filter((subp) => subp?.id > 0);
      return filtered.length > 0;
    }
    return false;
  }, [ListPrograms]);

  return (
    <>
      <div className="fatherPrograms">
        <div className="TitleTerminaldevices">
          <div className="Titleprograms" onClick={(e) => back()}>
            <div className="Icoback">
              <div className="fa fa-fw fa-mail-reply"></div>
            </div>
            <div className="TitleTerminaldevices2">
              {terminalDevice?.description}
            </div>
          </div>
        </div>
        <div className="classprogramsfather">
          {ListPrograms.length > 0 && isAnySubroutineSaved() && (
            <>
              <div className="ProgramsView">
                <ProgramsView
                  SetProgramClick={SetProgramClick}
                  ListPrograms={ListPrograms}
                  program={selectedProgram}
                  focusOnIndex={FocusOnProgramIndex}
                  onProgramDelete={removeProgram}
                  terminalDevice={terminalDevice}
                  urlstate={urlstate}
                  goToProgram={goToProgram}
                />
                {!isApiConsuming && (
                  <div className="fatherbuttonprogram">
                    <AddProgram
                      className="ButtonProgram Add"
                      onClick={(e) => createProgram()}
                    />
                  </div>
                )}
                {isApiConsuming && (
                  <AddProgram className="ButtonProgram Add Disabled" />
                )}
              </div>
            </>
          )}
          {ListPrograms.length > 0 && (
            <>
              {outputTasksMaxSizeReached && (
                <div className="MessageServerOut">
                  <div className="Error">
                    El dispositivo no dispone de suficiente memoria. Elimine
                    programas y/o subprogramas.
                  </div>
                  <div>
                    <DangerIcoOffOutputs className="IcoError" />
                  </div>
                </div>
              )}
            </>
          )}

          <Program
            actionOnCancelProgram={actionOnCancelProgram}
            actionOnSaveProgram={actionOnSaveProgram}
            updateProgram={updateProgramList}
            removeProgram={removeProgram}
            program={selectedProgram}
            outputs={Outputs}
            loading={isApiConsuming}
            menuSubrutineActivef={menuSubrutineActive}
            ListPrograms={ListPrograms}
            goToProgram={goToProgram}

          />
        </div>
      </div>
    </>
  );
};
