import React, { useCallback, useMemo } from 'react'
import { MESSAGE_TYPE_ACTION } from '../_constants/messageType.constants';
import { projectsConstants } from '../_constants/projects.constants';
import { usePublisher } from './publish-context';
import uuid from "uuid/v4";
import { isValidForAdvancedActoutput } from '../components/components/TerminalDevices/Fichas/OutputConfiguration/OutputsChecker';

const OutputsContext = React.createContext();

export const OutputsAPIProvider = (props) => {
    const {publish} = usePublisher();


    const advanced_actoutput = useCallback((targetId, target_output, bitOutputs = 0, cudId = 0, always_on = true, limit = 0) => {

      let config = cudId > 0 ? 1 : 0;
      config |= cudId > 0 ? (cudId << 1) & 0xE : 0
      config |= always_on ? 0x16 : 0

      const advActoutput = {
        id: uuid(),
        type: MESSAGE_TYPE_ACTION,
        data: {
          target_id: targetId,
          actions: [{
            id: 0,
            prevId: 0,
            outputs: bitOutputs > 0 ? bitOutputs : (1 << (target_output - 1)),
            config,
            pwm: 100,
            limit
          }],
        }
      };

      publish(projectsConstants.master_outputs.actions.advanced_actoutput, advActoutput);
    }, [publish])

    const actoutput = useCallback(
        (targetId, target_output, activationType, activationParams, controlUnit = "TIME", controlCudId = 0, always_on = true, limit = 1000) => {
          let actoutput;
          let actParams = activationParams && JSON.parse(activationParams);
          if (!actParams) {
            actParams = {
              first_pulse: 2,
              last_pulse: 2,
              pwm: 100,
            };
          }
          switch (activationType) {
            case projectsConstants.global.activations.pulses:
            case projectsConstants.global.activations.pulses_per_minute:
              actoutput = {
                id: uuid(),
                type: MESSAGE_TYPE_ACTION,
                data: {
                  target_id: targetId,
                  target_output: target_output,
                  init_action: {
                    pwm: 100,
                    control_unit: controlUnit,
                    in_cud: controlCudId,
                    polarity: "FROM_P_TO_N",
                    limit: actParams.first_pulse,
                  },
                  main_action: {
                    pwm: 0,
                    control_unit: controlUnit,
                    in_cud: controlCudId,
                    polarity: "FROM_P_TO_N",
                    limit: 0,
                    always_on:  projectsConstants.global.activations.pulses_per_minute === activationType ? false : always_on,
                  },
                  last_action: {
                    pwm:
                      projectsConstants.global.activations.pulses_per_minute ===
                      activationType
                        ? 0
                        : 100,
                    control_unit: controlUnit,
                    in_cud: controlCudId,
                    polarity: "FROM_P_TO_N",
                    limit: actParams.last_pulse,
                  },
                  loop:
                    projectsConstants.global.activations.pulses_per_minute ===
                    activationType,
                },
              };
              break;
            case projectsConstants.global.activations.pulses_with_polarity:
              actoutput = {
                id: uuid(),
                type: MESSAGE_TYPE_ACTION,
                data: {
                  target_id: targetId,
                  target_output: target_output,
                  init_action: {
                    pwm: 100,
                    control_unit: controlUnit,
                    in_cud: controlCudId,
                    polarity: "FROM_P_TO_N",
                    limit: actParams.first_pulse,
                  },
                  main_action: {
                    pwm: 0,
                    control_unit: controlUnit,
                    in_cud: controlCudId,
                    polarity: "FROM_P_TO_N",
                    limit: 0,
                    always_on,
                  },
                  last_action: {
                    pwm: 100,
                    control_unit: controlUnit,
                    in_cud: controlCudId,
                    polarity: "FROM_N_TO_P",
                    limit: actParams.last_pulse,
                  },
                },
              };
              break;
            case projectsConstants.global.activations.continuous:
            default:
              actoutput = {
                id: uuid(),
                type: MESSAGE_TYPE_ACTION,
                data: {
                  target_id: targetId,
                  target_output: target_output,
                  init_action: {
                    pwm: 0,
                    control_unit: controlUnit,
                    in_cud: controlCudId,
                    polarity: "FROM_P_TO_N",
                    limit: 0,
                  },
                  main_action: {
                    pwm: 100,
                    control_unit: controlUnit,
                    in_cud: controlCudId,
                    polarity: "FROM_P_TO_N",
                    limit,
                    always_on,
                  },
                  last_action: {
                    pwm: 0,
                    control_unit: controlUnit,
                    in_cud: controlCudId,
                    polarity: "FROM_P_TO_N",
                    limit: 0,
                  },
                },
              };
              break;
          }
    
          publish(projectsConstants.master_outputs.actions.actoutput, actoutput);
        },
        [publish]
    );

    const ActiveOutput = useCallback(
      (terminalDevice, output) => {
        if (isValidForAdvancedActoutput(terminalDevice)) {
          advanced_actoutput(
            terminalDevice.id,
            null,
            1 << (output?.output - 1),
            0,
            true
          );
        } else {
          actoutput(
            terminalDevice.id,
            output.output,
            output.getActivationType(),
            output.activationParams,
            "TIME",
            0,
            true
          );
        }
      },
      [advanced_actoutput, actoutput]
    );

    const endoutput = useCallback(
        (targetId, target_output) => {
          const endoutput = {
            id: uuid(),
            type: 0,
            data: {
              target_id: targetId,
              output: target_output,
              do_last: true,
            },
          };
    
          publish(projectsConstants.master_outputs.actions.endoutput, endoutput);
        },
        [publish]
      );

    const setoutputv = useCallback((target_id, voltage) => {
        publish(projectsConstants.master_outputs.actions.setoutputv, {
            id: uuid(),
            type: 0,
            data: {
                target_id,
                voltage,
            },
        });
    }, [publish]);

    const maintenance = useCallback((target_id, maintenance) => {
        publish(projectsConstants.master_outputs.actions.maintenance, {
            id: uuid(),
            type: 0,
            data: {
              target_id,
              maintenance
            },
          });
    }, [publish]);
    
    const lora_outputs_actouput = useCallback((targetId, output) => {
      const actoutput = {
        id: uuid(),
        type: MESSAGE_TYPE_ACTION,
        data: {
          target_id: targetId,
          outputs:  1 << (output - 1),
          pwm: 100,
          alwaysOn: true,
          unitControl: "TIME",
          cudId: 0,
          limit: 0
        }
      };

      publish(projectsConstants.lora_outputs.actions.actoutput, actoutput, projectsConstants.lora_outputs.id);
    }, [publish])

    const lora_outputs_endoutput = useCallback((targetId, output) => {
      const endoutput = {
        id: uuid(),
        type: MESSAGE_TYPE_ACTION,
        data: {
          target_id: targetId,
          outputs:  1 << (output - 1)
        }
      };

      publish(projectsConstants.lora_outputs.actions.endoutput, endoutput, projectsConstants.lora_outputs.id);
    }, [publish])

    const value = useMemo(() => {
        return ({
            actoutput,
            endoutput,
            setoutputv,
            maintenance,
            advanced_actoutput,
            isValidForAdvancedActoutput,

            lora_outputs_actouput,
            lora_outputs_endoutput
        })
    }, [actoutput, endoutput, setoutputv, maintenance, advanced_actoutput, lora_outputs_actouput, lora_outputs_endoutput]);

    return  <OutputsContext.Provider value={value} {...props} />;
}

export const useOutputsAPI = () => {
    const context = React.useContext(OutputsContext);
    if(!context) {
        console.log("useOutputsAPI debe estar dentro del proveedore OutputsContext")
    }
    return context;
}

export default OutputsContext