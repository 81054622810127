import React, { useEffect, useCallback, useState } from "react";
import _ from "lodash";
import { PopUpC } from "../../../Airframe/PopUp/PopUp";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import { OutputConfig } from "./OutputConfig";
import { usePublisher } from "../../../../../context/publish-context";
import { ReactComponent as QuestionSvg } from "./question.svg";
import uuid from "uuid/v4";
import { MESSAGE_TYPE_ACTION } from "../../../../../_constants/messageType.constants";
import { SetWorkModeMQTT } from "../../../MQTT/SetWorkModeMQTT";
import { DangerComponent } from "../../../../DangerComponent/DangerComponent";

import { VoltageSelector } from "../VoltageSelector/VoltageSelector";
import { Input } from "reactstrap";
import { Outputconfhelp } from "./Outputconfhelp";
import { Output } from "../../../../../classes/TerminalDevice/Output/Output";
import { DropdownOutputsTypes } from "./Dropdowns/DropdownOutputsTypes";
import { CheckComponent } from "../../../Airframe/CheckComponent/CheckComponents";
import { TerminalDeviceOutputsDropDown } from "./TerminalDeviceOutputsDropDown";
import {
  TerminalDeviceCalibrationTwoOptions,
} from "../../../Calibration/TerminalDeviceCalibrationTwoOptions";
import { isTerminalDeviceValidForSetworkmodeV2 } from "./SetWorkeModeChecker";
import { isTerminalDeviceValidForOutputCalibration, isTerminalDeviceValidForOutputWithAssociateDigitalSensor } from "./OutputsChecker";
import { UNITS } from "../../../Calibration/TerminalDeviceOutputTimeOrCudCalibration";
import { TerminalDeviceOutputDigitalSensorAssociate } from "./TerminalDeviceOutputDigitalSensorAssociate";
const {
  output_active: OUTPUT_ACTIVE,
  output_activated: OUTPUT_ACTIVATED,
} = projectsConstants.global.states;

const ACK = projectsConstants.global.codes.ACK;

export const Outputconfiguration = (props) => {
  const {
    outputs,
    sendData,
    updateOutputs,
    terminalDevice,
    lastStatus,
    showButtonSave,
    mustSave,
    id,
    typesControlResponse,
    controlWaitingResponse,
    waitingMqttResponse,
    waitingApiResponse,
    onErrorSaving,
    onSuccessSaving,
    onErrorNotifying,
    onSuccessNotifying,
    changeSelectOutput,
    infoLoadingServer,
    type,
    buttonAction,
    disableOutputType,
  } = props;

  const [selectedOutput, setselectedOutput] = useState(undefined);
  const [sourceOutput, setSourceOutput] = useState(undefined)

  const [checkWorkmode, setcheckWorkmode] = useState(false);

  const [errorAnyOutputActive, seterrorAnyOutputActive] = useState(false);
  const [errorReportByDevice, seterrorReportByDevice] = useState(false);
  const [errorReportByServer, seterrorReportByServer] = useState(false);
  const [successSetworkmode, setsuccessSetworkmode] = useState(false);
  const [errorApi, seterrorApi] = useState(false);
  const [errorTasksDependency, setErrorTasksDependency] = useState(false);
  const [laststatusCut, setlaststatusCut] = useState(undefined);
  const [showOutputconfig, setshowOutputconfig] = useState(false);

  const [successApi, setsuccessApi] = useState(false);
  const [lastMustSave, setLastMustSave] = useState(mustSave);
  const { publish } = usePublisher();
  const [showOutputConfigHelp, setShowOutputConfigHelp] = useState(false);
  const [outputVIsSync, setOutputVIsSync] = useState(true);

  const [activationParamsTochild, setactivationParamstoChild] = useState({});

  const [activationParamsToPost, setactivationParamstoPost] = useState({});

  const loadOutput = useCallback(
    (output, showSave) => {
      const stateOutput = _.cloneDeep(output);
      Object.setPrototypeOf(stateOutput, Output.prototype);
      setselectedOutput(stateOutput);
      setSourceOutput(_.deep)

      let initToZero = true;
      if (output.activationParams) {
        try {
          let params = JSON.parse(output.activationParams);
          setactivationParamstoChild(params);

          initToZero = false;
        } catch (e) {}
      }
      if (initToZero) {
        setactivationParamstoChild({});
      }

      //ya no se muestra el boton de guardar al seleccionar una salida:
      if (showSave) {
        // showButtonSave(id);
      }

      setshowOutputconfig(true);
    },
    [showButtonSave, id, activationParamsTochild]
  );

  //#region  USEEFFECTs

  useEffect(() => {
    if (lastMustSave !== mustSave && mustSave > 0) {
      setLastMustSave(mustSave);
    }
    if (
      lastMustSave !== mustSave &&
      mustSave > 0 &&
      selectedOutput.activationType !== undefined
    ) {
      actionSaveOutputConfig();
    }
  }, [mustSave]);

  useEffect(() => {
    if (lastStatus) {
      const laststatusHour = lastStatus.substring(12, 16);
      const laststatusYear = lastStatus.substring(0, 10);
      setlaststatusCut(laststatusHour + "   " + laststatusYear);
    }

    if (checkWorkmode) {
      publish(projectsConstants.master_outputs.actions.setworkmode, {
        type: MESSAGE_TYPE_ACTION,
        id: uuid(),
        data: {
          target_id: terminalDevice.id,
        },
      });
      controlWaitingResponse(true, typesControlResponse.Mqtt);
      setsuccessSetworkmode(false);
      setcheckWorkmode(false);
    }
  }, [
    publish,
    checkWorkmode,
    errorAnyOutputActive,
    errorReportByDevice,
    errorReportByServer,
    waitingMqttResponse,
    laststatusCut,
    outputs,
  ]);

  useEffect(() => {
    let isAllInSync = outputVIsSync;
    if (outputs && outputs instanceof Array && outputs.length > 0) {
      if (!selectedOutput) {
        loadOutput(outputs[0], false);
      }

      // Recorremos las salidas y buscamos alguna que su activationTypeExpected no cuadre con activationType
      let notSync = false;
      for (let i = 0; i < outputs.length; i++) {
        const out = outputs[i];
        if (
          out instanceof Output &&
          out.activationType !== out.activationTypeExpected
        ) {
          notSync = true;
          break;
        }
      }
      isAllInSync &= !notSync;
      //controlWaitingResponse(notSync, typesControlResponse.NotSync);
    } else {
      isAllInSync &= true;
      //controlWaitingResponse(false, typesControlResponse.NotSync);
    }

    controlWaitingResponse(!isAllInSync, typesControlResponse.NotSync);
  }, [outputs, outputVIsSync]);
  //#endregion

  const EditOutput = useCallback(
    (outputId) => {
      if (outputs instanceof Array) {
        outputs
          .filter((output) => output?.output === outputId)
          .forEach((output) => {
            changeSelectOutput(output);
            loadOutput(output, true);
          });
      }
    },
    [changeSelectOutput, outputs, loadOutput]
  );

  const checkPolarityChanged = useCallback(
    (ActivationType, ModeChooseold) => {
      if (isTerminalDeviceValidForSetworkmodeV2(terminalDevice)) {
        return ActivationType !== ModeChooseold;
      }
      let changed = false;
      if (
        (ActivationType ===
          projectsConstants.global.activations.pulses_with_polarity &&
          ModeChooseold !==
            projectsConstants.global.activations.pulses_with_polarity) ||
        (ActivationType !==
          projectsConstants.global.activations.pulses_with_polarity &&
          ModeChooseold ===
            projectsConstants.global.activations.pulses_with_polarity)
      ) {
        changed = true;
      }
      return changed;
    },
    [terminalDevice]
  );

  const checkCalibrationChange = useCallback(
    (originalOutput, updatedOutput) => {
      return isTerminalDeviceValidForSetworkmodeV2(terminalDevice) /*&&
        (originalOutput?.reportIntervalInSeconds !==
          updatedOutput?.reportIntervalInSeconds ||
          originalOutput?.measurement?.calibrationRawValue !==
          updatedOutput?.measurement?.calibrationRawValue ||
          originalOutput?.measurement?.calibrationUnitValue !==
          updatedOutput?.measurement?.calibrationUnitValue)*/;
    },
    [terminalDevice]
  );

  const actionSaveOutputConfig = useCallback(async () => {
    let error = false;
    const outputActivation = selectedOutput?.activationType
    let polarityChanged = checkPolarityChanged(
      outputActivation,
      sourceOutput?.activationType
    );
    if (polarityChanged) {
      let anyOn = false;
      outputs.forEach((o) => {
        anyOn |=
          o.currentState === OUTPUT_ACTIVE ||
          o.currentState === OUTPUT_ACTIVATED;
      });
      error = anyOn;
    }

    if (error) {
      seterrorAnyOutputActive(true);
    } else {
      seterrorAnyOutputActive(false);
      let pair = [];
      const outputTypeId = selectedOutput?.outputType?.id
      const postData = {
        output: selectedOutput.output,
        intensity: activationParamsToPost?.pwm || 0,
        expectedState: selectedOutput.expectedState,
        readyToWork: selectedOutput.readyToWork,
        activationTypeExpected: outputActivation,
        outputType: outputTypeId > 0 ? outputTypeId : undefined,
        activationParams: JSON.stringify({
          first_pulse: parseInt(activationParamsToPost?.first_pulse || 1000),
          last_pulse: parseInt(activationParamsToPost?.last_pulse || 1000),
          pwm: activationParamsToPost?.pwm || 0,
          custom: activationParamsToPost?.custom,
          multiplier_on: activationParamsToPost?.multiplier_on,
          multiplier_off: activationParamsToPost?.multiplier_off,
        }),
        description: selectedOutput?.description,
        reportIntervalInSeconds: selectedOutput.measurement ? 300 : 0,
        measurement: selectedOutput.measurement,
        digitalSensor: selectedOutput?.digitalSensor ? {
          sensorId: selectedOutput?.digitalSensor?.sensorId?.id,
          sensorIndex: selectedOutput?.digitalSensor?.sensorIndex
        } : undefined,
        theoreticalFlow: selectedOutput?.theoreticalFlow
      };

      pair.push(postData);

      const half = outputs.length / 2;
      let outputSelectTwin = 0;
      let outputSelect = selectedOutput.output;

      if (outputSelect > half) {
        outputSelectTwin = outputSelect - half;
      } else if (outputSelect <= half) {
        outputSelectTwin = outputSelect + half;
      }

      let outputTwin = undefined;
      for (let i = 0; i < outputs.length; i++) {
        if (outputs[i].output === outputSelectTwin) {
          outputTwin = outputs[i];
          break;
        }
      }

      let updatedOutputTwin = undefined;
      if (outputTwin) {
        let mustPush = false;
        if (
          outputActivation ===
          projectsConstants.global.activations.pulses_with_polarity
        ) {
          mustPush = true;
        } else if (
          outputTwin.activationType ===
          projectsConstants.global.activations.pulses_with_polarity
        ) {
          try {
            updatedOutputTwin = await sendData(selectedOutput.terminalDevice, [
              { ...postData, description: "", output: outputTwin.output },
            ]);
          } catch (e) {
            console.log(e);
          }
        }
        if (mustPush) {
          pair.push({ ...postData, output: outputTwin.output });
        }
      }

      setsuccessSetworkmode(false);
      controlWaitingResponse(true, typesControlResponse.Api);

      seterrorApi(false);
      infoLoadingServer(buttonAction.start, type, postData);
      setsuccessApi(false);
      setErrorTasksDependency(false);
      sendData(selectedOutput.terminalDevice, pair)
        .then((recvOutput) => {
          if (updatedOutputTwin && recvOutput && recvOutput instanceof Array) {
            recvOutput = recvOutput.concat(updatedOutputTwin);
          }
          updateOutputs(recvOutput);
          controlWaitingResponse(false, typesControlResponse.Api);
          setcheckWorkmode(polarityChanged || checkCalibrationChange());
          infoLoadingServer(buttonAction.finalized, type);
          setsuccessApi(true);
          onSuccessSaving(id);
        })
        .catch((err) => {
          infoLoadingServer(buttonAction.error, type);
          controlWaitingResponse(false, typesControlResponse.Api);
          seterrorApi(true);
          onErrorSaving(id);

          if (err?.response.status === 424) {
            // Dependencias con tareas programadas.
            setErrorTasksDependency(true);
          }
        });
    }
  }, [
    checkPolarityChanged,
    selectedOutput,
    sourceOutput,
    outputs,
    activationParamsToPost?.pwm,
    activationParamsToPost?.first_pulse,
    activationParamsToPost?.last_pulse,
    activationParamsToPost?.custom,
    activationParamsToPost?.multiplier_on,
    activationParamsToPost?.multiplier_off,
    controlWaitingResponse,
    typesControlResponse.Api,
    infoLoadingServer,
    buttonAction.start,
    buttonAction.finalized,
    buttonAction.error,
    type,
    sendData,
    updateOutputs,
    checkCalibrationChange,
    onSuccessSaving,
    id,
    onErrorSaving,
  ]);

  const processSetworkmodeMsg = useCallback(
    (messageId, message) => {
      const success = message?.data?.success;
      const targetId = message?.data?.target_id;
      if(targetId === terminalDevice?.id){
        if (success) {
          setsuccessSetworkmode(true);
          onSuccessNotifying(id);
          seterrorReportByDevice(false);
        } else {
          seterrorReportByDevice(true);
          setsuccessSetworkmode(false);
          onErrorNotifying(id);
        }
  
        controlWaitingResponse(false, typesControlResponse.Mqtt);
      }
    },
    [terminalDevice?.id, controlWaitingResponse, typesControlResponse.Mqtt, onSuccessNotifying, id, onErrorNotifying]
  );

  const processSetworkmodeErrorMsg = useCallback(
    (messageId, message) => {
      if(message?.data?.target_id === terminalDevice?.id){
        seterrorReportByServer(true);
        controlWaitingResponse(false, typesControlResponse.waitingMqttResponse);
        setsuccessSetworkmode(false);
        onErrorNotifying(id);
      }
    },
    [controlWaitingResponse, id, onErrorNotifying, terminalDevice?.id, typesControlResponse.waitingMqttResponse]
  );
  const onOutputDescriptionChange = useCallback(
    (data) => {
      setselectedOutput(current => ({...current, description: data}))
      showButtonSave(id);
    },
    [id, showButtonSave]
  );

  const onOutputTerminalDeviceSensorSelected = useCallback(digitalSensor => {
    setselectedOutput(current => ({...current, digitalSensor}))
    showButtonSave(id);
  }, [id, showButtonSave])

  const onTheoreticalFlowChange = useCallback(theoreticalFlow => {
    setselectedOutput(current => ({...current, theoreticalFlow}))
    showButtonSave(id);
  }, [id, showButtonSave])

  const ShowPopUpInfo = useCallback((type) => {
    setShowOutputConfigHelp(true);
  }, []);
  //Cierra PopUp
  const closePopUP = useCallback(() => {
    setShowOutputConfigHelp(false);
  }, []);

  const onActivationParamsChange = useCallback(
    (actParams) => {
      setactivationParamstoPost(actParams);
      showButtonSave(id);
    },
    [showButtonSave, id]
  );

  const onTypeOutputChange = useCallback(
    (idType) => {
      setselectedOutput(current => ({...current, outputType:{ id: idType}}))
      showButtonSave(id);
    },
    [id, showButtonSave]
  );

  const onChangeOutputActivation = useCallback(
    (newOutputActivation) => {
      if (newOutputActivation !== selectedOutput.activationType) {
        setselectedOutput(current => ({...current, activationType: newOutputActivation}))
        showButtonSave(id);
      }
    },
    [selectedOutput, showButtonSave, id]
  );

  const voltageSelectorEventSync = useCallback((inSync) => {
    setOutputVIsSync(inSync);
  }, []);

  //#region calibration

  const castToPositiveInteger = useCallback((field) => {
    if (("" + field).length > 0 && !isNaN(field)) {
      field = parseInt(field);
      field = field < 0 ? 0 : field;
      return field;
    }
    return "";
  }, []);

  const castToPositiveFloat = useCallback((field) => {
    if (("" + field).length > 0 && !isNaN(field)) {
      field = parseFloat(field);
      field = field < 0 ? 0 : field;
      return field;
    }
    return "";
  }, []);

  const onCalibrationRawValueChange = useCallback(
    (calibrationRawValue) => {
      if (selectedOutput) {
        const clonedOutput = _.cloneDeep(selectedOutput);
        if (!clonedOutput?.measurement) {
          clonedOutput.measurement = {
            measurementUnit: "l",
            sensorUnit: "Ud",
          };
        }

        clonedOutput.measurement.calibrationRawValue = castToPositiveInteger(
          calibrationRawValue
        );
        setselectedOutput(clonedOutput);
        showButtonSave(id);
      }
    },
    [selectedOutput, castToPositiveInteger, showButtonSave, id]
  );

  const onCalibrationUnitValueChange = useCallback(
    (calibrationUnitValue) => {
      if (selectedOutput) {
        const clonedOutput = _.cloneDeep(selectedOutput);
        if (!clonedOutput?.measurement) {
          clonedOutput.measurement = {
            measurementUnit: "l",
            sensorUnit: "Ud",
          };
        }

        clonedOutput.measurement.calibrationUnitValue = castToPositiveFloat(
          calibrationUnitValue
        );
        setselectedOutput(clonedOutput);
        showButtonSave(id);
      }
    },
    [selectedOutput, castToPositiveFloat, showButtonSave, id]
  );

  //#endregion

  return (
    <>
      <PopUpC
        activate={showOutputConfigHelp}
        deactivatepopup={closePopUP}
        content={<Outputconfhelp {...props} />}
      />
      <SetWorkModeMQTT
        processMsg={processSetworkmodeMsg}
        processErrorMsg={processSetworkmodeErrorMsg}
      />
      <OutputConfigurationErrors
        errorAnyOutputActive={errorAnyOutputActive}
        errorReportByDevice={errorReportByDevice}
        errorReportByServer={errorReportByServer}
        errorNotMatchingWorkmode={false}
        successSetworkmode={successSetworkmode}
        errorApi={errorApi}
        errorTasksDependency={errorTasksDependency}
        successApi={successApi}
      />
      <div className="Configuration">
        {waitingMqttResponse && (
          <div>Esperando respuesta del dispositivo...</div>
        )}
        {waitingApiResponse && <div>Esperando respuesta del servidor...</div>}

        <div className="Titlewithinfo">
          <div className="Title_DescripctionConf ">Salida:</div>
          <div className="icoOutputconf">
            <div className="icoOutputconf2">
              <QuestionSvg
                className="ButtonProgram Info"
                onClick={(e) => ShowPopUpInfo("InfoTarjetas")}
              />
            </div>
          </div>
        </div>

        <TerminalDeviceOutputsDropDown
          outputs={outputs}
          disabled={waitingApiResponse || waitingMqttResponse}
          onChange={EditOutput}
          selectedOutput={selectedOutput}
        ></TerminalDeviceOutputsDropDown>

        {!disableOutputType && (
          <>
            <div className="Titlewithinfo">
              <div className="Title_DescripctionConf ">Tipo:</div>
            </div>

            <DropdownOutputsTypes
              waitingMqttResponse={waitingMqttResponse}
              waitingApiResponse={waitingApiResponse}
              showButtonSave={onTypeOutputChange}
              selectedOutputTypeId={selectedOutput?.outputType?.id}
            />
          </>
        )}

        {showOutputconfig && (
          <>
            <div className="Title_Descripction">Descripcion:</div>
            <Input
              type="text"
              name="in_cud"
              id="in_cud"
              placeholder={"Descripción de la salida"}
              className="InputForm descriptionInput"
              onChange={(e) => onOutputDescriptionChange(e.target.value)}
              value={selectedOutput?.description || ""}
              disabled={waitingApiResponse || waitingMqttResponse}
            />
            <OutputConfig
              terminalDevice={terminalDevice}
              output={selectedOutput}
              outputActivation={selectedOutput.activationType}
              onChangeOutputActivation={onChangeOutputActivation}
              disabled={waitingApiResponse || waitingMqttResponse}
              activationParams={activationParamsTochild}
              onActivationParamsChange={onActivationParamsChange}
              savedatastate={onOutputDescriptionChange}
              selectedOutput={selectedOutput}
              outputDescription={selectedOutput?.description}
              disableOutputType={disableOutputType}
              excludedActivationModes={
                terminalDevice.device.id ===
                  projectsConstants.global.devices.PKT7 && [3]
              }
            />

            {isTerminalDeviceValidForOutputWithAssociateDigitalSensor(terminalDevice) && 
              <>
                <div className="TabOutputConf" />
                <div className="titleModesActivationConf">Sensor pulsos asociado:</div>
                <TerminalDeviceOutputDigitalSensorAssociate 
                  terminalDevice={terminalDevice}
                  output={selectedOutput}
                  onTerminalDeviceSensorSelected={onOutputTerminalDeviceSensorSelected}
                  onTheoreticalFlowChange={onTheoreticalFlowChange}
                />
              </>
            }

            {isTerminalDeviceValidForOutputCalibration(terminalDevice) && (
              <>
                <div className="TabOutputConf"></div>
                <TerminalDeviceCalibrationTwoOptions
                  calibrationRawValue={
                    selectedOutput?.measurement?.calibrationRawValue
                  }
                  calibrationUnitValue={
                    selectedOutput?.measurement?.calibrationUnitValue
                  }
                  calibrationOptions={[10, 30, 60, 120, 240]}
                  onCalibrationRawValueChange={onCalibrationRawValueChange}
                  onCalibrationUnitValueChange={onCalibrationUnitValueChange}
                  terminalDevice={terminalDevice}
                  unit={UNITS.TIME}
                  rawTitle={"Tiempo (s)"}
                  unitsTitle={"Litros"}
                  output={selectedOutput}
                />
              </>
            )}
          </>
        )}

        <div style={{ marginTop: "2%", width: "100%" }}>
          <VoltageSelector
            terminalDevice={terminalDevice}
            eventSync={voltageSelectorEventSync}
          />
          <div className="laststatus">{laststatusCut}</div>
        </div>
        {selectedOutput !== undefined && <></>}
      </div>
    </>
  );
};

export const OutputConfigurationErrors = (props) => {
  const {
    errorAnyOutputActive,
    errorReportByDevice,
    errorReportByServer,
    errorNotMatchingWorkmode,
    successSetworkmode,
    errorApi,
    errorTasksDependency,
    successApi,
  } = props;

  return (
    <div>
      {errorAnyOutputActive && (
        <DangerComponent
          message={
            "Para poder modificar la polaridad de alguna salida hay que desactivar todas las salidas."
          }
        />
      )}
      {errorReportByDevice && (
        <DangerComponent
          message={
            "El TerminalDevice ha reportado error durante la confugración. Compruebe que todas las saldias están desactivadas y vuelva a intentarlo en unos minutos."
          }
        />
      )}
      {errorReportByServer && (
        <DangerComponent
          message={
            "El TerminalDevice no ha respondido al mensaje. Asegúrese que el dispositivo está conectado."
          }
        />
      )}
      {errorNotMatchingWorkmode && (
        <DangerComponent
          message={"Hay un estado inesperado actualice la página."}
        />
      )}
      {errorApi && (
        <DangerComponent
          message={
            errorTasksDependency
              ? "Existen tareas asociadas al Terminal. No puede modificar la polaridad."
              : "Error guardando los datos en el servidor."
          }
        />
      )}
      {(successSetworkmode || successApi) && (
        <CheckComponent
          message={
            (successApi
              ? "Se han guardado los datos correctamente en la nube. "
              : "") +
            (successSetworkmode
              ? "Se han configurado correctamente las salidas en el dispositivo."
              : "")
          }
        />
      )}
    </div>
  );
};

/*export const getWorkmode = (outputs, forExpectedActivationType) => {
  let workmode = 0;
  if (outputs) {
    const half = Math.trunc(outputs.length / 2);
    for (let i = 0; i < half; i++) {
      const output = outputs[i];
      const activationType = forExpectedActivationType ? output.getActivationTypeExpected() : output.getActivationType();
      if (
        activationType ===
        projectsConstants.global.activations.pulses_with_polarity
      ) {
        workmode |= 1 << (output.output - 1);
      } else {
        workmode &= ~(1 << (output.output - 1));
      }
    }
    workmode = (workmode << half) | workmode;
  }
  return workmode;
};*/
