import React, { useState, useEffect, useCallback } from "react";
import { ReactComponent as IcoSaveMoney } from "../../images/save-money.svg";
import { MultipleStatistics } from "../MultipleStatistics";
import { LiquidBarchart } from "../LiquidBarchart";
import { CardStatistics } from "./CardStatistics ";
import "./Statistics.sass";
import { ToggleComponent } from "../../../../components/Airframe/Toggle/Toggle";
import { DayChoose } from "../DayChoose";
import { PostigelService } from "../../../../../_services/postigel.service";
export const Statistics = (props) => {
  const actualdaytmp = new Date(Date.now());
  const actualday = `${actualdaytmp.getFullYear()}-${
    actualdaytmp.getMonth() + 1
  }-${actualdaytmp.getDate()}`;

  const { terminalId, terminal, liquid, dataContainer } = props;
  const [mapDataGroupByDate, setmapDataGroupByDate] = useState(undefined);
  const [dayselect, setdayselect] = useState(actualday);

  function compareValues(key, order = "asc") {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === "desc" ? comparison * -1 : comparison;
    };
  }
  useEffect(() => {
    const terminalIdTmp = terminalId;
    const terminalTmp = terminal.id;

    PostigelService.getAll(terminalIdTmp, terminalTmp)
      .then((postigels) => {
        if (postigels.length > 0) {
          const postigelSorted2 = postigels.sort(compareValues("receivedAt"));

          const map = new Map();
          postigelSorted2.forEach((report) => {
            let date = new Date(Date.parse(report.receivedAt));

            const data = {
              name: date.getHours() + ":" + date.getMinutes(),
              Desinfecciones: report.dosificationCounter,
              Detecciones: report.presenceCounter,
            };
            //ActualDay
            const key = `${date.getFullYear()}-${
              date.getMonth() + 1
            }-${date.getDate()}`;

            if (!map.has(key)) {
              map.set(key, { data: [] });
            }
            map.get(key).data.push(data);
          });
          console.log(map);
          setmapDataGroupByDate(map);

          const keys = Array.from(mapDataGroupByDate.keys());
          if (keys.length > 0) {
            const tmpActualday = keys[keys.length - 1];
            setdayselect(tmpActualday);
          }
        } else {
          console.log("No hay ninguno");
        }
      })
      .catch((error) => {
        console.log("Error obteniendo datos de postigel");
      });
  }, [mapDataGroupByDate, terminal.id, terminalId]);

  const [types, setTypes] = useState({
    Liquido: {
      label: "Líquido",
      active: true,
      color: "ToggleLiquid",
    },
    Detecciones: {
      label: "Detecciones",
      active: true,
      color: "ToggleDetection",
    },
    Temperatura: {
      label: "Temperatura",
      active: true,
      color: "ToggleTemp",
    },
    Desinfecciones: {
      label: "Desinfecciones",
      active: true,
      color: "ToggleDesinfection",
    },
  });

  const valueCardSaving = "6,01";

  const deactivateStatistic = useCallback(
    (type) => {
      console.log("Desactivando: " + type);

      switch (type) {
        case types.Liquido.label:
          types.Liquido.active = false;
          setTypes({ ...types });
          break;
        case types.Detecciones.label:
          types.Detecciones.active = false;
          setTypes({ ...types });
          break;
        case types.Temperatura.label:
          types.Temperatura.active = false;
          setTypes({ ...types });
          break;
        case types.Desinfecciones.label:
          types.Desinfecciones.active = false;
          setTypes({ ...types });
          break;
        default:
          console.log("Error");
          break;
      }
    },
    [types]
  );

  const activateStatistic = useCallback(
    (type) => {
      console.log("Activando: " + type);

      switch (type) {
        case types.Liquido.label:
          types.Liquido.active = true;
          setTypes({ ...types });
          break;
        case types.Detecciones.label:
          types.Detecciones.active = true;
          setTypes({ ...types });
          break;
        case types.Temperatura.label:
          types.Temperatura.active = true;
          setTypes({ ...types });
          break;
        case types.Desinfecciones.label:
          types.Desinfecciones.active = true;
          setTypes({ ...types });
          break;
        default:
          console.log("Error");
          break;
      }
    },
    [types]
  );

  const changeDay = useCallback((selectDay, navigation) => {
    setdayselect(selectDay);
  }, []);
  return (
    <>
      <div className="TitleMultipleStatics">
        <div className="titleMultipleStatistics">Estadísticas:</div>
        <DayChoose
          mapDataGroupByDate={mapDataGroupByDate}
          changeDay={changeDay}
        />
      </div>

      <div className="fatherTogglesStatistics">
        <div className="ToggleF">
          <ToggleComponent
            color={types.Detecciones.color}
            label={types.Detecciones.label}
            deactivateStatistic={deactivateStatistic}
            activateStatistic={activateStatistic}
            activate={types.Detecciones.active}
          />
        </div>
        <div className="ToggleF">
          <ToggleComponent
            color={types.Liquido.color}
            label={types.Liquido.label}
            deactivateStatistic={deactivateStatistic}
            activateStatistic={activateStatistic}
            activate={types.Liquido.active}
          />
        </div>
        <div className="ToggleF">
          <ToggleComponent
            color={types.Desinfecciones.color}
            label={types.Desinfecciones.label}
            deactivateStatistic={deactivateStatistic}
            activateStatistic={activateStatistic}
            activate={types.Desinfecciones.active}
          />
        </div>
      </div>

      <MultipleStatistics
        stadisticsActives={types}
        mapDataGroupByDate={mapDataGroupByDate}
        dayselect={dayselect}
      />

      <div className="titleSavePostigel">Ahorro:</div>
      <div className="cardsSavePostigel">
        <LiquidBarchart liquid={liquid} dataContainer={dataContainer} />
        <CardStatistics
          title={"Ahorro"}
          value={valueCardSaving}
          symbol={"€"}
          ico={<IcoSaveMoney className="IcoPostigelSave" />}
        />
      </div>
    </>
  );
};
