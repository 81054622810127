import React, { useState, useMemo } from "react";
import Axios from "axios";
import { useEffect } from "react";

const UserContext = React.createContext();

export const UserProvider = (props) => {
  const [user, setuser] = useState(JSON.parse(localStorage.getItem("user")));
  const [loggedIn, setloggedIn] = useState(user ? true :  false);
  const [error, setError] = useState(undefined);
  const [jwtToken, setjwtToken] = useState("");
  const [jwtPayloadDecoded, setjwtPayloadDecoded] = useState({});

  const getCleanJwtToken = (token) => {
    return token.substring(7, token.length);
  }

  const loadJwtPayload = (jwtToken) => {
    let firstDotIndex = jwtToken.indexOf(".");
    let lastDotIndex = jwtToken.lastIndexOf(".");
    let payloadString = jwtToken.substring(firstDotIndex + 1, lastDotIndex);
    return JSON.parse(window.atob(payloadString));
  }

  const login = useMemo(() => async (email, password) => {
    try {
      const {
        data,
        status,
      } = await Axios.post(`${process.env.REACT_APP_API_PATH}/login/email`, {
        email,
        password,
      });
      if (status === 200) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        data.password = password;
        localStorage.setItem("user", JSON.stringify(data));
        setuser(data);
        let jwtToken = getCleanJwtToken(data.token);
        let jwtPayloadDecoded = loadJwtPayload(jwtToken);
        setjwtToken(jwtToken);
        setjwtPayloadDecoded(jwtPayloadDecoded);
        setloggedIn(true);
        setError(false);
      } else {
        setuser(null);
        localStorage.removeItem("user");
        setloggedIn(false);
        setError(true);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        setuser(null);
        localStorage.removeItem("user");
        setloggedIn(false);
        setError(true);
      }
    }
  }, []);

  const logout = useMemo(() => async () => {
    setuser(null);
    localStorage.removeItem("user");
    setloggedIn(false);
    setjwtPayloadDecoded({});
    setjwtToken("");
  }, []);

  const validateToken = useMemo(() => async (user) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: user.token,
      };
      const requestOptions = {
        method: "POST",
        headers,
      };
      const { status } = await Axios.post(
        `${process.env.REACT_APP_API_PATH}/token/validate`,
        {},
        requestOptions
      );
      if (status === 200) {
        setuser(user);
        
        let jwtToken = getCleanJwtToken(user.token);
        let jwtPayloadDecoded = loadJwtPayload(jwtToken);
        setjwtToken(jwtToken);
        setjwtPayloadDecoded(jwtPayloadDecoded);
        setloggedIn(true);
        setError(false);
      } else {
        setuser(null);
        localStorage.removeItem("user");
        setjwtToken(undefined);
        setjwtPayloadDecoded(undefined);
        setloggedIn(false);
        setError(true);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        setuser(null);
        localStorage.removeItem("user");
        setloggedIn(false);
        setError(true);
      }
    }
  }, []);

  const resetPassword = useMemo(() => async(jwt, password) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    }
    const requestOptions = {
      method: "POST",
      headers,
    };

    return Axios
      .post(
        `${process.env.REACT_APP_API_PATH}/users/password_reset`,
        {password},
        requestOptions
      )
      .then(response => {
        return response.status === 200
      })
  }, [])

  const requestResetPassword = useMemo(() => async(email) => {
    const headers = {
      "Content-Type": "application/json"
    }
    const requestOptions = {
      method: "POST",
      headers,
    };

    return Axios
      .post(
        `${process.env.REACT_APP_API_PATH}/users/password_restore`,
        {email},
        requestOptions
      )
      .then(response => {
        return response.status === 202
      })
  }, [])

  useEffect(() => {
    async function loadingUser() {
      if(loggedIn){
        validateToken(user);
      }
    }
    loadingUser();
    return () => {};
  }, []);

  const value = useMemo(() => {
    return {
      user,
      login,
      logout,
      loggedIn,
      error,
      jwtToken,
      jwtPayloadDecoded,
      resetPassword,
      requestResetPassword
    };
  }, [user, loggedIn, error, login, logout, jwtToken, jwtPayloadDecoded, resetPassword, requestResetPassword]);

  return <UserContext.Provider value={value} {...props} />;
};

export const useUser = () => {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error("useUser debe estar dentro del proveedore UserContext");
  }
  return context;
};
