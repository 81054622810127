import React, { useState, useEffect, useCallback } from "react";

import { ReactComponent as IcoDisinfection } from '../../images/disinfection.svg';
import { ReactComponent as IcoLed } from '../../images/led.svg';
import { ReactComponent as IcoNozzle } from '../../images/nozzle.svg';
import { ReactComponent as IcoCalibrate } from '../../images/calibrate.svg';
import { CardConfpostigelEspecial } from "./CardConfpostigelEspecial";
import { Output } from "../../../../../classes/TerminalDevice/Output/Output";
import { OutputService } from "../../../../../_services/outputcontroller.service";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import { usePublisher } from "../../../../../context/publish-context";
import uuid from "uuid/v4";
import { MESSAGE_TYPE_ACTION } from "../../../../../_constants/messageType.constants";

export const PUMP_OUTPUT_ID = 3;
export const LED_OUTPUT_ID = 4;
export const DELAY_DESINF_OUTPUT_ID = 2;

export const ConfigurationPostigel = (props) => {



  const { closeConfiguration, mmsTerminalDevice, onOutputsUpdate, openCalibrationMenu } = props;
  const getDefaultTime = useCallback(() => {
    return {
      time: 0,
      led: 0,
      minDesinfection: 0
    }
  }, []);
  const [configuration, setconfiguration] = useState(getDefaultTime());
  const [pumpOutput, setPumpOutput] = useState(undefined);
  const [ledOutput, setLedOutput] = useState(undefined);
  const [delayDesinfOutput, setDelayDesinfOutput] = useState(undefined);
  const [error, seterror] = useState(false);
  const { publish } = usePublisher();

  const types = {
    time: "time",
    led: "led",
    minDesinfection: "minDesinfection"
  };

  const getOutput = useCallback((outputs, outputId) => {
    let filtered = outputs.filter(output => {
      return output?.output === outputId
    });

    return filtered.length > 0 ? filtered[0] : undefined;
  }, []);

  const calculateTimes = useCallback(() => {
    let times = getDefaultTime();
    if (mmsTerminalDevice?.outputs && mmsTerminalDevice?.outputs instanceof Array && mmsTerminalDevice?.outputs.length > 0) {
      const pumpOutput = getOutput(mmsTerminalDevice.outputs, PUMP_OUTPUT_ID);
      if (pumpOutput && pumpOutput instanceof Output) {
        const pumpValue = (pumpOutput.intensity || 0) / 1000;
        times = { ...times, time: pumpValue };
      }
      setPumpOutput(pumpOutput);

      const ledOutput = getOutput(mmsTerminalDevice.outputs, LED_OUTPUT_ID);
      if (ledOutput && pumpOutput instanceof Output) {
        const pumpValue = (ledOutput.intensity || 0) / 1000;
        times = { ...times, led: pumpValue };
      }
      setLedOutput(ledOutput);

      const delayDesinfOutput = getOutput(mmsTerminalDevice.outputs, DELAY_DESINF_OUTPUT_ID);
      if (delayDesinfOutput && pumpOutput instanceof Output) {
        const pumpValue = (delayDesinfOutput.intensity || 0) / 1000;
        times = { ...times, minDesinfection: pumpValue };
      }
      setDelayDesinfOutput(delayDesinfOutput);
    }

    setconfiguration(times);
  }, [mmsTerminalDevice, getDefaultTime, getOutput]);

  useEffect(() => {
    calculateTimes();
  }, [mmsTerminalDevice])







  const sendDatatoServer = useCallback(
    async (toPut, toPost) => {
      let mustNotify = false;
      if (toPut !== undefined && toPut instanceof Array && toPut.length > 0) {
        try {
          let outputs = await OutputService.putTerminalDeviceOutputConfiguration(mmsTerminalDevice.terminal, mmsTerminalDevice.id, toPut);
          onOutputsUpdate(outputs);
          mustNotify = true;
          closeConfiguration();
        } catch (e) {
          console.log("Error guardando");
          seterror(true);
        }
      }
      if (toPost !== undefined && toPost instanceof Array && toPost.length > 0) {
        toPost.forEach(output => {
          try {
            let outputs = OutputService
              .postTerminalDeviceOutputConfiguration(mmsTerminalDevice.terminal, mmsTerminalDevice.id, output)
            onOutputsUpdate([outputs]);
            mustNotify = true;
            closeConfiguration();
          } catch (e) {
            console.log("Error guardando");
            seterror(true);
          }
        })
      }

      if (mustNotify) {
        publish(projectsConstants.postigel.actions.specconfig, {
          type: MESSAGE_TYPE_ACTION,
          id: uuid(),
          data: {
            target_id: mmsTerminalDevice.id
          }
        },
          projectsConstants.postigel.id);
      }
    },
    [mmsTerminalDevice, OutputService, onOutputsUpdate, publish]
  );

  const getOutputDto = useCallback((outputId, timeInSeconds) => {
    let pump = new Output();
    pump.terminalDevice = mmsTerminalDevice.id;
    pump.output = outputId;
    pump.intensity = timeInSeconds * 1000;
    pump.currentState = projectsConstants.global.states.output_deactivated;
    pump.expectedState = projectsConstants.global.states.output_deactivated;
    pump.readyToWork = true;
    pump.activationType = projectsConstants.global.activations.continuous;
    pump.activationTypeExpected = projectsConstants.global.activations.continuous;
    pump.activationParams = JSON.stringify({ sourceSession: uuid(), targetSession: "" });
    pump.description = "";
    pump.currentAction = 0;
    return pump;
  }, [mmsTerminalDevice]);

  const saveDataPostigel = useCallback(
    () => {
      const toPut = [];
      const toPost = [];
      const pumpOutputDTO = getOutputDto(PUMP_OUTPUT_ID, configuration.time);
      if (pumpOutput !== undefined) {
        toPut.push(pumpOutputDTO);
      } else {
        toPost.push(pumpOutputDTO)
      }

      const ledOutputDTO = getOutputDto(LED_OUTPUT_ID, configuration.led);
      if (ledOutput !== undefined) {
        toPut.push(ledOutputDTO);
      } else {
        toPost.push(ledOutputDTO)
      }

      const delayDesingOutputDTO = getOutputDto(DELAY_DESINF_OUTPUT_ID, configuration.minDesinfection);
      if (delayDesinfOutput !== undefined) {
        toPut.push(delayDesingOutputDTO);
      } else {
        toPost.push(delayDesingOutputDTO)
      }

      sendDatatoServer(toPut, toPost);
      //closeConfiguration();
    },
    [configuration, closeConfiguration, sendDatatoServer, pumpOutput, ledOutput, delayDesinfOutput, getOutputDto]
  );

  const registerchangesSlider = useCallback(
    (id, value) => {
      let newConfiguration = { ...configuration };
      newConfiguration[id] = value;
      setconfiguration(newConfiguration);
    },
    [configuration]
  );
  return (
    <div className="panelConfPostigel">
      <div className="postigelcenter">
        <div className="dataUiPostigel configurationsPostigel">


          <div className="subtitlePostigel">
            Configuraciones:
            </div>


          {error ? <div className="ErrorPostigelmvl">Error, guardando datos en el servidor.</div> : <div className="spacemvl"></div>}

          <div className="configurationscardsPostigel">
            <CardConfpostigelEspecial
              saveConfig={registerchangesSlider}
              title={"Tiempo de la bomba"}
              //value={configuration.time}
              value={configuration.time}
              data={pumpOutput}
              min={0} max={2.5} id={types.time}
              icon={<><IcoNozzle className="IcoPostigelsub" /></>}
            />

            <CardConfpostigelEspecial
              saveConfig={registerchangesSlider}
              title={"Tiempo del led"}
              value={configuration.led}
              data={ledOutput}
              min={0} max={5} id={types.led}
              icon={<><IcoLed className="IcoPostigelsub" /></>}
            />

            <CardConfpostigelEspecial
              saveConfig={registerchangesSlider}
              title={"Tiempo mínimo entre desinfecciones"}
              value={configuration.minDesinfection}
              data={delayDesinfOutput}
              min={0} max={15} id={types.minDesinfection}
              icon={<><IcoDisinfection className="IcoPostigelsub" /></>}
            />
          </div>

        </div>
      </div>



      {error && <div className="postigelcenter"><div className="ErrorPostigelpc">Error, guardando datos en el servidor.</div> </div>}

      <div className="ButtonaddCalibratePostigel">
        <div className="postigelcenterButton">

          <div className="DivButtonsPostigel">
            <div
              className="ButtonC2 smallb Secondary2inf helpsecondary"
              onClick={(e) => saveDataPostigel()}
            >
              Aceptar
              </div>

          </div>

        </div>
        <div className="CalibratePostigel" onClick={(e) => openCalibrationMenu()}>
          <IcoCalibrate className="IcoPostigelsub" /></div>
      </div>
    </div>
  );
};
