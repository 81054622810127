import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
export const Spinner = (props) => {
  const color="grey";
  const {nameclass,size}= props;
  return (
    <>
      <div className={nameclass}>
        <ClipLoader size={size} color={color} loading={true} />
      </div>
    </>
  );
};
