import React, { useState } from "react";
//import Dropdown from "react-dropdown";
//import "./Dropdown.sass";
import Select from "react-select";
//import Dropdown from 'rc-dropdown';
//import "react-dropdown/style.css";
export const DropdownC = (props) => {
  const { placeholder, onChange, options, value, changestyles, disabled, isClearable } = props;

  const customStyles = {
    control: (base) => ({
      ...base,
    }),
  };
  const [modstyles, setmodstyles] = useState(changestyles ? customStyles : "");

  return (
    <>
      { /*<Select
        menuPortalTarget={document.body}
        //Fix z index
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        classNamePrefix={"my-custom-react-select"}
        placeholder={placeholder}
        styles={modstyles}
        className="Selector"
        options={options}
        onChange={(e) => onChange(e)}
        isSearchable={false}
        inputProps={{ readOnly: true }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            neutral50: "black", // Placeholder color
          },
        })}
     
        {...props}
      />*/}
      <Select 
        placeholder={placeholder}
        options={options}
        onChange={onChange}
        isSearchable={false}
        value={value}
        className="Selector"
        isDisabled={disabled}
        isClearable={isClearable}
      ></Select>
    </>
  );
};
