import React, { useCallback, useMemo } from "react";
import axios from "axios";
import { Authservice } from "./Authservice";

const TriggersContext = React.createContext();

export const TriggersProvider = (props) => {
  const getAll = useCallback((terminalId, terminalDeviceId) => {
    return axios
      .get(
        `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/triggers/`,
        Authservice.getRequestOptions("GET")
      )
      .then(Authservice.handleResponse)
      .then((triggers) => {
        return parseTriggers(triggers);
      });
  }, []);

  const getOne = useCallback((terminalId, terminalDeviceId, triggerId) => {
    return axios
      .get(
        `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/triggers/${triggerId}`,
        Authservice.getRequestOptions("GET")
      )
      .then(Authservice.handleResponse)
      .then((trigger) => {
        return trigger;
      });
  }, []);

  const save = useCallback((terminalId, terminalDeviceId, trigger) => {
    return axios
      .post(
        `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/triggers/`,
        trigger,
        Authservice.getRequestOptions("POST")
      )
      .then(Authservice.handleResponse)
      .then((trigger) => {
        return trigger;
      });
  }, []);

  const deleteTrigger = useCallback(
    (terminalId, terminalDeviceId, triggerId) => {
      return axios
        .delete(
          `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/triggers/${triggerId}`,
          Authservice.getRequestOptions("DELETE")
        )
        .then(Authservice.handleResponse)
        .then((trigger) => {
          return trigger;
        });
    },
    []
  );

  const update = useCallback(
    (terminalId, terminalDeviceId, triggerId, trigger) => {
      return axios
        .put(
          `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/triggers/${triggerId}`,
          trigger,
          Authservice.getRequestOptions("PUT")
        )
        .then(Authservice.handleResponse)
        .then((trigger) => {
          return trigger;
        });
    },
    []
  );

  function parseTriggers(triggers) {
    if (triggers && triggers instanceof Array) {

      return triggers
        .sort((first, second) => {
          if (first?.id < second?.id) {
            return -1;
          }
          if (first?.id > second?.id) {
            return 1;
          }
          return 0;
        })
        .filter((trigger) => trigger && !trigger?.deleted_by_user)
        .map((trigger, index) => {
          return trigger;
        });
    }
    return triggers;
  }

  const updateConfig = useCallback(
    (terminalId, terminalDeviceId, triggerConfigOptions) => {
      return axios
        .put(
          `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/triggers/config/`,
          triggerConfigOptions,
          Authservice.getRequestOptions("PUT")
        )
        .then(Authservice.handleResponse)
        .then((triggerConfig) => {
          return triggerConfig;
        });
    },
    []
  );


  const value = useMemo(() => {
    return {
      getAll,
      getOne,
      save,
      deleteTrigger,
      update,
      updateConfig
    };
  }, [getAll, getOne, save, deleteTrigger, update, updateConfig]);

  return <TriggersContext.Provider value={value} {...props} />;
};

export const useTriggers = () => {
  const context = React.useContext(TriggersContext);
  if (!context) {
    throw new Error(
      "useTriggers debe estar dentro del proveedor TriggersContext"
    );
  }
  return context;
};
