import React, { useEffect, useState } from "react";

import { ReactComponent as IcoDragDrop } from "./DragDropIco.svg";

//import { zoomIn, fadeInLeft, zoomOutRight, fadeIn } from "react-animations";
import { fadeIn } from "react-animations";
import Radium, { StyleRoot } from "radium";

const styles = {
  AnimSecond: {
    animation: "x 4s",
    animationName: Radium.keyframes(fadeIn, "Animacion1"),
  },
};
export const TitleDragDrop = (props) => {
  const styles = {
    AnimSecond: {
      animation: "x 4s",
      animationName: Radium.keyframes(fadeIn, "Animacion1"),
    },
  };

  const { desactivate, reorderedError } = props;
  const [Desactivate, setDesactivate] = useState(0);
  const [nameClass, setnameClass] = useState("titleDragDrop");
  useEffect(() => {
    if (reorderedError) {
      setnameClass("titleDragDropwithError");
    }
  }, [reorderedError]);

  return (
    <>
      {!desactivate ? (
        <div className="titleDragDrop">
          <StyleRoot style={styles.AnimSecond}>
            <IcoDragDrop className="IcoDrag " />
          </StyleRoot>
        </div>
      ) : (
        <div className={nameClass}>
          {reorderedError && (
            <div className="Error">
              Si están en verde no pueden ser cambiados. 
              Estas fechas ya han sido notificadas en el servidor.
            </div>
          )}

          <StyleRoot style={styles.AnimSecond}>
            <IcoDragDrop className="IcoDeactivate" />
          </StyleRoot>
        </div>
      )}
    </>
  );
};
