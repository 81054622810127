import React, { useCallback } from "react";
import { Spinner } from "../../../Airframe/Spinner/Spinner";
import { OutputsView } from "../../OutputsView/OutputsView";
import { TerminalDeviceSensorTriggerAdditionalActionsInfo } from "./TerminalDeviceSensorTriggerAdditionalActionsInfo";
import { TriggerConfigurationTypes } from "./TerminalDeviceSensorTriggerConfigurator";
import "./triggers.sass";

export const states = {
  IDLE: "IDLE",
  EDIT: "EDIT",
  REMOVE: "REMOVE",
};

export const TerminalDeviceSensorTriggerCard = (props) => {
  const typesCondition = {
    GT: ">",
    LT: "<",
    SIMPLE_SMALLER_THAN: "<",
    SIMPLE_GREATER_THAN: ">"
  };

  const { trigger, terminalDevices, state, onClickEdit, onClickRemove, triggerConfigurationType, title } = props;

  const internalOnClickEdit = useCallback(() => {
    if (!trigger?.deletedByUser && onClickEdit) {
      onClickEdit(trigger);
    }
  }, [onClickEdit, trigger]);

  const internalOnClickRemove = useCallback(() => {
    if (onClickRemove) {
      onClickRemove(trigger);
    }
  }, [onClickRemove, trigger]);

  const getOutputs = useCallback(() => {
    if (trigger?.activations instanceof Array) {
      const map = new Map();
      trigger.activations.forEach((triggerActivation) => {
        if (!map.has(triggerActivation.terminalDeviceOutputs)) {
          map.set(triggerActivation.terminalDeviceOutputs, []);
        }

        const terminalDeviceOutputs = map.get(
          triggerActivation.terminalDeviceOutputs
        );
        if (terminalDevices instanceof Array) {
          terminalDevices
            .filter((td) => td?.id === triggerActivation.terminalDeviceOutputs)
            .flatMap((td) => td.outputs)
            .filter((tdOutput) => (1 << (tdOutput?.output - 1)) & triggerActivation?.outputs)
            .forEach((tdOutput) => terminalDeviceOutputs.push(tdOutput));
        }
      });

      const components = [];
      map.forEach((value, key) =>
        components.push(
          <div key={key}>
            {(terminalDevices &&
              terminalDevices
                .filter((td) => td?.id === key)
                .map((td, index) => (
                  <h6 key={index} className="card-subtitle mb-2 text-muted">
                    Se activan las salidas de {td?.id}-{td?.description || td?.device?.id}
                  </h6>
                ))) || <div key={`${key}-ids`}>{key}</div>}
            <div key={ `${key}-outputs`}
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {value.map((output, index) => (
                <OutputsView
                  key={output.terminalDevice + "-" + output.output}
                  output={output}
                  disabled={false}
                  onClickOnDisabled={() => {}}
                  onClick={() => {}}
                  showInfoOutputModified={() => {}}
                  hideDescription={true}
                />
              ))}
            </div>
          </div>
        )
      );
      return components;
    }
    return <></>;
  }, [terminalDevices, trigger?.activations]);

  const getTitle = useCallback(() => {
    if(title){
      return title.charAt(0).toUpperCase() + title.slice(1);
    }
    return "Consigna";
  }, [title])

  return (
    <>
      <div
        className={`${trigger?.deletedByUser ? "border-danger" : "border-primary"} card  mb-3  ${
          state === states.EDIT ? "triggerCardSelected" : ""
        }`}
        style={{ margin: "0.5rem", cursor: trigger?.deletedByUser ? "not-allowed" : "pointer" }}
      >
        <div className="card-body" onClick={internalOnClickEdit}>
          <div className="triggerCardHeader">
            <h6 className="card-subtitle mb-2 text-muted">{getTitle()}</h6>
            {!trigger?.deletedByUser && <div style={{ display: "flex" }}>
              <button
                style={{ marginRight: "1rem" }}
                className="btn btn-primary btn-sm rounded-1 triggerCardHeaderIcon"
                type="button"
                data-toggle="tooltip"
                data-placement="top"
                title="Editar"
                //onClick={internalOnClickEdit}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                className="btn btn-danger btn-sm rounded-1 triggerCardHeaderIcon"
                type="button"
                data-toggle="tooltip"
                data-placement="top"
                title="Eliminar"
                onClick={(event) => { event.stopPropagation(); internalOnClickRemove()}}
              >
                <i className="fa fa-trash"></i>
              </button>
            </div>
            }
          </div>

          {triggerConfigurationType !== TriggerConfigurationTypes.BUTTON && 
          <div className="triggerCardHeader" style={{justifyContent: "space-around"}}>
            <div className="card-title trigger-title">
              <h4 style={{ margin: "0.1rem" }}>
                Valor {typesCondition[trigger?.operator || ""]}{" "}
                {trigger?.setpoint}
              </h4>
            </div>
          </div>
          }
          <div className="TabOutputConf"></div>
          <div className="card-text">
            {(trigger?.notified === false || trigger?.deletedByUser) && (
              <div className="head_TsmConfigure">
                {trigger?.deletedByUser && <p className="text-danger">Eliminando del dispositivo...</p>}
                {!trigger?.deletedByUser && <p>Sincronizando con el dispositivo...</p>}
                <Spinner></Spinner>
              </div>
            )}
            <TerminalDeviceSensorTriggerAdditionalActionsInfo trigger={trigger}/>
            <div>{getOutputs()}</div>
          </div>
        </div>
      </div>
    </>
  );
};
