export class ProgramElement {
    id?: number;
    activeTime: string;
    outputs: number;

	deleted_by_user?: Boolean;
	deleted_by_user_at?: any;

    // USO front-end, no forma parte del DTO.
    index: number;
    hour: number;
    minute: number;
    new?: Boolean;
    modified?: Boolean;
    removed?: Boolean;
    
    constructor (activeTime: string, index: number, hour: number, minute: number, outputs: number){
        this.activeTime = activeTime;
        this.index = index;
        this.hour = hour;
        this.minute = minute;
        this.outputs = outputs;
    }
	
    
    getEndDateTime(startDatetime: Date) : Date{
        const [hour, minute] = this.activeTime ? this.activeTime.split(":") : [0,0];
        if(startDatetime && startDatetime instanceof Date){
            startDatetime = this.addHours(startDatetime, this.hour);
            startDatetime = this.addMinutes(startDatetime, this.minute);
        }
        return startDatetime;
    }
    getOutputsList() : any{
        const outputsList = [];

        let offset = 1;
        let i = 0;
        while(offset <= this.outputs){
            if(offset & this.outputs){
                outputsList.push(i + 1);
            }
            offset = 1 << ++i;
        }

        return outputsList;
    }

    getName(): string{
        return `S${this.index + 1}`
    }

    addHours(date: Date, h: number): Date{
        date.setTime(date.getTime() + h * 60 * 60 * 1000);
        return date;
    }

    addMinutes(date: Date, m: number): Date{
        date.setTime(date.getTime() + m * 60 * 1000);
        return date;
    }
}