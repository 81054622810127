import React, { useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";

import { ReactComponent as Configure } from "./../../../images/ImagesTerminalsDevice/configure.svg";
import { ReactComponent as Stats } from "./../../../images/ImagesTerminalsDevice/stats.svg";
import { ReactComponent as InfoSvg } from "./../../../images/ImagesTerminalsDevice/question.svg";
import { ReactComponent as Calendar } from "./../../../images/ImagesTerminalsDevice/calendar.svg";
import { ReactComponent as Home } from "./../../../images/ImagesTerminalsDevice/home.svg";
import Popup from "reactjs-popup";

export const TerminalDeviceNavbarStates = {
  HOME: "HOME",
  CONFIG: "CONFIG",
  STATS: "STATS",
  PROGRAMS: "PROGRAMS",
};

export const TerminalDeviceNavbar = (props) => {
  const {
    showConfig,
    showHome,
    showStats,
    showPrograms,
    showInfo,
    infoComponent,
    onOptionClick,
  } = props;

  const infoStates = {
    HIDEN: "HIDEN",
    SHOW: "SHOW",
  };
  const initInfoState = useMemo(() => {
    return {
      state: infoStates.HIDEN,
    };
  }, [infoStates.HIDEN]);

  const [infoState, setInfoState] = useState(initInfoState);

  const onConfigClick = useCallback(() => {
    onOptionClick(TerminalDeviceNavbarStates.CONFIG);
  }, [onOptionClick]);

  const onHomeClick = useCallback(() => {
    onOptionClick(TerminalDeviceNavbarStates.HOME);
  }, [onOptionClick]);

  const onStatsClick = useCallback(() => {
    onOptionClick(TerminalDeviceNavbarStates.STATS);
  }, [onOptionClick]);

  const onProgramsClick = useCallback(() => {
    onOptionClick(TerminalDeviceNavbarStates.PROGRAMS);
  }, [onOptionClick]);

  const onInfoClick = useCallback(() => {
    setInfoState({ state: infoStates.SHOW });
  }, [infoStates.SHOW]);

  const onCloseInfo = useCallback(() => {
    setInfoState({ state: infoStates.HIDEN });
  }, [infoStates.HIDEN]);

  return (
    <div className="ButtonsChangeAnimation">
      {showConfig && (
        <Configure
          alt="Config"
          className="PicPlugTD Colorsvg"
          onClick={(e) => onConfigClick()}
        />
      )}

      {showHome && !showConfig && (
        <Home
          alt="Home"
          className="PicPlugTD"
          onClick={(e) => onHomeClick()}
        ></Home>
      )}

      <div className="twoico">
        {showStats && (
          <Stats
            alt="Stats"
            className="PicPlugTD Colorsvg"
            onClick={(e) => onStatsClick()}
          ></Stats>
        )}
        {showPrograms && (
          <Calendar
            alt="Tasks"
            className="PicPlugTD confOutputs Colorsvg"
            onClick={(e) => onProgramsClick()}
          />
        )}
        {showInfo && (
          <InfoSvg
            className="ButtonProgram Info"
            onClick={(e) => onInfoClick()}
          />
        )}
      </div>

      {infoState.state === infoStates.SHOW && (
        <Popup open={true} closeOnDocumentClick onClose={onCloseInfo}>
          <div>
            <a className="close" onClick={() => onCloseInfo()}>
              &times;
            </a>
            <div>{infoComponent || <></>}</div>
          </div>
        </Popup>
      )}
    </div>
  );
};

TerminalDeviceNavbar.propTypes = {
  showConfig: PropTypes.bool,
  showHome: PropTypes.bool,
  showStats: PropTypes.bool,
  showPrograms: PropTypes.bool,
  showInfo: PropTypes.bool,
  infoComponent: PropTypes.element,
  onOptionClick: PropTypes.func.isRequired,
};
