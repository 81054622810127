import React from "react";
import PropTypes from "prop-types";

export const CalibrationStepError = (props) => {

  if(props.isUnknownState){
    return <div>¡ESTADO INESPERADO! {props.unknownState}</div>;
  }

  if(props.isNotifyingError){
    return <div>No se ha podido contactar con el dispositivo.</div>;
  }

  return (
    <div>
      No se ha podido realizar el calibrado. Los valores leidos por el sensor no
      son estables.
    </div>
  );
};

CalibrationStepError.propTypes = {
  isNotifyingError: PropTypes.bool,
  isUnknownState: PropTypes.bool,
  unknownState: PropTypes.string
}
