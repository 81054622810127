import React, { useCallback, useMemo } from "react";
import _ from "lodash";
import { EndoutputMQTT } from "../../MQTT/EndoutputMQTT";
import { ActoutputMQTT } from "../../MQTT/ActoutputMQTT";
import { UpdatesstateMQTT } from "../../MQTT/Updatesstate";
import { projectsConstants } from "../../../../_constants/projects.constants";
import { Output } from "../../../../classes/TerminalDevice/Output/Output";
import { UpdateoutputsMQTT } from "../../MQTT/UpdateoutputsMQTT";
import { SetWorkModeMQTT } from "../../MQTT/SetWorkModeMQTT";
import { AdvancedActoutputMQTT } from "../../MQTT/AdvancedActoutput";
import { useApp } from "../../../../context/app-context";
import { useTerminalDevice } from "../../../../context/terminal-device-context";
import { LoraActoutputMQTT } from "../../MQTT/LoraActoutputMQTT";
import { LoraEndoutputMQTT } from "../../MQTT/LoraEndoutputMQTT";

export const TerminalDeviceOutputStatesHandler = (props) => {
  const {
    output_active: OUTPUT_ACTIVE,
    output_deactivated: OUTPUT_DEACTIVATED,
    output_transition: OUTPUT_TRANSITION,
  } = projectsConstants.global.states;

  const {
    updateTerminalDevice,
    reloadTerminalDevice,
  } = useApp();

  const { terminalDevice } = useTerminalDevice();

  const outputs = useMemo(() => terminalDevice?.outputs || [], [
    terminalDevice?.outputs,
  ]);

  const renewOutputObject = useCallback(
    (output) => {
      const outputs = terminalDevice.outputs.map((sourceOutput) => {
        if (sourceOutput.output === output.output) {
          return output;
        }
        return sourceOutput;
      });
      terminalDevice.outputs = outputs;
      updateTerminalDevice(terminalDevice)
    },
    [updateTerminalDevice, terminalDevice]
  );

  //#region ACTOUTPUT
  const processActoutputMqtt = useCallback(
    (topic, message) => {
      let actoutput = message?.data?.actoutput;
      if (actoutput && outputs instanceof Array) {
        const success =
          message?.data?.success === projectsConstants.global.codes.ACK;

        outputs
          .filter(
            (output) =>
              output.terminalDevice === actoutput.target_id &&
              output.output === actoutput.target_output
          )
          .forEach((output) => {
            output.currentState = success ? OUTPUT_ACTIVE : OUTPUT_DEACTIVATED;
            renewOutputObject(
              Output.parseOutputDtoFromObject(_.cloneDeep(output))
            );
          });
      }
    },
    [outputs, renewOutputObject, OUTPUT_ACTIVE, OUTPUT_DEACTIVATED]
  );

  const processActoutputErrorMqtt = useCallback(
    (topic, message) => {
      let actoutput = message?.data;

      if (actoutput) {
        outputs
          .filter(
            (output) =>
              output.terminalDevice === actoutput.target_id &&
              output.output === actoutput.target_output &&
              output.prevState &&
              output.currentState === OUTPUT_TRANSITION
          )
          .forEach((output) => {
            output.currentState = output.prevState;
            renewOutputObject(
              Output.parseOutputDtoFromObject(_.cloneDeep(output))
            );
          });
      }
    },
    [outputs, renewOutputObject, OUTPUT_TRANSITION]
  );
  //#endregion

  //#region ADVANCED ACTOUTPUT
  const processAdvancedActoutputMqtt = useCallback(
    (topic, message) => {
      const targetId = message?.data?.target_id;
      const actions = message?.data?.actions;
      const success = message?.data?.success;
      if (targetId === terminalDevice?.id && actions instanceof Array) {
        actions
          .filter((action) => action?.prevId === 0)
          .forEach((action) =>
            outputs
              .filter(
                (output) =>
                  ((1 << (output.output - 1)) & action?.outputs) > 0
              )
              .forEach((output) => {
                output.currentState = success ? OUTPUT_ACTIVE : OUTPUT_DEACTIVATED;
                renewOutputObject(
                  Output.parseOutputDtoFromObject(_.cloneDeep(output))
                );
              })
          );
      }
    },
    [OUTPUT_ACTIVE, OUTPUT_DEACTIVATED, outputs, renewOutputObject, terminalDevice?.id]
  );

  const processAdvancedActoutputErrorMqtt = useCallback((topic, message) => {
    const targetId = message?.data?.target_id;
    const actions = message?.data?.actions;
    if (targetId === terminalDevice?.id && actions instanceof Array) {
      actions
        .filter((action) => action?.prevId === 0)
        .forEach((action) =>
          outputs
            .filter(
              (output) =>
                ((1 << (output.output - 1)) & action?.outputs) > 0
                && output.prevState
                && output.currentState === OUTPUT_TRANSITION
            )
            .forEach((output) => {
              output.currentState = output.prevState;
              renewOutputObject(
                Output.parseOutputDtoFromObject(_.cloneDeep(output))
              );
            })
        );
    }
  }, [OUTPUT_TRANSITION, outputs, renewOutputObject, terminalDevice?.id]);

  //#endregion

  //#region ENDOUTPUT

  const processEndoutputMqtt = useCallback(
    (topic, message) => {
      let endoutput = message?.data?.endoutput;
      if (endoutput) {
        const success =
          message?.data?.success === projectsConstants.global.codes.ACK;

        outputs
          .filter(
            (output) =>
              output.terminalDevice === endoutput.target_id &&
              (output.output === endoutput.output ||
                endoutput.output ===
                  projectsConstants.master_outputs.all_outputs)
          )
          .forEach((output) => {
            if (success) {
              // Actualizamos el estado al de desactivado
              output.currentState = OUTPUT_DEACTIVATED;
            } else {
              if (output.currentState === OUTPUT_TRANSITION) {
                output.currentState = OUTPUT_ACTIVE;
              }
            }
            renewOutputObject(
              Output.parseOutputDtoFromObject(_.cloneDeep(output))
            );
          });
      }
    },
    [
      outputs,
      renewOutputObject,
      OUTPUT_DEACTIVATED,
      OUTPUT_TRANSITION,
      OUTPUT_ACTIVE,
    ]
  );

  const processEndoutputErrorMqtt = useCallback(
    (topic, message) => {
      let endoutput = message?.data;
      if (endoutput) {
        outputs
          .filter(
            (output) =>
              output.terminalDevice === endoutput.target_id &&
              (output.output === endoutput.output ||
                endoutput.output ===
                  projectsConstants.master_outputs.all_outputs) &&
              output.prevState &&
              output.currentState === OUTPUT_TRANSITION
          )
          .forEach((output) => {
            output.currentState = output.prevState;
            renewOutputObject(
              Output.parseOutputDtoFromObject(_.cloneDeep(output))
            );
          });
      }
    },
    [outputs, renewOutputObject, OUTPUT_TRANSITION]
  );

  //#endregion

  //#region UPDATESSTATE
  const processUpdatesstateMqtt = useCallback(
    (topic, message) => {
      let states = message?.data?.devices_state;
      const terminalDeviceId =
        outputs && outputs.length > 0 && outputs[0].terminalDevice;
      states
        .filter(
          (terminalDeviceState) =>
            terminalDeviceState?.target_id === terminalDeviceId
        )
        .map((terminalDeviceState) => terminalDeviceState.outputs)
        .forEach((outputsState) => {
          outputs
            .map((output) => {
              const realState = outputsState & (1 << (output.output - 1)) ? OUTPUT_ACTIVE : OUTPUT_DEACTIVATED
              if(output.currentState === OUTPUT_TRANSITION){
                output.prevState = realState;
              }
              else{
                output.currentState = realState;
              }

              return Output.parseOutputDtoFromObject(_.cloneDeep(output));
            })
            .forEach(renewOutputObject);
        });
    },
    [outputs, renewOutputObject, OUTPUT_ACTIVE, OUTPUT_DEACTIVATED, OUTPUT_TRANSITION]
  );

  const processUpdateoutputsMqtt = useCallback(
    (topic, message) => {
      let targetId = message?.data?.target_id;
      let outputsState = message?.data?.outputs;
      outputs
        .filter((output) => output?.terminalDevice === targetId)
        .map((output) => {

          const realState = outputsState & (1 << (output.output - 1)) ? OUTPUT_ACTIVE : OUTPUT_DEACTIVATED
          if(output.currentState === OUTPUT_TRANSITION){
            output.prevState = realState;
          }
          else{
            output.currentState = realState;
          }

          return Output.parseOutputDtoFromObject(_.cloneDeep(output));
        })
        .forEach(renewOutputObject);
    },
    [OUTPUT_ACTIVE, OUTPUT_DEACTIVATED, OUTPUT_TRANSITION, renewOutputObject, outputs]
  );
  //#endregion

  //#region SETWORKMODE
  const processSetworkmodeMsg = useCallback(
    (messageId, message) => {
      const success = message?.data?.success;
      const targetId = message?.data?.target_id;
      const terminalDeviceId =
        outputs && outputs.length > 0 && outputs[0].terminalDevice;
      if (success && targetId === terminalDeviceId) {
        reloadTerminalDevice(terminalDeviceId);
      }
    },
    [outputs, reloadTerminalDevice]
  );

  const processSetworkmodeErrorMsg = useCallback((messageId, message) => {},
  []);
  //#endregion


  //#region LoRA

  const processLoraActoutputMsg = useCallback(
    (topic, message) => {
      const {target_id: targetId, success} = message?.data;
      const actoutputOutputs = message?.data?.outputActivation?.outputs
      if (targetId === terminalDevice?.id) {
        outputs
              .filter(
                (output) =>
                  ((1 << (output.output - 1)) & actoutputOutputs) > 0
              )
              .forEach((output) => {
                output.currentState = success ? OUTPUT_ACTIVE : OUTPUT_DEACTIVATED;
                renewOutputObject(
                  Output.parseOutputDtoFromObject(_.cloneDeep(output))
                );
              })
      }
    },
    [OUTPUT_ACTIVE, OUTPUT_DEACTIVATED, outputs, renewOutputObject, terminalDevice?.id]
  );

  const processLoraActoutputErrorMsg = useCallback((topic, message) => {
    console.log("No implementado")
  }, []);


  const processLoraEndoutputMsg = useCallback(
    (topic, message) => {
      let { endOutput, success} = message?.data;
      if (endOutput) {
        
        outputs
          .filter(
            (output) =>
              output.terminalDevice === endOutput.target_id &&
              ( ((1 << (output.output - 1)) & endOutput.outputs) > 0 ||
                endOutput.outputs === projectsConstants.master_outputs.all_outputs)
          )
          .forEach((output) => {
            if (success) {
              // Actualizamos el estado al de desactivado
              output.currentState = OUTPUT_DEACTIVATED;
            } else {
              if (output.currentState === OUTPUT_TRANSITION) {
                output.currentState = OUTPUT_ACTIVE;
              }
            }
            renewOutputObject(
              Output.parseOutputDtoFromObject(_.cloneDeep(output))
            );
          });
      }
    },
    [
      outputs,
      renewOutputObject,
      OUTPUT_DEACTIVATED,
      OUTPUT_TRANSITION,
      OUTPUT_ACTIVE,
    ]
  );

  const processLoraEndoutputErrorMsg = useCallback(
    (topic, message) => {
      console.log("NO Implementado")
    },
    []
  );

  //#endregion
  return (
    <>
      <ActoutputMQTT
        processMsg={processActoutputMqtt}
        processErrorMsg={processActoutputErrorMqtt}
      />
      <EndoutputMQTT
        processMsg={processEndoutputMqtt}
        processErrorMsg={processEndoutputErrorMqtt}
      />

      <UpdatesstateMQTT processMsg={processUpdatesstateMqtt} />
      <UpdateoutputsMQTT processMsg={processUpdateoutputsMqtt} />
      <SetWorkModeMQTT
        processMsg={processSetworkmodeMsg}
        processErrorMsg={processSetworkmodeErrorMsg}
      ></SetWorkModeMQTT>
      <AdvancedActoutputMQTT
        processMsg={processAdvancedActoutputMqtt}
        processErrorMsg={processAdvancedActoutputErrorMqtt}
      />

      <LoraActoutputMQTT 
        processMsg={processLoraActoutputMsg}
        processErrorMsg={processLoraActoutputErrorMsg}
      />

      <LoraEndoutputMQTT
        processMsg={processLoraEndoutputMsg}
        processErrorMsg={processLoraEndoutputErrorMsg}
      />
    </>
  );
};
