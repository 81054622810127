import React from "react";
import { TerminalDeviceSensorTriggerCard } from "./TerminalDeviceSensorTriggerCard";

export const TerminalDeviceSensorTriggerCardsView = (props) => {
  const {
    triggers,
    terminalDevices,
    title,
    greaterOperator,
    smallerOperator,
    onEdit,
    onDelete,
    triggerConfigurationType,
  } = props;

  if(!triggers || triggers.legth === 0){
      return <></>
  }

  return triggers
    .filter((trigger) => !trigger?.deletedConfirmationByDevice)
    .filter(
      (trigger) =>
        trigger?.operator === smallerOperator ||
        trigger?.operator === greaterOperator
    )
    .sort((first, second) => first?.id - second?.id)
    .map((trigger, index) => (
      <TerminalDeviceSensorTriggerCard
        key={index}
        trigger={trigger}
        terminalDevices={terminalDevices}
        state={trigger?.cardState}
        onClickEdit={onEdit}
        onClickRemove={onDelete}
        triggerConfigurationType={triggerConfigurationType}
        title={title}
      ></TerminalDeviceSensorTriggerCard>
    ));
};
