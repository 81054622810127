import React, { useEffect } from "react";
import { ReactComponent as Editsvg } from "./images/editar.svg";
import { getDateTimeFormatted } from "../../../../../../../../_helpers/DateFormatHelper";

export const CardActivate = (props) => {
  const {
    startDateTime,
    subprogram,
    isSubprogramNotified,
    actionOnDelete,
    actionOnRestore,
    actionOnEdit,
  } = props;

  return (
    <>
      <div className="twocolums">
        <div className="NameCardSubRoutine">
          <div>
            {!subprogram?.deleted_by_user &&
              (!isSubprogramNotified() ? (
                <div onClick={() => actionOnEdit(subprogram)}>
                  <Editsvg className="ICoCard" />
                </div>
              ) : (
                <>
                  <Editsvg className="ICoCardDeactivated" />
                </>
              ))}
          </div>
          <div className="titlecardsensor">{subprogram?.getName()}</div>
          {!subprogram?.deleted_by_user &&
            (!isSubprogramNotified() ? (
              <div
                className="fa fa-fw fa-close text-danger buttonDeleteCard "
                onClick={() => actionOnDelete(subprogram)}
              />
            ) : (
              <>
                <div className="fa fa-fw fa-close ICoCardDeactivated" />
              </>
            ))}
          {subprogram?.deleted_by_user && (
            <div
              className="fa fa-fw fa-undo buttonDeleteCard"
              onClick={() => actionOnRestore(subprogram)}
            ></div>
          )}
        </div>

        <div className="flex center">
          <div className="titlehourpr">
            De
            <div className="text-center HourCard">
              {getDateTimeFormatted(startDateTime)}
            </div>
            a
            <div className="text-center HourCard">
              {getDateTimeFormatted(subprogram?.getEndDateTime(startDateTime))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
