import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  UncontrolledTooltip,
} from "reactstrap";

import "./TokenManager.sass";
import _ from "lodash";
import { ReactComponent as Sharesvg } from "./share.svg";
import { tokenService } from "../../../../_services/token.service";

export const TokenManager = (props) => {
  const { ButtonShare } = props;
  const tokenPageSize = 10;
  const [tokensStored, settokensStored] = useState([]);
  const [tokensLoaded, settokensLoaded] = useState(false);
  const [tokensLoading, settokensLoading] = useState(false);
  const [tokensLoadingError, settokensLoadingError] = useState(false);
  const [tokensDeleteError, settokensDeleteError] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [maxPages, setmaxPages] = useState(1);
  const cache = useRef(new Map());
  const deleted = useRef(0);

  const loadTokens = useCallback(
    (page) => {
      tokenService.getTokens(page, tokenPageSize).then(
        (tmpTokens) => {
          for (let i = 0; i <= tmpTokens.content.length; i++) {
            if (tmpTokens.content[i]?.description === "funciona") {
              console.log(tmpTokens.content[i]?.description);
            }
          }

          settokensLoaded(true);
          settokensStored(tmpTokens.content);

          setmaxPages(tmpTokens.totalPages);
          cache.current.set(tmpTokens.number, tmpTokens.content);
        },
        (error) => {
          console.log("Error obteniendo tokens");
          settokensLoaded(false);
          settokensLoading(false);
          settokensLoadingError(true);
        }
      );
    },
    [tokenPageSize]
  );

  const loadPageData = useCallback(
    (paginationCurrentPage) => {
      setcurrentPage(paginationCurrentPage);
      // Comprobamos si tenemos en memoria.
      if (cache.current.has(paginationCurrentPage - 1)) {
        settokensStored(cache.current.get(paginationCurrentPage - 1));
      } else {
        loadTokens(paginationCurrentPage - 1);
      }
    },
    [loadTokens]
  );

  const deleteToken = useCallback(
    (tokenId) => {
      if (tokensStored) {
        let tokensCloned = _.cloneDeep(tokensStored);

        let index = -1;
        for (let i = 0; i < tokensCloned.length; i++) {
          const token = tokensCloned[i];
          if (token.id === tokenId) {
            index = i;
            break;
          }
        }
        if (index >= 0) {
          tokensCloned.splice(index, 1);
          settokensStored(tokensCloned);
          cache.current.set(currentPage - 1, tokensCloned);
          // Eliminamos de cache
          deleted.current = currentPage;

          //Llamamos a la API.

          tokenService.deleteToken(tokenId).then(
            (tokenDeleted) => {
              settokensDeleteError(false);

              // Borramos de memora las paginas posteriores al delete.
              let toDelete = [];
              for (var clave of cache.current.keys()) {
                if (clave <= deleted.current) {
                  toDelete.push(clave);
                }
              }
              toDelete.forEach((item) => {
                cache.current.delete(item);
              });

              loadPageData(deleted.current);
            },
            (error) => {
              settokensDeleteError(true);
            }
          );
        }
      }
    },
    [currentPage, loadPageData, tokensStored]
  );

  useEffect(() => {
    if (!tokensLoaded && !tokensLoading) {
      settokensLoading(true);
      loadTokens(currentPage - 1);
    }
    return () => {};
  }, [currentPage, loadTokens, tokensLoaded, tokensLoading]);

  return (
    <>
      <div className="ContentInsidePopUp">
        <div className="Title_Descripction">Llaves almacenadas:</div>
        {tokensLoadingError && (
          <div className="Error">Error recuperando los tokens.</div>
        )}
        {!tokensLoadingError && tokensLoading && !tokensLoaded && (
          <div>Cargando tokens...</div>
        )}
        {tokensDeleteError && (
          <div className="Error">Error eliminando el token.</div>
        )}
        {!tokensLoadingError && tokensLoaded && (
          <div style={{ width: "100%" }}>
            <TokenTable
              tokensStored={tokensStored}
              deleteToken={deleteToken}
              ButtonShare={ButtonShare}
            ></TokenTable>
          </div>
        )}
      </div>
      <PaginationTable
        fatherCurrentPage={currentPage}
        loadPageData={loadPageData}
        maxPages={maxPages}
      ></PaginationTable>
    </>
  );
};

const getDateTimeFormatted = (date) => {
  const month =
    date.getMonth() < 10 ? `0${date.getMonth()}` : `${date.getMonth()}`;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
  const hours =
    date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
  return `${day}/${month}/${date.getFullYear()} ${hours}:${minutes}`;
};

export const TokenTable = (props) => {
  const { ButtonShare, deleteToken } = props;

  const [tokensStored, settokensStored] = useState(props.tokensStored);

  useEffect(() => {
    settokensStored(props.tokensStored);
  }, [props]);

  return (
    <Table className="mb-0" responsive>
      <thead>
        <tr>
          <th className="bt-0">Id</th>
          <th className="bt-0"></th>
          <th className="bt-0">Nombre</th>
          <th className="bt-0">Válido desde</th>
          <th className="bt-0">Válido hasta</th>

          <th className="text-right bt-0"></th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(
          tokensStored.sort((first, second) => {
            if (first.id < second.id) {
              return -1;
            }
            if (first.id > second.id) {
              return 1;
            }
            return 0;
          })
        ).map((item, i) => (
          <tr
            key={i}
            className="FilaTabla"
            onClick={(e) => ButtonShare(tokensStored[item])}
          >
            <td className="align-middle fiedTable idhistoric">
              {tokensStored[item].id}
            </td>
            <td className="align-middle fiedTable sharebutton">
              <Sharesvg className="fa fa-fw fa-share-alt cursor iconsize" />
            </td>
            <td className="align-middle fiedTable description">
              {tokensStored[item].description}
            </td>

            <td className="align-middle fiedTable datehistoric">
              {getDateTimeFormatted(new Date(tokensStored[item].validSince))}
            </td>
            <td className="align-middle fiedTable datehistoric">
              {getDateTimeFormatted(new Date(tokensStored[item].validUntil))}
            </td>

            <td
              className="align-middle text-right deletehistoric"
              onClick={() => deleteToken(tokensStored[item].id)}
            >
              <a style={{ cursor: "pointer" }} id="UncontrolledTooltipRevoke">
                <i className="fa fa-fw fa-close text-danger buttonCloseTable"></i>
              </a>

              <UncontrolledTooltip
                placement="left"
                target="UncontrolledTooltipRevoke"
              >
                Elmininar
              </UncontrolledTooltip>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export const PaginationTable = (props) => {
  const { maxPages, fatherCurrentPage, loadPageData } = props;
  const [currentPage, setcurrentPage] = useState(fatherCurrentPage);

  const setFuturePage = useCallback(
    (futurePage) => {
      let page = futurePage > maxPages ? maxPages : futurePage;
      page = page >= 1 ? page : 1;

      loadPageData(page);
      setcurrentPage(page);
    },
    [loadPageData, maxPages]
  );

  const loadPage = useCallback((page) => {
    setFuturePage(page);
  });

  const lastPage = useCallback(() => {
    setFuturePage(maxPages);
  }, [maxPages, setFuturePage]);

  const nextPage = useCallback(() => {
    setFuturePage(currentPage + 1);
  }, [currentPage, setFuturePage]);

  const prevPage = useCallback(() => {
    setFuturePage(currentPage - 1);
  }, [currentPage, setFuturePage]);

  const getJustThree = useCallback(() => {
    let lowMiddle = 2;
    let highMiddle = maxPages - 1;
    let middle = currentPage;
    if (currentPage > highMiddle) {
      middle = highMiddle;
    } else if (currentPage < lowMiddle) {
      middle = lowMiddle;
    }
    const first = middle - 1;
    const last = middle + 1;
    const pages = [];

    if (first > 0) {
      pages.push(first);
    }
    if (middle > 0 && middle <= maxPages) {
      pages.push(middle);
    }
    if (last <= maxPages) {
      pages.push(last);
    }

    return pages.map((item, index) => {
      return (
        <PaginationItem active={currentPage === item} key={index}>
          <PaginationLink
            style={{ cursor: "pointer" }}
            onClick={() => loadPage(item)}
          >
            <span
              style={
                currentPage === item ? { color: "white" } : { color: "black" }
              }
            >
              {item}
            </span>
          </PaginationLink>
        </PaginationItem>
      );
    });
  }, [currentPage, loadPage, maxPages]);

  useEffect(() => {
    if (currentPage > maxPages) {
      setcurrentPage(1);
    }
  }, [currentPage, maxPages]);

  return (
    <Pagination
      aria-label={"Página"}
      style={{
        display: "flex-box",
        width: "100%",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        marginTop: "1%",
      }}
    >
      <PaginationItem>
        <PaginationLink
          previous
          style={{ cursor: "pointer" }}
          onClick={() => prevPage()}
        >
          <i className="fa fa-fw fa-chevron-left"></i>
        </PaginationLink>
      </PaginationItem>
      {getJustThree()}
      <PaginationItem>
        <PaginationLink
          next
          style={{ cursor: "pointer" }}
          onClick={() => nextPage()}
        >
          <i className="fa fa-fw fa-chevron-right"></i>
        </PaginationLink>
      </PaginationItem>
      <PaginationItem>
        <PaginationLink
          next
          style={{ cursor: "pointer" }}
          onClick={() => lastPage()}
        >
          <i className="fa fa-fw fa-chevron-right"></i>
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
};
