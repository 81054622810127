import React, { useCallback, useState, useEffect } from "react";
import { useOutputsAPI } from "../../../context/outputs-context";
import { projectsConstants } from "../../../_constants/projects.constants";
import { PaginatedOptions } from "../PaginatedOptions/PaginatedOptions";
import { TerminalDeviceOutputsDropDown } from "../TerminalDevices/Fichas/OutputConfiguration/TerminalDeviceOutputsDropDown";
import { Input } from "reactstrap";

const {
  output_deactivated: OUTPUT_DEACTIVATED,
  output_transition: OUTPUT_TRANSITION,
} = projectsConstants.global.states;

export const UNITS = {
  TIME: "TIME",
  PULSES: "PULSES",
};

export const TerminalDeviceOutputTimeOrCudCalibration = (props) => {
  const {
    calibrationRawValue,
    calibrationUnitValue,
    calibrationOptions,
    onCalibrationRawValueChange,
    onCalibrationUnitValueChange,
    terminalDevice,
    unit,
    cud,
    output,

    rawTitle,
    unitsTitle,
  } = props;

  const initState = {
    selectedOutput: output,
  };
  const [state, setState] = useState(initState);

  useEffect(() => {
    setState((prev) => ({ ...prev, selectedOutput: output }));
  }, [output]);

  const {
    actoutput,
    advanced_actoutput,
    isValidForAdvancedActoutput,
  } = useOutputsAPI();

  const isActivationEnabled = useCallback(
    (output) => {
      return (
        output?.currentState === OUTPUT_DEACTIVATED &&
        calibrationOptions.includes(calibrationRawValue)
      );
    },
    [calibrationOptions, calibrationRawValue]
  );

  const ActiveOutput = useCallback(
    (terminalDeviceId, output) => {
      if (isActivationEnabled(output)) {
        if (isValidForAdvancedActoutput(terminalDevice)) {
          advanced_actoutput(
            terminalDeviceId,
            null,
            1 << (output?.output - 1),
            cud,
            false,
            calibrationRawValue * 1000
          );
        } else {
          actoutput(
            terminalDeviceId,
            output.output,
            output.getActivationType(),
            output.activationParams,
            unit,
            cud,
            false,
            calibrationRawValue * 1000
          );
        }
        output.prevState = output.currentState;
        output.currentState = OUTPUT_TRANSITION;
      }
    },
    [
      isActivationEnabled,
      isValidForAdvancedActoutput,
      terminalDevice,
      advanced_actoutput,
      cud,
      calibrationRawValue,
      actoutput,
      unit,
    ]
  );

  const onAutomaticCalibrationOptionSelected = useCallback(
    (option) => {
      onCalibrationRawValueChange(option);
    },
    [onCalibrationRawValueChange]
  );

  const onOutputActivationClick = useCallback(() => {
    if (
      state.selectedOutput &&
      ((cud > 0 && unit === UNITS.PULSES) || unit === UNITS.TIME)
    ) {
      ActiveOutput(terminalDevice.id, state.selectedOutput);
    }
  }, [ActiveOutput, cud, state.selectedOutput, terminalDevice.id, unit]);

  const onOutputChange = useCallback((outputIndex, output) => {
    setState((state) => {
      return { ...state, selectedOutput: output };
    });
  }, []);

  return (
    <>
      <PaginatedOptions
        label={rawTitle}
        values={calibrationOptions}
        valueActive={calibrationRawValue}
        onOptionClick={onAutomaticCalibrationOptionSelected}
      ></PaginatedOptions>

      <div className="Title_Descripction">
        Salida a activar para el calibrado:
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <div style={{ width: "60%" }}>
          <TerminalDeviceOutputsDropDown
            outputs={terminalDevice?.outputs}
            disabled={output !== undefined}
            onChange={onOutputChange}
            selectedOutput={state.selectedOutput}
          ></TerminalDeviceOutputsDropDown>
        </div>
        <div style={{ width: "40%" }}>
          <button
            className={`ButtonOffAll little`}
            disabled={!isActivationEnabled(state.selectedOutput)}
            onClick={(e) => {
              onOutputActivationClick();
            }}
          >
            Activar
          </button>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div className="childCardDevices2">{unitsTitle}:</div>
        <Input
          type="number"
          name="cud_unitsValue"
          id="cud_unitsValue"
          placeholder={`${unitsTitle}...`}
          className="InputForm descriptionInput"
          onChange={(e) => onCalibrationUnitValueChange(e.target.value)}
          value={!isNaN(calibrationUnitValue) ? calibrationUnitValue : ""}
        />
      </div>
    </>
  );
};
