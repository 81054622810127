import React, { useCallback, useState, useEffect } from "react";
import { RemainingBar } from "./RemainingBar";

export const RemainingBarPrograms = (props) => {
  const { valuebar, hours,minutes } = props;
  const [invertvalue, setinvervalue] = useState((100 - valuebar).toFixed(2));

  useEffect(() => {
    if (valuebar !== 0) {
      setinvervalue((100 - valuebar).toFixed(2));
    } else {
      setinvervalue(100);
    }
  }, [valuebar]);


  return (
    <>
      <div className="BarRemainingContent">
        <div className="InfoRemainingBar"> tiempo restante: {invertvalue}%</div>
        <div className="barprogressprogram">
          <RemainingBar
            className="BarraProgress FullWidth"
            color="personalized"
            value={invertvalue}
          />
        </div>
        <div>Quedan {hours}:{minutes < 10 ? 0 + minutes.toString() : minutes} restantes.</div>
      </div>
    </>
  );
};
