import React, { useState, useEffect } from "react";
import { EmailShareButton, EmailIcon } from "react-share";
import whatssap from "./images/whatssap.png";
import sms from "./images/sms.png";
export const ButtonsShare = (props) => {
  const { messagesToShare, subject } = props;
  const [isIOSstate, setisIOSstate] = useState(undefined);
  const ALLOWED_URI_CHARS = "@#&=*+-_.,:!?()/~'%";
  useEffect(() => {
    let isIOS = (function () {
      var iosQuirkPresent = function () {
        var audio = new Audio();

        audio.volume = 0.5;
        return audio.volume === 1; // volume cannot be changed from "1" on iOS 12 and below
      };

      var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      var isAppleDevice = navigator.userAgent.includes("Macintosh");
      var isTouchScreen = navigator.maxTouchPoints >= 1; // true for iOS 13 (and hopefully beyond)

      return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
    })();
    setisIOSstate(isIOS);
  }, [isIOSstate]);

  return (
    <div className="ButtonsShare">
      <div></div>

      <EmailShareButton
        body={""}
        subject={subject || "Llave Tecatel:"}
        url={messagesToShare}
        separator={" "}
        className="shareByEmail"
      >
        <EmailIcon size={36} />
      </EmailShareButton>

      <div>
        <a
          href={
            "https://api.whatsapp.com/send?text=" +
            encodeURIComponent(messagesToShare)
          }
        >
          <img className="Iconref" src={whatssap} alt="shareByWhatssap" />
        </a>
      </div>
      <>
        {isIOSstate ? (
          /* IOS */
          <a href={"sms:?&body=" + encodeURIComponent(messagesToShare)}>
            <img className="Iconref" src={sms} alt="sharebySms" />
          </a>
        ) : (
          /* Android */
          <>
            <a
              href={
                "sms:?body=" +
                encodeURIComponent(encodeURIComponent(messagesToShare))
              }
            >
              <img className="Iconref" src={sms} alt="sharebySms" />
            </a>
          </>
        )}

        <div></div>
      </>
    </div>
  );
};
