import React, { useState, useEffect, useCallback } from "react";

import Toggle from "react-toggle";
import "./Toggle.sass";
export const ToggleComponent = (props) => {
  const {
    color,
    label,
    deactivateStatistic,
    activateStatistic,
    activate,
    onlyOne
  } = props;


  const [activatest, setactivatest] = useState(activate);

  useEffect(() => {
    if(onlyOne){
      setactivatest(activate);
    }
   
  }, [activate]);


  const toggleSelect = useCallback(() => {
    setactivatest(!activatest);

    if (activatest) {
      deactivateStatistic(label);
    } else {
      activateStatistic(label);
    }
  }, [activatest]);

  return (
    <>
      <div className={color}>
        <Toggle
          id="cheese-status"
          checked={activatest}
          icons={false}
          onChange={toggleSelect}
          className="toggle"
          disabled={false}
        />
      </div>

      {/* <div className="DetailsToggle"> {activate ? "ON" : "OFF"}</div>*/}
      <div className="DetailsToggle"> {label}</div>
    </>
  );
};
