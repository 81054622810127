import React, { useState, useCallback } from "react";
import { Link, Navigate } from "react-router-dom";
import {
  Form,
  FormGroup,
  Input,
  CustomInput,
  Button,
  Label,
  FormFeedback,
} from "reactstrap";
import EmptyLayout from "./../EmptyLayout";
import { Consumer as ThemeConsumer } from "./../Theme/ThemeContext";
import { HeaderAuth } from "./components/HeaderAuth";
import { FooterAuth } from "./../Pages/FooterAuth";
import { useUser } from "../../context/user-context";

import "./Login.sass";

export const Login = () => {
  const [email, setEmail] = useState(process.env.REACT_APP_DEFAULT_USERNAME);
  const [password, setPassword] = useState(
    process.env.REACT_APP_DEFAULT_PASSWORD
  );
  const [invalidEmail, setInValidEmail] = useState(false);
  const [invalidPassword, setInValidPassword] = useState(false);
  const { login, loggedIn, error: errorUser} = useUser();

  const handleClick = useCallback(
    (evt) => {
      if (email && password) {
        login(email, password);
      }
      setInValidEmail(!email);
      setInValidPassword(!password);
    },
    [email, password, login]
  );

  const handleKeypressed = useCallback(
    (e) => {
      //Press Enter
      (e.charCode === 13 || e.keyCode === 13) && handleClick(e);
    },
    [handleClick]
  );

  return (
    <EmptyLayout>
      {loggedIn && <Navigate to="/main"></Navigate>}
      <EmptyLayout.Section center>
        <HeaderAuth
          title="Iniciar sesión en TECO"
          text="Introduzca sus datos para iniciar sesión."
        />
        <Form className="mb-3">
          <FormGroup>
            <Label className="titleLabels" for="emailAdress">Correo electrónico</Label>
            <Input
              type="email"
              name="email"
              id="emailAdress"
              placeholder="Introducir el correo"
              className="bg-white"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={handleKeypressed}
              invalid={invalidEmail}
            />
            {invalidEmail && (
              <FormFeedback>Correo electrónico requerido</FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label   className="titleLabels" for="password">Contraseña</Label>
            <Input
              type="password"
              name="password"
              id="password"
              placeholder="Contraseña..."
              className="bg-white"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={handleKeypressed}
              invalid={invalidPassword}
            />
            {invalidPassword && (
              <FormFeedback>Contraseña requerida</FormFeedback>
            )}
          </FormGroup>
          {!loggedIn && errorUser && (
            <p className="text-danger">
              Error al iniciar sesión. Credenciales incorrectas.
            </p>
          )}
          <FormGroup>

          <div className="Alignj">
          <Input
                        type="checkbox"
                        id="rememberPassword"
                      />
          <div className="titleLabels">Recordar contraseña</div>
                  
          </div>

          
          </FormGroup>
          <ThemeConsumer>
            {({ color }) => (
              <Button
                color={color}
                className="BotonLogin"
                block
                onClick={handleClick}
              >
                <div className="BotonLogin">Iniciar sesión</div>
              </Button>
            )}
          </ThemeConsumer>
        </Form>

        <div className="margindown">
          <Link to="/password_forgot">
            He olvidado la contraseña
          </Link>
        </div>

        <FooterAuth />
      </EmptyLayout.Section>
    </EmptyLayout>
  );
};
