import { projectsConstants } from "../../../_constants/projects.constants";

export const isTerminalDeviceHW_LT3_ValidFor = (
  terminalDevice,
  expectedDeviceType,
  minVersion
) => {
  return (
    terminalDevice?.device?.id === expectedDeviceType &&
    parseInt(terminalDevice.mainCurrentVersion) >= minVersion &&
    parseInt(terminalDevice.hardwareVersion) < 3
  );
};

export const isTerminalDeviceHW_EGT3_ValidFor = (
  terminalDevice,
  expectedDeviceType,
  minVersion
) => {
  return (
    terminalDevice?.device?.id === expectedDeviceType &&
    parseInt(terminalDevice.mainCurrentVersion) >= minVersion &&
    parseInt(terminalDevice.hardwareVersion) >= 3
  );
};

const isTerminalDeviceValidFor = (terminalDevice, deviceId, minHw1Version, minHw3Version) => {
  return isTerminalDeviceHW_LT3_ValidFor(terminalDevice, deviceId, minHw1Version)
    || isTerminalDeviceHW_EGT3_ValidFor(terminalDevice, deviceId, minHw3Version)
}

export const isTerminalDeviceMMSValidFor = (terminalDevice, minHw1Version, minHw3Version, minX5Version = 0) => {
  return isTerminalDeviceValidFor(terminalDevice, projectsConstants.global.devices.MMS, minHw1Version, minHw3Version, minX5Version)
    || (terminalDevice?.device?.id === projectsConstants.global.devices.MMS_X5 && parseInt(terminalDevice.mainCurrentVersion) >= minX5Version)
}

export const isTerminalDeviceSIOValidFor = (terminalDevice, minHw1Version, minHw3Version) => {
  return isTerminalDeviceValidFor(terminalDevice, projectsConstants.global.devices.SIO, minHw1Version, minHw3Version)
}
