import React, { useCallback, useEffect, useRef, useState } from "react";
import { useApp } from "../../../context/app-context";
import { usePublisher } from "../../../context/publish-context";
import { projectsConstants } from "../../../_constants/projects.constants";
import uuid from "uuid/v4";
import { terminalsConstants } from "../../../_constants/terminals.constants";
import { GetTerminalsOnlineMQTT } from "../MQTT/GetTerminalsOnlineMQTT";
import Timer from "../Timer/Timer";

export const TerminalOnlineChecker = (props) => {
  const { active, deactived } = terminalsConstants.status.online;
  const { setTerminals, selectedTerminal } = useApp();
  const { publish } = usePublisher();
  const firstPublishExecuted = useRef(false);
  const [pendingMessages, setPendingMessages] = useState([]);

  const processGetOnlineTerminals = useCallback(
    (message) => {
      const { project } = message;
      let onlineTerminalIds = message?.data?.terminals;

      if (onlineTerminalIds instanceof Array) {
        setTerminals((currentTerminals) => (currentTerminals || [selectedTerminal]).map((terminal) => {
          if (
            (terminal?.terminalType || "").toLowerCase() ===
            project.toLowerCase()
          ) {
            const status = onlineTerminalIds.includes(terminal?.id)
              ? active
              : deactived;
            terminal.statusMqtt = status;
          }

          return terminal;
        }));
      }
    },
    [setTerminals, selectedTerminal, active, deactived]
  );

  useEffect(() => {
    if (pendingMessages.length > 0) {
      const pendingMessage = pendingMessages.shift();

      processGetOnlineTerminals(pendingMessage);

      setPendingMessages([...pendingMessages]);
    }
  }, [processGetOnlineTerminals, pendingMessages]);

  const processMasterOutputsGetOnlineTerminals = useCallback((id, message) => {
    setPendingMessages((messages) => [
      ...messages,
      { project: projectsConstants.master_outputs.id, ...message },
    ]);
  }, []);

  const processLoraOutputsGetOnlineTerminals = useCallback((id, message) => {
    setPendingMessages((messages) => [
      ...messages,
      { project: projectsConstants.lora_outputs.id, ...message },
    ]);
  }, []);

  const checkOnlineTerminals = useCallback(() => {
    return (
      publish(projectsConstants.master_outputs.actions.getonlineterminals, {
        type: 0,
        id: uuid(),
      }) &&
      publish(
        projectsConstants.lora_outputs.actions.getonlineterminals,
        {
          type: 0,
          id: uuid(),
          data: {},
        },
        projectsConstants.lora_outputs.id
      )
    );
  }, [publish]);

  useEffect(() => {
    if (!firstPublishExecuted.current && checkOnlineTerminals()) {
      firstPublishExecuted.current = true;
    }
  }, [checkOnlineTerminals]);

  return (
    <>
      <Timer
        isActive={true}
        callback={checkOnlineTerminals}
        callbackFirstExecutionDelayMiliseconds={30000}
        callbackExecutionEveryMiliseconds={30000}
        timerIntervalMiliseconds={5000}
      />
      <GetTerminalsOnlineMQTT
        processMsg={processMasterOutputsGetOnlineTerminals}
        processLoraMsg={processLoraOutputsGetOnlineTerminals}
      ></GetTerminalsOnlineMQTT>
    </>
  );
};
