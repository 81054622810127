import React, { useState, useEffect, useCallback } from "react";
import { usePrograms } from "../../../../../_services/programs.service";
import PropTypes from "prop-types";
import { ProgramFather } from "./programs/ProgramFather";
import { SettasksbyidMQTT } from "../../../MQTT/SettasksbyidMQTT";
import { DeletetasksMQTT } from "../../../MQTT/DeletetasksMQTT";
import { Spinner } from "../../../Airframe/Spinner/Spinner";
import "./ProgramsFather.sass";
export const ProgramsFather = (props) => {
  const { terminalDevice,CancelScheduledTasks,back,goToProgram,urlstate} = props;
  const { terminal: TerminalId } = terminalDevice;
  const programsService = usePrograms();
  const [isLoaded, setisLoaded] = useState(false);
  const [isRequested, setisRequested] = useState(false);
  const [programs, setprograms] = useState([]);
  const [errorApi, seterrorApi] = useState(false);

  

  useEffect(() => {
    if (!isRequested && TerminalId && terminalDevice.id) {
      setisRequested(true);
      programsService.getAll(TerminalId, terminalDevice.id).then(
        (fetchPrograms) => {
          setprograms(fetchPrograms);
          seterrorApi(false);
          setisLoaded(true);
        },
        (error) => {
          setprograms([]);
          seterrorApi(true);
          setisLoaded(true);
        }
      );
    }
  }, [isRequested]);

  const processSettasksbyidMsg = useCallback(
    (messageId, message) => {
      if (message?.data?.target_id) {
        if (message?.data?.target_id === terminalDevice.id) {
          setisRequested(false);
        }
      }
    },
    [terminalDevice]
  );

  const processSettasksbyidErrorMsg = useCallback((messageId, message) => {},
  []);

  const processGenericTargetIdMsg = useCallback(
    (message) => {
      if (message?.data?.target_id) {
        if (message?.data?.target_id === terminalDevice.id) {
          setisRequested(false);
        }
      }
    },
    [terminalDevice]
  );

  const processDeletetasksMsg = useCallback(
    (messageId, message) => {
      processGenericTargetIdMsg(message);
    },
    [processGenericTargetIdMsg]
  );

  const processDeletetasksErrorMsg = useCallback(
    (messageId, message) => {
      processGenericTargetIdMsg(message);
    },
    [processGenericTargetIdMsg]
  );

  return (
    <>
      {!isLoaded && (
        <div className={"LoadSpinner heightspinner"}>
          <Spinner size="50px" />
        </div>
      )}
      {isLoaded && !errorApi && (
        <>
          <SettasksbyidMQTT
            processMsg={processSettasksbyidMsg}
            processErrorMsg={processSettasksbyidErrorMsg}
          />
          <DeletetasksMQTT
            processMsg={processDeletetasksMsg}
            processErrorMsg={processDeletetasksErrorMsg}
          />
          <ProgramFather back={back}
           programs={programs} 
           outputs={terminalDevice?.outputs} 
           terminalDevice={terminalDevice} 
           CancelScheduledTasks={CancelScheduledTasks}
           goToProgram={goToProgram}
           urlstate={urlstate}
           />
        </>
      )}
      {errorApi && <div>Error consultando los programas.</div>}
    </>
  );
};

ProgramsFather.propTypes = {
  terminalDevice: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};
