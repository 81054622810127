import React, { useCallback, useEffect, useMemo, useState } from "react";
import { sensorService } from "../../../../../../_services/sensors.service";
import { Spinner } from "../../../../Airframe/Spinner/Spinner";
import uuid from "uuid/v4";
import { DangerComponent } from "../../../../../DangerComponent/DangerComponent";
import { CheckComponent } from "../../../../Airframe/CheckComponent/CheckComponents";
import { InputGroupwithid } from "../../../../Airframe/InputGroup/InputGroupwithid";
import { useApp } from "../../../../../../context/app-context";

export const TERMINAL_DEVICE_SENSOR_CALIBRATION_OPEN_POINT = "TERMINAL_DEVICE_SENSOR_CALIBRATION_OPEN_POINT";

export const TerminalDeviceSensorCalibrationRegister = (props) => {
  const {
    points,
    unit,
    inputUnit,
    terminalDevice,
    terminalDeviceSensor,
    onSaved,
  } = props;
  const { selectedTerminal: terminal } = useApp()
  const prefix = "terminal-device-sensor-calibration-row";
  const pointerPrefix = `p-${prefix}`;

  const states = useMemo(() => {
    return {
      IDLE: "IDLE",
      SAVING: "SAVING",
      SAVED: "SAVED",

      ERROR_SAVE: "ERROR_SAVE",
      ERROR_FORM_VALIDATE: "ERROR_FORM_VALIDATE",
    };
  }, []);
  const initState = useMemo(() => ({
    state: states.IDLE,
    calibration: undefined,

    values: [],
    points: [...points],
    temperature: undefined,
  }), [states.IDLE, points]);

  const [state, setState] = useState(initState);

  const onChange = useCallback(
    (sValue, id) => {
      if (id.length > prefix.length) {
        const index = parseInt(id.substring(prefix.length + 1));
        const value = parseFloat(sValue);
        if (value === "" || isNaN(value)) {
          state.values[index] = undefined;
        } else {
          state.values[index] = value;
        }
        setState({...state})
      }
    },
    [state]
  );

  const onChangePoint = useCallback((sValue, id) => {
    if (id.length > pointerPrefix.length) {
      const index = parseInt(id.substring(pointerPrefix.length + 1));
      const value = parseFloat(sValue);
      if (value === "" || isNaN(value)) {
        state.points[index] = undefined;
      } else {
        state.points[index] = value;
      }
      setState({...state})
    }
  }, [pointerPrefix.length, state])

  const onTempChange = useCallback(
    (sValue, id) => {
      const value = parseFloat(sValue);
      if (value === "" || isNaN(value)) {
        state.temperature = undefined
      } else {
        state.temperature = value
      }

      setState({...state})
    },
    [state]
  );

  const internalOnSaved = useCallback(() => {
    if (onSaved) {
      onSaved();
    }
  }, [onSaved]);

  const onSave = useCallback(() => {
    if (
      terminalDevice?.terminal !== undefined &&
      terminalDevice?.id !== undefined &&
      terminalDeviceSensor?.sensorId?.id !== undefined &&
      terminalDeviceSensor?.sensorIndex !== undefined
    ) {
      const count = state.values.reduce(
        (prev, current) =>
          prev + (current === undefined || current === null ? 0 : 1),
        0
      );
      if (count > 0) {
        setState((prev) => {
          return { ...prev, state: states.SAVING };
        });
        const dto = {
          tsm_sensor_calibration: {
            sensorIndex: terminalDeviceSensor.sensorIndex,
            uuid: uuid(),
            receivedAt: new Date().toISOString(),
            result: 1,
            points: state.points,
            values: state.values,
            temperature: state.temperature
          },
        };
        sensorService
          .saveTerminalDeviceSensorCalibration(
            terminalDevice.terminal,
            terminalDevice.id,
            terminalDeviceSensor.sensorId.id,
            terminalDeviceSensor.sensorIndex,
            dto
          )
          .then(
            (calibrationDto) => {
              setState((prev) => {
                return {
                  ...prev,
                  state: states.SAVED,
                };
              });
              internalOnSaved();
            },
            (error) => {
              setState((prev) => {
                return {
                  ...prev,
                  state: states.ERROR_SAVE,
                };
              });
            }
          );
      } else {
        setState((prev) => {
          return { ...prev, state: states.ERROR_FORM_VALIDATE };
        });
      }
    }
  }, [terminalDevice.terminal, terminalDevice.id, terminalDeviceSensor.sensorId.id, terminalDeviceSensor.sensorIndex, state.values, state.points, state.temperature, states.SAVING, states.SAVED, states.ERROR_SAVE, states.ERROR_FORM_VALIDATE, internalOnSaved]);

  const getSensorFirstMeasurement = useCallback(sensorId => {
    if(sensorId?.measurements instanceof Array && sensorId.measurements.length > 0){
      return sensorId.measurements[0].measurementUnit;
    }
  }, [])

  const getFileName = useCallback(() => {
    const measurementUnit = terminalDeviceSensor?.measurement?.measurementUnit || getSensorFirstMeasurement(terminalDeviceSensor?.sensorId)
    return `${terminal?.description || ""}(${terminal?.id})`
            + `-${terminalDevice.description || ""}(${terminalDevice.id})` 
            + `${measurementUnit ? `-${measurementUnit}` : ""}` 
            + `-${(new Date()).toISOString()}`
  }, [getSensorFirstMeasurement, terminal?.description, terminal?.id, terminalDevice.description, terminalDevice.id, terminalDeviceSensor?.measurement?.measurementUnit, terminalDeviceSensor?.sensorId])

  const onDownloadHistory = useCallback(() => {
    sensorService
          .getLastsTerminalDeviceSensorCalibrationsCSV(
            terminalDevice.terminal,
            terminalDevice.id,
            terminalDeviceSensor.sensorId.id,
            terminalDeviceSensor.sensorIndex,
            getFileName()
          );
  }, [getFileName, terminalDevice.id, terminalDevice.terminal, terminalDeviceSensor.sensorId.id, terminalDeviceSensor.sensorIndex])

  const getLabelInputComponent = useCallback(
    (label, index) => {
        return (
          <div key={index} className="RowUISelectTypes">
            <div className="DropdownF_SelectType dropdownPanelTypeBig">
              <InputGroupwithid
                id={`${pointerPrefix}-${index}`}
                type="number"
                placeholder={`Patrón...`}
                value={!isNaN(state.points[index]) ? state.points[index] : ""}
                onChange={onChangePoint}
                symbol={unit}
                disabled={label !== TERMINAL_DEVICE_SENSOR_CALIBRATION_OPEN_POINT}
              ></InputGroupwithid>
            </div>
            <div className="DropdownF_SelectType dropdownPanelTypeBig">
              <InputGroupwithid
                id={`${prefix}-${index}`}
                type="number"
                placeholder={`${inputUnit}...`}
                value={!isNaN(state.values[index]) ? state.values[index] : ""}
                onChange={onChange}
                symbol={inputUnit || "mV"}
              ></InputGroupwithid>
            </div>
          </div>
        );
    },
    [inputUnit, onChange, onChangePoint, pointerPrefix, state.points, state.values, unit]
  );

  const getLabels = useCallback(() => {
    if (points instanceof Array) {
      return points.map((label, index) => getLabelInputComponent(label, index));
    }
    return <></>;
  }, [getLabelInputComponent, points]);

  useEffect(() => {
    const newState = {...initState, values: []}
    setState(newState)
  }, [initState, terminalDeviceSensor])
 
  return (
    <div className="mainContainer">
      <div className="titleSensorInConf">
        Introducir los datos del calibrado realizado:
      </div>
      {getLabels()}
      <div className="RowUISelectTypes">
        <div className="DropdownF_SelectType dropdownPanelTypeBig">
          <InputGroupwithid
            type="text"
            value={"Temperatura"}
            symbol={"ºC"}
            disabled={true}
          ></InputGroupwithid>
        </div>
        <div className="DropdownF_SelectType dropdownPanelTypeBig">
          <InputGroupwithid
            id={`ph-cal-temp-input`}
            type="number"
            placeholder={`ºC...`}
            value={!isNaN(state.temperature) ? state.temperature : ""}
            onChange={onTempChange}
            min={0}
            symbol={"ºC"}
          ></InputGroupwithid>
        </div>
      </div>

      {state.state === states.ERROR_SAVE && (
        <DangerComponent
          message={"No se ha podido guardar el calibrado."}
        ></DangerComponent>
      )}
      {state.state === states.SAVED && (
        <CheckComponent
          message={"Se ha guardado el calibrado."}
        ></CheckComponent>
      )}
      <div className="RowUISelectTypes">
        {state.state !== states.SAVING ? (
          <>
            <div className="ButtonOffAll" onClick={(e) => onSave()}>
              <>Registrar calibrado</>
            </div>
            <div className="ButtonOffAll Secondary" onClick={(e) => onDownloadHistory()}>
              <>Obtener historico</>
            </div>
          </>
        ) : (
          <Spinner></Spinner>
        )}
      </div>
    </div>
  );
};
