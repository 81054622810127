import React, { useCallback } from "react";
import { getDateTimeFormatted } from "../../../../_helpers/DateFormatHelper";
import { OutputsSubprogram } from "./OutputsSubprogram";

export const SubprogramInfoListElement = (props) => {
    const { subprogram, startDatetime } = props;

      const getSingleOutputComponent = useCallback(output => {
        return <OutputsSubprogram key={`outputsSubprogram-${output}`} number={output}/>
      }, []);
    
      const getOutputsComponents = useCallback(() => {
        const outputsList = subprogram.getOutputsList();
        return outputsList.map(getSingleOutputComponent);
      }, [subprogram, getSingleOutputComponent]);

    return (<>
        <div className="ProgramsCard-SubProgram">
            <div className="ProgramsCard-Program">
                <div className="ProgramsCard-SubProgram-Title">
                    <div className="ProgramsCard-Programs-Name">{subprogram.getName()}</div>
                    <div className="ProgramsCard-Programs-Outputs">
                    {getOutputsComponents()}
                    </div>
                </div>
               
                <div className="ProgramsCard-Programs-Hour">De {getDateTimeFormatted(startDatetime)} a {getDateTimeFormatted(subprogram.getEndDateTime(startDatetime))} </div>
            </div>
        </div>
        <hr className="Separator"></hr>
    </>);
};
