import React from "react";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";
import { FooterAuth } from "../Pages/FooterAuth";
import { FooterText } from "../Pages/FooterText";
import Sidebar from "../Sidebar";

export const TecoSidebarBottom = (props) => (
  <React.Fragment>
    {/* START Desktop */}
    <Sidebar.HideSlim>
      <Sidebar.Section>
        <FooterAuth className="text-muted" />
      </Sidebar.Section>
    </Sidebar.HideSlim>
    {/* END Desktop */}

    {/* START Slim Only */}
    <Sidebar.ShowSlim>
      <Sidebar.Section className="text-center">
        {/* Footer Text as Tooltip */}
        <Button
          id="UncontrolledSidebarPopoverFooter"
          color="link"
          className="sidebar__link p-0 mt-3"
        >
          <i className="fa fa-fw fa-question-circle-o"></i>
        </Button>
        <UncontrolledPopover
          placement="left-end"
          target="UncontrolledSidebarPopoverFooter"
        >
          <PopoverBody>
            <FooterAuth />
          </PopoverBody>
        </UncontrolledPopover>
      </Sidebar.Section>
    </Sidebar.ShowSlim>
    {/* END Slim Only */}
  </React.Fragment>
);
