import React from "react";
export function Sensorsconfhelp(props) {
  return (
    <div className="documentation">
      <div className="helptitle">Configuración de sensores:</div>
      <div className="subhelptitle">1. Seleccionar un sensor análogico.</div>
      <div className="subhelptitle">2. Elige que tipo de sensor quieres activar</div>

      <div className="subhelptitle">
      Una vez activado se verán los datos en la vista principal y en la sección de estadísticas.
      </div>
    </div>
  );
}
