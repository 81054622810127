import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as IcoBottle } from "./../bottle.svg";

export const CalibrationStepInstructions = (props) => {
  return (
    <>
      <div className="bodyCalibrateContent">
        <div className="bodyCalibrateContentc">
        Coloca el sensor en la recipiente con la solución{" "}
          {props.bufferValue}pH para comenzar el calibrado.
        </div>
        <div className="bodyCalibrateContentc">
          <IcoBottle className="IcoForm" />
        </div>
      </div>
      <div className="ButtonTSMf">
        <div className="buttonTSMinColumn">
          <div className="ButtonTSM withStep">
            <div
              className="ButtonOfAll"
              onClick={(e) => {
                props.onCalibrationStart();
              }}
            >
              Iniciar ({props.bufferValue}pH)
            </div>
          </div>
        </div>
        <div>{props.currentStep}/{props.totalSteps}</div>
      </div>
    </>
  );
};

CalibrationStepInstructions.propTypes = {
  bufferValue: PropTypes.number.isRequired,
  onCalibrationStart: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired
};
