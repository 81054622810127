import React, { useState, useEffect, useCallback } from "react";
import { TecoSidebar } from "./../../TecoSidebar/TecoSidebar";
import { TecoNavbar } from "./../../TecoNavbar/TecoNavbar";
import TecoLayout from "./../../Layout/TecoLayout";
import { TerminalDevicesfather } from "../../components/TerminalDevices/TerminalDevicesFather/TerminalDevicesfather";
import { useApp } from "../../../context/app-context";
import { terminalService } from "../../../_services/terminal.service";
import { zoneService } from "../../../_services/zone.service";
import { Spinner } from "../../components/Airframe/Spinner/Spinner";
import { projectsConstants } from "../../../_constants/projects.constants";
import { PagePostigel } from "../PagePostigel/PagePostigel";
import { useParams } from 'react-router-dom';
import { TerminalDevice } from "../../../classes/TerminalDevice/TerminalDevice";

export const PageTerminalDevices = (props) => {
  let params = useParams();
  //const location = useLocation()
  const [sectorId] = useState(params?.sectorId || 0);
  const [zoneId] = useState(params?.zoneId || 0);
  const [terminalId] = useState(params?.terminalId || 0);
  //const terminalDevices = location?.state?.terminalDevices;

  const { selectedTerminal, setselectedTerminal, selectedZone, setSelectedZone, selectedSector, setSelectedSector } = useApp();
  useEffect(() => {
    if (selectedTerminal?.id !== parseInt(terminalId)) {
      terminalService.getTerminal(terminalId).then(
        (terminal) => {
          setselectedTerminal(terminal);
        },
        (error) => {
          console.log("Error");
          //   setloadingError(true);
          //   setterminalDevices([]);
        }
      );
    }

    if (selectedZone?.id !== parseInt(zoneId)) {
      zoneService
        .getZone(zoneId)
        .then((zone) => {
          setSelectedZone(zone);
        })
        .catch((error) => {
          console.log("ERROR CARGANDO SECTOR");
        });
    }

    if (selectedSector?.id !== parseInt(sectorId)) {
      zoneService
        .getZoneSector(zoneId, sectorId)
        .then((sector) => {
          setSelectedSector(sector.zoneSector);
        })
        .catch((error) => {
          console.log("ERROR CARGANDO SECTOR");
        });
    }
  }, []);

  const loadTerminalView = useCallback(() => {
    if (selectedTerminal) {
      switch (selectedTerminal.terminalType.toLowerCase()) {
        case projectsConstants.postigel.id:
          return <PagePostigel zoneId={zoneId} sectorId={sectorId} terminal={selectedTerminal} />
        default:
        //  return <PagePostigel zoneId={zoneId} sectorId={sectorId} terminal={selectedTerminal} />
         return <TerminalDevicesfather
            key={terminalId}
            terminalId={terminalId}
            terminalDevices={[]/*terminalDevices && terminalDevices.map(terminalDevice=> TerminalDevice.parseFromObjectDto(terminalDevice))*/}
            zoneId={zoneId}
            sectorId={sectorId}
          />;
      }
    }
    return <Spinner />
  }, [selectedTerminal, zoneId, sectorId, terminalId]);

  const getTitleNavbar = useCallback(() => {
    if (selectedTerminal) {
      switch (selectedTerminal.terminalType.toLowerCase()) {
        case projectsConstants.postigel:
          return "Postigel"
        default:

          return "TerminalDevices"

      }
    }
  }, [selectedTerminal]);


  return (
    <>
      <TecoLayout
        routedNavBar={() => (
          <TecoNavbar
            NavMenu={getTitleNavbar()}
            sectorId={sectorId}
            zoneId={zoneId}
          />
        )}
        routedSideBar={() => <TecoSidebar />}
        key="menu"
      >
        <div className="ContainerZones">
          {loadTerminalView()}
        </div>
      </TecoLayout>
    </>
  );
};
