import React from "react"
import Bell from "./../../../../../images/bell.png";
import BellActive from "./../../../../../images/bell_active.png";
import StopActive from "./../../../../../images/stop.png";
import Stop from "./../../../../../images/stop_trans.png";

export const TerminalDeviceSensorTriggerAdditionalActionsInfo = props => {
    const {title, trigger} = props
    
    return <div className="RowUISelectTypes titleSecondaryTSM">
    <div style={{ minWidth: "80%" }}>{title  || "Acciones adicionales:"}</div>
    <div
      style={{ maxWidth: "20%", textAlign: "end" }}
    >
      <img
        src={
          trigger?.mustEnterInMaintenanceWhenExecuted ? StopActive : Stop
        }
        alt={"Botón para parar el sistema cuando se cumpla la condición."}
        className="IcoDelProgram"
      />
    </div>
    <div
      style={{ maxWidth: "20%", textAlign: "end"}}
    >
      <img
        src={trigger?.mustBeReportedWhenExecuted ? BellActive : Bell}
        alt={"Campana notificación cuando se cumpla la condición."}
        className="IcoDelProgram"
      />
    </div>
  </div>
}