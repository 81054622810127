import React from "react";

export const OutputsSubprogram = (props) => {
  const { number } = props;

  return (
      <div key={number} className="outputpillv">
        <div className="outputpill">
          <div className="badge-primary2 outputvi">{number}</div>
        </div>
      </div>
  );
};
