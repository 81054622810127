import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useOutputsAPI } from "../../../context/outputs-context";
import { MESSAGE_TYPE_ACTION } from "../../../_constants/messageType.constants";
import uuid from "uuid/v4";
import { projectsConstants } from "../../../_constants/projects.constants";
import { usePublisher } from "../../../context/publish-context";
import { ReportSensorsMQTT } from "../MQTT/ReportSensorsMQTT";
import { Spinner } from "../Airframe/Spinner/Spinner";
import { TerminalDeviceOutputsDropDown } from "../TerminalDevices/Fichas/OutputConfiguration/TerminalDeviceOutputsDropDown";
import { Input } from "reactstrap";
import { DangerComponent } from "../../DangerComponent/DangerComponent";
import { terminalsConstants } from "../../../_constants/terminals.constants";

export const TerminalDeviceCalibrationManualOutputActivation = (props) => {
  const {
    calibrationRawValue,
    calibrationUnitValue,
    onCalibrationChange,
    terminal,
    terminalDevice,
    sensor,

    unitsTitle,
  } = props;

  const { active } = terminalsConstants.status.online;

  const unit = "PULSES";

  const states = useMemo(
    () => ({
      IDLE: "IDLE",
      REQUEST_PREV_CUD_VALUE: "REQUEST_PREV_CUD_VALUE",
      REQUESTING_PREV_CUD_VALUE: "REQUESTING_PREV_CUD_VALUE",
      RECEIVED_PREV_CUD_VALUE: "RECEIVED_PREV_CUD_VALUE",
      OUTPUT_ACTIVE: "OUTPUT_ACTIVE",
      OUTPUT_ACTIVE_WAIT_RESPONSE: "OUTPUT_ACTIVE_WAIT_RESPONSE",
      WAIT_FOR_USER_TO_ENDOUTPUT: "WAIT_FOR_USER_TO_ENDOUTPUT",
      OUTPUT_END: "OUTPUT_END",
      OUTPUT_END_WAIT_RESPONSE: "OUTPUT_END_WAIT_RESPONSE",
      REQUESTING_CUD_VALUE: "REQUESTING_CUD_VALUE",
      RECEIVED_CUD_VALUE: "RECEIVED_CUD_VALUE",
      VALID_CUD_VALUE: "VALID_CUD_VALUE",
      INVALID_CUD_VALUE: "INVALID_CUD_VALUE",

      TERMINAL_DEVICE_DISCONNECTED: "TERMINAL_DEVICE_DISCONNECTED",
    }),
    []
  );

  const errors = useMemo(
    () => ({
      NONE: "NONE",
      INVALID_CALIBRATION_UNIT_VALUE: "INVALID_CALIBRATION_UNIT_VALUE",
      INVALID_SENSOR_VALUES: "INVALID_SENSOR_VALUES",
    }),
    []
  );

  const initState = useMemo(
    () => ({
      state: states.IDLE,
      selectedOutput: terminalDevice?.outputs[0],
      lastSensorValue: undefined,
      currentSensorValue: undefined,
      error: errors.NONE,
    }),
    [errors.NONE, states.IDLE, terminalDevice?.outputs]
  );

  const [state, setState] = useState(initState);
  const {
    actoutput,
    advanced_actoutput,
    isValidForAdvancedActoutput,
    endoutput,
  } = useOutputsAPI();
  const { publish } = usePublisher();

  const getPhysicalCommunicationType = useCallback(() => {
    return sensor?.sensorId?.physicalCommunicationType || projectsConstants.global.sensors.phys.cuds
  }, [sensor])

  //#region  MQTT

  const publishGetSensorValue = useCallback(() => {
    let request = {
      type: MESSAGE_TYPE_ACTION,
      id: uuid(),
      data: {
        target_id: terminalDevice.id,
        id: sensor?.sensorIndex + 1,
        physical_communication: getPhysicalCommunicationType(),
      },
    };

    publish(projectsConstants.master_outputs.actions.getsensorvalue, request);
  }, [terminalDevice.id, sensor, getPhysicalCommunicationType, publish]);

  const processReportSensorsMsg = useCallback(
    (messageId, message) => {
      const terminalDeviceSensorValues =
        message.data?.terminal_device_sensor_values;

      if (
        !isNaN(sensor?.sensorIndex) &&
        terminalDevice?.sensors &&
        terminalDeviceSensorValues?.target_id === terminalDevice?.id &&
        terminalDeviceSensorValues.sensors_value instanceof Array
      ) {
        terminalDeviceSensorValues.sensors_value.forEach((sensorValue) => {
          terminalDevice.sensors
            .filter(
              (sourceSensor) =>
                sourceSensor?.sensorIndex === sensor?.sensorIndex &&
                sourceSensor?.sensorId?.physicalCommunicationType ===
                  getPhysicalCommunicationType()
            )
            .filter(
              (sensor) =>
                sensor?.sensorIndex === sensorValue?.sensor_index &&
                sensor?.sensorId?.physicalCommunicationType ===
                  sensorValue?.physical_communication_type
            )
            .forEach((sensor) => {
              if (state.state === states.REQUESTING_PREV_CUD_VALUE) {
                setState((current) => ({
                  ...current,
                  state: states.RECEIVED_PREV_CUD_VALUE,
                  lastSensorValue: sensorValue?.measure,
                }));
              } else if (state.state === states.REQUESTING_CUD_VALUE) {
                setState((current) => ({
                  ...current,
                  state: states.RECEIVED_CUD_VALUE,
                  currentSensorValue: sensorValue?.measure,
                }));
              }
            });
        });
      }
    },
    [
      sensor?.sensorIndex,
      state.state,
      states.RECEIVED_CUD_VALUE,
      states.RECEIVED_PREV_CUD_VALUE,
      states.REQUESTING_CUD_VALUE,
      states.REQUESTING_PREV_CUD_VALUE,
      terminalDevice?.id,
      terminalDevice.sensors,
      getPhysicalCommunicationType
    ]
  );

  //#endregion

  //#region

  const isActivationDisabled = useCallback(
    (output) => {
      return (
        state.selectedOutput === undefined ||
        state.selectedOutput.currentState ===
          projectsConstants.global.states.output_transition
      );
    },
    [state.selectedOutput]
  );

  const isOutputActive = useCallback(() => {
    return (
      state.selectedOutput?.currentState ===
      projectsConstants.global.states.output_active
    );
  }, [state.selectedOutput?.currentState]);

  //#endregion

  //#region ON CHANGE

  const onOutputChange = useCallback((outputIndex, output) => {
    setState((state) => {
      return { ...state, selectedOutput: output };
    });
  }, []);

  const ActiveOutput = useCallback(
    (terminalDeviceId, output) => {
      if (isValidForAdvancedActoutput(terminalDevice)) {
        advanced_actoutput(
          terminalDeviceId,
          null,
          1 << (output?.output - 1),
          sensor?.sensorIndex + 1,
          true
        );
      } else {
        actoutput(
          terminalDeviceId,
          output.output,
          output.getActivationType(),
          output.activationParams,
          unit,
          sensor?.sensorIndex + 1,
          true
        );
      }
    },
    [
      isValidForAdvancedActoutput,
      terminalDevice,
      advanced_actoutput,
      sensor?.sensorIndex,
      actoutput,
    ]
  );

  const endOutput = useCallback(() => {
    if (state.selectedOutput && sensor) {
      if (isOutputActive()) {
        endoutput(terminalDevice.id, state.selectedOutput?.output);
      }
      state.selectedOutput.prevState = state.selectedOutput.currentState;
      state.selectedOutput.currentState =
        projectsConstants.global.states.output_transition;
      setState((current) => ({
        ...current,
      }));
    }
  }, [
    endoutput,
    isOutputActive,
    sensor,
    state.selectedOutput,
    terminalDevice.id,
  ]);

  const activateOutput = useCallback(() => {
    if (state.selectedOutput && sensor) {
      if (!isOutputActive()) {
        ActiveOutput(terminalDevice.id, state.selectedOutput);
      }
      state.selectedOutput.prevState = state.selectedOutput.currentState;
      state.selectedOutput.currentState =
        projectsConstants.global.states.output_transition;
      setState((current) => ({
        ...current,
      }));
    }
  }, [
    ActiveOutput,
    isOutputActive,
    sensor,
    state.selectedOutput,
    terminalDevice.id,
  ]);

  const onOutputActivationClick = useCallback(() => {
    if (state.state === states.WAIT_FOR_USER_TO_ENDOUTPUT) {
      setState((current) => ({ ...current, state: states.OUTPUT_END }));
    } else {
      if (!isOutputActive()) {
        setState((current) => ({
          ...current,
          state: states.REQUEST_PREV_CUD_VALUE,
        }));
      } else {
        endOutput();
      }
    }
  }, [
    isOutputActive,
    state.state,
    states.OUTPUT_END,
    states.REQUEST_PREV_CUD_VALUE,
    states.WAIT_FOR_USER_TO_ENDOUTPUT,
    endOutput
  ]);

  const onCalibrationUnitValueChange = useCallback(
    (newCalibrationUnitValue) => {
      if (!isNaN(newCalibrationUnitValue) && newCalibrationUnitValue > 0) {
        setState((current) => ({ ...current, error: errors.NONE }));
        if (state.state === states.VALID_CUD_VALUE) {
          if (
            !isNaN(state.currentSensorValue) &&
            state.currentSensorValue > 0
          ) {
            onCalibrationChange(
              state.currentSensorValue - state.lastSensorValue,
              newCalibrationUnitValue
            );
            setState((current) => ({ ...current, error: errors.NONE }));
          } else {
            setState((current) => ({
              ...current,
              error: errors.INVALID_SENSOR_VALUES,
            }));
          }
        } else {
          onCalibrationChange(calibrationRawValue, newCalibrationUnitValue);
        }
      } else {
        setState((current) => ({
          ...current,
          error: errors.INVALID_CALIBRATION_UNIT_VALUE,
        }));
      }
    },
    [
      calibrationRawValue,
      errors.INVALID_CALIBRATION_UNIT_VALUE,
      errors.INVALID_SENSOR_VALUES,
      errors.NONE,
      onCalibrationChange,
      state.currentSensorValue,
      state.lastSensorValue,
      state.state,
      states.VALID_CUD_VALUE,
    ]
  );

  //#endregion

  useEffect(() => {
    if (terminal?.statusMqtt === active) {
      switch (state.state) {
        case states.IDLE:
          /*setState((current) => ({
            ...current,
            state: states.REQUEST_PREV_CUD_VALUE,
          }));*/
          break;
        case states.REQUEST_PREV_CUD_VALUE:
          if (sensor) {
            publishGetSensorValue();
            setState((current) => ({
              ...current,
              state: states.REQUESTING_PREV_CUD_VALUE,
            }));
          }
          break;
        case states.REQUESTING_PREV_CUD_VALUE:
          break;
        case states.RECEIVED_PREV_CUD_VALUE:
          setState((current) => ({
            ...current,
            state: states.OUTPUT_ACTIVE,
          }));
          break;
        case states.OUTPUT_ACTIVE:
          activateOutput();
          setState((current) => ({
            ...current,
            state: states.OUTPUT_ACTIVE_WAIT_RESPONSE,
          }));
          break;
        case states.OUTPUT_ACTIVE_WAIT_RESPONSE:
          if (
            state?.selectedOutput?.currentState ===
            projectsConstants.global.states.output_active
          ) {
            setState((current) => ({
              ...current,
              state: states.WAIT_FOR_USER_TO_ENDOUTPUT,
            }));
          }
          break;
        case states.WAIT_FOR_USER_TO_ENDOUTPUT:
          break;
        case states.OUTPUT_END:
          endOutput();
          setState((current) => ({
            ...current,
            state: states.OUTPUT_END_WAIT_RESPONSE,
          }));
          break;
        case states.OUTPUT_END_WAIT_RESPONSE:
          if (
            state.selectedOutput?.currentState ===
            projectsConstants.global.states.output_deactivated
          ) {
            publishGetSensorValue();
            setState((current) => ({
              ...current,
              state: states.REQUESTING_CUD_VALUE,
            }));
            setState((current) => ({
              ...current,
              state: states.REQUESTING_CUD_VALUE,
            }));
          }
          break;
        case states.REQUESTING_CUD_VALUE:
          break;
        case states.RECEIVED_CUD_VALUE:
          let nextState = states.VALID_CUD_VALUE;
          if (
            isNaN(state.currentSensorValue) ||
            state.currentSensorValue <= 0
          ) {
            nextState = states.INVALID_CUD_VALUE;
          }
          setState((current) => ({ ...current, state: nextState }));
          break;
        case states.VALID_CUD_VALUE:
          break;
        case states.INVALID_CUD_VALUE:
          break;
        default:
          break;
      }
    } else {
      setState((current) => ({
        ...current,
        state: states.TERMINAL_DEVICE_DISCONNECTED,
      }));
    }
  }, [
    publishGetSensorValue,
    state.state,
    states.IDLE,
    states.REQUESTING_PREV_CUD_VALUE,
    sensor,
    states.RECEIVED_PREV_CUD_VALUE,
    states.OUTPUT_ACTIVE,
    states.OUTPUT_ACTIVE_WAIT_RESPONSE,
    activateOutput,
    state.selectedOutput?.currentState,
    states.WAIT_FOR_USER_TO_ENDOUTPUT,
    states.OUTPUT_END,
    states.OUTPUT_END_WAIT_RESPONSE,
    states.REQUESTING_CUD_VALUE,
    states.RECEIVED_CUD_VALUE,
    endOutput,
    state.currentSensorValue,
    states.VALID_CUD_VALUE,
    states.INVALID_CUD_VALUE,
    states.REQUEST_PREV_CUD_VALUE,
    terminal?.statusMqtt,
    active,
    states.TERMINAL_DEVICE_DISCONNECTED,
  ]);

  if (state.state === states.TERMINAL_DEVICE_DISCONNECTED) {
    return (
      <DangerComponent
        message={
          "El dispositivo no está conectado. No se puede realizar el calibrado."
        }
      />
    );
  }

  return (
    <>
      <ReportSensorsMQTT
        processMsg={processReportSensorsMsg}
      ></ReportSensorsMQTT>
      {(state.state === states.REQUESTING_PREV_CUD_VALUE ||
        state.state === states.REQUESTING_CUD_VALUE) && (
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <Spinner size={"10px"}></Spinner>
          {`${
            state.state === states.REQUESTING_PREV_CUD_VALUE
              ? "Iniciando proceso"
              : "Solicitando datos"
          } ...`}
        </div>
      )}

      {state.state !== states.REQUESTING_PREV_CUD_VALUE &&
        state.state !== states.REQUESTING_CUD_VALUE && (
          <>
            {state.state === states.INVALID_CUD_VALUE && (
              <DangerComponent message={"Los pulsos del caudalímetro son 0!"} />
            )}

            <div className="Title_Descripction">
              Último calibrado: {calibrationUnitValue} {unitsTitle} | (
              {calibrationRawValue})
            </div>

            <div className="Title_Descripction">
              Salida a activar para el calibrado:
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              <div style={{ width: "60%" }}>
                <TerminalDeviceOutputsDropDown
                  outputs={terminalDevice?.outputs}
                  onChange={onOutputChange}
                  selectedOutput={state.selectedOutput}
                  disabled={false}
                ></TerminalDeviceOutputsDropDown>
              </div>
              <div>
                <button
                  className={`btn ${
                    isOutputActive() ? "btn-danger" : "btn-success"
                  }`}
                  disabled={isActivationDisabled(state.selectedOutput)}
                  onClick={(e) => {
                    onOutputActivationClick();
                  }}
                >
                  {isOutputActive() ? "Parar" : "Activar"}
                </button>
              </div>
            </div>
            {state.state === states.VALID_CUD_VALUE && (
              <div className="Title_Descripction">
                Pulsos recibidos: {state.currentSensorValue}
              </div>
            )}
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <div className="childCardDevices2">{unitsTitle}/calibrado:</div>
              <Input
                type="number"
                name="cud_unitsValue"
                id="cud_unitsValue"
                placeholder={`${unitsTitle}...`}
                className="InputForm descriptionInput"
                onChange={(e) => onCalibrationUnitValueChange(e.target.value)}
                disabled={state.state !== states.VALID_CUD_VALUE}
                invalid={state.error === errors.INVALID_CALIBRATION_UNIT_VALUE}
              />
            </div>
          </>
        )}
    </>
  );
};
