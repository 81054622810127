import React, { useCallback, useState, useEffect } from "react";
import Image1 from "./images/advanced/avanzado0.png";
import Image2 from "./images/advanced/avanzado1.png";
import Image3 from "./images/advanced/avanzado2.png";
import Image4 from "./images/advanced/avanzado4.png";
export const HelpsAdvanced = (props) => {
  return (
    <>
      <div className="documentation">
        <div className="helptitle helpf">
          Información sobre la programación en modo avanzado:
        </div>

        <div className="helptitle">
          2 partes: 1/2.Configurar la relación de las salidas con las categorias.
        
        </div>

        <div className="subhelptitle">
          Puedes programar tareas por niveles en el orden que tu quieras!. Desde
          ajustes - Tipos de salidas, puedes crear los tipos/categorias. Escribe
          el nombre/tipo y abreviatura que quieras darle. A continuación
          aparecerá el nuevo elemento en las líneas de abajo. Puedes mover
          cualquiera, en cualquier momento!.
        </div>

        <div className="ImageCenterhelp">
          <img
            src={Image3}
            alt="Programshelpimage2"
            className="ImageHelpPrograms"
          />
        </div>

        <div className="subhelptitle">
          Una vez tengas creadas las categorías/niveles, no olvides relacionar
          tus salidas. En ajustes salidas ( Arriba de descripción). El
          desplegable te permite elegir cualquier categoría. Al darle a guardar
          se relacionará automáticamente con la salida.
        </div>

        <div className="helptitle">
          2/2.Entrar en avanzado y programar con categorias agrupadas.
        </div>



        <div className="ImageCenterhelp">
          <img
            src={Image4}
            alt="Programshelpimage2"
            className="ImageHelpPrograms"
          />
        </div>


        <div className="subhelptitle">
          Una vez establecido los tipos que quieras, en el menu de programar
          salidas, aparecerá el botón de avanzado. Aquí podrás crear
          subprogramas filtrados por los tipos y niveles que has elegido. Tantas
          pantallas como niveles, para que combienes los tipos, ejemplo: Si en
          la primera línea pusiste 4 tipos, estos se mostrarán en el primer
          menu. Pudiendo crear tareas programadas que combine estas categorías.
          Un ejemplo sería abono + riego.
        </div>

        <div className="ImageCenterhelp">
          <img
            src={Image1}
            alt="Programshelpimage2"
            className="ImageHelpPrograms"
          />
        </div>

        <div className="subhelptitle">
          En el botón de siguiente, los números te indican el máximo de niveles
          (líneas).
        </div>

        <div className="ImageCenterhelp">
          <img
            src={Image2}
            alt="Programshelpimage2"
            className="ImageHelpPrograms2"
          />
        </div>
      </div>
    </>
  );
};
