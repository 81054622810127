import React, { useCallback } from "react";
import Radium, { StyleRoot } from "radium";
import { swing } from "react-animations";
import IcoPending from "./../../../../images/triggers/pendingIcon.png";
import { ReactComponent as AddProgram } from "./../../../../images/add.svg";
import { Spinner } from "../Spinner/Spinner";

export const HorizontalSelector = (props) => {
  const { elements, onElementClick, onAddClick, disabled } = props;

  const parseElements = useCallback(() => {
    return elements.map((element, index) => (
      <HorizontalSelectorElement
        key={index}
        element={element}
        onClick={onElementClick}
        disabled={disabled}
      ></HorizontalSelectorElement>
    ));
  }, [disabled, elements, onElementClick]);

  return (
    <div className="ProgramsView">
      <div className="FixScroll">{parseElements()}</div>
      <div className="fatherbuttonprogram">
        {!disabled &&
        <AddProgram
          className="ButtonProgram Add"
          onClick={(e) => onAddClick()}
        />
        }
        {disabled &&
        <AddProgram
          className="ButtonProgram Disabled"
        />
        }

      </div>
    </div>
  );
};

export const HorizontalSelectorElement = (props) => {
  const { element, onClick, disabled } = props;

  const onClickInternal = useCallback(() => {
    if(!disabled){
      onClick(element.source);
    }
  }, [disabled, element.source, onClick]);

  const styles = {
    AnimFirst: {
      //velocidad, si aumentas el tiempo va más lento, por eso los timeout una manera de controlar loop fijando velocidad.
      animation: "x 2s",
      animationName: Radium.keyframes(swing, "Animacion1"),
      animationIterationCount: Number.MAX_VALUE,
    },
  };

  return (
    <StyleRoot
      style={element?.selectedToDelete ? styles.AnimFirst : undefined}
      className={`ProgramsC ${element?.isSelected && !disabled ? "selected" : ""} ${disabled ? "disabled" : ""}`}
      onClick={onClickInternal}
    >
      <div className="nameprogramc">
        <div className="nameprogramc">{element?.title}</div>
        <div className="nameprogramc">{element?.text}</div>
      </div>
      <div
        className={`ColorBarProgram ${element?.isSelected && !disabled ? "selected" : ""} ${disabled ? "disabled" : ""}`}
      ></div>

      {element?.modified && (
        <div className="IcoPending">
          <img
            src={IcoPending}
            alt="Programs"
            className="PicPlugTD Small confOutputs"
          />
        </div>
      )}
      {element?.isInSync === false && (
        <div className="TopRigthElement">
          <Spinner size={"1.2rem"}></Spinner>
        </div>
      )}
    </StyleRoot>
  );
};
