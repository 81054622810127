import React, { useCallback, useState, useEffect } from "react";
import { useApp } from "../../../../../context/app-context";
import { useTerminalDevice } from "../../../../../context/terminal-device-context";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import { TerminalDeviceOutputsCardsView } from "../../OutputsView/TerminalDeviceOutputsCardsView";
import { TerminalDeviceOutputsView } from "../../OutputsView/TerminalDeviceOutputsView";
import { TerminalDeviceGenericAnalogSensorView } from "../../SensorsView/TerminalDeviceGenericAnalogSensorView";
import {
  TerminalDeviceNavbar,
  TerminalDeviceNavbarStates,
} from "../../TerminalDeviceNavbar";
import { AtomizerDosificationConfiguration } from "./AtomizerDosificationConfiguration";
import { AtomizerSyncStatus } from "./AtomizerSyncStatus";
import { TerminalDeviceSensorsStats } from "../../SensorsView/TerminalDeviceSensorsStats";
import { useOutputsAPI } from "../../../../../context/outputs-context";

export const AtomizerComponent = (props) => {
  const { terminalDevice } = useTerminalDevice();
  const { updateTerminalDevice } = useApp();
  const { lora_outputs_actouput, lora_outputs_endoutput } = useOutputsAPI()

  const initState = {
    navBarOptionSelected: TerminalDeviceNavbarStates.HOME,
  };
  const [state, setState] = useState(initState);

  const onNavBarOptionClick = useCallback((option) => {
    setState((currentState) => ({
      ...currentState,
      navBarOptionSelected: option,
    }));
  }, []);

  const onOutputClick = useCallback(
    (terminalDeviceId, outputId, outputIndex) => {
      if (
        terminalDevice?.id === terminalDeviceId &&
        terminalDevice?.outputs instanceof Array
      ) {
        const objectOutput = terminalDevice.outputs.find(
          (output) => output?.output === outputId
        );
        if (objectOutput) {

          if (objectOutput.currentState === projectsConstants.global.states.output_deactivated) {
            lora_outputs_actouput(terminalDeviceId, outputId)
          }
          else{
            lora_outputs_endoutput(terminalDeviceId, outputId)
          }

          objectOutput.currentState =
            projectsConstants.global.states.output_transition;

         
          updateTerminalDevice(terminalDevice);
        }
      }
    },
    [terminalDevice, updateTerminalDevice, lora_outputs_actouput, lora_outputs_endoutput]
  );

  const onOutputDisabledClick = useCallback(() => {}, []);

  return (
    <div className="Terminals" key={terminalDevice?.id}>
      <div className="TitleTerminaldevices">
        <div className="TitleTd">
          {terminalDevice?.description || terminalDevice?.device?.name || ""}
        </div>
      </div>

      <div className="ContentCard">
        <TerminalDeviceNavbar
          showConfig={
            state.navBarOptionSelected !== TerminalDeviceNavbarStates.CONFIG
          }
          showHome={
            state.navBarOptionSelected !== TerminalDeviceNavbarStates.HOME
          }
          showStats={true}
          showPrograms={false}
          showInfo={true}
          infoComponent={<></>}
          onOptionClick={onNavBarOptionClick}
        ></TerminalDeviceNavbar>

        <div className="NotificationTerminalDevices">
          <AtomizerSyncStatus />
          {state.navBarOptionSelected === TerminalDeviceNavbarStates.HOME && (
            <>
              <TerminalDeviceGenericAnalogSensorView
                terminalDevice={terminalDevice}
                onAnalogSensorClick={() => {}}
              />
              <hr className="Barra" />
              <TerminalDeviceOutputsCardsView terminalDevice={terminalDevice} />

              <hr className="Barra" />
              <div className="subtitleapp">Salidas</div>
              <TerminalDeviceOutputsView
                mode={"principalview"}
                onClickOnDisabled={onOutputDisabledClick}
                onClick={onOutputClick}
                outputs={terminalDevice?.outputs || []}
                mustUseLongPress={true}
              />
            </>
          )}
          {state.navBarOptionSelected === TerminalDeviceNavbarStates.CONFIG && (
            <>
              <AtomizerDosificationConfiguration />
            </>
          )}
          {state.navBarOptionSelected === TerminalDeviceNavbarStates.STATS && (
            <TerminalDeviceSensorsStats
              terminalDevice={terminalDevice}
            ></TerminalDeviceSensorsStats>
          )}
        </div>
      </div>
    </div>
  );
};
