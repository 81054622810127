import React, { useCallback, useEffect, useState } from "react";
import { DragDropWithHooks } from "../Fichas/Programs/components/DragDrop/DragDropWithHook";
import { TriggerSubprogramCardDragAndDrop } from "./TriggerSubprogramCardDragAndDrop";

export const TriggerSubprogramsView = (props) => {
  const {
    trigger,
    onReorderedTriggerSubprograms,
    onEditTriggerSubprogram,
    onDeleteTriggerSubprogram,
  } = props;

  const [subprograms, setSubprograms] = useState(trigger?.subprograms);

  const onDeleteSubprogramInternal = useCallback((subprogram) => {
    if(onDeleteTriggerSubprogram){
      onDeleteTriggerSubprogram(subprogram)
    }
  }, [onDeleteTriggerSubprogram]);

  const onEditSubprogramInternal = useCallback((subprogram) => {
    if(onEditTriggerSubprogram){
      onEditTriggerSubprogram(subprogram)
    }
  }, [onEditTriggerSubprogram]);

  const getPrevSubprogramEndDateTime = useCallback((subprograms, targetId, defaultEndDateTime) => {
    if(!targetId){
      return defaultEndDateTime
    }

    const subprogram = subprograms.find(element => element.id === targetId)
    return subprogram?.endDateTime ?? defaultEndDateTime
  }, [])

  const getSortedElements = useCallback(() => {
    if (subprograms instanceof Array) {
      let triggerStartDateTime = new Date(trigger?.startTimestamp);

      const sortedSubprograms = subprograms
        .sort((first, second) => first?.id - second?.id)
        
      sortedSubprograms.forEach(subprogram => {
        const startDateTime = new Date(getPrevSubprogramEndDateTime(sortedSubprograms, subprogram.prevId, triggerStartDateTime).getTime() 
          + (subprogram.offset ?? 0))
        const endDateTime = new Date(startDateTime.getTime() + (subprogram.unitsLimit ?? 0))

        subprogram.startDateTime = startDateTime
        subprogram.endDateTime = endDateTime

      })

      return sortedSubprograms
        .map((subprogram) => ({
          id: subprogram?.id,
          content: (
            <TriggerSubprogramCardDragAndDrop
              subprogram={subprogram}
              onEdit={onEditSubprogramInternal}
              onDelete={onDeleteSubprogramInternal}
            ></TriggerSubprogramCardDragAndDrop>
          ),
        }));
    }
    return [];
  }, [onDeleteSubprogramInternal, onEditSubprogramInternal, subprograms, trigger?.startTimestamp]);

  const oReorderedActivations = useCallback(
    (keys) => {
      if (subprograms) {
        const newSubprograms = keys
          .flatMap((sourceId, index) => {        
            return subprograms
              .filter((subprogram) => subprogram?.id === sourceId)
              .map((subprogram) => {
                return {
                  ...subprogram,
                  prevId: index,
                  id: index + 1,
                }
            });
          })

        console.log(newSubprograms);
        if (onReorderedTriggerSubprograms) {
          onReorderedTriggerSubprograms(newSubprograms);
        }
      }
    },
    [onReorderedTriggerSubprograms, subprograms]
  );

  useEffect(() => {
    setSubprograms(trigger?.subprograms || []);
  }, [trigger?.subprograms]);

  return (
    <DragDropWithHooks
      onReordered={oReorderedActivations}
      items={getSortedElements()}
    ></DragDropWithHooks>
  );
};
