import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';

import "./PagePostigel.sass";
import { Cardpostigel } from "./components/Cardpostigel";
import Arrows from "./images/arrows.png";
import Bannerpostigel from "./images/bannerpostigel.png";
import { ReactComponent as IcoLiquid } from './images/liquidIco.svg';
import { ReactComponent as IcoDisinfection } from './images/disinfection.svg';
import { ReactComponent as IcoDetection } from './images/detection.svg';
import { ReactComponent as QuestionSvg } from "./images/question.svg";

import { ReactComponent as IcoGraphics } from './images/barchart.svg';

import { ReactComponent as Configure } from "./../../../images/ImagesTerminalsDevice/configure.svg"
import { ConfigurationPostigel, PUMP_OUTPUT_ID, LED_OUTPUT_ID, DELAY_DESINF_OUTPUT_ID } from "./components/configuration/ConfigurationPostigel";
import { PopUpC } from "../../components/Airframe/PopUp/PopUp";

import postigel from "./images/postigel.png";
import changeBotteIco from "./images/changebottle.png";
import { terminalService } from "../../../_services/terminal.service";
import { projectsConstants } from "../../../_constants/projects.constants";
import { SpecConfigMQTT } from "../../components/MQTT/postigel/SpecConfigMQTT";
import { BatteryStatistics } from "./components/BatteryStatistics";
import { Spinner } from "../../components/Airframe/Spinner/Spinner";
import { Statistics } from "./components/Statistics/Statistics";
import { RegulatorIcobatery } from "./components/Batery/RegulatorIcobatery";
import { HelpPostigel } from "./components/help/HelpPostigel";
import { ResetPostigel } from "./components/reset/ResetPostigel";
import { Calibration } from "./components/calibration/Calibration";
import { PostigelService } from "../../../_services/postigel.service";
import { CheckTimer } from "../../components/TerminalDevices/TerminalDevicesFather/TSM/CheckTimer";
import Timer from "../../components/Timer/Timer";
import { transpileModule } from "typescript";
export const PagePostigel = (props) => {

  //vars logo batery:
  const [valueBatery, setvalueBatery] = useState(100);
  const [loadBatery, setloadBatery] = useState(false);
  //vars data postigel
  const [valuesPostigel, setvaluesPostigel] = useState({
    liquid: 0,
    batery: 0,
    desinfection: 0,
    detection: 0
  });

  //const timeToGetData = 30000;
  const timeToGetData = 30000;
  const sectorId = props.sectorId || props.match?.params.sectorId || 0;
  const zoneId = props.zoneId || props.match?.params.zoneId || 0;
  const selectedTerminal = props.terminal;
  const terminalId = selectedTerminal?.id || 0;
  const history = useNavigate();
  //show popups:
  const [viewConfiguration, setviewConfiguration] = useState(false);
  const [viewBatteryStatistics, setviewBatteryStatistics] = useState(false);
  const [viewStatistics, setviewStatistics] = useState(false);
  const [viewInfo, setviewInfo] = useState(false);
  const [viewConfirmReset, setviewConfirmReset] = useState(false);
  const [viewCalibration, setviewCalibration] = useState(false);


  const [loading, setloading] = useState(true);
  const [terminalDevices, setterminalDevices] = useState([]);
  const [loaded, setLoaded] = useState(false);


  const [loadingError, setloadingError] = useState(false);
  const [mmsTerminalDevice, setMmsTerminalDevice] = useState(undefined);
  //show spinners
  const [isAnyNotificationPending, setIsAnyNotificationPending] = useState(false);

  const [messageError, setmessageError] = useState(undefined);

  const [dataContainer, setdataContainer] = useState(undefined);

  const getOutput = useCallback((outputs, outputId) => {
    let filtered = outputs.filter(output => {
      return output?.output === outputId
    });

    return filtered.length > 0 ? filtered[0] : undefined;
  }, []);

  useEffect(() => {

    getValuesPostigel();
  }, [mmsTerminalDevice]);

  const getValuesPostigel = useCallback(() => {

    if (mmsTerminalDevice !== undefined) {
      const pumpOutput = getOutput(mmsTerminalDevice.outputs, PUMP_OUTPUT_ID);
      const ledOutput = getOutput(mmsTerminalDevice.outputs, LED_OUTPUT_ID);
      const delayDesinfOutput = getOutput(mmsTerminalDevice.outputs, DELAY_DESINF_OUTPUT_ID);

      const data = pumpOutput?.container;
      setdataContainer(data);
      const capacity = data.capacity;
      const currentvalueCapacity = data.currentValue;
      /* console.log("Capacidad: " + currentvalueCapacity + "/" + capacity);
       console.log(pumpOutput);
       console.log(ledOutput);
       console.log(delayDesinfOutput);
 */
      const terminalIdTmp = mmsTerminalDevice.id;
      const terminalTmp = mmsTerminalDevice.terminal;
      PostigelService.getLast(terminalTmp, terminalIdTmp)
        .then((infoPostigel) => {
          const numberOfDesinfecctions = infoPostigel.dosificationCounter;
          const numberOfDetections = infoPostigel.presenceCounter;
          const levelBatery = infoPostigel.batteryLevel
          const porcentajeLiquid = (parseFloat((currentvalueCapacity / capacity) * 100)).toFixed(2);

          //  console.log("Porcentaje del liquido actual: " + porcentajeLiquid);

          setvaluesPostigel({
            ...valuesPostigel,
            desinfection: numberOfDesinfecctions,
            detection: numberOfDetections,
            batery: isFloat(levelBatery.toFixed(2)),
            liquid: isFloat(porcentajeLiquid)
          });

        }).catch(error => {
          console.log("Error obteniendo datos de postigel");
        });
    }
  }, [mmsTerminalDevice]);

  //Si el numero decimal tiene decimales redondos (00) devuelve el numero en tipo entero sin decimales.
  function isFloat(numberInput) {
    const decimal = numberInput % 1

    if (decimal === 0) {
      return parseInt(numberInput);
    } else {
      return numberInput;
    }

  }


  const checkIfAnyNotificationPending = useCallback((mms) => {
    if (mms.outputs instanceof Array) {
      let pending = false;
      mms.outputs.forEach(output => {
        if (output?.activationParams && (output?.output === PUMP_OUTPUT_ID || output?.output === LED_OUTPUT_ID || output?.output === DELAY_DESINF_OUTPUT_ID)) {
          const jsonActType = JSON.parse(output?.activationParams);
          if (jsonActType?.sourceSession !== jsonActType?.targetSession) {
            pending = true;
          }
        }
      });
      setIsAnyNotificationPending(pending);
    }
  }, []);

  const [errorSave, seterrorSave] = useState(false);
  const [errorConnectingTerminal, seterrorConnectingTerminal] = useState(false);


  const LoadTerminalDevices = useCallback((terminalId) => {
    terminalService.getTerminalDevices(terminalId).then(
      (tmpTerminalDevices) => {
        if (
          tmpTerminalDevices &&
          tmpTerminalDevices instanceof Array
        ) {
          setterminalDevices(tmpTerminalDevices);

          let mmsTerminalDevices = tmpTerminalDevices.filter(terminalDevice => {
            return terminalDevice?.device?.id === projectsConstants.global.devices.MMS;
          }).sort((mms1, mms2) => {
            return mms1?.id - mms2?.id;
          });

          const mmsTerminalDevice = mmsTerminalDevices.length > 0 ? mmsTerminalDevices[0] : undefined;
          setMmsTerminalDevice(mmsTerminalDevice);
          if (mmsTerminalDevice) {
            checkIfAnyNotificationPending(mmsTerminalDevice);
          }
        } else {
          setterminalDevices([]);
        }

        setloading(false);
        setloadingError(false);
      },
      (error) => {
        setloadingError(true);
        setterminalDevices([]);
      }
    );
  }, [checkIfAnyNotificationPending]);

  useEffect(() => {
    if (!loaded) {
      setloadingError(false);
      LoadTerminalDevices(terminalId);
      setLoaded(true);
    }
  }, [terminalId, terminalDevices]);



  const Return = useCallback(() => {
    history(`/zones/${zoneId}/sector/${sectorId}/`);
  }, [zoneId, sectorId]);

  const showInfo = useCallback(() => {
    setviewInfo(true);
  }, []);

  const confirmReset = useCallback(() => {
    setviewConfirmReset(true);
  }, []);

  const closeviewInfo = useCallback(() => {
    setviewInfo(false);
  }, []);

  const closeviewConfirmReset = useCallback(() => {
    setviewConfirmReset(false);
  }, []);




  //Control navegation configuration:
  //Info open/close
  const closeConfiguration = useCallback(() => {
    setviewConfiguration(false);
  }, []);

  const closeBatteryStatistics = useCallback(() => {
    setviewBatteryStatistics(false);
  }, []);

  const openConfiguration = useCallback(() => {
    setviewConfiguration(true);
  }, []);


  const showgraphicBatery = useCallback(() => {
    setviewBatteryStatistics(true);
  }, []);


  const openStatistics = useCallback(() => {
    setviewStatistics(true);
  }, []);

  const closeStatistics = useCallback(() => {

    setviewStatistics(false);
  }, []);





  const onOutputsUpdate = useCallback((outputs) => {
    if (outputs instanceof Array && outputs.length > 0
      && mmsTerminalDevice?.outputs instanceof Array) {
      outputs.forEach(newOutput => {
        if (newOutput) {
          for (let i = 0; i < mmsTerminalDevice?.outputs.length; i++) {
            const sourceOuput = mmsTerminalDevice.outputs[i];

            if (sourceOuput?.output === newOutput.output) {
              mmsTerminalDevice.outputs[i] = newOutput;
              break;
            }
          }
        }
      });

      checkIfAnyNotificationPending(mmsTerminalDevice);
    }
  }, [mmsTerminalDevice, checkIfAnyNotificationPending]);

  //#region MQTT 

  const processSpecConfigMessage = useCallback((id, message) => {
    if (message?.data) {
      const data = message.data;
      const config = data.config;
      const success = data.success;
      if (success !== projectsConstants.global.codes.ACK) {

        setmessageError(config?.target_id);
        seterrorSave(true);
      } else {
        // Debemos regargar las salidas.
        LoadTerminalDevices(terminalId);
      }
    }
  }, [projectsConstants, terminalId]);

  const processSpecConfigError = useCallback((id, message) => {
    setmessageError(message?.data?.target_id);
    seterrorConnectingTerminal(true);
  }, []);


  const openCalibrationMenu = useCallback(() => {
    setviewCalibration(true);
    setviewConfiguration(false);
  }, []);

  const closeCalibration = useCallback(() => {
    setviewCalibration(false);
  }, []);


  const onCalibrationDone = useCallback(() => {
    setviewCalibration(false);
  }, []);

  const executingTimer = useCallback((event) => {
    LoadTerminalDevices(terminalId);
  }, [terminalId, LoadTerminalDevices]);


  return (
    <>
      <Timer
        isActive={true}
        callback={executingTimer}
        callbackFirstExecutionDelayMiliseconds={0}
        callbackExecutionEveryMiliseconds={timeToGetData}
        timerIntervalMiliseconds={5000}
      />
      <SpecConfigMQTT processMsg={processSpecConfigMessage} processErrorMsg={processSpecConfigError} />
      <PopUpC
        activate={viewConfiguration}
        deactivatepopup={closeConfiguration}
        popupPersonalizedClass={"MoreLargemvl"}
        content={<ConfigurationPostigel
          closeConfiguration={closeConfiguration}
          mmsTerminalDevice={mmsTerminalDevice}
          onOutputsUpdate={onOutputsUpdate}
          openCalibrationMenu={openCalibrationMenu} />} />


      <PopUpC
        activate={viewStatistics}
        deactivatepopup={closeStatistics}
        popupPersonalizedClass={"MoreLargemvl"}
        content={<Statistics terminalId={terminalId} terminal={mmsTerminalDevice} liquid={valuesPostigel.liquid} dataContainer={dataContainer} />}
      />

      <PopUpC
        activate={viewBatteryStatistics}
        deactivatepopup={closeBatteryStatistics}
        popupPersonalizedClass={"MoreLargemvl"}
        content={<BatteryStatistics terminalId={terminalId} terminal={mmsTerminalDevice} />} />


      <PopUpC
        activate={viewInfo}
        deactivatepopup={closeviewInfo}
        popupPersonalizedClass={"MoreLargemvl"}
        content={<HelpPostigel />} />

      <PopUpC
        activate={viewConfirmReset}
        deactivatepopup={closeviewConfirmReset}
        popupPersonalizedClass={"MoreLargemvl"}
        content={<ResetPostigel dataContainer={dataContainer} liquid={valuesPostigel.liquid} closePopUp={closeviewConfirmReset} />} />

      <PopUpC
        activate={viewCalibration}
        deactivatepopup={closeCalibration}
        popupPersonalizedClass={"MoreLargemvl"}
        content={<Calibration onCalibrationDone={onCalibrationDone} terminalDevice={mmsTerminalDevice} terminalId={terminalId} />}
      />



      <div className="pagePostigel">

        <div className="bannerTitlePostigelf">


          <div className="PrimeraMitad">

            <div className="buttonsActionsPostigel">
              <div className="gotoConfigurationPostigel" onClick={openConfiguration}>
                <Configure className="IcoPostigel" />
              </div>


              <div className="gotoConfigurationPostigel" onClick={openStatistics}>
                {/*      <img className="IcoPostigelpng" src={Historical} alt="keyslist" />*/}


                <IcoGraphics className="IcoPostigelGraphics" />
              </div>

            </div>
            <div className="bannerTitlePostigel">

              <div className="titlepostigel">
                POSTIGEL
            </div>

            </div>

          </div>
          <div>{isAnyNotificationPending && <div className="spinnerPostigel"><Spinner size={"25px"} /></div>}</div>
          <div className="ErrosPostigel">
            {errorSave && <div className="ErrorPostigelpc">Error, guardando datos en el servidor.</div>}
            {errorConnectingTerminal && <div className="ErrorPostigelpc ">Error, no se ha podido contactar con el dispositivo.</div>}
          </div>

          <div className="buttonResetPostigel" >
            <div>
              <QuestionSvg
                className="ButtonProgram Info"
                onClick={(e) => showInfo()}
              />
            </div>
            <div className="icoResetPostigelf"
              onClick={(e) => confirmReset()}>
              <img src={changeBotteIco} alt="check" className="icoResetPostigel" />
            </div>


          </div>
        </div>

        {errorSave &&
          <div className="ErrorPostigelmvl ">Error, guardando datos en el servidor.</div>}


        {errorConnectingTerminal && <div className="ErrorPostigelmvl ">Error, no se ha podido contactar con el dispositivo.</div>}

        <div className="ButtonUI" onClick={(e) => Return()}>
          <div className="fa fa-fw fa-mail-reply"></div>
          <div className="<">
            <div className="SubtitleApp">dispositivos</div>

          </div>

        </div>



        <div className="dataUiPostigelf">
          <div className="dataUiPostigel">
            <Cardpostigel title="Nivel del liquido desinfectante" value={valuesPostigel.liquid} symbol={"%"} id={"fluid"} icon={<IcoLiquid className="IcoPostigelapp" />} />
          </div>
          <div className="centerpostigel">
            {/* <Cardpostigel title="Temperatura" value={valuesdefect.temp} id={"temp"} symbol={"°C"} icon={<IcoThermometer className="IcoPostigelapp" />} />*/}
            <Cardpostigel title="Numero de detecciones" value={valuesPostigel.detection} id={"detection"} symbol={""} icon={<IcoDetection className="IcoPostigelapp" />} />

            <div className="midldlePostigel">
              <img src={postigel} alt="check" className="arrowsPostigel" />
            </div>
            <Cardpostigel title="Numero de desinfecciones" value={valuesPostigel.desinfection} id={"desinfection"} symbol={""} icon={<IcoDisinfection className="IcoPostigelapp" />} />
          </div>

          <Cardpostigel showgraphicBatery={showgraphicBatery} title="Batería" value={valuesPostigel.batery} id={"batery"} symbol={"%"} icon={<RegulatorIcobatery value={valueBatery} loadBatery={loadBatery} />} />


          {/*onClick={(e) => showgraphicBatery()} */}

        </div>


      </div>
    </>
  );
};

