import React, { useEffect, useState, useCallback } from "react";
import { projectsConstants } from "../../../../_constants/projects.constants";
import "./OutputsView.sass";
import { ReactComponent as IcoOutputChange } from "./outputmodified.svg";
import { OutputCircle } from "./OutputCircle";

export function OutputsView(props) {
  const {
    disabled,
    colorLed,
    output,
    showpill,
    outputModified,
    onClickOnDisabled,
    onClick,
    showInfoOutputModified,
    hideDescription,
    className,
    mustUseLongPress
  } = props;
  const [descriptionOrNumber, setdescriptionOrNumber] = useState(undefined);
  const [shadowLedType, setshadowLedType] = useState(undefined);

  const typesColors = {
    Colorcontinuous: "rgb(33,150,243)",
    Colorpulses: "rgb(255,87,34)",
    Colorpulses_with_polarity: "#ca8eff",
    Colorpulses_per_minute: "rgb(55,81,30)",
  };

  const selectOutputModifiedInToolsFromView = useCallback(
    (index) => {
      showInfoOutputModified(output.output);
    },
    [showInfoOutputModified, output]
  );

  useEffect(() => {
    if (output?.description) {
      setdescriptionOrNumber(output.description);
    }

    switch (output && output.getActivationType()) {
      case projectsConstants.global.activations.continuous:
        setshadowLedType(typesColors.Colorcontinuous);
        break;
      case projectsConstants.global.activations.pulses:
        setshadowLedType(typesColors.Colorpulses);
        break;
      case projectsConstants.global.activations.pulses_with_polarity:
        setshadowLedType(typesColors.Colorpulses_with_polarity);
        break;
      case projectsConstants.global.activations.pulses_per_minute:
        setshadowLedType(typesColors.Colorpulses_per_minute);
        break;
      default:
        break;
    }
  }, [props, typesColors.Colorcontinuous, typesColors.Colorpulses, typesColors.Colorpulses_per_minute, typesColors.Colorpulses_with_polarity, output]);
  return (
    <>
      <>
        {outputModified && (
          <div className="icoModifiedOutput">
            <IcoOutputChange
              className="buttonInfoModifiedOutput Info"
              onClick={selectOutputModifiedInToolsFromView}
            />
          </div>
        )}
        <div
          className={className}
        >
          {!hideDescription && (
            <div
              className={`DescriptionOutputModified ${
                disabled && "notpointer"
              }`}
            >
              {showpill && (
                <div className="categoryOutput">{output?.outputType?.description}</div>
              )}

              <div
                className={`DescriptionOutputchildrencenter ${
                  disabled && "notpointer"
                }`}
              >
                {descriptionOrNumber}
              </div>
            </div>
          )}

          <OutputCircle
            output={output}
            title={descriptionOrNumber}
            disabled={disabled}
            colorLed={colorLed || "rgb(147, 18, 21)"}
            shadowColor={shadowLedType}
            onClick={onClick}
            onClickOnDisabled={onClickOnDisabled}
            mustUseLongPress={mustUseLongPress}
          ></OutputCircle>
        </div>
      </>
    </>
  );
}
