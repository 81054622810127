import React, { useCallback, useState } from "react";
import { TerminalDevices } from "./../TerminalDevices/TerminalDevices";
import { Spinner } from "../../Airframe/Spinner/Spinner";
import { LOADING_STATUS, useApp } from "../../../../context/app-context";
import { useNavigate } from 'react-router-dom';
import { TerminalOnlineChecker } from "../../Terminals/TerminalOnlineChecker";
import { terminalsConstants } from "../../../../_constants/terminals.constants";
import { TerminalDeviceProvider } from "../../../../context/terminal-device-context";
import { getConnectionStatusIcon } from "../../Terminals/TerminalWithSensorsCard";
import { DateTimetriggerUploader } from "../DatetimeTriggers/DateTimeTriggerUploader";
import { PopUpC } from "../../Airframe/PopUp/PopUp";
import { isTerminalDeviceValidForDatetimeTriggersActivationsWithOffset } from "../Fichas/Triggers/TerminalDeviceTriggerChecker";
import { projectsConstants } from "../../../../_constants/projects.constants";

export const TerminalDevicesfather = (props) => {
  const { zoneId, sectorId } = props;
  const { selectedTerminal, terminalDevices, loadingState } = useApp();
  const [showTriggersUploader, setShowTriggersUploader] = useState(false)
  const history = useNavigate();
  

  const loadTerminalDevicesComponent = useCallback(() => {
    return terminalDevices
      ?.sort((first, second) => {
        if (first.id < second.id) {
          return -1;
        }
        if (first.id > second.id) {
          return 1;
        }
        return 0;
      })
      ?.map((terminalDevice, index) => (
        <TerminalDeviceProvider key={index} terminalDevice={terminalDevice}>
          <TerminalDevices
            key={terminalDevice.id}
            index={index}
          />
        </TerminalDeviceProvider>
      ));
  }, [terminalDevices]);

  /*************** COMMENTS ********************************/

  const Return = useCallback(() => {
    history(`/zones/${zoneId}/sector/${sectorId}/`);
  }, [history, sectorId, zoneId]);

  const ClosePopUp = useCallback(() => {
    setShowTriggersUploader(false)
  }, []);

  const isValidForTerminalTriggersExcel = useCallback(() => {
    return terminalDevices
      .filter(terminalDevice => (terminalDevice.device?.id === projectsConstants.global.devices.SIO 
        || terminalDevice.device?.id === projectsConstants.global.devices.MMS 
        || terminalDevice.device?.id === projectsConstants.global.devices.MMS_X5))
      .reduce(
        (accumulator, currentValue) => isTerminalDeviceValidForDatetimeTriggersActivationsWithOffset(currentValue) & accumulator,
        true
      )
  }, [terminalDevices])

  const goToTrigger = useCallback(
    (trigger) => {
      //Llama a programas:
      if (
        selectedTerminal?.sector !== undefined &&
        zoneId !== undefined
      )
        history({
          pathname: `/zones/${zoneId}/sector/${selectedTerminal.sector}/terminals/${selectedTerminal.id}/terminalDevices/${trigger.terminalDevice}/programs/${trigger.id}`,
          dataprops: { ...props },
          programid: trigger.id,
          sectorid: selectedTerminal.sector,
          terminaldeviceid: trigger.terminalDevice,
          zoneid: zoneId,
        });
    },
    [props, history, zoneId, selectedTerminal]
  );

  return (
    <>
      {loadingState === LOADING_STATUS.loading || loadingState === LOADING_STATUS.idle ? (
        <div className={"Loadspinner heightspinner"}>
          <Spinner size={"50px"} />
        </div>
      ) : loadingState === LOADING_STATUS.error ? (
        <div className="Error">Error recuperando los Dispositivos</div>
      ) : (
        <>
          <div className="titleApp">
            <div>{selectedTerminal?.description}</div>
            <div>{getConnectionStatusIcon(selectedTerminal)}</div>

            {isValidForTerminalTriggersExcel() ? 
            <div  onClick={(e) => setShowTriggersUploader(true)}>
              <div className="fa fa-fw fa-calendar"></div>
            </div>
            : <></>
            }

          </div>
          <div className="ButtonUI" onClick={(e) => Return()}>
            <div className="fa fa-fw fa-mail-reply"></div>
            <div className="ButtonUITitle">
              <div className="SubtitleApp">Terminales</div>
            </div>
          </div>

          <PopUpC
            activate={showTriggersUploader}
            deactivatepopup={ClosePopUp}
            content={
              <DateTimetriggerUploader onTriggerClick={goToTrigger}/>
            }
          />

          <TerminalOnlineChecker />
          <div className="LoadDiv">
            {loadTerminalDevicesComponent()}

            {/* <TSM terminalDevices={terminalDevices} {...props} />*/}
          </div>
        </>
      )}
    </>
  );
};
