import React, { useCallback, useState } from "react";
import "./Headtimepreypos.sass";

export const Headtimepreypos = (props) => {
  //useEffect(() => {}, [props]);

  const types={
    right: "right",
    left: "left",
    principal: "select"
  }

  const {activeClockPre,activeClockPos,activeClockDefault}=props;
  const [selectedBlock, setSelectedBlock] = useState(types.principal);

  
  const clockPre = useCallback(() => {
    setSelectedBlock(types.left);
    activeClockPre();
  }, [activeClockPre]);
  const clockDefault = useCallback(() => {
    setSelectedBlock(types.principal);
    activeClockDefault();
  }, [activeClockDefault]);
  const clockPos = useCallback(() => {
    setSelectedBlock(types.right);
    activeClockPos();
  }, [activeClockPos]);

  return (
    <>
      <div className="OptionsIrrigationf">
        <div className={`IrriElement left ${selectedBlock === types.left ? types.principal : ""}`} /*onClick={(e) => clockPre()}*/>
          {/*Preactivación*/}
        </div>
        <div
          className={`IrriElement ${selectedBlock === types.principal ? types.principal : ""}`}
          onClick={(e) => clockDefault()}
        >
          Tiempo activación
        </div>
        <div className={`IrriElement right ${selectedBlock === types.right ? types.principal : ""}`} /*onClick={(e) => clockPos()}*/>
          {/*Postactivación*/}
        </div>
      </div>
    </>
  );
};
