import React from "react";
import { projectsConstants } from "../../../_constants/projects.constants";
import { MqttMessageProcessor } from "../MQTT/MqttMessageProcessor";

export const GetsensorvalueMQTT = (props) => {
  return (
    <MqttMessageProcessor
      topic={projectsConstants.master_outputs.actions.getsensorvalue}
      processMsg={props.processMsg}
      processErrorMsg={props.processErrorMsg}
    ></MqttMessageProcessor>
  );
};
