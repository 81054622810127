import React, { useCallback } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

export const PaginatedOptions = (props) => {
  const { label, values, valueActive, onOptionClick, unit } = props;

  const onClick = useCallback(
    (value) => {
      if (onOptionClick) {
        onOptionClick(value);
      }
    },
    [onOptionClick]
  );

  return (
    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
      <div className="childCardDevices2">{label}: </div>
      <Pagination
        aria-label={"Página"}
        style={{
          display: "flex-box",
          width: "100%",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          marginTop: "1%",
        }}
      >
        {values instanceof Array &&
          values.map((value, index) => (
            <PaginationItem active={value === valueActive} key={index}>
              <PaginationLink
                style={{ cursor: "pointer" }}
                onClick={() => onClick(value)}
              >
                <span
                  style={{ color: value === valueActive ? "white" : "black" }}
                >
                  {value}
                </span>
              </PaginationLink>
            </PaginationItem>
          ))}
        {unit && (
          <PaginationItem disabled={true} active={false} key={values.length + 1}>
            <PaginationLink disabled={true}>
              <span style={{ color: "black" }}>{unit}</span>
            </PaginationLink>
          </PaginationItem>
        )}
      </Pagination>
    </div>
  );
};
