import React from "react";

import Layout, {
  PageConfigConsumer
} from "./../../components/Layout";

import {
  ThemeProvider
} from "./../../components/Theme";

import "./../../styles/bootstrap.scss";
import "./../../styles/main.scss";
import "./../../styles/plugins/plugins.scss";
import "./../../styles/plugins/plugins.css";
import tecoIcon from "./../../images/favicons/teco.png"

const favIcons = [
  /*{
    rel: "icon",
    type: "image/x-icon",
    href: require("./../../images/favicons/favicon.ico")
  },

  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: require("./../../images/favicons/apple-touch-icon.png")
  }
  {
    rel: "icon",
    type: "image/png",
    sizes: "32x32",
    href: require("./../../images/favicons/favicon-32x32.png")
  },{
    rel: "icon",
    type: "image/png",
    sizes: "16x16",
    href: require("./../../images/favicons/favicon-16x16.png")
  }*/
  {
    id:"teco",
    rel: "icon",
    type: "image/png",
    sizes: "32x32",
    href: tecoIcon
  }
];

class TecoLayout extends React.Component {
  /*static propTypes = {
    children: PropTypes.node.isRequired,
    routedNavbar: PropTypes.func.isRequired,
    routedSidebar: PropTypes.func.isRequired
  };*/

  render() {
    const { children, routedNavBar, routedSideBar } = this.props;
    return (
      <ThemeProvider initialStyle="light" initialColor="primary">
        <Layout sidebarSlim favIcons={favIcons}>
          {/* --------- Navbar ----------- */}
          <Layout.Navbar key="NavBar">{routedNavBar()}</Layout.Navbar>
          {/* -------- Sidebar ------------*/}
          <Layout.Sidebar key="sidebar">{routedSideBar()}</Layout.Sidebar>

          {/* -------- Content ------------*/}
          <Layout.Content key="Layout">{children}</Layout.Content>

          {/* -- Theme Selector (DEMO) ----*/}
          {/*<PageConfigConsumer>-->
            {({ sidebarHidden, navbarHidden }) => (
              <ThemeSelector styleDisabled={sidebarHidden && navbarHidden} />
            )}
            </PageConfigConsumer>-*/}
        </Layout>
      </ThemeProvider>
    );
  }
}

export default TecoLayout;
