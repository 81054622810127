import React from "react"
import PropTypes from "prop-types";
import { useTerminalDevice } from "../../../../../../context/terminal-device-context"
import { ReactComponent as AddTerminalDeviceSensor } from "./../../../../../../images/add.svg"
import { AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from "reactstrap";
import { projectsConstants } from "../../../../../../_constants/projects.constants";

export const SensorsRS485Configuration = (props) => {

    const { 
        showButtonSave, 
        onErrorSaving,
        onSuccessSaving
    } = props
    const { terminalDevice, updateTerminalDeviceSensor: parentUpdateTerminalDeviceSensor} = useTerminalDevice()

    return <UncontrolledAccordion stayOpen>
        {terminalDevice.sensors instanceof Array && terminalDevice.sensors
            .filter((terminalDeviceSensor) => projectsConstants.global.sensors.phys.RS485 !== terminalDeviceSensor.physicalCommunicationType)
            .map((terminalDeviceSensor, index) => {return <AccordionItem key={`${terminalDeviceSensor.sensorId?.id}-${terminalDeviceSensor?.sensorIndex}`}>
            <AccordionHeader targetId={index}>{terminalDeviceSensor?.sensorIndex}</AccordionHeader>
            <AccordionBody accordionId={index}>index</AccordionBody>
        </AccordionItem>})}
    </UncontrolledAccordion>
}

SensorsRS485Configuration.propTypes = {
    showButtonSave: PropTypes.func.isRequired,
    onErrorSaving: PropTypes.func.isRequired,
    onSuccessSaving: PropTypes.func.isRequired,
};