import React, { useState, useEffect } from "react";
import { BarChart, XAxis, YAxis, Tooltip, ResponsiveContainer, Bar, CartesianGrid, Legend } from 'recharts';

export const CardStatistics = (props) => {

    const { title, value, symbol, ico } = props;

    return (
        <>
            <div className="cardSavePostigel">
                <div>
                    <div className="titlecardSavePostigel">{title}</div>
                    <div className="valuecardSavePostigel2">{value} {symbol}</div>
                </div>
                <div> {ico} </div>

            </div>
        </>
    );
};
