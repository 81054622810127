import React, { useState, useEffect } from "react";
import { Terminalsfather } from "./../../components/Terminals/Terminalsfather";
import { TecoSidebar } from "./../../TecoSidebar/TecoSidebar";
import { TecoNavbar } from "./../../TecoNavbar/TecoNavbar";
import TecoLayout from "./../../Layout/TecoLayout";
import { useApp } from "../../../context/app-context";
import { zoneService } from "../../../_services/zone.service";
import { useParams } from 'react-router-dom';

export const PageTerminals = (props) => {
  let params = useParams();
  const [sectorId] = useState(params?.sectorId || 0);
  const [zoneId] = useState(params?.zoneId || 0);
  const { selectedSector, setSelectedSector } = useApp();

  useEffect(() => {
    if (selectedSector?.id !== sectorId) {
      zoneService
        .getZoneSector(zoneId, sectorId)
        .then((sector) => {
          setSelectedSector(sector.zoneSector);
        })
        .catch((error) => {
          console.log("ERROR CARGANDO SECTOR");
        });
    }
  }, [sectorId, zoneId]);

  return (
    <>
      <TecoLayout
        routedNavBar={() => (
          <TecoNavbar
            NavMenu={"Terminals"}
            sectorId={sectorId}
            zoneId={zoneId}
          />
        )}
        routedSideBar={() => <TecoSidebar />}
        key="menu"
      >
        <Terminalsfather
          changeTitle={sectorId}
          zoneId={zoneId}
          sectorId={sectorId}
        />
      </TecoLayout>
    </>
  );
};
