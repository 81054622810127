import React, { useState, useEffect, useCallback } from "react";
import "./calibration.sass";
import { InputCalibration } from "./InputCalibrate";
import { ReactComponent as IcoVaso } from "./vaso.svg";
import { ReactComponent as IcoCalibrate } from "../../images/calibrate.svg";
import { SpecCalibrationMQTT } from "../../../../components/MQTT/postigel/SepcCalibrationMQTT";
import { usePublisher } from "../../../../../context/publish-context";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import uuid from "uuid/v4";
import { MESSAGE_TYPE_ACTION } from "../../../../../_constants/messageType.constants";
import { Spinner } from "../../../../components/Airframe/Spinner/Spinner";
import { OutputService } from "../../../../../_services/outputcontroller.service";
import { PUMP_OUTPUT_ID } from "../configuration/ConfigurationPostigel";
import { ReactComponent as IcoDanger } from './danger.svg';

export const Calibration = (props) => {
  const { onCalibrationDone, terminalDevice, terminalId } = props;
  const DOSIS_NUMBER = 3;
  const DOSIS_TIME_MS = 2000;
  const CALIBRATION_UNITS = "cl";

  const states = {
    IDLE: "IDLE",
    NOTIFYING: "NOTIFYING",
    NOTIFIED: "NOTIFIED",
  };
  const errors = {
    NONE: "NONE",
    NOTIFYING: "E_NOTIFYING",
    TERMINAL_DEVICE_RESPONSE_ERROR: "TERMINAL_DEVICE_RESPONSE_ERROR",
    API: "API"
  };
  const steps = {
    INFO: "INFO",
    RESULTS: "RESULTS"
  }
  const [stateMachine, setStateMachine] = useState({
    state: states.IDLE,
    currentStep: steps.INFO,
    dosis: [0, 0, 0]
  });
  const [errorStateMachine, setErrorStateMachine] = useState({
    error: errors.NONE,
  });
  const { publish } = usePublisher();

  const notifyPostigel = useCallback(() => {
    publish(
      projectsConstants.postigel.actions.speccalibration,
      {
        type: MESSAGE_TYPE_ACTION,
        id: uuid(),
        data: {
          target_id: terminalDevice.id,
          dosisNumber: DOSIS_NUMBER,
          dosisTimeMs: DOSIS_TIME_MS,
        },
      },
      projectsConstants.postigel.id
    );
  }, [terminalDevice, publish, MESSAGE_TYPE_ACTION, DOSIS_NUMBER, DOSIS_TIME_MS]);

  const goToCalibrate = useCallback(() => {
    notifyPostigel();
    setStateMachine((stateMachine) => {
      return { ...stateMachine, state: states.NOTIFYING };
    });
    setErrorStateMachine(errorStateMachine => { return { ...errorStateMachine, error: errors.NONE } })
  }, [notifyPostigel, states, errors]);

  const onCalibrationMqttMsg = useCallback(
    (messageId, message) => {
      const terminalDeviceId = message?.data?.calibration?.target_id;
      const success =
        message?.data?.success == projectsConstants.global.codes.ACK;
      if (terminalDeviceId === terminalDevice.id) {
        if (success) {
          setStateMachine((stateMachine) => {
            return { ...stateMachine, state: states.NOTIFIED, currentStep: steps.RESULTS };
          });
          setErrorStateMachine((errorStateMachine) => {
            return { errorStateMachine, error: errors.NONE };
          });
        } else {
          setStateMachine((stateMachine) => {
            return { ...stateMachine, state: states.IDLE, currentStep: steps.INFO };
          });
          setErrorStateMachine((errorStateMachine) => {
            return {
              errorStateMachine,
              error: errors.TERMINAL_DEVICE_RESPONSE_ERROR,
            };
          });
        }
      }
    },
    [terminalDevice, states, errors, steps]
  );

  const onCalibrationMqttError = useCallback(
    (messageId, message) => {
      const terminalDeviceId = message?.data?.target_id;
      if (terminalDeviceId === terminalDevice.id) {
        setStateMachine((stateMachine) => {
          return { ...stateMachine, state: states.IDLE, currentStep: steps.INFO };
        });
        setErrorStateMachine((errorStateMachine) => {
          return { ...errorStateMachine, error: errors.NOTIFYING };
        });
      }
    },
    [terminalDevice, states, errors, steps]
  );

  //#region  DOSIS

  const getAverageDosis = useCallback(() => {
    return stateMachine.dosis.reduce((accumulator, currentValue) => accumulator + currentValue) / stateMachine.dosis.length;
  }, [stateMachine]);

  const onChangeDosis = useCallback((value, index) => {
    stateMachine.dosis[index] = value;
    setStateMachine({ ...stateMachine, dosis: [...stateMachine.dosis] });
  }, [stateMachine])

  //#endregion

  const onAcceptClick = useCallback(() => {
    /*const averageDosis = Math.round(getAverageDosis() * 100) / 100;
    OutputService.putTerminalDeviceOutputCalibration(terminalId, terminalDevice.id, PUMP_OUTPUT_ID, {
      pulses: DOSIS_TIME_MS,
      valueInUnit: averageDosis,
      unit: CALIBRATION_UNITS
    }).then(calibration => {
      onCalibrationDone();
    }
    ).catch(error => {
      console.log(error);
      setErrorStateMachine(errorStateMachine => { return { ...errorStateMachine, error: errors.API } });
    })*/
    alert("Pendiente de implementar!")

  }, []);

  return (
    <>
      <SpecCalibrationMQTT
        processMsg={onCalibrationMqttMsg}
        processErrorMsg={onCalibrationMqttError}
      ></SpecCalibrationMQTT>
      {stateMachine.state === states.NOTIFYING && (
        <div className="panelNotificationPostigel">
          <div>
            Notificando al Postigel
          </div>
          <div>
            <Spinner size="50px" />
          </div>
        </div>
      )}
      {stateMachine.state !== states.NOTIFYING &&
        stateMachine.currentStep === steps.INFO && (
          <>
            <div className="helptitle titlehelpPostigel">Calibración:</div>
            {/*--------Errores:-------- */}
            {errorStateMachine.error === errors.NOTIFYING && (<div className="ErrorfPostigel">
                        <IcoDanger className="IcoErrorPostigel" />
              <div className="ErrorconfPostigel">
                <div >Postigel no conectado o se ha perdido la respuesta. </div>
                
               <div className="ErrorconfPostigelc">Asegúrese que
                tiene conectado el Postigel a internet y vuelva a intentarlo en unos minutos.</div> 
              </div></div>
            )}

            {errorStateMachine.error === errors.TERMINAL_DEVICE_RESPONSE_ERROR && (
              <div className="ErrorconfPostigel">Error en el Postigel</div>
            )}

            {errorStateMachine.error === errors.API && (
              <div className="ErrorconfPostigel">Error guardando los datos del calibrado.</div>
            )}

            {/*---------------- */}
            {errorStateMachine.error === errors.NONE && (<>
              <div className="bodyCalibratePosti">
                <div className="subhelptitle">
                  Los pasos para realizar el calibrado correctamente, son los
                  siguientes:
              </div>

                <div className="subhelptitle">
                  Postigel te servirá 3 dosis, pon un vasito con el que puedas
                  medir en cl la dosis recibida e introduce las 3 medidas en
                  centilitros (Cl).
              </div>

                <div className="IcoFinalCalibrate">
                  <IcoVaso className="IcoPostigel" />
                </div>
                <div className="subhelptitle">
                  Eso es todo, de esta manera ya estará listo para que tengas un
                  control de la cantidad del líquido.
                  Podrás configurar la
                  dosis por segundos.
              </div>
              </div>

              <div className="ButtonaddCalibratePostigel">
                <div className="postigelcenterButton">
                  <div className="DivButtonsPostigel">
                    <div
                      className="ButtonC2 smallb Secondary2inf helpsecondary"
                      onClick={(e) => goToCalibrate()}
                    >
                      Comenzar
                  </div>
                  </div>
                </div>

                <div>1/2</div>
              </div>
            </>)}
          </>
        )}




      {stateMachine.state !== states.NOTIFYING &&
        stateMachine.currentStep === steps.RESULTS && (
          <>
            <div className="helptitle titlehelpPostigel">
              {/*    <IcoCalibrate className="IcoPostigel" /> */}Calibración:
            </div>

            <div className="cardsHelpAccordion fathercalibrate">
              <InputCalibration name={"Dosis1"} onChange={onChangeDosis} onChangeParams={[0]} />
              <InputCalibration name={"Dosis2"} onChange={onChangeDosis} onChangeParams={[1]} />
              <InputCalibration name={"Dosis3"} onChange={onChangeDosis} onChangeParams={[2]} />
            </div>



            <div className="ButtonaddCalibratePostigel">
              <div className="postigelcenterButton">
                <div className="DivButtonsPostigel">
                  <div
                    className="ButtonC2 smallb Secondary2inf helpsecondary"
                    onClick={(e) => onAcceptClick()}
                  >
                    Aceptar
                  </div>
                </div>
              </div>
              2/2
            </div>
          </>
        )}
    </>
  );
};
