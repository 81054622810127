import React, {useEffect} from "react";
import { useUser } from "../../context/user-context";
import { Navigate } from "react-router-dom";
import { useApp } from "../../context/app-context";

export const Logout = () => {
    const {logout} = useUser();

    useEffect(() => {
        logout();
        console.log("LOGGED OUT");
        return () => {
            
        }
    })

    return <Navigate to="/login"></Navigate>;
}