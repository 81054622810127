import React, { useMemo, useState, useCallback } from "react";
import {
  Form,
  FormGroup,
  Input,
  Button,
  Label,
  FormFeedback,
} from "reactstrap";
import EmptyLayout from "../../EmptyLayout";
import { HeaderAuth } from "../../login/components/HeaderAuth";
import { FooterAuth } from "../FooterAuth";
import { ThemeConsumer } from "../../Theme";
import { useUser } from "../../../context/user-context";
import { Spinner } from "../../components/Airframe/Spinner/Spinner";

export const ForgotPassword = (props) => {
  const apiStates = useMemo(
    () => ({
      IDLE: "IDLE",
      REQUESTED: "REQUESTED",
      DONE: "DONE",
      ERROR: "ERROR",
    }),
    []
  );

  const initState = useMemo(
    () => ({
      email: undefined,
      invalidEmail: false,

      apiState: apiStates.IDLE,
    }),
    [apiStates.IDLE]
  );
  const [state, setState] = useState(initState);
  const { requestResetPassword } = useUser();

  const validateEmail = useCallback((email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }, []);

  const onEmailChange = useCallback(
    (email) => {
      setState((current) => ({
        ...current,
        email: email,
        invalidEmail: !validateEmail(email),
      }));
    },
    [validateEmail]
  );

  const onResetPasswordClick = useCallback(
    (evt) => {
      setState((current) => ({ ...current, apiState: apiStates.REQUESTED }));
      requestResetPassword(state.email).then(
        (result) =>
          setState((current) => ({
            ...current,
            apiState: result ? apiStates.DONE : apiStates.ERROR,
          })),
        (err) => {
          setState((current) => ({
            ...current,
            apiState: apiStates.ERROR,
          }));
        }
      );
    },
    [
      apiStates.DONE,
      apiStates.ERROR,
      apiStates.REQUESTED,
      requestResetPassword,
      state.email,
    ]
  );

  return (
    <EmptyLayout>
      <EmptyLayout.Section center>
        <HeaderAuth
          title="Restablecer contraseña en TECO"
          text="Introduzca sus datos y le enviaremos un enlace para poder definir su nueva contraseña."
        />

        {state.apiState === apiStates.REQUESTED && <Spinner />}
        {state.apiState === apiStates.DONE && (
          <p className="text-success">
            En breve recibirá un correo con las instrucciones.
          </p>
        )}
        {state.apiState === apiStates.ERROR && (
          <p className="text-danger">
            Parece que ha habido un error, vuelva a intentarlo en unos minutos.
          </p>
        )}

        {(state.apiState === apiStates.IDLE ||
          state.apiState === apiStates.ERROR) && (
          <Form className="mb-3">
            <FormGroup>
              <Label className="titleLabels" for="emailAdress">
                Correo electrónico
              </Label>
              <Input
                type="email"
                name="email"
                id="emailAdress"
                placeholder="Introducir el correo"
                className="bg-white"
                value={state.email || ""}
                onChange={(e) => onEmailChange(e.target.value)}
                invalid={state.invalidEmail}
              />
              {state.invalidEmail && (
                <FormFeedback>Correo no válido</FormFeedback>
              )}
            </FormGroup>

            <ThemeConsumer>
              {({ color }) => (
                <Button
                  color={color}
                  className="BotonLogin"
                  block
                  onClick={onResetPasswordClick}
                  disabled={state.invalidEmail || state.email === undefined}
                >
                  <div className="BotonLogin">Restablecer</div>
                </Button>
              )}
            </ThemeConsumer>
          </Form>
        )}

        <FooterAuth />
      </EmptyLayout.Section>
    </EmptyLayout>
  );
};
