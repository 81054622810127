import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import EmptyLayout from "../../EmptyLayout";
import { HeaderAuth } from "../../login/components/HeaderAuth";
import {
  Form,
  FormGroup,
  Input,
  Button,
  Label,
  FormFeedback,
} from "reactstrap";
import { ThemeConsumer } from "../../Theme";
import { FooterAuth } from "../FooterAuth";
import { Link, useSearchParams } from "react-router-dom";
import { useUser } from "../../../context/user-context";
import { Spinner } from "../../components/Airframe/Spinner/Spinner";

export const ResetPassword = (props) => {
  const regularExpression = useMemo(
    () => /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
    []
  );

  const apiStates = useMemo(
    () => ({
      IDLE: "IDLE",
      REQUESTED: "REQUESTED",
      DONE: "DONE",
      ERROR: "ERROR",
    }),
    []
  );

  const initState = useMemo(
    () => ({
      jwt: undefined,
      jwtInvalid: false,

      password: undefined,
      passwordConfirm: undefined,
      invalidPassword: false,
      invalidPasswordConfirm: false,

      apiState: apiStates.IDLE,
    }),
    [apiStates.IDLE]
  );
  const [state, setState] = useState(initState);
  const [searchParams] = useSearchParams();
  const passwordConfirmRef = useRef(null);
  const { resetPassword } = useUser();

  const onPasswordChange = useCallback(
    (password) => {
      setState((current) => ({
        ...current,
        password,
        invalidPassword: !regularExpression.test(password),
      }));
    },
    [regularExpression]
  );

  const onPasswordConfirmChange = useCallback(
    (passwordConfirm) =>
      setState((current) => ({
        ...current,
        passwordConfirm,
        invalidPasswordConfirm: current.password !== passwordConfirm,
      })),
    []
  );

  const onSetPasswordClick = useCallback(
    (evt) => {
      setState((current) => ({ ...current, apiState: apiStates.REQUESTED }));
      resetPassword(state.jwt, state.password).then(
        (result) =>
          setState((current) => ({
            ...current,
            apiState: result ? apiStates.DONE : apiStates.ERROR,
          })),
        (err) => {
          setState((current) => ({
            ...current,
            apiState: apiStates.ERROR,
          }));
        }
      );
    },
    [
      resetPassword,
      state.jwt,
      state.password,
      apiStates.REQUESTED,
      apiStates.DONE,
      apiStates.ERROR,
    ]
  );

  const onPasswordKeyPress = useCallback((e) => {
    if (e.charCode === 13 || e.keyCode === 13) {
      passwordConfirmRef.current.focus();
    }
  }, []);

  const onPasswordCofnirmKeyPress = useCallback(
    (e) => {
      if (e.charCode === 13 || e.keyCode === 13) {
        onSetPasswordClick();
      }
    },
    [onSetPasswordClick]
  );

  useEffect(() => {
    if (
      searchParams.has("p") &&
      searchParams.has("h") &&
      searchParams.has("s")
    ) {
      const jwt = `${searchParams.get("h")}.${searchParams.get(
        "p"
      )}.${searchParams.get("s")}`;
      const jwtPayloadObject = JSON.parse(window.atob(searchParams.get("p")));
      let jwtInvalid = false;

      if (jwtPayloadObject?.exp > 0) {
        const currentTimestamp = new Date().getTime() / 1000;
        jwtInvalid = currentTimestamp >= jwtPayloadObject.exp;
      }

      setState((current) => ({
        ...current,
        jwtInvalid,
        jwt,
      }));
    } else {
      setState((current) => ({ ...current, jwtInvalid: true }));
    }
  }, [searchParams]);

  if (state.jwtInvalid) {
    return (
      <EmptyLayout>
        <EmptyLayout.Section center>
          <HeaderAuth
            title="Restablecer contraseña TECO"
            text="Introduzca los datos de su nueva contraseña."
          />
          <p className="text-danger">El enlace ha caducado.</p>
          <FooterAuth />
        </EmptyLayout.Section>
      </EmptyLayout>
    );
  }

  return (
    <EmptyLayout>
      <EmptyLayout.Section center>
        <HeaderAuth
          title="Restablecer contraseña TECO"
          text="Introduzca los datos de su nueva contraseña."
        />
        <Form className="mb-3">
          <FormGroup>
            <Label className="titleLabels" for="emailAdress">
              Nueva contraseña:
            </Label>
            <Input
              type="password"
              name="password"
              id="password"
              placeholder="Contraseña..."
              className="bg-white"
              value={state.password || ""}
              onChange={(e) => onPasswordChange(e.target.value)}
              onKeyPress={onPasswordKeyPress}
              invalid={state.invalidPassword}
            />
            {state.invalidPassword && (
              <FormFeedback>
                Contraseña no válida. Debe ser de almenos 6 carácteres, contener
                al menos un número y un cáracter especial: !@#$%^&*
              </FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label className="titleLabels" for="emailAdress">
              Repetir contraseña:
            </Label>
            <Input
              type="password"
              name="passwordConfirm"
              id="passwordConfirm"
              placeholder="Contraseña..."
              className="bg-white"
              value={state.passwordConfirm || ""}
              onChange={(e) => onPasswordConfirmChange(e.target.value)}
              onKeyPress={onPasswordCofnirmKeyPress}
              invalid={state.invalidPasswordConfirm}
              innerRef={passwordConfirmRef}
            />
            {state.invalidPasswordConfirm && (
              <FormFeedback>Las contraseñas no coinciden</FormFeedback>
            )}
          </FormGroup>

          {state.apiState === apiStates.REQUESTED && <Spinner />}
          {state.apiState === apiStates.DONE && (
            <div>
              <p className="text-success">Se ha modificado la contraseña</p>
              <div className="margindown">
                <Link to="/login">
                  Iniciar sesión
                </Link>
              </div>
            </div>
          )}
          {state.apiState === apiStates.ERROR && (
            <p className="text-danger">Error modificando la contraseña</p>
          )}

          {(state.apiState === apiStates.IDLE ||
            state.apiState === apiStates.ERROR) && (
            <ThemeConsumer>
              {({ color }) => (
                <Button
                  color={color}
                  className="BotonLogin"
                  block
                  onClick={onSetPasswordClick}
                  disabled={
                    state.invalidPassword ||
                    state.invalidPasswordConfirm ||
                    state.password === undefined ||
                    state.passwordConfirm === undefined
                  }
                >
                  <div className="BotonLogin">Definir contraseña</div>
                </Button>
              )}
            </ThemeConsumer>
          )}
        </Form>
        <FooterAuth />
      </EmptyLayout.Section>
    </EmptyLayout>
  );
};
