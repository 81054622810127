import React, { useCallback } from "react";
import { Input } from "reactstrap";
import { InputGroupwithid } from "../../../Airframe/InputGroup/InputGroupwithid";
import { TriggerConfigurationTypes } from "./TerminalDeviceSensorTriggerConfigurator";
import Bell from "./../../../../../images/bell.png";
import BellActive from "./../../../../../images/bell_active.png";
import StopActive from "./../../../../../images/stop.png";
import Stop from "./../../../../../images/stop_trans.png";
import AddUser from "./../../../../../images/users/add-user.png";
import { ReceiversAssociationView } from "../../../Receivers/ReceiversAssociationView";
import { TerminalDeviceOutputsView } from "../../OutputsView/TerminalDeviceOutputsView";
import { TerminalDeviceSensorTriggerAdditionalActions } from "./TerminalDeviceSensorTriggerAdditionalActions";

export const TerminalDeviceSensorTriggerForm = (props) => {
  const {
    trigger,
    terminalDeviceSensor,
    title,
    focusOnSetpoint,
    greaterOperator,
    smallerOperator,
    triggerConfigurationType,
    allowAdditionalActions,

    onSetpointChange,
    onOperatorChange,
    onProportionalBandChange,
    onReadPeriodChange,
    onDosificationPeriodChange,
    onActivationTimeChange,

    onBellClick,
    onStopClick,
    onAddUserClick,

    onReceiverDelete,
    onReceiverChange,

    dropDownTerminalDevices,
    triggerTerminalDeviceOutputs,
    terminalDeviceOutputs,
    onSelectOutput,
  } = props;

  //#region  TITLES
  const getProportionalBandTitle = useCallback(() => {
    return triggerConfigurationType === TriggerConfigurationTypes.SENSOR_ALARM
      ? "Margen"
      : "Banda proporcional";
  }, [triggerConfigurationType]);

  const getDosificationPeriodTitle = useCallback(() => {
    return triggerConfigurationType === TriggerConfigurationTypes.SENSOR_ALARM
      ? "Tiempo estabilización"
      : "Periodo inyección";
  }, [triggerConfigurationType]);

  //#endregion

  //#region GET TRIGGER DATA

  const getTriggerSetpoint = useCallback(() => {
    return trigger?.setpoint === undefined || trigger?.deletedByUser
      ? ""
      : trigger.setpoint;
  }, [trigger]);

  const getTriggerOperator = useCallback(() => {
    return trigger?.operator ? trigger.operator : greaterOperator;
  }, [trigger.operator, greaterOperator]);

  const getProportionalBand = useCallback(() => {
    return trigger?.proportionalBand === undefined || trigger?.deletedByUser
      ? ""
      : trigger.proportionalBand;
  }, [trigger]);

  const getReadPeriod = useCallback(() => {
    return trigger?.readPeriod === undefined || trigger?.deletedByUser
      ? ""
      : trigger.readPeriod;
  }, [trigger]);

  const getDosificationPeriod = useCallback(() => {
    return trigger?.dosificationPeriod === undefined || trigger?.deletedByUser
      ? ""
      : trigger.dosificationPeriod;
  }, [trigger]);

  const getActivationTime = useCallback(() => {
    return !trigger?.activations ||
      trigger?.activations[0]?.unitsLimit === undefined ||
      trigger?.deletedByUser
      ? ""
      : trigger.activations[0]?.unitsLimit / 1000;
  }, [trigger?.deletedByUser, trigger?.activations]);

  //#endregion

  return (
    <>
      {(!triggerConfigurationType ||
        triggerConfigurationType === TriggerConfigurationTypes.SENSOR ||
        triggerConfigurationType ===
          TriggerConfigurationTypes.SENSOR_ALARM) && (
        <>
          <div className="titleSensorInConf">
            Define la {title} para "{terminalDeviceSensor.sensorId?.description}
            ". La salida se debe activar siempre que se cumpla:
          </div>
          <div className="RowUISelectTypes">
            <div className="sensorInputToolsTsm">{"valor es"}</div>
            <div className="DropdownF_SelectType dropdownPanelTypeBig">
              <InputGroupwithid
                foco={focusOnSetpoint}
                type="number"
                placeholder={`${title}...`}
                value={getTriggerSetpoint()}
                className="bg-white inputTSM"
                //onChange={(e) => changeConf(e.target.value, id)}
                tabIndex={-1}
                id={"inputgroup"}
                symbol={
                  getTriggerOperator() === greaterOperator ? (
                    <div className="fa fa-fw fa-chevron-right"></div>
                  ) : (
                    <div className="fa fa-fw fa-chevron-left"> </div>
                  )
                }
                onChange={onSetpointChange}
                min={0}
                personalized={"tsm"}
                changeCondition={onOperatorChange}
              />
            </div>
          </div>

          <div className="RowUISelectTypes">
            <div className="sensorInputToolsTsm">
              {getProportionalBandTitle()}
            </div>
            <div className="DropdownF_SelectType dropdownPanelTypeBig">
              <Input
                type="number"
                placeholder={`${getProportionalBandTitle()} ...`}
                value={getProportionalBand()}
                onChange={onProportionalBandChange}
                min={0.1}
              ></Input>
            </div>
          </div>

          {triggerConfigurationType !==
            TriggerConfigurationTypes.SENSOR_ALARM && (
            <div className="RowUISelectTypes">
              <div className="sensorInputToolsTsm">Periodo lectura</div>
              <div className="DropdownF_SelectType dropdownPanelTypeBig">
                <Input
                  type="number"
                  placeholder={"Periodo lectura (s)..."}
                  value={getReadPeriod()}
                  onChange={onReadPeriodChange}
                  min={1}
                ></Input>
              </div>
            </div>
          )}

          <div className="RowUISelectTypes">
            <div className="sensorInputToolsTsm">
              {getDosificationPeriodTitle()}
            </div>
            <div className="DropdownF_SelectType dropdownPanelTypeBig">
              <Input
                type="number"
                placeholder={`${getDosificationPeriodTitle()} (s)...`}
                value={getDosificationPeriod()}
                onChange={onDosificationPeriodChange}
                min={1}
              ></Input>
            </div>
          </div>
        </>
      )}

      {(triggerConfigurationType === TriggerConfigurationTypes.BUTTON ||
        triggerConfigurationType ===
          TriggerConfigurationTypes.SENSOR_ALARM) && (
        <div className="RowUISelectTypes">
          <div className="sensorInputToolsTsm">Tiempo activación</div>
          <div className="DropdownF_SelectType dropdownPanelTypeBig">
            <Input
              type="number"
              placeholder={"Tiempo activación (s)..."}
              value={getActivationTime()}
              onChange={onActivationTimeChange}
              min={1}
            ></Input>
          </div>
        </div>
      )}

      {allowAdditionalActions && (
        <TerminalDeviceSensorTriggerAdditionalActions
          trigger={trigger}
          onStopClick={onStopClick}
          onBellClick={onBellClick}
          onAddUserClick={onAddUserClick}
        />
      )}

      {allowAdditionalActions && trigger?.mustBeReportedWhenExecuted && (
        <ReceiversAssociationView
          receivers={trigger?.receivers}
          onReceiverDelete={onReceiverDelete}
          onReceiverChange={onReceiverChange}
        />
      )}

      {(!allowAdditionalActions ||
        !trigger?.mustEnterInMaintenanceWhenExecuted) && (
        <>
          <div className="RowUISelectTypes titleSecondaryTSM">
            Salidas a activar:
          </div>

          {dropDownTerminalDevices}
          <TerminalDeviceOutputsView
            mode={"subprogram"}
            onClick={onSelectOutput}
            outputs={terminalDeviceOutputs?.outputs || []}
            forcedMapStates={triggerTerminalDeviceOutputs}
            forcedSelectedColor={"rgb(33,150,243)"}
          />
        </>
      )}
    </>
  );
};
