import { TerminalDeviceInfraprogram } from "../Infraprogram/TerminalDeviceInfraprogram";
import { OutputTaskTerminalDeviceSubprogram } from "./OutputTaskTerminalDeviceSubprogram";

export class TerminalDeviceSubprogram {
  id;
  activeTime;
  outputs;
  index;

  initDelay;
  endDelay;

  deleted_by_user;
  deleted_by_user_at;

  //private List<OutputTaskTerminalDeviceSubprogramDTO>
  outputTasks;
  infraprograms;

  static parseFromPlainDto(dtoText) {
    const plainObject = JSON.parse(dtoText);
    return TerminalDeviceSubprogram.parseOutputDtoFromObject(plainObject);
  }

  static parseFromObjectDto(dtoObject) {
    if (dtoObject) {
      const terminalDeviceSubrogram = Object.setPrototypeOf(
        dtoObject,
        TerminalDeviceSubprogram.prototype
      );

      if (dtoObject.outputTasks instanceof Array) {
        terminalDeviceSubrogram.outputTasks = dtoObject.outputTasks.map(
          (outputTask) =>
            OutputTaskTerminalDeviceSubprogram.parseFromObjectDto(outputTask)
        );
      }

      if (dtoObject.infraprograms instanceof Map) {
        terminalDeviceSubrogram.infraprograms.forEach((infraprograms, hierarchyLevel) => {
            if(infraprograms && infraprograms instanceof Array){
                infraprograms.forEach((infraprogram, index, infraprogramsArraySource) => {
                    infraprogramsArraySource[index] = infraprogram instanceof TerminalDeviceInfraprogram ?  infraprogram : TerminalDeviceInfraprogram.parseFromObjectDto(infraprogram);
                })
            }
        });
      }

      return terminalDeviceSubrogram;
    }
    return dtoObject;
  }

  getEndDateTime(startDatetime) {
    const [hour, minute] = this.activeTime
      ? this.activeTime.split(":")
      : [0, 0];
    if (startDatetime && startDatetime instanceof Date) {
      startDatetime.addHours(hour);
      startDatetime.addMinutes(minute);
    }
    return startDatetime;
  }

  getOutputsList() {
    const outputsList = [];

    let offset = 1;
    let i = 0;
    while (offset <= this.outputs) {
      if (offset & this.outputs) {
        outputsList.push(i + 1);
      }
      offset = 1 << ++i;
    }

    return outputsList;
  }

  getName() {
    return `S${this.index + 1}`;
  }
}
