import React from "react";
import { Spinner } from "../../../../../Airframe/Spinner/Spinner";

export const CalibrationStepNotifying = (props) => {
  return (
    <div>
      <div>Notificando al dispositivo...</div>
      <div className="spinnerCalibrate">
        <Spinner size={"50px"} />
      </div>
    </div>
  );
};


