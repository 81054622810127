import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { TsmCalibrateSensorMQTT } from "../../../../MQTT/tsm/TsmCalibrateSensorMQTT";
import { projectsConstants } from "../../../../../../_constants/projects.constants";
import { usePublisher } from "../../../../../../context/publish-context";
import uuid from "uuid/v4";
import { MESSAGE_TYPE_ACTION } from "../../../../../../_constants/messageType.constants";
import { TsmSensorsListener } from "../TsmSensorsListener";
import { CalibrationStepNotifying } from "./Steps/CalibrationStepNotifying";
import { CalibrationStepWaitingToStabilize } from "./Steps/CalibrationStepWaitingToStabilize";
import { CalibrationStepSuccess } from "./Steps/CalibrationStepSuccess";
import { CalibrationStepError } from "./Steps/CalibrationStepError";
import { CalibrationStepInstructions } from "./Steps/CalibrationStepInstructions";
import { CalibrationStepPointDone } from "./Steps/CalibrationStepPointDone";
import { TsmCalibrationReportMQTT } from "../../../../MQTT/tsm/TsmCalibrationReportMQTT";

export const CalibrationWithPoints = (props) => {
  const CALIBRATION_STATES = {
    IDLE: "IDLE",
    NOTIFIYING: "NOTIFIYING",
    NOTIFIED_WAITING_TO_STABILIZE: "NOTIFIED_WAITING_TO_STABLISH",
    CALIBRATION_DONE: "CALIBRATION_DONE",
    CALIBRATION_POINT_DONE: "CALIBRATION_POINT_DONE",
    CALIBRATION_ERROR: "CALIBRATION_ERROR",
    CALIBRATION_NOTIFYING_ERROR: "CALIBRATION_NOTIFYING_ERROR",
  };

  const CALIBRATION_POSITIONS = {
    LOW: "LOW",
    MED: "MED",
    HIGH: "HIGH",
  };

  const calibrationInitState = {
    state: CALIBRATION_STATES.IDLE,
    lastStateAt: undefined,
    notified: false,
    allPointsCalibrated: false,
    probe_acid_ideal: undefined,
    probe_base_ideal: undefined,
    mv_differ_from_zero: undefined,
  };

  const { points, terminalDevice, sensor } = props;

  const { publish } = usePublisher();

  const [currentPointIndex, setCurrentPointIndex] = useState(0);
  const [calibrationState, setCalibrationState] = useState(
    calibrationInitState
  );

  const getCurrentPointValue = useCallback(() => {
    return points[currentPointIndex];
  }, [currentPointIndex, points]);

  const getPointPosition = useCallback(() => {
    switch (points[0]) {
      case 4:
        return CALIBRATION_POSITIONS.LOW;
      case 10:
        return CALIBRATION_POSITIONS.HIGH;
      case 7:
      default:
        return CALIBRATION_POSITIONS.MED;
    }
  }, [
    points,
    CALIBRATION_POSITIONS.LOW,
    CALIBRATION_POSITIONS.MED,
    CALIBRATION_POSITIONS.HIGH,
  ]);

  const publishCalibrationMQTT = useCallback(
    (point, bufferValue) => {
      let published = publish(
        projectsConstants.master_outputs.actions.tsm.tsm_calibratesensor,
        {
          id: uuid(),
          type: MESSAGE_TYPE_ACTION,
          data: {
            target_id: terminalDevice.id,
            sensor_id: sensor.sensorIndex + 1,
            buffer: parseFloat(bufferValue),
            hast_tmp_sensor: false,
            point,
          },
        },
        projectsConstants.master_outputs.id
      );

      if (published) {
        setCalibrationState((prevState) => {
          return {
            ...prevState,
            notified: false,
            state: CALIBRATION_STATES.NOTIFIYING,
          };
        });
      }
    },
    [publish, terminalDevice.id, sensor.sensorIndex, CALIBRATION_STATES.NOTIFIYING]
  );

  const onStartCalibration = useCallback(() => {
    const currentPointValue = getCurrentPointValue();
    const pointPosition = getPointPosition();
    publishCalibrationMQTT(pointPosition, currentPointValue);
  }, [getCurrentPointValue, getPointPosition, publishCalibrationMQTT]);

  const  onNextCaibrationPointClick = useCallback(() => {
    setCalibrationState(prevState => {
      return {...prevState, state: CALIBRATION_STATES.IDLE};
    })
  }, [CALIBRATION_STATES.IDLE]);

  const getCalibrationStateContent = useCallback(
    (value) => {
      switch (calibrationState.state) {
        case CALIBRATION_STATES.NOTIFIYING:
          return <CalibrationStepNotifying></CalibrationStepNotifying>;
        case CALIBRATION_STATES.NOTIFIED_WAITING_TO_STABILIZE:
          return (
            <CalibrationStepWaitingToStabilize
              lastValue={sensor.lastValue}
              lastValueAt={
                sensor.lastValueAt &&
                new Date(Date.parse(sensor.lastValueAt)).toLocaleString()
              }
            ></CalibrationStepWaitingToStabilize>
          );
        case CALIBRATION_STATES.CALIBRATION_DONE:
          return <CalibrationStepSuccess></CalibrationStepSuccess>;
        case CALIBRATION_STATES.CALIBRATION_POINT_DONE:
          return <CalibrationStepPointDone onNext={onNextCaibrationPointClick}></CalibrationStepPointDone>
        case CALIBRATION_STATES.CALIBRATION_ERROR:
          return (
            <CalibrationStepError
              isNotifyingError={false}
            ></CalibrationStepError>
          );
        case CALIBRATION_STATES.CALIBRATION_NOTIFYING_ERROR:
          return (
            <CalibrationStepError
              isNotifyingError={true}
            ></CalibrationStepError>
          );
        case CALIBRATION_STATES.IDLE:
          return (
            <CalibrationStepInstructions
              bufferValue={getCurrentPointValue()}
              onCalibrationStart={onStartCalibration}
              currentStep={currentPointIndex + 1}
              totalSteps={points instanceof Array ? points.length : 0}
            />
          );
        default:
          return (
            <CalibrationStepError
              isUnknownState={true}
              unknownState={calibrationState.state}
            ></CalibrationStepError>
          );
      }
    },
    [calibrationState.state, CALIBRATION_STATES.NOTIFIYING, CALIBRATION_STATES.NOTIFIED_WAITING_TO_STABILIZE, CALIBRATION_STATES.CALIBRATION_DONE, CALIBRATION_STATES.CALIBRATION_POINT_DONE, CALIBRATION_STATES.CALIBRATION_ERROR, CALIBRATION_STATES.CALIBRATION_NOTIFYING_ERROR, CALIBRATION_STATES.IDLE, sensor.lastValue, sensor.lastValueAt, onNextCaibrationPointClick, getCurrentPointValue, onStartCalibration, currentPointIndex, points]
  );

  const onCalibrationMqttMsg = useCallback(
    (topic, message) => {
      const terminalDeviceId = message?.data?.target_id;
      const sensorIndex = message?.data?.sensor_id - 1;

      if (
        terminalDeviceId === terminalDevice?.id &&
        sensorIndex === sensor?.sensorIndex
      ) {
        sensor.status = projectsConstants.global.sensors.states.calibrating;
        setCalibrationState((prevState) => {
          return {
            ...prevState,
            notified: true,
            state: CALIBRATION_STATES.NOTIFIED_WAITING_TO_STABILIZE,
          };
        });
      }
    },
    [sensor, terminalDevice, CALIBRATION_STATES]
  );

  const onCalibrationMqttError = useCallback(
    (topic, message) => {
      const terminalDeviceId = message?.data?.target_id;
      const sensorIndex = message?.data?.sensor_id - 1;

      if (
        terminalDeviceId === terminalDevice?.id &&
        sensorIndex === sensor?.sensorIndex
      ) {
        setCalibrationState((prevState) => {
          return {
            ...prevState,
            notified: false,
            state: CALIBRATION_STATES.CALIBRATION_NOTIFYING_ERROR,
          };
        });
      }
    },
    [terminalDevice, sensor, CALIBRATION_STATES]
  );

  const processCalibrationResult = useCallback((result, errorCode, probeData = {}) => {
    let state = "UNKNOWN";
    if (result) {
      let allPointsCalibrated = currentPointIndex + 1 === points.length;
      setCurrentPointIndex(currentPointIndex + 1);
      state = allPointsCalibrated ? CALIBRATION_STATES.CALIBRATION_DONE : CALIBRATION_STATES.CALIBRATION_POINT_DONE;
    } else {
      state = CALIBRATION_STATES.CALIBRATION_ERROR;
    }
    setCalibrationState((prevState) => {
      return {
        ...prevState,
        lastStateAt: new Date(),
        state,
        ...probeData
      };
    });
  }, [currentPointIndex, points, CALIBRATION_STATES.CALIBRATION_DONE, CALIBRATION_STATES.CALIBRATION_POINT_DONE, CALIBRATION_STATES.CALIBRATION_ERROR]);

  const onCalibrationReportMqttMsg = useCallback((topic, message) => {
    if(message?.data?.devices_calibrations instanceof Array){
      message.data.devices_calibrations
      .filter(deviceCalibration => deviceCalibration?.target_id === terminalDevice?.id)
      .flatMap(deviceCalibration => deviceCalibration?.sensors || {})
      .filter(sensorCalibration => sensorCalibration?.sensor_index === sensor?.sensorIndex )
      .forEach(sensorCalibration => {
        if(sensorCalibration?.result !== undefined && sensorCalibration?.result !== null){
          processCalibrationResult(sensorCalibration.result === 0, sensorCalibration.result, {
            probe_acid_ideal: sensorCalibration?.probe_acid_ideal,
            probe_base_ideal: sensorCalibration?.probe_base_ideal,
            mv_differ_from_zero: sensorCalibration?.mv_differ_from_zero
           });
        }else{
          console.log("tsm_calibrationreport -> Incompleto.");
        }
      });
    }
  }, [processCalibrationResult, sensor.sensorIndex, terminalDevice.id]);

  const onCalibrationReportMqttError = useCallback((topic, message) => {
    console.log("MENSAJE INESPERADO -> " + message);
  }, []);

  const processSensorState = useCallback(
    (sensor) => {
      if (calibrationState.notified) {
        switch (sensor?.status) {
          case projectsConstants.global.sensors.states.idle:
          case projectsConstants.global.sensors.states.measuring:
            processCalibrationResult(sensor?.error !== projectsConstants.global.sensors.errors.none, sensor?.error);
            break;
          case projectsConstants.global.sensors.states.measuring_not_stable:
          case projectsConstants.global.sensors.states.calibrating:
            setCalibrationState((prevState) => {
              return {
                ...prevState,
                lastStateAt: new Date(),
                state: CALIBRATION_STATES.NOTIFIED_WAITING_TO_STABILIZE,
              };
            });
            break;
          default:
            break;
        }
      }
    },
    [CALIBRATION_STATES, calibrationState, processCalibrationResult]
  );

  const onUpdateStateReceived = useCallback(
    (sensorUpdated) => {
      if (sensorUpdated === sensor) {
        processSensorState(sensor);
      }
    },
    [sensor, processSensorState]
  );

  if (!points instanceof Array || !points.length) {
    return <>No se han definido los puntos de calibrado</>;
  }

  if (currentPointIndex >= points.length) {
    return <div>
      <>Se ha finalizado el calibrado</>
      {calibrationState?.probe_acid_ideal && <div>% Ácido ideal: {calibrationState.probe_acid_ideal}%</div>}
      {calibrationState?.probe_base_ideal && <div>% Base ideal: {calibrationState.probe_base_ideal}%</div>}
      </div>;
  }

  return (
    <>
      <TsmCalibrateSensorMQTT
        processMsg={onCalibrationMqttMsg}
        processErrorMsg={onCalibrationMqttError}
      />
      <TsmSensorsListener
        terminalDevice={terminalDevice}
        onUpdateStateReceived={onUpdateStateReceived}
      />
      <TsmCalibrationReportMQTT
        processMsg={onCalibrationReportMqttMsg}
        processErrorMsg={onCalibrationReportMqttError}
      />
      {getCalibrationStateContent()}
    </>
  );
};

CalibrationWithPoints.propTypes = {
  points: PropTypes.arrayOf(PropTypes.number),
  terminalDevice: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  sensor: PropTypes.shape({
    sensorIndex: PropTypes.number.isRequired,
    status: PropTypes.string,
  }),
};
