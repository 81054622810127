import React from "react";
import { OutputconfigLegend } from "./HeadLegend/OutputconfigLegend";
export const HelpView = (props) => {
  return (
    <div className="documentation">
      <div className="helptitle">Ayuda:</div>
      <div className="subhelptitle">El color de la sombra de cada salida, indica el tipo de configuración
      establecido.</div>
      <OutputconfigLegend/>
    </div>
  );
};
