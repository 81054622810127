import React, { useCallback, useState, useEffect } from "react";

import { CardSensorTSM } from "./CardSensorTSM";
import "./TSM.sass";
import { TsmConfigure } from "./TsmConfigure/TsmConfigure";
import { PopUpC } from "../../../Airframe/PopUp/PopUp";
import { Calibrate } from "./Calibrate/Calibrate";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import { TsmSensorsListener } from "./TsmSensorsListener";
import { SetTriggersMQTT } from "../../../MQTT/triggers/SetTriggersMQTT";
import { DeleteTriggersMQTT } from "../../../MQTT/triggers/DeleteTriggersMQTT";
import { Notification } from "../../../Airframe/Notification/components/Notification";
import { useRef } from "react";
import { TerminalDeviceFooterInfo } from "../../TerminalDevices/Components/TerminalDeviceFooterInfo";

export const TSMComponent = (props) => {
  const { terminalDevices, terminalDevice } = props;

  const [goToConfig, setgoToConfig] = useState(false);
  const [openPopup, setopenPopup] = useState(false);
  const [errorEnterInConfSensor, seterrorEnterInConfSensor] = useState(false);
  const [selectedSensor, setselectedSensor] = useState(undefined);
  const [sensors, setSensors] = useState(terminalDevice?.sensors);
  const notificationsRef = useRef([]);

  const [errorsType, seterrorsType] = useState({
    delete: false,
    post: false,
    put: false,
    notfound: false,
  });

  const [exitsType, setExitsType] = useState({
    delete: false,
    post: false,
    put: false,
    notfound: false,
  });

  useEffect(() => {
    setSensors(terminalDevice.sensors);
  }, [terminalDevice?.sensors]);

  const configureConsign = useCallback(
    (sensorIndex) => {
      let selectedSensor;
      sensors.forEach((sensor) => {
        if (sensor?.sensorIndex === sensorIndex) {
          selectedSensor = sensor;
        }
      });
      if (selectedSensor) {
        setselectedSensor(selectedSensor);
        setgoToConfig(true);
        seterrorEnterInConfSensor(false);
      } else {
        seterrorEnterInConfSensor(true);
      }
    },
    [goToConfig, sensors]
  );

  const showErrors = useCallback(
    (exitsTypetmp, errorsTypetmp) => {
      setExitsType(exitsTypetmp);
      seterrorsType(errorsTypetmp);
    },
    [exitsType, errorsType]
  );

  const back = useCallback(() => {
    setgoToConfig(false);
  }, [goToConfig]);

  const calibrateSensor = useCallback((sensor) => {
    setselectedSensor(sensor);
    setopenPopup(true);
  }, []);

  const closePopup = useCallback((event) => {
    setopenPopup(false);
  }, []);

  const getCard = useCallback(
    (key, sensorUnit, sensor) => {

      let trigger;
      if (sensor?.triggers?.length > 0 && sensor?.triggers[0]?.deletedByUser === false) {
        trigger = sensor.triggers[0];
      }

      return (
        <CardSensorTSM
          key={key}
          id={sensor?.sensorIndex}
          sensorIndex={sensor?.sensorIndex}
          sensorUnit={sensorUnit}
          sensor={sensor}
          setpoint={trigger?.setpoint}
          button={
            <div className="ButtonTSM">
              <div
                className="ButtonOffAll Secondary"
                onClick={(e) => calibrateSensor(sensor)}
              >
                Calibrar
              </div>
            </div>
          }
          configureConsign={configureConsign}
        />
      );
    },
    [calibrateSensor, configureConsign]
  );

  const getSensorCards = useCallback(() => {
    let cards = [];

    if (sensors instanceof Array) {
      cards = sensors
        .sort((sensorOne, sensorTwo) => {
          return sensorOne?.sensorIndex - sensorTwo?.sensorIndex;
        })
        .filter(
          (sensor) =>
            sensor?.active && sensor?.sensorId && sensor?.sensorId?.measurements
        )
        .map((sensor) => {
          sensor.sensorId.measurements.forEach((m) => {
            m.sensorSource = sensor;
          });
          return sensor;
        })
        .flatMap((sensor) => {
          return sensor.sensorId.measurements;
        })
        .filter((measurements) => {
          return measurements?.measurementUnit;
        })
        .map((measurements, index) => {
          return getCard(
            index,
            measurements.measurementUnit,
            measurements.sensorSource
          );
        })
        .filter((card) => {
          return card !== undefined;
        });
    }

    return cards;
  }, [sensors, getCard, projectsConstants]);

  const onTriggerCreated = useCallback(
    (trigger) => {
      if (!selectedSensor?.triggers instanceof Array) {
        selectedSensor.triggers = [];
      }
      selectedSensor.triggers.push(trigger);
      selectedSensor.triggers = [...selectedSensor.triggers];
    },
    [selectedSensor]
  );

  const onTriggerUpdated = useCallback(
    (trigger) => {
      if (!selectedSensor?.triggers instanceof Array) {
        selectedSensor.triggers = [];
      }

      for (let i = 0; i < selectedSensor.triggers.length; i++) {
        const sourceTrigger = selectedSensor.triggers[i];
        if (sourceTrigger?.id === trigger?.id) {
          selectedSensor.triggers[i] = trigger;
        }
      }

      selectedSensor.triggers = [...selectedSensor.triggers];
    },
    [selectedSensor]
  );

  const onTriggerDeleted = useCallback(
    (trigger) => {
      if (!selectedSensor?.triggers instanceof Array) {
        selectedSensor.triggers = [];
      }

      let indexFound = -1;
      for (let i = 0; i < selectedSensor.triggers.length; i++) {
        const sourceTrigger = selectedSensor.triggers[i];
        if (sourceTrigger?.id === trigger?.id) {
          indexFound = i;
          sourceTrigger.deletedByUser = true;
        }
      }

      if (indexFound >= 0) {
        
        selectedSensor.triggers = [...selectedSensor.triggers];
      }
    },
    [selectedSensor]
  );

  const onUpdateStateReceived = useCallback((sensorUpdated) => {
    setSensors((sensors) => {
      return [...sensors];
    });
  }, []);

  //#region  Triggers MQTT

  const processSetTriggerIds = useCallback((triggerIds, isActive) => {
    let updated = false;
    const sensorsWithTriggers = sensors
        .filter(sensor => sensor?.triggers instanceof Array && sensor.triggers.length > 0)
    triggerIds.forEach((triggerId) => {
      sensorsWithTriggers.forEach(sensor => {

        let sensorTrigger = sensor.triggers.filter(
          (t) => t?.id === triggerId
        )[0];
        if (sensorTrigger) {
          sensorTrigger.notified = true;
  
          updated = true;
  
          if(isActive){
            notificationsRef.current.push({message: `Se ha actualizado la consigna del sensor ${sensor?.sensorIndex + 1}. Revise el estado de las salidas anteriores asociadas.`});
          }
        }
      });
    });

    if (updated) {
      onUpdateStateReceived();
    }
  }, [sensors, onUpdateStateReceived]);

  const onSetTriggersMqttMessage = useCallback(
    (topic, message) => {
      //const terminalId = message?.data?.terminal_id;
      const success = message?.data?.success;
      const triggerIds = message?.data?.trigger_ids;
      const triggerIdsActive = message?.data?.trigger_ids_active;
      if (
        success &&
        sensors instanceof Array
      ) {
        if(triggerIds instanceof Array){
          processSetTriggerIds(triggerIds, false);
        }
        if(triggerIdsActive instanceof Array){
          processSetTriggerIds(triggerIdsActive, true);
        }
      }
    },
    [processSetTriggerIds]
  );

  const onSetTriggersMqttError = useCallback((topic, message) => {
    console.log(message);
  });

  const processDeleteTriggerIds = useCallback((triggerIds, isActive) => {
    let updated = false;
    const sensorsWithTriggers = sensors
        .filter(sensor => sensor?.triggers instanceof Array && sensor.triggers.length > 0)
    triggerIds.forEach((triggerId) => {
      sensorsWithTriggers.forEach(sensor => {

        let sensorTrigger = sensor.triggers.filter(
          (t) => t?.id === triggerId
        )[0];
        if (sensorTrigger) {
          sensorTrigger.deletedByUser = true;
  
          updated = true;
  
          if(isActive){
            notificationsRef.current.push({message: `Se ha eliminado la consigna del sensor ${sensor?.sensorIndex + 1}. Revise el estado de las salidas asociadas.`});
          }
        }
      });
    });

    if (updated) {
      onUpdateStateReceived();
    }
  }, [sensors, onUpdateStateReceived]);

  const onDeleteTriggersMqttMessage = useCallback(
    (topic, message) => {
      const success = message?.data?.success;
      const triggerIds = message?.data?.trigger_ids;
      const triggerIdsActive = message?.data?.trigger_ids_active;
      if (
        success &&
        sensors instanceof Array
      ) {
        if(triggerIds instanceof Array){
          processDeleteTriggerIds(triggerIds, false);
        }
        if(triggerIdsActive instanceof Array){
          processDeleteTriggerIds(triggerIdsActive, true);
        }
      }
    },
    [sensors, processDeleteTriggerIds]
  );

  const onDeleteTriggersMqttError = useCallback((topic, message) => {
    console.log(message);
  });

  //#endregion

  const getNotificationsComponents = useCallback(() => {
    let size = notificationsRef.current.length;
    let notifications = [];
    for(let i = 0; i < size; i++){
      let not = notificationsRef.current.shift();
      notifications.push(<Notification title={""} message={not.message} type={"info"} time={5000}></Notification>);
    }
    return <div>{notifications}</div>
  }, []);

  return (
    <>
      {getNotificationsComponents()}
      <TsmSensorsListener
        terminalDevice={terminalDevice}
        onUpdateStateReceived={onUpdateStateReceived}
      ></TsmSensorsListener>
      <SetTriggersMQTT
        processMsg={onSetTriggersMqttMessage}
        processErrorMsg={onSetTriggersMqttError}
      />
      <DeleteTriggersMQTT
        processMsg={onDeleteTriggersMqttMessage}
        processErrorMsg={onDeleteTriggersMqttError}
      />
      <PopUpC
        activate={openPopup}
        deactivatepopup={closePopup}
        popupPersonalizedClass={"MoreLargemvl"}
        content={
          <Calibrate
            terminalDevice={terminalDevice}
            sensor={selectedSensor}
            closePopup={closePopup}
          />
        }
      />
      {/* <CheckTimer /> */}
      <div className="Terminals">
        <div className="TitleTerminaldevices">
          <div className="TitleTd">TSM</div>
        </div>

        {goToConfig && (
          <TsmConfigure
            back={back}
            terminalDevices={terminalDevices}
            selectedSensor={selectedSensor}
            terminalDevice={terminalDevice}
          />
        )}

        {/*Botón acceso a panel de edición de consigna */}
        {!goToConfig && (
          <>
            {/*<div className="ButtonsChangeAnimation tsmtitleIconsHead">
              <div className="goToStatisticsTsm" onClick={openStatistics}>
                <IcoGraphics className="IcoPostigelGraphics" />
              </div>
            </div>
            <hr className="Barra"></hr>*/}
            {/* Tarjetas sensores */}
            <div className="sensorTSMPanel">
              {exitsType.post === true && (
                <div className="messageTsm">Consigna creada</div>
              )}
              {exitsType.delete === true && (
                <div className="Errortsm">Consigna borrada</div>
              )}
              {exitsType.put === true && (
                <div className="messageTsm">Consigna modificada</div>
              )}
              {errorEnterInConfSensor && (
                <div className="Errortsm">
                  No tienes ningún sensor configurado
                </div>
              )}
              {getSensorCards()}
            </div>

            <TerminalDeviceFooterInfo terminalDevice={terminalDevice} />
          </>
        )}
      </div>
    </>
  );
};
