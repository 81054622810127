import React, { useCallback, useEffect, useState } from "react";
import { PopUpC } from "../../../Airframe/PopUp/PopUp";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import { sensorService } from "../../../../../_services/sensors.service";
import { DangerComponent } from "../../../../DangerComponent/DangerComponent";

import _ from "lodash";
import { TerminalDeviceSensorsDropDown } from "./sensorsConf/TerminalDeviceSensorsDropDown";

import { ReactComponent as QuestionSvg } from "./../../../../../images/ImagesTerminalsDevice/question.svg";

import { CheckComponent } from "../../../Airframe/CheckComponent/CheckComponents";
import { SensorConfiguration } from "./sensorsConf/SensorConfiguration";
import { Sensorsconfhelp } from "./sensorsConf/Sensorsconfhelp";
import { usePublisher } from "../../../../../context/publish-context";
import uuid from "uuid/v4";

import { MESSAGE_TYPE_ACTION } from "../../../../../_constants/messageType.constants";
import { TerminalDeviceSensorStats } from "../../TerminalDevices/Components/TerminalDeviceSensorStats";
import { TerminalDeviceSensorTriggerConfigurator, TriggerConfigurationOperators, TriggerConfigurationTypes } from "../Triggers/TerminalDeviceSensorTriggerConfigurator";
import { TerminalDeviceSensorCalibrationRegister, TERMINAL_DEVICE_SENSOR_CALIBRATION_OPEN_POINT } from "./calibration/TerminalDeviceSensorCalibrationRegister";
import { TerminalDeviceSensorCalibrationInfo } from "./calibration/TerminalDeviceSensorCalibrationInfo";
import { isTerminalDeviceValidForTriggers } from "../Triggers/TerminalDeviceTriggerChecker";
import { isTerminalDeviceValidForReportSensorValues } from "./ReportSensorValuesChecker";
import { isTerminalDeviceNotAbleToHandleMultipleAnalogInputType, isTerminalDeviceValidForAnalogInputType } from "./SensorInputTypeChecker";
import { isValidMeasurement } from "./sensorsConf/SensorGenericMeasurementConfiguration";
import { useApp } from "../../../../../context/app-context";
import { useTerminalDevice } from "../../../../../context/terminal-device-context";
import { TwoOptionsBox,
        options as twoOptionsIds } from "./TwoOptionsBox";
import { SensorsRS485Configuration } from "./RS485/SensorsRS485Configuration";

export const SensorsConfiguration = (props) => {
  const {
    showButtonSave,
    mustSave,
    id,
    onErrorSaving,
    onSuccessSaving,
    infoLoadingServer,
    type,
    buttonAction,
  } = props;
  const {terminalDevices} = useApp()
  const { terminalDevice, updateTerminalDeviceSensor: parentUpdateTerminalDeviceSensor} = useTerminalDevice()
  const [sensors, setsensors] = useState([]);
  const { maxAnalogInputs } = terminalDevice.device;

  const [sensorTypes, setsensorTypes] = useState([]);
  const [sensorTypesLoaded, setsensorTypesLoaded] = useState(false);
  const [sensorTypesLoading, setsensorTypesLoading] = useState(false);
  const [selectedSensorDescription, setselectedSensorDescription] = useState(
    undefined
  );

  const [waitingApiResponse, setwaitingApiResponse] = useState(false);

  const [selectedSensor, setselectedSensor] = useState(undefined);
  const [selectedSensorStatus, setselectedSensorStatus] = useState(false);
  const [selectedSensorTypeId, setselectedSensorTypeId] = useState(undefined);
  const [selectedReportInterval, setSelectedReportInterval] = useState(undefined);
  const [showSensorsConfHelp, setshowSensorsConfHelp] = useState(false);
  const [showSensorsConfType, setshowSensorsConfType] = useState(false);

  const errorConstants = {
    NONE: "NONE",
    SERVICE: "SERVICE",
    EMPTY_SENSOR_TYPE: "EMPTY_SENSOR_TYPE",
    API: "API",
    DIFF_ANALOG_INPUT_TYPE: "DIFF_ANALOG_INPUT_TYPE",
  };
  const [errors, setErrors] = useState({ error: errorConstants.NONE });
  /*
  const [errorService, seterrorService] = useState(false);
  const [errorEmptySensorType, seterrorEmptySensorType] = useState(false);
  const [errorApi, seterrorApi] = useState(false);
  */
  const [lastMustSave, setLastMustSave] = useState(mustSave);

  const { publish } = usePublisher();
  let i = 0;
  const states = {
    IDLE: i++,
    SENSOR_TYPES_LOADING: i++,
    SENSOR_TYPES_LOADED: i++,
    SENSOR_TYPES_ERROR: i++,
    API_WAITING: i++,
    API_ERROR: i++,
    TERMINAL_DEVICE_SENSOR_CREATE: i++,
    TERMINAL_DEVICE_SENSOR_CREATED: i++,
    TERMINAL_DEVICE_SENSOR_UPDATE: i++,
    TERMINAL_DEVICE_SENSOR_UPDATED: i++,
    TERMINAL_DEVICE_SENSOR_DELETE_AND_CREATE: i++,
    TERMINAL_DEVICE_SENSOR_DELETED_PENDING_CREATE: i++,
    TERMINAL_DEVICE_SENSOR_DELETED_AND_CREATED: i++,
    READY: i++,
  };
  const [stateMachine, setStateMachine] = useState(states.IDLE);
  const successCreateUpdateStates = [
    states.TERMINAL_DEVICE_SENSOR_CREATED,
    states.TERMINAL_DEVICE_SENSOR_DELETED_AND_CREATED,
    states.TERMINAL_DEVICE_SENSOR_UPDATED,
  ];
  const [dto, setDto] = useState({});

  const loadAnalogSensor = useCallback(
    (sensorIndex, showSave, source) => {
      //Muestra el siguiente desplegable
      setshowSensorsConfType(true);
      if (showSave) {
        showButtonSave(id);
      }
      if (source) {
        let tmpselectedSensor = source.filter(
          (s) =>
            s?.sensorIndex === sensorIndex &&
            s?.sensorId.physicalCommunicationType ===
              projectsConstants.global.sensors.phys.analog
        )[0];

        if (tmpselectedSensor) {
          // Existe configuracion previa del sensor.
          setselectedSensor(tmpselectedSensor);
          setselectedSensorStatus(tmpselectedSensor?.active);
          setselectedSensorTypeId(tmpselectedSensor?.sensorId?.id);
          setselectedSensorDescription(
            tmpselectedSensor?.sensorId?.description
          );
        } else {
          // No hay configuración del sensor.
          setselectedSensor({
            id: sensorIndex + 1,
            sensorIndex,
            active: false,
          });
          setselectedSensorStatus(undefined);
          setselectedSensorTypeId(undefined);
        }
      }
    },
    [showButtonSave, id]
  );

  const onAnalogSensorSelected = useCallback(
    (sensorIndex) => {
      setErrors((errors) => {
        return { ...errors, error: errorConstants.NONE };
      });
      loadAnalogSensor(sensorIndex, true, sensors);
    },
    [errorConstants.NONE, loadAnalogSensor, sensors]
  );

  const getAnalogSensors = useCallback(() => {
    const validForAnalogInputType = isTerminalDeviceValidForAnalogInputType(terminalDevice);
    let filtered = sensorTypes.filter(
      (stype) =>
        stype?.physicalCommunicationType ===
        projectsConstants.global.sensors.phys.analog
        && (validForAnalogInputType || stype?.analogInputType === "V")
    );
    return filtered;
  }, [sensorTypes, terminalDevice]);

  const ShowPopUpInfo = useCallback((type) => {
    setshowSensorsConfHelp(true);
  }, []);

  const closePopUP = useCallback(() => {
    setshowSensorsConfHelp(false);
  }, []);

  const onChangeActivation = useCallback(
    (status) => {
      if (isTerminalDeviceNotAbleToHandleMultipleAnalogInputType(terminalDevice) && status) {
        let otherSensorsWithDiffAnalogSensorType = sensors.filter(
          (sensor) =>
            sensor?.active 
            && sensor?.sensorId?.physicalCommunicationType ===
              projectsConstants.global.sensors.phys.analog 
              && sensor?.sensorId?.analogInputType !==
              selectedSensor?.sensorId?.analogInputType
              && sensor?.sensorIndex !== selectedSensor?.sensorIndex
        ).length;

        if (otherSensorsWithDiffAnalogSensorType > 0) {
          setErrors((errors) => {
            return { ...errors, error: errorConstants.DIFF_ANALOG_INPUT_TYPE };
          });
          return;
        }
      }

      if (selectedSensor) {
        selectedSensor.active = status;
        setselectedSensorStatus(status);
        showButtonSave(id);
      }
    },
    [errorConstants.DIFF_ANALOG_INPUT_TYPE, id, selectedSensor, sensors, showButtonSave, terminalDevice]
  );

  const onChangeSensorType = useCallback(
    (sensorTypeId) => {
      const prevSensorTypeId = selectedSensor?.sensorId?.id;
      let isNew = false;
      let isUpdate = false;
      if (prevSensorTypeId && !selectedSensor?.isNew) {
        // Hay un sensor anterior y no se ha marcado como que es nuevo.
        if (prevSensorTypeId !== sensorTypeId) {
          // Sensor disinto al actual
          isUpdate = true;
        }
      } else {
        // No hay sensor anterior.
        isNew = true;
      }
      // Recorremos los otros sensore para aseguraros que son del mismo analogInputType
      // En caso de existir un sensor activo con el analogInputType distinto no dejamos realizar el cambio.
      if (sensors instanceof Array) {
        // Buscamos el nuevo sensorTypeId
        let selectedSensorType = sensorTypes.filter(
          (st) => st?.id === sensorTypeId
        )[0];

        if (selectedSensorType) {
          let otherSensorsWithDiffAnalogSensorType = sensors
          .filter(
            (sensor) =>
              sensor?.active
              && sensor?.sensorId?.physicalCommunicationType ===
                projectsConstants.global.sensors.phys.analog 
              && sensor?.sensorId?.analogInputType !==
                selectedSensorType?.analogInputType
              && sensor?.sensorIndex !== selectedSensor?.sensorIndex
          ).length;

          if (isTerminalDeviceNotAbleToHandleMultipleAnalogInputType(terminalDevice) && otherSensorsWithDiffAnalogSensorType > 0) {
            // Hay otros sensores activos con otro analogInputType
            setErrors((errors) => {
              return {
                ...errors,
                error: errorConstants.DIFF_ANALOG_INPUT_TYPE,
              };
            });
          } else {
            setselectedSensor({
              ...selectedSensor,
              sensorId: selectedSensorType,
              isNew,
              isUpdate,
            });

            showButtonSave(id);
            setselectedSensorTypeId(sensorTypeId);
          }
        }
      }
    },
    [errorConstants.DIFF_ANALOG_INPUT_TYPE, id, selectedSensor, sensorTypes, sensors, showButtonSave, terminalDevice]
  );

  const onChangeReportInterval = useCallback(intervalInSeconds => {
    if (selectedSensor) {
      selectedSensor.reportIntervalInSeconds = intervalInSeconds;
      setSelectedReportInterval(intervalInSeconds);
      showButtonSave(id);
    }
  }, [id, selectedSensor, showButtonSave])

  const onTerminalDeviceSensorMeasurementChange = useCallback(measurement => {
    setselectedSensor({
      ...selectedSensor,
      measurement
    });

    if(isValidMeasurement(selectedSensor, measurement)){
      showButtonSave(id);
    }
  }, [id, selectedSensor, showButtonSave])

  const mutateState = useCallback(() => {
    if (terminalDevice?.sensors instanceof Array) {
      terminalDevice.sensors = _.cloneDeep(terminalDevice.sensors);
      setsensors(terminalDevice.sensors);
      if (selectedSensor) {
        loadAnalogSensor(selectedSensor.sensorIndex, false, terminalDevice.sensors);
      }
    }
  }, [loadAnalogSensor, selectedSensor, terminalDevice])

  const onTerminalDeviceCalibrationRegisterDone = useCallback(() => {
    mutateState()
  }, [mutateState])

  const createdTerminalDeviceSensor = useCallback(
    (terminalDeviceSensor) => {
      setErrors((errors) => {
        return { ...errors, error: errorConstants.NONE };
      });
      setwaitingApiResponse(false);
      parentUpdateTerminalDeviceSensor(terminalDeviceSensor);
      if (sensors) {
        let tmpSensors = _.cloneDeep(sensors);
        // Eliminamos si hay alguno con mismo index.
        let prevTerminalDeviceSensor = tmpSensors.filter(
          (tdSensor) =>
            tdSensor?.sensorIndex === terminalDeviceSensor.sensorIndex &&
            tdSensor?.sensorId.physicalCommunicationType ===
              projectsConstants.global.sensors.phys.analog
        )[0];

        if (prevTerminalDeviceSensor) {
          tmpSensors[
            tmpSensors.indexOf(prevTerminalDeviceSensor)
          ] = terminalDeviceSensor;
        } else {
          tmpSensors.push(terminalDeviceSensor);
        }
        setsensors(tmpSensors);
        setselectedSensor(terminalDeviceSensor);
      }

      switch (stateMachine) {
        case states.TERMINAL_DEVICE_SENSOR_CREATE:
          setStateMachine(states.TERMINAL_DEVICE_SENSOR_CREATED);
          break;
        case states.TERMINAL_DEVICE_SENSOR_DELETED_PENDING_CREATE:
          setStateMachine(states.TERMINAL_DEVICE_SENSOR_DELETED_AND_CREATED);
          break;
        default:
          break;
      }
    },
    [
      parentUpdateTerminalDeviceSensor,
      sensors,
      stateMachine,
      errorConstants.NONE,
      states.TERMINAL_DEVICE_SENSOR_CREATE,
      states.TERMINAL_DEVICE_SENSOR_CREATED,
      states.TERMINAL_DEVICE_SENSOR_DELETED_PENDING_CREATE,
      states.TERMINAL_DEVICE_SENSOR_DELETED_AND_CREATED,
    ]
  );

  const createTerminalDeviceSensor = useCallback(
    (sensorIndex, dto) => {
      sensorService
        .createTerminalDeviceSensor(
          terminalDevice.terminal,
          terminalDevice.id,
          selectedSensorTypeId,
          sensorIndex,
          dto
        )
        .then(
          createdTerminalDeviceSensor,
          () => {},
          (error) => {
            setErrors((errors) => {
              return { ...errors, error: errorConstants.API };
            });
            setwaitingApiResponse(false);
            setStateMachine(states.API_ERROR);
          }
        );
    },
    [
      createdTerminalDeviceSensor,
      errorConstants.API,
      selectedSensorTypeId,
      states.API_ERROR,
      terminalDevice.id,
      terminalDevice.terminal,
    ]
  );

  const updateTerminalDeviceSensor = useCallback(
    (sensorIndex, dto) => {
      infoLoadingServer(buttonAction.start, type, dto);
      sensorService
        .updateTerminalDeviceSensor(
          terminalDevice.terminal,
          terminalDevice.id,
          selectedSensorTypeId,
          sensorIndex,
          dto
        )
        .then(
          (terminalDeviceSensorUpdated) => {
            infoLoadingServer(buttonAction.finalized, type);
            setErrors((errors) => {
              return { ...errors, error: errorConstants.NONE };
            });
            setwaitingApiResponse(false);
            parentUpdateTerminalDeviceSensor(terminalDeviceSensorUpdated);
            setStateMachine(states.TERMINAL_DEVICE_SENSOR_UPDATED);
          },
          (error) => {
            infoLoadingServer(buttonAction.error, type);
            setErrors((errors) => {
              return { ...errors, error: errorConstants.API };
            });
            setwaitingApiResponse(false);
            setStateMachine(states.API_ERROR);
          }
        );
    },
    [
      infoLoadingServer,
      buttonAction.start,
      buttonAction.finalized,
      buttonAction.error,
      type,
      terminalDevice.terminal,
      terminalDevice.id,
      selectedSensorTypeId,
      parentUpdateTerminalDeviceSensor,
      states.TERMINAL_DEVICE_SENSOR_UPDATED,
      states.API_ERROR,
      errorConstants.NONE,
      errorConstants.API,
    ]
  );

  const actionOnSaveAnalogSensor = useCallback(() => {
    let sensorType = undefined;
    if (sensorTypes) {
      for (let i = 0; i < sensorTypes.length; i++) {
        if (sensorTypes[i].id === selectedSensorTypeId) {
          sensorType = sensorTypes[i];
          break;
        }
      }
      if (sensorType) {
        setErrors((errors) => {
          return { ...errors, error: errorConstants.NONE };
        });
      } else {
        setErrors((errors) => {
          return { ...errors, error: errorConstants.EMPTY_SENSOR_TYPE };
        });
      }
    } else {
      setErrors((errors) => {
        return { ...errors, error: errorConstants.EMPTY_SENSOR_TYPE };
      });
    }

    //const prevSensorTypeId = selectedSensor?.sensorId?.id;
    const { sensorIndex, measurement } = selectedSensor;
    // Creamos la nueva configuración.
    const dto = {
      sensorIndex,
      active: selectedSensorStatus || false,
      reportIntervalInSeconds: selectedReportInterval || 300
    };

    if(isValidMeasurement(selectedSensor, measurement)) {
      dto.measurement = measurement
    }

    setDto(dto);
    if (selectedSensor?.isNew || selectedSensor?.isUpdate) {
      // NUEVO
      // Debemos controlar si eliminar la anterior configuración.
      if (selectedSensor?.isUpdate) {
        // Hay configuración previa. Debemos eliminarla.
        setStateMachine(states.TERMINAL_DEVICE_SENSOR_DELETE_AND_CREATE);
      } else {
        setStateMachine(states.TERMINAL_DEVICE_SENSOR_CREATE);
      }
    } else {
      // ACTUALIZAR
      setStateMachine(states.TERMINAL_DEVICE_SENSOR_UPDATE);
    }
  }, [sensorTypes, selectedSensor, selectedSensorStatus, selectedReportInterval, selectedSensorTypeId, errorConstants.NONE, errorConstants.EMPTY_SENSOR_TYPE, states.TERMINAL_DEVICE_SENSOR_DELETE_AND_CREATE, states.TERMINAL_DEVICE_SENSOR_CREATE, states.TERMINAL_DEVICE_SENSOR_UPDATE]);

  const onSensorTriggerChanged = useCallback(() => {
    mutateState()
  }, [mutateState]);

  const canChangeSensorType = useCallback(() => {
    return (selectedSensor?.triggers instanceof Array && selectedSensor.triggers.filter(trigger => !trigger.deletedConfirmationByDevice).length === 0)
      || !selectedSensor?.triggers
  }, [selectedSensor])

  //#region  useEffect

  useEffect(() => {
    if (lastMustSave !== mustSave && mustSave > 0) {
      actionOnSaveAnalogSensor();
      setLastMustSave(mustSave);
    }
  }, [mustSave]);

  useEffect(() => {
    if (terminalDevice && terminalDevice.sensors && Array.isArray(terminalDevice.sensors)) {
      setsensors(terminalDevice.sensors);
      if (terminalDevice.sensors.length > 0) {
        loadAnalogSensor(0, false, terminalDevice.sensors);
      }
    }
  }, [terminalDevice?.sensors]);

  useEffect(() => {
    if (stateMachine === states.IDLE && !sensorTypesLoading) {
      setsensorTypesLoading(true);
      setStateMachine(states.SENSOR_TYPES_LOADING);
      sensorService.getSensorsTypes().then(
        (sTypes) => {
          setsensorTypes(sTypes);
          setErrors((errors) => {
            return { ...errors, error: errorConstants.NONE };
          });
          setsensorTypesLoaded(true);
          setStateMachine(states.SENSOR_TYPES_LOADED);
        },
        (error) => {
          setErrors((errors) => {
            return { ...errors, error: errorConstants.SERVICE };
          });
          setsensorTypesLoaded(false);
          setStateMachine(states.SENSOR_TYPES_ERROR);
        }
      );
    }
  }, [props]);

  useEffect(() => {
    // Cargamos los tipos de sensores.
    switch (stateMachine) {
      case states.TERMINAL_DEVICE_SENSOR_DELETED_PENDING_CREATE:
      case states.TERMINAL_DEVICE_SENSOR_CREATE:
        setwaitingApiResponse(true);
        createTerminalDeviceSensor(selectedSensor?.sensorIndex, dto);
        setStateMachine(states.READY);
        break;
      case states.TERMINAL_DEVICE_SENSOR_UPDATE:
        updateTerminalDeviceSensor(selectedSensor?.sensorIndex, dto);
        setStateMachine(states.READY);
        break;
      case states.TERMINAL_DEVICE_SENSOR_DELETE_AND_CREATE:
        //deleteTerminalDeviceSensor(selectedSensor?.sensorIndex);
        setStateMachine(states.TERMINAL_DEVICE_SENSOR_CREATE);
        //setStateMachine(states.READY);
        break;
      case states.TERMINAL_DEVICE_SENSOR_CREATED:
      case states.TERMINAL_DEVICE_SENSOR_DELETED_AND_CREATED:
      case states.TERMINAL_DEVICE_SENSOR_UPDATED:
        onAnalogSensorSelected(selectedSensor.sensorIndex);
        onSuccessSaving(id);
        publish(projectsConstants.master_outputs.actions.common.sensorsconfig, {
          id: uuid(),
          type: MESSAGE_TYPE_ACTION,
          data: {
            target_id: terminalDevice?.id,
          },
        });
        break;
      case states.API_ERROR:
        onErrorSaving(id);
        break;
      default:
        break;
    }
  }, [stateMachine]);

  //#endregion

  const getTerminalDeviceSensorCalibrationRegister = useCallback(
    (terminalDeviceSensor) => {
      if (terminalDeviceSensor?.sensorId?.measurements instanceof Array) {
        return terminalDeviceSensor.sensorId.measurements.map(
          (measurement, index) => {
            switch (measurement?.measurementUnit) {
              case projectsConstants.global.sensors.units.pH.badge:
                return (
                  <div key={index}>
                    <TerminalDeviceSensorCalibrationInfo
                      terminalDevice={terminalDevice}
                      terminalDeviceSensor={terminalDeviceSensor}
                    ></TerminalDeviceSensorCalibrationInfo>
                    <TerminalDeviceSensorCalibrationRegister
                      terminalDevice={terminalDevice}
                      terminalDeviceSensor={terminalDeviceSensor}
                      points={[4.0, 7.0, 10.0]}
                      unit={measurement.measurementUnit}
                      inputUnit={"mV"}
                      onSaved={onTerminalDeviceCalibrationRegisterDone}
                    ></TerminalDeviceSensorCalibrationRegister>
                  </div>
                );
                case projectsConstants.global.sensors.units["uS/cm"].badge:
                  return (
                    <div key={index}>
                      <TerminalDeviceSensorCalibrationInfo
                        terminalDevice={terminalDevice}
                        terminalDeviceSensor={terminalDeviceSensor}
                        inputUnit={"uS/cm"}
                      ></TerminalDeviceSensorCalibrationInfo>
                      <TerminalDeviceSensorCalibrationRegister
                        terminalDevice={terminalDevice}
                        terminalDeviceSensor={terminalDeviceSensor}
                        points={[TERMINAL_DEVICE_SENSOR_CALIBRATION_OPEN_POINT]}
                        unit={measurement.measurementUnit}
                        inputUnit={"uS/cm"}
                        onSaved={onTerminalDeviceCalibrationRegisterDone}
                      ></TerminalDeviceSensorCalibrationRegister>
                    </div>
                  );
                case projectsConstants.global.sensors.units.mV.badge:
                  return (
                    <div key={index}>
                      <TerminalDeviceSensorCalibrationInfo
                        terminalDevice={terminalDevice}
                        terminalDeviceSensor={terminalDeviceSensor}
                      ></TerminalDeviceSensorCalibrationInfo>
                      <TerminalDeviceSensorCalibrationRegister
                        terminalDevice={terminalDevice}
                        terminalDeviceSensor={terminalDeviceSensor}
                        points={[86, 256]} 
                        unit={measurement.measurementUnit}
                        inputUnit={"mV"}
                        onSaved={onTerminalDeviceCalibrationRegisterDone}
                      ></TerminalDeviceSensorCalibrationRegister>
                    </div>
                  );
              default:
                return <div key={index}></div>;
            }
          }
        );
      }
      return <></>;
    },
    [onTerminalDeviceCalibrationRegisterDone, terminalDevice]
  );

  const analogSensorsConfigBlock = <div className="configurationSensors">
  <div className="Titlewithinfo">
    <div className="Title_DescripctionConf ">Sensor analógico:</div>
    <div className="icoOutputconf">
      <div className="icoOutputconf2">
        <QuestionSvg
          className="ButtonProgram Info"
          onClick={(e) => ShowPopUpInfo("InfoTarjetas")}
        />
      </div>
    </div>
  </div>
  {errors.error === errorConstants.SERVICE && !sensorTypesLoaded && (
    <DangerComponent
      message={"Error recuperando los tipos de sensores."}
    ></DangerComponent>
  )}
  {/*   Error*/}

  {errors.error !== errorConstants.SERVICE  && (
    <TerminalDeviceSensorsDropDown
      sensors={sensors}
      maxAnalogInputs={maxAnalogInputs}
      disabled={waitingApiResponse}
      onSelected={onAnalogSensorSelected}
      selectedSensor={selectedSensor}
    />
  )}
  {errors.error === errorConstants.EMPTY_SENSOR_TYPE && (
    <DangerComponent
      message={"Seleccione un tipo de sensor."}
    ></DangerComponent>
  )}

  {errors.error === errorConstants.DIFF_ANALOG_INPUT_TYPE && (
    <DangerComponent
      message={
        "Este tipo de sensor no es compatible con los ya configurados."
      }
    ></DangerComponent>
  )}

  {showSensorsConfType && (
    <>
      <div className="TabOutputConf"></div>
      <SensorConfiguration
        sensor={selectedSensor}
        sensorTypes={getAnalogSensors()}
        onChangeActivation={onChangeActivation}
        onChangeSensorType={onChangeSensorType}
        onChangeReportInterval={onChangeReportInterval}
        onTerminalDeviceSensorMeasurementChange={onTerminalDeviceSensorMeasurementChange}
        selectedSensorDescription={selectedSensorDescription}
        sensorTypeSelectorDisabled={!canChangeSensorType()}
        sensorTypeSelectorDisabledInfoComponent={<p className="text-danger">Si dispone de consignas no se puede modificar el tipo del sensor.</p>}
      />
      {selectedSensor?.active && !selectedSensor.isUpdate && selectedSensor?.notified &&
        getTerminalDeviceSensorCalibrationRegister(selectedSensor)}
      {selectedSensor?.active && !selectedSensor.isUpdate && selectedSensor?.notified && (
        <TerminalDeviceSensorStats
          sensor={selectedSensor}
        ></TerminalDeviceSensorStats>
      )}
      {selectedSensor?.active && !selectedSensor?.isNew && !selectedSensor.isUpdate && selectedSensor?.notified && isTerminalDeviceValidForTriggers(terminalDevice) && (
        <>

          {
          // Deshabilitamos para SIO, no testeado en el dispositivo 
          }
          {terminalDevice?.device?.id !== projectsConstants.global.devices.SIO && <TerminalDeviceSensorTriggerConfigurator
            key={"consignas"}
            terminalDevice={terminalDevice}
            selectedSensor={selectedSensor}
            terminalDevices={terminalDevices}
            onSensorsChanged={onSensorTriggerChanged}
            allowAdditionalActions={false}
            allowOutputFromAnyDeviceOnTerminal={terminalDevice?.device?.id === projectsConstants.global.devices.MMS || terminalDevice?.device?.id === projectsConstants.global.devices.MMS_X5}
          ></TerminalDeviceSensorTriggerConfigurator>
          }
          {isTerminalDeviceValidForReportSensorValues(terminalDevice) && 
          <TerminalDeviceSensorTriggerConfigurator
            key={"alarmas"}
            terminalDevice={terminalDevice}
            selectedSensor={selectedSensor}
            terminalDevices={terminalDevices}
            onSensorsChanged={onSensorTriggerChanged}
            triggerConfigurationType={TriggerConfigurationTypes.SENSOR_ALARM}
            title={"alarma"}
            greaterOperator={TriggerConfigurationOperators.simple_greater_than}
            smallerOperator={TriggerConfigurationOperators.simple_smaller_than}
            allowAdditionalActions={true}
            allowOutputFromAnyDeviceOnTerminal={terminalDevice?.device?.id === projectsConstants.global.devices.MMS || terminalDevice?.device?.id === projectsConstants.global.devices.MMS_X5}
          ></TerminalDeviceSensorTriggerConfigurator>
          }
        </>
      )}
    </>
  )}
</div>

  return (
    <div className="Configuration">
      {errors.error === errorConstants.API && (
        <DangerComponent
          message={"Error guardando la configuración. Vuelva a intentarlo."}
        ></DangerComponent>
      )}

      {successCreateUpdateStates.includes(stateMachine) && (
        <CheckComponent
          message={"Se han guardado los datos correctamente."}
        ></CheckComponent>
      )}

      <PopUpC
        activate={showSensorsConfHelp}
        deactivatepopup={closePopUP}
        content={<Sensorsconfhelp />}
      />

        <TwoOptionsBox 
          optionOneContent = {analogSensorsConfigBlock}
          //optionTwoContent = {<SensorsRS485Configuration showButtonSave={() => {showButtonSave(id);}} onErrorSaving={onErrorSaving} onSuccessSaving={onSuccessSaving} />}
          optionOneTitle = {<>Analógicos</>}
          optionTwoTitle = {<>Otros</>}
          optionSelected = {twoOptionsIds.one}
        />
    </div>
  );
};
