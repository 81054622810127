import React, { useState,useCallback } from "react";
import Datapickersass from "./Datapicker.sass";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
registerLocale("es", es);

//react-datepicker-popper esta clase tiene la propiedad transform para hacer
//un translate3d y decidir si queremos que apezca arriba, abajo etc.. del Input
export const DataPicker = (props) => {
  const {className}=props;
  const [startDate, setStartDate] = useState(props.defaultValue || new Date());
  const {id,getDataPicker}= props;
  const SelectDay = useCallback((day) => {
   console.table("Seleccionado día: " + day );
   setStartDate(day);
   getDataPicker(id,day)
  });
  return (
    <>
      {" "}
      <DatePicker
      className={className}
        selected={startDate}
        onChange={(date) => SelectDay(date)}
        //showTimeSelect
        dateFormat="Pp"
        locale="es"
      />
    </>
  );
};
