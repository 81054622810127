import React, { useEffect, useState, useCallback, useMemo } from "react";
import { terminalService } from "./../../../_services/terminal.service";
import { Terminal } from "./Terminal";
import { Spinner } from "./../../components/Airframe/Spinner/Spinner";
import { useApp } from "../../../context/app-context";
import { useNavigate } from "react-router-dom";
import { TerminalOnlineChecker } from "./TerminalOnlineChecker";
import { DangerComponent } from "../../DangerComponent/DangerComponent";

export const Terminalsfather = (props) => {
  const { zoneId, sectorId } = props;
  const { selectedSector, setTerminals, terminals } = useApp();
  const history = useNavigate();

  const states = {
    IDLE: "IDLE",
    LOADING: "LOADING",
    LOADED: "LOADED",
    ERROR: "ERROR",
  };
  const initState = useMemo(() => ({ state: states.IDLE }), [states.IDLE]);
  const [state, setState] = useState(initState);

  const Return = useCallback(() => {
    history(`/zones/${zoneId}/`);
  }, [history, zoneId]);

  useEffect(() => {
    if (state.state === states.IDLE) {
      setState({ ...state, state: states.LOADING });
      terminalService.getTerminalsofSectorInZoneAll(zoneId, sectorId).then(
        (tmpTerminals) => {
          setState({ ...state, state: states.LOADED });
          setTerminals(tmpTerminals);
        },
        (error) => {
          setState({ ...state, state: states.ERROR });
        }
      );
    }
  }, [
    sectorId,
    setTerminals,
    state,
    states.ERROR,
    states.IDLE,
    states.LOADED,
    states.LOADING,
    zoneId,
  ]);

  const getTerminalsComponent = useCallback(() => {
    if (terminals?.length > 0) {
      return terminals.map((terminal, index) => (
        <Terminal
          key={terminal?.id || index}
          ColorLed="rgb(27,185,52)"
          zoneId={zoneId}
          sectorId={sectorId}
          terminal={terminal}
        />
      ));
    } else {
      return (
        <DangerComponent
          message={"No tienes asociado ningún terminal."}
        ></DangerComponent>
      );
    }
  }, [sectorId, zoneId, terminals]);

  return (
    <>
      {(state.state === states.LOADING || state.state === states.IDLE) && (
        <div className="LoadSpinner heightspinner">
          <Spinner size="50px"></Spinner>
        </div>
      )}
      {state.state === states.LOADED && (
        <>
          <TerminalOnlineChecker />
          <div className="ZonesDiv">
            <div
              className="ContainerZones"
              key={`container-${zoneId}-${sectorId}`}
            >
              <div className="titleApp">{selectedSector?.name}</div>
              <div className="ButtonUI" onClick={(e) => Return()}>
                <div className="fa fa-fw fa-mail-reply"></div>
                <div className="<">
                  <div className="SubtitleApp">Sectores</div>
                </div>
              </div>
              <div className="ZonesDiv">{getTerminalsComponent()}</div>
            </div>
          </div>
        </>
      )}
      {state.state === states.ERROR && (
        <DangerComponent message="Error cargando los terminales."></DangerComponent>
      )}
    </>
  );
};
