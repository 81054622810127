import React from "react";
import { projectsConstants } from "../../../../_constants/projects.constants";
import { MqttMessageProcessor } from "../MqttMessageProcessor";

export const DeleteTriggersMQTT = (props) => {
    return (
        <MqttMessageProcessor
          topic={projectsConstants.master_outputs.actions.common.deletetriggers}
          processMsg={props.processMsg}
          processErrorMsg={props.processErrorMsg}
        ></MqttMessageProcessor>
      );
}