import { authHeader } from "../_helpers";
import axios from "axios";
import { Authservice } from "./Authservice";
import { TerminalDeviceSensor } from "../classes/TerminalDevice/Sensor/TerminalDeviceSensor";

export const sensorRegistersType = {
  MAIN: "MAIN",
  DAY_ACCUMULATED: "DAY_ACCUMULATED",
  WEEK_ACCUMULATED: "WEEK_ACCUMULATED",
  MONTH_ACCUMULATED: "MONTH_ACCUMULATED",
  YEAR_ACCUMULATED: "YEAR_ACCUMULATED",
  TOTAL_ACCUMULATED: "TOTAL_ACCUMULATED",

  DAY_AVERAGE: "DAY_AVERAGE",
  WEEK_AVERAGE: "WEEK_AVERAGE",
  MONTH_AVERAGE: "MONTH_AVERAGE",
  YEAR_AVERAGE: "YEAR_AVERAGE",
  TOTAL_AVERAGE: "TOTAL_AVERAGE"
}

export const sensorService = {
  getSensorsTypes,
  createTerminalDeviceSensor,
  deleteTerminalDeviceSensor,
  updateTerminalDeviceSensor,
  postSensorTRigger,
  putSensorTRigger,
  deleteSensorTrigger,
  getTerminalDeviceSensors,
  getTerminalDeviceSensor,
  getTerminalDeviceSensorRegisters,
  getTerminalDeviceSensorRegistersByPeriod,
  getLastTerminalDeviceSensorCalibration,
  saveTerminalDeviceSensorCalibration,
  getLastsTerminalDeviceSensorCalibrationsCSV
};


function deleteSensorTrigger(
  terminalId,
  terminalDeviceId,
  sensorId,
  sensorIndex,
  triggerId,
  
) {

  const authToken = authHeader();
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization,
    },
  };

  return axios
    .delete(
      `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/sensors/${sensorId}/index/${sensorIndex}/trigger/${triggerId}`,
      requestOptions
    )
    .then(Authservice.handleResponse)
    .then((triggerSensorDelete) => {
      return triggerSensorDelete;
    });
}

function getSensorsTypes() {
  const authToken = authHeader();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization,
    },
  };

  return axios
    .get(`${process.env.REACT_APP_API_PATH}/sensors/`, requestOptions)
    .then(Authservice.handleResponse)
    .then((sensorsTypes) => {
      return sensorsTypes;
    });
}

function getTerminalDeviceSensor(
  terminalId,
  terminalDeviceId,
  sensorId,
  sensorIndex
) {
  const authToken = authHeader();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization,
    },
  };

  return axios
    .get(`${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/sensors/${sensorId}/index/${sensorIndex}`, requestOptions)
    .then(Authservice.handleResponse)
    .then((sensorsTypes) => {
      return sensorsTypes;
    });
}

function getTerminalDeviceSensors(terminalId,
  terminalDeviceId) {
  const authToken = authHeader();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization,
    },
  };

  return axios
    .get(`${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/sensors/`, requestOptions)
    .then(Authservice.handleResponse)
    .then((sensorsTypes) => {
      return sensorsTypes;
    });
}

function postSensorTRigger(
  terminalId,
  terminalDeviceId,
  sensorId,
  sensorIndex,
  data
) {
  const authToken = authHeader();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization,
    },
  };

  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/sensors/${sensorId}/index/${sensorIndex}/trigger/`,
      data,
      requestOptions
    )
    .then(Authservice.handleResponse)
    .then((terminalDeviceTriggers) => {
      return terminalDeviceTriggers;
    });
}


function putSensorTRigger(
  terminalId,
  terminalDeviceId,
  sensorId,
  sensorIndex,
  triggerId,
  data
) {
  const authToken = authHeader();
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization,
    },
  };

  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/sensors/${sensorId}/index/${sensorIndex}/trigger/${triggerId}`,
      data,
      requestOptions
    )
    .then(Authservice.handleResponse)
    .then((terminalDeviceTriggers) => {
      return terminalDeviceTriggers;
    });
}



/**
 * Crea una relacion entre una entrada analogica de un sensor y un tipo de sensor.
 * @param {*} terminalId
 * @param {*} terminalDeviceId
 * @param {*} sensorId
 * @param {*} sensorIndex
 * @param {*} sensorCreateDto {"sensorIndex": 0,  "active": true }
 */
function createTerminalDeviceSensor(
  terminalId,
  terminalDeviceId,
  sensorId,
  sensorIndex,
  sensorCreateDto
) {
  const authToken = authHeader();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization,
    },
  };

  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/sensors/${sensorId}/index/${sensorIndex}`,
      sensorCreateDto,
      requestOptions
    )
    .then(Authservice.handleResponse)
    .then((terminalDeviceSensor) => {
      return parseTerminalDeviceSensor(terminalDeviceSensor);
    });
}

function deleteTerminalDeviceSensor(terminalId, terminalDeviceId, sensorIndex) {
  const authToken = authHeader();
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization,
    },
  };

  return axios
    .delete(
      `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/sensors/index/${sensorIndex}`,
      requestOptions
    )
    .then(Authservice.handleResponse)
    .then((terminalDeviceSensorDeleted) => {
      return parseTerminalDeviceSensor(terminalDeviceSensorDeleted);
    });
}

/**
 *
 * @param {*} terminalId
 * @param {*} terminalDeviceId
 * @param {*} sensorId
 * @param {*} sensorIndex
 * @param {*} sensorUpdateDto {"sensorIndex": 0,  "active": true }
 */
function updateTerminalDeviceSensor(
  terminalId,
  terminalDeviceId,
  sensorId,
  sensorIndex,
  sensorUpdateDto
) {
  const authToken = authHeader();
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization,
    },
  };

  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/sensors/${sensorId}/index/${sensorIndex}`,
      sensorUpdateDto,
      requestOptions
    )
    .then(Authservice.handleResponse)
    .then((terminalDeviceSensor) => {
      return parseTerminalDeviceSensor(terminalDeviceSensor);
    });
}

function getTerminalDeviceSensorRegisters(
  terminalId,
  terminalDeviceId,
  sensorId,
  sensorIndex
) {
  const authToken = authHeader();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization,
    },
  };

  return axios
    .get(`${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/sensors/${sensorId}/index/${sensorIndex}/registers`, requestOptions)
    .then(Authservice.handleResponse)
    .then((registers) => registers);
}

/**
 * 
 * @param {*} terminalId 
 * @param {*} terminalDeviceId 
 * @param {*} sensorId 
 * @param {*} sensorIndex 
 * @param {*} day (yyyy-MM-dd)
 * @returns 
 */
function getTerminalDeviceSensorRegistersByPeriod(
  terminalId,
  terminalDeviceId,
  sensorId,
  sensorIndex,
  fromDate,
  toDate,
  registerType
) {
  const authToken = authHeader();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization,
    },
  };

  return axios
    .get(`${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/sensors/${sensorId}/index/${sensorIndex}/registers/day?day=${encodeURIComponent(fromDate)}${toDate ? `&dayEnd=${toDate}` : ""}&registerType=${encodeURIComponent(registerType) || sensorRegistersType.MAIN}`, requestOptions)
    .then(Authservice.handleResponse)
    .then((registers) => registers);
}

function getLastTerminalDeviceSensorCalibration(
  terminalId,
  terminalDeviceId,
  sensorId,
  sensorIndex) {
    const authToken = authHeader();
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken.Authorization,
      },
    };
  
    return axios
      .get(`${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/sensors/${sensorId}/index/${sensorIndex}/calibrations/last`, requestOptions)
      .then(Authservice.handleResponse)
      .then((calibration) => calibration);
}

function saveTerminalDeviceSensorCalibration(
  terminalId,
  terminalDeviceId,
  sensorId,
  sensorIndex,
  calibration) {
    const authToken = authHeader();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken.Authorization,
      },
    };
  
    return axios
      .post(`${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/sensors/${sensorId}/index/${sensorIndex}/calibrations/`,
      calibration,
      requestOptions)
      .then(Authservice.handleResponse)
      .then((calibration) => calibration);
}

function getLastsTerminalDeviceSensorCalibrationsCSV(
  terminalId,
  terminalDeviceId,
  sensorId,
  sensorIndex,
  filename = `${terminalDeviceId}-${sensorId}-${sensorIndex}`,
  page = 0,
  size = 200) {
    const authToken = authHeader();
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken.Authorization,
      },
    };
  
    return axios
      .get(`${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/sensors/${sensorId}/index/${sensorIndex}/calibrations/page/csv`, requestOptions)
      .then(response => {
        // Aquí puedes hacer lo que necesites con el archivo descargado, por ejemplo, guardarlo en el disco.
        // En este ejemplo lo mostramos en una ventana nueva
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = `${filename}.csv`;
        document.body.appendChild(a);
        a.click();        
      })
      .catch(error => console.error('Error al descargar archivo', error));
}

function parseTerminalDeviceSensor(terminalDeviceSensor) {
  return TerminalDeviceSensor.parseFromObjectDto(terminalDeviceSensor);
}
