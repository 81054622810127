import { projectsConstants } from "../../../../../_constants/projects.constants";
import { isTerminalDeviceMMSValidFor, isTerminalDeviceSIOValidFor } from "../../VersionChecker";

const isTerminalDeviceMMSValidForAnalogInputType = (terminalDevice) => isTerminalDeviceMMSValidFor(
  terminalDevice,
  projectsConstants.master_outputs.versions.MMS_HW1_MIN_MA_ANALOG_INPUT_TYPE,
  projectsConstants.master_outputs.versions.MMS_HW3_MIN_MA_ANALOG_INPUT_TYPE
)


const isTerminalDeviceSIOValidForAnalogInputType = (terminalDevice) => isTerminalDeviceSIOValidFor(
  terminalDevice,
  projectsConstants.master_outputs.versions.SIO_HW1_MIN_MA_ANALOG_INPUT_TYPE,
  projectsConstants.master_outputs.versions.SIO_HW3_MIN_MA_ANALOG_INPUT_TYPE
)

export const isTerminalDeviceValidForAnalogInputType = terminalDevice => 
  isTerminalDeviceMMSValidForAnalogInputType(terminalDevice) ||
  isTerminalDeviceSIOValidForAnalogInputType(terminalDevice)





const isTerminalDeviceMMSAbleToHandleMultipleAnalogInputType = (terminalDevice) => isTerminalDeviceMMSValidFor(
  terminalDevice,
  projectsConstants.master_outputs.versions.MMS_HW1_MIN_MA_ANALOG_INPUT_TYPE,
  9999999
)


const isTerminalDeviceSIOAbleToHandleMultipleAnalogInputType = (terminalDevice) => isTerminalDeviceSIOValidFor(
  terminalDevice,
  projectsConstants.master_outputs.versions.SIO_HW1_MIN_MA_ANALOG_INPUT_TYPE,
  9999999
)

export const isTerminalDeviceAbleToHandleMultipleAnalogInputType = terminalDevice => 
  isTerminalDeviceMMSAbleToHandleMultipleAnalogInputType(terminalDevice) ||
  isTerminalDeviceSIOAbleToHandleMultipleAnalogInputType(terminalDevice)

export const isTerminalDeviceNotAbleToHandleMultipleAnalogInputType = terminalDevice =>
  !isTerminalDeviceAbleToHandleMultipleAnalogInputType(terminalDevice)