import { Device } from "../Device/Device";
import { Output } from "./Output/Output";
import { TerminalDeviceSensor } from "./Sensor/TerminalDeviceSensor";

export class TerminalDevice {
    id;
	device;
	terminal;
    nserie;
	status;
	lastStatus;
	outputs;
	sensors;
	outputsVoltage;
	outputsVoltageExpected;
	description;
	inMaintenance;
    inMaintenanceExpected;
    triggerWeeklyActivationGracePeriod;
    triggerWeeklyChecks;

    

    static parseFromPlainDto(dtoText){
        const plainObject = JSON.parse(dtoText);
        return TerminalDevice.parseOutputDtoFromObject(plainObject);
    }

    static parseFromObjectDto(dtoObject){
        if(dtoObject){
            const terminalDevice = Object.setPrototypeOf(dtoObject, TerminalDevice.prototype);

            if(dtoObject.outputs instanceof Array){
                terminalDevice.outputs = dtoObject.outputs.map(output => Output.parseOutputDtoFromObject(output));
            }else{
                terminalDevice.outputs = [];
            }

            if(dtoObject.device instanceof Object ){
                terminalDevice.device = Device.parseFromObjectDto(terminalDevice.device);
            }

            if(dtoObject.sensors instanceof Object){
                terminalDevice.sensors = dtoObject.sensors.map(sensor => TerminalDeviceSensor.parseFromObjectDto(sensor));
            }else{
                terminalDevice.sensors = [];
            }

            return terminalDevice;
        }
        return dtoObject
    }

    isInMaintenance() {
        return this.inMaintenance;
    }
    setInMaintenance(inMaintenance){
        this.inMaintenance = inMaintenance;
    }

    isMaintenanceExpected() {
        return this.inMaintenanceExpected !== undefined && this.inMaintenanceExpected !== null ? this.inMaintenanceExpected : this.inMaintenance;
    }
    setMaintenanceExpected(inMaintenanceExpected){
        this.inMaintenanceExpected = inMaintenanceExpected;
    }

    isInMaintenanceSync() {
        return this.isInMaintenance() === this.isMaintenanceExpected();
    }
}