import React, { useCallback, useMemo } from "react";
import axios from "axios";
import { Authservice } from "./Authservice";
import { TerminalDeviceProgram } from "../classes/TerminalDevice/Program/TerminalDeviceProgram";
import { TerminalDeviceSubprogram } from "../classes/TerminalDevice/Program/Subprogram/TerminalDeviceSubprogram";

const ProgramsContext = React.createContext();

export const ProgramsProvider = (props) => {
  const getAll = useCallback((terminalId, terminalDeviceId) => {
    return axios
      .get(
        `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/programs/`,
        Authservice.getRequestOptions("GET")
      )
      .then(Authservice.handleResponse)
      .then((programs) => {
        return parsePrograms(programs);
      });
  }, []);

  const getOne = useCallback((terminalId, terminalDeviceId, programId) => {
    return axios
      .get(
        `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/programs/${programId}`,
        Authservice.getRequestOptions("GET")
      )
      .then(Authservice.handleResponse)
      .then((program) => {
        return parseProgram(program);
      });
  }, []);

  /**
   * {
  "name": "string",
  "triggerType": "TIME",
  "startHour": "string",
  "days": 0,
  "subprograms": [
    {
      "activeTime": "string",
      "outputs": 0,
      "index": 0,
      "initDelay": 0,
      "endDelay": 0
    }
  ],
  "active": true,
  "subprogramsDelay": 0
}
   */
  const save = useCallback((terminalId, terminalDeviceId, program) => {
    return axios
      .post(
        `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/programs/`,
        program,
        Authservice.getRequestOptions("POST")
      )
      .then(Authservice.handleResponse)
      .then((program) => {
        return parseProgram(program);
      });
  }, []);

  const deleteProgram = useCallback(
    (terminalId, terminalDeviceId, programId) => {
      return axios
        .delete(
          `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/programs/${programId}`,
          Authservice.getRequestOptions("DELETE")
        )
        .then(Authservice.handleResponse)
        .then((program) => {
          return parseProgram(program);
        });
    },
    []
  );

  /**
   * {
  "name": "string",
  "triggerType": "TIME",
  "startHour": "string",
  "days": 0,
  "subprograms": [
    {
      "id": 0,
      "activeTime": "string",
      "outputs": 0,
      "index": 0,
      "initDelay": 0,
      "endDelay": 0
    }
  ],
  "active": true,
  "subprogramsDelay": 0
}
   */
  const update = useCallback(
    (terminalId, terminalDeviceId, programId, program) => {
      return axios
        .put(
          `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/programs/${programId}`,
          program,
          Authservice.getRequestOptions("PUT")
        )
        .then(Authservice.handleResponse)
        .then((program) => {
          return parseProgram(program);
        });
    },
    []
  );

  const parseStartHour = useCallback((startHour) => {
    return startHour.split(":");
  }, []);

  function parsePrograms(programs) {
    if (programs && programs instanceof Array) {
      const parsed = programs.map(parseProgram);

      return parsed
        .sort((first, second) => {
          if (first?.id < second?.id) {
            return -1;
          }
          if (first?.id > second?.id) {
            return 1;
          }
          return 0;
        })
        .filter((program) => program && !program?.deleted_by_user)
        .map((program, index) => {
          program.index = index;
          const [hour, minute] = parseStartHour(program.startHour);
          program.hour = hour;
          program.minute = minute;
          program.new = false;
          program.modified = false;
          program.removed = false;

          if (program.subprograms && program.subprograms instanceof Array) {
            program.subprograms = program.subprograms
              .sort((first, second) => {
                if (first?.index < second?.index) {
                  return -1;
                }
                if (first?.index > second?.index) {
                  return 1;
                }
                return 0;
              })
              .map((subprogram, index) => {
                subprogram.new = false;
                subprogram.index = index;
                subprogram.name = `S${index + 1 || ""}`;
                return subprogram;
              });
          }
          return program;
        });
    }
    return programs;
  }

  function parseProgram(program) {
    return TerminalDeviceProgram.parseFromObjectDto(program);
  }

  const value = useMemo(() => {
    return {
      getAll,
      getOne,
      save,
      deleteProgram,
      update,
    };
  }, [getAll, getOne, save, deleteProgram, update]);

  return <ProgramsContext.Provider value={value} {...props} />;
};

export const usePrograms = () => {
  const context = React.useContext(ProgramsContext);
  if (!context) {
    throw new Error(
      "usePrograms debe estar dentro del proveedor ProgramsContext"
    );
  }
  return context;
};
