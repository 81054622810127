import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { projectsConstants } from "../../../../../../_constants/projects.constants";
import { sensorService } from "../../../../../../_services/sensors.service";
import { DangerComponent } from "../../../../../DangerComponent/DangerComponent";
import { DropdownC } from "../../../../Airframe/Dropdown/Dropdown";
import { PopUpC } from "../../../../Airframe/PopUp/PopUp";
import { Spinner } from "../../../../Airframe/Spinner/Spinner";
import { ReactComponent as QuestionSvg } from "./../../../../../../images/ImagesTerminalsDevice/question.svg";
import { Input } from "reactstrap";
import { CheckComponent } from "../../../../Airframe/CheckComponent/CheckComponents";
import {
  TerminalDeviceSensorTriggerConfigurator,
  TriggerConfigurationOperators,
  TriggerConfigurationTypes,
} from "../../Triggers/TerminalDeviceSensorTriggerConfigurator";
import { DigitalSensorsConfigurationHelp } from "./DigitalSensorsConfigurationHelp";
import { TerminalDeviceSensorStats } from "../../../TerminalDevices/Components/TerminalDeviceSensorStats";
import { useApp } from "../../../../../../context/app-context";
import { castToPositiveInteger } from "../CudsConfiguration";
import {
  SensorGenericMeasurementConfiguration,
  isValidMeasurement,
} from "../sensorsConf/SensorGenericMeasurementConfiguration";
import { TerminalDeviceSensorCommonConfiguration } from "../TerminalDeviceSensorCommonConfiguration";
import { usePublisher } from "../../../../../../context/publish-context";
import uuid from "uuid/v4";
import { MESSAGE_TYPE_ACTION } from "../../../../../../_constants/messageType.constants";
import { useTerminalDevice } from "../../../../../../context/terminal-device-context";

export const DigitalSensorsConfiguration = (props) => {
  const {
    onDigitalSensorChangeNotification,
    mustSave,
    changeNotificationType,
    onErrorSaving,
    onSuccessSaving,
  } = props;

  const { terminalDevices } = useApp();
  const { terminalDevice, updateTerminalDeviceSensor: parentUpdateTerminalDeviceSensor} = useTerminalDevice()
  const { publish } = usePublisher();

  const sensortypesLoadedStates = {
    idle: "IDLE",
    loading: "LOADING",
    loaded: "LOADED",
    error: "ERROR",
    error_not_found: "ERROR_NOT_FOUND",
  };

  const apiStates = {
    idle: "IDLE",
    create: "CREATE",
    update: "UPDATE",
    waiting: "WAITING",
    created: "CREATED",
    updated: "UPDATED",
    error: "ERROR",
  };

  const initState = {
    selectedSensor: undefined,
    httpAction: undefined,

    sensorType: undefined,
    sensorTypesLoadState: sensortypesLoadedStates.idle,

    lastMustSave: mustSave,
    apiState: apiStates.idle,
  };

  const httpActions = {
    create: "CREATE",
    update: "UPDATE",
  };

  const errorCodes = {
    error_form_raw_value: 1,
    error_form_unit_value: 2,
    error_saving: 4,
  };

  const [state, setState] = useState(initState);
  const [showHelp, setShowHelp] = useState(false);
  const { maxDigitalInputs } = terminalDevice?.device;

  const loadDigitalSensor = useCallback(
    (sensorIndex, source) => {
      if (source instanceof Array) {
        let tmpSelectedSensor = source.filter(
          (s) =>
            s?.sensorIndex === sensorIndex &&
            s?.sensorId.physicalCommunicationType ===
              projectsConstants.global.sensors.phys.digital
        )[0];

        if (tmpSelectedSensor) {
          setState({
            ...state,
            httpAction: httpActions.update,
            selectedSensor: _.cloneDeep(tmpSelectedSensor),
          });
        } else {
          setState({
            ...state,
            httpAction: httpActions.create,
            selectedSensor: {
              sensorIndex,
              active: false,
              description: undefined,
              sensorId: {
                id: state.sensorType?.id,
              },
            },
          });
        }
      }
    },
    [httpActions.create, httpActions.update, state]
  );

  //#region ON
  const onInfoClick = useCallback(() => {
    setShowHelp(true);
  }, []);

  const onClosePopUp = useCallback(() => {
    setShowHelp(false);
  }, []);

  const validateForm = useCallback(() => {
    return { ...state, apiState: apiStates.idle };
  }, [state, apiStates.idle]);

  const onDigitalSensorSelected = useCallback(
    (sensorIndex) => {
      loadDigitalSensor(sensorIndex, terminalDevice.sensors);
    },
    [loadDigitalSensor, terminalDevice.sensors]
  );

  const onSaveAction = useCallback(() => {
    let newState = validateForm();
    if (newState?.apiState !== apiStates.error) {
      switch (state.httpAction) {
        case httpActions.create:
          newState = { ...newState, apiState: apiStates.create };
          break;
        case httpActions.update:
          newState = { ...newState, apiState: apiStates.update };
          break;
        default:
          console.log("httpAction no soportado");
          break;
      }
    }
    setState(newState);
  }, [
    validateForm,
    apiStates.error,
    apiStates.create,
    apiStates.update,
    state.httpAction,
    httpActions.create,
    httpActions.update,
  ]);

  const onAnyFieldChange = useCallback(() => {
    onDigitalSensorChangeNotification(changeNotificationType);
  }, [onDigitalSensorChangeNotification, changeNotificationType]);

  const onDescriptionChange = useCallback(
    (description) => {
      if (state.selectedSensor) {
        state.selectedSensor.description = description;
        setState({ ...state });
        onAnyFieldChange();
      }
    },
    [state, onAnyFieldChange]
  );

  const onActivedChange = useCallback(() => {
    if (state.selectedSensor) {
      state.selectedSensor.active = !state.selectedSensor.active;
      setState({ ...state });
      onAnyFieldChange();
    }
  }, [state, onAnyFieldChange]);

  const onChangeReportInterval = useCallback(
    (intervalInMinutes) => {
      if (state.selectedSensor) {
        state.selectedSensor.reportIntervalInSeconds = intervalInMinutes * 60;
        setState({ ...state });
        onAnyFieldChange();
      }
    },
    [onAnyFieldChange, state]
  );

  const mutateState = useCallback(() => {
    if (terminalDevice?.sensors instanceof Array) {
      terminalDevice.sensors = terminalDevice.sensors.map((s) =>
        s?.sensorIndex === state.selectedSensor?.sensorIndex &&
        s?.sensorId.physicalCommunicationType ===
          projectsConstants.global.sensors.phys.digital
          ? state?.selectedSensor
          : s
      );
      if (state.selectedSensor) {
        loadDigitalSensor(
          state.selectedSensor?.sensorIndex,
          terminalDevice.sensors
        );
      }
    }
  }, [loadDigitalSensor, state.selectedSensor, terminalDevice]);

  const onTerminalDeviceSensorMeasurementChange = useCallback(
    (measurement) => {
      if (state.selectedSensor) {
        state.selectedSensor.measurement = measurement;
        setState({ ...state });
        onAnyFieldChange();
      }

      if (isValidMeasurement(state.selectedSensor, measurement)) {
        onAnyFieldChange();
      }
    },
    [onAnyFieldChange, state]
  );

  const onPulseStabilizationChange = useCallback(
    (pulseStabilizationMillis) => {
      if (state.selectedSensor) {
        state.selectedSensor.pulseStabilizationMillis =
          castToPositiveInteger(pulseStabilizationMillis) || 0;

        setState({ ...state });
        onAnyFieldChange();
      }
    },
    [onAnyFieldChange, state]
  );

  //#endregion

  //#region components

  const getDropDownCuds = useCallback(() => {
    let selected = undefined;
    const options = _.times(maxDigitalInputs, (i) => {
      const option = {
        id: i + 1,
        value: i + 1,
        label: `Entrada pulsos ${i + 1}`,
      };

      if (i === state.selectedSensor?.sensorIndex) {
        selected = option;
      }
      return option;
    });

    return (
      <DropdownC
        options={options}
        onChange={(selected) => onDigitalSensorSelected(selected?.id - 1)}
        placeholder={"Seleccione un sensor..."}
        value={selected}
      />
    );
  }, [
    maxDigitalInputs,
    onDigitalSensorSelected,
    state.selectedSensor?.sensorIndex,
  ]);

  //#endregion

  //#region  API POST/PUT
  const getDto = useCallback((sensor) => {
    const calibrationRawValue = sensor.measurement?.calibrationRawValue || 1;
    const calibrationUnitValue = sensor.measurement?.calibrationUnitValue || 1;
    return {
      sensorIndex: sensor.sensorIndex,
      active: sensor.active,
      description: sensor.description,
      measurement: sensor.measurement || {
        measurementUnit: "l",
        sensorUnit: "Ud",
        calibrationRawValue,
        calibrationUnitValue,
      },
      pulseStabilizationMillis: sensor.pulseStabilizationMillis || 0,
      equation: `({sensorValue}*${calibrationUnitValue})/${calibrationRawValue}`,
      reportIntervalInSeconds: sensor.reportIntervalInSeconds || 300,
    };
  }, []);

  const createTerminalDeviceSensor = useCallback(() => {
    setState({ ...state, apiState: apiStates.waiting });
    sensorService
      .createTerminalDeviceSensor(
        terminalDevice.terminal,
        terminalDevice.id,
        state.sensorType.id,
        state.selectedSensor.sensorIndex,
        getDto(state.selectedSensor)
      )
      .then(
        (terminalDeviceSensorCreated) => {
          setState({
            ...state,
            apiState: apiStates.created,
            selectedSensor: terminalDeviceSensorCreated,
          });
        },
        (error) => {
          setState({
            ...state,
            apiState: apiStates.error,
            errorCode: errorCodes.error_saving,
          });
        }
      );
  }, [
    apiStates.waiting,
    terminalDevice.terminal,
    terminalDevice.id,
    state,
    getDto,
    apiStates.created,
    apiStates.error,
    errorCodes.error_saving,
  ]);

  const updateTerminalDeviceSensor = useCallback(() => {
    setState({ ...state, apiState: apiStates.waiting });
    sensorService
      .updateTerminalDeviceSensor(
        terminalDevice.terminal,
        terminalDevice.id,
        state.sensorType.id,
        state.selectedSensor.sensorIndex,
        getDto(state.selectedSensor)
      )
      .then(
        (terminalDeviceSensorUpdated) => {
          setState({
            ...state,
            apiState: apiStates.updated,
            selectedSensor: terminalDeviceSensorUpdated,
          });
        },
        (error) => {
          setState({
            ...state,
            apiState: apiStates.error,
            errorCode: errorCodes.error_saving,
          });
        }
      );
  }, [
    apiStates.waiting,
    terminalDevice.terminal,
    terminalDevice.id,
    state,
    getDto,
    apiStates.updated,
    apiStates.error,
    errorCodes.error_saving,
  ]);

  //#endregion

  //#region useEffect
  useEffect(() => {
    if (state.sensorTypesLoadState === sensortypesLoadedStates.idle) {
      // CARGAMOS LOS SENSORES.
      setState((state) => ({
        ...state,
        sensorTypesLoadState: sensortypesLoadedStates.loading,
      }));
      sensorService.getSensorsTypes().then(
        (sTypes) => {
          sTypes = sTypes instanceof Array ? sTypes : [];
          const sType = sTypes.filter(
            (sType) =>
              sType?.physicalCommunicationType ===
                projectsConstants.global.sensors.phys.digital &&
              sType?.name === projectsConstants.global.sensors.name.fake_digital
          )[0];
          setState((state) => ({
            ...state,
            sensorType: sType,
            sensorTypesLoadState: sType
              ? sensortypesLoadedStates.loaded
              : sensortypesLoadedStates.error_not_found,
          }));
        },
        (error) => {
          setState((state) => ({
            ...state,
            sensorType: undefined,
            sensorTypesLoadState: sensortypesLoadedStates.error,
          }));
        }
      );
    }
  }, [
    sensortypesLoadedStates.error,
    sensortypesLoadedStates.error_not_found,
    sensortypesLoadedStates.idle,
    sensortypesLoadedStates.loaded,
    sensortypesLoadedStates.loading,
    state.sensorTypesLoadState,
  ]);

  useEffect(() => {
    if (state.lastMustSave !== mustSave && mustSave > 0) {
      state.lastMustSave = mustSave;
      onSaveAction();
    }
  }, [mustSave, onSaveAction, state, state.lastMustSave]);

  useEffect(() => {
    if (
      terminalDevice?.sensors &&
      terminalDevice.sensors instanceof Array &&
      terminalDevice.sensors.length > 0 &&
      state.sensorTypesLoadState === sensortypesLoadedStates.loaded
    ) {
      if (state.selectedSensor?.sensorIndex !== undefined) {
        loadDigitalSensor(
          state.selectedSensor.sensorIndex,
          terminalDevice.sensors
        );
      } else {
        loadDigitalSensor(0, terminalDevice.sensors);
      }
    }
  }, [terminalDevice.sensors, state.sensorTypesLoadState]);

  const onSaved = useCallback(() => {
    parentUpdateTerminalDeviceSensor(state.selectedSensor);
    onSuccessSaving(changeNotificationType);
    if(state.selectedSensor?.notified === false){
      publish(projectsConstants.master_outputs.actions.common.sensorsconfig, {
        id: uuid(),
        type: MESSAGE_TYPE_ACTION,
        data: {
          target_id: terminalDevice?.id,
        },
      });
    }
  }, [changeNotificationType, onSuccessSaving, parentUpdateTerminalDeviceSensor, publish, state.selectedSensor, terminalDevice?.id])

  useEffect(() => {
    switch (state.apiState) {
      case apiStates.create:
        createTerminalDeviceSensor();
        break;
      case apiStates.update:
        updateTerminalDeviceSensor();
        break;
      case apiStates.created:
      case apiStates.updated:
        onSaved();
        break;
      case apiStates.error:
        if (state.errorCode === errorCodes.error_saving) {
          onErrorSaving(changeNotificationType);
        }
        break;
      default:
        break;
    }
  }, [state.apiState]);
  //#endregion

  if (
    state.sensorTypesLoadState === sensortypesLoadedStates.error ||
    state.sensorTypesLoadState === sensortypesLoadedStates.error_not_found
  ) {
    return (
      <DangerComponent
        message={"Error recuperando los tipos de sensores."}
      ></DangerComponent>
    );
  } else if (
    state.sensorTypesLoadState === sensortypesLoadedStates.loading ||
    state.apiState === apiStates.waiting
  ) {
    return <Spinner></Spinner>;
  }

  return (
    <div>
      <PopUpC
        activate={showHelp}
        deactivatepopup={onClosePopUp}
        content={<DigitalSensorsConfigurationHelp />}
      />
      {(state.apiState === apiStates.created ||
        state.apiState === apiStates.updated) && (
        <CheckComponent
          message={"Se han guardado los datos correctamente."}
        ></CheckComponent>
      )}
      <div className="Titlewithinfo">
        <div className="Title_DescripctionConf ">Entrada pulsos:</div>
        <div className="icoOutputconf">
          <div className="icoOutputconf2">
            <QuestionSvg
              className="ButtonProgram Info"
              onClick={(e) => onInfoClick()}
            />
          </div>
        </div>
      </div>
      {getDropDownCuds()}

      <div className="Title_Descripction">Descripción:</div>
      <Input
        type="text"
        name="digital_sensor_descr"
        id="digital_sensor_descr"
        placeholder={"Descripción..."}
        className="InputForm descriptionInput"
        onChange={(e) => onDescriptionChange(e.target.value)}
        value={state.selectedSensor?.description || ""}
      />

      <TerminalDeviceSensorCommonConfiguration
        sensor={state.selectedSensor}
        onChangeActiveState={onActivedChange}
        onReportIntervalSelected={onChangeReportInterval}
      />

      {state.selectedSensor?.active && (
        <>
          <div className="TabOutputConf"></div>
          <div className="titleModesActivationConf">Datos calibrado</div>

          <SensorGenericMeasurementConfiguration
            terminalDeviceSensor={state.selectedSensor}
            onTerminalDeviceSensorMeasurementChange={
              onTerminalDeviceSensorMeasurementChange
            }
          />

          <div className="Title_Descripction">
            Tiempo mínimo de señal para considerar pulso (milisegundos):
          </div>
          <Input
            type="text"
            name="digital_sensor_pulseStabilizationMillis"
            id="digital_sensor_pulseStabilizationMillis"
            placeholder={"Milisegundos..."}
            className="InputForm descriptionInput"
            onChange={(e) => onPulseStabilizationChange(e.target.value)}
            value={state.selectedSensor?.pulseStabilizationMillis || ""}
          />
        </>
      )}

      {state.selectedSensor?.active && (
        <TerminalDeviceSensorStats
          sensor={state.selectedSensor}
        ></TerminalDeviceSensorStats>
      )}

      {state.selectedSensor && (
        <TerminalDeviceSensorTriggerConfigurator
          terminalDevice={terminalDevice}
          selectedSensor={state.selectedSensor}
          terminalDevices={terminalDevices}
          onSensorsChanged={mutateState}
          triggerConfigurationType={TriggerConfigurationTypes.BUTTON}
          allowOutputFromAnyDeviceOnTerminal={false}
          allowAdditionalActions={true}
          greaterOperator={TriggerConfigurationOperators.falling_flank}
        />
      )}
    </div>
  );
};
