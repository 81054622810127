import React, { useEffect } from "react";
import  Check  from "./check.png";

export const CheckComponent = (props) => {
  const { message } = props;
  useEffect(() => {}, [props]);
  return (
    <div className="childCardDevices">
      <div style={{ display: "flex", width: "100%", alignItems: "center" }} className="Title_Descripction">
        <div style={{ width: "15%" }}>
          <img src={Check} alt="check" className="PicPlugTD" />
        </div>
        <div className="confirmServer">{message}</div>
      </div>
    </div>
  );
};
