import React, { useCallback, useMemo } from "react";
import { AtomizerProvider } from "../_services/atomizer.service";
import { TerminalDeviceSensor } from "../classes/TerminalDevice/Sensor/TerminalDeviceSensor";
import { useApp } from "./app-context";

const TerminalDeviceContext = React.createContext();

export const TerminalDeviceProvider = (props) => {
  const { updateTerminalDevice } = useApp()
  const { terminalDevice } = props

  const updateTerminalDeviceSensor = useCallback(
    (terminalDeviceSensor) => {
      if (terminalDevice?.sensors instanceof Array) {
        let prevTds = terminalDevice.sensors.filter(
          (tmpTds) =>
            tmpTds?.sensorIndex === terminalDeviceSensor.sensorIndex &&
            tmpTds?.sensorId.physicalCommunicationType ===
              terminalDeviceSensor?.sensorId.physicalCommunicationType
        )[0];

        if (prevTds) {
          let index = terminalDevice
            .sensors
            .indexOf(prevTds);
            terminalDevice.sensors[index] = TerminalDeviceSensor.parseFromObjectDto({...terminalDeviceSensor});
        } else {
          terminalDevice.sensors.push(terminalDeviceSensor);
        }
        updateTerminalDevice(terminalDevice);
      }
    },
    [updateTerminalDevice, terminalDevice]
  );

  const deleteTerminalDeviceSensor = useCallback(
    (terminalDeviceId, sensorIndex) => {
      if (terminalDevice && terminalDevice.sensors) {
        let index = -1;
        for (let i = 0; i < terminalDevice.sensors.length; i++) {
          if (terminalDevice.sensors[i].sensorIndex === sensorIndex) {
            index = i;
            break;
          }
        }
        if (index >= 0) {
          terminalDevice.sensors.splice(index, 1);
          updateTerminalDevice(terminalDevice);
        }
      }
    },
    [updateTerminalDevice, terminalDevice]
  );

  const value = useMemo(() => {
    return {
      terminalDevice,
      updateTerminalDeviceSensor,
      deleteTerminalDeviceSensor
    };
  }, [terminalDevice, updateTerminalDeviceSensor, deleteTerminalDeviceSensor]);

  return <TerminalDeviceContext.Provider value={value} > 
    <AtomizerProvider {...props} />
  </TerminalDeviceContext.Provider>;
};

export const useTerminalDevice = () => {
  const context = React.useContext(TerminalDeviceContext);
  if (!context) {
    throw new Error(
      "useTerminalDevice debe estar dentro del proveedor AppContext"
    );
  }
  return context;
};

export default TerminalDeviceContext;
