import React, { useEffect, useState, useCallback } from "react";
import { projectsConstants } from "../../../../../../../../_constants/dist/projects.constants.dev";
import { OutputService } from "../../../../../../../../_services/outputcontroller.service";
import { PopUpC } from "../../../../../../Airframe/PopUp/PopUp";
import { SelectActivationTypes } from "../../../../../OutputsView/SelectActivationTypes/SelectActivationTypes";

import { ReactComponent as Configure } from "./../../../../../../../../images/ImagesTerminalsDevice/configure.svg";

export const TypesController = props => {

    const { waitingMqttResponse,
     waitingApiResponse, 
     outputs, changeTypeOutputView, modeC, outputModifiedViewInPopUpTools,actualizedDropdownTools,ShowButtonSavePrograms} = props
    const [showPopUpTypes, setshowPopUpTypes] = useState(false);
    const [activationParams, setactivationParams] = useState({});

    //get tipos
    const [outputTypes, setoutputTypes] = useState(undefined);

    //Save number of output select not filter for subprograms map, change by position for filter.
    const [outputSelect, setoutputSelect] = useState(undefined);
    const [mode, setmode] = useState(projectsConstants.global.activations.continuous);

    // Preparando banner con la salida seleccionada para mostrar sus datos editados:
    useEffect(() => {

        if (outputModifiedViewInPopUpTools) {
            outputs.forEach(output => {
                if (output.output === outputModifiedViewInPopUpTools.output) {
                    setoutputSelect(output);
                    loadActivationParams(output);
                    loadActivationMode(output);
                    setshowPopUpTypes(true);
                }

            });
        }
    }, [outputModifiedViewInPopUpTools]);


    useEffect(() => {
        GetAllActivations();
    }, [actualizedDropdownTools]);

    

    function GetAllActivations() {
        OutputService.getActivations().then(
            (tmpActivations) => {
                setoutputTypes(tmpActivations);
                getOutputs(tmpActivations);
            },
            (error) => {
                console.table("Error al recuperar los modos de activación");
            }
        );
    }

    const changeMode = useCallback(
        (positionMode, option) => {
            setmode(option.sid);
        }, []);

    const selectTypes = useCallback(() => {
        setshowPopUpTypes(true);
    }, []);

    const closePopUP = useCallback(() => {
        // setactivationParams({})
        setshowPopUpTypes(false);
    }, []);


    const onActivationParamsChange = useCallback(
        (actParams) => {
            setactivationParams(actParams);
        },
        []
    );

    const loadActivationParams = useCallback((output) => {

        if (typeof output?.activationParams === "string") {
            try {
                setactivationParams(JSON.parse(output?.activationParams) || output?.activationParams);
                //  setactivationParams(JSON.parse(output?.activationParams) || {});
            } catch (err) {
                console.log(err);
                // setactivationParams({});
            }

        } else {
            setactivationParams(output?.activationParams);
        }

    }, []);

    const loadActivationMode = useCallback((output) => {
        if(output?.activationType){
            setmode(output?.activationType);
        }
    }, []);


    const getOutputs = useCallback(
        (typesActivation) => {
            let selectedOutput = undefined;
            if(outputs.length > 0){
                selectedOutput = outputs[0];
            }
           
            setoutputSelect(selectedOutput);
            loadActivationParams(selectedOutput);
            loadActivationMode(selectedOutput);
        },
        [ loadActivationParams, loadActivationMode, outputs]
    );


    const saveModificationOutputInMap = useCallback(
        (modeTmp) => {
            changeTypeOutputView(outputSelect, modeTmp, activationParams);
            closePopUP();
        },
        [outputSelect, activationParams,  changeTypeOutputView, closePopUP]
    );

    const onSavechangeOutputinTools = useCallback(
        (position, option) => {
            const output = outputs[position];
            setoutputSelect(output);
            loadActivationParams(output);
            loadActivationMode(output);
        },
        [outputs, loadActivationParams, loadActivationMode]
    );

    return (
        <> <PopUpC
            activate={showPopUpTypes}
            deactivatepopup={closePopUP}
            popupPersonalizedClass={"typesOutputsClass"}
            content={
                <SelectActivationTypes
                    activationParams={activationParams}
                    onActivationParamsChange={onActivationParamsChange}
                    disabled={waitingApiResponse || waitingMqttResponse}
                    outputs={outputs}
                    back={closePopUP}
                    saveModificationOutputInMap={saveModificationOutputInMap}
                    changeOutput={onSavechangeOutputinTools}
                    changeMode={changeMode}
                    outputTypes={outputTypes}
                    mode={mode}
                    outputSelect={outputSelect}
                    ShowButtonSavePrograms={ShowButtonSavePrograms}
                    
                    />}
        />
            <div>
                <Configure className="SvgIcoR2"
                    onClick={(e) => selectTypes()}
                />

            </div>
        </>
    );
}
