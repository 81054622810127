export class Device {
    id;
	name;
	manufacturer;
	model;
	protocol;
	com;
	speed;
	databits;
	parity;
	stopbits;
	checksum;
	maxOutputs;
	maxAnalogInputs;
	maxCuds;
	tecoId;

    static parseFromPlainDto(dtoText){
        const plainObject = JSON.parse(dtoText);
        return Device.parseOutputDtoFromObject(plainObject);
    }

    static parseFromObjectDto(dtoObject){
        return Object.setPrototypeOf(dtoObject, Device.prototype);
    }
}