import React, { useEffect, useCallback, useState, useRef } from "react";
import { BoxDialog } from "../../../../Airframe/BoxDialog/BoxDialog";
import { Clock } from "./../components/Clock/Clock";
import { Days } from "./../components/Days/Days";
import { ReactComponent as DangerIcoOffOutputs } from "./../../../../../../images/ImagesTerminalsDevice/danger.svg";
import { Input } from "reactstrap";
import { DrapDropView } from "../components/DragDrop/DrapDropView";
import { TerminalDeviceProgram } from "../../../../../../classes/TerminalDevice/Program/TerminalDeviceProgram";
export const ProgramUI = (props) => {
  const focusName = useRef(null);

  const {
    errorSubprogramsSize,
    errorDaysSelected,
    showButtonSave,
    actionOnCancelProgram,
    ShowUIAddSubroutines,
    errorStateMachine,
    errorDatetime,
    alldata,
    SubroutinesList,
    ListPrograms,
    Programsreorganizeds,
    program,
    updateProgram,
    actionOnSave,
    actionOnDeleteSubroutine,
    actionOnEdit,
    InfoC,
    reorderedError,
    focusOnSubprogramIndex,
    PermisedEditionProgram,
    messagenotedit,
    typeofCard,
 
  } = props;

  //Efecto - Load programas en estado.
  const [showComicSubroutines, setShowComicSubroutines] = useState(false);
  const [showComicHour, setShowComicHour] = useState(false);
  const [showComicDays, setShowComicDays] = useState(false);

  useEffect(() => {
    focusName.current.focus();
  }, []);
  //#region FORM DATA
  /** SETs FORM DATA */
  const SetName = useCallback(
    (name) => {
      program.name = name;
      program.modified = true;
      updateProgram(program);
      //setalldata({...alldata, name, modified: true});
    },
    [program, updateProgram]
  );

  const SetHourMinutes = useCallback(
    (seconds, minute, hour) => {
      program.hour = hour;
      program.minute = minute;
      program.modified = true;
      updateProgram(program);
      //setalldata({...alldata, hour, minute, modified: true});
    },
    [program, updateProgram]
  );

  const SetDays = useCallback(
    (days) => {
      program.days = days;
      program.modified = true;
      updateProgram(program);
      //setalldata({...alldata, days, modified: true});
    },
    [program, updateProgram]
  );
  //#endregion

  //Avisa al usuario si no puede editar la fecha ni los días de un programa guardado.
  const WarningMessageDays = useCallback(() => {
    setShowComicDays(true);
  }, []);

  const WarningMessageClock = useCallback(() => {
    setShowComicHour(true);
  }, []);
  const WarningMessagesSubroutines = useCallback(() => {
    setShowComicSubroutines(true);
  }, []);

  const closeMessageNotEdit = useCallback(() => {
    setShowComicSubroutines(false);
    setShowComicDays(false);
    setShowComicHour(false);
  }, []);

  return (
    <>
      {alldata?.errorSaving && (
        <div className="MessageServerOut">
          <div className="Error">ERROR GUARDANDO EL PROGRAMA</div>
          <div>
            <DangerIcoOffOutputs className="IcoError" />
          </div>
        </div>
      )}
      {alldata?.errorDeleting && (
        <>
          <div className="MessageServerOut">
            <div className="Error">ERROR ELIMINANDO EL PROGRAMA</div>
            <div>
              <DangerIcoOffOutputs className="IcoError" />
            </div>
          </div>
          <hr className="Barra" />
        </>
      )}
      <BoxDialog
        content={"No se pueden editar las programaciones de salidas."}
        show={showComicSubroutines}
        onClose={closeMessageNotEdit}
      />
      {SubroutinesList?.length > 0 && ListPrograms.length > 0 && (
        <>
          <DrapDropView
            typeofCard={typeofCard}
            reorderedError={reorderedError}
            listElements={SubroutinesList}
            alldata={alldata}
            actionOnDeletep={actionOnDeleteSubroutine}
            actionOnEdit={actionOnEdit}
            itemstoReorganized={Programsreorganizeds}
            focusOnIndex={focusOnSubprogramIndex}
            Info={InfoC}
            showMessageNotEdit={WarningMessagesSubroutines}
            typeDragDrop={"Subprograms"}
          />
        </>
      )}

      <div className="ViewInterfacePrograms">
        {/*     <div className="TitleProgram">
              Configuración del programa {alldata?.index + 1}:
            </div> */}
        <div className="ViewInterfaceProgramsPart1">
          <div className="InputnameProgram">
            <div className="titleform programName">Nombre del programa:</div>
            <div className="NameKeyProgramF">
              <Input
                type="text"
                placeholder={`Programa ${alldata?.index + 1}`}
                value={alldata?.name || ""}
                className="bg-white NameKeyProgram"
                onChange={(e) => SetName(e.target.value)}
                innerRef={focusName}
                tabIndex={-1}
                id={"nameprogram"}
              />
            </div>
          </div>
        </div>

        <BoxDialog
          content={"No puedes editar este programa."}
          show={showComicHour}
          onClose={closeMessageNotEdit}
        />
        <div className="ViewInterfaceProgramsPart2">
          {/*   <hr className="Barra" /> */}

          <div className="SelectTimeProgram">
            <div
              ref={errorDatetime}
              hidden={!errorStateMachine.datetime}
              className="Error"
            >
              El programa no puede durar más de 24H.
            </div>
            <Clock
              SetHour={SetHourMinutes}
              minMinutes={0}
              initHour={alldata.hour}
              initMinute={alldata.minute}
              enabled={PermisedEditionProgram}
              WarningMessage={WarningMessageClock}
              title={<div className="HourStartIn">Hora de Inicio:</div>}
              maxHours={23}
              maxMinutes={59}
              maxMinutesWhenMaxHoursReached={59}
              onMaxHourSetMinutesToMax={true}
              applyMaxMinutsOnlyWhenMaxHoursReached={true}
            />
          </div>
        </div>

        {/*   <hr className="Barra" /> */}
        <BoxDialog
          content={"No puedes editar los dias."}
          show={showComicDays}
          onClose={closeMessageNotEdit}
        />
        <div className="DaysComponent">
          <div
            ref={errorDaysSelected}
            hidden={!errorStateMachine.daysSelected}
            className="Error"
          >
            Debes de seleccionar al menos un día.
          </div>

          <Days
            SetDays={SetDays}
            initDays={alldata.days}
            enabled={PermisedEditionProgram}
            WarningMessage={WarningMessageDays}
          />
        </div>
        {messagenotedit}
        {/*   <hr className="Barra" /> */}
      </div>

      {/** Linea arriba de los botones para mostrar los errores que puedan surgir por la interacción del usuario: */}
      <div
        ref={errorSubprogramsSize}
        hidden={!errorStateMachine.subprogramsSize}
        className="Error"
      >
        Programa las salidas.
      </div>

      <div className="ButtonsPrograms">

          <>
            <div
              className="Button"
              onClick={(e) => actionOnCancelProgram()}
            >
              Atras
            </div>
            <div
              ref={errorSubprogramsSize}
              hidden={!errorStateMachine.subprogramsSize}
              className="Error"
            >
              Programa las salidas.
            </div>
            <div
              className="Button Secondary"
              onClick={(e) => ShowUIAddSubroutines()}
            >
              Programar salidas
            </div>
          </>
       

        {showButtonSave && (
          <>
         
            <div className="Button" onClick={(e) => actionOnSave()}>
              Guardar
            </div>
          </>
        )}
      </div>
    </>
  );
};
