import React, { useState, useEffect, useCallback } from "react";
import { CardSensor } from "./CardSensor";
import { sensorService } from "../../../../../_services/sensors.service";
import { projectsConstants } from "../../../../../_constants/projects.constants";


const states = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  LOADED: "LOADED",

  ERROR: "ERROR",
};
const initState = {
  state: states.LOADED,
  measures: undefined,
};

export const AnalogSensorCard = props => {
    const { terminalDevice, sensor, onSensorClick } = props;
    const [state, setState] = useState({...initState, measures: sensor?.measures});

    useEffect(() => {
        setState(current => ({...current, measures: sensor?.measures}))
    }, [sensor])

    const onClickCardSensor = useCallback(() => {
        if(sensor && onSensorClick){
          onSensorClick(sensor.sensorIndex, sensor?.sensorId?.physicalCommunicationType)
        }
      }, [onSensorClick, sensor])
    
      useEffect(() => {
        setState(currentState => ({...currentState, state: states.IDLE}))
      }, [sensor?.lastValueAt])
    
      useEffect(() => {
        if (state.state === states.IDLE) {
          setState({ ...state, state: states.LOADING });
          sensorService
            .getTerminalDeviceSensor(
              terminalDevice?.terminal,
              terminalDevice?.id,
              sensor?.sensorId?.id,
              sensor?.sensorIndex
            )
            .then(
              (receivedTerminalDeviceSensor) => {
                setState({ ...state, state: states.LOADED, measures: receivedTerminalDeviceSensor?.measures });
              },
              (error) => {
                setState({ ...state, state: states.ERROR });
              }
            );
        }
      }, [sensor?.sensorId, sensor?.sensorIndex, state, terminalDevice?.id, terminalDevice?.terminal]);

    
    const getCards = useCallback(() => {
        return sensor
            ?.measures
            ?.map((measure, index) => {
                return (
                    <CardSensor
                      key={index}
                      physicalCommunicationType={
                        projectsConstants.global.sensors.phys.analog
                      }
                      id={index}
                      sensorIndex={sensor?.sensorIndex}
                      sensorValue={measure.value}
                      sensorUnit={measure.unit}
                      loading={sensor?.isRequesting || state.state === states.LOADING}
                      dateTime={
                        sensor?.lastValueAt &&
                        new Date(sensor.lastValueAt)
                      }
                      onClick={onClickCardSensor}
                      allowSwitchToAverage={!isNaN(measure.dailyAverage)}
                      dialyAverageSensorValue={measure.dailyAverage}
                      dailyMinSensorValue={measure.dailyMin}
                      dailyMaxSensorValue={measure.dailyMax}
                    />
                  );
            }) 
    }, [onClickCardSensor, sensor?.isRequesting, sensor.lastValueAt, sensor?.measures, sensor?.sensorIndex, state.state])

    return <>
        {getCards()}
    </>
}