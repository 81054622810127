import React, { useState, useEffect } from "react";
import "./Info.sass";
export const Info = (props) => {
  const { data } = props;
  const [fecha, setfecha] = useState(false);
  useEffect(() => {
  /*  if (data?.lastHeartbeat) {
      const dataModification = new Date(data.lastHeartbeat);
      const hour = dataModification.getUTCHours();
      const date = dataModification.getUTCDate();
      const moth = dataModification.getUTCMonth() + 1;
      const year = dataModification.getUTCFullYear();
      let minutes = dataModification.getUTCMinutes();
      if(minutes<10){ minutes= "0"+minutes};
      setfecha(hour + ":" + minutes + "  " + date + "/" + moth + "/" + year);
      console.log(new Intl.DateTimeFormat().format(date));
    }*/
  }, []);

  return (
    <div>
      {/* Sectors */}
      {data?.zone && (
        <div>
          <div className="TitleNotification">Zona: </div>
          {data?.zone}
        </div>
      )}
      {data?.name && (
        <div>
          <div className="TitleNotification">Nombre: </div>
          {data?.name}
        </div>
      )}
      {data?.description && (
        <div>
          <div className="TitleNotification">Descripción: </div>
          <div> {data?.description}</div>
        </div>
      )}

      {data?.location_latitude && data?.location_longitude && (
        <div className="Coordenates">
          <div className="TitleNotification2">Longitud y latitud:</div>
          <div className="Coordenatesdata">
          <div> {data?.location_longitude} /</div>
            <div> { data?.location_latitude} </div>
           
          </div>
        </div>
      )}

      {/* Terminals */}
      {data?.lastHeartbeat && (
        <div>
          <div className="TitleNotification"> Última modificación:</div>
          <div>{fecha}</div>
        </div>
      )}

      {data?.user && (
        <div>
          <div className="TitleNotification">Permisos de Usuario:</div>{" "}
          <div>{data?.user}</div>
        </div>
      )}
      {data?.terminalType && (
        <div>
          <div className="TitleNotification">Tipo de terminal:</div>
          <div>{data?.terminalType}</div>
        </div>
      )}

      {data?.terminalDevices && (
        <div>
          <div className="TitleNotification">Dispositivos:</div>
          <div className="terminalsquantityInfo">
            {data?.terminalDevices.map((terminaldevice, index) => (
              <div key={index} className="terminalsquantityInfo-component">
            
                {terminaldevice},
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
